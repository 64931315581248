import * as React from 'react';
import Flex, { FlexProps } from '../Flex/Flex';

export interface RowProps extends FlexProps {
  /**
   * margin-left and margin-right
   */
  mx: number | string;
}

const Row = ({ mx = -3, ...props }): React.ReactElement<RowProps> => (
  <Flex mx={mx} {...props} />
);

export default Row;
