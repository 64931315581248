import rawSites from './rawSDSSites';

interface SitesResponse {
  sites: SiteResponse[];
  nextPageToken: string;
  numberOfSites: number;
}

interface SiteResponse {
  name: string;
  siteId: number;
  installs: InstallResponse[];
}

interface InstallResponse {
  name: string;
  siteEnv: 'PRODUCTION' | 'STAGING' | 'DEVELOPMENT';
  domainName: string;
  status: string;
}

const mockSites = (installNames: string[]): SitesResponse => {
  const selectedSites = rawSites.filter(
    s =>
      s.installs.map(i => i.name).filter(x => installNames.includes(x)).length
  ) as SiteResponse[];

  return {
    sites: selectedSites,
    nextPageToken: null,
    numberOfSites: selectedSites.length,
  };
};

export default mockSites;
