import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

const useEffectAfterMount = (
  callback: EffectCallback,
  deps: DependencyList = []
) => {
  const isMountedRef = useRef(false);
  useEffect(() => {
    if (isMountedRef.current) {
      callback();
    } else {
      isMountedRef.current = true;
    }
  }, deps);
};

export default useEffectAfterMount;
