import { Mode } from '../modes';
import { usePrefersColorScheme } from './usePrefersColorScheme';
import { useAutoMode } from './useAutoMode';
import { useDarkMode } from './useDarkMode';
import { eventName } from '../eventName';

export const useAppearance = () => {
  const { prefersDark } = usePrefersColorScheme();
  const { isPortalInAutoMode } = useAutoMode();
  const { isPortalInDarkMode } = useDarkMode();

  const updateBrowserAppearance = (mode: Mode) => {
    document.dispatchEvent(new CustomEvent(eventName, { detail: mode }));
    const element = document.querySelector('[data-appearance-mode]');
    if (element && element instanceof HTMLElement) {
      element.dataset.appearanceMode = mode;
    }
  };

  const isDark = isPortalInAutoMode ? prefersDark : isPortalInDarkMode;

  return {
    isDark,
    isDarkMode: isDark, // delete this once isDarkMode usages are refactored out
    updateBrowserAppearance,
  };
};

export default useAppearance;
