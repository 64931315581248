import React from 'react';
import {
  Box,
  GradientCircularProgress,
  Typography,
} from '@wpengine/unicorn/components';

const LoadingCart: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={6}>
      <GradientCircularProgress />
      <Typography>Loading your cart...</Typography>
    </Box>
  );
};

export default LoadingCart;
