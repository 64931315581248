import { rest } from 'msw';
import responses from 'mocksDir/shared/data/mockUserEntitlements';
import { ENDPOINTS, MSW_PARAMS } from 'mocksDir/shared/constants';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';

const handlers = [
  rest.get(`*/${ENDPOINTS.USER_ENTITLEMENTS}*`, (req, res, ctx) => {
    if (hasPageUrlQueryParam(MSW_PARAMS.MORE_THAN_TEN_ACCOUNTS)) {
      return customResponse(
        ctx.status(200),
        ctx.json(responses.moreThanTenAccounts)
      );
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.MULTIPLE_PARTIAL_ACCOUNTS)) {
      return customResponse(
        ctx.status(200),
        ctx.json(responses.multiplePartialAccounts)
      );
    }

    return customResponse(ctx.status(200), ctx.json(responses.diverseAccounts));
  }),
];

export default handlers;
