import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const AnnouncementIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="announcement-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5027 2.1048C17.9973 2.35069 18.1988 2.95091 17.9529 3.44544L17.2349 4.88956C16.989 5.38409 16.3888 5.58565 15.8942 5.33976C15.3997 5.09387 15.1982 4.49365 15.444 3.99912L16.1621 2.555C16.408 2.06047 17.0082 1.85891 17.5027 2.1048ZM12.4459 3.69078C12.7582 3.72791 13.0348 3.90975 13.1927 4.18169L19.0338 14.2424C19.1925 14.5157 19.2126 14.8479 19.0882 15.1384C18.9637 15.4289 18.7092 15.6434 18.4019 15.717L15.1532 16.495C15.9142 17.8286 15.4496 19.5227 14.1179 20.2873L11.7831 21.6277C10.4485 22.394 8.73926 21.9415 7.96529 20.6084L6.746 18.5083L3.82595 19.2076C3.39631 19.3105 2.95007 19.1192 2.72825 18.7372L1.13521 15.9933C0.911731 15.6084 0.969549 15.1221 1.27705 14.8003L11.6049 3.9929C11.8221 3.76557 12.1337 3.65366 12.4459 3.69078ZM8.77635 18.0221L9.69491 19.6042C9.91355 19.9808 10.4027 20.114 10.7873 19.8932L13.1221 18.5528C13.5034 18.3339 13.6297 17.854 13.413 17.4807L13.123 16.9812L8.77635 18.0221ZM3.24208 15.6389L4.0841 17.0892L16.6297 14.0849L12.1313 6.33689L3.24208 15.6389ZM21.1742 6.31195C21.4492 6.7909 21.2839 7.4021 20.8049 7.67709L18.8593 8.79416C18.3804 9.06915 17.7692 8.90381 17.4942 8.42486C17.2192 7.9459 17.3845 7.33471 17.8635 7.05971L19.8091 5.94265C20.288 5.66765 20.8992 5.83299 21.1742 6.31195ZM19.3854 11.3455C19.4207 10.7943 19.8962 10.3762 20.4473 10.4115L22.064 10.5152C22.6152 10.5505 23.0333 11.026 22.998 11.5771C22.9626 12.1283 22.4872 12.5464 21.936 12.5111L20.3193 12.4074C19.7682 12.3721 19.35 11.8966 19.3854 11.3455Z"
        />
      </SvgTemplate>
    );
  }
);
export default AnnouncementIcon;
