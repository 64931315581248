import { SubscriptionData } from '../../types/AddSiteAddons';

const sample: SubscriptionData = {
  currency: 'usd',
  recurrence: 'ANNUALLY',
  locale: 'en',
  plan: 'scale',
  comped: false,
  region: 'US',
  existingSubPrice: '18100.00',
  accountID: 27649,
  addonData: [
    {
      id: 'monitoring-site',
      name: 'Site Monitoring',
      price: '50.00',
      quantity: '0',
    },
    {
      id: 'global-edge-security',
      name: 'Global Edge Security',
      price: '1000.00',
      quantity: '0',
    },
  ],
};

export default { sample };
