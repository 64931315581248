import { sites_and_storage_used_path } from 'railsRoutes';
import getCsrfToken from 'componentsDir/users/utils/userUtils';
import BaseApiClient from '../../sites/repositories/BaseApiClient';

export type SitesAndStorageUsedParams = {
  sitesUsed: number;
  sitesMax: number;
  storageUsed: number;
  storageMax: number;
};

class CartClient extends BaseApiClient {
  constructor() {
    super(getCsrfToken());
  }

  public async getSiteAndStorageData(): Promise<SitesAndStorageUsedParams> {
    const response = await this.axiosClient.get(sites_and_storage_used_path());
    return response.data;
  }
}

export default CartClient;
