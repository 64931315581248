import { rest } from 'msw';
import {
  customResponse,
  getQueryParam,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { V1alpha1Domain } from 'platform_one_api_client';
import { SslCertificateResponse, SslCertificatesResponse } from '../types';
import { allSslCertificates } from './sslCertificates';

const handlers = [
  rest.get(
    `*/platform-one/domains/v1alpha1/environments%2F:installName/domains`,
    (req, res, ctx) => {
      const response = {
        domains: [
          {
            domainName: 'test.com',
            networkType: 'LEGACY',
          },
        ] as V1alpha1Domain[],
        totalSize: 1,
      };

      if (hasPageUrlQueryParam('noLegacyDomains')) {
        return customResponse(
          ctx.status(200),
          ctx.json({ ...response, totalSize: 0, domains: [] })
        );
      }

      if (hasPageUrlQueryParam('onlyWpeLegacyDomains')) {
        return customResponse(
          ctx.status(200),
          ctx.json({
            ...response,
            totalSize: 2,
            domains: [
              {
                domainName: 'test.wpengine.com',
                networkType: 'LEGACY',
              },
              {
                domainName: 'test.wpenginepowered.com',
                networkType: 'LEGACY_CDN',
              },
            ],
          })
        );
      }

      if (hasPageUrlQueryParam('notFound')) {
        return customResponse(
          ctx.status(404),
          ctx.json({ code: 5, message: 'no domains found for environment' })
        );
      }

      return customResponse(ctx.status(200), ctx.json(response));
    }
  ),

  rest.get('*/installs/*/ssl_certificates/index_data*', (req, res, ctx) => {
    const { searchParams } = req.url;
    const query = searchParams.get('search[query]') || '';
    const page = parseInt(searchParams.get('page') || '1', 10);
    const pageSize = parseInt(searchParams.get('pageSize') || '15', 10);
    const order = (searchParams.get('direction') || 'asc') as 'asc' | 'desc';
    const orderBy = searchParams.get('column') || ('expiry' as const);

    // Filter certificates based on the search query
    const filteredCertificates = allSslCertificates.filter(
      cert =>
        cert.name.toLowerCase().includes(query.toLowerCase()) ||
        cert.status.toLowerCase().includes(query.toLowerCase()) ||
        cert.type.toLowerCase().includes(query.toLowerCase())
    );

    // Sort the filtered certificates
    filteredCertificates.sort((a, b) => {
      let comparison = 0;
      switch (orderBy) {
        case 'provider':
          comparison = a.type.localeCompare(b.type);
          break;
        case 'status':
          comparison = a.status.localeCompare(b.status);
          break;
        case 'name':
          comparison = a.name.localeCompare(b.name);
          break;
        case 'expiry':
          comparison =
            new Date(a.expiry).getTime() - new Date(b.expiry).getTime();
          break;
        default:
          comparison = 0;
      }
      return order === 'asc' ? comparison : -comparison;
    });

    // Calculate pagination
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedCertificates = filteredCertificates.slice(
      startIndex,
      endIndex
    );

    const response: SslCertificatesResponse = {
      ssl_certificates: paginatedCertificates,
      total: filteredCertificates.length,
    };

    return res(ctx.status(200), ctx.json(response));
  }),

  rest.get('*/installs/*/ssl_certificates/*', (req, res, ctx) => {
    const response: SslCertificateResponse = {
      id: 1,
      name: 'test-cert.com',
      installId: 1,
      secureAllUrls: true,
      domains: [{ id: '1', name: 'test-domain.com' }],
      regexes: [],
      autoRenew: true,
      installName: 'test-install',
    };

    if (hasPageUrlQueryParam('secureSpecificUrls')) {
      const regexes = [
        {
          id: 1,
          domainId: 1,
          domainName: '',
          regex: 'test-regex',
        },
        {
          id: 2,
          domainId: 1,
          domainName: '',
          regex: 'hellos',
        },
      ];
      return customResponse(
        ctx.status(200),
        ctx.json({ ...response, secureAllUrls: false, regexes })
      );
    }

    if (hasPageUrlQueryParam('secureSpecificUrlsNoRegexes')) {
      return customResponse(
        ctx.status(200),
        ctx.json({ ...response, secureAllUrls: false })
      );
    }

    return customResponse(ctx.status(200), ctx.json(response));
  }),

  rest.patch('*/installs/*/ssl_certificates/*', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json({}));
  }),

  rest.post(
    '*/installs/*/ssl_certificates/ssl_third_party/validate.*',
    (req, res, ctx) => {
      const errors = [];
      const withKeyError = getQueryParam('withKeyError');
      if (withKeyError) {
        errors.push({ type: 'key', error: withKeyError });
      }

      const withCertError = getQueryParam('withCertError');
      if (withCertError) {
        errors.push({ type: 'cert', error: withCertError });
      }

      if (errors.length) {
        return customResponse(
          ctx.status(200),
          ctx.json({ valid: false, errors })
        );
      }

      const response = {
        valid: true,
        domains: ['primary_domain.com'],
        issuer: 'issuer',
        valid_from: 'valid_from',
        valid_until: 'valid_until',
        wildcard: false,
        primary_domain: 'primary_domain.com',
      };

      return customResponse(ctx.status(200), ctx.json(response));
    }
  ),

  rest.post(
    '*/installs/*/ssl_certificates/ssl_third_party.*',
    (req, res, ctx) => {
      const withError = getQueryParam('withUploadError');
      if (withError) {
        const response = {
          success: false,
          errors: [{ error: withError }],
        };
        return customResponse(ctx.status(200), ctx.json(response));
      }

      const response = {
        success: true,
      };

      return customResponse(ctx.status(200), ctx.json(response));
    }
  ),
];

export default handlers;
