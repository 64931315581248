import { EnvironmentInfo } from '../../types';

const mockEnvironmentInfo = (
  installName: string
): Partial<EnvironmentInfo> => ({
  allCachesLastClearedAt: null,
  accountWpeId: 123456,
  availableVersions: [],
  canDeleteInstall: true,
  clusterId: 123456,
  customer: {
    customerType: 'customer',
    hostingType: 'shared',
    planType: 'managed-hosting-professional',
  },
  createdAt: 'Apr 6, 2023 09:25 PM UTC',
  dataCenterRegion: 'us-west1',
  displayDomain: 'displayName',
  ecommerce: {
    hasSlowQueryLogs: false,
    showSmartQueryMonitorBanner: false,
    showSmartQueryMonitorBannerDismissPath: '',
    showDynamicPluginLoadingBanner: false,
    dynamicPluginLoadingBannerDismissPath: '',
    showStripeConnectTestModeBanner: false,
    showStripeConnectTestModeBannerDismissPath: '',
    showStripeConnectUpdateRequiredBanner: false,
    showStripeConnectUpdateRequiredBannerDismissPath: '',
    hasStripePreviewFeatureFlag: false,
    multisite: false,
  },
  installEnv: 'production',
  installId: 1006,
  installUuid: '7fde8665-f9c7-4ef2-91a6-bb56128c6a5d',
  installName,
  installDomain: `http://${installName}.wpenginepoweredstaging.com`,
  installPending: false,
  ownerEmails: 'omarlittle@thewire.com',
  primaryDomainName: `${installName}.com`,
  primaryDomainUrl: `http://${installName}.com`,
  region: 'Europe',
  showPrimaryDomain: true,
  sshLogin: {
    text: `${installName}@${installName}.ssh.wpengine.net`,
    copyLink: true,
  },
  technicalContactLink: `/technical_contacts#${installName}`,
  technicalContactName: 'Indiana Jones',
  transferable: false,
  sandbox: false,
  wpAdminURL: `http://${installName}.com/wpadmin`,
  edgeFullPageCacheAdoptionBanner: {
    dismissed: false,
  },
  transferMigrating: false,
});

export default mockEnvironmentInfo;
