import { rest } from 'msw';
import { hasPageUrlQueryParam } from 'mocksDir/utils/browserUtils';
import {
  onboarding_sandbox_sites_path,
  onboarding_sandbox_sites_log_activity_path,
} from 'railsRoutes';
import { MSW_PARAMS } from '../constants';

const onboardingHandlers = [
  rest.get(`*${onboarding_sandbox_sites_path()}*`, (_req, res, ctx) => {
    const queryParams = [
      MSW_PARAMS.FIRST_SANDBOX_CREATED,
      MSW_PARAMS.ONBOARDING_DISMISSED,
    ];

    const resBody = queryParams.reduce(
      (acc, param) => ({
        ...acc,
        [param]: hasPageUrlQueryParam(param, 'true'),
      }),
      {}
    );

    return res(ctx.status(200), ctx.json(resBody));
  }),

  rest.post(
    `*${onboarding_sandbox_sites_log_activity_path()}*`,
    (_req, res, ctx) => {
      return res(ctx.status(200), ctx.json({}));
    }
  ),
];

export default onboardingHandlers;
