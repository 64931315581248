import * as React from 'react';
import { Box, Button, ButtonProps } from '@mui/material';
import {
  borderRadiusMedium,
  gray50,
  gray1100,
  blue600,
  gray1200,
} from 'design-tokens';

interface SideNavActiveProps extends ButtonProps {
  label: string;
}

const SideNavButton: React.FC<SideNavActiveProps> = ({
  sx,
  label,
  title,
  ...props
}) => {
  return (
    <Button
      sx={{
        alignItems: 'center',
        backgroundColor: 'inherit',
        borderRadius: borderRadiusMedium,
        color: gray1100,
        display: 'flex',
        gap: '8px',
        justifyContent: 'flex-start',
        lineHeight: '20px',
        margin: '0 12px',
        minWidth: 'unset',
        padding: '10px 8px',
        whiteSpace: 'nowrap',
        ':focus-visible': {
          outlineColor: blue600,
          outlineWidth: '2px',
          outlineStyle: 'solid',
          outlineOffset: '2px',
          zIndex: '1',
        },
        ':focus-visible, :hover': {
          color: gray1200,
          backgroundColor: gray50,
        },
        '.MuiButton-startIcon, .MuiButton-endIcon': {
          margin: 0,
        },
        '.MuiButton-endIcon': {
          marginLeft: 'auto',
        },
        ...sx,
      }}
      title={title || label}
      variant="text"
      {...props}
    >
      <Box overflow="hidden" textOverflow="ellipsis">
        {label}
      </Box>
    </Button>
  );
};

export default SideNavButton;
