import * as React from 'react';
import { CircularProgress } from '@wpengine/unicorn/components';
import { useQuery } from 'react-query';
import axios from 'axios';
import Green from './Green';
import Red from './Red';
import Yellow from './Yellow';

export const SERVERS = ['North America', 'Europe', 'Asia / Pacific'];
export const DEGRADED = 'degraded-performance';
export const DOWN = 'down';
export const UP = 'up';

type WpEngineStatusResponse = {
  service_name: string;
  service_status: typeof UP | typeof DOWN | typeof DEGRADED;
  maintenance: boolean;
  incidents: boolean;
}[];

const StatusLight = () => {
  const { isLoading, error, data } = useQuery('systemStatus', () =>
    axios.get<WpEngineStatusResponse>('/wpengine_status').then(res => res.data)
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return null;
  }

  const servers =
    (Array.isArray(data) &&
      data.filter(service => SERVERS.includes(service.service_name))) ||
    [];

  if (servers.length === 0) {
    return null;
  }

  if (servers.some(server => server.service_status === DOWN)) {
    return <Red fontSize="small" />;
  }

  if (servers.some(server => server.service_status === DEGRADED)) {
    return <Yellow fontSize="small" />;
  }

  if (servers.every(server => server.service_status === UP)) {
    return <Green fontSize="small" />;
  }

  return null;
};

export default StatusLight;
