import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import {
  siteWithAllInstalls,
  siteWithOnlyDev,
  transferableSite,
  sandboxSite,
  siteWithoutStg,
  siteWithoutPrd,
} from 'componentsDir/AllSites/mocks/data/mockSiteInfo';
import { ENDPOINTS } from 'componentsDir/AllSites/constants';

const getSiteHandlers = [
  // GET relevant to transfer info of given site UUID
  rest.get(`*${ENDPOINTS.GET_SITE}*`, (req, res, ctx) => {
    const siteInfo = {
      site: {
        ...siteWithAllInstalls.site,
        uuid: req.url.searchParams.get('site_uuid'),
      },
    };

    if (hasPageUrlQueryParam('dedicatedTransfer')) {
      return customResponse(
        ctx.status(200),
        ctx.json({ ...siteInfo, accountWpeId: 4 })
      );
    }

    if (hasPageUrlQueryParam('siteWithOnlyDev')) {
      return customResponse(ctx.status(200), ctx.json(siteWithOnlyDev));
    }

    if (hasPageUrlQueryParam('transferable')) {
      return customResponse(ctx.status(200), ctx.json(transferableSite));
    }

    if (hasPageUrlQueryParam('sandbox')) {
      return customResponse(ctx.status(200), ctx.json(sandboxSite));
    }

    if (hasPageUrlQueryParam('siteWithoutStg')) {
      return customResponse(ctx.status(200), ctx.json(siteWithoutStg));
    }

    if (hasPageUrlQueryParam('siteWithoutPrd')) {
      return customResponse(ctx.status(200), ctx.json(siteWithoutPrd));
    }

    return customResponse(ctx.status(200), ctx.json(siteInfo));
  }),
];

export default getSiteHandlers;
