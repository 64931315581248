import React from 'react';
import { Badge, Collapse, Popover } from '@mui/material';
import { ShoppingCartIcon } from '@wpengine/unicorn/icons';
import { CartSummaryContextProvider } from 'componentsDir/shared/providers/Cart/contexts/CartSummaryContext';
import { subscribe } from 'commonsDir/helpers/events';
import useBillingMediator from 'componentsDir/shared/hooks/billingMediator/useBillingMediator';
import useCart from 'componentsDir/shared/providers/Cart/hooks/useCart';
import CartMenu from './components/CartMenu';
import ErrorCart from './components/ErrorCart';
import LoadingCart from './components/LoadingCart';
import EmptyCart from './components/EmptyCart';
import NavIconButton from '../NavIconButton';

const Cart: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const timerRef = React.useRef<NodeJS.Timeout>();
  const menuButtonId = 'top-nav-cart-menu-button';
  const menuId = 'top-nav-cart-menu';

  const {
    billingInfo,
    isLoading: billingLoading,
    isError: billingError,
  } = useBillingMediator();
  const { subscription, subscriptionPlan } = billingInfo;
  const { cart, isLoading: isCartLoading, isError: isCartError } = useCart();
  const { new_plan, selected_addons } = cart;

  const renderDropdown = () => {
    if (billingLoading || isCartLoading) {
      return <LoadingCart />;
    }

    if (!subscription || !subscriptionPlan || billingError || isCartError) {
      return <ErrorCart />;
    }

    if (!new_plan && !selected_addons?.length) {
      return <EmptyCart />;
    }

    return (
      <CartSummaryContextProvider>
        <CartMenu onClose={handleClose} />
      </CartSummaryContextProvider>
    );
  };

  const itemCount = new_plan
    ? 1 + selected_addons?.length
    : selected_addons?.length || 0;

  const clearTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = undefined;
    }
  };

  const startCloseTimer = React.useCallback(() => {
    clearTimer();
    timerRef.current = setTimeout(() => {
      if (!isHovering) {
        setOpen(false);
      }
    }, 3000);
  }, [isHovering]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearTimer();
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
    clearTimer();
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    if (open) {
      startCloseTimer();
    }
  };

  React.useEffect(() => {
    return subscribe('OPEN_CART', () => {
      setOpen(true);
      startCloseTimer();
    });
  }, [startCloseTimer]);

  return (
    <>
      <Collapse in={itemCount > 0} orientation="horizontal">
        <NavIconButton
          active={open}
          aria-controls={open ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          aria-label="Open cart"
          color="default"
          id={menuButtonId}
          title="Cart"
          data-testId="open-cart-button"
          ref={anchorRef}
          onClick={handleClick}
        >
          <Badge
            badgeContent={itemCount}
            invisible={itemCount === 0}
            color="error"
            sx={() => ({
              '.MuiBadge-badge': {
                fontWeight: 700,
                minWidth: 0,
                width: '15px',
                height: '15px',
                padding: 0,
                right: '5px',
              },
            })}
          >
            <ShoppingCartIcon />
          </Badge>
        </NavIconButton>
      </Collapse>
      <Popover
        id={menuId}
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            'aria-labelledby': menuButtonId,
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={theme => ({
          '> .MuiPaper-root': {
            marginTop: theme.spacing(2),
            border: 'none',
            width: '390px',
          },
        })}
      >
        {renderDropdown()}
      </Popover>
    </>
  );
};

export default Cart;
