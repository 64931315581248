import * as React from 'react';
import { colorWhite } from 'design-tokens';
import { Divider, Stack, Slide } from '@mui/material';
import GroupHeading from './GroupHeading';

type SubMenuProps = {
  heading: string;
  onBack: () => void;
  open: boolean;
};

const SubMenu: React.FC<SubMenuProps> = ({
  children,
  heading,
  onBack,
  open,
}) => {
  return (
    <Slide direction="left" in={open} mountOnEnter unmountOnExit>
      <Stack
        bottom={0}
        direction="column"
        left={0}
        padding="0 12px 12px 12px"
        position="absolute"
        right={0}
        sx={{
          background: colorWhite,
          '&:focus-visible': {
            outline: 'none',
          },
        }}
        top={0}
      >
        <GroupHeading title={heading} onBack={onBack} />
        <Divider sx={{ margin: '0px -12px 12px -12px' }} />
        {children}
      </Stack>
    </Slide>
  );
};

export default SubMenu;
