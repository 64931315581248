import { rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { all_caches_cleared_install_cache_dashboard_index_path } from 'railsRoutes';

const allCachesClearedHandlers = [
  rest.post(
    `*${all_caches_cleared_install_cache_dashboard_index_path(':install_id')}*`,
    (_req, _res, ctx) => {
      return customResponse(ctx.status(200), ctx.json({}));
    }
  ),
];

export default allCachesClearedHandlers;
