import FetchAPI from 'commonsDir/services/FetchAPI';
import { useQuery } from 'react-query';
import { cart_path } from 'railsRoutes';
import { Cart } from '../types/types';
import QueryKey from '../apis/QueryKey';
import initialCartState from '../helpers/cart';

const useCart = () => {
  const { data, isLoading, isError } = useQuery(
    QueryKey.GET_CART,
    () => FetchAPI.get(cart_path()),
    { placeholderData: initialCartState }
  );

  const cart: Cart = data || {};

  return { cart, isLoading, isError };
};

export default useCart;
