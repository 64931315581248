/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { rest } from 'msw';
import ssoHandlers from 'componentsDir/singleSignOn/mocks/handlers';
import { customResponse } from 'mocksDir/utils/browserUtils';
import {
  entriCompanyTiersResponse,
  entriSettingsResponse,
  entriValidateDomainResponse,
  mockCreateReport,
  mockGetReport,
} from './data';
import { DomainsRepository } from './domains-repository';

export const domainsRepository = new DomainsRepository(500);
const dsa: any[] = [];
const dsaTry: any = {};
let domainsTry = 0;

export const commonDomainsRefreshHandlers = [
  ...ssoHandlers,

  rest.get('*/entri_connect_jwt', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.delay(300),
      ctx.json({
        applicationId: 'wpengine_dev',
        jwt: 'entri-jwt',
        exp: 123,
      })
    );
  }),

  rest.get('*entri.com/settings', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.delay(300),
      ctx.json(entriSettingsResponse)
    );
  }),

  rest.post('*entri.com/validate/token', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.delay(100),
      ctx.json({
        message: 'JWT token is valid',
      })
    );
  }),

  rest.get('*entri.com/company/tiers', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.delay(300),
      ctx.json(entriCompanyTiersResponse)
    );
  }),

  rest.post('*entri.com/validate/domain', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.delay(300),
      ctx.json(entriValidateDomainResponse)
    );
  }),

  rest.post(
    `*/platform-one/domains/v1alpha1/environments%2F:installName/domains/:domainName:purgeCache`,
    (req, res, ctx) => {
      return customResponse(
        ctx.status(200),
        ctx.delay(700),
        ctx.json({ status: true })
      );
    }
  ),

  rest.post(
    `*/platform-one/domains/v1alpha1/environments%2F:installName%2Fdomains%2F:domainName:triggerValidation`,
    (req, res, ctx) => {
      return customResponse(
        ctx.status(200),
        ctx.delay(700),
        ctx.json({ status: true })
      );
    }
  ),

  rest.patch(
    `*/platform-one/domains/v1alpha1/environments%2F:installName%2Fdomains%2F:domainName`,
    (req, res, ctx) => {
      const updatedFields: any = req.body ?? {};

      const updatedDomain = domainsRepository.updateDomain(
        req.params.domainName as string,
        updatedFields
      );

      return customResponse(
        ctx.status(200),
        ctx.delay(700),
        ctx.json(updatedDomain)
      );
    }
  ),

  rest.get(
    `*/platform-one/domains/v1alpha1/environments%2F:installName%2Fdomains%2F:domainName`,
    (req, res, ctx) => {
      const domain = domainsRepository.getDomain(
        req.params.domainName as string
      );

      return customResponse(ctx.status(200), ctx.delay(700), ctx.json(domain));
    }
  ),

  rest.delete(
    `*/platform-one/domains/v1alpha1/environments%2F:installName%2Fdomains%2F:domainName`,
    (req, res, ctx) => {
      const status = domainsRepository.deleteDomain(
        req.params.domainName as string
      );

      return customResponse(
        ctx.status(200),
        ctx.delay(700),
        ctx.json({
          status,
        })
      );
    }
  ),

  rest.post(
    '*/installs/:install_id/domains/create_status_dsa',
    async (req, res, ctx) => {
      const install_id = req.params.install_id as string;
      const { names } = req.body as any;

      const report = mockCreateReport({
        install_name: install_id,
        names: names as string[],
        install_ip: '1.2.2.1',
      });
      dsa.push({ id: report.id, domains: names });
      dsaTry[report.id] = 0;

      return customResponse(ctx.status(200), ctx.delay(1000), ctx.json(report));
    }
  ),

  rest.get(
    '*/installs/:install_id/domains/get_status_dsa',
    async (req, res, ctx) => {
      const report_id = req.url.searchParams.get('report_id');

      const { id, domains } = dsa.find(r => r.id === report_id);
      const tryCount = dsaTry[id];

      const complete = tryCount >= 1;
      const report = mockGetReport(id, domains, complete);

      if (!complete) {
        dsaTry[id] += 1;
      }

      return customResponse(ctx.status(200), ctx.delay(800), ctx.json(report));
    }
  ),

  rest.post(
    `*/platform-one/domains/v1alpha1/environments%2F:installName/domains`,
    (req, res, ctx) => {
      const newDomain = domainsRepository.createDomain(
        (req.body as any)?.domainName
      );

      return customResponse(
        ctx.status(200),
        ctx.delay(700),
        ctx.json(newDomain)
      );
    }
  ),
];

export const getDomainRefreshDmsHandler = (
  domainRepo: DomainsRepository = domainsRepository
) => {
  return rest.get(
    `*/platform-one/domains/v1alpha1/environments%2F:installName/domains`,
    (req, res, ctx) => {
      const pageSize = req.url.searchParams.get('pageSize');
      const pageToken = req.url.searchParams.get('pageToken');

      const domainsResponse = domainRepo.getDomains(+pageSize, pageToken);

      // const isError = pageToken === '5' && domainsTry < 2;
      // if (isError) {
      //   domainsTry += 1;
      // }

      return customResponse(
        // ctx.status(isError ? 500 : 200),
        ctx.status(200),
        ctx.delay(900),
        // ctx.delay(30 * +pageSize),
        ctx.json(domainsResponse)
      );
    }
  );
};

export const getDomainRedirectHandlers = () => {
  return [
    rest.delete(
      `*/platform-one/domains/v1alpha1/environments%2F:installName/domains/:domainName/redirect`,
      (req, res, ctx) => {
        return customResponse(
          ctx.status(200),
          ctx.delay(700),
          ctx.json({ status: true })
        );
      }
    ),
  ];
};

export const installHandler = [
  rest.get(`*/sites/v1alpha1/environments/:installName`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        name: 'test-install-name',
        account: { number: 1 },
      })
    );
  }),
];

export const entitlementsHandler = [
  rest.get(`*/users/entitlements*`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json([
        {
          name: 'test-install-name',
          plan: 'growth',
          wpe_id: 1,
          entitlements: {
            data: [
              {
                product: 'global-edge-security',
                quantity: 1,
                is_active: true,
                wpe_id: 1,
              },
            ],
            error: false,
          },
        },
      ])
    );
  }),
];

export const orderSslHandler = [
  rest.post('*/installs/:installName/ssl_orders', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.delay(700), ctx.json({}));
  }),
];

export default [
  ...commonDomainsRefreshHandlers,
  getDomainRefreshDmsHandler(),
  ...getDomainRedirectHandlers(),
];
