import { mockedIntegration } from 'componentsDir/shared/repositories/NotifyHubClient/mocks/data/integrations';
import { slackChannel } from 'componentsDir/shared/repositories/NotifyHubClient/mocks/data/channels';
import messages from 'componentsDir/shared/repositories/NotifyHubClient/mocks/data/messages';

export const listSlackPoliciesResponse: NotifyHubSlackPolicyList = {
  nextPageToken: '',
  slackPolicies: [
    {
      id: 'mocked_policy',
      name:
        'accounts/mocked_account_id/slackIntegrations/mocked_integration_id/slackPolicies/mocked_policy',
      accountId: 'mocked_account_id',
      slackIntegration: mockedIntegration,
      channel: slackChannel,
      messagesKeys: [messages.messages[0].key, messages.messages[1].key],
      warnings: [],
    },
  ],
};

export const slackPolicyResponse: NotifyHubSlackPolicy = {
  id: 'mocked_policy',
  name:
    'accounts/mocked_account_id/slackIntegrations/mocked_integration_id/slackPolicies/mocked_policy',
  accountId: 'mocked_account_id',
  slackIntegration: mockedIntegration,
  channel: slackChannel,
  messagesKeys: [messages.messages[0].key, messages.messages[1].key],
  warnings: [],
};

export const listEmailPoliciesResponse: NotifyHubEmailPolicyList = {
  nextPageToken: '',
  emailPolicies: [
    {
      id: 'mocked_policy',
      name: 'accounts/mocked_account_id/emailPolicies/mocked_policy',
      accountId: 'mocked_account_id',
      email: 'mocked@email.com',
      firstName: 'mocked',
      lastName: 'policy',
      technicalContact: false,
      messagesKeys: [messages.messages[0].key, messages.messages[1].key],
      warnings: [],
      policyTarget: 'custom_email',
      isVerified: false,
    },
  ],
  canCreateEmailPolicy: true,
};

export const emailPolicyResponse: NotifyHubEmailPolicy = {
  id: 'mocked_policy',
  name: 'accounts/mocked_account_id/emailPolicies/mocked_policy',
  accountId: 'mocked_account_id',
  email: 'mocked@email.com',
  firstName: 'mocked',
  lastName: 'policy',
  technicalContact: false,
  messagesKeys: [messages.messages[0].key, messages.messages[1].key],
  warnings: [],
  policyTarget: 'custom_email',
  isVerified: false,
};
