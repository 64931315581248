import * as React from 'react';
import { Box } from '@wpengine/unicorn/components';
import { borderRadiusMedium } from 'design-tokens';

const SideNavListStyle: React.FC = props => {
  return (
    <Box
      className="sidenav-list"
      sx={({ palette }) => ({
        backgroundColor: palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        flexShrink: '0',
        height: '100%',
        left: '0',
        scrollbarWidth: 'none',
        top: '0',
        userSelect: 'none',
        width: 'inherit',
        '&::-webkit-scrollbar': {
          width: '0px' /* Remove scrollbar space */,
        },
        '.focusable': {
          borderRadius: borderRadiusMedium,
          border: 'none',
          '&.Mui-focusVisible, &:focus-visible': {
            outline: `2px solid ${palette.primary.main}`,
            color: palette.primary.dark,
          },
        },
      })}
    >
      {props.children}
    </Box>
  );
};

export default SideNavListStyle;
