import {
  Subscription,
  SubscriptionPlansV2,
} from 'commonsDir/types/BillingMediator';

const mockSubscription: Subscription = {
  subscription_id: 'S184932',
  family_id: 'startup',
  plan_name: 'Startup',
  plan_group_id: '1',
  plan_group_order: 2,
  recurrence: 'MONTHLY',
  currency: 'USD',
  region: 'BASE',
  base_price: '30.002',
  total_price: '30.0',
  marketing_json: '{}',
  plan_lines: [
    {
      product: 'cdn',
      description: 'CDN1',
      quantity: 1,
      base_sku: 'cdn',
    },
    {
      product: 'cdnbw',
      description: 'CDN Bandwidth',
      quantity: 50,
      base_sku: 'cdnbw',
    },
    {
      product: 'global-ingress-beta',
      description: 'Network Ingress 2.0 - Beta',
      quantity: 1,
      base_sku: 'global-ingress-beta',
    },
    {
      product: 'local-storage',
      description: 'Local Storage',
      quantity: 10,
      base_sku: 'local-storage',
    },
    {
      product: 'page-performance',
      description: 'Page Performance',
      quantity: 1,
      base_sku: 'page-performance',
    },
    {
      product: 'shared-hosting',
      description: 'Hosting on Shared Architecture',
      quantity: 1,
      base_sku: 'shared-hosting',
    },
    {
      product: 'shared-support',
      description: 'Shared Support',
      quantity: 1,
      base_sku: 'shared-support',
    },
    {
      product: 'sites',
      description: 'Sites',
      quantity: 1,
      base_sku: 'sites',
    },
    {
      product: 'ssh-gateway',
      description: 'SSH Gateway - SSH access to installs via the SSH Gateway',
      quantity: 1,
      base_sku: 'ssh-gateway',
    },
    {
      product: 'studiopress-bundle',
      description: 'StudioPress Bundle',
      quantity: 1,
      base_sku: 'studiopress-bundle',
    },
    {
      product: 'support',
      description: 'Support',
      quantity: 1,
      base_sku: 'support',
    },
    {
      product: 'visitors',
      description: 'Visitors',
      quantity: 25000,
      base_sku: 'visitors',
    },
    {
      product: 'wpe-hosting-account',
      description: 'WPE Hosting Account',
      quantity: 1,
      base_sku: 'wpe-hosting-account',
    },
    {
      product: 'wpe_ssl',
      description: 'WP Engine SSL',
      quantity: 1,
      base_sku: 'wpe_ssl',
    },
  ],
  addons: [
    {
      product: 'page-speed-boost-addon',
      name: 'Page Speed Boost',
      description: 'Page Speed Boost 1 Pack',
      quantity: 1,
      base_sku: 'page-speed-boost',
    },
    {
      product: 'global-edge-security',
      name: 'Global Edge Security',
      description:
        'Global Edge Security - Advanced security with DDoS protection and Web Application Firewall',
      quantity: 1,
      base_sku: 'global-edge-security',
    },
  ],
  can_upgrade_plan: true,
  can_add_addons: true,
};

const mockPlan: SubscriptionPlansV2 = {
  subscription_plan_id: 'startup-20240801',
  subscription_family_id: 'startup',
  plan_name: 'Startup',
  plan_group_order: 2,
  recurrence: 'MONTHLY',
  currency: 'USD',
  region: 'BASE',
  marketing_json: '{}',
  price: '30',
  plan_lines: [
    {
      product: 'level-0-support',
      description: 'Level 0 Support',
      base_sku: 'level-0-support',
    },
    {
      product: 'cdnbw',
      description: 'CDN Bandwidth',
      quantity: 50,
      base_sku: 'cdnbw',
    },
    {
      product: 'cdn',
      description: 'CDN1',
      base_sku: 'cdn',
    },
    {
      product: 'global-ingress-beta',
      description: 'Network Ingress 2.0 - Beta',
      base_sku: 'global-ingress-beta',
    },
    {
      product: 'shared-hosting',
      description: 'Hosting on Shared Architecture',
      base_sku: 'shared-hosting',
    },
    {
      product: 'local-storage',
      description: 'Local Storage',
      quantity: 10,
      base_sku: 'local-storage',
    },
    {
      product: 'page-performance',
      description: 'Page Performance',
      base_sku: 'page-performance',
    },
    {
      product: 'ssh-gateway',
      description: 'SSH Gateway - SSH access to installs via the SSH Gateway',
      base_sku: 'ssh-gateway',
    },
    {
      product: 'sandbox-sites',
      description: 'Sandbox Sites',
      quantity: 2,
      base_sku: 'sandbox-sites',
    },
    {
      product: 'shared-support',
      description: 'Shared Support',
      base_sku: 'shared-support',
    },
    {
      product: 'sites',
      description: 'Sites',
      quantity: 1,
      base_sku: 'sites',
    },
    {
      product: 'support',
      description: 'Support',
      base_sku: 'support',
    },
    {
      product: 'studiopress-bundle',
      description: 'StudioPress Bundle',
      base_sku: 'studiopress-bundle',
    },
    {
      product: 'visitors',
      description: 'Visitors',
      quantity: 25000,
      base_sku: 'visitors',
    },
    {
      product: 'wpe_ssl',
      description: 'WP Engine SSL',
      base_sku: 'wpe_ssl',
    },
    {
      product: 'wpe-hosting-account',
      description: 'WPE Hosting Account',
      base_sku: 'wpe-hosting-account',
    },
  ],
  addons: [
    {
      product: 'enhanced-support',
      name: 'Enhanced Support',
      description: 'Enhanced Support',
      base_sku: 'enhanced-support',
      prices: [
        {
          price: '0',
          quantity: 1,
        },
      ],
      maximum_quantity: 1,
    },
    {
      product: 'genesis-pro',
      name: 'Genesis Pro w/ Hosting Plan -',
      description:
        'Genesis Pro Plan - Create beautiful and effective content faster with block-based page building tools.',
      base_sku: 'genesis-pro',
      prices: [
        {
          price: '30',
          quantity: 1,
        },
      ],
      maximum_quantity: 1,
    },
    {
      product: 'geotarget',
      name: 'GeoTarget',
      description: 'GeoTarget - Location-based personalized content',
      base_sku: 'geotarget',
      prices: [
        {
          price: '15',
          quantity: 1,
        },
      ],
      maximum_quantity: 1,
    },
    {
      product: 'global-edge-security',
      name: 'Global Edge Security',
      description:
        'Global Edge Security - Advanced security with DDoS protection and Web Application Firewall',
      base_sku: 'global-edge-security',
      prices: [
        {
          price: '30',
          quantity: 1,
        },
      ],
      maximum_quantity: 1,
    },
    {
      product: 'page-speed-boost-addon',
      name: 'Page Speed Boost',
      description: 'Page Speed Boost 1 Pack',
      base_sku: 'page-speed-boost',
      prices: [
        {
          price: '450',
          quantity: 100,
        },
        {
          price: '300',
          quantity: 50,
        },
        {
          price: '250',
          quantity: 25,
        },
        {
          price: '175',
          quantity: 15,
        },
        {
          price: '125',
          quantity: 10,
        },
        {
          price: '70',
          quantity: 5,
        },
        {
          price: '45',
          quantity: 3,
        },
        {
          price: '20',
          quantity: 1,
        },
      ],
    },
    {
      product: 'page-speed-boost-bundled',
      name: 'Page Speed Boost 1 Pack Bundled',
      description: 'Page Speed Boost 1 Pack Bundled',
      base_sku: 'page-speed-boost',
      prices: [
        {
          price: '0',
          quantity: 1,
        },
      ],
    },
    {
      product: 'monitoring-site-addon',
      name: 'Site Monitoring',
      description: 'Site Monitoring ',
      base_sku: 'monitoring-site',
      prices: [
        {
          price: '50',
          quantity: 100,
        },
        {
          price: '35',
          quantity: 50,
        },
        {
          price: '25',
          quantity: 25,
        },
        {
          price: '20',
          quantity: 15,
        },
        {
          price: '15',
          quantity: 5,
        },
        {
          price: '5',
          quantity: 1,
        },
      ],
    },
    {
      product: 'multisite',
      name: 'Multi-Site',
      description:
        'Multisite - Run multiple WordPress sites from a single install',
      base_sku: 'multisite',
      prices: [
        {
          price: '30',
          quantity: 1,
        },
      ],
      maximum_quantity: 1,
    },
    {
      product: 'sites-addon',
      name: 'Sites',
      description: 'Sites',
      base_sku: 'sites',
      prices: [
        {
          price: '20',
          quantity: 1,
        },
      ],
      maximum_quantity: 25,
    },
    {
      product: 'smart-plugin-manager-addon',
      name: 'Smart Plugin Manager',
      description: 'Smart Plugin Manager',
      base_sku: 'smart-plugin-manager',
      prices: [
        {
          price: '300',
          quantity: 150,
        },
        {
          price: '250',
          quantity: 100,
        },
        {
          price: '150',
          quantity: 50,
        },
        {
          price: '100',
          quantity: 25,
        },
        {
          price: '75',
          quantity: 15,
        },
        {
          price: '30',
          quantity: 5,
        },
        {
          price: '10',
          quantity: 1,
        },
      ],
    },
    {
      product: 'smart-search',
      name: 'WP Engine Smart Search',
      description: 'WP Engine Smart Search',
      base_sku: 'smart-search',
      prices: [
        {
          price: '15',
          quantity: 1,
        },
      ],
      maximum_quantity: 1,
    },
    {
      product: 'smart-search-bundled',
      name: 'WP Engine Smart Search (Bundled)',
      description: 'WP Engine Smart Search (Bundled)',
      base_sku: 'smart-search',
      prices: [
        {
          price: '0',
          quantity: 1,
        },
      ],
      maximum_quantity: 1,
    },
  ],
};

const mockPlanUpgrade: SubscriptionPlansV2 = {
  subscription_plan_id: 'managed-hosting-professional-20240801',
  subscription_family_id: 'managed-hosting-professional',
  plan_name: 'Professional',
  plan_group_order: 3,
  recurrence: 'MONTHLY',
  currency: 'USD',
  region: 'BASE',
  marketing_json: '{}',
  price: '59',
  plan_lines: [
    {
      product: 'cdnbw',
      description: 'CDN Bandwidth',
      quantity: 150,
      base_sku: 'cdnbw',
    },
    {
      product: 'cdn',
      description: 'CDN1',
      base_sku: 'cdn',
    },
    {
      product: 'global-ingress-beta',
      description: 'Network Ingress 2.0 - Beta',
      base_sku: 'global-ingress-beta',
    },
    {
      product: 'shared-hosting',
      description: 'Hosting on Shared Architecture',
      base_sku: 'shared-hosting',
    },
    {
      product: 'local-storage',
      description: 'Local Storage',
      quantity: 15,
      base_sku: 'local-storage',
    },
    {
      product: 'page-performance',
      description: 'Page Performance',
      base_sku: 'page-performance',
    },
    {
      product: 'phone-support',
      description: 'Phone Support',
      base_sku: 'phone-support',
    },
    {
      product: 'ssh-gateway',
      description: 'SSH Gateway - SSH access to installs via the SSH Gateway',
      base_sku: 'ssh-gateway',
    },
    {
      product: 'sandbox-sites',
      description: 'Sandbox Sites',
      quantity: 3,
      base_sku: 'sandbox-sites',
    },
    {
      product: 'shared-support',
      description: 'Shared Support',
      base_sku: 'shared-support',
    },
    {
      product: 'sites',
      description: 'Sites',
      quantity: 3,
      base_sku: 'sites',
    },
    {
      product: 'support',
      description: 'Support',
      base_sku: 'support',
    },
    {
      product: 'studiopress-bundle',
      description: 'StudioPress Bundle',
      base_sku: 'studiopress-bundle',
    },
    {
      product: 'third-party-ssl',
      description: 'Third Party SSL',
      base_sku: 'third-party-ssl',
    },
    {
      product: 'visitors',
      description: 'Visitors',
      quantity: 75000,
      base_sku: 'visitors',
    },
    {
      product: 'wpe_ssl',
      description: 'WP Engine SSL',
      base_sku: 'wpe_ssl',
    },
    {
      product: 'wpe-hosting-account',
      description: 'WPE Hosting Account',
      base_sku: 'wpe-hosting-account',
    },
  ],
  addons: [
    {
      product: 'enhanced-support',
      name: 'Enhanced Support',
      description: 'Enhanced Support',
      base_sku: 'enhanced-support',
      prices: [
        {
          price: '0',
          quantity: 1,
        },
      ],
      maximum_quantity: 1,
    },
    {
      product: 'genesis-pro',
      name: 'Genesis Pro w/ Hosting Plan -',
      description:
        'Genesis Pro Plan - Create beautiful and effective content faster with block-based page building tools.',
      base_sku: 'genesis-pro',
      prices: [
        {
          price: '30',
          quantity: 1,
        },
      ],
      maximum_quantity: 1,
    },
    {
      product: 'geotarget',
      name: 'GeoTarget',
      description: 'GeoTarget - Location-based personalized content',
      base_sku: 'geotarget',
      prices: [
        {
          price: '22',
          quantity: 1,
        },
      ],
      maximum_quantity: 1,
    },
    {
      product: 'global-edge-security',
      name: 'Global Edge Security',
      description:
        'Global Edge Security - Advanced security with DDoS protection and Web Application Firewall',
      base_sku: 'global-edge-security',
      prices: [
        {
          price: '45',
          quantity: 1,
        },
      ],
      maximum_quantity: 1,
    },
    {
      product: 'multisite',
      name: 'Multi-Site',
      description:
        'Multisite - Run multiple WordPress sites from a single install',
      base_sku: 'multisite',
      prices: [
        {
          price: '30',
          quantity: 1,
        },
      ],
      maximum_quantity: 1,
    },
    {
      product: 'page-speed-boost-addon',
      name: 'Page Speed Boost',
      description: 'Page Speed Boost 1 Pack',
      base_sku: 'page-speed-boost',
      prices: [
        {
          price: '20',
          quantity: 1,
        },
        {
          price: '45',
          quantity: 3,
        },
        {
          price: '70',
          quantity: 5,
        },
        {
          price: '125',
          quantity: 10,
        },
        {
          price: '175',
          quantity: 15,
        },
        {
          price: '250',
          quantity: 25,
        },
        {
          price: '300',
          quantity: 50,
        },
        {
          price: '450',
          quantity: 100,
        },
      ],
    },
    {
      product: 'page-speed-boost-bundled',
      name: 'Page Speed Boost 1 Pack Bundled',
      description: 'Page Speed Boost 1 Pack Bundled',
      base_sku: 'page-speed-boost',
      prices: [
        {
          price: '0',
          quantity: 1,
        },
      ],
    },
    {
      product: 'monitoring-site-addon',
      name: 'Site Monitoring',
      description: 'Site Monitoring ',
      base_sku: 'monitoring-site',
      prices: [
        {
          price: '5',
          quantity: 1,
        },
        {
          price: '15',
          quantity: 5,
        },
        {
          price: '20',
          quantity: 15,
        },
        {
          price: '25',
          quantity: 25,
        },
        {
          price: '35',
          quantity: 50,
        },
        {
          price: '50',
          quantity: 100,
        },
      ],
    },
    {
      product: 'sites-addon',
      name: 'Sites',
      description: 'Sites',
      base_sku: 'sites',
      prices: [
        {
          price: '20',
          quantity: 1,
        },
      ],
      maximum_quantity: 35,
    },
    {
      product: 'smart-plugin-manager-addon',
      name: 'Smart Plugin Manager',
      description: 'Smart Plugin Manager',
      base_sku: 'smart-plugin-manager',
      prices: [
        {
          price: '10',
          quantity: 1,
        },
        {
          price: '30',
          quantity: 5,
        },
        {
          price: '75',
          quantity: 15,
        },
        {
          price: '100',
          quantity: 25,
        },
        {
          price: '150',
          quantity: 50,
        },
        {
          price: '250',
          quantity: 100,
        },
        {
          price: '300',
          quantity: 150,
        },
      ],
    },
    {
      product: 'smart-search',
      name: 'WP Engine Smart Search',
      description: 'WP Engine Smart Search',
      base_sku: 'smart-search',
      prices: [
        {
          price: '25',
          quantity: 1,
        },
      ],
      maximum_quantity: 1,
    },
    {
      product: 'smart-search-bundled',
      name: 'WP Engine Smart Search (Bundled)',
      description: 'WP Engine Smart Search (Bundled)',
      base_sku: 'smart-search',
      prices: [
        {
          price: '0',
          quantity: 1,
        },
      ],
      maximum_quantity: 1,
    },
  ],
};

const mockBillingData = {
  subscription: mockSubscription,
  subscriptionPlan: mockPlan,
};

export { mockBillingData, mockPlan, mockPlanUpgrade, mockSubscription };
