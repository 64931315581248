const wpengineStatusResponse = [
  {
    service_name: 'North America',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Europe',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Asia / Pacific',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'User Portal',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Phone Support',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Chat Support',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Ticket Support',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Local',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Migration Plugin',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Advanced Network',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'APM',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Atlas',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Backups',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'CDN',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Content Performance',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'GIT',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Global Edge Security',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Mail',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'MySQL/PHPMyAdmin',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Page Performance',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'SFTP',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'Smart Plugin Manager',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'SSH Gateway',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'SSL',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
  {
    service_name: 'WPE API',
    service_status: 'up',
    maintenance: false,
    incidents: false,
  },
];

export default wpengineStatusResponse;
