import { ENDPOINTS } from 'componentsDir/ManageSiteTagsPage/constants';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';

const createSitesTagsHandler = [
  rest.post(`*/v1/${ENDPOINTS.CREATE_SITES_TAGS}`, (_req, _res, ctx) => {
    return customResponse(ctx.status(200), ctx.json({}));
  }),
];

export default createSitesTagsHandler;
