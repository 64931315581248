import { PlanSubscription } from 'componentsDir/addons/types';

const mockPremiumQuarter: PlanSubscription = {
  account_id: 12345,
  subscription_plan_id: 'p01',
  subscription_price: 6624.0,
  subscription_plan_name: 'P1',
  plan_base_price: 6624.0,
  current_subscription_price: 6624.0,
  currency: 'EUR',
  locale: 'en-EU',
  selected_plan_name: 'P1',
  selected_plan_id: 'p01',
  selected_plan_price_monthly: 600.0,
  selected_plan_price_annually: 6624.0,
  selected_plan_is_premium: true,
  subscription_recurrence: 'QUARTERLY',
  terms: 'ON_RECEIPT',
  is_premium: true,
  datacenter: 'us',
  period_end: '2024-08-05T10:34:45.000+00:00',
  display_upgrade_site_addon_notice: false,
  can_self_change_to_annual: true,
  v2_update_eligible: false,
  annual_terms_enabled: false,
  plan_features: [
    {
      name: 'Sites',
      value: '999,999,999',
    },
    {
      name: 'Visits / month',
      value: '1,000,000',
    },
    {
      name: 'Extra Storage',
      value: '100 GB',
    },
  ],
  plan_addons: [
    {
      id: 'monitoring-site',
      name: 'Site Monitoring',
      limit: 1000,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 54.8,
      legacy_entitlement: false,
      dropdown_value: '1 monitoring license',
    },
    {
      id: 'smart-plugin-manager',
      name: 'Smart Plugin Manager',
      limit: 1000,
      qty_included: 1,
      additional_qty: 1,
      subscription_qty: 0,
      price: 110.4,
      legacy_entitlement: false,
      dropdown_value: '1 environment license',
    },
    {
      id: 'global-edge-security',
      name: 'Global Edge Security',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 1,
      price: 2760.0,
      legacy_entitlement: false,
    },
    {
      id: 'genesis-pro',
      name: 'Genesis Pro',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 1,
      price: 331.2,
      legacy_entitlement: false,
    },
    {
      id: 'smart-plugin-manager-5-pack',
      name: 'Smart Plugin Manager licenses (5)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 5,
      subscription_qty: 0,
      price: 331.2,
      legacy_entitlement: false,
      dropdown_value: '5 environment licenses',
    },
    {
      id: 'smart-plugin-manager-15-pack',
      name: 'Smart Plugin Manager licenses (15)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 15,
      subscription_qty: 1,
      price: 828.0,
      legacy_entitlement: false,
      dropdown_value: '15 environment licenses',
    },
    {
      id: 'smart-plugin-manager-25-pack',
      name: 'Smart Plugin Manager licenses (25)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 25,
      subscription_qty: 0,
      price: 1104.0,
      legacy_entitlement: false,
      dropdown_value: '25 environment licenses',
    },
    {
      id: 'smart-plugin-manager-50-pack',
      name: 'Smart Plugin Manager licenses (50)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 50,
      subscription_qty: 0,
      price: 1656.0,
      legacy_entitlement: false,
      dropdown_value: '50 environment licenses',
    },
    {
      id: 'smart-plugin-manager-100-pack',
      name: 'Smart Plugin Manager licenses (100)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 100,
      subscription_qty: 0,
      price: 2760.0,
      legacy_entitlement: false,
      dropdown_value: '100 environment licenses',
    },
    {
      id: 'smart-plugin-manager-150-pack',
      name: 'Smart Plugin Manager licenses (150)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 150,
      subscription_qty: 0,
      price: 3312.0,
      legacy_entitlement: false,
      dropdown_value: '150 environment licenses',
    },
    {
      id: 'local-storage',
      name: 'Extra Storage',
      limit: 1100,
      qty_included: 100,
      additional_qty: 250,
      subscription_qty: 0,
      price: 1104.0,
      legacy_entitlement: false,
    },
    {
      id: 'premium-plus-services',
      name: 'Premium Plus',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 22080.0,
      legacy_entitlement: false,
    },
    {
      id: 'monitoring-site-05',
      name: 'Site Monitoring licenses (5)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 5,
      subscription_qty: 1,
      price: 164.39,
      legacy_entitlement: false,
      dropdown_value: '5 monitoring licenses',
    },
    {
      id: 'monitoring-site-15',
      name: 'Site Monitoring licenses (15)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 15,
      subscription_qty: 0,
      price: 219.19,
      legacy_entitlement: false,
      dropdown_value: '15 monitoring licenses',
    },
    {
      id: 'monitoring-site-25',
      name: 'Site Monitoring licenses (25)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 25,
      subscription_qty: 0,
      price: 273.99,
      legacy_entitlement: false,
      dropdown_value: '25 monitoring licenses',
    },
    {
      id: 'monitoring-site-50',
      name: 'Site Monitoring licenses (50)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 50,
      subscription_qty: 0,
      price: 383.59,
      legacy_entitlement: false,
      dropdown_value: '50 monitoring licenses',
    },
    {
      id: 'monitoring-site-100',
      name: 'Site Monitoring licenses (100)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 100,
      subscription_qty: 0,
      price: 547.98,
      legacy_entitlement: false,
      dropdown_value: '100 monitoring licenses',
    },
  ],
};

export default mockPremiumQuarter;
