export interface EntriConnectAuth {
  applicationId: string;
  jwt: string;
  exp: number;
}

export enum EntriRecordType {
  A = 'A',
  CNAME = 'CNAME',
  TXT = 'TXT',
}

export enum EntriTriggerType {
  PreVerify = 'PreVerify',
  PointDns = 'PointDns',
}

export interface EntriDnsRecord {
  type: EntriRecordType;
  host: string;
  value: string;
  ttl: number;
}

export interface EntriConfig {
  applicationId: string;
  token: string;
  prefilledDomain: string;
  dnsRecords: EntriDnsRecord[];
}

export interface EntriConnectCheckDomainResponse {
  provider: string;
  setupType: string;
  cnameFlattening: boolean;
  wildcardSupport: boolean;
  wwwRedirect: boolean;
}

export interface EntriConnectInfo extends EntriConnectCheckDomainResponse {
  providerSupport: boolean;
}
