import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const SSHGateWay: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="SSHGateWay logo" viewBox="0 0 64 64" {...props}>
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_793_825)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 41C31 40.4477 31.4477 40 32 40H43C43.5523 40 44 40.4477 44 41C44 41.5523 43.5523 42 43 42H32C31.4477 42 31 41.5523 31 41Z"
        fill="#006BD6"
      />
      <path
        d="M21.616 42.3231C22.3804 43.0875 23.6196 43.0875 24.3839 42.3231L29.3639 37.3431C30.145 36.5621 30.145 35.2958 29.3639 34.5147L24.3941 29.5449C23.6241 28.7749 22.3758 28.7749 21.6058 29.5449C20.8287 30.322 20.837 31.5844 21.6242 32.3513L24.9064 35.5485C25.106 35.743 25.1081 36.0631 24.911 36.2602L21.616 39.5552C20.8517 40.3196 20.8517 41.5588 21.616 42.3231Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 48H49C50.6569 48 52 46.6569 52 45L52 19C52 17.3431 50.6568 16 49 16H15C13.3431 16 12 17.3431 12 19V45C12 46.6569 13.3431 48 15 48ZM49 46H15C14.4477 46 14 45.5523 14 45V26H50V45C50 45.5523 49.5523 46 49 46ZM17 21C17 20.4477 17.4477 20 18 20H20C20.5523 20 21 20.4477 21 21C21 21.5523 20.5523 22 20 22H18C17.4477 22 17 21.5523 17 21ZM24.1539 20C23.6016 20 23.1539 20.4477 23.1539 21C23.1539 21.5523 23.6016 22 24.1539 22H26.1539C26.7062 22 27.1539 21.5523 27.1539 21C27.1539 20.4477 26.7062 20 26.1539 20H24.1539ZM38 21C38 20.4477 38.4477 20 39 20H46C46.5523 20 47 20.4477 47 21C47 21.5523 46.5523 22 46 22H39C38.4477 22 38 21.5523 38 21Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_793_825"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default SSHGateWay;
