import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const UsersIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="users-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 8C3 5.23858 5.23858 3 8 3C8.43009 3 8.84889 3.05453 9.24926 3.15758C9.78411 3.29524 10.1061 3.84042 9.96844 4.37528C9.83078 4.91013 9.28559 5.23212 8.75074 5.09445C8.51187 5.03297 8.26051 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11C8.55228 11 9 11.4477 9 12C9 12.5523 8.55228 13 8 13C6.59095 13 5.3793 13.6009 4.49519 14.7386C3.72938 15.7241 3.18302 17.1519 3.03818 19H6C6.55228 19 7 19.4477 7 20C7 20.5523 6.55228 21 6 21H2C1.44772 21 1 20.5523 1 20C1 17.3393 1.67762 15.1049 2.91598 13.5114C3.45369 12.8194 4.09003 12.2589 4.80276 11.8443C3.70117 10.9272 3 9.54547 3 8ZM16 5C14.3431 5 13 6.34315 13 8C13 9.65685 14.3431 11 16 11C17.6569 11 19 9.65685 19 8C19 6.34315 17.6569 5 16 5ZM19.1972 11.8443C20.2988 10.9272 21 9.54547 21 8C21 5.23858 18.7614 3 16 3C13.2386 3 11 5.23858 11 8C11 9.54547 11.7012 10.9272 12.8028 11.8443C12.09 12.2589 11.4537 12.8194 10.916 13.5114C9.67762 15.1049 9 17.3393 9 20C9 20.5523 9.44772 21 10 21H22C22.5523 21 23 20.5523 23 20C23 17.3393 22.3224 15.1049 21.084 13.5114C20.5463 12.8194 19.91 12.2589 19.1972 11.8443ZM16 13C14.591 13 13.3793 13.6009 12.4952 14.7386C11.7294 15.7241 11.183 17.1519 11.0382 19H20.9618C20.817 17.1519 20.2706 15.7241 19.5048 14.7386C18.6207 13.6009 17.409 13 16 13Z"
        />
      </SvgTemplate>
    );
  }
);
export default UsersIcon;
