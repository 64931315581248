import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const StepperNineFilledIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="stepper-nine-filled-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM14 13.5C14 14.5774 13.6439 15.1702 13.2782 15.5019C12.886 15.8576 12.3858 16 11.9999 16C11.5339 16 10.9031 15.7839 10.4872 15.226C10.1572 14.7831 9.53068 14.6917 9.08785 15.0217C8.64502 15.3518 8.55359 15.9783 8.88362 16.4211C9.73536 17.5639 11.007 18 11.9999 18C12.8141 18 13.8139 17.716 14.6217 16.9834C15.456 16.2268 16 15.0697 16 13.5L15.9999 10C15.9999 7.83041 14.2161 6 12 6C9.78381 6 8 7.8304 8 10C8 12.1696 9.78385 14 12 14C12.7296 14 13.4123 13.8016 14 13.458L14 13.5ZM12 12C13.0975 12 13.9999 11.0792 13.9999 10C13.9999 8.92088 13.0975 8 12 8C10.9024 8 10 8.92087 10 10C10 11.0791 10.9024 12 12 12Z"
        />
      </SvgTemplate>
    );
  }
);
export default StepperNineFilledIcon;
