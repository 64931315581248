import useCart from '../hooks/useCart';
import useUpdateCart from '../hooks/useUpdateCart';
import { AddonAction } from '../types/types';

const useUpdateAddon = () => {
  const { cart } = useCart();
  const { mutate } = useUpdateCart();

  return (addonAction: AddonAction) => {
    const newAddons = [...cart.selected_addons];
    const updatedAddonIndex = newAddons.findIndex(
      addon => addon.base_sku === addonAction.base_sku
    );

    if (addonAction.quantity > 0) {
      newAddons[updatedAddonIndex].quantity = addonAction.quantity;
    } else {
      newAddons.splice(updatedAddonIndex, 1);
    }

    mutate({ ...cart, selected_addons: newAddons });
  };
};

export default useUpdateAddon;
