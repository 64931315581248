import React from 'react';
import { Box, Typography } from '@wpengine/unicorn/components';

const EmptyCart = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={6}>
      <Typography variant="h3">Your cart is empty!</Typography>
    </Box>
  );
};

export default EmptyCart;
