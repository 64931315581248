import * as React from 'react';
import { NavItem } from '@wpengine/unicorn/components';
import StatusLight from './StatusLight';

type StatusEnum = 'Left' | 'Right';

interface SystemStatusProps {
  position?: StatusEnum;
}

const SystemStatus = ({ position = 'Right' }: SystemStatusProps) => {
  return (
    <NavItem
      endIcon={position === 'Right' && <StatusLight />}
      href="https://wpenginestatus.com/"
      size="small"
      startIcon={position === 'Left' && <StatusLight />}
    >
      System status
    </NavItem>
  );
};

export default SystemStatus;
