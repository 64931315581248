import { styled } from '@mui/material';
import { Box } from '@wpengine/unicorn/components';

const StyledBox = styled(Box)({
  display: 'flex',
  position: 'relative',
  padding: '4px',
});

export default StyledBox;
