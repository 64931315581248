/* eslint-disable import/prefer-default-export */
import {
  GetAllTemplatesResponse,
  GetAllRulesResponse,
  GetPagesResponse,
  GetPluginsResponse,
} from '../types';

export const getTemplate: GetAllTemplatesResponse = {
  enabled: true,
  templates: [
    {
      id: '00000000-0000-0000-0000-000000000001',
      name: 'Default WooCommerce template',
      enabled: true,
      default: true,
      createdBy: 'system',
      updatedBy: 'system',
      rules: [
        {
          id: '00000000-0000-0000-0000-000000000003',
          enabled: true,
          conditions: [
            {
              id: 'f67b0d0b-f14f-4e77-b096-8327edff19c5',
              label: 'Label for woo.*',
              type: 'postContentBlock',
              operator: 'withoutRegex',
              value: 'woo.*',
            },
          ],
          actions: [
            {
              id: 'acf7eba2-dbaa-4d93-bcac-c75c08b723e8',
              label: 'Label for woo.*',
              type: 'assetsFromPlugin',
              operator: 'withRegex',
              value: 'woo.*',
            },
          ],
        },
      ],
    },
  ],
};

export const getRules: GetAllRulesResponse = {
  enabled: true,
  status: 'Ready',
  rules: [
    {
      id: '00000000-0000-0000-0000-000000000003',
      enabled: true,
      conditions: [
        {
          id: 'f67b0d0b-f14f-4e77-b096-8327edff19c5',
          label: 'woo.*',
          type: 'postContentBlock',
          operator: 'withoutRegex',
          value: 'woo.*',
        },
      ],
      actions: [
        {
          id: 'acf7eba2-dbaa-4d93-bcac-c75c08b723e8',
          label: 'woo.*',
          type: 'assetsFromPlugin',
          operator: 'withRegex',
          value: 'woo.*',
        },
      ],
    },
    {
      id: '94abeb72-95e4-489c-88c2-0b08fe660cb3',
      enabled: true,
      conditions: [
        {
          id: 'ce9f43d8-d019-4192-b0b1-932c52d5fedc',
          type: 'post',
          operator: 'equals',
          value: '1',
          label: 'About us',
        },
        {
          id: 'ce9f43d8-d019-4192-b0b1-932c52d5fedc',
          type: 'post',
          operator: 'equals',
          value: '23',
          label: 'Contact us',
        },
        {
          id: 'ce9f43d8-d019-4192-b0b1-932c52d5fedc',
          type: 'post',
          operator: 'equals',
          value: '42',
          label: 'Terms of service',
        },
        {
          id: 'ce9f43d8-d019-4192-b0b1-932c52d5fedc',
          type: 'post',
          operator: 'equals',
          value: '2',
          label: 'Privacy policy',
        },
        {
          id: 'ce9f43d8-d019-4192-b0b1-932c52d5fedc',
          type: 'post',
          operator: 'equals',
          value: '5',
          label: 'Our history',
        },
      ],
      actions: [
        {
          id: '6fa4243a-aefd-4431-bd2d-da4804e18ace',
          type: 'plugin',
          operator: 'equals',
          value: 'woocommerce',
          label: 'WooCommerce',
        },
        {
          id: 'bf8d56a9-57a2-47d8-9e4f-44361859c3cd',
          type: 'plugin',
          operator: 'equals',
          value: 'woocommerce-gateway-stripe',
          label: 'WooCommerce Stripe Gateway',
        },
        {
          id: '4c27edbb-d486-4f25-b1ff-c2fc37bc3310',
          type: 'plugin',
          operator: 'equals',
          value: 'woocommerce-payments',
          label: 'WooPayments: Integrated WooCommerce Payments',
        },
        {
          id: '9726077d-8a98-46f0-939f-9faf4b6b30b6',
          type: 'plugin',
          operator: 'equals',
          value: 'woocommerce-paypal-payments',
          label: 'WooCommerce PayPal Payments',
        },
        {
          id: 'da3e4d2c-c2cd-4381-95b1-c7ef6a5a1a6d',
          type: 'plugin',
          operator: 'equals',
          value: 'woocommerce-gateway-stripe',
          label: 'WooCommerce Stripe Gateway',
        },
        {
          id: '515c9f3e-1fd8-4fb9-a404-e91be2a369b9',
          type: 'plugin',
          operator: 'equals',
          value: 'woocommerce-services',
          label: 'WooCommerce Shipping & Tax',
        },
        {
          id: '77028c7e-978f-4fa9-8720-5ec6945b5e76',
          type: 'plugin',
          operator: 'equals',
          value: 'woocommerce-square',
          label: 'WooCommerce Square',
        },
      ],
      note: 'Default MSW note 1',
    },
    {
      id: '0ba6b2d4-15c5-4d52-a8c2-388f9d91fdc0',
      enabled: true,
      conditions: [
        {
          id: '4c374327-f647-4181-92a6-11890367f61e',
          type: 'post',
          operator: 'equals',
          value: '2',
          label: 'Contact us',
        },
      ],
      actions: [
        {
          id: 'c09e6cdf-93f0-419e-a312-ffd7d3073e97',
          type: 'plugin',
          operator: 'equals',
          value: 'bloated-plugin-2',
          label: 'Bloated Plugin 2',
        },
      ],
      note: 'Default MSW note 2',
    },
  ],
};

export const previewSite = {
  url: 'test-site-4.local',
};

export const getPages: GetPagesResponse = {
  pages: [
    {
      id: '77',
      title: {
        rendered: 'Test page 1499',
      },
      link: 'https://ls07021344stg.wpenginepoweredstaging.com/test-page-1499/',
      status: 'publish',
      type: 'post',
    },
    {
      id: '75',
      title: {
        rendered: 'Test Page 1449',
      },
      link: 'https://ls07021344stg.wpenginepoweredstaging.com/test-page-1449/',
      status: 'publish',
      type: 'post',
    },
    {
      id: '73',
      title: {
        rendered: 'Test Page KLM',
      },
      link: 'https://ls07021344stg.wpenginepoweredstaging.com/test-page-klm/',
      status: 'publish',
      type: 'post',
    },
    {
      id: '72',
      title: {
        rendered: 'Test Page HIJ',
      },
      link: 'https://ls07021344stg.wpenginepoweredstaging.com/test-page-hij/',
      status: 'publish',
      type: 'post',
    },
    {
      id: '71',
      title: {
        rendered: 'Test Page DEF',
      },
      link: 'https://ls07021344stg.wpenginepoweredstaging.com/test-page-def/',
      status: 'publish',
      type: 'post',
    },
    {
      id: '70',
      title: {
        rendered: 'Test Page ABC',
      },
      link: 'https://ls07021344stg.wpenginepoweredstaging.com/test-page-abc/',
      status: 'publish',
      type: 'post',
    },
    {
      id: '57',
      title: {
        rendered: 'Page That is not 1434',
      },
      link: 'https://ls07021344stg.wpenginepoweredstaging.com/about/',
      status: 'publish',
      type: 'post',
    },
    {
      id: '55',
      title: {
        rendered: 'Home',
      },
      link: 'https://ls07021344stg.wpenginepoweredstaging.com/',
      status: 'publish',
      type: 'post',
    },
    {
      id: '9',
      title: {
        rendered: 'Refund and Returns Policy',
      },
      link: 'https://ls07021344stg.wpenginepoweredstaging.com/?page_id=9',
      status: 'draft',
      type: 'post',
    },
  ],
};

export const getPlugins: GetPluginsResponse = {
  plugins: [
    {
      plugin: 'disable-json-api/disable-json-api',
      status: 'active',
      name: 'Disable REST API',
      plugin_uri: 'http://www.binarytemplar.com/disable-json-api',
      author: 'Dave McHale',
      author_uri: 'http://www.binarytemplar.com',
      version: '1.8',
      textdomain: 'disable-json-api',
    },
    {
      plugin: 'wpe-configuration/wpe-configuration',
      status: 'active',
      name: 'eCommerce Configuration By WP Engine',
      plugin_uri: 'https://wpengine.com/',
      author: 'WP Engine',
      author_uri: 'https://wpengine.com/',
      version: '0.2.24',
      textdomain: 'wpe-configuration',
    },
    {
      plugin: 'woocommerce/woocommerce',
      status: 'active',
      name: 'WooCommerce',
      plugin_uri: 'https://woocommerce.com/',
      author: 'Automattic',
      author_uri: 'https://woocommerce.com',
      version: '9.3.1',
      textdomain: 'woocommerce',
    },
    {
      plugin: 'woocommerce-gateway-stripe/woocommerce-gateway-stripe',
      status: 'active',
      name: 'WooCommerce Stripe Gateway',
      plugin_uri: 'https://wordpress.org/plugins/woocommerce-gateway-stripe/',
      author: 'WooCommerce',
      author_uri: 'https://woocommerce.com/',
      version: '8.6.1',
      textdomain: 'woocommerce-gateway-stripe',
    },
    {
      plugin: 'wp-phpmyadmin-extension/index',
      status: 'active',
      name: 'WP phpMyAdmin',
      plugin_uri:
        'https://puvox.software/software/wordpress-plugins/?plugin=wp-phpmyadmin-extension',
      author: 'Puvox.software',
      author_uri: 'https://puvox.software/',
      version: '5.2.1.13',
      textdomain: 'wp-phpmyadmin-extension',
    },
  ],
};
