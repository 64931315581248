import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import useEffectAfterMount from './useEffectAfterMount';
import OwnershipCookieName from './OwnershipCookieName';

export const eventName = 'show_ownership'; // export needed for testing

export const useOwnershipToggleDispatcher = (isToggledOn: boolean) => {
  const cookies = new Cookies();
  useEffectAfterMount(() => {
    cookies.set(OwnershipCookieName, isToggledOn, { path: '/' });
    document.dispatchEvent(new CustomEvent(eventName, { detail: isToggledOn }));
  }, [isToggledOn]);
};

export const useOwnershipToggleListener = () => {
  const cookies = new Cookies();

  const [isVisible, setIsVisible] = useState(
    cookies.get(OwnershipCookieName) === 'true'
  );

  const showOwnershipToggled = (e: CustomEvent): void => {
    setIsVisible(e.detail);
  };

  useEffect(() => {
    document.addEventListener(eventName, showOwnershipToggled);
    return () => document.removeEventListener(eventName, showOwnershipToggled);
  }, []);
  return { isVisible };
};

export default useOwnershipToggleDispatcher;
