import {
  Activation,
  ActivationsGetResponse,
} from 'componentsDir/shared/repositories/ActivationsPrivateAPIClient/types';

export const mockActivations: ActivationsGetResponse = {
  activations: [
    {
      uuid: '11111111-2f15-4c84-8a4a-8833745232e7',
      productInstanceId: '24680',
      productNamespace: 'uma-newrelic',
      status: 'ACTIVE',
      context: {
        installId: '24680',
        productId: 'uma-site-monitoring',
        installDomain: 'https://seedenv.wpengine.com',
        monitorId: 'test-uuid-monitor-id-seedenv',
      },
      createdOn: '2022-04-06T10:37:16.306824Z',
      updatedOn: '2022-05-16T11:02:37.001204Z',
    },
    {
      uuid: '22222222-2f15-4c84-8a4a-8833745232e7',
      productInstanceId: '13579',
      productNamespace: 'uma-newrelic',
      status: 'ACTIVE',
      context: {
        installId: '13579',
        productId: 'uma-site-monitoring',
        installDomain: 'https://seedenv2.wpengine.com',
        monitorId: 'test-uuid-monitor-id-seedenv2',
      },
      createdOn: '2022-04-06T10:37:16.306824Z',
      updatedOn: '2022-05-16T11:02:37.001204Z',
    },
    {
      uuid: '33333333-2f15-4c84-8a4a-8833745232e7',
      productInstanceId: '12345',
      productNamespace: 'uma-newrelic',
      status: 'ACTIVE',
      context: {
        installId: '12345',
        productId: 'uma-site-monitoring',
        installDomain: 'https://seedenv3.wpengine.com',
        monitorId: 'test-uuid-monitor-id-seedenv3',
      },
      createdOn: '2022-04-06T10:37:16.306824Z',
      updatedOn: '2022-05-16T11:02:37.001204Z',
    },
    {
      uuid: '44444444-2f15-4c84-8a4a-8833745232e7',
      productInstanceId: '44444444',
      productNamespace: 'uma-newrelic',
      status: 'SUSPENDED',
      context: {
        installId: '44444444',
        productId: 'uma-site-monitoring',
      },
      createdOn: '2022-04-06T10:37:16.306824Z',
      updatedOn: '2022-05-16T11:02:37.001204Z',
    },
    {
      uuid: '55555555-2f15-4c84-8a4a-8833745232e7',
      productInstanceId: '55555555',
      productNamespace: 'uma-newrelic',
      status: 'FAILED_TO_DEACTIVATE',
      context: {
        installId: '55555555',
        productId: 'uma-site-monitoring',
      },
      createdOn: '2022-04-06T10:37:16.306824Z',
      updatedOn: '2022-05-16T11:02:37.001204Z',
    },
    {
      uuid: '66666666-2f15-4c84-8a4a-8833745232e7',
      productInstanceId: '1006',
      productNamespace: 'uma-newrelic',
      status: 'INACTIVE',
      context: {
        installId: '66666666',
        productId: 'uma-site-monitoring',
      },
      createdOn: '2022-04-06T10:37:16.306824Z',
      updatedOn: '2022-05-16T11:02:37.001204Z',
    },
  ],
};

export const mockEmptyActivations: ActivationsGetResponse = {
  activations: [],
};

export const getMockedActivation = (
  activation?: Partial<Activation>
): Activation => ({
  uuid: '66666666-2f15-4c84-8a4a-8833745232e7',
  productInstanceId: '1006',
  productNamespace: 'uma-newrelic',
  status: 'ACTIVE',
  context: {},
  createdOn: '2022-04-06T10:37:16.306824Z',
  updatedOn: '2022-05-16T11:02:37.001204Z',
  ...activation,
});
