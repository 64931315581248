interface Responses {
  [key: string]: { error: string };
}

const responses: Responses = {
  missingSiteId: {
    error: 'siteId is a required parameter.',
  },
  missingAccountWpeId: {
    error: 'accountWpeId is a required parameter.',
  },
  unauthorized: {
    error: 'You are not authorized to create this resource.',
  },
};

export default responses;
