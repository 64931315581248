import * as React from 'react';
import { Switch, SwitchProps } from '@mui/material';

const Toggle = React.forwardRef<HTMLInputElement, SwitchProps>((props, ref) => {
  const { checked, onChange, ...other } = props;

  const actionKeyPress = (evt: any): void => {
    if (evt.key === 'Enter' && onChange) {
      onChange(evt, !checked);
    }
  };

  return (
    <Switch
      aria-checked={checked}
      checked={checked}
      onKeyUp={(evt): void => actionKeyPress(evt)}
      onChange={onChange}
      inputRef={ref}
      {...other}
      size="medium"
    />
  );
});

export default Toggle;
