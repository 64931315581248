import { RoleType, UserEntitlements } from 'componentsDir/shared/types';
import { ACCOUNTS } from 'mocksDir/shared/constants';

interface Responses {
  [key: string]: UserEntitlements[];
}

interface Samples {
  supportModalData: UserEntitlements[];
  dedicatedOwner: UserEntitlements;
  dedicatedPartialWithoutBilling: UserEntitlements;
  sharedOwnerOverLimit: UserEntitlements;
  sharedOwnerWithinLimit: UserEntitlements;
  sharedPartialWithoutBillingOverLimit: UserEntitlements;
  dedicatedOwnerWithSameName: UserEntitlements;
  incorrectUserData: UserEntitlements[];
}

export const samples: Samples = {
  supportModalData: [
    {
      name: 'Account 0',
      plan: 'growth',
      entitlements: {
        data: [
          {
            product: 'sites',
            quantity: 100,
            is_active: true,
          },
          {
            product: 'shared-hosting',
            quantity: 1,
            is_active: true,
          },
        ],
        error: false,
      },
      role: 'PARTIAL_WITHOUT_BILLING',
      canAccessAllSites: false,
      wpe_id: 1,
      sites_used: 10,
    },
    {
      name: 'Account 1',
      plan: 'growth',
      entitlements: {
        data: [
          {
            product: 'sites',
            quantity: 200,
            is_active: true,
          },
          {
            product: 'shared-hosting',
            quantity: 1,
            is_active: false,
          },
        ],
        error: false,
      },
      role: 'FULL_WITHOUT_BILLING',
      canAccessAllSites: true,
      wpe_id: 2,
      sites_used: 100,
    },
    {
      name: 'Account 2',
      plan: 'growth',
      entitlements: {
        data: [
          {
            product: 'sites',
            quantity: ACCOUNTS.DEDICATED.SITES_LIMIT,
            is_active: true,
          },
          {
            product: 'dedicated-environment',
            quantity: 1,
            is_active: false,
          },
        ],
        error: false,
      },
      role: 'OWNER',
      canAccessAllSites: true,
      wpe_id: 3,
      sites_used: 5,
    },
  ],
  incorrectUserData: [
    {
      name: '',
      plan: '',
      entitlements: {
        data: [],
        error: true,
      },
      role: '' as RoleType,
      canAccessAllSites: false,
      wpe_id: 1,
      sites_used: 1,
    },
    {
      name: '',
      plan: 'growth',
      entitlements: {
        data: [
          {
            product: 'sites',
            quantity: ACCOUNTS.DEDICATED.SITES_LIMIT,
            is_active: true,
          },
          {
            product: 'dedicated-environment',
            quantity: 1,
            is_active: false,
          },
        ],
        error: false,
      },
      role: 'FULL_WITHOUT_BILLING',
      canAccessAllSites: true,
      wpe_id: 2,
      sites_used: -1,
    },
    {
      name: 'account3',
      plan: 'growth',
      entitlements: {
        data: [
          {
            product: 'sites',
            quantity: ACCOUNTS.DEDICATED.SITES_LIMIT,
            is_active: true,
          },
          {
            product: 'shared-hosting',
            quantity: 1,
            is_active: false,
          },
        ],
        error: false,
      },
      role: 'OWNER',
      canAccessAllSites: true,
      wpe_id: 3,
      sites_used: ('' as unknown) as number,
    },
  ],
  dedicatedOwner: {
    name: 'Account 0',
    plan: 'growth',
    entitlements: {
      data: [
        {
          product: 'sites',
          quantity: ACCOUNTS.DEDICATED.SITES_LIMIT,
          is_active: true,
        },
        {
          product: 'dedicated-environment',
          quantity: 1,
          is_active: true,
        },
      ],
      error: false,
    },
    role: 'OWNER',
    canAccessAllSites: true,
    wpe_id: 1,
    sites_used: 10,
  },
  dedicatedPartialWithoutBilling: {
    name: 'Account 1',
    plan: 'growth',
    entitlements: {
      data: [
        {
          product: 'sites',
          quantity: ACCOUNTS.DEDICATED.SITES_LIMIT,
          is_active: true,
        },
        {
          product: 'dedicated-environment',
          quantity: 1,
          is_active: true,
        },
      ],
      error: false,
    },
    role: 'PARTIAL_WITHOUT_BILLING',
    canAccessAllSites: false,
    wpe_id: 2,
    sites_used: 10,
  },
  sharedOwnerOverLimit: {
    name: 'Account 2',
    plan: 'growth',
    entitlements: {
      data: [
        {
          product: 'sites',
          quantity: 10,
          is_active: true,
        },
        {
          product: 'shared-hosting',
          quantity: 1,
          is_active: true,
        },
        {
          product: 'monitoring-site',
          quantity: 10,
          is_active: true,
        },
      ],
      error: false,
    },
    role: 'OWNER',
    canAccessAllSites: true,
    wpe_id: 3,
    sites_used: 10,
  },
  sharedOwnerWithinLimit: {
    name: 'Account 3',
    plan: 'growth',
    entitlements: {
      data: [
        {
          product: 'sites',
          quantity: 100,
          is_active: true,
        },
        {
          product: 'shared-hosting',
          quantity: 1,
          is_active: true,
        },
        {
          product: 'ecommerce',
          quantity: 1,
          is_active: true,
        },
        {
          product: 'monitoring-site',
          quantity: 10,
          is_active: true,
        },
      ],
      error: false,
    },
    role: 'OWNER',
    canAccessAllSites: true,
    wpe_id: 4,
    sites_used: 20,
  },
  sharedPartialWithoutBillingOverLimit: {
    name: 'Account 4',
    plan: 'growth',
    entitlements: {
      data: [
        {
          product: 'sites',
          quantity: 10,
          is_active: true,
        },
        {
          product: 'shared-hosting',
          quantity: 1,
          is_active: true,
        },
        {
          product: 'monitoring-site',
          quantity: 10,
          is_active: true,
        },
      ],
      error: false,
    },
    role: 'PARTIAL_WITHOUT_BILLING',
    canAccessAllSites: false,
    wpe_id: 5,
    sites_used: 11,
  },
  dedicatedOwnerWithSameName: {
    name: 'Account 0',
    plan: 'growth',
    entitlements: {
      data: [
        {
          product: 'sites',
          quantity: ACCOUNTS.DEDICATED.SITES_LIMIT,
          is_active: true,
        },
        {
          product: 'dedicated-environment',
          quantity: 1,
          is_active: true,
        },
      ],
      error: false,
    },
    role: 'OWNER',
    canAccessAllSites: true,
    wpe_id: 6,
    sites_used: 10,
  },
};

const responses: Responses = {
  diverseAccounts: [
    { ...samples.sharedOwnerWithinLimit, wpe_id: 1 },
    { ...samples.sharedOwnerOverLimit, wpe_id: 2 },
    { ...samples.sharedPartialWithoutBillingOverLimit, wpe_id: 3 },
    { ...samples.dedicatedOwner, wpe_id: 4 },
    { ...samples.dedicatedPartialWithoutBilling, wpe_id: 5 },
  ],
  moreThanTenAccounts: [
    { ...samples.sharedOwnerWithinLimit, wpe_id: 1 },
    { ...samples.sharedOwnerOverLimit, wpe_id: 2 },
    { ...samples.sharedPartialWithoutBillingOverLimit, wpe_id: 3 },
    { ...samples.dedicatedOwner, wpe_id: 4 },
    { ...samples.dedicatedPartialWithoutBilling, wpe_id: 5 },
    { ...samples.sharedOwnerWithinLimit, wpe_id: 6, name: 'Account 6' },
    { ...samples.sharedOwnerOverLimit, wpe_id: 7, name: 'Account 7' },
    {
      ...samples.sharedPartialWithoutBillingOverLimit,
      wpe_id: 8,
      name: 'Account 8',
    },
    { ...samples.dedicatedOwner, wpe_id: 9, name: 'Account 9' },
    {
      ...samples.dedicatedPartialWithoutBilling,
      wpe_id: 10,
      name: 'Account 10',
    },
    {
      ...samples.dedicatedPartialWithoutBilling,
      wpe_id: 11,
      name: 'Account 11',
    },
  ],
  identicalNames: [
    { ...samples.sharedOwnerWithinLimit, wpe_id: 1 },
    { ...samples.sharedOwnerOverLimit, wpe_id: 2 },
    { ...samples.sharedPartialWithoutBillingOverLimit, wpe_id: 3 },
    { ...samples.dedicatedOwner, wpe_id: 4 },
    { ...samples.dedicatedPartialWithoutBilling, wpe_id: 5 },
    { ...samples.sharedOwnerWithinLimit, wpe_id: 6, name: 'Account 6' },
    { ...samples.sharedOwnerOverLimit, wpe_id: 7, name: 'Account 7' },
    {
      ...samples.sharedPartialWithoutBillingOverLimit,
      wpe_id: 8,
      name: 'Account 8',
    },
    { ...samples.dedicatedOwner, wpe_id: 9, name: 'Account 9' },
    {
      ...samples.dedicatedPartialWithoutBilling,
      wpe_id: 10,
      name: 'Account 10',
    },
    {
      ...samples.dedicatedPartialWithoutBilling,
      wpe_id: 11,
      name: 'Account 11',
    },
    {
      ...samples.dedicatedOwnerWithSameName,
      wpe_id: 12,
    },
  ],
  multiplePartialAccounts: [
    { ...samples.dedicatedPartialWithoutBilling, wpe_id: 1 },
    { ...samples.sharedPartialWithoutBillingOverLimit, wpe_id: 2 },
  ],
  singleAccount: [{ ...samples.dedicatedOwner, wpe_id: 1 }],
};

export default responses;
