import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import classNames from 'classnames';
import { darkModeManualInvertClass } from '../components/DarkMode';

export interface LogoTemplateProps extends SvgIconProps {
  disabled?: Boolean;
  size?: '64' | '48' | '128';
}

const LogoTemplate = React.forwardRef<SVGSVGElement, LogoTemplateProps>(
  (props, ref) => {
    const {
      name,
      children,
      className = '',
      size = '48',
      sx,
      disabled = false,
      ...other
    } = props;

    return (
      <SvgIcon
        aria-label={name}
        data-testid={name}
        ref={ref}
        className={classNames(darkModeManualInvertClass, className)}
        sx={[
          {
            ...(disabled && {
              filter: 'opacity(.4) grayscale(1)',
            }),
          },
          { fontSize: `${size}px` },
          // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...other}
      >
        {children}
      </SvgIcon>
    );
  }
);
export default LogoTemplate;
