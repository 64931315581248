import { hasPageUrlQueryParam } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import { ENDPOINTS, MSW_PARAMS } from '../constants';

const handlers = [
  rest.get(`*${ENDPOINTS.GET_ALL_SITES_CSV}*`, (req, res, ctx) => {
    if (hasPageUrlQueryParam(MSW_PARAMS.CSV_ERROR)) {
      return res(ctx.status(500), ctx.json({}));
    }

    return res(
      ctx.status(200),
      ctx.text('Account name,Site name\nneild2142,My Yurty Site')
    );
  }),
];

export default handlers;
