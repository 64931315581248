export function capitalize(input: string): string {
  if (input.length === 0) {
    return '';
  }

  return input[0].toUpperCase() + input.slice(1);
}

export function splitToWords(input: string): string[] {
  return input
    .split(/(?=[A-Z])|[^a-zA-Z0-9.,\s]+/)
    .filter((word: string) => word.length > 0)
    .map((word: string) => word.toLowerCase());
}

export function toCamelCase(input: string): string {
  const words = splitToWords(input);

  if (words.length < 2) {
    return input;
  }

  return [words[0], ...words.slice(1).map(word => capitalize(word))].join('');
}

export function toSnakeCase(input: string): string {
  const words = splitToWords(input);
  if (words.length < 2) {
    return input;
  }

  return words.join('_');
}
