import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const LiveCart: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="LiveCart logo" viewBox="0 0 64 64" {...props}>
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_793_841)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5 48C32.3284 48 33 47.3284 33 46.5C33 45.6716 32.3284 45 31.5 45C30.6716 45 30 45.6716 30 46.5C30 47.3284 30.6716 48 31.5 48ZM31.5 50C33.433 50 35 48.433 35 46.5C35 44.567 33.433 43 31.5 43C29.567 43 28 44.567 28 46.5C28 48.433 29.567 50 31.5 50Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 48C22.3284 48 23 47.3284 23 46.5C23 45.6716 22.3284 45 21.5 45C20.6716 45 20 45.6716 20 46.5C20 47.3284 20.6716 48 21.5 48ZM21.5 50C23.433 50 25 48.433 25 46.5C25 44.567 23.433 43 21.5 43C19.567 43 18 44.567 18 46.5C18 48.433 19.567 50 21.5 50Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2252 22.3679C14.4151 22.1351 14.6996 22 15 22H38C38.3004 22 38.5849 22.1351 38.7749 22.3679C38.9648 22.6006 39.04 22.9065 38.9796 23.2008L36.1101 36.2008C36.0147 36.666 35.6053 37 35.1304 37H17.8696C17.3947 37 16.9853 36.666 16.8899 36.2008C16.8899 36.2008 14.0807 23.4951 14.0204 23.2008C13.9601 22.9065 14.0352 22.6006 14.2252 22.3679ZM19 32C19 31.4477 19.4477 31 20 31H26C26.5523 31 27 31.4477 27 32C27 32.5523 26.5523 33 26 33H20C19.4477 33 19 32.5523 19 32ZM29 31C28.4477 31 28 31.4477 28 32C28 32.5523 28.4477 33 29 33H31C31.5523 33 32 32.5523 32 32C32 31.4477 31.5523 31 31 31H29ZM31 27C31 26.4477 31.4477 26 32 26H34C34.5523 26 35 26.4477 35 27C35 27.5523 34.5523 28 34 28H32C31.4477 28 31 27.5523 31 27ZM19 26C18.4477 26 18 26.4477 18 27C18 27.5523 18.4477 28 19 28L29 28C29.5523 28 30 27.5523 30 27C30 26.4477 29.5523 26 29 26L19 26Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 27C44 26.4477 44.4477 26 45 26H51C51.5523 26 52 26.4477 52 27C52 27.5523 51.5523 28 51 28H45C44.4477 28 44 27.5523 44 27Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43 32C43 31.4477 43.4477 31 44 31H49C49.5523 31 50 31.4477 50 32C50 32.5523 49.5523 33 49 33H44C43.4477 33 43 32.5523 43 32Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42 37C42 36.4477 42.4477 36 43 36H46C46.5523 36 47 36.4477 47 37C47 37.5523 46.5523 38 46 38H43C42.4477 38 42 37.5523 42 37Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.9589 21.2859C44.152 20.7228 44.4001 20.4448 44.655 20.2855C44.9348 20.1106 45.3489 20 46 20H48C48.5523 20 49 19.5523 49 19C49 18.4477 48.5523 18 48 18H46C45.1511 18 44.3152 18.1394 43.595 18.5895C42.8544 19.0523 42.3568 19.7673 42.0513 20.6838C42.0441 20.7053 42.0377 20.7271 42.032 20.749L38.532 34.249C38.3934 34.7836 38.7144 35.3294 39.249 35.468C39.7836 35.6066 40.3294 35.2856 40.468 34.751L43.9589 21.2859ZM38.6961 36.5194C39.2377 36.6277 39.5889 37.1546 39.4806 37.6961L39.4803 37.6977L39.4799 37.6995C39.4685 37.7521 39.4565 37.8044 39.4431 37.8565C39.4205 37.9443 39.3867 38.0646 39.3393 38.2069C39.2457 38.4878 39.0931 38.8718 38.8575 39.2645C38.3921 40.0401 37.497 41 36 41H18.998C18.447 41 18 41.4464 18 42C18 42.5523 17.5523 43 17 43C16.4477 43 16 42.5523 16 42C16 40.3445 17.3398 39 18.998 39H36C36.503 39 36.8579 38.7099 37.1425 38.2355C37.2819 38.0032 37.3793 37.7622 37.4419 37.5744C37.4727 37.4822 37.4936 37.4072 37.5061 37.3584C37.5124 37.3341 37.5165 37.3166 37.5186 37.307L37.5203 37.2997L37.5199 37.3014L37.5197 37.3025L37.5203 37.2997C37.6307 36.7608 38.1561 36.4114 38.6961 36.5194Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_793_841"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default LiveCart;
