import { DefaultRequestBody, PathParams, rest } from 'msw';
import {
  addDelay,
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import {
  CreatePluginIntegration,
  CreatePluginIntegrationsPayload,
  CreatePluginIntegrationsResponse,
  FailedPluginIntegration,
  GetPluginIntegrationsResponse,
  PatchPluginIntegration,
  PatchPluginIntegrationsPayload,
  PluginIntegration,
} from 'componentsDir/shared/repositories/PluginIntegrationApiClient/types';
import INTEGRATIONS_ENDPOINT from 'componentsDir/shared/repositories/PluginIntegrationApiClient/constants';
import { chunk } from 'lodash';
import {
  allIntegrations,
  emptyIntegrations,
} from '../data/mockPluginIntegrations';

let currentIntegrations = allIntegrations;

export const getCurrentIntegrations = () => {
  return currentIntegrations;
};

export const resetCurrentIntegrations = () => {
  currentIntegrations = allIntegrations;
};

const activationTime = 5_000;

const finishActivations = (integrations: PluginIntegration[]) => {
  const integrationsUuids = integrations.map(
    integration => integration.activationUuid
  );
  currentIntegrations.integrations.forEach((integration, idx) => {
    if (integrationsUuids.includes(integration.activationUuid)) {
      // eslint-disable-next-line no-param-reassign
      integration.state = idx % 3 === 0 ? 'FAILED_TO_ACTIVATE' : 'ACTIVE';
    }
  });
};

const handlers = [
  rest.get<DefaultRequestBody, PathParams, GetPluginIntegrationsResponse>(
    `*${INTEGRATIONS_ENDPOINT}`,
    async (req, res, ctx) => {
      // Usage example: http://localhost:3000/products/page_speed_boost/enable?selected=envname1&empty=true
      if (hasPageUrlQueryParam('empty')) {
        return customResponse(ctx.status(200), ctx.json(emptyIntegrations));
      }

      return customResponse(ctx.status(200), ctx.json(allIntegrations));
    }
  ),
  rest.post<
    CreatePluginIntegrationsPayload,
    PathParams,
    CreatePluginIntegrationsResponse
  >(`*${INTEGRATIONS_ENDPOINT}\\:batchCreate`, async (req, res, ctx) => {
    // Usage example: http://localhost:3000/products/page_speed_boost/enable?selected=envname1&enableError=true
    if (hasPageUrlQueryParam('enableError')) {
      return customResponse(ctx.status(500), addDelay(1000));
    }

    const requestedIntegrations: CreatePluginIntegration[] = req.body.requests;

    const successfulIntegrations: PluginIntegration[] = requestedIntegrations.map(
      request => ({
        installName: request.integration.installName,
        name: `integrations/${request.integration.installName}`,
        activationUuid: `uuid-${request.integration.installName}`,
        lastError: 'NO_ERROR',
        lastErrorDetails: '',
        lastErrorPrettyMessage: '',
        lastUpdateTime: '2023-11-29T12:24:00',
        nitropack: {
          licensedUri: `${request.integration.installName}.wpengine.com`,
          safeMode: 'DISABLED',
          envType: 'PRD',
          setting: 'SETTING_UNSPECIFIED',
        },
        state: 'NEW',
        type: request.integration.type,
      })
    );

    // We use the same list for failed, but only only use some in our responses.
    const failedIntegrations: FailedPluginIntegration[] = requestedIntegrations.map(
      request => ({
        installName: request.integration.installName,
        status: {
          code: 404,
          message: `Environment not found - gone fishin'`,
        },
      })
    );

    // Usage example: http://localhost:3000/products/page_speed_boost/enable?selected=envname1,envname2,envname3&enableSomeFailed=true
    if (hasPageUrlQueryParam('enableSomeFailed')) {
      // Only add the successful integration to the existing list.
      // Assume we have 3 selected environments.
      currentIntegrations.integrations = [
        ...currentIntegrations.integrations,
        successfulIntegrations[0],
      ];

      return customResponse(
        ctx.status(200),
        ctx.json({
          integrations: [successfulIntegrations[0]],
          failedIntegrations: [failedIntegrations[1], failedIntegrations[2]],
        })
      );
    }

    // Usage example: http://localhost:3000/products/page_speed_boost/enable?selected=envname1,envname2,envname3&enableAllFailed=true
    if (hasPageUrlQueryParam('enableAllFailed')) {
      return customResponse(
        ctx.status(200),
        ctx.json({
          integrations: [],
          failedIntegrations,
        })
      );
    }

    // All successful - update the existing list.
    currentIntegrations.integrations = [
      ...currentIntegrations.integrations,
      ...successfulIntegrations,
    ];

    setTimeout(() => {
      finishActivations(currentIntegrations.integrations);
    }, activationTime);

    const responseBody: CreatePluginIntegrationsResponse = {
      integrations: successfulIntegrations,
      failedIntegrations: [],
    };

    return customResponse(
      ctx.status(200),
      ctx.json(responseBody),
      addDelay(1000)
    );
  }),
  rest.post<
    PatchPluginIntegrationsPayload,
    PathParams,
    CreatePluginIntegrationsResponse
  >(`*${INTEGRATIONS_ENDPOINT}\\:batchUpdate`, async (req, res, ctx) => {
    // Usage example: http://localhost:3000/products/page_speed_boost/integrations:batchUpdate
    if (hasPageUrlQueryParam('enableError')) {
      return customResponse(ctx.status(500), addDelay(1000));
    }

    const requestedIntegrations: PatchPluginIntegration[] = req.body.requests;

    const successfulIntegrations = requestedIntegrations.map(
      pluginIntegration => {
        const matchingCurrentIntegration = currentIntegrations.integrations.find(
          i => i.name === pluginIntegration.integration.name
        );
        return {
          ...matchingCurrentIntegration,
          ...pluginIntegration,
          nitropack: {
            ...matchingCurrentIntegration.nitropack,
            ...pluginIntegration.integration.nitropack,
          },
        };
      }
    );

    const failedIntegrations = requestedIntegrations.map(pluginIntegration => {
      return {
        name: pluginIntegration.integration.name,
        status: {
          code: 404,
          message: `Environment not found - gone fishin'`,
        },
      };
    });

    const requestedIntegrationNames = requestedIntegrations.map(
      i => i.integration.name
    );
    // Usage example: http://localhost:3000/products/page_speed_boost/integrations&enableSomeFailed=true
    if (hasPageUrlQueryParam('enableSomeFailed')) {
      const [successInstallNames, failedInstallNames] = chunk(
        requestedIntegrationNames,
        Math.ceil(requestedIntegrationNames.length / 2)
      );
      const unpatchedPluginIntegrations = currentIntegrations.integrations.filter(
        i => !successInstallNames.includes(i.name)
      );
      const patchedPluginIntegrations = successfulIntegrations.filter(i =>
        successInstallNames.includes(i.name)
      );
      currentIntegrations.integrations = [
        ...unpatchedPluginIntegrations,
        ...patchedPluginIntegrations,
      ];

      const responseFailedIntegrations = failedIntegrations.filter(i =>
        failedInstallNames.includes(i.name)
      );

      return customResponse(
        ctx.status(200),
        ctx.json({
          integrations: patchedPluginIntegrations,
          failedIntegrations: responseFailedIntegrations,
        })
      );
    }

    // Usage example: http://localhost:3000/products/page_speed_boost/integrations&enableAllFailed=true
    if (hasPageUrlQueryParam('enableAllFailed')) {
      return customResponse(
        ctx.status(200),
        ctx.json({
          integrations: [],
          failedIntegrations,
        })
      );
    }

    const successInstallNames = requestedIntegrationNames; // all request installs were successful
    const unpatchedPluginIntegrations = currentIntegrations.integrations.filter(
      i => !successInstallNames.includes(i.name)
    );
    const patchedPluginIntegrations = successfulIntegrations.filter(i =>
      successInstallNames.includes(i.name)
    );

    // All successful - update the existing list.
    currentIntegrations.integrations = [
      ...unpatchedPluginIntegrations,
      ...patchedPluginIntegrations,
    ];

    const responseBody: CreatePluginIntegrationsResponse = {
      integrations: successfulIntegrations,
      failedIntegrations: [],
    };

    return customResponse(
      ctx.status(200),
      ctx.json(responseBody),
      addDelay(1000)
    );
  }),

  rest.delete<
    PluginIntegration['activationUuid'],
    PathParams,
    PluginIntegration
  >(`*${INTEGRATIONS_ENDPOINT}/:activationUuid`, async (req, res, ctx) => {
    if (hasPageUrlQueryParam('releaseError')) {
      return customResponse(ctx.status(500));
    }
    return customResponse(ctx.status(200));
  }),
];

export default handlers;
