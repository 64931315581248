import { RollbarContext, useRollbar } from '@rollbar/react';
import * as React from 'react';

/**
 * sets RollbarContext if the component is within a RollbarProvider
 * @param children
 * @param context - the context that will be send to rollbar
 * @constructor
 */
const ConditionalRollbarContext: React.FC<{
  context: string;
}> = ({ children, context }) => {
  let isWithinRollbarProvider;
  try {
    const rollbar = useRollbar();
    isWithinRollbarProvider = !!rollbar;
  } catch (e) {
    isWithinRollbarProvider = false;
  }

  if (isWithinRollbarProvider) {
    return <RollbarContext context={context}>{children}</RollbarContext>;
  }

  return <>{children}</>;
};

export default ConditionalRollbarContext;
