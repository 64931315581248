import { ServerSiteNote } from './types';

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

const mockSiteNotes: ServerSiteNote[] = [
  {
    id: 100,
    content:
      "This is a note on a site and it can have multiple lines\nLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    userUpdatedAt: yesterday,
    lastUpdatedByUserId: 123,
    siteId: 1,
    accountWpeId: 101,
  },
];

export default mockSiteNotes;
