import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const DashboardIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="dashboard-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5973 7.53987C11.4401 7.00875 12 6.06976 12 5C12 3.34315 10.6569 2 9 2C7.34315 2 6 3.34315 6 5C6 5.8719 6.37195 6.65692 6.96584 7.20506L4.19841 12.0065C4.13282 12.0022 4.06667 12 4 12C2.34315 12 1 13.3431 1 15C1 16.6569 2.34315 18 4 18C5.65685 18 7 16.6569 7 15C7 14.1339 6.633 13.3536 6.04603 12.806L8.68942 8.21975L13.2549 16.5595C12.4951 17.1038 12 17.9941 12 19C12 20.6569 13.3431 22 15 22C16.6569 22 18 20.6569 18 19C18 18.1339 17.633 17.3536 17.046 16.8059L19.8192 11.9946C19.879 11.9982 19.9393 12 20 12C21.6569 12 23 10.6569 23 9C23 7.34315 21.6569 6 20 6C18.3431 6 17 7.34315 17 9C17 9.8719 17.3719 10.6569 17.9658 11.2051L15.2158 15.9763L10.5973 7.53987ZM10 5C10 5.55228 9.55228 6 9 6C8.44772 6 8 5.55228 8 5C8 4.44772 8.44772 4 9 4C9.55228 4 10 4.44772 10 5ZM4 16C4.55228 16 5 15.5523 5 15C5 14.4477 4.55228 14 4 14C3.44772 14 3 14.4477 3 15C3 15.5523 3.44772 16 4 16ZM15 20C15.5523 20 16 19.5523 16 19C16 18.4477 15.5523 18 15 18C14.4477 18 14 18.4477 14 19C14 19.5523 14.4477 20 15 20ZM20 10C20.5523 10 21 9.55228 21 9C21 8.44772 20.5523 8 20 8C19.4477 8 19 8.44772 19 9C19 9.55228 19.4477 10 20 10Z"
        />
      </SvgTemplate>
    );
  }
);
export default DashboardIcon;
