import {
  V1alpha1Domain,
  V1alpha1Environment,
  V1alpha1NetworkType,
} from 'platform_one_api_client';

const wpengineDomains = [
  'wpengine.com',
  'wpenginedev.com',
  'wpenginestaging.com',
  'wpenginepowered.com',
  'wpenginepowereddev.com',
  'wpenginepoweredstaging.com',
];

const DEFAULT_WP_ENVIRONMENT_NAME = 'install0';
const DEFAULT_DOMAIN = 'wpenginepoweredstaging.com';
const DEFAULT_NETWORK_TYPE = V1alpha1NetworkType.An;
const A_RECORDS = ['104.16.140.134', '104.16.140.135'];
const NETWORK_TYPES = [
  V1alpha1NetworkType.An,
  V1alpha1NetworkType.Ges,
  V1alpha1NetworkType.Legacy,
];

export const generateSampleDomain = ({
  wpEnvironmentName = DEFAULT_WP_ENVIRONMENT_NAME,
  networkType = DEFAULT_NETWORK_TYPE,
  domain = DEFAULT_DOMAIN,
  ...restData
}: Partial<V1alpha1Domain> & {
  wpEnvironmentName?: string;
  edgeFullPageCacheEnabled?: boolean;
  domain?: string;
} = {}): V1alpha1Domain => {
  const domainName = `${wpEnvironmentName}.${domain}`;

  const baseDomain = {
    domainName,
    wpEnvironment: {
      name: wpEnvironmentName,
    },
    ...restData,
  };

  return {
    ...baseDomain,
    createTime: '2024-02-13T09:07:52Z',
    updateTime: '2024-02-13T09:07:52Z',
    networkType,
    wpEnvironment: {
      name: wpEnvironmentName,
    },
    networkDetails: {
      dnsConfigInfo: {
        cname: `cname.${domainName}`,
        aRecords: A_RECORDS,
      },
    },
  };
};

export const generateSampleDomains = ({
  wpEnvironmentName = DEFAULT_WP_ENVIRONMENT_NAME,
  networkType = DEFAULT_NETWORK_TYPE,
  domain = DEFAULT_DOMAIN,
  numberOfDomains = 10,
  ...restData
}: Partial<V1alpha1Domain> & {
  wpEnvironmentName?: string;
  edgeFullPageCacheEnabled?: boolean;
  domain?: string;
  numberOfDomains?: number;
} = {}): { domains: V1alpha1Domain[] } => {
  const customDomainIndexStartsAt = 5;

  const generatedDomains = Array.from(
    { length: numberOfDomains },
    (_, index) => {
      const props = {
        wpEnvironmentName,
        networkType,
        domain,
        ...restData,
      };

      if (index === 0) {
        props.primary = true;
      } else {
        props.networkType = NETWORK_TYPES[index % NETWORK_TYPES.length];
      }

      if (domain === DEFAULT_DOMAIN) {
        if (index > customDomainIndexStartsAt) {
          props.domain = `customdomain.com${index}`;
        } else {
          props.domain = wpengineDomains[index % wpengineDomains.length];
        }
      } else if (index !== 0) {
        props.domain = `${domain}${index}`;
      }

      return generateSampleDomain(props);
    }
  );

  return {
    domains: generatedDomains,
  };
};

export const generateSampleInstall = ({
  name = DEFAULT_WP_ENVIRONMENT_NAME,
  type = 'PRODUCTION',
  domain = DEFAULT_DOMAIN,
  ...restData
}: Partial<V1alpha1Environment> & {
  domain?: string;
} = {}): V1alpha1Environment => {
  const domainName = `${name}.${domain}`;

  return {
    account: {
      name: 'sample1',
      number: 12345678,
    },
    name,
    createTime: '2024-02-13T09:07:48Z',
    state: 'ACTIVE',
    phpVersion: {
      active: '8.2',
      available: ['8.2', '7.4'],
    },
    primaryDomain: domainName,
    htaccessEnabled: false,
    type,
    sandbox: false,
    ...restData,
  };
};
