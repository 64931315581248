import React from 'react';
import { useCartSummaryContext } from 'componentsDir/shared/providers/Cart/contexts/CartSummaryContext';
import Addon from './Addon';

const Addons: React.FC = () => {
  const { newAddons } = useCartSummaryContext();

  if (!newAddons.length) {
    return null;
  }

  const renderAddons = () => {
    return newAddons.map((addon, index) => {
      const border = index < newAddons.length - 1;

      return <Addon key={addon.base_sku} border={border} addon={addon} />;
    });
  };

  return <>{renderAddons()}</>;
};

export default Addons;
