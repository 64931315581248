import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { install_rewrite_path, install_rewrites_path } from 'railsRoutes';
import { Rewrite } from '../types';

const handlers = [
  rest.post<Rewrite>(install_rewrites_path('*'), (req, res, ctx) => {
    if (hasPageUrlQueryParam('enableError')) {
      return customResponse(ctx.status(500));
    }

    return res(
      ctx.status(200),
      ctx.json({
        ...req.body,
      })
    );
  }),
  rest.delete(install_rewrite_path('*', '*'), (req, res, ctx) => {
    if (hasPageUrlQueryParam('enableError')) {
      return customResponse(ctx.status(500));
    }

    return res(ctx.status(200));
  }),
];

export default handlers;
