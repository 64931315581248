import rawSpmEnvironments, {
  EnvironmentsResponse,
} from 'componentsDir/SPM/mocks/data/rawSpmEnvironments';
import { rest } from 'msw';
import { ENDPOINTS } from 'componentsDir/AllSites/constants';
import { customResponse } from 'mocksDir/utils/browserUtils';

const handlers = [
  rest.get(`*${ENDPOINTS.GET_ALL_SITES_BY_INSTALLS}`, (req, res, ctx) => {
    const installNames = req?.url?.searchParams
      ?.get('installNames')
      ?.split(',');
    return customResponse(
      ctx.status(200),
      ctx.json(generateGetAllSitesByInstallsResponse(installNames))
    );
  }),
];

export default handlers;

const generateGetAllSitesByInstallsResponse = (installNames: string[]) => {
  const correspondingSpmEnvironments = rawSpmEnvironments.filter(environment =>
    installNames.includes(environment.provider_metadata.install_name)
  );
  return { sites: correspondingSpmEnvironments.map(generateSdsSite) };
};

const generateSdsSite = (environment: EnvironmentsResponse) => {
  const {
    install_name,
    environment_type,
    site_id,
    account_name,
    account_id,
  } = environment.provider_metadata;
  return {
    account: {
      name: account_name,
      role: 'FULL_WITHOUT_BILLING',
      wpeId: account_id,
    },
    name: install_name,
    siteId: site_id,
    installs: [
      {
        name: install_name,
        siteEnv: environment_type,
        domainName: `http://${install_name}.wpenginedev.com`,
      },
    ],
    transferable: false,
    sandbox: false,
  };
};
