import {
  about_products_global_edge_security_path,
  about_products_smart_search_path,
  about_site_monitors_path,
  atlas_path,
  ecommerce_path,
  ecommerce_performance_path,
  ecommerce_solution_path,
  headless_solution_path,
  plans_path,
  products_application_performance_about_path,
  products_genesis_blocks_pro_path,
  products_genesis_custom_blocks_pro_path,
  products_global_edge_security_path,
  products_instant_store_search_path,
  products_page_speed_boost_about_path,
  products_page_speed_boost_path,
  products_smart_plugin_manager_path,
  products_smart_search_ai_about_path,
  products_smart_search_path,
  site_monitors_path,
  themes_path,
} from 'railsRoutes';
import { DiscoveryHubItem } from '../types';

export const mockYourProducts: DiscoveryHubItem[] = [
  {
    id: 'applicationperformance',
    actionLink: products_application_performance_about_path(),
    manageLink: 'https://one.newrelic.com/',
    owned: true,
    show: true,
  },
  {
    id: 'studiopressthemes',
    actionLink: themes_path(),
    manageLink: themes_path(),
    owned: true,
  },
  {
    id: 'iss',
    manageLink: products_instant_store_search_path(),
    owned: true,
  },
  {
    id: 'ges',
    show: true,
    actionLink: about_products_global_edge_security_path(),
    manageLink: products_global_edge_security_path(),
    owned: true,
    showQuickBuy: true,
    subscriptionLocked: false,
  },
  {
    id: 'pagespeedboost',
    show: true,
    actionLink: products_page_speed_boost_about_path(),
    manageLink: products_page_speed_boost_path(),
    owned: true,
    showQuickBuy: true,
    subscriptionLocked: false,
  },
  {
    id: 'sitemonitoring',
    show: true,
    actionLink: about_site_monitors_path(),
    manageLink: site_monitors_path(),
    owned: true,
    showQuickBuy: true,
    subscriptionLocked: false,
  },
  {
    id: 'spm',
    show: true,
    actionLink: products_smart_plugin_manager_path(),
    manageLink: products_smart_plugin_manager_path(),
    owned: true,
    showQuickBuy: true,
    subscriptionLocked: false,
  },
  {
    id: 'wpsmartsearch',
    show: true,
    actionLink: about_products_smart_search_path(),
    manageLink: products_smart_search_path(),
    owned: true,
    showQuickBuy: true,
    subscriptionLocked: false,
  },
  {
    id: 'genesisblocks',
    actionLink: 'https://wpengine.com/genesis-blocks/',
    manageLink: products_genesis_blocks_pro_path(),
    owned: true,
  },
  {
    id: 'genesiscustomblocks',
    actionLink: 'https://wpengine.com/genesis-custom-blocks/',
    manageLink: products_genesis_custom_blocks_pro_path(),
    owned: true,
  },
  {
    id: 'headlesswordpress',
    actionLink: headless_solution_path(),
    manageLink: atlas_path(),
    owned: true,
  },
  {
    id: 'ecommercesuite',
    actionLink: ecommerce_solution_path(),
    manageLink: ecommerce_path(),
    owned: true,
  },
  {
    id: 'managedwordpress',
    actionLink: plans_path(),
    manageLink: plans_path(),
    owned: false,
  },
  {
    id: 'evercache',
    manageLink: ecommerce_performance_path(),
    owned: true,
  },
  {
    id: 'livecart',
    manageLink: ecommerce_performance_path(),
    owned: true,
  },
  {
    id: 'stripeconnect',
    manageLink: ecommerce_path(),
    owned: true,
  },
];

export const mockYourProductsNoEcomm: DiscoveryHubItem[] = mockYourProducts.filter(
  item =>
    // Show prop is false without ecomm, so builder does not return these
    !(
      item.id === 'iss' ||
      item.id === 'ecommercesuite' ||
      item.id === 'evercache' ||
      item.id === 'livecart' ||
      item.id === 'stripeconnect'
    )
);

export const mockYourProductsMinimal: DiscoveryHubItem[] = mockYourProducts.map(
  item => {
    // StudioPress Themes should always show
    if (item.id === 'studiopressthemes') {
      return item;
    }
    return { ...item, owned: false };
  }
);

export const mockPlatformsAndSpecializedProducts: DiscoveryHubItem[] = [
  {
    id: 'headlesswordpress',
    actionLink: headless_solution_path(),
    manageLink: atlas_path(),
    owned: true,
  },
  {
    id: 'ecommercesuite',
    actionLink: ecommerce_solution_path(),
    manageLink: ecommerce_path(),
    owned: true,
  },
  {
    id: 'managedwordpress',
    actionLink: plans_path(),
    manageLink: plans_path(),
    owned: false,
  },
];

export const mockTools: DiscoveryHubItem[] = [
  {
    id: 'faust',
    actionLink: 'https://faustjs.org/',
    learnMoreLink: '',
  },
  {
    id: 'genesisblocks',
    actionLink: 'https://wpengine.com/genesis-blocks/',
    manageLink: products_genesis_blocks_pro_path(),
    owned: false,
  },
  {
    id: 'genesiscustomblocks',
    actionLink: 'https://wpengine.com/genesis-custom-blocks/',
    manageLink: products_genesis_custom_blocks_pro_path(),
    owned: false,
  },
  {
    id: 'local',
    actionLink: 'https://localwp.com/?modal=download',
    learnMoreLink: 'https://localwp.com/',
  },
];

export const mockProductExtensions: DiscoveryHubItem[] = [
  {
    id: 'applicationperformance',
    actionLink: products_application_performance_about_path(),
    manageLink: 'https://one.newrelic.com/',
    owned: true,
    show: true,
  },
  {
    id: 'ges',
    show: true,
    actionLink: products_global_edge_security_path(),
    manageLink: products_global_edge_security_path(),
    owned: false,
    showQuickBuy: true,
    subscriptionLocked: false,
  },
  {
    id: 'pagespeedboost',
    show: true,
    actionLink: products_page_speed_boost_about_path(),
    manageLink: products_page_speed_boost_path(),
    owned: false,
    showQuickBuy: true,
    subscriptionLocked: false,
  },
  {
    id: 'sitemonitoring',
    show: true,
    actionLink: about_site_monitors_path(),
    manageLink: site_monitors_path(),
    owned: false,
    showQuickBuy: true,
    subscriptionLocked: false,
  },
  {
    id: 'spm',
    show: true,
    actionLink: products_smart_plugin_manager_path(),
    manageLink: products_smart_plugin_manager_path(),
    owned: false,
    showQuickBuy: true,
    subscriptionLocked: false,
  },
  {
    id: 'wpsmartsearch',
    show: true,
    actionLink: about_products_smart_search_path(),
    manageLink: products_smart_search_path(),
    owned: true,
    showQuickBuy: true,
    subscriptionLocked: false,
  },
  {
    id: 'wpsmartsearchai',
    show: true,
    actionLink: products_smart_search_ai_about_path(),
    manageLink: products_smart_search_path(),
    owned: true,
    showQuickBuy: true,
    subscriptionLocked: false,
  },
];

export const mockThemes: DiscoveryHubItem[] = [
  {
    id: 'frost',
    actionLink: 'https://frostwp.com/latest/',
  },
  {
    id: 'studiopressthemes',
    actionLink: themes_path(),
  },
];

export const mockPlugins: DiscoveryHubItem[] = [
  {
    id: 'advancedCustomFields',
    actionLink: 'https://advancedcustomfields.com/latest/',
    learnMoreLink: 'https://www.advancedcustomfields.com',
  },
  {
    id: 'betterSearchReplace',
    actionLink: 'https://bettersearchreplace.com/latest/',
    learnMoreLink: 'https://bettersearchreplace.com/',
  },
  {
    id: 'wpMigrate',
    actionLink: 'https://deliciousbrains.com/wp-migrate-lite-latest/',
    learnMoreLink: 'https://deliciousbrains.com/wp-migrate-db-pro/',
  },
  {
    id: 'wpOffloadMedia',
    actionLink: 'https://deliciousbrains.com/wp-offload-media-lite-latest/',
    learnMoreLink: 'https://deliciousbrains.com/wp-offload-media/',
  },
  {
    id: 'wpOffloadSes',
    actionLink: 'https://deliciousbrains.com/wp-offload-ses-lite-latest/',
    learnMoreLink: 'https://deliciousbrains.com/wp-offload-ses/',
  },
];

export const mockAllData = {
  your_products: mockYourProducts,
  platforms_and_specialized_products: mockPlatformsAndSpecializedProducts,
  product_extensions: mockProductExtensions,
  plugins: mockPlugins,
  tools: mockTools,
  themes: mockThemes,
};

export default {
  mockProductExtensions,
  mockPlatformsAndSpecializedProducts,
  mockTools,
  mockPlugins,
  mockThemes,
  mockYourProducts,
  mockYourProductsNoEcomm,
  mockYourProductsMinimal,
  mockAllData,
};
