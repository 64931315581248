import { StatusCodes } from 'http-status-codes';
import { DefaultRequestBody, rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { mockProvisions } from 'componentsDir/Usage/mocks/data';

const handlers = [
  rest.get<DefaultRequestBody, { accountName: string }>(
    `*accounts/:accountName/provisions`,
    (req, res, ctx) => {
      const { accountName } = req.params;

      return customResponse(
        ctx.status(StatusCodes.OK),
        ctx.json(mockProvisions(accountName))
      );
    }
  ),
];

export default handlers;
