import { rest } from 'msw';
import { responses } from 'componentsDir/AllSites/mocks/data/listSitesByUser';
import { ENDPOINTS, MSW_PARAMS } from 'componentsDir/AllSites/constants';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { setSingleDOMAccount } from 'commonsDir/helpers/domTestHelpers';

const handlers = [
  rest.get(`*${ENDPOINTS.GET_ALL_SITES}`, (req, res, ctx) => {
    if (hasPageUrlQueryParam(MSW_PARAMS.EMPTY_SITES_WP_VERSIONS)) {
      return res(ctx.status(200), ctx.json(responses.emptyWpVersions));
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.SINGLE_ACCOUNT)) {
      setSingleDOMAccount();
      return res(ctx.status(200), ctx.json(responses.singleAccount));
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.SINGLE_PARTIAL_WITH_BILLING_ACCOUNT)) {
      setSingleDOMAccount();
      return res(
        ctx.status(200),
        ctx.json(responses.singlePartialWithBillingAccount)
      );
    }

    if (
      hasPageUrlQueryParam(MSW_PARAMS.SINGLE_PARTIAL_WITHOUT_BILLING_ACCOUNT)
    ) {
      setSingleDOMAccount();
      return res(
        ctx.status(200),
        ctx.json(responses.singlePartialWithoutBillingAccount)
      );
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.SDS_BANNERS_ENABLED)) {
      return res(ctx.status(200), ctx.json(responses.sdsBanners));
    }

    if (
      hasPageUrlQueryParam(MSW_PARAMS.FILTER_ENABLED) &&
      hasPageUrlQueryParam(MSW_PARAMS.EMPTY_FILTER_RESULTS)
    ) {
      return res(ctx.status(200), ctx.json(responses.emptyFilteredResults));
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.FILTER_ENABLED)) {
      return res(ctx.status(200), ctx.json(responses.enoughSitesToFilter));
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.MULTI_PAGE)) {
      if (req.url.searchParams.get('pageToken') === '1') {
        return res(ctx.status(200), ctx.json(responses.secondPage));
      }

      return res(
        ctx.status(200),
        ctx.json({
          ...responses.differentOwners,
          numberOfSites: 6,
          nextPageToken: '1',
        })
      );
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.EMPTY_SITES)) {
      return res(ctx.status(200), ctx.json(responses.emptySites));
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.SANDBOX_SITES)) {
      return res(ctx.status(200), ctx.json(responses.sandboxSites));
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.SINGLE_SITE)) {
      return res(ctx.status(200), ctx.json(responses.singleSite));
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.MULTIPLE_SITES)) {
      return res(ctx.status(200), ctx.json(responses.differentOwners));
    }

    return customResponse(ctx.status(200), ctx.json(responses.differentOwners));
  }),
];

export default handlers;
