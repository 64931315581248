import * as React from 'react';
import {
  Link as MuiLink,
  LinkProps as MuiLinkProps,
  styled,
} from '@mui/material';
import { ExternalLinkIcon } from '@wpengine/unicorn/icons';

const ExternalIcon = styled(ExternalLinkIcon)({
  marginLeft: '4px',
  verticalAlign: 'bottom',
});

export type LinkProps = MuiLinkProps<any, { component?: any }> & {
  /**
   * Used for sites external to the Portal
   * (i.e., not localhost paths) - including
   * WP Engine support links
   *
   * External links should also open in a new tab
   */
  hideExternalIcon?: boolean;
  isExternal?: boolean;
};

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props: LinkProps, ref: React.Ref<HTMLAnchorElement>) => {
    const {
      children,
      className,
      isExternal,
      color,
      hideExternalIcon,
      ...rest
    } = props;

    // Make sure rel is noreferrer to prevent a vulnerability if target is _blank.
    const rel =
      props.rel ||
      (props.target === '_blank' || isExternal ? 'noreferrer' : null);

    // This deletion prevents the spread of the navigate function from React Router on to the <a> tag
    delete rest.navigate;

    return (
      <MuiLink
        {...rest}
        rel={rel}
        ref={ref}
        target={isExternal ? '_blank' : '_self'}
        className={className}
        color={color || 'primary'}
      >
        {children}
        {isExternal && !hideExternalIcon && (
          <ExternalIcon aria-label="Opens in new tab" />
        )}
      </MuiLink>
    );
  }
);

export default Link;
