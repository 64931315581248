import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const StripeConnect: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="StripeConnect logo" viewBox="0 0 64 64" {...props}>
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_793_851)"
      />
      <path
        d="M37.5 26C37.7761 26 38.0011 25.776 37.9896 25.5001C37.898 23.302 37.2035 21.1678 35.9776 19.3332C34.6591 17.3598 32.7849 15.8217 30.5922 14.9135C28.3995 14.0052 25.9867 13.7676 23.6589 14.2306C21.3311 14.6936 19.193 15.8365 17.5147 17.5147C15.8365 19.193 14.6936 21.3311 14.2306 23.6589C13.7676 25.9867 14.0052 28.3995 14.9135 30.5922C15.8217 32.7849 17.3598 34.6591 19.3332 35.9776C21.1678 37.2035 23.302 37.898 25.5001 37.9896C25.776 38.0011 26 37.7761 26 37.5V30C26 27.7909 27.7909 26 30 26H37.5Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 29.5C29.9477 29.5 29.5 29.9477 29.5 30.5V38.5C29.5 43.4706 33.5294 47.5 38.5 47.5C43.4706 47.5 47.5 43.4706 47.5 38.5C47.5 33.5294 43.4706 29.5 38.5 29.5H30.5ZM27.5 30.5C27.5 28.8431 28.8431 27.5 30.5 27.5H38.5C44.5751 27.5 49.5 32.4249 49.5 38.5C49.5 44.5751 44.5751 49.5 38.5 49.5C32.4249 49.5 27.5 44.5751 27.5 38.5V30.5Z"
        fill="#006BD6"
      />
      <path
        d="M31 31.5C31 31.2239 31.2239 31 31.5 31H37.5C37.7761 31 38 31.2239 38 31.5V36C38 37.1046 37.1046 38 36 38H31.5C31.2239 38 31 37.7761 31 37.5V31.5Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_793_851"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default StripeConnect;
