import backupHandlers from './backupHandlers';
import createCopyHandlers from './createCopyHandlers';
import wpDBTablesHandlers from './wpDBTablesHandlers';

const copyEnvironmentHandlers = [
  ...backupHandlers,
  ...wpDBTablesHandlers,
  ...createCopyHandlers,
];

export default copyEnvironmentHandlers;
