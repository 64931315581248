import * as React from 'react';
import { FormHelperText, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { red700 } from 'design-tokens';
import { ErrorIcon } from '@wpengine/unicorn/icons';
import { Box } from '../../layout';

type ErrorTextProps = BoxProps & {
  id?: string;
  text: string;
};

const ErrorHelperText = styled(FormHelperText)(({ theme }) => ({
  color: red700,
  fontSize: '0.875rem',
  lineHeight: theme.spacing(3),
  marginLeft: theme.spacing(1),
  marginTop: 0,
}));

const ErrorText = (props: ErrorTextProps): JSX.Element => {
  const { id, text, sx } = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      {...props}
      sx={{ margin: '8px 0', ...sx }}
    >
      <ErrorIcon htmlColor={red700} />
      <ErrorHelperText id={id}>{text}</ErrorHelperText>
    </Box>
  );
};

export default ErrorText;
