import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { wp_db_tables_deploys_path } from 'railsRoutes';
import sampleWPDBTables from '../data/wpDbTables';

const wpDBTablesReturn = sampleWPDBTables();

const wpDBTablesHandlers = [
  rest.get(`*${wp_db_tables_deploys_path()}*`, (req, _res, ctx) => {
    if (hasPageUrlQueryParam('display_install')) {
      return customResponse(
        ctx.status(200),
        ctx.json({
          tables: [req.url.searchParams.get('install')],
        })
      );
    }

    return customResponse(ctx.status(200), ctx.json(wpDBTablesReturn));
  }),
];

export default wpDBTablesHandlers;
