import * as React from 'react';
import { products_path } from 'railsRoutes';
import { DiamondIcon } from '@wpengine/unicorn/icons';
import SideNavLink from '../components/SideNavLink/SideNavLink';

const AddonsDevTools: React.FC = () => {
  const productsPath = products_path();

  return (
    <SideNavLink icon={<DiamondIcon />} label="Products" href={productsPath} />
  );
};

export default AddonsDevTools;
