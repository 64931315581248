import { QuickBuyData } from 'componentsDir/shared/QuickBuyModal/types';

export const mockQuickBuyData: QuickBuyData = {
  bundle: true,
  recurrence: 'MONTHLY',
  currency: 'USD',
  locale: 'en',
  plan: 'startup',
  comped: false,
  region: 'us',
  existingProducts: [],
  existingSubPrice: 300,
  accountID: 12345,
  addonData: [],
  checkoutId: 'fa03c20e-8983-4398-bf01-1fd31486c8e3',
  billingSystem: 'bapi',
  termsOfServiceUrl: 'https://wpengine.com/legal/terms-of-service/',
};

export const nonBundleMockQuickBuyData: QuickBuyData = {
  bundle: false,
  recurrence: 'MONTHLY',
  currency: 'USD',
  locale: 'en',
  plan: 'startup',
  comped: false,
  region: 'us',
  existingProducts: [],
  existingSubPrice: 300,
  accountID: 12345,
  addonData: [],
  checkoutId: 'fa03c20e-8983-4398-bf01-1fd31486c8e3',
  billingSystem: 'bapi',
  termsOfServiceUrl: 'https://wpengine.com/legal/terms-of-service/',
};
