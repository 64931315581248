import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const ExternalLinkIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="external-link-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H7.62132C5.39406 21 4.27864 18.3071 5.85355 16.7322L11.5858 11H10C9.44772 11 9 10.5523 9 10C9 9.44772 9.44772 9 10 9H14C14.5523 9 15 9.44772 15 10V14C15 14.5523 14.5523 15 14 15C13.4477 15 13 14.5523 13 14V12.4142L7.26777 18.1464C6.95278 18.4614 7.17587 19 7.62132 19H18C18.5523 19 19 18.5523 19 18V6C19 5.44772 18.5523 5 18 5H6C5.44772 5 5 5.44772 5 6V11C5 11.5523 4.55228 12 4 12C3.44772 12 3 11.5523 3 11V6Z"
        />
      </SvgTemplate>
    );
  }
);
export default ExternalLinkIcon;
