export const LOCAL_STORAGE = {
  KEYS: {
    PORTAL_DEV_TOOLS_ENABLED: 'portalDevToolsEnabled',
    ENABLE_MSW: 'enableMSW',
  },
};

export const VIEWS = {
  KEYS: {
    MAIN_MENU: 'mainMenu',
    USEFUL_RESOURCES: 'usefulResources',
    USER_ROLES: 'userRoles',
    INSTALLS: 'installs',
    MSW_TOGGLE: 'mswToggle',
  },
};
