import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const StepperThreeIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="stepper-three-icon" ref={ref} {...props}>
        <path d="M10.5 9.25C10.5 8.65825 11.09 8 12 8C12.91 8 13.5 8.65825 13.5 9.25C13.5 9.8431 12.9141 10.5 12 10.5C11.4477 10.5 11 10.9477 11 11.5C11 12.0523 11.4477 12.5 12 12.5C13.1837 12.5 14 13.3782 14 14.25C14 15.1218 13.1837 16 12 16C10.8163 16 10 15.1218 10 14.25C10 13.6977 9.55229 13.25 9 13.25C8.44772 13.25 8 13.6977 8 14.25C8 16.3782 9.86994 18 12 18C14.1301 18 16 16.3782 16 14.25C16 13.0968 15.451 12.0923 14.609 11.414C15.1539 10.8549 15.5 10.1097 15.5 9.25C15.5 7.34175 13.79 6 12 6C10.21 6 8.5 7.34175 8.5 9.25C8.5 9.80228 8.94772 10.25 9.5 10.25C10.0523 10.25 10.5 9.80228 10.5 9.25Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
        />
      </SvgTemplate>
    );
  }
);
export default StepperThreeIcon;
