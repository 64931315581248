import { useMutation, useQueryClient } from 'react-query';
import FetchAPI from 'commonsDir/services/FetchAPI';
import { cart_path } from 'railsRoutes';
import { Cart } from '../types/types';
import QueryKey from '../apis/QueryKey';

const useUpdateCart = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QueryKey.UPDATE_CART],
    mutationFn: (newCart: Cart) => {
      return FetchAPI.patch<Cart>(cart_path(), newCart);
    },
    onMutate: async (newCart: Cart) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(QueryKey.GET_CART);

      // Snapshot the previous cart
      const previousCart = queryClient.getQueryData(QueryKey.GET_CART);

      // Optimistically update the new cart
      queryClient.setQueryData(QueryKey.GET_CART, newCart);

      // Return the previous cart as a snapshotted value
      return previousCart;
    },
    // If the mutation fails, use the previous cart from onMutate to roll back
    onError: (previousCart: Cart) => {
      queryClient.setQueryData(QueryKey.GET_CART, previousCart);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(QueryKey.GET_CART);
    },
  });
};

export default useUpdateCart;
