import * as React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@wpengine/unicorn/components';
import designTokens from 'design-tokens';
import { AccountColor } from 'commonsDir/types/Accounts';

export interface AvatarProps {
  color?: AccountColor;
  nickname: string;
  size?: 'small' | 'medium';
}

const Avatar: React.FC<AvatarProps> = ({
  color,
  nickname,
  size = 'medium',
}) => {
  const letter = nickname.substring(0, 1);
  const length = size === 'small' ? '20px' : '24px';
  const fontSize = size === 'small' ? '13.33px' : '1rem';

  return (
    <Box
      sx={{
        justifyContent: 'center',
      }}
    >
      <Typography
        sx={{
          backgroundColor: designTokens[color] || designTokens.blue700,
          borderRadius: '50%',
          color: designTokens.colorWhite,
          display: 'grid',
          fontSize,
          fontWeight: 'bold',
          lineHeight: length,
          height: length,
          textAlign: 'center',
          textTransform: 'uppercase',
          width: length,
        }}
      >
        {letter}
      </Typography>
    </Box>
  );
};

export default Avatar;
