import { ExtensionType } from 'componentsDir/products/smart_plugin_manager/utils/interfaces';

const themes: {
  slug: string;
  name: string;
  type: ExtensionType;
  sites: Array<any>;
}[] = [
  {
    slug: 'twentytwenty-two/twentytwenty-two.php',
    name: 'Twenty Twenty-Two',
    type: 'theme',
    sites: [
      {
        site_id: 30,
        version: '1.6.15',
        install_name: 'testsite300',
        active: true,
        update: {
          version: '2.1.4',
          released_at: '2022-02-22 20:02:10',
          update_id: 68745,
        },
        extension_id: 59339858,
        vulnerabilities: [],
      },
      {
        site_id: 31,
        version: '1.15.6',
        install_name: 'testsite301',
        active: true,
        update: null,
        extension_id: 21548439,
        vulnerabilities: [],
      },
      {
        site_id: 32,
        version: '1.2.7',
        install_name: 'testsite302',
        active: true,
        update: null,
        extension_id: 66929305,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'twentytwenty-one/twentytwenty-one.php',
    name: 'Twenty Twenty-One',
    type: 'theme',
    sites: [
      {
        site_id: 110,
        version: '1.5.12',
        install_name: 'testsite1100',
        active: true,
        update: null,
        extension_id: 47396372,
        vulnerabilities: [],
      },
      {
        site_id: 111,
        version: '1.5.3',
        install_name: 'testsite1101',
        active: true,
        update: {
          version: '5.4.4',
          released_at: '2022-04-24 03:42:12',
          update_id: 70461,
        },
        extension_id: 84733963,
        vulnerabilities: [],
      },
      {
        site_id: 112,
        version: '1.10.7',
        install_name: 'testsite1102',
        active: true,
        update: {
          version: '5.4.4',
          released_at: '2022-04-24 03:42:12',
          update_id: 32910,
        },
        extension_id: 31022655,
        vulnerabilities: [],
      },
      {
        site_id: 130,
        version: '1.10.13',
        install_name: 'testsite1300',
        active: true,
        update: null,
        extension_id: 92609590,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.8.10',
        install_name: 'testsite1301',
        active: true,
        update: {
          version: '5.4.4',
          released_at: '2022-04-24 03:42:12',
          update_id: 59488,
        },
        extension_id: 86650067,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.2.15',
        install_name: 'testsite1302',
        active: true,
        update: null,
        extension_id: 3733184,
        vulnerabilities: [],
      },
      {
        site_id: 150,
        version: '1.4.6',
        install_name: 'testsite1500',
        active: true,
        update: null,
        extension_id: 53663091,
        vulnerabilities: [],
      },
      {
        site_id: 151,
        version: '1.15.15',
        install_name: 'testsite1501',
        active: true,
        update: null,
        extension_id: 18430777,
        vulnerabilities: [],
      },
      {
        site_id: 152,
        version: '1.3.15',
        install_name: 'testsite1502',
        active: true,
        update: null,
        extension_id: 74391997,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'helloelementor/helloelementor.php',
    name: 'Hello Elementor',
    type: 'theme',
    sites: [
      {
        site_id: 50,
        version: '1.8.11',
        install_name: 'testsite500',
        active: true,
        update: {
          version: '2.3.1',
          released_at: '2022-03-27 07:44:50',
          update_id: 91681,
        },
        extension_id: 64391794,
        vulnerabilities: [],
      },
      {
        site_id: 51,
        version: '1.13.3',
        install_name: 'testsite501',
        active: true,
        update: {
          version: '2.3.1',
          released_at: '2022-03-27 07:44:50',
          update_id: 42331,
        },
        extension_id: 99039136,
        vulnerabilities: [],
      },
      {
        site_id: 52,
        version: '1.11.5',
        install_name: 'testsite502',
        active: true,
        update: null,
        extension_id: 46093609,
        vulnerabilities: [],
      },
      {
        site_id: 130,
        version: '1.1.14',
        install_name: 'testsite1300',
        active: true,
        update: {
          version: '2.3.1',
          released_at: '2022-03-27 07:44:50',
          update_id: 93242,
        },
        extension_id: 2666038,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.8.15',
        install_name: 'testsite1301',
        active: true,
        update: {
          version: '2.3.1',
          released_at: '2022-03-27 07:44:50',
          update_id: 24012,
        },
        extension_id: 94916755,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.12.1',
        install_name: 'testsite1302',
        active: true,
        update: {
          version: '2.3.1',
          released_at: '2022-03-27 07:44:50',
          update_id: 22149,
        },
        extension_id: 70651057,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'astra/astra.php',
    name: 'Astra',
    type: 'theme',
    sites: [
      {
        site_id: 150,
        version: '1.5.5',
        install_name: 'testsite1500',
        active: true,
        update: {
          version: '5.3.2',
          released_at: '2021-12-01 02:33:21',
          update_id: 83409,
        },
        extension_id: 35887898,
        vulnerabilities: [],
      },
      {
        site_id: 151,
        version: '1.13.2',
        install_name: 'testsite1501',
        active: true,
        update: null,
        extension_id: 19193826,
        vulnerabilities: [],
      },
      {
        site_id: 152,
        version: '1.7.3',
        install_name: 'testsite1502',
        active: true,
        update: {
          version: '5.3.2',
          released_at: '2021-12-01 02:33:21',
          update_id: 78807,
        },
        extension_id: 40098739,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'twentytwenty/twentytwenty.php',
    name: 'Twenty Twenty',
    type: 'theme',
    sites: [
      {
        site_id: 30,
        version: '1.7.15',
        install_name: 'testsite300',
        active: true,
        update: null,
        extension_id: 38577511,
        vulnerabilities: [],
      },
      {
        site_id: 31,
        version: '1.1.11',
        install_name: 'testsite301',
        active: true,
        update: {
          version: '4.1.1',
          released_at: '2022-04-17 18:23:29',
          update_id: 93012,
        },
        extension_id: 81527600,
        vulnerabilities: [],
      },
      {
        site_id: 32,
        version: '1.5.10',
        install_name: 'testsite302',
        active: true,
        update: {
          version: '4.1.1',
          released_at: '2022-04-17 18:23:29',
          update_id: 86321,
        },
        extension_id: 39608071,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'oceanwp/oceanwp.php',
    name: 'OceanWP',
    type: 'theme',
    sites: [
      {
        site_id: 40,
        version: '1.4.11',
        install_name: 'testsite400',
        active: true,
        update: null,
        extension_id: 89721659,
        vulnerabilities: [],
      },
      {
        site_id: 41,
        version: '1.5.11',
        install_name: 'testsite401',
        active: true,
        update: {
          version: '5.4.5',
          released_at: '2022-04-30 02:22:40',
          update_id: 52601,
        },
        extension_id: 17390509,
        vulnerabilities: [],
      },
      {
        site_id: 42,
        version: '1.12.7',
        install_name: 'testsite402',
        active: true,
        update: null,
        extension_id: 12699269,
        vulnerabilities: [],
      },
      {
        site_id: 50,
        version: '1.2.8',
        install_name: 'testsite500',
        active: true,
        update: {
          version: '5.4.5',
          released_at: '2022-04-30 02:22:40',
          update_id: 65118,
        },
        extension_id: 41759591,
        vulnerabilities: [],
      },
      {
        site_id: 51,
        version: '1.4.2',
        install_name: 'testsite501',
        active: true,
        update: null,
        extension_id: 96344491,
        vulnerabilities: [],
      },
      {
        site_id: 52,
        version: '1.11.8',
        install_name: 'testsite502',
        active: true,
        update: null,
        extension_id: 98057692,
        vulnerabilities: [],
      },
      {
        site_id: 140,
        version: '1.3.15',
        install_name: 'testsite1400',
        active: true,
        update: null,
        extension_id: 83115563,
        vulnerabilities: [],
      },
      {
        site_id: 141,
        version: '1.15.12',
        install_name: 'testsite1401',
        active: true,
        update: null,
        extension_id: 31882673,
        vulnerabilities: [],
      },
      {
        site_id: 142,
        version: '1.3.12',
        install_name: 'testsite1402',
        active: true,
        update: null,
        extension_id: 92148142,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'twentyseventeen/twentyseventeen.php',
    name: 'Twenty Seventeen',
    type: 'theme',
    sites: [
      {
        site_id: 50,
        version: '1.1.3',
        install_name: 'testsite500',
        active: true,
        update: {
          version: '5.1.5',
          released_at: '2021-12-15 09:32:26',
          update_id: 93946,
        },
        extension_id: 19079500,
        vulnerabilities: [],
      },
      {
        site_id: 51,
        version: '1.15.5',
        install_name: 'testsite501',
        active: true,
        update: null,
        extension_id: 94060053,
        vulnerabilities: [],
      },
      {
        site_id: 52,
        version: '1.13.13',
        install_name: 'testsite502',
        active: true,
        update: null,
        extension_id: 34610338,
        vulnerabilities: [],
      },
      {
        site_id: 100,
        version: '1.13.2',
        install_name: 'testsite1000',
        active: true,
        update: null,
        extension_id: 60944833,
        vulnerabilities: [],
      },
      {
        site_id: 101,
        version: '1.6.9',
        install_name: 'testsite1001',
        active: true,
        update: {
          version: '5.1.5',
          released_at: '2021-12-15 09:32:26',
          update_id: 70645,
        },
        extension_id: 24992048,
        vulnerabilities: [],
      },
      {
        site_id: 102,
        version: '1.11.7',
        install_name: 'testsite1002',
        active: true,
        update: {
          version: '5.1.5',
          released_at: '2021-12-15 09:32:26',
          update_id: 38068,
        },
        extension_id: 15218502,
        vulnerabilities: [],
      },
      {
        site_id: 110,
        version: '1.13.9',
        install_name: 'testsite1100',
        active: true,
        update: null,
        extension_id: 22078527,
        vulnerabilities: [],
      },
      {
        site_id: 111,
        version: '1.9.5',
        install_name: 'testsite1101',
        active: true,
        update: null,
        extension_id: 26662771,
        vulnerabilities: [],
      },
      {
        site_id: 112,
        version: '1.4.14',
        install_name: 'testsite1102',
        active: true,
        update: {
          version: '5.1.5',
          released_at: '2021-12-15 09:32:26',
          update_id: 35586,
        },
        extension_id: 66602716,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'neve/neve.php',
    name: 'Neve',
    type: 'theme',
    sites: [
      {
        site_id: 10,
        version: '1.11.5',
        install_name: 'testsite100',
        active: true,
        update: null,
        extension_id: 46053154,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        version: '1.6.6',
        install_name: 'testsite101',
        active: true,
        update: null,
        extension_id: 75389589,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.11.11',
        install_name: 'testsite102',
        active: true,
        update: null,
        extension_id: 52400935,
        vulnerabilities: [],
      },
      {
        site_id: 140,
        version: '1.15.2',
        install_name: 'testsite1400',
        active: true,
        update: {
          version: '5.4.5',
          released_at: '2021-10-15 15:52:44',
          update_id: 94177,
        },
        extension_id: 44929304,
        vulnerabilities: [],
      },
      {
        site_id: 141,
        version: '1.2.12',
        install_name: 'testsite1401',
        active: true,
        update: {
          version: '5.4.5',
          released_at: '2021-10-15 15:52:44',
          update_id: 61820,
        },
        extension_id: 31143256,
        vulnerabilities: [],
      },
      {
        site_id: 142,
        version: '1.3.4',
        install_name: 'testsite1402',
        active: true,
        update: null,
        extension_id: 11067380,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'kadence/kadence.php',
    name: 'Kadence',
    type: 'theme',
    sites: [
      {
        site_id: 120,
        version: '1.12.9',
        install_name: 'testsite1200',
        active: true,
        update: null,
        extension_id: 6714144,
        vulnerabilities: [],
      },
      {
        site_id: 121,
        version: '1.15.15',
        install_name: 'testsite1201',
        active: true,
        update: {
          version: '3.1.4',
          released_at: '2021-11-16 18:49:26',
          update_id: 52921,
        },
        extension_id: 13561870,
        vulnerabilities: [],
      },
      {
        site_id: 122,
        version: '1.6.6',
        install_name: 'testsite1202',
        active: true,
        update: null,
        extension_id: 13884223,
        vulnerabilities: [],
      },
      {
        site_id: 130,
        version: '1.1.15',
        install_name: 'testsite1300',
        active: true,
        update: {
          version: '3.1.4',
          released_at: '2021-11-16 18:49:26',
          update_id: 51040,
        },
        extension_id: 49393027,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.15.12',
        install_name: 'testsite1301',
        active: true,
        update: {
          version: '3.1.4',
          released_at: '2021-11-16 18:49:26',
          update_id: 40064,
        },
        extension_id: 6084028,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.11.11',
        install_name: 'testsite1302',
        active: true,
        update: null,
        extension_id: 93213303,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'twentynineteen/twentynineteen.php',
    name: 'Twenty Nineteen',
    type: 'theme',
    sites: [
      {
        site_id: 120,
        version: '1.10.1',
        install_name: 'testsite1200',
        active: true,
        update: null,
        extension_id: 65125182,
        vulnerabilities: [],
      },
      {
        site_id: 121,
        version: '1.10.11',
        install_name: 'testsite1201',
        active: true,
        update: null,
        extension_id: 11237267,
        vulnerabilities: [],
      },
      {
        site_id: 122,
        version: '1.5.13',
        install_name: 'testsite1202',
        active: true,
        update: {
          version: '5.3.5',
          released_at: '2021-09-10 07:22:45',
          update_id: 63613,
        },
        extension_id: 79773014,
        vulnerabilities: [],
      },
      {
        site_id: 140,
        version: '1.6.5',
        install_name: 'testsite1400',
        active: true,
        update: null,
        extension_id: 10882399,
        vulnerabilities: [],
      },
      {
        site_id: 141,
        version: '1.1.1',
        install_name: 'testsite1401',
        active: true,
        update: null,
        extension_id: 12795614,
        vulnerabilities: [],
      },
      {
        site_id: 142,
        version: '1.3.6',
        install_name: 'testsite1402',
        active: true,
        update: {
          version: '5.3.5',
          released_at: '2021-09-10 07:22:45',
          update_id: 76560,
        },
        extension_id: 65119178,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'popularfx/popularfx.php',
    name: 'PopularFX',
    type: 'theme',
    sites: [
      {
        site_id: 50,
        version: '1.5.5',
        install_name: 'testsite500',
        active: true,
        update: {
          version: '2.1.5',
          released_at: '2022-07-15 22:34:12',
          update_id: 25362,
        },
        extension_id: 39544139,
        vulnerabilities: [],
      },
      {
        site_id: 51,
        version: '1.3.9',
        install_name: 'testsite501',
        active: true,
        update: null,
        extension_id: 3346452,
        vulnerabilities: [],
      },
      {
        site_id: 52,
        version: '1.3.4',
        install_name: 'testsite502',
        active: true,
        update: {
          version: '2.1.5',
          released_at: '2022-07-15 22:34:12',
          update_id: 15471,
        },
        extension_id: 58756351,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'generatepress/generatepress.php',
    name: 'GeneratePress',
    type: 'theme',
    sites: [
      {
        site_id: 130,
        version: '1.1.7',
        install_name: 'testsite1300',
        active: true,
        update: {
          version: '5.3.1',
          released_at: '2021-12-20 19:22:38',
          update_id: 20500,
        },
        extension_id: 14501043,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.14.1',
        install_name: 'testsite1301',
        active: true,
        update: {
          version: '5.3.1',
          released_at: '2021-12-20 19:22:38',
          update_id: 48823,
        },
        extension_id: 71882880,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.14.9',
        install_name: 'testsite1302',
        active: true,
        update: null,
        extension_id: 17929144,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'inspiro/inspiro.php',
    name: 'Inspiro',
    type: 'theme',
    sites: [
      {
        site_id: 30,
        version: '1.14.1',
        install_name: 'testsite300',
        active: true,
        update: null,
        extension_id: 61245501,
        vulnerabilities: [],
      },
      {
        site_id: 31,
        version: '1.6.13',
        install_name: 'testsite301',
        active: true,
        update: {
          version: '3.1.2',
          released_at: '2022-06-12 07:42:25',
          update_id: 37071,
        },
        extension_id: 1934787,
        vulnerabilities: [],
      },
      {
        site_id: 32,
        version: '1.5.11',
        install_name: 'testsite302',
        active: true,
        update: {
          version: '3.1.2',
          released_at: '2022-06-12 07:42:25',
          update_id: 45938,
        },
        extension_id: 73136569,
        vulnerabilities: [],
      },
      {
        site_id: 150,
        version: '1.12.15',
        install_name: 'testsite1500',
        active: true,
        update: {
          version: '3.1.2',
          released_at: '2022-06-12 07:42:25',
          update_id: 77333,
        },
        extension_id: 46055385,
        vulnerabilities: [],
      },
      {
        site_id: 151,
        version: '1.7.6',
        install_name: 'testsite1501',
        active: true,
        update: null,
        extension_id: 17412315,
        vulnerabilities: [],
      },
      {
        site_id: 152,
        version: '1.2.10',
        install_name: 'testsite1502',
        active: true,
        update: {
          version: '3.1.2',
          released_at: '2022-06-12 07:42:25',
          update_id: 84259,
        },
        extension_id: 34463346,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'go/go.php',
    name: 'Go',
    type: 'theme',
    sites: [
      {
        site_id: 20,
        version: '1.4.11',
        install_name: 'testsite200',
        active: true,
        update: null,
        extension_id: 73725805,
        vulnerabilities: [],
      },
      {
        site_id: 21,
        version: '1.14.5',
        install_name: 'testsite201',
        active: true,
        update: {
          version: '5.2.3',
          released_at: '2022-04-19 17:27:57',
          update_id: 74855,
        },
        extension_id: 61038928,
        vulnerabilities: [],
      },
      {
        site_id: 22,
        version: '1.13.11',
        install_name: 'testsite202',
        active: true,
        update: {
          version: '5.2.3',
          released_at: '2022-04-19 17:27:57',
          update_id: 75937,
        },
        extension_id: 50445158,
        vulnerabilities: [],
      },
      {
        site_id: 50,
        version: '1.11.5',
        install_name: 'testsite500',
        active: true,
        update: null,
        extension_id: 69248660,
        vulnerabilities: [],
      },
      {
        site_id: 51,
        version: '1.5.9',
        install_name: 'testsite501',
        active: true,
        update: {
          version: '5.2.3',
          released_at: '2022-04-19 17:27:57',
          update_id: 46941,
        },
        extension_id: 26999014,
        vulnerabilities: [],
      },
      {
        site_id: 52,
        version: '1.11.10',
        install_name: 'testsite502',
        active: true,
        update: {
          version: '5.2.3',
          released_at: '2022-04-19 17:27:57',
          update_id: 58586,
        },
        extension_id: 63303331,
        vulnerabilities: [],
      },
      {
        site_id: 90,
        version: '1.7.5',
        install_name: 'testsite900',
        active: true,
        update: {
          version: '5.2.3',
          released_at: '2022-04-19 17:27:57',
          update_id: 62726,
        },
        extension_id: 63389638,
        vulnerabilities: [],
      },
      {
        site_id: 91,
        version: '1.4.9',
        install_name: 'testsite901',
        active: true,
        update: {
          version: '5.2.3',
          released_at: '2022-04-19 17:27:57',
          update_id: 21446,
        },
        extension_id: 99612951,
        vulnerabilities: [],
      },
      {
        site_id: 92,
        version: '1.15.12',
        install_name: 'testsite902',
        active: true,
        update: null,
        extension_id: 63450193,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'twentysixteen/twentysixteen.php',
    name: 'Twenty Sixteen',
    type: 'theme',
    sites: [
      {
        site_id: 80,
        version: '1.8.15',
        install_name: 'testsite800',
        active: true,
        update: null,
        extension_id: 29649151,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        version: '1.13.2',
        install_name: 'testsite801',
        active: true,
        update: null,
        extension_id: 42601496,
        vulnerabilities: [],
      },
      {
        site_id: 82,
        version: '1.11.12',
        install_name: 'testsite802',
        active: true,
        update: {
          version: '5.4.3',
          released_at: '2021-10-28 22:37:35',
          update_id: 98096,
        },
        extension_id: 24442244,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'storefront/storefront.php',
    name: 'Storefront',
    type: 'theme',
    sites: [
      {
        site_id: 140,
        version: '1.15.6',
        install_name: 'testsite1400',
        active: true,
        update: null,
        extension_id: 54943238,
        vulnerabilities: [],
      },
      {
        site_id: 141,
        version: '1.7.3',
        install_name: 'testsite1401',
        active: true,
        update: null,
        extension_id: 936289,
        vulnerabilities: [],
      },
      {
        site_id: 142,
        version: '1.4.15',
        install_name: 'testsite1402',
        active: true,
        update: null,
        extension_id: 25819990,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'hestia/hestia.php',
    name: 'Hestia',
    type: 'theme',
    sites: [
      {
        site_id: 40,
        version: '1.15.14',
        install_name: 'testsite400',
        active: true,
        update: null,
        extension_id: 8241681,
        vulnerabilities: [],
      },
      {
        site_id: 41,
        version: '1.1.5',
        install_name: 'testsite401',
        active: true,
        update: null,
        extension_id: 67544865,
        vulnerabilities: [],
      },
      {
        site_id: 42,
        version: '1.7.6',
        install_name: 'testsite402',
        active: true,
        update: null,
        extension_id: 16102487,
        vulnerabilities: [],
      },
      {
        site_id: 90,
        version: '1.7.13',
        install_name: 'testsite900',
        active: true,
        update: null,
        extension_id: 7408459,
        vulnerabilities: [],
      },
      {
        site_id: 91,
        version: '1.12.14',
        install_name: 'testsite901',
        active: true,
        update: null,
        extension_id: 83401059,
        vulnerabilities: [],
      },
      {
        site_id: 92,
        version: '1.9.11',
        install_name: 'testsite902',
        active: true,
        update: null,
        extension_id: 3802225,
        vulnerabilities: [],
      },
      {
        site_id: 130,
        version: '1.12.7',
        install_name: 'testsite1300',
        active: true,
        update: null,
        extension_id: 43700262,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.14.1',
        install_name: 'testsite1301',
        active: true,
        update: {
          version: '2.1.1',
          released_at: '2022-08-04 21:48:42',
          update_id: 97006,
        },
        extension_id: 41025925,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.3.5',
        install_name: 'testsite1302',
        active: true,
        update: null,
        extension_id: 53522617,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'blocksy/blocksy.php',
    name: 'Blocksy',
    type: 'theme',
    sites: [
      {
        site_id: 70,
        version: '1.2.2',
        install_name: 'testsite700',
        active: true,
        update: null,
        extension_id: 4601727,
        vulnerabilities: [],
      },
      {
        site_id: 71,
        version: '1.15.7',
        install_name: 'testsite701',
        active: true,
        update: {
          version: '2.1.5',
          released_at: '2021-10-13 23:47:34',
          update_id: 61838,
        },
        extension_id: 31664774,
        vulnerabilities: [],
      },
      {
        site_id: 72,
        version: '1.8.8',
        install_name: 'testsite702',
        active: true,
        update: null,
        extension_id: 58365533,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'royalelementorkit/royalelementorkit.php',
    name: 'Royal Elementor Kit',
    type: 'theme',
    sites: [
      {
        site_id: 10,
        version: '1.1.7',
        install_name: 'testsite100',
        active: true,
        update: null,
        extension_id: 55806056,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        version: '1.15.2',
        install_name: 'testsite101',
        active: true,
        update: null,
        extension_id: 87483176,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.13.14',
        install_name: 'testsite102',
        active: true,
        update: {
          version: '2.4.2',
          released_at: '2022-05-30 09:37:50',
          update_id: 11835,
        },
        extension_id: 16424435,
        vulnerabilities: [],
      },
      {
        site_id: 90,
        version: '1.10.8',
        install_name: 'testsite900',
        active: true,
        update: null,
        extension_id: 97179926,
        vulnerabilities: [],
      },
      {
        site_id: 91,
        version: '1.6.10',
        install_name: 'testsite901',
        active: true,
        update: null,
        extension_id: 50062612,
        vulnerabilities: [],
      },
      {
        site_id: 92,
        version: '1.7.7',
        install_name: 'testsite902',
        active: true,
        update: null,
        extension_id: 87103434,
        vulnerabilities: [],
      },
      {
        site_id: 130,
        version: '1.9.2',
        install_name: 'testsite1300',
        active: true,
        update: null,
        extension_id: 44998492,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.2.10',
        install_name: 'testsite1301',
        active: true,
        update: null,
        extension_id: 9715207,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.10.2',
        install_name: 'testsite1302',
        active: true,
        update: null,
        extension_id: 32825957,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'colibriwp/colibriwp.php',
    name: 'Colibri WP',
    type: 'theme',
    sites: [
      {
        site_id: 130,
        version: '1.15.1',
        install_name: 'testsite1300',
        active: true,
        update: {
          version: '5.2.5',
          released_at: '2021-11-29 17:49:13',
          update_id: 25192,
        },
        extension_id: 49439789,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.8.5',
        install_name: 'testsite1301',
        active: true,
        update: null,
        extension_id: 22304428,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.6.1',
        install_name: 'testsite1302',
        active: true,
        update: null,
        extension_id: 45812597,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'sydney/sydney.php',
    name: 'Sydney',
    type: 'theme',
    sites: [
      {
        site_id: 30,
        version: '1.3.1',
        install_name: 'testsite300',
        active: true,
        update: {
          version: '2.5.4',
          released_at: '2021-10-26 03:59:41',
          update_id: 68077,
        },
        extension_id: 14905576,
        vulnerabilities: [],
      },
      {
        site_id: 31,
        version: '1.4.4',
        install_name: 'testsite301',
        active: true,
        update: null,
        extension_id: 77850897,
        vulnerabilities: [],
      },
      {
        site_id: 32,
        version: '1.14.8',
        install_name: 'testsite302',
        active: true,
        update: {
          version: '2.5.4',
          released_at: '2021-10-26 03:59:41',
          update_id: 94254,
        },
        extension_id: 15811929,
        vulnerabilities: [],
      },
      {
        site_id: 40,
        version: '1.13.11',
        install_name: 'testsite400',
        active: true,
        update: {
          version: '2.5.4',
          released_at: '2021-10-26 03:59:41',
          update_id: 95410,
        },
        extension_id: 37745041,
        vulnerabilities: [],
      },
      {
        site_id: 41,
        version: '1.9.15',
        install_name: 'testsite401',
        active: true,
        update: null,
        extension_id: 17798297,
        vulnerabilities: [],
      },
      {
        site_id: 42,
        version: '1.2.5',
        install_name: 'testsite402',
        active: true,
        update: null,
        extension_id: 58487175,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'newsment/newsment.php',
    name: 'Newsment',
    type: 'theme',
    sites: [
      {
        site_id: 20,
        version: '1.6.1',
        install_name: 'testsite200',
        active: true,
        update: null,
        extension_id: 47919866,
        vulnerabilities: [],
      },
      {
        site_id: 21,
        version: '1.5.2',
        install_name: 'testsite201',
        active: true,
        update: null,
        extension_id: 14177784,
        vulnerabilities: [],
      },
      {
        site_id: 22,
        version: '1.6.4',
        install_name: 'testsite202',
        active: true,
        update: null,
        extension_id: 42741241,
        vulnerabilities: [],
      },
      {
        site_id: 150,
        version: '1.12.4',
        install_name: 'testsite1500',
        active: true,
        update: {
          version: '3.1.5',
          released_at: '2021-10-17 00:00:56',
          update_id: 84190,
        },
        extension_id: 27098203,
        vulnerabilities: [],
      },
      {
        site_id: 151,
        version: '1.1.5',
        install_name: 'testsite1501',
        active: true,
        update: null,
        extension_id: 92828783,
        vulnerabilities: [],
      },
      {
        site_id: 152,
        version: '1.7.12',
        install_name: 'testsite1502',
        active: true,
        update: {
          version: '3.1.5',
          released_at: '2021-10-17 00:00:56',
          update_id: 10811,
        },
        extension_id: 94802860,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'twentyfifteen/twentyfifteen.php',
    name: 'Twenty Fifteen',
    type: 'theme',
    sites: [
      {
        site_id: 120,
        version: '1.5.3',
        install_name: 'testsite1200',
        active: true,
        update: null,
        extension_id: 9449851,
        vulnerabilities: [],
      },
      {
        site_id: 121,
        version: '1.2.1',
        install_name: 'testsite1201',
        active: true,
        update: null,
        extension_id: 66648700,
        vulnerabilities: [],
      },
      {
        site_id: 122,
        version: '1.3.9',
        install_name: 'testsite1202',
        active: true,
        update: {
          version: '2.3.5',
          released_at: '2021-11-20 19:50:19',
          update_id: 47864,
        },
        extension_id: 62413074,
        vulnerabilities: [],
      },
      {
        site_id: 150,
        version: '1.7.14',
        install_name: 'testsite1500',
        active: true,
        update: null,
        extension_id: 80600976,
        vulnerabilities: [],
      },
      {
        site_id: 151,
        version: '1.15.1',
        install_name: 'testsite1501',
        active: true,
        update: null,
        extension_id: 44171406,
        vulnerabilities: [],
      },
      {
        site_id: 152,
        version: '1.1.1',
        install_name: 'testsite1502',
        active: true,
        update: null,
        extension_id: 38144169,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'chromenews/chromenews.php',
    name: 'ChromeNews',
    type: 'theme',
    sites: [
      {
        site_id: 20,
        version: '1.15.12',
        install_name: 'testsite200',
        active: true,
        update: null,
        extension_id: 69030292,
        vulnerabilities: [],
      },
      {
        site_id: 21,
        version: '1.13.4',
        install_name: 'testsite201',
        active: true,
        update: null,
        extension_id: 43103513,
        vulnerabilities: [],
      },
      {
        site_id: 22,
        version: '1.15.15',
        install_name: 'testsite202',
        active: true,
        update: {
          version: '3.2.3',
          released_at: '2022-08-01 04:27:59',
          update_id: 35054,
        },
        extension_id: 82915950,
        vulnerabilities: [],
      },
      {
        site_id: 60,
        version: '1.4.2',
        install_name: 'testsite600',
        active: true,
        update: null,
        extension_id: 2237793,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.7.2',
        install_name: 'testsite601',
        active: true,
        update: {
          version: '3.2.3',
          released_at: '2022-08-01 04:27:59',
          update_id: 51584,
        },
        extension_id: 14123272,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.5.14',
        install_name: 'testsite602',
        active: true,
        update: {
          version: '3.2.3',
          released_at: '2022-08-01 04:27:59',
          update_id: 97472,
        },
        extension_id: 95523200,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'zakra/zakra.php',
    name: 'Zakra',
    type: 'theme',
    sites: [
      {
        site_id: 10,
        version: '1.6.13',
        install_name: 'testsite100',
        active: true,
        update: null,
        extension_id: 6822249,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        version: '1.10.14',
        install_name: 'testsite101',
        active: true,
        update: null,
        extension_id: 69877270,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.15.13',
        install_name: 'testsite102',
        active: true,
        update: {
          version: '2.1.5',
          released_at: '2022-02-01 17:36:02',
          update_id: 80217,
        },
        extension_id: 10057386,
        vulnerabilities: [],
      },
      {
        site_id: 50,
        version: '1.5.10',
        install_name: 'testsite500',
        active: true,
        update: null,
        extension_id: 49607263,
        vulnerabilities: [],
      },
      {
        site_id: 51,
        version: '1.11.9',
        install_name: 'testsite501',
        active: true,
        update: null,
        extension_id: 19921199,
        vulnerabilities: [],
      },
      {
        site_id: 52,
        version: '1.13.11',
        install_name: 'testsite502',
        active: true,
        update: {
          version: '2.1.5',
          released_at: '2022-02-01 17:36:02',
          update_id: 83159,
        },
        extension_id: 66443895,
        vulnerabilities: [],
      },
      {
        site_id: 90,
        version: '1.7.3',
        install_name: 'testsite900',
        active: true,
        update: null,
        extension_id: 94223742,
        vulnerabilities: [],
      },
      {
        site_id: 91,
        version: '1.12.2',
        install_name: 'testsite901',
        active: true,
        update: {
          version: '2.1.5',
          released_at: '2022-02-01 17:36:02',
          update_id: 43029,
        },
        extension_id: 55773280,
        vulnerabilities: [],
      },
      {
        site_id: 92,
        version: '1.8.6',
        install_name: 'testsite902',
        active: true,
        update: null,
        extension_id: 3478544,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'zeever/zeever.php',
    name: 'Zeever',
    type: 'theme',
    sites: [
      {
        site_id: 100,
        version: '1.6.15',
        install_name: 'testsite1000',
        active: true,
        update: {
          version: '2.3.1',
          released_at: '2021-09-26 16:40:02',
          update_id: 83004,
        },
        extension_id: 83142916,
        vulnerabilities: [],
      },
      {
        site_id: 101,
        version: '1.4.7',
        install_name: 'testsite1001',
        active: true,
        update: null,
        extension_id: 19241057,
        vulnerabilities: [],
      },
      {
        site_id: 102,
        version: '1.6.15',
        install_name: 'testsite1002',
        active: true,
        update: null,
        extension_id: 47658272,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'botiga/botiga.php',
    name: 'Botiga',
    type: 'theme',
    sites: [
      {
        site_id: 120,
        version: '1.13.9',
        install_name: 'testsite1200',
        active: true,
        update: null,
        extension_id: 16940538,
        vulnerabilities: [],
      },
      {
        site_id: 121,
        version: '1.1.5',
        install_name: 'testsite1201',
        active: true,
        update: {
          version: '4.5.2',
          released_at: '2021-12-12 06:30:01',
          update_id: 33895,
        },
        extension_id: 32448506,
        vulnerabilities: [],
      },
      {
        site_id: 122,
        version: '1.12.12',
        install_name: 'testsite1202',
        active: true,
        update: {
          version: '4.5.2',
          released_at: '2021-12-12 06:30:01',
          update_id: 31638,
        },
        extension_id: 85651524,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'blankcanvas/blankcanvas.php',
    name: 'Blank Canvas',
    type: 'theme',
    sites: [
      {
        site_id: 10,
        version: '1.7.7',
        install_name: 'testsite100',
        active: true,
        update: {
          version: '2.1.5',
          released_at: '2021-11-30 21:45:23',
          update_id: 47827,
        },
        extension_id: 83550564,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        version: '1.5.12',
        install_name: 'testsite101',
        active: true,
        update: {
          version: '2.1.5',
          released_at: '2021-11-30 21:45:23',
          update_id: 48869,
        },
        extension_id: 19005754,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.11.7',
        install_name: 'testsite102',
        active: true,
        update: null,
        extension_id: 72794525,
        vulnerabilities: [],
      },
      {
        site_id: 110,
        version: '1.11.3',
        install_name: 'testsite1100',
        active: true,
        update: null,
        extension_id: 94254392,
        vulnerabilities: [],
      },
      {
        site_id: 111,
        version: '1.5.11',
        install_name: 'testsite1101',
        active: true,
        update: null,
        extension_id: 12783913,
        vulnerabilities: [],
      },
      {
        site_id: 112,
        version: '1.15.2',
        install_name: 'testsite1102',
        active: true,
        update: null,
        extension_id: 62060812,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'newshunt/newshunt.php',
    name: 'News Hunt',
    type: 'theme',
    sites: [
      {
        site_id: 100,
        version: '1.2.13',
        install_name: 'testsite1000',
        active: true,
        update: null,
        extension_id: 10858209,
        vulnerabilities: [],
      },
      {
        site_id: 101,
        version: '1.1.10',
        install_name: 'testsite1001',
        active: true,
        update: null,
        extension_id: 16235013,
        vulnerabilities: [],
      },
      {
        site_id: 102,
        version: '1.8.12',
        install_name: 'testsite1002',
        active: true,
        update: null,
        extension_id: 92755769,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'minimalistnewspaper/minimalistnewspaper.php',
    name: 'Minimalist Newspaper',
    type: 'theme',
    sites: [
      {
        site_id: 100,
        version: '1.1.3',
        install_name: 'testsite1000',
        active: true,
        update: null,
        extension_id: 31288857,
        vulnerabilities: [],
      },
      {
        site_id: 101,
        version: '1.2.4',
        install_name: 'testsite1001',
        active: true,
        update: null,
        extension_id: 95001057,
        vulnerabilities: [],
      },
      {
        site_id: 102,
        version: '1.8.8',
        install_name: 'testsite1002',
        active: true,
        update: null,
        extension_id: 93263856,
        vulnerabilities: [],
      },
      {
        site_id: 120,
        version: '1.5.15',
        install_name: 'testsite1200',
        active: true,
        update: {
          version: '3.3.2',
          released_at: '2022-05-17 11:23:28',
          update_id: 80506,
        },
        extension_id: 69676705,
        vulnerabilities: [],
      },
      {
        site_id: 121,
        version: '1.10.6',
        install_name: 'testsite1201',
        active: true,
        update: {
          version: '3.3.2',
          released_at: '2022-05-17 11:23:28',
          update_id: 77498,
        },
        extension_id: 83930033,
        vulnerabilities: [],
      },
      {
        site_id: 122,
        version: '1.10.5',
        install_name: 'testsite1202',
        active: true,
        update: null,
        extension_id: 13958970,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'onepress/onepress.php',
    name: 'OnePress',
    type: 'theme',
    sites: [
      {
        site_id: 10,
        version: '1.4.1',
        install_name: 'testsite100',
        active: true,
        update: null,
        extension_id: 29987236,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        version: '1.13.3',
        install_name: 'testsite101',
        active: true,
        update: null,
        extension_id: 52394222,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.13.15',
        install_name: 'testsite102',
        active: true,
        update: {
          version: '2.4.4',
          released_at: '2022-07-05 09:30:52',
          update_id: 29182,
        },
        extension_id: 72482269,
        vulnerabilities: [],
      },
      {
        site_id: 90,
        version: '1.2.3',
        install_name: 'testsite900',
        active: true,
        update: {
          version: '2.4.4',
          released_at: '2022-07-05 09:30:52',
          update_id: 69429,
        },
        extension_id: 19846273,
        vulnerabilities: [],
      },
      {
        site_id: 91,
        version: '1.14.1',
        install_name: 'testsite901',
        active: true,
        update: {
          version: '2.4.4',
          released_at: '2022-07-05 09:30:52',
          update_id: 89455,
        },
        extension_id: 66932202,
        vulnerabilities: [],
      },
      {
        site_id: 92,
        version: '1.11.1',
        install_name: 'testsite902',
        active: true,
        update: {
          version: '2.4.4',
          released_at: '2022-07-05 09:30:52',
          update_id: 89628,
        },
        extension_id: 31226025,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'ashe/ashe.php',
    name: 'Ashe',
    type: 'theme',
    sites: [
      {
        site_id: 80,
        version: '1.4.5',
        install_name: 'testsite800',
        active: true,
        update: {
          version: '4.3.2',
          released_at: '2022-02-11 17:07:46',
          update_id: 98458,
        },
        extension_id: 96636891,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        version: '1.13.2',
        install_name: 'testsite801',
        active: true,
        update: {
          version: '4.3.2',
          released_at: '2022-02-11 17:07:46',
          update_id: 13093,
        },
        extension_id: 68709116,
        vulnerabilities: [],
      },
      {
        site_id: 82,
        version: '1.6.7',
        install_name: 'testsite802',
        active: true,
        update: {
          version: '4.3.2',
          released_at: '2022-02-11 17:07:46',
          update_id: 27079,
        },
        extension_id: 27771987,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'sinatra/sinatra.php',
    name: 'Sinatra',
    type: 'theme',
    sites: [
      {
        site_id: 40,
        version: '1.4.2',
        install_name: 'testsite400',
        active: true,
        update: {
          version: '2.5.2',
          released_at: '2021-10-13 10:30:23',
          update_id: 30110,
        },
        extension_id: 64915946,
        vulnerabilities: [],
      },
      {
        site_id: 41,
        version: '1.4.2',
        install_name: 'testsite401',
        active: true,
        update: null,
        extension_id: 30643578,
        vulnerabilities: [],
      },
      {
        site_id: 42,
        version: '1.9.8',
        install_name: 'testsite402',
        active: true,
        update: {
          version: '2.5.2',
          released_at: '2021-10-13 10:30:23',
          update_id: 13395,
        },
        extension_id: 33643683,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'shapely/shapely.php',
    name: 'Shapely',
    type: 'theme',
    sites: [
      {
        site_id: 40,
        version: '1.2.13',
        install_name: 'testsite400',
        active: true,
        update: {
          version: '5.3.1',
          released_at: '2022-05-18 12:44:42',
          update_id: 60345,
        },
        extension_id: 73410685,
        vulnerabilities: [],
      },
      {
        site_id: 41,
        version: '1.13.15',
        install_name: 'testsite401',
        active: true,
        update: {
          version: '5.3.1',
          released_at: '2022-05-18 12:44:42',
          update_id: 81159,
        },
        extension_id: 90675103,
        vulnerabilities: [],
      },
      {
        site_id: 42,
        version: '1.2.10',
        install_name: 'testsite402',
        active: true,
        update: null,
        extension_id: 59011957,
        vulnerabilities: [],
      },
      {
        site_id: 150,
        version: '1.12.14',
        install_name: 'testsite1500',
        active: true,
        update: {
          version: '5.3.1',
          released_at: '2022-05-18 12:44:42',
          update_id: 46624,
        },
        extension_id: 45161450,
        vulnerabilities: [],
      },
      {
        site_id: 151,
        version: '1.3.10',
        install_name: 'testsite1501',
        active: true,
        update: {
          version: '5.3.1',
          released_at: '2022-05-18 12:44:42',
          update_id: 85414,
        },
        extension_id: 78575947,
        vulnerabilities: [],
      },
      {
        site_id: 152,
        version: '1.3.1',
        install_name: 'testsite1502',
        active: true,
        update: null,
        extension_id: 84232704,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'colormag/colormag.php',
    name: 'ColorMag',
    type: 'theme',
    sites: [
      {
        site_id: 80,
        version: '1.12.9',
        install_name: 'testsite800',
        active: true,
        update: {
          version: '2.1.3',
          released_at: '2021-11-02 02:03:51',
          update_id: 40667,
        },
        extension_id: 97448836,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        version: '1.11.13',
        install_name: 'testsite801',
        active: true,
        update: null,
        extension_id: 18426764,
        vulnerabilities: [],
      },
      {
        site_id: 82,
        version: '1.6.2',
        install_name: 'testsite802',
        active: true,
        update: {
          version: '2.1.3',
          released_at: '2021-11-02 02:03:51',
          update_id: 58026,
        },
        extension_id: 50178269,
        vulnerabilities: [],
      },
      {
        site_id: 120,
        version: '1.1.13',
        install_name: 'testsite1200',
        active: true,
        update: null,
        extension_id: 4136927,
        vulnerabilities: [],
      },
      {
        site_id: 121,
        version: '1.13.1',
        install_name: 'testsite1201',
        active: true,
        update: null,
        extension_id: 57958199,
        vulnerabilities: [],
      },
      {
        site_id: 122,
        version: '1.1.15',
        install_name: 'testsite1202',
        active: true,
        update: null,
        extension_id: 85147813,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'bravada/bravada.php',
    name: 'Bravada',
    type: 'theme',
    sites: [
      {
        site_id: 80,
        version: '1.11.5',
        install_name: 'testsite800',
        active: true,
        update: {
          version: '4.1.1',
          released_at: '2022-01-25 05:16:57',
          update_id: 95227,
        },
        extension_id: 9677688,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        version: '1.14.4',
        install_name: 'testsite801',
        active: true,
        update: null,
        extension_id: 75587034,
        vulnerabilities: [],
      },
      {
        site_id: 82,
        version: '1.12.10',
        install_name: 'testsite802',
        active: true,
        update: {
          version: '4.1.1',
          released_at: '2022-01-25 05:16:57',
          update_id: 82826,
        },
        extension_id: 46149166,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'twentyfourteen/twentyfourteen.php',
    name: 'Twenty Fourteen',
    type: 'theme',
    sites: [
      {
        site_id: 20,
        version: '1.2.7',
        install_name: 'testsite200',
        active: true,
        update: {
          version: '3.4.4',
          released_at: '2022-05-24 16:20:46',
          update_id: 56208,
        },
        extension_id: 3990897,
        vulnerabilities: [],
      },
      {
        site_id: 21,
        version: '1.12.14',
        install_name: 'testsite201',
        active: true,
        update: null,
        extension_id: 40143026,
        vulnerabilities: [],
      },
      {
        site_id: 22,
        version: '1.15.12',
        install_name: 'testsite202',
        active: true,
        update: null,
        extension_id: 94192679,
        vulnerabilities: [],
      },
      {
        site_id: 30,
        version: '1.8.13',
        install_name: 'testsite300',
        active: true,
        update: null,
        extension_id: 4732532,
        vulnerabilities: [],
      },
      {
        site_id: 31,
        version: '1.14.6',
        install_name: 'testsite301',
        active: true,
        update: null,
        extension_id: 38879318,
        vulnerabilities: [],
      },
      {
        site_id: 32,
        version: '1.8.2',
        install_name: 'testsite302',
        active: true,
        update: null,
        extension_id: 46473945,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'customify/customify.php',
    name: 'Customify',
    type: 'theme',
    sites: [
      {
        site_id: 140,
        version: '1.15.12',
        install_name: 'testsite1400',
        active: true,
        update: {
          version: '4.2.4',
          released_at: '2022-05-01 07:44:59',
          update_id: 54690,
        },
        extension_id: 92539429,
        vulnerabilities: [],
      },
      {
        site_id: 141,
        version: '1.5.13',
        install_name: 'testsite1401',
        active: true,
        update: {
          version: '4.2.4',
          released_at: '2022-05-01 07:44:59',
          update_id: 84504,
        },
        extension_id: 64035704,
        vulnerabilities: [],
      },
      {
        site_id: 142,
        version: '1.10.15',
        install_name: 'testsite1402',
        active: true,
        update: null,
        extension_id: 71899735,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'newsup/newsup.php',
    name: 'Newsup',
    type: 'theme',
    sites: [
      {
        site_id: 10,
        version: '1.11.13',
        install_name: 'testsite100',
        active: true,
        update: {
          version: '3.3.3',
          released_at: '2021-08-23 23:19:34',
          update_id: 80444,
        },
        extension_id: 37272122,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        version: '1.11.13',
        install_name: 'testsite101',
        active: true,
        update: null,
        extension_id: 23582707,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.6.11',
        install_name: 'testsite102',
        active: true,
        update: null,
        extension_id: 15501931,
        vulnerabilities: [],
      },
      {
        site_id: 20,
        version: '1.14.12',
        install_name: 'testsite200',
        active: true,
        update: {
          version: '3.3.3',
          released_at: '2021-08-23 23:19:34',
          update_id: 95401,
        },
        extension_id: 55261297,
        vulnerabilities: [],
      },
      {
        site_id: 21,
        version: '1.5.8',
        install_name: 'testsite201',
        active: true,
        update: {
          version: '3.3.3',
          released_at: '2021-08-23 23:19:34',
          update_id: 56065,
        },
        extension_id: 6791516,
        vulnerabilities: [],
      },
      {
        site_id: 22,
        version: '1.11.3',
        install_name: 'testsite202',
        active: true,
        update: {
          version: '3.3.3',
          released_at: '2021-08-23 23:19:34',
          update_id: 18143,
        },
        extension_id: 81577662,
        vulnerabilities: [],
      },
      {
        site_id: 30,
        version: '1.5.15',
        install_name: 'testsite300',
        active: true,
        update: {
          version: '3.3.3',
          released_at: '2021-08-23 23:19:34',
          update_id: 45165,
        },
        extension_id: 23350286,
        vulnerabilities: [],
      },
      {
        site_id: 31,
        version: '1.2.7',
        install_name: 'testsite301',
        active: true,
        update: null,
        extension_id: 55690078,
        vulnerabilities: [],
      },
      {
        site_id: 32,
        version: '1.9.13',
        install_name: 'testsite302',
        active: true,
        update: null,
        extension_id: 21907371,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'twentyeleven/twentyeleven.php',
    name: 'Twenty Eleven',
    type: 'theme',
    sites: [
      {
        site_id: 70,
        version: '1.13.1',
        install_name: 'testsite700',
        active: true,
        update: {
          version: '4.3.1',
          released_at: '2022-06-09 01:07:38',
          update_id: 89740,
        },
        extension_id: 47460589,
        vulnerabilities: [],
      },
      {
        site_id: 71,
        version: '1.1.2',
        install_name: 'testsite701',
        active: true,
        update: null,
        extension_id: 6467358,
        vulnerabilities: [],
      },
      {
        site_id: 72,
        version: '1.13.5',
        install_name: 'testsite702',
        active: true,
        update: null,
        extension_id: 49917174,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'broadnews/broadnews.php',
    name: 'BroadNews',
    type: 'theme',
    sites: [
      {
        site_id: 30,
        version: '1.11.14',
        install_name: 'testsite300',
        active: true,
        update: null,
        extension_id: 69706521,
        vulnerabilities: [],
      },
      {
        site_id: 31,
        version: '1.11.15',
        install_name: 'testsite301',
        active: true,
        update: null,
        extension_id: 96779790,
        vulnerabilities: [],
      },
      {
        site_id: 32,
        version: '1.11.8',
        install_name: 'testsite302',
        active: true,
        update: null,
        extension_id: 87970192,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'twentytwelve/twentytwelve.php',
    name: 'Twenty Twelve',
    type: 'theme',
    sites: [
      {
        site_id: 40,
        version: '1.7.3',
        install_name: 'testsite400',
        active: true,
        update: null,
        extension_id: 15751972,
        vulnerabilities: [],
      },
      {
        site_id: 41,
        version: '1.3.7',
        install_name: 'testsite401',
        active: true,
        update: null,
        extension_id: 89522459,
        vulnerabilities: [],
      },
      {
        site_id: 42,
        version: '1.12.15',
        install_name: 'testsite402',
        active: true,
        update: {
          version: '4.3.2',
          released_at: '2021-12-12 19:30:22',
          update_id: 18370,
        },
        extension_id: 1114103,
        vulnerabilities: [],
      },
      {
        site_id: 120,
        version: '1.3.5',
        install_name: 'testsite1200',
        active: true,
        update: {
          version: '4.3.2',
          released_at: '2021-12-12 19:30:22',
          update_id: 19058,
        },
        extension_id: 17169854,
        vulnerabilities: [],
      },
      {
        site_id: 121,
        version: '1.11.1',
        install_name: 'testsite1201',
        active: true,
        update: null,
        extension_id: 50786205,
        vulnerabilities: [],
      },
      {
        site_id: 122,
        version: '1.13.4',
        install_name: 'testsite1202',
        active: true,
        update: {
          version: '4.3.2',
          released_at: '2021-12-12 19:30:22',
          update_id: 10543,
        },
        extension_id: 78887645,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'flavita/flavita.php',
    name: 'Flavita',
    type: 'theme',
    sites: [
      {
        site_id: 50,
        version: '1.15.4',
        install_name: 'testsite500',
        active: true,
        update: {
          version: '2.5.4',
          released_at: '2022-06-08 02:21:39',
          update_id: 65386,
        },
        extension_id: 55339572,
        vulnerabilities: [],
      },
      {
        site_id: 51,
        version: '1.9.11',
        install_name: 'testsite501',
        active: true,
        update: null,
        extension_id: 43251087,
        vulnerabilities: [],
      },
      {
        site_id: 52,
        version: '1.14.7',
        install_name: 'testsite502',
        active: true,
        update: null,
        extension_id: 34894451,
        vulnerabilities: [],
      },
      {
        site_id: 110,
        version: '1.7.14',
        install_name: 'testsite1100',
        active: true,
        update: {
          version: '2.5.4',
          released_at: '2022-06-08 02:21:39',
          update_id: 26090,
        },
        extension_id: 62600250,
        vulnerabilities: [],
      },
      {
        site_id: 111,
        version: '1.3.1',
        install_name: 'testsite1101',
        active: true,
        update: {
          version: '2.5.4',
          released_at: '2022-06-08 02:21:39',
          update_id: 76767,
        },
        extension_id: 98909592,
        vulnerabilities: [],
      },
      {
        site_id: 112,
        version: '1.15.13',
        install_name: 'testsite1102',
        active: true,
        update: null,
        extension_id: 2348653,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'lightning/lightning.php',
    name: 'Lightning',
    type: 'theme',
    sites: [
      {
        site_id: 40,
        version: '1.10.1',
        install_name: 'testsite400',
        active: true,
        update: null,
        extension_id: 46734673,
        vulnerabilities: [],
      },
      {
        site_id: 41,
        version: '1.2.14',
        install_name: 'testsite401',
        active: true,
        update: {
          version: '2.4.5',
          released_at: '2021-11-19 06:03:30',
          update_id: 33221,
        },
        extension_id: 93805641,
        vulnerabilities: [],
      },
      {
        site_id: 42,
        version: '1.14.2',
        install_name: 'testsite402',
        active: true,
        update: null,
        extension_id: 92919712,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'newsway/newsway.php',
    name: 'News Way',
    type: 'theme',
    sites: [
      {
        site_id: 20,
        version: '1.1.3',
        install_name: 'testsite200',
        active: true,
        update: {
          version: '3.3.3',
          released_at: '2022-04-15 20:54:37',
          update_id: 88249,
        },
        extension_id: 82671043,
        vulnerabilities: [],
      },
      {
        site_id: 21,
        version: '1.13.12',
        install_name: 'testsite201',
        active: true,
        update: {
          version: '3.3.3',
          released_at: '2022-04-15 20:54:37',
          update_id: 26393,
        },
        extension_id: 17640675,
        vulnerabilities: [],
      },
      {
        site_id: 22,
        version: '1.5.15',
        install_name: 'testsite202',
        active: true,
        update: null,
        extension_id: 51817613,
        vulnerabilities: [],
      },
      {
        site_id: 110,
        version: '1.11.5',
        install_name: 'testsite1100',
        active: true,
        update: {
          version: '3.3.3',
          released_at: '2022-04-15 20:54:37',
          update_id: 97976,
        },
        extension_id: 4705794,
        vulnerabilities: [],
      },
      {
        site_id: 111,
        version: '1.3.12',
        install_name: 'testsite1101',
        active: true,
        update: null,
        extension_id: 39290104,
        vulnerabilities: [],
      },
      {
        site_id: 112,
        version: '1.5.1',
        install_name: 'testsite1102',
        active: true,
        update: null,
        extension_id: 43713540,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'rifefree/rifefree.php',
    name: 'Rife Free',
    type: 'theme',
    sites: [
      {
        site_id: 130,
        version: '1.12.4',
        install_name: 'testsite1300',
        active: true,
        update: {
          version: '5.1.4',
          released_at: '2021-12-02 20:34:55',
          update_id: 90391,
        },
        extension_id: 13067056,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.3.12',
        install_name: 'testsite1301',
        active: true,
        update: {
          version: '5.1.4',
          released_at: '2021-12-02 20:34:55',
          update_id: 13107,
        },
        extension_id: 58801863,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.2.10',
        install_name: 'testsite1302',
        active: true,
        update: null,
        extension_id: 40306017,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'phlox/phlox.php',
    name: 'Phlox',
    type: 'theme',
    sites: [
      {
        site_id: 80,
        version: '1.10.11',
        install_name: 'testsite800',
        active: true,
        update: null,
        extension_id: 81395460,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        version: '1.15.12',
        install_name: 'testsite801',
        active: true,
        update: {
          version: '4.3.3',
          released_at: '2022-01-11 02:44:08',
          update_id: 28776,
        },
        extension_id: 56446232,
        vulnerabilities: [],
      },
      {
        site_id: 82,
        version: '1.12.2',
        install_name: 'testsite802',
        active: true,
        update: {
          version: '4.3.3',
          released_at: '2022-01-11 02:44:08',
          update_id: 36143,
        },
        extension_id: 39009417,
        vulnerabilities: [],
      },
      {
        site_id: 100,
        version: '1.8.3',
        install_name: 'testsite1000',
        active: true,
        update: {
          version: '4.3.3',
          released_at: '2022-01-11 02:44:08',
          update_id: 48556,
        },
        extension_id: 65560738,
        vulnerabilities: [],
      },
      {
        site_id: 101,
        version: '1.9.3',
        install_name: 'testsite1001',
        active: true,
        update: null,
        extension_id: 32483417,
        vulnerabilities: [],
      },
      {
        site_id: 102,
        version: '1.13.5',
        install_name: 'testsite1002',
        active: true,
        update: {
          version: '4.3.3',
          released_at: '2022-01-11 02:44:08',
          update_id: 33155,
        },
        extension_id: 50791030,
        vulnerabilities: [],
      },
      {
        site_id: 140,
        version: '1.1.14',
        install_name: 'testsite1400',
        active: true,
        update: null,
        extension_id: 6424352,
        vulnerabilities: [],
      },
      {
        site_id: 141,
        version: '1.7.5',
        install_name: 'testsite1401',
        active: true,
        update: {
          version: '4.3.3',
          released_at: '2022-01-11 02:44:08',
          update_id: 86909,
        },
        extension_id: 33451161,
        vulnerabilities: [],
      },
      {
        site_id: 142,
        version: '1.5.1',
        install_name: 'testsite1402',
        active: true,
        update: null,
        extension_id: 43945267,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'total/total.php',
    name: 'Total',
    type: 'theme',
    sites: [
      {
        site_id: 60,
        version: '1.3.8',
        install_name: 'testsite600',
        active: true,
        update: null,
        extension_id: 25577013,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.11.10',
        install_name: 'testsite601',
        active: true,
        update: {
          version: '2.5.2',
          released_at: '2022-03-21 15:57:23',
          update_id: 91804,
        },
        extension_id: 94633266,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.7.2',
        install_name: 'testsite602',
        active: true,
        update: {
          version: '2.5.2',
          released_at: '2022-03-21 15:57:23',
          update_id: 45252,
        },
        extension_id: 65636206,
        vulnerabilities: [],
      },
      {
        site_id: 100,
        version: '1.4.14',
        install_name: 'testsite1000',
        active: true,
        update: {
          version: '2.5.2',
          released_at: '2022-03-21 15:57:23',
          update_id: 91118,
        },
        extension_id: 21276157,
        vulnerabilities: [],
      },
      {
        site_id: 101,
        version: '1.7.8',
        install_name: 'testsite1001',
        active: true,
        update: null,
        extension_id: 98281877,
        vulnerabilities: [],
      },
      {
        site_id: 102,
        version: '1.4.1',
        install_name: 'testsite1002',
        active: true,
        update: {
          version: '2.5.2',
          released_at: '2022-03-21 15:57:23',
          update_id: 59384,
        },
        extension_id: 25288218,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'envoshopper/envoshopper.php',
    name: 'Envo Shopper',
    type: 'theme',
    sites: [
      {
        site_id: 80,
        version: '1.7.4',
        install_name: 'testsite800',
        active: true,
        update: {
          version: '3.4.1',
          released_at: '2021-11-23 08:03:57',
          update_id: 90162,
        },
        extension_id: 54599990,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        version: '1.4.11',
        install_name: 'testsite801',
        active: true,
        update: {
          version: '3.4.1',
          released_at: '2021-11-23 08:03:57',
          update_id: 75041,
        },
        extension_id: 71183937,
        vulnerabilities: [],
      },
      {
        site_id: 82,
        version: '1.12.7',
        install_name: 'testsite802',
        active: true,
        update: null,
        extension_id: 63691635,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'brite/brite.php',
    name: 'Brite',
    type: 'theme',
    sites: [
      {
        site_id: 90,
        version: '1.6.2',
        install_name: 'testsite900',
        active: true,
        update: null,
        extension_id: 63851233,
        vulnerabilities: [],
      },
      {
        site_id: 91,
        version: '1.7.10',
        install_name: 'testsite901',
        active: true,
        update: {
          version: '2.1.3',
          released_at: '2022-02-12 05:19:03',
          update_id: 48339,
        },
        extension_id: 50879103,
        vulnerabilities: [],
      },
      {
        site_id: 92,
        version: '1.6.2',
        install_name: 'testsite902',
        active: true,
        update: null,
        extension_id: 63914460,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'pathway/pathway.php',
    name: 'Pathway',
    type: 'theme',
    sites: [
      {
        site_id: 60,
        version: '1.13.8',
        install_name: 'testsite600',
        active: true,
        update: null,
        extension_id: 42690256,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.4.8',
        install_name: 'testsite601',
        active: true,
        update: null,
        extension_id: 18118308,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.4.7',
        install_name: 'testsite602',
        active: true,
        update: null,
        extension_id: 41970409,
        vulnerabilities: [],
      },
      {
        site_id: 80,
        version: '1.11.12',
        install_name: 'testsite800',
        active: true,
        update: {
          version: '3.4.3',
          released_at: '2022-02-05 15:02:25',
          update_id: 77073,
        },
        extension_id: 89539213,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        version: '1.8.14',
        install_name: 'testsite801',
        active: true,
        update: {
          version: '3.4.3',
          released_at: '2022-02-05 15:02:25',
          update_id: 77870,
        },
        extension_id: 97739487,
        vulnerabilities: [],
      },
      {
        site_id: 82,
        version: '1.10.9',
        install_name: 'testsite802',
        active: true,
        update: null,
        extension_id: 62753817,
        vulnerabilities: [],
      },
      {
        site_id: 120,
        version: '1.11.11',
        install_name: 'testsite1200',
        active: true,
        update: null,
        extension_id: 42534854,
        vulnerabilities: [],
      },
      {
        site_id: 121,
        version: '1.7.10',
        install_name: 'testsite1201',
        active: true,
        update: {
          version: '3.4.3',
          released_at: '2022-02-05 15:02:25',
          update_id: 47175,
        },
        extension_id: 67799036,
        vulnerabilities: [],
      },
      {
        site_id: 122,
        version: '1.8.5',
        install_name: 'testsite1202',
        active: true,
        update: null,
        extension_id: 74906283,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'twentyten/twentyten.php',
    name: 'Twenty Ten',
    type: 'theme',
    sites: [
      {
        site_id: 130,
        version: '1.12.10',
        install_name: 'testsite1300',
        active: true,
        update: {
          version: '2.3.5',
          released_at: '2022-05-25 16:48:20',
          update_id: 85672,
        },
        extension_id: 34196105,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.11.14',
        install_name: 'testsite1301',
        active: true,
        update: null,
        extension_id: 74569157,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.3.7',
        install_name: 'testsite1302',
        active: true,
        update: null,
        extension_id: 54481642,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'mesmerize/mesmerize.php',
    name: 'Mesmerize',
    type: 'theme',
    sites: [
      {
        site_id: 10,
        version: '1.8.2',
        install_name: 'testsite100',
        active: true,
        update: {
          version: '2.5.3',
          released_at: '2021-12-04 04:35:10',
          update_id: 24696,
        },
        extension_id: 92491016,
        vulnerabilities: [
          {
            external_id: 'wsscan uuid6',
            severity: 'high',
            fixed_in_version: '2.5.3',
            type: 'NO AUTHORISATION',
            description:
              'The software does not perform an authorization check when an actor attempts to access a resource or perform an action.',
            last_updated_at: '2021-12-04T12:38:29.000Z',
          },
        ],
      },
      {
        site_id: 11,
        version: '1.2.9',
        install_name: 'testsite101',
        active: true,
        update: null,
        extension_id: 35357573,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.8.6',
        install_name: 'testsite102',
        active: true,
        update: null,
        extension_id: 72491825,
        vulnerabilities: [],
      },
      {
        site_id: 60,
        version: '1.14.4',
        install_name: 'testsite600',
        active: true,
        update: {
          version: '2.5.3',
          released_at: '2021-12-04 04:35:10',
          update_id: 26594,
        },
        extension_id: 91986132,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.2.4',
        install_name: 'testsite601',
        active: true,
        update: {
          version: '2.5.3',
          released_at: '2021-12-04 04:35:10',
          update_id: 86064,
        },
        extension_id: 86909515,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.8.11',
        install_name: 'testsite602',
        active: true,
        update: null,
        extension_id: 63580257,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'qi/qi.php',
    name: 'Qi',
    type: 'theme',
    sites: [
      {
        site_id: 140,
        version: '1.7.10',
        install_name: 'testsite1400',
        active: true,
        update: null,
        extension_id: 26975395,
        vulnerabilities: [],
      },
      {
        site_id: 141,
        version: '1.12.4',
        install_name: 'testsite1401',
        active: true,
        update: null,
        extension_id: 742839,
        vulnerabilities: [],
      },
      {
        site_id: 142,
        version: '1.10.13',
        install_name: 'testsite1402',
        active: true,
        update: null,
        extension_id: 34545291,
        vulnerabilities: [],
      },
      {
        site_id: 150,
        version: '1.3.6',
        install_name: 'testsite1500',
        active: true,
        update: null,
        extension_id: 86434751,
        vulnerabilities: [],
      },
      {
        site_id: 151,
        version: '1.8.15',
        install_name: 'testsite1501',
        active: true,
        update: {
          version: '3.5.5',
          released_at: '2022-01-23 18:07:58',
          update_id: 22744,
        },
        extension_id: 91301100,
        vulnerabilities: [],
      },
      {
        site_id: 152,
        version: '1.5.14',
        install_name: 'testsite1502',
        active: true,
        update: null,
        extension_id: 58250053,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'gridmode/gridmode.php',
    name: 'GridMode',
    type: 'theme',
    sites: [
      {
        site_id: 30,
        version: '1.11.1',
        install_name: 'testsite300',
        active: true,
        update: null,
        extension_id: 97980171,
        vulnerabilities: [],
      },
      {
        site_id: 31,
        version: '1.15.12',
        install_name: 'testsite301',
        active: true,
        update: null,
        extension_id: 71754262,
        vulnerabilities: [],
      },
      {
        site_id: 32,
        version: '1.6.6',
        install_name: 'testsite302',
        active: true,
        update: null,
        extension_id: 69154953,
        vulnerabilities: [],
      },
      {
        site_id: 150,
        version: '1.10.13',
        install_name: 'testsite1500',
        active: true,
        update: null,
        extension_id: 29925051,
        vulnerabilities: [],
      },
      {
        site_id: 151,
        version: '1.14.11',
        install_name: 'testsite1501',
        active: true,
        update: null,
        extension_id: 15807209,
        vulnerabilities: [],
      },
      {
        site_id: 152,
        version: '1.11.1',
        install_name: 'testsite1502',
        active: true,
        update: null,
        extension_id: 97789402,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'blogstream/blogstream.php',
    name: 'Blogstream',
    type: 'theme',
    sites: [
      {
        site_id: 20,
        version: '1.12.10',
        install_name: 'testsite200',
        active: true,
        update: null,
        extension_id: 5156956,
        vulnerabilities: [],
      },
      {
        site_id: 21,
        version: '1.12.9',
        install_name: 'testsite201',
        active: true,
        update: null,
        extension_id: 51339274,
        vulnerabilities: [],
      },
      {
        site_id: 22,
        version: '1.3.13',
        install_name: 'testsite202',
        active: true,
        update: null,
        extension_id: 22829755,
        vulnerabilities: [],
      },
      {
        site_id: 80,
        version: '1.5.7',
        install_name: 'testsite800',
        active: true,
        update: null,
        extension_id: 2301606,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        version: '1.9.9',
        install_name: 'testsite801',
        active: true,
        update: {
          version: '2.1.5',
          released_at: '2021-10-13 04:58:05',
          update_id: 35850,
        },
        extension_id: 15308583,
        vulnerabilities: [],
      },
      {
        site_id: 82,
        version: '1.2.15',
        install_name: 'testsite802',
        active: true,
        update: null,
        extension_id: 74423368,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'blogsite/blogsite.php',
    name: 'BlogSite',
    type: 'theme',
    sites: [
      {
        site_id: 10,
        version: '1.4.1',
        install_name: 'testsite100',
        active: true,
        update: {
          version: '3.5.4',
          released_at: '2022-04-18 05:18:50',
          update_id: 57517,
        },
        extension_id: 18115862,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        version: '1.3.11',
        install_name: 'testsite101',
        active: true,
        update: null,
        extension_id: 12840911,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.1.8',
        install_name: 'testsite102',
        active: true,
        update: null,
        extension_id: 97775601,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'futurio/futurio.php',
    name: 'Futurio',
    type: 'theme',
    sites: [
      {
        site_id: 60,
        version: '1.4.12',
        install_name: 'testsite600',
        active: true,
        update: {
          version: '3.5.5',
          released_at: '2022-04-20 03:54:05',
          update_id: 70493,
        },
        extension_id: 61084912,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.11.13',
        install_name: 'testsite601',
        active: true,
        update: null,
        extension_id: 91211,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.8.14',
        install_name: 'testsite602',
        active: true,
        update: null,
        extension_id: 18282576,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'vantage/vantage.php',
    name: 'Vantage',
    type: 'theme',
    sites: [
      {
        site_id: 100,
        version: '1.6.7',
        install_name: 'testsite1000',
        active: true,
        update: null,
        extension_id: 5564643,
        vulnerabilities: [],
      },
      {
        site_id: 101,
        version: '1.14.13',
        install_name: 'testsite1001',
        active: true,
        update: {
          version: '3.4.4',
          released_at: '2022-03-07 06:34:02',
          update_id: 25250,
        },
        extension_id: 54717954,
        vulnerabilities: [],
      },
      {
        site_id: 102,
        version: '1.3.7',
        install_name: 'testsite1002',
        active: true,
        update: {
          version: '3.4.4',
          released_at: '2022-03-07 06:34:02',
          update_id: 68762,
        },
        extension_id: 19820674,
        vulnerabilities: [],
      },
      {
        site_id: 130,
        version: '1.15.15',
        install_name: 'testsite1300',
        active: true,
        update: {
          version: '3.4.4',
          released_at: '2022-03-07 06:34:02',
          update_id: 83526,
        },
        extension_id: 8332427,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.7.8',
        install_name: 'testsite1301',
        active: true,
        update: null,
        extension_id: 30145447,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.14.12',
        install_name: 'testsite1302',
        active: true,
        update: null,
        extension_id: 37090301,
        vulnerabilities: [],
      },
      {
        site_id: 140,
        version: '1.4.8',
        install_name: 'testsite1400',
        active: true,
        update: {
          version: '3.4.4',
          released_at: '2022-03-07 06:34:02',
          update_id: 42849,
        },
        extension_id: 77890382,
        vulnerabilities: [],
      },
      {
        site_id: 141,
        version: '1.12.3',
        install_name: 'testsite1401',
        active: true,
        update: {
          version: '3.4.4',
          released_at: '2022-03-07 06:34:02',
          update_id: 59333,
        },
        extension_id: 56572366,
        vulnerabilities: [],
      },
      {
        site_id: 142,
        version: '1.11.5',
        install_name: 'testsite1402',
        active: true,
        update: {
          version: '3.4.4',
          released_at: '2022-03-07 06:34:02',
          update_id: 18955,
        },
        extension_id: 47847678,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'refinedblocks/refinedblocks.php',
    name: 'Refined Blocks',
    type: 'theme',
    sites: [
      {
        site_id: 60,
        version: '1.11.3',
        install_name: 'testsite600',
        active: true,
        update: null,
        extension_id: 57332187,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.7.2',
        install_name: 'testsite601',
        active: true,
        update: {
          version: '5.2.1',
          released_at: '2022-05-24 21:16:07',
          update_id: 87838,
        },
        extension_id: 51734071,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.14.8',
        install_name: 'testsite602',
        active: true,
        update: {
          version: '5.2.1',
          released_at: '2022-05-24 21:16:07',
          update_id: 70632,
        },
        extension_id: 6318545,
        vulnerabilities: [],
      },
      {
        site_id: 110,
        version: '1.5.5',
        install_name: 'testsite1100',
        active: true,
        update: null,
        extension_id: 34873316,
        vulnerabilities: [],
      },
      {
        site_id: 111,
        version: '1.14.15',
        install_name: 'testsite1101',
        active: true,
        update: null,
        extension_id: 46909227,
        vulnerabilities: [],
      },
      {
        site_id: 112,
        version: '1.13.5',
        install_name: 'testsite1102',
        active: true,
        update: null,
        extension_id: 68312472,
        vulnerabilities: [],
      },
      {
        site_id: 150,
        version: '1.15.11',
        install_name: 'testsite1500',
        active: true,
        update: {
          version: '5.2.1',
          released_at: '2022-05-24 21:16:07',
          update_id: 42096,
        },
        extension_id: 75929249,
        vulnerabilities: [],
      },
      {
        site_id: 151,
        version: '1.11.8',
        install_name: 'testsite1501',
        active: true,
        update: {
          version: '5.2.1',
          released_at: '2022-05-24 21:16:07',
          update_id: 64877,
        },
        extension_id: 77179451,
        vulnerabilities: [],
      },
      {
        site_id: 152,
        version: '1.13.7',
        install_name: 'testsite1502',
        active: true,
        update: {
          version: '5.2.1',
          released_at: '2022-05-24 21:16:07',
          update_id: 99414,
        },
        extension_id: 39194350,
        vulnerabilities: [],
      },
    ],
  },
];

export default themes;
