import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { hot } from 'react-hot-loader';
import { colorWhite, gray100, topNavHeight } from 'design-tokens';
import { Ownership } from 'componentsDir/Ownership';
import useVerifyHash from 'commonsDir/hooks/useVerifyHash';
import { UnicornProvider, Box, Button } from '@wpengine/unicorn/components';
import NotificationsPanelLazy from 'componentsDir/Notifications/NotificationsPanel/NotificationsPanelLazy';
import startMSW from 'mocksDir/utils/startMSW';
import { AccountLookupType } from 'commonsDir/types/Accounts';
import { TopNavProvider } from './contexts/TopNavProvider';
import OmniSearch from './components/OmniSearch';
import ProfileDropdown from './components/ProfileDropdown';
import Dashboard from './components/Dashboard';
import SupportDropdown from './components/SupportDropdown';
import Cart from './components/Cart/Cart';

interface TopNavProps {
  allAccountsView: boolean;
  chatPinId: string;
  csrfToken: string;
  currentAccount: AccountLookupType;
  currentUserUuid: string;
  hasAccounts: boolean;
  hasEsm: boolean;
  hasNotificationsDeveloperAccess: boolean;
  hasWpeLabsAccess: boolean;
  impersonating: boolean;
  isAdmin: boolean;
  isAccountOwner: boolean;
  notificationsEnabled: boolean;
  semiPersistentCartEnabled: boolean;
  showOmniSearch: boolean;
  showSshKeys: boolean;
  verify: string;
}

const TopNav: React.FC<TopNavProps> = ({
  allAccountsView,
  chatPinId,
  csrfToken,
  currentAccount,
  currentUserUuid,
  hasAccounts,
  hasEsm,
  hasNotificationsDeveloperAccess,
  hasWpeLabsAccess,
  impersonating,
  isAdmin,
  isAccountOwner,
  notificationsEnabled,
  semiPersistentCartEnabled,
  showOmniSearch,
  showSshKeys,
  verify,
}) => {
  // Starts MSW mocking in the browser (only if enableMSW flag is set to 'true' in local storage).
  startMSW();

  useVerifyHash(
    `${isAdmin}${hasEsm}${hasNotificationsDeveloperAccess}`.replace(null, ''),
    verify
  );
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <UnicornProvider>
      <Ownership team="CAFE" inset>
        <TopNavProvider
          allAccountsView={allAccountsView}
          chatPinId={chatPinId}
          csrfToken={csrfToken}
          currentAccount={currentAccount}
          currentUserUuid={currentUserUuid}
          hasAccounts={hasAccounts}
          hasNotificationsDeveloperAccess={hasNotificationsDeveloperAccess}
          hasWpeLabsAccess={hasWpeLabsAccess}
          impersonating={impersonating}
          isAdmin={isAdmin}
          isAccountOwner={isAccountOwner}
          notificationsEnabled={notificationsEnabled}
          showOmniSearch={showOmniSearch}
          showSshKeys={showSshKeys}
          smallScreen={smallScreen}
        >
          <Button
            variant="outlined"
            size="small"
            sx={{
              top: '-100px',
              left: '32px',
              textAlign: 'center',
              position: 'fixed',
              background: theme.palette.background.default,
              zIndex: 2,
              '&:focus': {
                top: '32px',
              },
              '&:hover': {
                background: theme.palette.background.default,
              },
            }}
            href="#site_overview"
          >
            Skip to main content
          </Button>
          <Box
            alignItems="center"
            bgcolor={colorWhite}
            borderBottom={`2px solid ${gray100}`}
            display="flex"
            gap={2}
            height={topNavHeight}
            justifyContent="space-between"
            paddingX={2}
          >
            <Dashboard />
            <Box
              alignItems="center"
              display="flex"
              flexGrow="1"
              gap={1}
              justifyContent="flex-end"
            >
              {showOmniSearch && <OmniSearch />}
              <SupportDropdown />
              <NotificationsPanelLazy />
              <ProfileDropdown />
              {semiPersistentCartEnabled && <Cart />}
            </Box>
          </Box>
        </TopNavProvider>
      </Ownership>
    </UnicornProvider>
  );
};

export default hot(module)(TopNav);
