import * as React from 'react';
import { gray600 } from 'design-tokens';
import { NavItem, Toggle } from '@wpengine/unicorn/components';
import { useOwnershipToggleDispatcher } from '../useOwnershipToggleEvents';
import { useOwnershipToggle } from './useOwnershipToggle';

const OwnershipToggle = () => {
  const { checked, handleToggle } = useOwnershipToggle();
  useOwnershipToggleDispatcher(checked);
  return (
    <NavItem
      onClick={handleToggle}
      size="small"
      sx={theme => ({
        width: '100%',
        '.MuiSwitch-switchBase': {
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: theme.palette.admin.main,
          },
        },
        '&:hover': {
          '.MuiSwitch-track': {
            backgroundColor: gray600,
          },
          '.Mui-checked + .MuiSwitch-track': {
            backgroundColor: theme.palette.admin.dark,
          },
        },
        '&& .Mui-checked:hover + .MuiSwitch-track': {
          backgroundColor: theme.palette.admin.dark,
        },
      })}
    >
      <Toggle
        checked={checked}
        sx={{
          margin: 0,
        }}
        tabIndex={-1}
      />
      Show P&E ownership
    </NavItem>
  );
};

export default OwnershipToggle;
