import deleteSitesTagsHandler from 'componentsDir/ManageSiteTagsPage/mocks/msw/deleteSitesTags/handler';
import sitesBulkTagsHandler from './sitesBulkTags/handler';
import taggableSitesHandler from './taggableSites/handler';
import createSitesTagsHandler from './createSitesTags/handler';

const manageSiteTagsHandler = [
  ...createSitesTagsHandler,
  ...deleteSitesTagsHandler,
  ...sitesBulkTagsHandler,
  ...taggableSitesHandler,
];

export default manageSiteTagsHandler;
