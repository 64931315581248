import { rest } from 'msw';
import Responses from 'mocksDir/shared/data/mockAvailableLicenses';
import { ENDPOINTS } from 'mocksDir/shared/constants';

const handlers = [
  rest.get(`*/${ENDPOINTS.AVAILABLE_LICENSES}*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(Responses.sample));
  }),
];

export default handlers;
