import * as React from 'react';
import { useEffect } from 'react';

const useOmniSearch = (resultsVisible: boolean): void => {
  const onArrowPress = React.useRef((evt: React.KeyboardEvent): void => {
    const omnisearchResults = document.querySelector('#omni-search-results');
    const resultLinks = document.querySelectorAll('#omni-search-results a');
    if (!omnisearchResults || resultLinks.length === 0) return;

    const activeElem = document.activeElement;
    const activeMenuId = [].slice
      .call(resultLinks)
      .findIndex((m: any) => m === activeElem);

    if (evt.key === 'ArrowDown')
      handleArrowDown(omnisearchResults, resultLinks, activeElem, activeMenuId);
    else if (evt.key === 'ArrowUp')
      handleArrowUp(omnisearchResults, resultLinks, activeElem, activeMenuId);
    evt.preventDefault();
  });

  const handleArrowDown = (
    omnisearchResults: Element,
    resultLinks: NodeListOf<Element>,
    activeElem: Element,
    activeMenuId: number
  ): void => {
    if (
      !omnisearchResults.contains(activeElem) ||
      activeElem === resultLinks[resultLinks.length - 1]
    ) {
      (resultLinks[0] as any).focus();
    } else {
      const nextId = activeMenuId + 1;
      (resultLinks[nextId] as any).focus();
    }
  };

  const handleArrowUp = (
    omnisearchResults: Element,
    resultLinks: NodeListOf<Element>,
    activeElem: Element,
    activeMenuId: number
  ): void => {
    if (
      !omnisearchResults.contains(activeElem) ||
      activeElem === resultLinks[0]
    ) {
      (resultLinks[resultLinks.length - 1] as any).focus();
    } else {
      const previousId = activeMenuId - 1;
      (resultLinks[previousId] as any).focus();
    }
  };

  useEffect(() => {
    function handleKeyPress(e: any): void {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        onArrowPress.current(e);
      }
    }
    if (resultsVisible) {
      document.addEventListener('keydown', handleKeyPress);
    } else {
      document.removeEventListener('keydown', handleKeyPress);
    }

    return (): void => document.removeEventListener('keydown', handleKeyPress);
  }, [resultsVisible]);
};

export default useOmniSearch;
