import { rest } from 'msw';
import { ENDPOINTS, MSW_PARAMS } from 'componentsDir/AllSites/constants';
import {
  hasPageUrlQueryParam,
  getQueryParam,
} from 'mocksDir/utils/browserUtils';
import siteNotesStore from './data/siteNotes/store';
import { SiteNotesUpsertRequest } from '../types';
import staticResponses from './data/siteNotes/staticResponses';

const handlers = [
  rest.post(`*${ENDPOINTS.SITE_NOTES}*`, (req, res, ctx) => {
    const upsertRequestBody = req.body as SiteNotesUpsertRequest;
    if (!upsertRequestBody.siteId) {
      return res(ctx.status(400), ctx.json(staticResponses.missingSiteId));
    }

    const accountWpeId = Number(req.url.searchParams.get('accountWpeId'));

    if (!accountWpeId) {
      return res(
        ctx.status(400),
        ctx.json(staticResponses.missingAccountWpeId)
      );
    }
    if (hasPageUrlQueryParam(MSW_PARAMS.UNAUTHORIZED)) {
      return res(
        ctx.status(401),
        ctx.json(ctx.json(staticResponses.unauthorized))
      );
    }
    const userId = getQueryParam('mockUserId');
    const response = siteNotesStore.upsertSiteNotes(
      Number(upsertRequestBody.siteId),
      upsertRequestBody.content,
      Number(userId),
      accountWpeId
    );
    return res(ctx.status(200), ctx.json(response));
  }),
];

export default handlers;
