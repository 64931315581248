import * as React from 'react';
import { SvgIconProps } from '@mui/material';
import SvgTemplate from '../utils/SvgTemplate';

const ChevronUpSmallIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="chevron-up-small-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.30141 13.903C8.69658 14.2888 9.3297 14.2813 9.71552 13.8861L12 11.5462L14.2845 13.8861C14.6703 14.2813 15.3034 14.2888 15.6986 13.903C16.0938 13.5172 16.1013 12.8841 15.7155 12.4889L12.8367 9.54034C12.3774 9.06989 11.6226 9.06989 11.1633 9.54034L8.28448 12.4889C7.89866 12.8841 7.90624 13.5172 8.30141 13.903Z"
        />
      </SvgTemplate>
    );
  }
);

export default ChevronUpSmallIcon;
