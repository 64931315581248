import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const WPGraphQL: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="WPGraphQL logo" viewBox="0 0 128 128" {...props}>
      <circle cx="64" cy="64" r="64" fill="url(#paint0_radial_159_10684)" />
      <path
        d="M65.5239 56.2556C68.2608 56.2556 70.4795 54.0369 70.4795 51.3C70.4795 48.5632 68.2608 46.3445 65.5239 46.3445C62.787 46.3445 60.5684 48.5632 60.5684 51.3C60.5684 54.0369 62.787 56.2556 65.5239 56.2556Z"
        fill="white"
      />
      <path
        d="M102.588 74.4878C100.007 74.05 97.7689 76.0116 97.7359 78.5018C97.7111 80.5294 96.592 82.4291 94.6965 83.1476C91.1698 84.4898 87.8247 81.9046 87.8247 78.5555V51.5931C87.8247 40.1994 79.3755 30.3915 68.0521 29.1403C55.8903 27.794 45.3928 36.3011 43.5262 47.6741C43.5262 47.6823 43.5179 47.6906 43.5096 47.6906C33.4457 49.8875 26 58.8365 26 69.4703V87.6654C26 89.9326 27.8377 91.7703 30.1049 91.7703H39.3718C41.6348 91.7703 43.3527 89.9202 43.3445 87.6572C43.3321 84.2131 46.8505 81.5742 50.4805 83.1518C52.2314 83.9157 53.2638 85.7163 53.2556 87.6241C53.2473 89.912 55.1015 91.7662 57.3852 91.7662H66.4952C68.7624 91.7662 70.6 89.9285 70.6 87.6613V69.4951C70.6 68.8302 70.472 68.1612 70.1623 67.5748C69.3777 66.0757 67.8538 65.2291 66.2515 65.3159C66.0162 65.3283 65.7725 65.3365 65.5289 65.3365C57.7982 65.3365 51.4964 59.0471 51.4881 51.3164C51.4881 51.3123 51.4881 51.304 51.4881 51.2999L51.55 50.4657C52.058 43.5362 57.4678 37.8455 64.3973 37.3004C72.6483 36.6479 79.5737 43.181 79.5737 51.2958V78.3407C79.5737 84.6632 84.6656 90.7792 90.9261 91.6381C98.9541 92.7408 105.863 86.5752 105.999 78.7867C106.036 76.7137 104.641 74.8305 102.596 74.4837L102.588 74.4878ZM62.3367 73.7238V83.0981C62.3367 83.3252 62.1508 83.511 61.9237 83.511H61.1432C60.9697 83.511 60.8169 83.3995 60.7591 83.2343C58.9421 78.1053 54.0402 74.4258 48.3 74.4258C42.5598 74.4258 37.658 78.1095 35.8409 83.2343C35.7831 83.3995 35.6303 83.511 35.4527 83.511H34.6722C34.4451 83.511 34.2593 83.3252 34.2593 83.0981V69.4703C34.2593 63.4823 38.0048 58.3409 43.3279 56.3298C43.5592 56.2431 43.8111 56.3835 43.8689 56.623C45.9874 65.2333 53.1276 71.8985 61.9898 73.315C62.188 73.348 62.3367 73.5173 62.3367 73.7197V73.7238Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_159_10684"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(181.019)"
        >
          <stop stopColor="#0ECAD4" />
          <stop offset="1" stopColor="#7A45E5" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default WPGraphQL;
