import * as React from 'react';
import isNavActive from 'componentsDir/SideNav/utils/isNavActive';
import {
  addSideNavListener,
  notifySideNavListeners,
  removeSideNavListener,
} from 'componentsDir/SideNav/utils/SideNavListeners';
import {
  ChevronDownSmallIcon,
  ChevronUpSmallIcon,
} from '@wpengine/unicorn/icons';
import { Menu, MenuItem } from '@wpengine/unicorn/components';
import { blue700, blue800, gray50, gray100 } from 'design-tokens';
import SideNavContext from '../SideNavContext';
import SideNavOption, { SideNavOptionProps } from './SideNavOption';
import CollapseButton from '../CollapseButton/CollapseButton';

export interface SideNavDropdownProps {
  icon: React.ReactNode;
  links: SideNavOptionProps[];
  label: string;
}

const SideNavDropdown: React.FC<SideNavDropdownProps> = ({
  links,
  icon,
  label,
}) => {
  const { collapsed } = React.useContext(SideNavContext);
  const [childActive, setChildActive] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  React.useEffect(() => {
    links.forEach(child => {
      if (isNavActive(child.href)) {
        setChildActive(true);
        setExpanded(true);
      }
    });
  }, []);

  React.useEffect(() => {
    const handler = (activeTitle: string): void => {
      if (activeTitle !== label) {
        setExpanded(false);
      }
    };

    addSideNavListener('expandedItem', handler);

    return (): void => removeSideNavListener('expandedItem', handler);
  }, []);

  const onClickHandler = (): void => {
    if (!expanded) {
      notifySideNavListeners('expandedItem', label);
    }
    setExpanded(!expanded);
  };

  const handleClick = (event: {
    currentTarget: React.SetStateAction<HTMLElement>;
  }): void => {
    if (collapsed) {
      setAnchorEl(event.currentTarget);
    } else {
      onClickHandler();
    }
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const getHoverMenu = (): React.ReactElement | null => {
    if (collapsed) {
      return (
        <Menu
          id={`${label}-hover-menu`}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          style={{ marginLeft: '8px' }}
        >
          {links.map(link => (
            <MenuItem component="a" href={link.href} key={link.label}>
              {link.label}
            </MenuItem>
          ))}
        </Menu>
      );
    }
    return null;
  };

  const endIcon = expanded ? <ChevronUpSmallIcon /> : <ChevronDownSmallIcon />;

  return (
    <>
      {getHoverMenu()}
      <CollapseButton
        onClick={handleClick}
        startIcon={icon}
        endIcon={collapsed ? null : endIcon}
        label={label}
        sx={{
          '& .MuiButton-endIcon': {
            '.MuiSvgIcon-root': {
              fontSize: '24px',
            },
          },
          ...(childActive &&
            collapsed && {
              backgroundColor: gray100,
              color: blue700,
              '.MuiTypography-root': {
                fontWeight: '700',
              },
              '&:focus, &:focus-visible, &:hover': {
                backgroundColor: gray50,
                color: blue800,
              },
            }),
        }}
      />
      {!collapsed &&
        expanded &&
        links.map(link => (
          <SideNavOption key={link.label} href={link.href} label={link.label} />
        ))}
    </>
  );
};

export default SideNavDropdown;
