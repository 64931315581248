import { rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { mockTrends } from 'componentsDir/Dashboard/mocks/data';

const handlers = [
  rest.get('/widgets/trends', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(mockTrends));
  }),
];

export default handlers;
