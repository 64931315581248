import { rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { ENDPOINTS } from 'componentsDir/install/Cache/constants';

const psbClearCacheHandlers = [
  rest.post(`*${ENDPOINTS.PSB_INTEGRATION}/*`, (_req, _res, ctx) => {
    return customResponse(ctx.status(200), ctx.json({}));
  }),
];

export default psbClearCacheHandlers;
