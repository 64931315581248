import { rest } from 'msw';
import { generate_support_pin_path } from 'railsRoutes';

const handlers = [
  rest.post(generate_support_pin_path(), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        pin: '67890',
      })
    );
  }),
];

export default handlers;
