export default {
  source_environments: [
    {
      back_ups_url: '/deploys/data?install=endpointSite1&raw=1',
      disabled: false,
      environment: 'production',
      label: 'endpointSite1',
      move_only_to_production: false,
      site: 'endpointSite1',
      transferable: false,
      value: 'endpointSite1',
      wp_db_tables_url: '/deploys/wp_db_tables?install=endpointSite1',
    },
    {
      back_ups_url: '/deploys/data?install=endpointSite2&raw=1',
      disabled: false,
      environment: 'production',
      label: 'endpointSite2',
      move_only_to_production: false,
      site: 'endpointSite2',
      transferable: false,
      value: 'endpointSite2',
      wp_db_tables_url: '/deploys/wp_db_tables?install=endpointSite2',
    },
  ],
};
