import {
  SiteInfoInstall,
  SiteInfoResponse,
} from 'componentsDir/AllSites/types';
import { BLANK_UUID, SITE_UUID } from 'componentsDir/SiteTransfer/constants';

const mockPrdSiteInfoInstall: SiteInfoInstall = {
  name: 'prdInstall',
  siteEnv: 'production',
  domainName: 'https://fathertedcrilly.com',
  passwordProtected: true,
  uuid: BLANK_UUID,
};

const mockDevSiteInfoInstall: SiteInfoInstall = {
  ...mockPrdSiteInfoInstall,
  name: 'devInstall',
  siteEnv: 'development',
};

const mockStgSiteInfoInstall: SiteInfoInstall = {
  ...mockPrdSiteInfoInstall,
  name: 'stgInstall',
  siteEnv: 'staging',
};

const siteWithAllInstalls: SiteInfoResponse = {
  site: {
    name: 'myLovelySite',
    installs: [
      mockPrdSiteInfoInstall,
      mockStgSiteInfoInstall,
      mockDevSiteInfoInstall,
    ],
    transferable: false,
    sandbox: false,
    uuid: SITE_UUID,
    note: {
      id: 123,
      content: 'Life is like a box of chocolates.',
    },
    tags: [
      {
        id: 234,
        name: 'needs_upgrade',
      },
    ],
    account: {
      wpeId: 1,
    },
  },
};

const siteWithOnlyDev: SiteInfoResponse = {
  site: {
    ...siteWithAllInstalls.site,
    installs: [mockDevSiteInfoInstall],
    note: {
      id: 123,
      content: 'This site only contains one dev environment.',
    },
  },
};

const siteWithoutStg: SiteInfoResponse = {
  site: {
    ...siteWithAllInstalls.site,
    installs: [mockDevSiteInfoInstall, mockPrdSiteInfoInstall],
    note: {
      id: 123,
      content: 'This site contains only dev and production environments',
    },
  },
};

const siteWithoutPrd: SiteInfoResponse = {
  site: {
    ...siteWithAllInstalls.site,
    installs: [mockDevSiteInfoInstall, mockStgSiteInfoInstall],
    note: {
      id: 123,
      content: 'This site contains only dev and staging environments',
    },
  },
};

const transferableSite: SiteInfoResponse = {
  site: {
    ...siteWithAllInstalls.site,
    transferable: true,
    note: {
      id: 123,
      content: 'This is a transferable site.',
    },
  },
};

const sandboxSite: SiteInfoResponse = {
  site: {
    ...siteWithAllInstalls.site,
    sandbox: true,
    installs: [mockDevSiteInfoInstall],
    note: {
      id: 123,
      content: 'This is a sandbox site.',
    },
  },
};

export {
  siteWithAllInstalls,
  siteWithOnlyDev,
  transferableSite,
  siteWithoutStg,
  siteWithoutPrd,
  sandboxSite,
};
