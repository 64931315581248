import {
  Account,
  Install,
  ListSitesByUserResponse,
  Site,
} from 'componentsDir/AllSites/types';

interface Responses {
  [key: string]: ListSitesByUserResponse;
}

interface Samples {
  accounts: Account[];
  installs: Install[];
  sites: Site[];
}

export const samples: Samples = {
  accounts: [
    {
      name: 'account0',
      ownerEmails: 'hannah.mckenna@msw.ie',
      role: 'OWNER',
      wpeId: 1,
      pendingInstallCount: 1,
    },
    {
      name: 'account1',
      ownerEmails:
        'jonathon.yamada@msw.ie,vance.patual@msw.ie,another.owner@msw.ie',
      role: 'OWNER',
      wpeId: 2,
      pendingInstallCount: 0,
    },
    {
      name: 'account2',
      ownerEmails:
        'jonathon.yamada@msw.ie,vance.patual@msw.ie,another.owner@msw.ie',
      role: 'PARTIAL_WITHOUT_BILLING',
      wpeId: 3,
      pendingInstallCount: 0,
    },
    {
      name: 'account3',
      ownerEmails: 'hannah.mckenna@msw.ie',
      role: 'OWNER',
      wpeId: 1,
      pendingInstallCount: 0,
    },
    {
      name: 'account4',
      ownerEmails: 'hannah.mckenna@msw.ie',
      role: 'OWNER',
      wpeId: 5,
      pendingInstallCount: 5,
    },
  ],
  installs: [
    {
      avatar: {
        url:
          'https://storage.googleapis.com/wpe-cx-avatar-service-dev-processed/cee35afc-1a4f-4074-a91a-460391e9a8df_2e37e00b-511b-47ab-b7d5-b494e383d5a2.png?1693927270356894',
      },
      bandwidth: '0',
      bandwidthTotal: '0',
      billableVisits: '0',
      cluster: '0',
      domainName: 'http://install0.wpengine.com',
      name: 'install0',
      passwordProtected: false,
      phpVersion: '7.1',
      siteEnv: 'PRODUCTION',
      status: 'active',
      storage: '0',
      transferable: false,
      sandbox: false,
      uuid: '00000000-0000-0000-0000-000000000000',
      visitors: '0',
      wpVersion: '6.0',
    },
    {
      avatar: {
        url:
          'https://storage.googleapis.com/wpe-cx-avatar-service-dev-processed/cee35afc-1a4f-4074-a91a-460391e9a8df_2e37e00b-511b-47ab-b7d5-b494e383d5a2.png?1693927270356894',
      },
      bandwidth: '111',
      bandwidthTotal: '1111',
      billableVisits: '0',
      cluster: '111',
      domainName: 'http://install1.wpengine.com',
      name: 'install1',
      passwordProtected: false,
      phpVersion: '7.2',
      siteEnv: 'STAGING',
      status: 'active',
      storage: '1111',
      transferable: false,
      sandbox: false,
      uuid: '00000000-0000-0000-0000-000000000000',
      visitors: '1111',
      wpVersion: '6.0',
    },
    {
      avatar: {
        url:
          'https://storage.googleapis.com/wpe-cx-avatar-service-dev-processed/cee35afc-1a4f-4074-a91a-460391e9a8df_2e37e00b-511b-47ab-b7d5-b494e383d5a2.png?1693927270356894',
      },
      bandwidth: '222',
      bandwidthTotal: '2222',
      billableVisits: '22',
      cluster: '3333',
      domainName: 'http://install2.wpengine.com',
      name: 'install2',
      passwordProtected: false,
      phpVersion: '7.3',
      siteEnv: 'DEVELOPMENT',
      status: 'active',
      storage: '222',
      transferable: false,
      sandbox: false,
      uuid: '00000000-0000-0000-0000-000000000000',
      visitors: '0',
      wpVersion: '6.0',
    },
    {
      avatar: {
        url:
          'https://storage.googleapis.com/wpe-cx-avatar-service-dev-processed/cee35afc-1a4f-4074-a91a-460391e9a8df_2e37e00b-511b-47ab-b7d5-b494e383d5a2.png?1693927270356894',
      },
      bandwidth: '333',
      bandwidthTotal: '3333',
      billableVisits: '33',
      cluster: '3333',
      domainName: 'http://install3.wpengine.com',
      name: 'install3',
      passwordProtected: false,
      phpVersion: '7.3',
      siteEnv: 'DEVELOPMENT',
      status: 'active',
      storage: '3333',
      transferable: false,
      sandbox: false,
      uuid: '00000000-0000-0000-0000-000000000000',
      visitors: '0',
      wpVersion: '-1',
    },
    {
      avatar: {
        url:
          'https://storage.googleapis.com/wpe-cx-avatar-service-dev-processed/cee35afc-1a4f-4074-a91a-460391e9a8df_2e37e00b-511b-47ab-b7d5-b494e383d5a2.png?1693927270356894',
      },
      bandwidth: '444',
      bandwidthTotal: '4444',
      billableVisits: '44',
      cluster: '4444',
      domainName: 'http://install4.wpengine.com',
      name: 'install4',
      passwordProtected: false,
      phpVersion: '7.3',
      siteEnv: 'DEVELOPMENT',
      status: 'active',
      storage: '4444',
      transferable: false,
      sandbox: false,
      uuid: '00000000-0000-0000-0000-000000000000',
      visitors: '0',
      wpVersion: '-1',
    },
    {
      avatar: {
        url:
          'https://storage.googleapis.com/wpe-cx-avatar-service-dev-processed/cee35afc-1a4f-4074-a91a-460391e9a8df_2e37e00b-511b-47ab-b7d5-b494e383d5a2.png?1693927270356894',
      },
      bandwidth: '555',
      bandwidthTotal: '5555',
      billableVisits: '55',
      cluster: '555',
      domainName: 'http://install5.wpengine.com',
      name: 'install5',
      passwordProtected: false,
      phpVersion: '7.3',
      siteEnv: 'DEVELOPMENT',
      status: 'active',
      storage: '4444',
      transferable: false,
      sandbox: false,
      uuid: '00000000-0000-0000-0000-000000000000',
      visitors: '0',
      wpVersion: '-1',
    },
    {
      avatar: {
        url:
          'https://storage.googleapis.com/wpe-cx-avatar-service-dev-processed/cee35afc-1a4f-4074-a91a-460391e9a8df_2e37e00b-511b-47ab-b7d5-b494e383d5a2.png?1693927270356894',
      },
      bandwidth: '0',
      bandwidthTotal: '0',
      billableVisits: '0',
      cluster: '0',
      domainName: 'http://install6.wpengine.com',
      name: 'install6',
      passwordProtected: false,
      phpVersion: '7.1',
      siteEnv: 'PRODUCTION',
      status: 'active',
      storage: '0',
      transferable: false,
      sandbox: false,
      uuid: '00000000-0000-0000-0000-000000000000',
      visitors: '0',
      wpVersion: '6.0',
    },
    {
      avatar: {
        url:
          'https://storage.googleapis.com/wpe-cx-avatar-service-dev-processed/cee35afc-1a4f-4074-a91a-460391e9a8df_2e37e00b-511b-47ab-b7d5-b494e383d5a2.png?1693927270356894',
      },
      bandwidth: '0',
      bandwidthTotal: '0',
      billableVisits: '0',
      cluster: '0',
      domainName: 'http://install7.wpengine.com',
      name: 'install7',
      passwordProtected: false,
      phpVersion: '7.1',
      siteEnv: 'PRODUCTION',
      status: 'active',
      storage: '0',
      transferable: false,
      sandbox: false,
      uuid: '00000000-0000-0000-0000-000000000000',
      visitors: '0',
      wpVersion: '6.0',
    },
    {
      avatar: {
        url:
          'https://storage.googleapis.com/wpe-cx-avatar-service-dev-processed/cee35afc-1a4f-4074-a91a-460391e9a8df_2e37e00b-511b-47ab-b7d5-b494e383d5a2.png?1693927270356894',
      },
      bandwidth: '0',
      bandwidthTotal: '0',
      billableVisits: '0',
      cluster: '0',
      domainName: 'http://install8pw.wpengine.com',
      name: 'install8pw',
      passwordProtected: true,
      phpVersion: '7.1',
      siteEnv: 'DEVELOPMENT',
      status: 'active',
      storage: '0',
      transferable: false,
      sandbox: false,
      uuid: '00000000-0000-0000-0000-000000000000',
      visitors: '0',
      wpVersion: '6.0',
    },
    {
      avatar: {
        url:
          'https://storage.googleapis.com/wpe-cx-avatar-service-dev-processed/cee35afc-1a4f-4074-a91a-460391e9a8df_2e37e00b-511b-47ab-b7d5-b494e383d5a2.png?1693927270356894',
      },
      bandwidth: '0',
      bandwidthTotal: '0',
      billableVisits: '0',
      cluster: '0',
      domainName: 'http://install9sbx.wpengine.com',
      name: 'install9sbx',
      passwordProtected: false,
      phpVersion: '7.1',
      siteEnv: 'DEVELOPMENT',
      status: 'active',
      storage: '0',
      transferable: false,
      sandbox: true,
      uuid: '00000000-0000-0000-0000-000000000000',
      visitors: '0',
      wpVersion: '6.0',
    },
    {
      avatar: {
        url:
          'https://storage.googleapis.com/wpe-cx-avatar-service-dev-processed/cee35afc-1a4f-4074-a91a-460391e9a8df_2e37e00b-511b-47ab-b7d5-b494e383d5a2.png?1693927270356894',
      },
      bandwidth: '0',
      bandwidthTotal: '0',
      billableVisits: '0',
      cluster: '0',
      domainName: 'http://install10sbxpw.wpengine.com',
      name: 'install10sbxpw',
      passwordProtected: true,
      phpVersion: '7.1',
      siteEnv: 'DEVELOPMENT',
      status: 'active',
      storage: '0',
      transferable: false,
      sandbox: true,
      uuid: '00000000-0000-0000-0000-000000000000',
      visitors: '0',
      wpVersion: '6.0',
    },
    {
      avatar: {
        url:
          'https://storage.googleapis.com/wpe-cx-avatar-service-dev-processed/cee35afc-1a4f-4074-a91a-460391e9a8df_2e37e00b-511b-47ab-b7d5-b494e383d5a2.png?1693927270356894',
      },
      bandwidth: '0',
      bandwidthTotal: '0',
      billableVisits: '0',
      cluster: '0',
      domainName: 'http://install11xfer.wpengine.com',
      name: 'install11xfer',
      passwordProtected: false,
      phpVersion: '7.1',
      siteEnv: 'DEVELOPMENT',
      status: 'active',
      storage: '0',
      transferable: true,
      sandbox: false,
      uuid: '00000000-0000-0000-0000-000000000000',
      visitors: '0',
      wpVersion: '6.0',
    },
    {
      avatar: {
        url:
          'https://storage.googleapis.com/wpe-cx-avatar-service-dev-processed/cee35afc-1a4f-4074-a91a-460391e9a8df_2e37e00b-511b-47ab-b7d5-b494e383d5a2.png?1693927270356894',
      },
      bandwidth: '0',
      bandwidthTotal: '0',
      billableVisits: '0',
      cluster: '0',
      domainName: 'http://install12xferpw.wpengine.com',
      name: 'install12xferpw',
      passwordProtected: true,
      phpVersion: '7.1',
      siteEnv: 'DEVELOPMENT',
      status: 'active',
      storage: '0',
      transferable: true,
      sandbox: false,
      uuid: '00000000-0000-0000-0000-000000000000',
      visitors: '0',
      wpVersion: '6.0',
    },
  ],
  sites: [
    {
      account: {
        name: 'account0',
        ownerEmails: 'hannah.mckenna@msw.ie',
        role: 'OWNER',
        wpeId: 1,
        pendingInstallCount: 0,
      },
      installs: [
        {
          avatar: {
            url:
              'https://storage.googleapis.com/wpe-cx-avatar-service-dev-processed/cee35afc-1a4f-4074-a91a-460391e9a8df_2e37e00b-511b-47ab-b7d5-b494e383d5a2.png?1693927270356894',
          },
          bandwidth: '0',
          bandwidthTotal: '0',
          billableVisits: '0',
          cluster: '0',
          domainName: 'http://install0.wpengine.com',
          name: 'install0',
          passwordProtected: false,
          phpVersion: '7.1',
          siteEnv: 'PRODUCTION',
          status: 'active',
          storage: '0',
          transferable: false,
          sandbox: false,
          uuid: '00000000-0000-0000-0000-000000000000',
          visitors: '0',
          wpVersion: '6.0',
        },
      ],
      name: 'site name 0',
      siteId: 1,
      siteUuid: '0001',
      tagCount: 0,
      transferable: false,
      sandbox: false,
    },
  ],
};

export const responses: Responses = {
  differentOwners: {
    nextPageToken: '',
    remoteCallsFailure: false,
    numberOfSites: 5,
    numberOfFilteredSites: 0,
    pendingInstallCount: 0,
    firstPendingInstall: '',
    sites: [
      {
        account: samples.accounts[0],
        name: 'MSW site 1',
        siteId: 1,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        installs: [samples.installs[0]],
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
      {
        account: samples.accounts[1],
        name: 'MSW site 2',
        siteId: 2,
        siteUuid: '6d78872f-a930-453a-9816-a31581bc853e',
        installs: [samples.installs[1], samples.installs[2]],
        transferable: false,
        sandbox: false,
        tagCount: 2,
      },
      {
        account: samples.accounts[1],
        name: 'MSW site 3',
        siteId: 3,
        siteUuid: 'aa802c14-a2e4-49ae-9755-9bd65dffd291',
        installs: [],
        transferable: false,
        sandbox: false,
        tagCount: 3,
      },
      {
        account: samples.accounts[1],
        name: 'MSW site 4',
        siteId: 4,
        siteUuid: 'aa802c14-a2e4-49ae-9755-9bd65dffd291',
        installs: [samples.installs[6]],
        transferable: false,
        sandbox: false,
        tagCount: 3,
      },
      {
        account: samples.accounts[1],
        name: 'MSW site 5',
        siteId: 5,
        siteUuid: 'aa802c14-a2e4-49ae-9755-9bd65dffd291',
        installs: [samples.installs[7]],
        transferable: false,
        sandbox: false,
        tagCount: 3,
      },
    ],
  },
  secondPage: {
    nextPageToken: '1',
    remoteCallsFailure: false,
    numberOfSites: 6,
    numberOfFilteredSites: 0,
    pendingInstallCount: 0,
    firstPendingInstall: '',
    sites: [
      {
        account: samples.accounts[0],
        name: 'MSW site 4',
        siteId: 4,
        siteUuid: 'cc5b318d-e16b-46a2-94bd-1276b8ebd808',
        installs: [samples.installs[3]],
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
      {
        account: samples.accounts[1],
        name: 'MSW site 5',
        siteId: 5,
        siteUuid: 'b0938905-cdb0-45f0-94c3-899d79be109c',
        installs: [samples.installs[4]],
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
      {
        account: samples.accounts[1],
        name: 'MSW site 6',
        siteId: 6,
        siteUuid: 'a52fd454-95c2-4f3b-b140-0c85603f501b',
        installs: [samples.installs[5]],
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
    ],
  },
  emptyWpVersions: {
    nextPageToken: '',
    remoteCallsFailure: false,
    numberOfSites: 2,
    numberOfFilteredSites: 0,
    pendingInstallCount: 0,
    firstPendingInstall: 'proto1staging',
    sites: [
      {
        account: samples.accounts[0],
        name: 'MSW site 1',
        siteId: 1,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        installs: [samples.installs[3], samples.installs[4]],
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
      {
        account: samples.accounts[1],
        name: 'MSW site 2',
        siteId: 2,
        siteUuid: '6d78872f-a930-453a-9816-a31581bc853e',
        installs: [samples.installs[5]],
        transferable: false,
        sandbox: false,
        tagCount: 2,
      },
      {
        account: samples.accounts[1],
        name: 'MSW site 3',
        siteId: 3,
        siteUuid: 'aa802c14-a2e4-49ae-9755-9bd65dffd291',
        installs: [samples.installs[0]],
        transferable: false,
        sandbox: false,
        tagCount: 3,
      },
    ],
  },
  singleAccount: {
    nextPageToken: '',
    remoteCallsFailure: false,
    numberOfSites: 2,
    numberOfFilteredSites: 0,
    pendingInstallCount: 0,
    firstPendingInstall: 'proto1staging',
    sites: [
      {
        account: samples.accounts[3],
        name: 'MSW site 1',
        siteId: 1,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        installs: [samples.installs[3], samples.installs[4]],
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
    ],
  },
  singleAccountWithMultiplePendingInstalls: {
    nextPageToken: '',
    remoteCallsFailure: false,
    numberOfSites: 2,
    numberOfFilteredSites: 0,
    pendingInstallCount: 0,
    firstPendingInstall: 'proto1staging',
    sites: [
      {
        account: samples.accounts[4],
        name: 'MSW site 1',
        siteId: 1,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        installs: [samples.installs[3], samples.installs[4]],
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
    ],
  },
  singlePartialWithBillingAccount: {
    nextPageToken: '',
    remoteCallsFailure: false,
    numberOfSites: 1,
    numberOfFilteredSites: 0,
    pendingInstallCount: 0,
    firstPendingInstall: 'proto1staging',
    sites: [
      {
        account: { ...samples.accounts[0], role: 'PARTIAL_WITH_BILLING' },
        name: 'MSW site 1',
        siteId: 1,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        installs: [samples.installs[3], samples.installs[4]],
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
    ],
  },
  singlePartialWithoutBillingAccount: {
    nextPageToken: '',
    remoteCallsFailure: false,
    numberOfSites: 1,
    numberOfFilteredSites: 0,
    pendingInstallCount: 0,
    firstPendingInstall: 'proto1staging',
    sites: [
      {
        account: { ...samples.accounts[0], role: 'PARTIAL_WITHOUT_BILLING' },
        name: 'MSW site 1',
        siteId: 1,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        installs: [samples.installs[3], samples.installs[4]],
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
    ],
  },
  enoughSitesToFilter: {
    nextPageToken: '',
    remoteCallsFailure: false,
    numberOfSites: 11,
    numberOfFilteredSites: 11,
    pendingInstallCount: 0,
    firstPendingInstall: '',
    sites: [
      {
        account: samples.accounts[0],
        installs: [samples.installs[0]],
        name: `MSW site 0`,
        siteId: 0,
        siteUuid: '00000000-0000-0000-0000-000000000000',
        transferable: false,
        sandbox: false,
        tagCount: 0,
      },
      {
        account: samples.accounts[0],
        installs: [samples.installs[0]],
        name: `MSW site 1`,
        siteId: 1,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
      {
        account: samples.accounts[1],
        installs: [samples.installs[0]],
        name: `MSW site 2`,
        siteId: 2,
        siteUuid: '6d78872f-a930-453a-9816-a31581bc853e',
        transferable: false,
        sandbox: false,
        tagCount: 2,
      },
      {
        account: samples.accounts[0],
        installs: [samples.installs[0]],
        name: `MSW site 3`,
        siteId: 3,
        siteUuid: 'aa802c14-a2e4-49ae-9755-9bd65dffd291',
        transferable: false,
        sandbox: false,
        tagCount: 3,
      },
      {
        account: samples.accounts[1],
        installs: [samples.installs[6]],
        name: `MSW site 4`,
        siteId: 4,
        siteUuid: 'cc5b318d-e16b-46a2-94bd-1276b8ebd808',
        transferable: false,
        sandbox: false,
        tagCount: 4,
      },
      {
        account: samples.accounts[2],
        installs: [samples.installs[7]],
        name: `MSW site 5`,
        siteId: 5,
        siteUuid: 'b0938905-cdb0-45f0-94c3-899d79be109c',
        transferable: false,
        sandbox: false,
        tagCount: 5,
      },
      {
        account: samples.accounts[0],
        installs: [samples.installs[0]],
        name: `MSW site 6`,
        siteId: 6,
        siteUuid: 'a52fd454-95c2-4f3b-b140-0c85603f501b',
        transferable: false,
        sandbox: false,
        tagCount: 6,
      },
      {
        account: samples.accounts[0],
        installs: [samples.installs[0]],
        name: `MSW site 7`,
        siteId: 7,
        siteUuid: '2e656b71-98e9-4a20-a8d2-56897894f4f6',
        transferable: false,
        sandbox: false,
        tagCount: 7,
      },
      {
        account: samples.accounts[0],
        installs: [samples.installs[0]],
        name: `MSW site 8`,
        siteId: 8,
        siteUuid: '1afe24c8-f8a3-48df-af4a-8f0f6ecde48f',
        transferable: false,
        sandbox: false,
        tagCount: 8,
      },
      {
        account: samples.accounts[0],
        installs: [samples.installs[0]],
        name: `MSW site 9`,
        siteId: 9,
        siteUuid: 'a1e6a975-4101-4df9-b509-88c22de08c90',
        transferable: false,
        sandbox: false,
        tagCount: 9,
      },
      {
        account: samples.accounts[0],
        installs: [samples.installs[0]],
        name: `MSW site 10`,
        siteId: 10,
        siteUuid: '4c0449a8-95a3-4892-ba97-06987cdf844d',
        transferable: false,
        sandbox: false,
        tagCount: 10,
      },
    ],
  },
  emptySites: {
    nextPageToken: '',
    remoteCallsFailure: false,
    numberOfSites: 0,
    numberOfFilteredSites: 0,
    pendingInstallCount: 0,
    firstPendingInstall: '',
    sites: [],
  },
  emptyFilteredResults: {
    nextPageToken: '',
    remoteCallsFailure: false,
    numberOfSites: 0,
    numberOfFilteredSites: 0,
    pendingInstallCount: 0,
    firstPendingInstall: '',
    sites: [],
  },
  sdsBanners: {
    nextPageToken: '',
    remoteCallsFailure: true,
    numberOfSites: 5,
    numberOfFilteredSites: 0,
    pendingInstallCount: 1,
    firstPendingInstall: 'yourPendingInstall',
    sites: [
      {
        account: samples.accounts[0],
        installs: [
          {
            ...samples.installs[0],
            name: 'yourPendingInstall',
          },
        ],
        name: `MSW site 0`,
        siteId: 0,
        siteUuid: '00000000-0000-0000-0000-000000000000',
        transferable: false,
        sandbox: false,
        tagCount: 0,
      },
      {
        account: samples.accounts[0],
        installs: [samples.installs[0]],
        name: `MSW site 1`,
        siteId: 1,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
      {
        account: samples.accounts[0],
        installs: [samples.installs[0]],
        name: `MSW site 2`,
        siteId: 2,
        siteUuid: '6d78872f-a930-453a-9816-a31581bc853e',
        transferable: false,
        sandbox: false,
        tagCount: 2,
      },
      {
        account: samples.accounts[0],
        installs: [samples.installs[0]],
        name: `MSW site 3`,
        siteId: 3,
        siteUuid: 'aa802c14-a2e4-49ae-9755-9bd65dffd291',
        transferable: false,
        sandbox: false,
        tagCount: 3,
      },
      {
        account: samples.accounts[0],
        installs: [samples.installs[0]],
        name: `MSW site 4`,
        siteId: 4,
        siteUuid: 'cc5b318d-e16b-46a2-94bd-1276b8ebd808',
        transferable: false,
        sandbox: false,
        tagCount: 4,
      },
    ],
  },
  needsAttention: {
    nextPageToken: '',
    remoteCallsFailure: false,
    numberOfSites: 3,
    numberOfFilteredSites: 0,
    pendingInstallCount: 0,
    firstPendingInstall: '',
    sites: [
      {
        account: samples.accounts[0],
        name: 'MSW site 1',
        siteId: 1,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        installs: [samples.installs[0]],
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
      {
        account: samples.accounts[1],
        name: 'MSW site 6',
        siteId: 6,
        siteUuid: '6d78872f-a930-453a-9816-a31581bc853e',
        installs: [samples.installs[6]],
        transferable: false,
        sandbox: false,
        tagCount: 2,
      },
      {
        account: samples.accounts[1],
        name: 'MSW site 7',
        siteId: 3,
        siteUuid: 'aa802c14-a2e4-49ae-9755-9bd65dffd291',
        installs: [samples.installs[7]],
        transferable: false,
        sandbox: false,
        tagCount: 3,
      },
    ],
  },
  sandboxSites: {
    nextPageToken: '',
    remoteCallsFailure: false,
    numberOfSites: 3,
    numberOfFilteredSites: 0,
    pendingInstallCount: 0,
    firstPendingInstall: '',
    sites: [
      {
        account: samples.accounts[0],
        name: 'MSW site 1',
        siteId: 1,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        installs: [samples.installs[7]],
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
      {
        account: samples.accounts[0],
        name: 'MSW site 2',
        siteId: 2,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        installs: [samples.installs[8]],
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
      {
        account: samples.accounts[0],
        name: 'MSW sandbox 3',
        siteId: 3,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        installs: [samples.installs[9]],
        transferable: false,
        sandbox: true,
        tagCount: 1,
      },
      {
        account: samples.accounts[1],
        name: 'MSW sandbox 4',
        siteId: 4,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        installs: [samples.installs[10]],
        transferable: false,
        sandbox: true,
        tagCount: 1,
      },
      {
        account: samples.accounts[1],
        name: 'MSW transferable 5',
        siteId: 5,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        installs: [samples.installs[11]],
        transferable: true,
        sandbox: false,
        tagCount: 1,
      },
      {
        account: samples.accounts[1],
        name: 'MSW transferable 6',
        siteId: 6,
        siteUuid: '6d78872f-a930-453a-9816-a31581bc853e',
        installs: [samples.installs[12]],
        transferable: true,
        sandbox: false,
        tagCount: 2,
      },
    ],
  },
  singleSite: {
    nextPageToken: '',
    remoteCallsFailure: false,
    numberOfSites: 1,
    numberOfFilteredSites: 0,
    pendingInstallCount: 0,
    firstPendingInstall: '',
    sites: [
      {
        account: samples.accounts[0],
        name: 'MSW lone site ',
        siteId: 1,
        siteUuid: '25d3cb62-232d-40cb-bc88-55148053133c',
        installs: [samples.installs[7]],
        transferable: false,
        sandbox: false,
        tagCount: 1,
      },
    ],
  },
};
