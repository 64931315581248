import { InputBase, styled } from '@mui/material';
import { colorWhite, gray100, gray400 } from 'design-tokens';

const UnicornInput = styled(InputBase)(({ theme }) => ({
  border: `1px solid ${gray400}`,
  marginTop: 8,
  '&.Mui-focused:focus-within': {
    backgroundColor: colorWhite,
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
  },
  '&.Mui-disabled': {
    backgroundColor: gray100,
    borderColor: gray400,
    '& > .MuiSelect-icon path': {
      fill: gray400,
    },
  },
  '&.Mui-error': {
    borderColor: theme.palette.error.main,
  },
  '& > .MuiInputAdornment-positionStart': {
    marginLeft: 8,
    marginRight: 0,
  },
}));

export default UnicornInput;
