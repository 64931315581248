import { Components, Theme } from '@mui/material';
import { borderRadiusSmall, gray900, gray1200 } from 'design-tokens';

type colorType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';

const iconButtonTheme = (theme: Theme): Components['MuiIconButton'] => {
  const override = (color: colorType) => {
    return {
      color: theme.palette[color].main,
      ':hover': {
        backgroundColor: 'transparent',
        color: theme.palette[color].dark,
      },
      ':focus-visible': {
        outline: `2px solid ${theme.palette[color][90]}`,
      },
      ':active': {
        color: theme.palette[color][90],
      },
    };
  };

  return {
    defaultProps: {
      color: 'primary',
    },
    styleOverrides: {
      root: {
        color: theme.palette.secondary.light,
        borderRadius: borderRadiusSmall,
        ':hover': {
          backgroundColor: 'transparent',
          color: theme.palette.secondary.main,
        },
        ':focus-visible': {
          outline: `2px solid ${theme.palette.primary[90]}`,
        },
        ':active': {
          color: theme.palette.secondary.main,
        },
      },
      colorPrimary: override('primary'),
      colorSecondary: {
        color: gray900,
        ':hover': {
          backgroundColor: 'transparent',
          color: gray1200,
        },
        ':focus-visible': {
          outline: `2px solid ${gray900}`,
        },
        ':active': {
          color: gray1200,
        },
      },
      colorError: override('error'),
      colorWarning: override('warning'),
      colorInfo: override('info'),
      colorSuccess: override('success'),
    },
  };
};

export default iconButtonTheme;
