import { publish } from 'commonsDir/helpers/events';
import useUpdatePlan from '../hooks/useUpdatePlan';

const useAddPlan = () => {
  const { mutate } = useUpdatePlan();

  return (planId: string) => {
    publish('OPEN_CART');
    mutate(planId);
  };
};

export default useAddPlan;
