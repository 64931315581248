import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import { ENDPOINTS } from '../constants';

const handlers = [
  // GET */account_ready
  rest.get(`*/${ENDPOINTS.ACCOUNT_READY}`, (req, res, ctx) => {
    if (hasPageUrlQueryParam('provisioning')) {
      return customResponse(
        ctx.status(200),
        ctx.json({ ready: false, time: 1709305795 })
      );
    }

    return customResponse(
      ctx.status(200),
      ctx.json({ ready: true, time: 1709305795 })
    );
  }),
];

export default handlers;
