import * as React from 'react';
import usePinRefreshHandler from 'commonsDir/hooks/usePinRefresh';
import { ListItemText, Tooltip } from '@mui/material';
import { CopyIcon, KeyIcon, RefreshIcon } from '@wpengine/unicorn/icons';
import { Menu, MenuItem, Typography, Box } from '@wpengine/unicorn/components';
import { generate_support_pin_path } from 'railsRoutes';
import getCsrfToken from 'componentsDir/users/utils/userUtils';
import axios from 'axios';
import { gray1100, gray900, gray100, borderRadiusMedium } from 'design-tokens';
import { ItemInteractionProps } from '../../utils';
import CollapseButton from '../CollapseButton/CollapseButton';
import SideNavContext from '../SideNavContext';
import { SUPPORT_PIN_DISABLED_TOOLTIP } from '../shared/constants';

const HOVER_MENU_ID = 'unicorn-chat-pin-hover-menu';
const CHATPIN = 'chatpin';

export interface SideNavChatPinProps {
  accountUserId?: string;
  chatPinId?: string;
  disabled: boolean;
}

const SideNavChatPin: React.FC<SideNavChatPinProps> = ({
  chatPinId,
  accountUserId,
  disabled,
}) => {
  const { collapsed } = React.useContext(SideNavContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [tooltipText, setTooltipText] = React.useState('Click to copy');

  const [chatPin, setChatPin] = usePinRefreshHandler(chatPinId, accountUserId);

  React.useEffect(() => {
    setChatPin(chatPinId);
  }, []);

  const onRefreshPin = (): void => {
    axios({
      method: 'post',
      url: generate_support_pin_path(),
      headers: {
        'X-CSRF-TOKEN': getCsrfToken(),
      },
    }).then(response => {
      if (response.status === 200) {
        setChatPin(response.data.pin);
        document.dispatchEvent(
          new CustomEvent(CHATPIN, { detail: response.data.pin })
        );
      }
    });
  };

  const handleClick = (event: {
    currentTarget: React.SetStateAction<HTMLElement>;
  }): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleCopy = (): void => {
    navigator.clipboard.writeText(chatPin);
    handleClose();
  };

  const sx = {
    alignItems: 'center',
    backgroundColor: gray100,
    border: '4px solid transparent',
    borderRadius: borderRadiusMedium,
    display: 'flex',
    gap: '0.75rem',
    justifyContent: 'center',
    padding: '0.75rem 0.5rem',
    margin: 'auto 12px 0',
  };

  const renderCollapsedView = (): React.ReactElement => {
    return (
      <>
        {disabled && (
          <Tooltip
            title={SUPPORT_PIN_DISABLED_TOOLTIP}
            data-testid="support-pin-disabled-tooltip"
          >
            <Box
              sx={{
                height: 50,
                cursor: 'not-allowed',
                top: 50,
                zIndex: 1,
                position: 'relative',
              }}
            />
          </Tooltip>
        )}
        <CollapseButton
          onClick={handleClick}
          label="Support PIN"
          startIcon={<KeyIcon />}
          sx={{ marginTop: 'auto' }}
        />
        {!disabled && (
          <Menu
            id={HOVER_MENU_ID}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
            transformOrigin={{ vertical: 'center', horizontal: 'left' }}
            style={{ marginLeft: '8px' }}
          >
            <MenuItem
              onClick={handleCopy}
              sx={{ cursor: disabled ? 'not-allowed' : 'default' }}
            >
              <ListItemText>
                <strong>Support PIN</strong> {chatPin}{' '}
              </ListItemText>
              <CopyIcon
                style={{ marginLeft: '8px', height: '18px', width: '18px' }}
              />
            </MenuItem>
            <MenuItem onClick={onRefreshPin}>
              <ListItemText>Refresh PIN</ListItemText>
              <RefreshIcon
                style={{ marginLeft: '8px', height: '18px', width: '18px' }}
              />
            </MenuItem>
          </Menu>
        )}
      </>
    );
  };

  const renderExpandedView = (): React.ReactElement => {
    return (
      <>
        {disabled && (
          <Tooltip
            title={SUPPORT_PIN_DISABLED_TOOLTIP}
            data-testid="support-pin-disabled-tooltip"
          >
            <Box
              sx={{
                height: 75,
                cursor: 'not-allowed',
                top: 70,
                zIndex: 1,
                position: 'relative',
              }}
            />
          </Tooltip>
        )}
        <Box sx={sx} title="Support PIN">
          <KeyIcon />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'space-between',
              cursor: disabled ? 'not-allowed' : 'default',
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="button">Support PIN</Typography>
              {disabled ? (
                'n/a'
              ) : (
                <Tooltip
                  sx={{ padding: 0 }}
                  title={tooltipText}
                  onOpen={() => {
                    setTooltipText('Click to copy');
                  }}
                  data-testid="click-to-copy-tooltip"
                >
                  <Box
                    onClick={() => {
                      setTooltipText('Copied to clipboard');
                      navigator.clipboard.writeText(chatPin);
                    }}
                    component="button"
                    sx={{
                      all: 'unset',
                      borderColor: gray1100,
                      borderBottom: `1px dashed`,
                      cursor: 'pointer',
                      fontWeight: 580,
                      lineHeight: '24px',
                      fontSize: '14px',
                    }}
                  >
                    {chatPin}
                  </Box>
                </Tooltip>
              )}
            </Box>
            {!disabled && (
              <Tooltip sx={{ padding: 0 }} title="Refresh PIN">
                <Box
                  component="span"
                  aria-label="Refresh PIN"
                  className="focusable"
                  {...ItemInteractionProps(onRefreshPin)}
                >
                  <RefreshIcon
                    sx={{
                      color: gray900,
                      cursor: 'pointer',
                      display: 'block',
                      height: '18px',
                      width: '18px',
                    }}
                  />
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>
      </>
    );
  };

  return collapsed ? renderCollapsedView() : renderExpandedView();
};

export default SideNavChatPin;
