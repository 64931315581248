import { ListSubheader, styled } from '@mui/material';
import { gray100, gray1200 } from 'design-tokens';

const UnicornListSubheader = styled(ListSubheader)({
  '&.MuiListSubheader-root': {
    backgroundColor: gray100,
    color: gray1200,
    letterSpacing: '0.016em',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});

export default UnicornListSubheader;
