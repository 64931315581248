import * as React from 'react';
import { Divider as MuiDivider } from '@mui/material';

const Divider = () => {
  return (
    <MuiDivider
      sx={theme => ({
        borderColor: theme.palette.common.border,
        '&&': {
          margin: theme.spacing(1.5, -1.5),
        },
      })}
    />
  );
};

export default Divider;
