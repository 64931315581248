import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const StepperFiveFilledIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="stepper-five-filled-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM9.5 6C8.94772 6 8.5 6.44772 8.5 7V11.9267C8.5 12.3734 8.79628 12.7659 9.22588 12.8884C9.65549 13.0108 10.1142 12.8335 10.3497 12.4539C10.7287 11.8431 11.3611 11.5 12 11.5C12.986 11.5 14 12.3352 14 13.75C14 15.1648 12.986 16 12 16C11.1714 16 10.2553 15.4455 9.92845 14.6285C9.7233 14.1158 9.14131 13.8664 8.62854 14.0715C8.11578 14.2767 7.8664 14.8587 8.07155 15.3715C8.73049 17.0185 10.4327 18 12 18C14.1416 18 16 16.2176 16 13.75C16 11.2824 14.1416 9.5 12 9.5C11.4773 9.5 10.969 9.60725 10.5 9.806V8H14.5C15.0523 8 15.5 7.55228 15.5 7C15.5 6.44772 15.0523 6 14.5 6H9.5Z"
        />
      </SvgTemplate>
    );
  }
);
export default StepperFiveFilledIcon;
