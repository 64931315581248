import { all_sites_path, sites_path } from 'railsRoutes';

const isNavActive = (path: string): boolean => {
  const addOnsPaths = [
    '/products/global_edge_security',
    '/products/page_speed_boost',
    '/products/page_speed_boost/about',
    '/products/site_monitors/about',
    '/notifications/settings/slack',
    '/products/smart_plugin_manager',
    '/products/site_monitors#/',
    '/products/instant_store_search/',
  ];

  if (
    addOnsPaths.some((addOnsPath: string) =>
      window.location.pathname?.startsWith(addOnsPath)
    )
  ) {
    return path === '/addons';
  }

  const sitePaths = [sites_path(), all_sites_path()];

  if (
    sitePaths.some((sitePath: string) =>
      window.location.pathname?.startsWith(sitePath)
    )
  ) {
    return path.startsWith(sitePaths[0]) || path.startsWith(sitePaths[1]);
  }

  return path !== '' && path !== '#'
    ? window.location.pathname?.startsWith(path) ||
        window.location.pathname?.endsWith(path)
    : false;
};

export default isNavActive;
