import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const Themes: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="Themes logo" viewBox="0 0 64 64" {...props}>
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_793_830)"
      />
      <path
        d="M27.6118 35.8775C25.6974 34.1238 23.4206 33.8976 21.5028 34.5207C19.6366 35.1269 18.0437 36.5573 17.4045 38.2278C17.0056 39.2703 16.7048 40.7306 16.4818 42.1569C16.2558 43.6019 16.099 45.084 16.0062 46.2082C15.9138 47.3282 16.8723 48.2168 17.9568 48.0983C19.3558 47.9453 21.0087 47.7639 22.5363 47.4635C24.0341 47.1688 25.5567 46.7346 26.6169 46.0022C27.6124 45.3144 29.0487 43.9566 29.6531 42.1581C29.963 41.2361 30.0547 40.1922 29.7429 39.0907C29.4324 37.9939 28.7431 36.9139 27.6118 35.8775Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.0421 16.661C46.1892 15.7976 44.8016 15.7775 43.9241 16.6158L26.6268 33.1397C26.2274 33.5212 25.5944 33.5067 25.2129 33.1074C24.8314 32.708 24.8459 32.075 25.2453 31.6935L42.5426 15.1696C44.2094 13.5773 46.845 13.6155 48.4649 15.2554C50.0612 16.8714 50.0935 19.4604 48.5382 21.1157L32.31 38.3876C31.9319 38.7901 31.299 38.8099 30.8965 38.4317C30.494 38.0535 30.4743 37.4207 30.8525 37.0182L47.0807 19.7462C47.8995 18.8747 47.8824 17.5117 47.0421 16.661Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.936 47.2052C27.936 46.6529 28.3837 46.2052 28.936 46.2052H43.936C44.4883 46.2052 44.936 46.6529 44.936 47.2052C44.936 47.7574 44.4883 48.2052 43.936 48.2052H28.936C28.3837 48.2052 27.936 47.7574 27.936 47.2052Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.936 47.2052C45.936 46.6529 46.3837 46.2052 46.936 46.2052H48.936C49.4883 46.2052 49.936 46.6529 49.936 47.2052C49.936 47.7574 49.4883 48.2052 48.936 48.2052H46.936C46.3837 48.2052 45.936 47.7574 45.936 47.2052Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_793_830"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default Themes;
