import {
  Audit,
  PerformanceTest,
  PerformanceTestResult,
} from 'componentsDir/products/smart_plugin_manager/utils/interfaces';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';

type NestedPartial<T> = {
  [K in keyof T]?: T[K] extends object ? NestedPartial<T[K]> : T[K];
};
export const mockResult = ({
  scores,
  metrics,
  preset,
  createdAt,
}: NestedPartial<PerformanceTestResult>) => ({
  id: 1,
  preset: preset ?? 'desktop',
  createdAt: createdAt ?? '2020-03-19T12:14:07Z',
  scores: {
    performance: 100,
    accessibility: 100,
    seo: 100,
    bestPractices: 100,
    ...scores,
  },
  metrics: {
    firstContentfulPaint: 730,
    largestContentfulPaint: 758,
    cumulativeLayoutShift: 0.000022000000171829015,
    timeToFirstByte: 413,
    totalBlockingTime: 0,
    speedIndex: 960,
    timeToInteractive: 730,
    ...metrics,
  },
});
export const mockPerformanceTest = (
  initialData?: Partial<PerformanceTest>,
  performanceScore?: {
    desktop: number;
    mobile: number;
  }
) => ({
  id: Math.round(Math.random() * 1_000),
  finished_at: '2020-03-19T12:14:07Z',
  error_message: '',
  url: 'https://mocksite.com',
  results: [
    {
      preset: 'desktop',
      scores: {
        performance: performanceScore?.desktop ?? 100,
        accessibility: 100,
        seo: 100,
        best_practices: 100,
      },
      metrics: {
        firstContentfulPaint: 730,
        largestContentfulPaint: 758,
        cumulativeLayoutShift: 0.000022000000171829015,
        timeToFirstByte: 413,
        totalBlockingTime: 0,
        speedIndex: 960,
        timeToInteractive: 730,
      },
      created_at: '2020-03-19T12:14:07Z',
    },
    {
      preset: 'mobile',
      scores: {
        performance: performanceScore?.mobile ?? 95,
        accessibility: 94,
        seo: 93,
        best_practices: 92,
      },
      metrics: {
        firstContentfulPaint: 2052,
        largestContentfulPaint: 2369,
        cumulativeLayoutShift: 0.00016900000628083944,
        timeToFirstByte: 629,
        totalBlockingTime: 52,
        speedIndex: 3405,
        timeToInteractive: 2210,
      },
      created_at: '2020-03-19T12:14:07Z',
    },
  ],
  ...initialData,
});

export const mockAudit = (audit?: Partial<Audit>): Audit => {
  return {
    id: 'uses-responsive-images',
    scoreDisplayMode: 'metricSavings',
    displayValue: 'Potential savings of 232\u{a0}KiB',
    score: 0.5,
    ...audit,
  };
};

const performance = [
  rest.get(
    `*/v3/sites/performance-tests\\:batchGetLatestPsbTests`,
    (req, res, ctx) => {
      if (hasPageUrlQueryParam('performanceError')) {
        return customResponse(ctx.status(500));
      }

      const url = new URL(req.url);
      const siteIds = url.searchParams.get('ids').split(',');
      return customResponse(
        ctx.status(200),
        ctx.json(
          siteIds.reduce((result: Record<string, unknown>, id) => {
            // eslint-disable-next-line no-param-reassign
            result[id] = {
              before: mockPerformanceTest({
                results: [
                  mockResult({
                    preset: 'desktop',
                    scores: {
                      performance: 70,
                    },
                  }),
                  mockResult({
                    preset: 'mobile',
                    scores: {
                      performance: 60,
                    },
                  }),
                ],
              }),
              after: mockPerformanceTest(),
            };
            return result;
          }, {})
        )
      );
    }
  ),
  rest.get(
    '*/v3/sites/:siteId/performance-tests/:performanceTestId',
    (req, res, ctx) => {
      const { siteId, performanceTestId } = req.params;

      return customResponse(
        ctx.status(200),
        ctx.json({
          performance_test: mockPerformanceTest({
            id: +performanceTestId,
            siteId: +siteId,
          }),
        })
      );
    }
  ),
  rest.put('*/v3/sites/:siteId/performance-tests', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({
        performance_test: mockPerformanceTest(),
      })
    );
  }),
  rest.get('*/v3/sites/:siteId/performance-tests', (req, res, ctx) => {
    if (hasPageUrlQueryParam('performanceError')) {
      return customResponse(ctx.status(500));
    }

    if (hasPageUrlQueryParam('hasPerfectScore')) {
      return customResponse(
        ctx.status(200),
        ctx.json({
          performance_tests: [mockPerformanceTest()],
        })
      );
    }

    if (hasPageUrlQueryParam('hasNoTests')) {
      return customResponse(
        ctx.status(200),
        ctx.json({
          performance_tests: [],
        })
      );
    }

    if (hasPageUrlQueryParam('hasNoResults')) {
      return customResponse(
        ctx.status(200),
        ctx.json({
          performance_tests: [mockPerformanceTest({ results: [] })],
        })
      );
    }

    if (hasPageUrlQueryParam('hasEcomStorePerformance')) {
      return customResponse(
        ctx.status(200),
        ctx.json({
          performance_tests: [
            mockPerformanceTest({
              finishedAt: '2025-01-15T12:14:07Z',
              customName: 'cached-blanksite3',
              scheduledAt: '2024-01-24T12:55:15.000Z',
              results: [
                mockResult({
                  preset: 'desktop',
                  scores: {
                    performance: 85,
                  },
                }),
              ],
            }),
            mockPerformanceTest({
              finishedAt: '2025-01-15T12:15:07Z',
              customName: 'uncached-blanksite3',
              scheduledAt: '2024-01-24T12:55:15.000Z',
              results: [
                mockResult({
                  preset: 'desktop',
                  scores: {
                    performance: 78,
                  },
                }),
              ],
            }),
          ],
        })
      );
    }
    if (hasPageUrlQueryParam('hasEcomStorePerformanceError')) {
      return customResponse(
        ctx.status(200),
        ctx.json({
          performance_tests: [
            mockPerformanceTest({
              finishedAt: '2025-01-15T12:14:07Z',
              customName: 'cached-blanksite3',
              scheduledAt: '2024-01-24T12:55:15.000Z',
              results: [
                mockResult({
                  preset: 'desktop',
                  scores: {
                    performance: 85,
                  },
                }),
              ],
              errorMessage: 'Example Error',
            }),
            mockPerformanceTest({
              finishedAt: '2025-01-15T12:15:07Z',
              customName: 'uncached-blanksite3',
              scheduledAt: '2024-01-24T12:55:15.000Z',
              results: [
                mockResult({
                  preset: 'desktop',
                  scores: {
                    performance: 78,
                  },
                }),
              ],
              errorMessage: 'Lighthouse Error',
            }),
          ],
        })
      );
    }

    if (hasPageUrlQueryParam('hasMixedScore')) {
      return customResponse(
        ctx.status(200),
        ctx.json({
          performance_tests: [
            mockPerformanceTest({
              results: [
                mockResult({
                  preset: 'desktop',
                  scores: {
                    performance: 90,
                    accessibility: 87,
                    seo: 85,
                    bestPractices: 20,
                  },
                }),
                mockResult({
                  preset: 'mobile',
                  scores: {
                    performance: 95,
                    accessibility: 77,
                    seo: 90,
                    bestPractices: 60,
                  },
                }),
              ],
            }),
            mockPerformanceTest({
              results: [
                mockResult({
                  preset: 'desktop',
                  scores: {
                    performance: 95,
                    accessibility: 80,
                    seo: 70,
                    bestPractices: 20,
                  },
                }),
                mockResult({
                  preset: 'mobile',
                  scores: {
                    performance: 95,
                    accessibility: 77,
                    seo: 90,
                    bestPractices: 60,
                  },
                }),
              ],
            }),
          ],
        })
      );
    }

    return customResponse(
      ctx.status(200),
      ctx.json({
        performance_tests: [
          mockPerformanceTest({
            customName: 'safeMode:ENABLED',
            results: [
              mockResult({
                preset: 'desktop',
                scores: {
                  performance: 55,
                },
              }),
              mockResult({
                preset: 'mobile',
                scores: {
                  performance: 80,
                },
              }),
            ],
            errorMessage: '',
            id: 1,
          }),
          mockPerformanceTest({
            customName: `testnitro:mock`,
            finishedAt: '2020-03-17T20:14:07Z',
          }),
        ],
      })
    );
  }),
  rest.get(
    '*/v3/sites/:siteId/performance-tests/:testId/reports/:resultId',
    (req, res, ctx) => {
      return customResponse(
        ctx.status(200),
        ctx.json({
          audits: {
            'uses-responsive-images': mockAudit({
              id: 'uses-responsive-images',
              scoreDisplayMode: 'metricSavings',
              displayValue: 'Potential savings of 232 KiB',
              score: 0.5,
            }),
            'render-blocking-resources': mockAudit({
              id: 'render-blocking-resources',
              scoreDisplayMode: 'metricSavings',
              displayValue: 'Potential savings of 3,390 ms',
              score: 0,
            }),
            'offscreen-images': mockAudit({
              id: 'offscreen-images',
              scoreDisplayMode: 'metricSavings',
              displayValue: 'Potential savings of 236\u{a0}KiB',
              score: 0,
            }),
            'unminified-javascript': mockAudit({
              id: 'unminified-javascript',
              scoreDisplayMode: 'metricSavings',
              score: 1,
            }),
          },
        })
      );
    }
  ),
];

export default performance;
