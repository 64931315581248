import { StatusCodes } from 'http-status-codes';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import * as responses from '../data/responses';

const dplHandlers = [
  rest.get(`*/ecom-wp-config-relay/v1/templates`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.getTemplate)
    );
  }),
  rest.post(`*/ecom-wp-config-relay/v1/installs/dpl`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.NO_CONTENT));
  }),
  rest.post(`*/ecom-wp-config-relay/v1/templates/*/toggle`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.NO_CONTENT));
  }),
  rest.post(`*/ecom-wp-config-relay/v1/rules/toggle`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json({ rules: responses.getRules.rules })
    );
  }),
  rest.post(`*/ecom-wp-config-relay/v1/templates`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.NO_CONTENT));
  }),
  rest.post(`*/ecom-wp-config-relay/v1/templates/preview`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.previewSite)
    );
  }),
  rest.get(`*/ecom-wp-config-relay/v1/rules`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.getRules)
    );
  }),
  rest.post(`*/ecom-wp-config-relay/v1/rules/*/toggle`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.NO_CONTENT));
  }),
  rest.post(`*/ecom-wp-config-relay/v1/rules`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.NO_CONTENT));
  }),
  rest.put(`*/ecom-wp-config-relay/v1/rules`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.NO_CONTENT));
  }),
  rest.delete(`*/ecom-wp-config-relay/v1/rules/*`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.NO_CONTENT));
  }),
  rest.post(`*/ecom-wp-config-relay/v1/rules/preview`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.previewSite)
    );
  }),
  rest.get(`*/ecom-wp-config-relay/v1/installs/*/pages`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.getPages)
    );
  }),
  rest.get(`*/ecom-wp-config-relay/v1/installs/*/plugins`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.getPlugins)
    );
  }),
];

export default dplHandlers;
