import { rest } from 'msw';
import responses from 'componentsDir/AllSites/mocks/data/getFiltersByUser';
import { ENDPOINTS, MSW_PARAMS } from 'componentsDir/AllSites/constants';
import { hasPageUrlQueryParam } from 'mocksDir/utils/browserUtils';
import {
  setDOMAccounts,
  setSingleDOMAccount,
} from 'commonsDir/helpers/domTestHelpers';
import { SiteFilter } from 'componentsDir/AllSites/types';

const handlers = [
  rest.get(`*${ENDPOINTS.GET_ALL_SITES_FILTERS}*`, (req, res, ctx) => {
    const formatToDOMAccountLookupType = (account: SiteFilter) => ({
      id: +account.id,
      wpe_id: +account.id,
      nickname: account.name,
      uuid: `00000000-0000-0000-0000-00000000000${account.id}`,
    });

    if (hasPageUrlQueryParam(MSW_PARAMS.EMPTY_FILTERS)) {
      return res(ctx.status(500), ctx.json({}));
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.LESS_THAN_ELEVEN_ACCOUNT_FILTERS)) {
      setDOMAccounts(
        responses.lessThanElevenAccountFilters.accounts.map(account =>
          formatToDOMAccountLookupType(account)
        )
      );

      return res(
        ctx.status(200),
        ctx.json(responses.lessThanElevenAccountFilters)
      );
    }

    if (
      hasPageUrlQueryParam(MSW_PARAMS.SINGLE_ACCOUNT) ||
      req.url.searchParams.get('accountWpeId')
    ) {
      setSingleDOMAccount();
      return res(ctx.status(200), ctx.json(responses.singleAccountFilters));
    }

    setDOMAccounts(
      responses.allFilters.accounts.map(account =>
        formatToDOMAccountLookupType(account)
      )
    );

    return res(ctx.status(200), ctx.json(responses.allFilters));
  }),
];

export default handlers;
