import { MigrationStatusResponse } from 'componentsDir/SiteTransfer/types';

const mockMigrationRequiredResponse: MigrationStatusResponse = {
  migration_required: true,
  success: true,
};

const mockMigrationUnrequiredResponse: MigrationStatusResponse = {
  migration_required: false,
  success: true,
};

export { mockMigrationRequiredResponse, mockMigrationUnrequiredResponse };
