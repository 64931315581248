import { rest } from 'msw';
import { StatusCodes } from 'http-status-codes';

const handlers = [
  rest.patch('/installs/test/advanced', (req, res, ctx) => {
    const pageParams = new URLSearchParams(window.location.search);
    const isError = pageParams.get('error') === 'true';

    if (isError) {
      return res(ctx.status(400));
    }

    return res(ctx.status(200));
  }),

  rest.post('/installs/test/perm_reload_apply', (req, res, ctx) => {
    const pageParams = new URLSearchParams(window.location.search);
    const isError = pageParams.get('error') === 'true';

    if (isError) {
      return res(ctx.status(StatusCodes.BAD_REQUEST));
    }

    return res(ctx.status(200));
  }),

  rest.post('/installs/test/wp_cli', async (req, res, ctx) => {
    const pageParams = new URLSearchParams(window.location.search);
    const isError = pageParams.get('error') === 'true';

    if (isError) {
      return res(ctx.status(400));
    }

    return res(ctx.status(200), ctx.json({ response: 'output' }));
  }),
];

export default handlers;
