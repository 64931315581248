import {
  InstallOperationStatus,
  InstallOperationStatusType,
  TrackerStepStatusType,
} from '../types';

const test_user: string = 'Alan Smithee';

const staleDate = new Date('2024-01-01').toISOString();

export const inProgress: InstallOperationStatus[] = [
  {
    id: 404,
    jobType: 'backup',
    jobStatus: InstallOperationStatusType.inProgress,
    startedTime: new Date(Date.now()).toISOString(),
    completedTime: null,
    updatedAt: new Date(Date.now()).toISOString(),
    initiatedBy: test_user,
    metadata: {
      description: 'Backup description entered by user',
      stepStates: [
        {
          status: TrackerStepStatusType.current,
          timestamp: new Date(Date.now()).toISOString(),
        },
        {
          status: TrackerStepStatusType.pending,
          timestamp: null,
        },
      ],
    },
    startedBy: test_user,
  },
];

export const activeError: InstallOperationStatus[] = [
  {
    id: 404,
    jobType: 'backup',
    jobStatus: InstallOperationStatusType.error,
    startedTime: new Date(Date.now() - 120000).toISOString(),
    completedTime: new Date(Date.now()).toISOString(),
    updatedAt: new Date(Date.now()).toISOString(),
    initiatedBy: test_user,
    metadata: {
      description: 'Backup description entered by user',
      stepStates: [
        {
          status: TrackerStepStatusType.completed,
          timestamp: new Date(Date.now() - 120000).toISOString(),
        },
        {
          status: TrackerStepStatusType.completed,
          timestamp: new Date(Date.now()).toISOString(),
        },
      ],
    },
    startedBy: test_user,
  },
];

export const staleError: InstallOperationStatus[] = [
  {
    id: 404,
    jobType: 'backup',
    jobStatus: InstallOperationStatusType.error,
    startedTime: staleDate,
    completedTime: staleDate,
    updatedAt: staleDate,
    initiatedBy: test_user,
    metadata: {
      description: 'Backup description entered by user',
      stepStates: [
        {
          status: TrackerStepStatusType.completed,
          timestamp: staleDate,
        },
        {
          status: TrackerStepStatusType.completed,
          timestamp: staleDate,
        },
      ],
    },
    startedBy: test_user,
  },
];

export const activeCompleted: InstallOperationStatus[] = [
  {
    id: 404,
    jobType: 'backup',
    jobStatus: InstallOperationStatusType.success,
    startedTime: new Date(Date.now() - 120000).toISOString(),
    completedTime: new Date(Date.now()).toISOString(),
    updatedAt: new Date(Date.now()).toISOString(),
    initiatedBy: test_user,
    metadata: {
      description: 'Backup description entered by user',
      stepStates: [
        {
          status: TrackerStepStatusType.completed,
          timestamp: new Date(Date.now() - 120000).toISOString(),
        },
        {
          status: TrackerStepStatusType.completed,
          timestamp: new Date(Date.now()).toISOString(),
        },
      ],
    },
    startedBy: test_user,
  },
];

export const staleCompleted: InstallOperationStatus[] = [
  {
    id: 404,
    jobType: 'backup',
    jobStatus: InstallOperationStatusType.success,
    startedTime: staleDate,
    completedTime: staleDate,
    updatedAt: staleDate,
    initiatedBy: test_user,
    metadata: {
      description: 'Backup description entered by user',
      stepStates: [
        {
          status: TrackerStepStatusType.completed,
          timestamp: staleDate,
        },
        {
          status: TrackerStepStatusType.completed,
          timestamp: staleDate,
        },
      ],
    },
    startedBy: test_user,
  },
];

export const systemInitiated: InstallOperationStatus[] = [
  {
    id: 404,
    jobType: 'backup',
    jobStatus: InstallOperationStatusType.inProgress,
    startedTime: new Date(Date.now()).toISOString(),
    completedTime: new Date(Date.now()).toISOString(),
    updatedAt: new Date(Date.now()).toISOString(),
    initiatedBy: 'System',
    metadata: {
      description: 'Backup description entered by user',
      stepStates: [
        {
          status: TrackerStepStatusType.current,
          timestamp: new Date(Date.now()).toISOString(),
        },
        {
          status: TrackerStepStatusType.pending,
          timestamp: null,
        },
      ],
    },
    startedBy: null,
  },
];
