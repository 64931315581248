export const ACCOUNTS = { DEDICATED: { SITES_LIMIT: 999_999_999 } };
export const ENDPOINTS = {
  USER_ENTITLEMENTS: 'users/entitlements',
  AVAILABLE_LICENSES: 'available_licenses',
  ENTITLEMENTS: 'capi/private/entitlements',
  ACTIVATIONS: 'capi/private/activations',
  ACCOUNT_READY: 'account_ready',
  DATACENTERS: 'datacenters',
};

export const QUERY_KEYS = {
  USER_ENTITLEMENTS: 'user-entitlements',
  AVAILABLE_LICENSES: 'available-licenses',
  ENTITLEMENTS: 'entitlements',
  ADDON_ENTITLEMENT: 'addon-entitlement',
  ACTIVATIONS: 'activations',
};

export const MSW_PARAMS = {
  MULTIPLE_PARTIAL_ACCOUNTS: 'multiple-partial-accounts',
  MORE_THAN_TEN_ACCOUNTS: 'more-than-ten-accounts',
  GES_AVAILABLE: 'ges-available',
};
