import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import {
  ENDPOINTS,
  MSW_PARAMS,
} from 'componentsDir/ManageSiteTagsPage/constants';

const deleteSitesTagsHandler = [
  rest.put(`*/v1/${ENDPOINTS.DELETE_SITES_TAGS}`, (_req, _res, ctx) => {
    if (hasPageUrlQueryParam(MSW_PARAMS.DELETE_SITES_TAGS_SUCCESS)) {
      return customResponse(ctx.status(200), ctx.delay(500));
    }
    return customResponse(ctx.status(500));
  }),
];

export default deleteSitesTagsHandler;
