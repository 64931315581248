import { useEffect } from 'react';
import useLocalStorage from 'commonsDir/hooks/useLocalStorage';

export const CHATPIN = 'chatpin';

const usePinRefreshHandler = (
  initialPin: string,
  accountUserId: string
): [string, (i: string) => void] => {
  const [chatPin, setChatPin] = useLocalStorage<string>(
    `chatPinId[${accountUserId}]`,
    initialPin
  );

  useEffect(() => {
    document.addEventListener(CHATPIN, (e: CustomEvent) =>
      setChatPin(e.detail)
    );
    return () => {
      document.removeEventListener(CHATPIN, (e: CustomEvent) =>
        setChatPin(e.detail)
      );
    };
  }, []);

  return [chatPin, setChatPin];
};

export default usePinRefreshHandler;
