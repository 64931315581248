const mockMonitorResults: IMonitorData[] = [
  {
    averageDuration: 5.43429708480835,
    latestError:
      'NetworkError: DNS resolution failed for host: ivanlopez.wpenginedev.com',
    latestResult: 'FAILED',
    monitorId: '50040798-4668-4d39-bdcf-0426dabc625a',
    responseCode: -7,
    responseStatus: 'Timed out',
  },
];

export const failedResultFor = (
  monitor: IMonitor,
  opts?: Partial<IMonitorData>
) => {
  return {
    averageDuration: opts?.averageDuration || 5.43429708480835,
    latestError:
      opts?.latestError ||
      'NetworkError: DNS resolution failed for host: ivanlopez.wpenginedev.com',
    latestResult: opts?.latestResult || ('FAILED' as 'FAILED' | 'SUCCESS'),
    monitorId: monitor.monitorId,
    responseCode: opts?.responseCode || -7,
    responseStatus: opts?.responseStatus || 'Timed out',
  };
};

export const successResultFor = (
  monitor: IMonitor,
  opts?: Partial<IMonitorData>
) => {
  return {
    averageDuration: opts?.averageDuration || 191.72201538085938,
    latestResult: 'SUCCESS' as 'FAILED' | 'SUCCESS',
    monitorId: monitor.monitorId,
    responseCode: opts?.responseCode || 200,
    responseStatus: opts?.responseStatus || 'OK',
  };
};

export default mockMonitorResults;
