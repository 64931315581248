import axios, { AxiosInstance } from 'axios';
import values from 'lodash/values';
import isString from 'lodash/isString';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';

class SearchRepository {
  request: AxiosInstance;

  term: string;

  searchUrl: string;

  cachedResults: SearchResult[];

  constructor(searchUrl: string) {
    this.searchUrl = searchUrl;
    this.request = axios.create();
  }

  public async get(term: string): Promise<SearchResult[] | [string]> {
    if (term.includes(this.term)) {
      return this.filterCachedResults(term);
    }
    try {
      const response = await axios.get(this.searchUrl + term);
      this.term = term;
      this.cachedResults = response.data;
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        return ['error'];
      }
      return [];
    }
  }

  private filterCachedResults(term: string): SearchResult[] {
    return filter(this.cachedResults, result => {
      return reduce(
        values(result),
        (a: boolean, b: any) =>
          a ||
          (isString(b) ? b.toUpperCase().includes(term.toUpperCase()) : false),
        false
      );
    });
  }
}

export default SearchRepository;
