import React from 'react';
import { Actions, Addons, Header, Plan, Total } from './CartMenu/index';

type CartMenuProps = {
  onClose: () => void;
};

const CartMenu: React.FC<CartMenuProps> = ({ onClose }) => {
  return (
    <>
      <Header />
      <Plan />
      <Addons />
      <Total />
      <Actions onClose={onClose} />
    </>
  );
};

export default CartMenu;
