import { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material';
import Box from '../Box/Box';

export interface FlexProps extends BoxProps {
  children?: JSX.Element | JSX.Element[];
  className?: string;
}

// Flex: an implementation of a flexbox component from @rebass/grid
// @see https://github.com/rebassjs/grid
const Flex = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
});

export default Flex;
