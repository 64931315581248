import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const StepperFourIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="stepper-four-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 6.5C14.5 6.08825 14.2476 5.71859 13.8641 5.56866C13.4807 5.41872 13.0445 5.51917 12.7652 5.82172L6.7652 12.3217C6.49579 12.6136 6.42465 13.0372 6.58395 13.4011C6.74326 13.7649 7.1028 14 7.5 14H12.5V16.5C12.5 17.0523 12.9477 17.5 13.5 17.5C14.0523 17.5 14.5 17.0523 14.5 16.5V14C15.0523 14 15.5 13.5523 15.5 13C15.5 12.4477 15.0523 12 14.5 12V6.5ZM12.5 9.05765V12H9.78399L12.5 9.05765Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
        />
      </SvgTemplate>
    );
  }
);
export default StepperFourIcon;
