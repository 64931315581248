import * as React from 'react';
import { SvgIconProps } from '@mui/material';
import SvgTemplate from '../utils/SvgTemplate';

const ChevronDownSmallIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="chevron-down-small-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.30141 10.2845C8.69659 9.89866 9.3297 9.90624 9.71552 10.3014L12 12.6413L14.2845 10.3014C14.6703 9.90624 15.3034 9.89866 15.6986 10.2845C16.0938 10.6703 16.1013 11.3034 15.7155 11.6986L12.8367 14.6472C12.3774 15.1176 11.6226 15.1176 11.1633 14.6472L8.28448 11.6986C7.89866 11.3034 7.90624 10.6703 8.30141 10.2845Z"
        />
      </SvgTemplate>
    );
  }
);

export default ChevronDownSmallIcon;
