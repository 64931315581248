export const singleErrorLog = [
  {
    installName: 'lacroix',
    environment: 'PROD',
    type: 'error',
    date: '2022-10-21T15:21:32.000+00:00',
    severity: 'notice',
    client: '34.70.138.156:0',
    message:
      'auditor:scan=fingerprint {"blog_id":1,"kind":"wp-core","name":"wp-admin","slug":"wp-admin","ver":"6.0.2","sig":"v1:nohash"}, referer: https://lacroix.wpenginedev.com/wp-cron.php?doing_wp_cron=1666365692.8539910316467285156250',
  },
];

export default singleErrorLog;
