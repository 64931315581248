import { PlanSubscription } from 'componentsDir/addons/types';
import mockStartupAnnual from './mockStartupAnnual';

const mockStartupToScaleUpgrade: PlanSubscription = {
  ...mockStartupAnnual,
  selected_plan_name: 'Scale',
  selected_plan_id: 'scale',
  subscription_price: 70,
  plan_base_price: 300,
  selected_plan_price_monthly: 290,
  selected_plan_price_annually: 2900,
  terms: 'ON_RECEIPT',
  period_end: '2023-02-05T10:34:45.000+00:00',
  plan_features: [
    {
      name: 'Sites',
      value: '30',
    },
    {
      name: 'Visits / month',
      value: '400,000',
    },
    {
      name: 'Extra Storage',
      value: '30 GB',
    },
  ],
  plan_addons: [
    {
      id: 'sites',
      name: 'Additional sites',
      limit: 95,
      qty_included: 30,
      additional_qty: 1,
      subscription_qty: 0,
      price: 200.0,
      legacy_entitlement: false,
    },
    {
      id: 'monitoring-site',
      name: 'Site Monitoring',
      limit: 1000,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 50.0,
      legacy_entitlement: false,
      dropdown_value: '1 monitoring license',
    },
    {
      id: 'smart-plugin-manager',
      name: 'Smart Plugin Manager',
      limit: 1000,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 100.0,
      legacy_entitlement: false,
      dropdown_value: '1 environment license',
    },
    {
      id: 'global-edge-security',
      name: 'Global Edge Security',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 1000.0,
      legacy_entitlement: false,
    },
    {
      id: 'genesis-pro',
      name: 'Genesis Pro',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 300.0,
      legacy_entitlement: false,
    },
    {
      id: 'multisite',
      name: 'WordPress multisite',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 600.0,
      legacy_entitlement: false,
    },
    {
      id: 'smart-plugin-manager-5-pack',
      name: 'Smart Plugin Manager licenses (5)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 5,
      subscription_qty: 0,
      price: 300.0,
      legacy_entitlement: false,
      dropdown_value: '5 environment licenses',
    },
    {
      id: 'smart-plugin-manager-15-pack',
      name: 'Smart Plugin Manager licenses (15)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 15,
      subscription_qty: 0,
      price: 750.0,
      legacy_entitlement: false,
      dropdown_value: '15 environment licenses',
    },
    {
      id: 'smart-plugin-manager-25-pack',
      name: 'Smart Plugin Manager licenses (25)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 25,
      subscription_qty: 0,
      price: 1000.0,
      legacy_entitlement: false,
      dropdown_value: '25 environment licenses',
    },
    {
      id: 'smart-plugin-manager-50-pack',
      name: 'Smart Plugin Manager licenses (50)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 50,
      subscription_qty: 0,
      price: 1500.0,
      legacy_entitlement: false,
      dropdown_value: '50 environment licenses',
    },
    {
      id: 'smart-plugin-manager-100-pack',
      name: 'Smart Plugin Manager licenses (100)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 100,
      subscription_qty: 0,
      price: 2500.0,
      legacy_entitlement: false,
      dropdown_value: '100 environment licenses',
    },
    {
      id: 'smart-plugin-manager-150-pack',
      name: 'Smart Plugin Manager licenses (150)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 150,
      subscription_qty: 0,
      price: 3000.0,
      legacy_entitlement: false,
      dropdown_value: '150 environment licenses',
    },
    {
      id: 'monitoring-site-05',
      name: 'Site Monitoring licenses (5)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 5,
      subscription_qty: 0,
      price: 150.0,
      legacy_entitlement: false,
      dropdown_value: '5 monitoring licenses',
    },
    {
      id: 'monitoring-site-15',
      name: 'Site Monitoring licenses (15)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 15,
      subscription_qty: 0,
      price: 200.0,
      legacy_entitlement: false,
      dropdown_value: '15 monitoring licenses',
    },
    {
      id: 'monitoring-site-25',
      name: 'Site Monitoring licenses (25)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 25,
      subscription_qty: 0,
      price: 250.0,
      legacy_entitlement: false,
      dropdown_value: '25 monitoring licenses',
    },
    {
      id: 'local-storage-large',
      name: 'Local Storage Large',
      limit: 400,
      qty_included: 0,
      additional_qty: 100,
      subscription_qty: 0,
      price: 500.0,
      legacy_entitlement: false,
    },
  ],
};

export default mockStartupToScaleUpgrade;
