import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const SmartSearch: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="SmartSearch logo" viewBox="0 0 64 64" {...props}>
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_793_815)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.2929 41.2929C35.6834 40.9024 36.3166 40.9024 36.7071 41.2929L43.7071 48.2929C44.0976 48.6834 44.0976 49.3166 43.7071 49.7071C43.3166 50.0976 42.6834 50.0976 42.2929 49.7071L35.2929 42.7071C34.9024 42.3166 34.9024 41.6834 35.2929 41.2929Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5 44C32.9558 44 39 37.9558 39 30.5C39 23.0442 32.9558 17 25.5 17C18.0442 17 12 23.0442 12 30.5C12 37.9558 18.0442 44 25.5 44ZM28.6376 23.6879C27.3453 23.0926 25.9103 22.8773 24.5001 23.067C23.0899 23.2567 21.7628 23.8436 20.6737 24.7592C20.251 25.1146 19.6202 25.06 19.2648 24.6373C18.9094 24.2145 18.964 23.5837 19.3867 23.2283C20.7662 22.0685 22.4472 21.3251 24.2334 21.0848C26.0197 20.8445 27.8374 21.1173 29.4744 21.8713C31.1114 22.6253 32.5001 23.8294 33.4784 25.343C34.4568 26.8567 34.9844 28.6174 34.9997 30.4196C35.0043 30.9719 34.5604 31.4234 34.0082 31.428C33.4559 31.4327 33.0044 30.9888 32.9997 30.4365C32.9877 29.0137 32.5712 27.6237 31.7988 26.4287C31.0264 25.2337 29.93 24.2831 28.6376 23.6879ZM18.6388 25.384C19.1171 25.6601 19.281 26.2717 19.0048 26.75C18.8084 27.0903 18.639 27.4454 18.4981 27.8122C18.3002 28.3278 17.7218 28.5854 17.2062 28.3875C16.6906 28.1895 16.4331 27.6111 16.631 27.0955C16.8093 26.6309 17.0239 26.181 17.2728 25.75C17.5489 25.2717 18.1605 25.1078 18.6388 25.384Z"
        fill="#006BD6"
      />
      <path
        d="M43.0194 16.0971C43.0065 16.0325 43.0016 15.9666 42.9952 15.9011C42.9455 15.3952 42.5189 15 42 15C41.4811 15 41.0545 15.3952 41.0048 15.9011C40.9984 15.9666 40.9935 16.0325 40.9806 16.0971L40.7974 17.0129C40.7182 17.4088 40.4088 17.7182 40.0129 17.7974L39.0971 17.9806C39.0325 17.9935 38.9666 17.9984 38.9011 18.0048C38.3952 18.0545 38 18.4811 38 19C38 19.5189 38.3952 19.9455 38.9011 19.9952C38.9666 20.0016 39.0325 20.0065 39.0971 20.0194L40.0129 20.2026C40.4088 20.2818 40.7182 20.5912 40.7974 20.9871L40.9806 21.9029C40.9935 21.9675 40.9984 22.0334 41.0048 22.0989C41.0545 22.6048 41.4811 23 42 23C42.5189 23 42.9455 22.6048 42.9952 22.0989C43.0016 22.0334 43.0065 21.9675 43.0194 21.9029L43.2026 20.9871C43.2818 20.5912 43.5912 20.2818 43.9871 20.2026L44.9029 20.0194C44.9675 20.0065 45.0334 20.0016 45.0989 19.9952C45.6048 19.9455 46 19.5189 46 19C46 18.4811 45.6048 18.0545 45.0989 18.0048C45.0334 17.9984 44.9675 17.9935 44.9029 17.9806L43.9871 17.7974C43.5912 17.7182 43.2818 17.4088 43.2026 17.0129L43.0194 16.0971Z"
        fill="#006BD6"
      />
      <path
        d="M47.7694 24.8471C47.7565 24.7825 47.7521 24.7165 47.7436 24.6512C47.6952 24.2837 47.3807 24 47 24C46.6193 24 46.3048 24.2837 46.2564 24.6512C46.2479 24.7165 46.2435 24.7825 46.2306 24.8471L46.1307 25.3463C46.0516 25.7421 45.7421 26.0516 45.3463 26.1307L44.8471 26.2306C44.7825 26.2435 44.7165 26.2479 44.6512 26.2564C44.2837 26.3048 44 26.6193 44 27C44 27.3807 44.2837 27.6952 44.6512 27.7436C44.7165 27.7521 44.7825 27.7565 44.8471 27.7694L45.3463 27.8693C45.7421 27.9484 46.0516 28.2579 46.1307 28.6537L46.2306 29.1529C46.2435 29.2175 46.2479 29.2835 46.2564 29.3488C46.3048 29.7163 46.6193 30 47 30C47.3807 30 47.6952 29.7163 47.7436 29.3488C47.7521 29.2835 47.7565 29.2175 47.7694 29.1529L47.8693 28.6537C47.9484 28.2579 48.2579 27.9484 48.6537 27.8693L49.1529 27.7694C49.2175 27.7565 49.2835 27.7521 49.3488 27.7436C49.7163 27.6952 50 27.3807 50 27C50 26.6193 49.7163 26.3048 49.3488 26.2564C49.2835 26.2479 49.2175 26.2435 49.1529 26.2306L48.6537 26.1307C48.2579 26.0516 47.9484 25.7421 47.8693 25.3463L47.7694 24.8471Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_793_815"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default SmartSearch;
