import { context, DefaultRequestBody, PathParams, response, rest } from 'msw';
import {
  hasPageUrlQueryParam,
  customResponse,
  addDelay,
} from 'mocksDir/utils/browserUtils';
import TRANSFER_ENDPOINT from 'componentsDir/shared/repositories/TransferSiteApiClient/constants';
import {
  CreateTransferDetailsPayload,
  TransferDetails,
} from 'componentsDir/shared/repositories/TransferSiteApiClient/types';
import {
  mockDedicatedTransferDetails,
  mockFailedStartTransferResponse,
  mockPartialFailedStartTransferResponse,
  mockSuccessfulStartTransferResponse,
  mockSuccessfulStartTransferResponseNoMigration,
  mockTransferDetails,
} from 'mocksDir/shared/data/mockTransferDetails';
import {
  sites_transfer_log_activity_path,
  sites_transfer_account_geo_path,
  sites_transfer_migration_required_path,
} from 'railsRoutes';
import {
  mockEmptyAccountGeoResponse,
  mockSuccessfulAccountGeoResponse,
} from 'componentsDir/SiteTransfer/mocks/data/mockAccountGeoResponse';
import {
  mockMigrationRequiredResponse,
  mockMigrationUnrequiredResponse,
} from 'componentsDir/SiteTransfer/mocks/data/mockMigrationStatusResponse';
import { AccountGeoResponse } from 'componentsDir/SiteTransfer/types';

const handlers = [
  // GET */v1/transfer/:transferUuid
  rest.get<DefaultRequestBody, PathParams, TransferDetails>(
    `*${TRANSFER_ENDPOINT}/:transferUuid`,
    async (req, res, ctx) => {
      if (hasPageUrlQueryParam('transferError')) {
        return customResponse(ctx.status(500), ctx.json({ message: 'Oopsie' }));
      }

      if (hasPageUrlQueryParam('transferDedicated')) {
        return customResponse(
          ctx.status(200),
          ctx.json(mockDedicatedTransferDetails)
        );
      }

      if (hasPageUrlQueryParam('transferLoading')) {
        return response(addDelay('infinite'), context.json({}));
      }

      return customResponse(ctx.status(200), ctx.json(mockTransferDetails));
    }
  ),
  // POST */v1/transfer
  rest.post<CreateTransferDetailsPayload>(
    `*/${TRANSFER_ENDPOINT}`,
    (req, res, ctx) => {
      if (hasPageUrlQueryParam('transferError')) {
        return customResponse(ctx.status(500), ctx.json({ message: 'Oopsie' }));
      }

      if (hasPageUrlQueryParam('transferLoading')) {
        return response(addDelay('infinite'), context.json({}));
      }

      return customResponse(
        ctx.status(200),
        ctx.json({ ...mockTransferDetails, ...req.body })
      );
    }
  ),
  // PATCH */v1/transfer/:transferUuid
  rest.patch<Partial<TransferDetails>>(
    `*/${TRANSFER_ENDPOINT}`,
    (req, res, ctx) => {
      if (hasPageUrlQueryParam('transferError')) {
        return customResponse(ctx.status(500), ctx.json({ message: 'Oopsie' }));
      }

      if (hasPageUrlQueryParam('transferLoading')) {
        return response(addDelay('infinite'), context.json({}));
      }

      return customResponse(ctx.status(200), ctx.json(mockTransferDetails));
    }
  ),
  // POST */v1/transfer/:transferUuid/start
  rest.post(`*/${TRANSFER_ENDPOINT}/:transferUuid/start`, (req, res, ctx) => {
    if (hasPageUrlQueryParam('transferError')) {
      return customResponse(ctx.status(500), ctx.json({ message: 'Oopsie' }));
    }

    if (hasPageUrlQueryParam('transferLoading')) {
      return response(addDelay('infinite'), context.json({}));
    }

    // Return a 500 response if startTransferPartialFailed query param present
    // Indicates that the transfer started, but some installs failed to transfer
    if (hasPageUrlQueryParam('startTransferPartialFailed')) {
      return customResponse(
        ctx.status(500),
        ctx.json(mockPartialFailedStartTransferResponse)
      );
    }

    // Return a 500 response if startTransferFailed query param present
    // Indicates that the transfer started, but all installs failed to transfer
    if (hasPageUrlQueryParam('startTransferFailed')) {
      return customResponse(
        ctx.status(500),
        ctx.json(mockFailedStartTransferResponse)
      );
    }

    // Return a 200 response if the mockSuccessfulStartTransferResponseNoMigration query param is present
    // Indicates that the transfer succeeded, but a migration was not required
    if (hasPageUrlQueryParam('startTransferNoMigration')) {
      return customResponse(
        ctx.status(200),
        ctx.json(mockSuccessfulStartTransferResponseNoMigration)
      );
    }

    return customResponse(
      ctx.status(200),
      ctx.json(mockSuccessfulStartTransferResponse)
    );
  }),
  // GET /sites_transfer/account_geo?wpe_id=:wpe_id
  rest.get(`*${sites_transfer_account_geo_path()}`, (req, res, ctx) => {
    if (hasPageUrlQueryParam('transferError')) {
      return customResponse(ctx.status(500), ctx.json({ message: 'Oopsie' }));
    }

    if (hasPageUrlQueryParam('transferLoading')) {
      return response(addDelay('infinite'), context.json({}));
    }

    // Return a 404 response if accountGeoNotFound query param present
    // Indicates that the account_geo endpoint did not return a data center for the account, likely due to non-existant signup record.
    if (hasPageUrlQueryParam('accountGeoNotFound')) {
      return customResponse(
        ctx.status(404),
        ctx.json(mockEmptyAccountGeoResponse)
      );
    }

    // Return a randomized geo if randomizeAccountGeo query param present
    // It simulates a real response, updating the datacenter state + migration status payload when switching between different data centers.
    if (hasPageUrlQueryParam('randomizeAccountGeo')) {
      const dcs = ['uk', 'us', 'ca', 'de', 'au', 'jp', 'sg'];
      const dc = dcs[Math.floor(Math.random() * dcs.length)];
      const mockSuccessfulAccountGeoResponseRandom: AccountGeoResponse = {
        data_center: dc,
      };

      return customResponse(
        ctx.status(200),
        ctx.json(mockSuccessfulAccountGeoResponseRandom)
      );
    }

    return customResponse(
      ctx.status(200),
      ctx.json(mockSuccessfulAccountGeoResponse)
    );
  }),
  // POST /sites_transfer/migration_required
  rest.post(`*${sites_transfer_migration_required_path()}`, (req, res, ctx) => {
    if (hasPageUrlQueryParam('transferError')) {
      return customResponse(ctx.status(500), ctx.json({ message: 'Oopsie' }));
    }

    if (hasPageUrlQueryParam('transferLoading')) {
      return response(addDelay('infinite'), context.json({}));
    }

    // Mock request where migration is required.
    if (hasPageUrlQueryParam('migrationRequired')) {
      return customResponse(
        ctx.status(200),
        ctx.json(mockMigrationRequiredResponse)
      );
    }

    // Mock request where migration is not required
    return customResponse(
      ctx.status(200),
      ctx.json(mockMigrationUnrequiredResponse)
    );
  }),
  // POST /sites_transfer/log_activity
  rest.post(`*${sites_transfer_log_activity_path()}`, (req, res, ctx) => {
    return customResponse(ctx.status(200));
  }),
];

export default handlers;
