import { hasPageUrlQueryParam } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import { ENDPOINTS, MSW_PARAMS } from '../constants';
import { UpgradeSandboxSiteData, UpgradeSandboxSiteResponse } from '../types';

const handlers = [
  rest.post(`*${ENDPOINTS.UPGRADE_SANDBOX_SITE}*`, (req, res, ctx) => {
    if (hasPageUrlQueryParam(MSW_PARAMS.UPGRADE_SANDBOX_ERROR)) {
      return res(ctx.status(500), ctx.json({}));
    }

    const siteData: UpgradeSandboxSiteData = req.body as UpgradeSandboxSiteData;
    const resBody: UpgradeSandboxSiteResponse = { siteId: siteData.siteId };

    return res(ctx.status(200), ctx.json(resBody));
  }),
];

export default handlers;
