import * as React from 'react';
import { ThemeProvider } from '@mui/material';
import unicorn from '@wpengine/unicorn/theme';

interface UnicornProviderProps {
  children: React.ReactNode;
}

const UnicornProvider = ({ children }: UnicornProviderProps) => {
  return <ThemeProvider theme={unicorn}>{children}</ThemeProvider>;
};

export default UnicornProvider;
