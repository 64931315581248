import { rest } from 'msw';
import { ENDPOINTS } from 'componentsDir/AllSites/constants';

const handlers = [
  rest.put(`*${ENDPOINTS.SITE_TAG_DELETE}*`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json([]));
  }),
];

export default handlers;
