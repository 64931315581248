import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '@wpengine/unicorn/icons/utils/SvgTemplate';

const DoubleChevronRightIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate
        name="double-chevron-right-icon"
        ref={ref}
        viewBox="-2 0 24 14"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.459 7C20.459 6.73478 20.3536 6.48043 20.1661 6.29289L14.1661 0.292893C13.7756 -0.0976315 13.1424 -0.0976315 12.7519 0.292893C12.3614 0.683417 12.3614 1.31658 12.7519 1.70711L18.0448 7L12.7519 12.2929C12.3614 12.6834 12.3614 13.3166 12.7519 13.7071C13.1424 14.0976 13.7756 14.0976 14.1661 13.7071L20.1661 7.70711C20.3536 7.51957 20.459 7.26522 20.459 7ZM0.751879 13.7071C0.361355 13.3166 0.361355 12.6834 0.751879 12.2929L6.04477 7L0.751881 1.70711C0.361355 1.31658 0.361355 0.683417 0.751881 0.292893C1.1424 -0.0976315 1.77557 -0.0976315 2.16609 0.292893L8.16609 6.29289C8.55662 6.68342 8.55662 7.31658 8.16609 7.70711L2.16609 13.7071C1.77557 14.0976 1.1424 14.0976 0.751879 13.7071Z"
        />
      </SvgTemplate>
    );
  }
);
export default DoubleChevronRightIcon;
