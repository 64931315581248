import { validateStatus } from 'componentsDir/sites/repositories/ApiClientHelpers';
import axios, { AxiosInstance } from 'axios';

class BaseApiClient {
  csrfToken: string;

  axiosClient: AxiosInstance;

  constructor(csrfToken: string) {
    this.csrfToken = csrfToken;
    this.axiosClient = axios.create({
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': this.csrfToken,
        'Content-Type': 'application/json',
      },
      validateStatus,
    });
  }
}

export default BaseApiClient;
