import { rest } from 'msw';
import {
  hasPageUrlQueryParam,
  customResponse,
} from 'mocksDir/utils/browserUtils';
import { transformObject } from 'componentsDir/products/smart_plugin_manager/utils/functions';
import {
  toCamelCase,
  toSnakeCase,
} from 'componentsDir/products/smart_plugin_manager/utils/string';
import { ENDPOINTS } from 'mocksDir/shared/constants';
import mockDataStore from 'componentsDir/SiteMonitoring/mocks/store';
import {
  mockActivations,
  mockEmptyActivations,
} from 'mocksDir/shared/data/mockActivations';
import { ActivationWrite } from 'componentsDir/shared/repositories/ActivationsPrivateAPIClient/types';

// Use page url query params to respond with different mock data.
// Example usages:
// http://localhost:3000/capi/private/activations?activationsGetError=true        |   Returns error for GET activations.
// http://localhost:3000/capi/private/activations?activationsPostError=true       |   Returns error for POST activations.
// http://localhost:3000/capi/private/activations?activationsPatchError=true      |   Returns error for PATCH activations.
// http://localhost:3000/capi/private/activations?activationsPatchError=true      |   Returns error for PATCH activations.
// http://localhost:3000/capi/private/activations?noActivations=true              |   Returns empty activations array.

const handlers = [
  // GET */capi/private/activations
  rest.get(`*/${ENDPOINTS.ACTIVATIONS}`, (req, res, ctx) => {
    if (hasPageUrlQueryParam('activationsGetError')) {
      return customResponse(ctx.status(500), ctx.json({ message: 'Oopsie' }));
    }

    if (hasPageUrlQueryParam('noActivations')) {
      return customResponse(
        ctx.status(200),
        ctx.json({ mockEmptyActivations })
      );
    }

    const product = req.url.searchParams.get('product');
    const monitoringProduct = 'monitoring-site';

    if (product === monitoringProduct) {
      mockDataStore.logRequest(req);
      const activations = mockDataStore.getActivations();
      const activationsResponse = {
        activations: transformObject(activations, toSnakeCase),
      };

      return customResponse(ctx.status(200), ctx.json(activationsResponse));
    }

    return customResponse(ctx.status(200), ctx.json(mockActivations));
  }),
  // POST */capi/private/activations
  rest.post<ActivationWrite>(`*/${ENDPOINTS.ACTIVATIONS}`, (req, res, ctx) => {
    if (hasPageUrlQueryParam('activationsPostError')) {
      return customResponse(ctx.status(500), ctx.json({ message: 'Oopsie' }));
    }

    const { product_namespace } = req.body;
    const monitoringProduct = 'uma-newrelic';

    if (product_namespace === monitoringProduct) {
      if (hasPageUrlQueryParam('activationDelay')) {
        mockDataStore.setActivationDelay(1000);
      }
      mockDataStore.logRequest(req);
      const reqBody = transformObject(req.body, toCamelCase);
      const activations = mockDataStore.createActivation(
        reqBody.context as IActivationCreatePayload
      );
      const activationsResponse = transformObject(activations, toSnakeCase);

      return customResponse(ctx.status(200), ctx.json(activationsResponse));
    }

    return customResponse(
      ctx.status(200),
      ctx.json(mockActivations.activations.pop())
    );
  }),
  // PATCH */capi/private/activations
  rest.patch<ActivationWrite>(`*/${ENDPOINTS.ACTIVATIONS}`, (req, res, ctx) => {
    if (hasPageUrlQueryParam('activationsPatchError')) {
      return customResponse(ctx.status(500), ctx.json({ message: 'Oopsie' }));
    }

    const { product_namespace } = req.body;
    const monitoringProduct = 'uma-newrelic';

    if (product_namespace === monitoringProduct) {
      if (hasPageUrlQueryParam('activationDelay')) {
        mockDataStore.setActivationDelay(1000);
      }
      mockDataStore.logRequest(req);
      const { searchParams } = req.url;
      const activationUuid = searchParams.get('activation_uuid');
      const reqBody = transformObject(req.body, toCamelCase);
      let activation;

      if (
        reqBody.status === 'ACTIVATING' ||
        reqBody.status === 'UNSUSPENDING'
      ) {
        activation = mockDataStore.activateActivation(activationUuid);
      } else if (reqBody.status === 'SUSPENDING') {
        activation = mockDataStore.suspendActivation(activationUuid);
      } else if (reqBody.status === 'DEACTIVATING') {
        activation = mockDataStore.deactivateActivation(activationUuid);
      }
      const activationsResponse = transformObject(activation, toSnakeCase);

      return customResponse(ctx.status(200), ctx.json(activationsResponse));
    }

    return customResponse(
      ctx.status(200),
      ctx.json(mockActivations.activations.pop())
    );
  }),
];

export default handlers;
