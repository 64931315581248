import * as React from 'react';
import { matchPath } from 'react-router-dom';
import moment from 'moment-timezone';
import { Crumb } from '@wpengine/unicorn/components/Breadcrumbs/BreadcrumbLink';
import { EnvironmentLabel } from '@wpengine/unicorn/components';
import { EnvironmentType } from 'commonsDir/types/Installs';
import pluralize from 'pluralize';
import { SpmRouteInterface } from './routes';
import {
  ActionErrorInterface,
  ActionInterface,
  BulkActionsInterface,
  DisabledSiteInterface,
  EnabledSiteInterface,
  PluginShapeInterface,
  UpdatesListInterface,
  CheckboxNodeInterface,
  ExtensionType,
} from './interfaces';
import UserTracking from '../../../../modules/utilities/UserTracking';
import { intersection } from './set';

export const setPlural = (num: number): string => {
  if (num === 1) {
    return '';
  }
  return 's';
};

/**
 * Returns an object containing number of successfull and failed extension updates (plugins or themes) and failed extension instalations.
 * The second parameter determines whether numbers for plugins or themes are returned, it defaultes to plugins.
 *  @param ActionInterface action
 *  @param extension 'plugin' or 'theme' type
 *  @return object containing successful updates, failed updates and failed installations
 */
export const getExtensionUpdates = (
  action: ActionInterface,
  extension: ExtensionType = 'plugin'
): { updatesSuccessful: number; updatesFailed: number; instalationsFailed: number } => {
  const extensions = extension === 'plugin' ? action.plugins : action.themes;
  let updatesSuccessful = 0;
  let updatesFailed = 0;
  let instalationsFailed = 0;
  const failedUpdatesErrorIds: number[] = [];
  extensions.forEach(theme => {
    if (!theme.errorId && ['update_passed'].includes(action.finishReason)) {
      updatesSuccessful += 1;
      return;
    }
    // else action.finish_reason === 'update_rolled_back' || action.finish_reason === 'update_failed'
    updatesFailed += 1;
    failedUpdatesErrorIds.push(theme.errorId);
  });
  // Filter errors to get instalationsFailed - extensions whose error is not 'extension_update_skipped'.
  action.errors.forEach(e => {
    if (failedUpdatesErrorIds.includes(e.id) && e.error !== 'extension_update_skipped') {
      instalationsFailed += 1;
    }
  });
  return { updatesSuccessful, updatesFailed, instalationsFailed };
};

/**
 * Returns a section heading containing the number of updated or failed extensions (plugins or themes) and the number of issues.
 * The second parameter determines whether numbers for plugins or themes are returned, it defaultes to plugins.
 *  @param ActionInterface action
 *  @param extension 'plugin' or 'theme' type
 *  @return string containing the section heading
 */
export const getSectionName = (action: ActionInterface, extension: ExtensionType = 'plugin'): string => {
  let sectionName = ``;
  let extensionUpdates = getExtensionUpdates(action, extension).updatesSuccessful;
  const pluginInstallationsFailed = getExtensionUpdates(action, extension).instalationsFailed;
  const issues = action.testcasesFailed + action.testcasesPassedWithChanges + pluginInstallationsFailed;
  if (action.finishReason === 'update_passed') {
    sectionName = `Successfully updated `;
  }
  if (action.finishReason === 'update_rolled_back' || action.finishReason === 'update_failed') {
    sectionName = `Failed to update `;
    extensionUpdates = getExtensionUpdates(action, 'plugin').updatesFailed;
  }
  if (extensionUpdates > 0) {
    sectionName += `${extensionUpdates} ${pluralize(extension, extensionUpdates, false)}`;
  }
  if (issues > 0) {
    sectionName += `, ${issues} ${pluralize('issue', issues, false)}`;
  }
  return sectionName;
};

export const getErrorObject = (errorId: number, action: ActionInterface): ActionErrorInterface => {
  return errorId && action ? action.errors.find(error => error.id === errorId) : null;
};

export const getVrtMessage = (action: ActionInterface): string => {
  let msg = `Smart Plugin Manager visually tested ${action.testcasesTotal} URL${action.testcasesTotal > 1 ? 's' : ''}`;
  if (action.testcasesFailed > 0) {
    msg += ` and detected relevant changes on ${action.testcasesFailed} URL${action.testcasesFailed > 1 ? 's' : ''}`;
  }
  if (action.testcasesFailed === 0) {
    if (action.testcasesPassedWithChanges > 0) {
      msg += ` and detected visual changes on ${action.testcasesPassedWithChanges} URL${
        action.testcasesPassedWithChanges > 1 ? 's' : ''
      } that we've deemed to be minor or expected.`;
    }
    if (action.testcasesPassedWithChanges === 0) {
      msg += ` and there were no visual changes detected after the update.`;
    }
  }
  return msg;
};

/**
 * Returns time difference in hours between now and the date supplied as a parameter.
 * The date must be UTC and in format readable by the MomentJS library e.g. '2021-09-27T13:28:01.000Z'.
 * @param date_utc string
 * @return number of hours
 */
export const getHoursSinceDate = (date_utc: string): number => {
  const duration = moment.utc().diff(moment(date_utc));
  return Math.floor(duration / (1000 * 60 * 60));
};

// date formatter for ActionsSummary.tsx
export const formatDate = (date: string, timeZone = 'UTC', lineBreak = false, format = ''): React.ReactNode => {
  const abbr = moment.tz(timeZone).zoneAbbr();
  const mo = moment.utc(date);
  if (!mo || !abbr) {
    return null;
  }
  if (lineBreak) {
    const str = mo.tz(timeZone).format(format);
    const index = str.indexOf('|');
    const day = str.substr(0, index);
    const time = str.substr(index + 1);
    return (
      <>
        <span style={{ display: 'block' }}>{day}</span>
        <span style={{ display: 'block' }}>
          {time} {abbr}
        </span>
      </>
    );
  }
  if (format) {
    if (format === 'h:mm A') {
      return `${mo.tz(timeZone).format(format)} ${abbr}`;
    }
    return `${mo.tz(timeZone).format(format)}`;
  }
  return `${mo.tz(timeZone).format('MMMM D, YYYY h:mm A')} ${abbr}`;
};

export const uniqueId = (() => {
  let num = 0;
  return (prefix = ''): string => {
    num += 1;
    return prefix + num;
  };
})();

export const sendPendoEvent = (category: string, action = '', label = ''): void => {
  UserTracking.sendEvent(category, action, label);
};

export const sendUpdatePendoEvent = (bulk: BulkActionsInterface): void => {
  if (!bulk) {
    return;
  }
  const bulkFailed = [
    'failure',
    'update_failed',
    'update_rolled_back',
    'update_passed_and_rolled_back',
    'update_passed_and_failed',
  ].includes(bulk.finishReason);
  const actionsFailed = bulk.actions.filter(
    item => item.finishReason === 'update_failed' || item.finishReason === 'update_rolled_back'
  );
  if (bulkFailed || actionsFailed.length) {
    UserTracking.sendEvent(
      'SPM - site status visits after failure',
      bulk.finishReason,
      `Bulk ID: ${bulk.id} | Actions failed: ${actionsFailed.length}`
    );
    return;
  }
  UserTracking.sendEvent(
    'SPM - site status visits after success',
    bulk.finishReason,
    `Bulk ID: ${bulk.id} | Actions failed: ${actionsFailed.length}`
  );
};

/**
 * Returns available extension updates or up-to-date extension updates, depending on the second parameter.
 * If true (default value), the available updates are returned, the up-to-date updates otherwise.
 * The extensions are returned as an object containing an array of plugins and an array of themes.
 * @param site: EnabledSiteInterface
 * @param availableUpdates: boolean
 * @return object containing plugins, themes, if WordPress update is available and if PHP version is available
 */
export const getUpdatesList = (site: EnabledSiteInterface, availableUpdates = true): UpdatesListInterface => {
  const plugins: PluginShapeInterface[] = [];
  const themes: PluginShapeInterface[] = [];
  if (site?.plugins) {
    site.plugins.forEach(plugin => {
      if (availableUpdates && plugin.update) {
        plugins.push(plugin);
      }
      if (!availableUpdates && !plugin.update) {
        plugins.push(plugin);
      }
    });
  }
  if (site?.themes) {
    site.themes.forEach(theme => {
      if (availableUpdates && theme.update) {
        themes.push(theme);
      }
      if (!availableUpdates && !theme.update) {
        themes.push(theme);
      }
    });
  }

  return {
    plugins: plugins.sort((a, b) => a.name.localeCompare(b.name)),
    themes: themes.sort((a, b) => a.name.localeCompare(b.name)),
  };
};

export const trimText = (text: string, length: number): string => {
  if (text?.length > length) {
    let str = text.substr(0, length);
    const index = str.lastIndexOf(' ');
    str = str.substr(0, index);
    return `${str}...`;
  }
  return text;
};

export function sitesToMap<T extends EnabledSiteInterface>(sites: T[]): Map<string, T> {
  const sitesMap = new Map<string, T>();
  sites.forEach(site => {
    if (site?.installName) {
      sitesMap.set(site.installName, site);
    } else {
      sitesMap.set(site.providerMetadata.installName, site);
    }
  });
  return sitesMap;
}

// Merge spmSites with localSites. There might be more spm sites than their local counterparts.
// Only those SPM sites will be merged whose install names are present in localSites.
export const mergeSites = (
  enabledSites: EnabledSiteInterface[],
  disabledSites: DisabledSiteInterface[]
): EnabledSiteInterface[] => {
  // Filter enabledSites to exclude those that don't match local sites.
  const filteredEnabledSites = disabledSites.flatMap((d: DisabledSiteInterface) =>
    enabledSites.filter((e: EnabledSiteInterface) => d.installName === e.providerMetadata.installName)
  );

  // Applying sorting from AU Sites API.
  // FrontendUrl is common field between AU Sites API and PAPI.
  const sitesAPiFrontendUrls = enabledSites.map(({ frontendUrl }) => frontendUrl);
  filteredEnabledSites.sort(
    (a, b) => sitesAPiFrontendUrls.indexOf(a.frontendUrl) - sitesAPiFrontendUrls.indexOf(b.frontendUrl)
  );
  const enabledMap = sitesToMap<EnabledSiteInterface>(filteredEnabledSites);
  const disabledMap = sitesToMap<DisabledSiteInterface>(disabledSites);
  const enabledInstallNames = new Set(enabledMap.keys());
  const disabledInstallNames = new Set(disabledMap.keys());
  return Array.from(intersection<string>(enabledInstallNames, disabledInstallNames)).map(installName => {
    return {
      ...disabledMap.get(installName),
      ...enabledMap.get(installName),
    };
  });
};

export function transformObject(obj: any, keyTransformer: (string: string) => string, skip_level_keys = false): any {
  const isArray = Array.isArray(obj);

  if (isArray) {
    return obj.map((x: any) => transformObject(x, keyTransformer));
  }

  if (obj !== Object(obj) || typeof obj === 'function') {
    return obj;
  }

  const ret: any = {};

  Object.entries(obj).forEach(([key, value]) => {
    const val = transformObject(
      value,
      keyTransformer,
      // These properties in site objects are keyed by the slug of the extension and cannot be transformed
      ['plugins', 'themes', 'translations'].includes(key)
    );

    if (!skip_level_keys) {
      ret[keyTransformer(key)] = val;
    } else {
      ret[key] = val;
    }
  });

  return ret;
}

export const mapEnv = (env: string): string => {
  let tag;
  switch (env) {
    case 'PRD':
    case 'PROD':
    case 'production':
      tag = 'PRD';
      break;
    case 'STG':
    case 'STAGE':
    case 'staging':
      tag = 'STG';
      break;
    default:
      tag = 'DEV';
      break;
  }
  return tag;
};

export const unmapEnv = (env: string) => {
  switch (env) {
    case 'PRD':
    case 'PROD':
    case 'production':
      return 'production' as const;
    case 'STG':
    case 'STAGE':
    case 'staging':
      return 'staging' as const;
    default:
      return 'development' as const;
  }
};

export const getEnvLabel = (environment = 'DEV'): React.ReactElement => {
  const env = unmapEnv(environment) as EnvironmentType;
  return (
    <span className="environment">
      <EnvironmentLabel environment={env} testid="spm-env-label" />
    </span>
  );
};

// Add necessary props to local sites
export const setLocalSitesShape = (
  local_sites: DisabledSiteInterface[],
  currentAccountName: string
): DisabledSiteInterface[] => {
  return local_sites.map(s => {
    return {
      ...s,
      provider: 'wpengine',
      accountName: currentAccountName,
      env: mapEnv(s.env),
    };
  });
};

// Return IDs for those sites for which searchTerm is a substring of installName or frontendUrl.
export const getSearchedIDs = (sites: EnabledSiteInterface[], searchTerm: string): number[] => {
  return sites
    .filter(site => {
      let trimmedUrl = site.frontendUrl;
      let lastSlashIndex = trimmedUrl.lastIndexOf('/');
      trimmedUrl = trimmedUrl.substr(lastSlashIndex + 1);
      let trimmedSearchTerm = searchTerm;
      lastSlashIndex = trimmedSearchTerm.lastIndexOf('/');
      trimmedSearchTerm = trimmedSearchTerm.substr(lastSlashIndex + 1);
      return site.providerMetadata.installName.includes(searchTerm) || trimmedUrl.includes(trimmedSearchTerm);
    })
    .map(site => site.id!);
};

// Return sites whose ids match those in searchedIDs
export const getSitesBySearchedIDs = (sites: EnabledSiteInterface[], searchedIDs: number[]): EnabledSiteInterface[] => {
  return searchedIDs.flatMap((id: number) =>
    sites.filter((s: EnabledSiteInterface) => {
      if (id === s.id) {
        return true;
      }
      return false;
    })
  );
};

/**
 * Returns the provided url trimmed to the length of the second parameter. The removed part is in the middle of the string, replaced by '[...]'.
 * E.g. testsite.wpe[...]category/cars
 *  @param url string
 *  @param length number
 *  @return trimmed url, if the url is shorter than 'length' then the url is returned
 */
export const trimUrl = (url: string, length: number): string => {
  const clipLength = length - 4;
  if (url.length <= clipLength) {
    return url;
  }
  const index = Math.round(url.length / 2);
  let clipping = url.length - clipLength;
  if (clipping < 4) {
    clipping = 4;
  }
  const firstPart = url.substring(0, index - Math.round(clipping / 2) - 1);
  const secondPart = url.substring(index + Math.round(clipping / 2));
  return `${firstPart}[...]${secondPart}`;
};

export const getUpdatePassedMessage = (
  pluginsSuccessful: number,
  themesSuccessful: number,
  isSpmView: boolean,
  updateThemes: boolean
): string => {
  if (!isSpmView) {
    return updateThemes ? 'Plugins and themes are up to date.' : 'Plugins are up to date.';
  }
  if (pluginsSuccessful && !themesSuccessful) {
    return `Successfully updated ${pluginsSuccessful} ${pluralize('plugin', pluginsSuccessful, false)}`;
  }
  if (!pluginsSuccessful && themesSuccessful) {
    return `Successfully updated ${themesSuccessful} ${pluralize('theme', themesSuccessful, false)}`;
  }
  return `Successfully updated ${pluginsSuccessful} ${pluralize(
    'plugin',
    pluginsSuccessful,
    false
  )} and ${themesSuccessful} ${pluralize('theme', themesSuccessful, false)}`;
};

export const getUpdateFailedMessage = (
  pluginsFailed: number,
  themesFailed: number,
  isSpmView: boolean,
  updateThemes: boolean
): string => {
  if (!isSpmView) {
    return updateThemes
      ? 'Failed to update plugins or themes. Site NOT restored to previous version'
      : 'Failed to update plugins. Site NOT restored to previous version';
  }
  if (pluginsFailed && !themesFailed) {
    return `Failed to update ${pluginsFailed} ${pluralize(
      'plugin',
      pluginsFailed,
      false
    )}. Site NOT restored to previous version.`;
  }
  if (!pluginsFailed && themesFailed) {
    return `Failed to update ${themesFailed} ${pluralize(
      'theme',
      themesFailed,
      false
    )}. Site NOT restored to previous version.`;
  }
  return `Failed to update ${pluginsFailed} ${pluralize(
    'plugin',
    pluginsFailed,
    false
  )} and ${themesFailed} ${pluralize('theme', themesFailed, false)}. Site NOT restored to previous version.`;
};

export const getUpdateRolledBackMessage = (
  pluginsFailed: number,
  themesFailed: number,
  isSpmView: boolean,
  updateThemes: boolean
): string => {
  if (!isSpmView) {
    return updateThemes
      ? 'Failed to update plugins or themes. Site restored to previous version.'
      : 'Failed to update plugins. Site restored to previous version.';
  }
  if (pluginsFailed && !themesFailed) {
    return `Failed to update ${pluginsFailed} ${pluralize(
      'plugin',
      pluginsFailed,
      false
    )}. Site restored to previous version.`;
  }
  if (!pluginsFailed && themesFailed) {
    return `Failed to update ${themesFailed} ${pluralize(
      'theme',
      themesFailed,
      false
    )}. Site restored to previous version.`;
  }
  return `Failed to update ${pluginsFailed} ${pluralize(
    'plugin',
    pluginsFailed,
    false
  )} and ${themesFailed} ${pluralize('theme', themesFailed, false)}. Site restored to previous version.`;
};

export const getUpdatePassedAndRolledBackMessage = (
  pluginsSuccessful: number,
  themesSuccessful: number,
  pluginsFailed: number,
  themesFailed: number,
  isSpmView: boolean,
  updateThemes: boolean
): string => {
  if (!isSpmView) {
    return updateThemes
      ? 'There was an issue with some plugin and theme updates.'
      : 'There was an issue with some plugin updates.';
  }

  const openingMsg = `There was an issue updating some plugins and themes.`;
  let successMsg = '';
  let failMsg = '';
  const closingMsg = ` The failed updates were restored to their previous versions.`;

  if (pluginsSuccessful || themesSuccessful) {
    const pluginSuccessMsg = pluginsSuccessful
      ? ` ${pluginsSuccessful} ${pluralize('plugin', pluginsSuccessful, false)}`
      : '';
    const themeSuccessMsg = themesSuccessful
      ? ` ${themesSuccessful} ${pluralize('theme', themesSuccessful, false)}`
      : '';
    const and = pluginsSuccessful && themesSuccessful ? ' and' : '';
    successMsg = ` Successfully updated${pluginSuccessMsg}${and}${themeSuccessMsg}.`;
  }
  if (pluginsFailed || themesFailed) {
    const pluginFailMsg = pluginsFailed ? ` ${pluginsFailed} ${pluralize('plugin', pluginsFailed, false)}` : '';
    const themeFailMsg = themesFailed ? ` ${themesFailed} ${pluralize('theme', themesFailed, false)}` : '';
    const and = pluginsFailed && themesFailed ? ' and' : '';
    failMsg = ` Failed to update${pluginFailMsg}${and}${themeFailMsg}.`;
  }

  return `${openingMsg}${successMsg}${failMsg}${closingMsg}`;
};

/**
 * Returns an array of breadcrumbs based on a route for an environment.
 * The parameters 'environmentsNumber' and 'isBulkUpdate' are used to manage exeptions while accessing the route
 * 'products/smart_plugin_manager#/settings'. The 'environmentsNumber' provides the number of environments being
 * updated during the bulk settings update. The 'isBulkUpdate' parameter is used to distiguish between routes
 * '/settings' and '/installName/settings' in order to display the number of environments being updated.
 *  @param pathNames string
 *  @param routes SpmRouteInterface[]
 *  @param environment string
 *  @param environmentsNumber number
 *  @param isBulkUpdate boolean
 *  @return array of breadcrumbs
 */
export const getBreadcrumbs = (
  pathNames: string,
  routes: SpmRouteInterface[],
  environment: string,
  environmentsNumber: number,
  isBulkUpdate: boolean
) => {
  if (isBulkUpdate) {
    return [
      {
        label: 'Smart Plugin Manager',
        link: '/',
      },
      {
        label: `${environmentsNumber} ${pluralize('environment', environmentsNumber, false)}`,
      },
      {
        label: 'Settings',
      },
    ];
  }

  let match: { isExact: boolean; path: string; url: string; params: any };

  const route = routes.find((b: SpmRouteInterface) => {
    match = matchPath(pathNames, b.path);
    return match !== null && match.isExact;
  });

  if (route) {
    return route.breadcrumbs.map((breadcrumb: Crumb) => {
      const crumb = { ...breadcrumb };
      if (Object.prototype.hasOwnProperty.call(crumb, 'link')) {
        crumb.link = crumb.link.replace('installName', match.params?.installName);
        crumb.link = crumb.link.replace('bulkId', match.params?.bulkId);
      }
      if (crumb.label === 'installName' && crumb.link) {
        crumb.label = match.params?.installName;
        crumb.badge = getEnvLabel(environment);
      }
      if (crumb.label === 'installName' && !crumb.link) {
        crumb.label = (
          <span style={{ gap: '.5em', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {getEnvLabel(environment)} {match.params?.installName}
          </span>
        );
      }
      return crumb;
    });
  }

  return [
    {
      label: 'Smart Plugin Manager',
      link: '/',
    },
  ];
};

// Removes 'https://', 'http://', and 'www.' from the provided url
export const getPrettyUrl = (url: string): string => {
  const regex = /\b(?:https:\/\/|http:\/\/)\b/gi;
  return url ? url.replace(regex, '') : '';
};

export const getToggleLabel = (value: any, checked: boolean): string => {
  if (value === 'mixed values') {
    return 'mixed values';
  }
  if (checked) {
    return 'Enabled';
  }
  return 'Disabled';
};

/**
 * Returns an object containing nodes for react-checkbox-tree and enabled extensions (plugins/themes).
 * For theme updates set the second parameter themeUpdates to true.
 *  @param sites EnabledSiteInterface[]
 *  @param themeUpdates boolean
 *  @return object contaning react-checkbox-tree nodes and enabled extensions
 */
export const getCheckboxTreeNodes = (sites: EnabledSiteInterface[], themeUpdates = false) => {
  const allNodesObj: { [key: string]: CheckboxNodeInterface } = {};
  const enabledExtensions: string[] = [];

  // react-checkbox-tree requires the 'value' property to be unique in order to identify the checkbox tree nodes.
  // We need to use the site 'id' and the plugin 'slug' to create a unique ID for the site-plugin relation (nodeId).
  sites.forEach(site => {
    // For managed themes, exclude those sites whose theme updates are inactive.
    if (themeUpdates && !site.updateThemes) {
      return;
    }
    const extensions = themeUpdates ? site.themes : site.plugins;
    extensions.forEach(ext => {
      if (ext.slug === 'autoupdater/autoupdater.php') {
        return;
      }
      const nodeId = `${site.id}_${ext.slug}`;
      if (ext.updatesEnabled) {
        enabledExtensions.push(nodeId);
      }
      if (!Object.hasOwnProperty.call(allNodesObj, ext.slug)) {
        allNodesObj[ext.slug] = {
          value: ext.slug,
          label: ext.name,
          title: 'plugin_toggle',
          icon: <></>,
          children: [
            {
              value: nodeId,
              label: site.installName,
              // icon: <EnvironmentLabel environment={unmapEnv(site.env) as EnvironmentType} component="span" />,
              icon: getEnvLabel(site.env),
            },
          ],
        };
        return;
      }
      allNodesObj[ext.slug] = {
        value: ext.slug,
        label: ext.name,
        title: 'plugin_toggle',
        icon: <></>,
        children: [
          ...allNodesObj[ext.slug].children,
          {
            value: nodeId,
            label: site.installName,
            icon: getEnvLabel(site.env),
          },
        ],
      };
    });
  });

  const dataValue = Object.values(allNodesObj).sort((a, b) => a.label.localeCompare(b.label));
  const uniqueObjects = [...new Map(dataValue.map(item => [item, item])).values()];

  const allNodesArray = [
    {
      value: 'select_all',
      label: 'Select all',
      title: 'select_all_toggle',
      icon: <></>,
      children: uniqueObjects,
    },
  ];

  return { nodes: allNodesArray, enabled: enabledExtensions };
};

/**
 * Returns 001.001.001.b1 for 1.1.1-beta1
 * @param version string representing WordPress version
 * @param length optional number parameter representing number of parts in version
 * @param parts_length optional number parameter representing length of single path
 * @return string
 */
export const formatWordPressVersion = (version: string, length: number = 3, parts_length: number = length): string => {
  // Match only sets of digits separated by a full stop
  const regex = RegExp(/^([0-9]+(?:\.[0-9]+)*)([^.]+.*)?$/, 'i');
  const version_match = regex.exec(version);
  if (!version_match || !version_match[1]) {
    return version;
  }

  // Remove zeroes and split by a full stop
  const parts = version_match[1].split(/\.|-/).map(part =>
    parseInt(part, 10)
      .toString()
      .padStart(length, '0')
  );

  // Ensure that there are at least 3 parts of the formatted version
  while (parts.length < parts_length) {
    parts.push(''.padStart(length, '0'));
  }

  // Join with a full stop and append suffix, eg. ".b1"
  return (
    parts.join('.') +
    (version_match[2] ? `.${version_match[2][1]}${version_match[2][version_match[2].length - 1]}` : '.x0')
  ).toLowerCase();
};

/**
 * Returns -1 if v1 older than v2
 * returns  1 if v1 newer than v2
 * returns  0 if v1 = v2
 * Uses string comparison and should be improved.
 *  @param v1 string representing WordPress version
 *  @param v2 string representing WordPress version
 *  @return -1, 0 or 1
 */
export const compareWordPressVersions = (v1: string, v2: string): -1 | 0 | 1 => {
  const formattedV1 = formatWordPressVersion(v1);
  const formattedV2 = formatWordPressVersion(v2);

  if (formattedV1 > formattedV2) return 1;
  if (formattedV1 < formattedV2) return -1;
  return 0;
};
