import { useEffect, useCallback, useState } from 'react';
import { Mode } from '../modes';
import { eventName } from '../eventName';

export const useMode = (mode: Mode) => {
  const [isMode, setIsMode] = useState(
    document
      .querySelector('[data-appearance-mode]')
      ?.getAttribute('data-appearance-mode') === mode
  );

  const toggleMode = useCallback(
    (e: CustomEvent<Mode>): void => {
      setIsMode(e.detail === mode);
    },
    [mode]
  );

  useEffect(() => {
    document.addEventListener('dm-cookie-update', toggleMode);
    return () => document.removeEventListener('dm-cookie-update', toggleMode);
  }, [toggleMode]);

  useEffect(() => {
    document.addEventListener(eventName, toggleMode);
    return () => document.removeEventListener(eventName, toggleMode);
  }, [toggleMode]);

  return {
    isMode,
  };
};

export default useMode;
