import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const StepperSevenIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="stepper-seven-icon" ref={ref} {...props}>
        <path d="M9 6C8.44772 6 8 6.44772 8 7C8 7.55228 8.44772 8 9 8H13.656L11.0422 16.7127C10.8835 17.2416 11.1837 17.7991 11.7127 17.9578C12.2416 18.1165 12.7991 17.8163 12.9578 17.2873L15.9578 7.28735C16.0487 6.98457 15.991 6.65668 15.8023 6.40307C15.6136 6.14946 15.3161 6 15 6H9Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
        />
      </SvgTemplate>
    );
  }
);
export default StepperSevenIcon;
