import { useMode } from './useMode';

export const useAutoMode = () => {
  const { isMode: isPortalInAutoMode } = useMode('auto');

  return {
    isPortalInAutoMode,
  };
};

export default useAutoMode;
