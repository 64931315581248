import normalizeUrl from 'componentsDir/SiteMonitoring/utils/normalizeUrl';
import { v4 as uuidv4 } from 'uuid';
import { SiteMonitoringActivation } from 'componentsDir/shared/repositories/ActivationsPrivateAPIClient/types';
import * as mockMonitors from './mockMonitors';

const mockActivations: SiteMonitoringActivation[] = Object.keys(
  mockMonitors
).map((key: string) => {
  const mockMonitorsRecord: Record<string, IMonitor> = mockMonitors;
  return {
    context: {
      installId: mockMonitorsRecord[key].installWpeId.toString(),
      monitorId: mockMonitorsRecord[key].monitorId,
      productId: 'uma-site-monitoring',
      installDomain: normalizeUrl(
        mockMonitorsRecord[key].installDomain +
          (mockMonitorsRecord[key].subpath || '')
      ),
    },
    createdOn: '2022-04-11T20:09:47.891151Z',
    productInstanceId: mockMonitorsRecord[key].monitorId,
    productNamespace: 'uma-newrelic',
    product: 'monitoring-site',
    status: 'ACTIVE',
    updatedOn: '2022-04-12T21:00:27.339198Z',
    uuid: uuidv4(),
  };
});

export default mockActivations;
