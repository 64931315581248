import chunk from 'lodash/chunk';

function paginateMockData<T>(
  mockData: T[],
  pageToken: string,
  pageSize: number
): [T[], string] {
  const pages = chunk(mockData, pageSize || 10);
  const selectedPageNumber = parseInt(pageToken, 10) || 0;
  const selectedPage = pages[selectedPageNumber];
  const nextPageToken = pages[selectedPageNumber + 1]
    ? String(selectedPageNumber + 1)
    : null;

  return [selectedPage || [], nextPageToken];
}

export default paginateMockData;
