import provisionsHandler from 'componentsDir/Usage/mocks/provisionsHandler';
import trendsHandler from 'componentsDir/Usage/mocks/trendsHandler';
import addonEnablementHandler from 'componentsDir/Usage/mocks/addonEnablementHandler';
import envUsageHandler from 'componentsDir/Usage/mocks/envUsageHandler';

const usageHandlers = [
  ...provisionsHandler,
  ...trendsHandler,
  ...addonEnablementHandler,
  ...envUsageHandler,
];

export default usageHandlers;
