import { v4 as uuidv4 } from 'uuid';
import * as mockInstalls from './mockInstalls';

export const install0PrimaryMonitor: IMonitor = {
  installWpeId: mockInstalls.install0.id,
  installName: mockInstalls.install0.name,
  installDomain: mockInstalls.install0.primaryDomain,
  accountWpeId: mockInstalls.install0.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: true,
  contacts: {
    email: null,
  },
};

export const install0MailMonitor: IMonitor = {
  installWpeId: mockInstalls.install0.id,
  installName: mockInstalls.install0.name,
  installDomain: mockInstalls.install0.primaryDomain,
  subpath: '/mail',
  accountWpeId: mockInstalls.install0.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: false,
  contacts: {
    email: null,
  },
};

export const install0BizMonitor: IMonitor = {
  installWpeId: mockInstalls.install0.id,
  installName: mockInstalls.install0.name,
  installDomain: `https://install0.biz`,
  accountWpeId: mockInstalls.install0.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: false,
  contacts: {
    email: null,
  },
};

export const install6PrimaryMonitor: IMonitor = {
  installWpeId: mockInstalls.install6.id,
  installName: mockInstalls.install6.name,
  installDomain: mockInstalls.install6.primaryDomain,
  accountWpeId: mockInstalls.install6.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: true,
  contacts: {
    email: null,
  },
};

export const install7PrimaryMonitor: IMonitor = {
  installWpeId: mockInstalls.install7.id,
  installName: mockInstalls.install7.name,
  installDomain: mockInstalls.install7.primaryDomain,
  accountWpeId: mockInstalls.install7.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: true,
  contacts: {
    email: null,
  },
};

export const googlePrimaryMonitor: IMonitor = {
  installWpeId: mockInstalls.googleInstall.id,
  installName: mockInstalls.googleInstall.name,
  installDomain: `https://${mockInstalls.googleInstall.primaryDomain}`,
  accountWpeId: mockInstalls.googleInstall.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: true,
  contacts: {
    email: null,
  },
};

export const googleMailMonitor: IMonitor = {
  installWpeId: mockInstalls.googleInstall.id,
  installName: mockInstalls.googleInstall.name,
  installDomain: `https://${mockInstalls.googleInstall.primaryDomain}`,
  subpath: '/mail',
  accountWpeId: mockInstalls.googleInstall.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: true,
  contacts: {
    email: null,
  },
};

export const googleBizMonitor: IMonitor = {
  installWpeId: mockInstalls.googleInstall.id,
  installName: mockInstalls.googleInstall.name,
  installDomain: `https://google.biz`,
  accountWpeId: mockInstalls.googleInstall.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: false,
  contacts: {
    email: null,
  },
};

export const yahooPrimaryMonitor: IMonitor = {
  installWpeId: mockInstalls.yahooInstall.id,
  installName: mockInstalls.yahooInstall.name,
  installDomain: `https://${mockInstalls.yahooInstall.primaryDomain}`,
  accountWpeId: mockInstalls.yahooInstall.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: true,
  contacts: {
    email: null,
  },
};

export const askJeevesPrimaryMonitor: IMonitor = {
  installWpeId: mockInstalls.askJeevesInstall.id,
  installName: mockInstalls.askJeevesInstall.name,
  installDomain: `https://${mockInstalls.askJeevesInstall.primaryDomain}`,
  accountWpeId: mockInstalls.askJeevesInstall.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: true,
  contacts: {
    email: null,
  },
};

export const infoseekPrimaryMonitor: IMonitor = {
  installWpeId: mockInstalls.infoseekInstall.id,
  installName: mockInstalls.infoseekInstall.name,
  installDomain: `https://${mockInstalls.infoseekInstall.primaryDomain}`,
  accountWpeId: mockInstalls.infoseekInstall.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: true,
  contacts: {
    email: null,
  },
};

export const facebookPrimaryMonitor: IMonitor = {
  installWpeId: mockInstalls.facebookInstall.id,
  installName: mockInstalls.facebookInstall.name,
  installDomain: `https://${mockInstalls.facebookInstall.primaryDomain}`,
  accountWpeId: mockInstalls.facebookInstall.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: true,
  contacts: {
    email: null,
  },
};

export const instagramMonitor: IMonitor = {
  installWpeId: mockInstalls.instagramInstall.id,
  installName: mockInstalls.instagramInstall.name,
  installDomain: `https://${mockInstalls.instagramInstall.primaryDomain}`,
  accountWpeId: mockInstalls.instagramInstall.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: true,
  contacts: {
    email: null,
  },
};

export const nintentdoMonitor: IMonitor = {
  installWpeId: mockInstalls.nintendoInstall.id,
  installName: mockInstalls.nintendoInstall.name,
  installDomain: `https://${mockInstalls.nintendoInstall.primaryDomain}`,
  accountWpeId: mockInstalls.nintendoInstall.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: true,
  contacts: {
    email: null,
  },
};

export const segaMonitor: IMonitor = {
  installWpeId: mockInstalls.segaInstall.id,
  installName: mockInstalls.segaInstall.name,
  installDomain: `https://${mockInstalls.segaInstall.primaryDomain}`,
  accountWpeId: mockInstalls.segaInstall.accountWpeId,
  nrAccountId: 1000,
  monitorId: uuidv4(),
  alertsPolicyId: 100,
  alertsConditionId: 100,
  alertsChannelId: 100,
  clusterId: 1,
  region: 'us-east',
  monitorSpec: 'enabled',
  monitorStatus: 'enabled',
  alertSpec: 'enabled',
  alertStatus: 'enabled',
  isPrimaryDomain: true,
  contacts: {
    email: null,
  },
};
