import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const InstantStoreSearch: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="InstantStoreSearch logo" viewBox="0 0 64 64" {...props}>
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_793_846)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.2929 41.2929C35.6834 40.9024 36.3166 40.9024 36.7071 41.2929L43.7071 48.2929C44.0976 48.6834 44.0976 49.3166 43.7071 49.7071C43.3166 50.0976 42.6834 50.0976 42.2929 49.7071L35.2929 42.7071C34.9024 42.3166 34.9024 41.6834 35.2929 41.2929Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5 44C32.9558 44 39 37.9558 39 30.5C39 23.0442 32.9558 17 25.5 17C18.0442 17 12 23.0442 12 30.5C12 37.9558 18.0442 44 25.5 44ZM28.6376 23.6879C27.3453 23.0926 25.9103 22.8773 24.5001 23.067C23.0899 23.2567 21.7628 23.8436 20.6737 24.7592C20.251 25.1146 19.6202 25.06 19.2648 24.6373C18.9094 24.2145 18.964 23.5837 19.3867 23.2283C20.7662 22.0685 22.4472 21.3251 24.2334 21.0848C26.0197 20.8445 27.8374 21.1173 29.4744 21.8713C31.1114 22.6253 32.5001 23.8294 33.4784 25.343C34.4568 26.8567 34.9844 28.6174 34.9997 30.4196C35.0043 30.9719 34.5604 31.4234 34.0082 31.428C33.4559 31.4327 33.0044 30.9888 32.9997 30.4365C32.9877 29.0137 32.5712 27.6237 31.7988 26.4287C31.0264 25.2337 29.93 24.2831 28.6376 23.6879ZM18.6388 25.384C19.1171 25.6601 19.281 26.2717 19.0048 26.75C18.8084 27.0903 18.639 27.4454 18.4981 27.8122C18.3002 28.3278 17.7218 28.5854 17.2062 28.3875C16.6906 28.1895 16.4331 27.6111 16.631 27.0955C16.8093 26.6309 17.0239 26.181 17.2728 25.75C17.5489 25.2717 18.1605 25.1078 18.6388 25.384Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 19C36 18.4477 36.4477 18 37 18H42C42.5523 18 43 18.4477 43 19C43 19.5523 42.5523 20 42 20H37C36.4477 20 36 19.5523 36 19Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 26C40 25.4477 40.4477 25 41 25H46C46.5523 25 47 25.4477 47 26C47 26.5523 46.5523 27 46 27H41C40.4477 27 40 26.5523 40 26Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 34L41 34C40.4477 34 40 33.5523 40 33C40 32.4477 40.4477 32 41 32L45 32C45.5523 32 46 32.4477 46 33C46 33.5523 45.5523 34 45 34Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 19C44 18.4477 44.4477 18 45 18L47 18C47.5523 18 48 18.4477 48 19C48 19.5523 47.5523 20 47 20L45 20C44.4477 20 44 19.5523 44 19Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 26C48 25.4477 48.4477 25 49 25H51C51.5523 25 52 25.4477 52 26C52 26.5523 51.5523 27 51 27L49 27C48.4477 27 48 26.5523 48 26Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47 33C47 32.4477 47.4477 32 48 32L50 32C50.5523 32 51 32.4477 51 33C51 33.5523 50.5523 34 50 34H48C47.4477 34 47 33.5523 47 33Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_793_846"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default InstantStoreSearch;
