import { rest } from 'msw';
import { v4 as uuidv4 } from 'uuid';
import { GitPushDeveloper } from '../types';

export const developers: GitPushDeveloper[] = [
  {
    id: uuidv4(),
    name: 'mock-dev',
    fingerprint: 'fingerprint:for:mock-dev',
    ssh_public_key:
      'ssh-ed25519 AAAAC3NzaC1lZDI1NTE5AAAAII9STJEarvIzx9Ur6loP5kG+mX0ujUMiF49s0h5qVIKN',
  },
  {
    fingerprint: '4f:82:4d:c3:97:60:8f:d5:b8:40:fa:ff:23:f1:e6:5b',
    name: 'seanosh-soshaughnessy',
    ssh_public_key:
      'ssh-rsa AAAAB3NzaC1yc2EAAAABIwAAAQEAt2OVf/7gH9Hb1Mua6Qe/lBbrorumYIpj0MtiZ7JB+6oZOZU7EGssFpHKBj/USJFHmiLXG5Qxgx/8sWF3roEbxA5KyNd2wfW/Vk9GYh91wG8ahpzYupXq6TAznVElV74I88onwNQUcNu7fb6ZJpKAbxHBMPADtkxgavtI36dWA13ur4VNSaILZR/85Gh6c/sH7ZEoCoR3P53FcXpm5KpPxtq4Ood2MSbvAGyXKu7IVj9HUsDNgsiIW9/k2LZ6VWnPvpRoj4SmqZ4P6XNqfT/i2gqoPmIpuNIsNFtyVGl864ykxVhZOD4tD4b7KzyTMgDfsXH+w4e4zUS6o66MprT4mw==',
    id: uuidv4(),
  },
];

const handlers = [
  rest.get(
    `/capi/private/installs/:install_uuid/git_push_developers`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          developers,
          success: true,
        })
      );
    }
  ),

  rest.post(
    `/capi/private/installs/:install_uuid/git_push_developers`,
    (req, res, ctx) => {
      const reqBody = req.body as {
        name: string;
        ssh_public_key: string;
      };
      const { name, ssh_public_key } = reqBody;

      // Server side validation below; the rest is client-side
      // SSH Key Validation: Must be present, unique, and valid (min key length 1024))
      const sshKeyExists = name.includes('key-exists');

      // Developer Name Validation: Must be present, unique, and valid (max chars=30)
      const developerNameExists = name.includes('name-exists');

      const developerInvalid = name.includes('dev-invalid');

      let errorMessage;
      let developer_name;
      const errors = [];

      if (sshKeyExists) {
        errorMessage = 'This SSH Key is used by example-example';
        developer_name = 'example-example';
      }

      if (developerNameExists) {
        errorMessage = 'Invalid Input';
        errors.push({
          field: 'name',
          message: 'Developer name taken.',
        });
      }

      if (developerInvalid) {
        errorMessage = 'SSH Public Key is not a valid RSA/DSA key...';
      }

      if (errorMessage) {
        return res(
          ctx.status(422),
          ctx.json({
            success: false,
            errors,
            message: errorMessage,
            developer_name,
          })
        );
      }

      const developer = {
        id: uuidv4(),
        name,
        fingerprint: `fingerprint:for:${name}`,
        ssh_public_key,
      };

      developers.push(developer);

      return res(
        ctx.json({
          success: true,
        })
      );
    }
  ),

  rest.delete(
    `/capi/private/installs/:install_uuid/git_push_developers/:developer_id`,
    (req, res, ctx) => {
      const { developer_id } = req.params;

      const developerIndex = developers.findIndex(
        developer => developer.id === developer_id
      );

      if (developers[developerIndex].name.includes('delete-error')) {
        return res(
          ctx.status(422),
          ctx.json({
            success: false,
          })
        );
      }

      // Remove the existing developer
      if (developerIndex >= 0) {
        developers.splice(developerIndex, 1);
      }

      return res(
        ctx.status(200),
        ctx.json({
          success: true,
        })
      );
    }
  ),
  rest.post(
    `/capi/private/installs/:install_uuid/git_push_developers/data`,
    (req, res, ctx) => {
      return res(
        ctx.json({
          valid: 'valid',
        })
      );
    }
  ),
];

export default handlers;
