/* eslint-disable no-param-reassign */
import { v4 as uuidv4 } from 'uuid';
import { RestRequest } from 'msw';
import ActivationStatus from 'componentsDir/SiteMonitoring/contexts/activations/useActivationsClient/ActivationStatus';
import keyBy from 'lodash/keyBy';
import { SiteMonitoringActivation } from 'componentsDir/shared/repositories/ActivationsPrivateAPIClient/types';
import normalizeUrl from '../utils/normalizeUrl';
import getMonitorActivationUrl from '../utils/getMonitorActivationUrl';
import getMonitorUrl from '../utils/getMonitorUrl';
import mockMonitorResults from './data/mockMonitorResults';
import mockCapiDomains from './data/mockDomains';

// Standard resources
import * as mockMonitors from './data/mockMonitors';
import * as mockInstalls from './data/mockInstalls';
import mockIncidents from './data/mockIncidents';
import mockActivations from './data/mockActivations';

// import generateData from './data/loadTesting/generateData';

const NEWRELIC_ACCOUNT_ID = 1000;
class SiteMonitorMockDataStore {
  monitors: IMonitor[] = [];

  monitorResults: IMonitorData[] = [];

  activations: SiteMonitoringActivation[] = [];

  installs: IInstall[] = [];

  incidents: IIncident[] = [];

  requestLog: RestRequest[] = [];

  domains: typeof mockCapiDomains = [];

  entitlementQuantity = 0;

  activationDelay = 0;

  constructor() {
    this.reset();
  }

  reset() {
    // Standard resources
    this.monitors = Object.values(mockMonitors);
    this.installs = Object.values(mockInstalls);
    this.incidents = mockIncidents;
    this.domains = mockCapiDomains;
    this.activations = mockActivations;
    this.entitlementQuantity = 17;
    this.activationDelay = 0;

    // Load testing resources
    // const { installs, domains, monitors, activations } = generateData(300, [
    //   3,
    //   5,
    // ]);
    // console.log('LOAD TEST RESOURCE COUNT', {
    //   installs: installs.length,
    //   domains: domains.length,
    //   monitors: monitors.length,
    //   activations: activations.length,
    // });
    // this.monitors = monitors;
    // this.domains = domains;
    // this.installs = installs;
    // this.activations = activations;
    // this.entitlementQuantity = 2000;
    // -------

    this.requestLog = [];
    this.monitorResults = mockMonitorResults;
  }

  logRequest(req: RestRequest) {
    this.requestLog.push(req);
  }

  setActivationDelay(delay: number) {
    this.activationDelay = delay;
  }

  findActivation(activationUuid: string) {
    return this.activations.find(a => a.uuid === activationUuid);
  }

  findMonitor(monitorUuid: string) {
    return this.monitors.find(m => m.monitorId === monitorUuid);
  }

  findMonitorByUrl(monitorUrl: string) {
    const normalizedUrl = normalizeUrl(monitorUrl);
    return this.monitors.find(m => getMonitorUrl(m) === normalizedUrl);
  }

  findInstall(installWpeId: number) {
    return this.installs.find(i => i.id === installWpeId);
  }

  createMonitorFromActivation(activation: SiteMonitoringActivation) {
    const activationContext = activation.context;
    const monitorInstall = this.installs.find(
      i => i.id === parseInt(activation.context.installId, 10)
    );

    const isPrimaryDomain =
      normalizeUrl(monitorInstall?.primaryDomain) ===
      normalizeUrl(activationContext.installDomain);

    const newMonitor: IMonitor = {
      installDomain: activation.context?.installDomain,
      installWpeId: parseInt(activationContext.installId, 10),
      monitorId: activationContext.monitorId,
      installName: activationContext.installName,
      accountWpeId: this.installs.find(
        i => i.name === activationContext.installName
      )?.accountWpeId,
      nrAccountId: NEWRELIC_ACCOUNT_ID,
      // dummy value here for now, maybe something more realistic later
      alertsConditionId: 100,
      alertsPolicyId: 100,
      alertsChannelId: 100,
      //
      monitorSpec: 'enabled',
      monitorStatus: 'enabled',
      alertSpec: 'enabled',
      alertStatus: 'enabled',
      isPrimaryDomain,
      clusterId: activationContext.clusterId,
      region: activationContext.region,
      contacts: {
        email: null,
      },
    };
    this.monitors = [...this.monitors, newMonitor];
  }

  createActivation(activationPayload: IActivationCreatePayload) {
    const activationUuid = uuidv4();
    const newActivation: SiteMonitoringActivation = {
      createdOn: String(new Date()),
      updatedOn: String(new Date()),
      uuid: activationUuid,
      productInstanceId: null,
      productNamespace: 'uma-newrelic',
      status: ActivationStatus.ACTIVATING,
      context: {
        installId: activationPayload.installId,
        installDomain: activationPayload.installDomain,
        installName: activationPayload.installName,
        productId: 'uma-site-monitoring',
      },
    };
    this.activations = [...this.activations, newActivation];
    const monitorId = uuidv4();
    const activation = this.findActivation(activationUuid);
    activation.productInstanceId = monitorId;
    activation.context.monitorId = monitorId;
    setTimeout(
      () =>
        this.completeActivationProcess(activationUuid, ActivationStatus.ACTIVE),
      this.activationDelay
    );
    return newActivation;
  }

  completeActivationProcess(
    activationUuid: string,
    completionStatus: string,
    monitorCallback?: (m: IMonitor) => void
  ) {
    const activation = this.findActivation(activationUuid);
    activation.status = completionStatus;
    const monitor = this.findMonitorByUrl(getMonitorActivationUrl(activation));
    if (monitor) {
      return monitorCallback?.(monitor);
    }
    return this.createMonitorFromActivation(activation);
  }

  completeDeactivationProcess(activationUuid: string) {
    const activation = this.findActivation(activationUuid);
    activation.status = ActivationStatus.INACTIVE;
    const { monitorId } = activation.context;
    const monitor = this.findMonitor(monitorId);
    if (monitor) {
      this.monitors = this.monitors.filter(m => m.monitorId !== monitorId);
    }
  }

  activateActivation(activationUuid: string) {
    const activation = this.findActivation(activationUuid);
    activation.status = ActivationStatus.ACTIVATING;
    setTimeout(
      () =>
        this.completeActivationProcess(
          activationUuid,
          ActivationStatus.ACTIVE,
          m => {
            m.monitorStatus = 'enabled';
          }
        ),
      this.activationDelay
    );
    return activation;
  }

  suspendActivation(activationUuid: string) {
    const activation = this.findActivation(activationUuid);
    activation.status = ActivationStatus.SUSPENDING;
    setTimeout(
      () =>
        this.completeActivationProcess(
          activationUuid,
          ActivationStatus.SUSPENDED,
          m => {
            m.monitorStatus = 'disabled';
          }
        ),
      this.activationDelay
    );
    return activation;
  }

  deactivateActivation(activationUuid: string) {
    const activation = this.findActivation(activationUuid);
    activation.status = ActivationStatus.DEACTIVATING;
    setTimeout(
      () => this.completeDeactivationProcess(activationUuid),
      this.activationDelay
    );
    return activation;
  }

  getMonitors(accountWpeId: number, monitorIds?: string[]) {
    const monitorIdSet = new Set<string>(monitorIds);
    return monitorIds
      ? this.monitors.filter(
          m => monitorIdSet.has(m.monitorId) && m.accountWpeId === accountWpeId
        )
      : this.monitors.filter(m => m.accountWpeId === accountWpeId);
  }

  getActivations() {
    return this.activations;
  }

  getMonitorsData(): IMonitorData[] {
    return this.monitorResults;
  }

  getSortedIncidents(orderBy: string, accountWpeIds?: number[]) {
    const monitorIds = this.monitors.map(m => m.monitorId);
    const monitorsById = keyBy<IMonitor>(this.monitors, 'monitorId');
    const existingIncidents = this.incidents.filter(
      i =>
        monitorIds.includes(i.monitorId) &&
        accountWpeIds?.includes(monitorsById[i.monitorId]?.accountWpeId)
    );

    if (!orderBy) {
      return existingIncidents;
    }

    const orderByRegex = /(.+)\((.+)\)/g;
    const orderByMatch = orderBy.matchAll(orderByRegex);
    const [, direction, column] = orderByMatch.next().value as [
      string,
      string,
      keyof IIncident
    ];

    const sorted = existingIncidents.sort((a, b) => {
      if (a[column] < b[column]) {
        return -1;
      }
      if (a[column] > b[column]) {
        return 1;
      }
      return 0;
    });

    if (direction === 'desc') {
      return sorted.reverse();
    }
    return sorted;
  }

  getIncidents({
    offset,
    limit,
    query,
    startTime,
    sortBy,
    accountWpeIds,
  }: {
    offset: number;
    limit: number;
    query: string;
    startTime: number;
    sortBy?: string;
    accountWpeIds: number[];
  }) {
    const incidents = this.getSortedIncidents(sortBy, accountWpeIds);

    const queriedIncidents = query
      ? incidents.filter(i => {
          const lowercaseQuery = query.toLowerCase();
          const fullUrl = normalizeUrl(i.domain, i.subpath);
          return (
            i.installName.toLowerCase().includes(lowercaseQuery) ||
            fullUrl.includes(lowercaseQuery)
          );
        })
      : incidents;
    const dateConstrainedIncidents = startTime
      ? queriedIncidents.filter(i => i.startTime >= startTime)
      : queriedIncidents;
    const total = dateConstrainedIncidents.length;

    const offsetIncidents = offset
      ? dateConstrainedIncidents.slice(offset)
      : dateConstrainedIncidents;

    const limitedIncidents = limit
      ? offsetIncidents.slice(0, limit)
      : offsetIncidents;

    return { incidents: limitedIncidents, total };
  }

  getIncidentStats(accountWpeIds: number[]): IIncidentStats {
    return {
      sinceStartTimeCount: this.getSortedIncidents('', accountWpeIds).length,
      unresolvedCount: this.getSortedIncidents('', accountWpeIds).filter(
        i => i.acknowledgedBy === null || i.acknowledgedBy === undefined
      ).length,
    };
  }
}

const mockDataStore = new SiteMonitorMockDataStore();
export default mockDataStore;
