import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { gray1200, gray600 } from 'design-tokens';

const HelperText = styled(FormHelperText)({
  fontWeight: 'normal',
  lineHeight: '20px',
  marginTop: '4px',
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  '&.Mui-focused, &.Mui-error': {
    color: gray1200,
  },
  '&.Mui-disabled': {
    color: gray600,
  },
});

export default HelperText;
