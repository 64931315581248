import { rest } from 'msw';
import ssoHandlers from 'componentsDir/singleSignOn/mocks/handlers';
import { ENDPOINTS } from 'componentsDir/AllSites/constants';

const siteTagsResp = {
  tags: [
    {
      id: 1,
      name: 'jack-pappertons',
    },
    {
      id: 2,
      name: 'sweet',
    },
    {
      id: 3,
      name: 'lady-blossom',
    },
  ],
};

const handlers = [
  ...ssoHandlers,
  rest.get(`*${ENDPOINTS.SITE_TAGS}*`, (req, res, ctx) => {
    const siteUuid: string = req?.url.searchParams.get('siteUuid');

    if (siteUuid === '6260a7d6-fec3-48e9-b681-ffc34930dade') {
      return res(ctx.json({ tags: [] }));
    }

    if (siteUuid === 'f748ebb6-1b8c-4027-b0fc-e809a33d0952') {
      return res(ctx.json(siteTagsResp));
    }

    // Return a mock response for the API endpoint
    return res(
      ctx.delay(50),
      ctx.json({ tags: [{ id: 1, name: 'Mock tag' }] })
    );
  }),
];

export default handlers;
