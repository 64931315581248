import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const CheckCircleFilledIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="check-circle-filled-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM16.2071 9.29289C16.5976 9.68342 16.5976 10.3166 16.2071 10.7071L11.7071 15.2071C11.3166 15.5976 10.6834 15.5976 10.2929 15.2071L7.79289 12.7071C7.40237 12.3166 7.40237 11.6834 7.79289 11.2929C8.18342 10.9024 8.81658 10.9024 9.20711 11.2929L11 13.0858L14.7929 9.29289C15.1834 8.90237 15.8166 8.90237 16.2071 9.29289Z"
        />
      </SvgTemplate>
    );
  }
);
export default CheckCircleFilledIcon;
