import { rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';

const handlers = [
  rest.get('*/platform-one/sites/v1alpha1/environments', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({
        environments: [
          {
            name: 'Environment 1',
            technicalContacts: [
              {
                displayName: 'Technical Contact 1',
                email: 'technical-contact-1@gmail.com',
                firstName: 'Technical',
                lastName: 'Contact 1',
                phone: '+1402-111-1111',
              },
            ],
          },
          {
            name: 'Environment 2',
            technicalContacts: [
              {
                displayName: 'Technical Contact 2',
                email: 'technical-contact-2@gmail.com',
                firstName: 'Technical',
                lastName: 'Contact 2',
                phone: '+2131-234-5678',
              },
            ],
          },
          {
            name: 'Environment 3',
            technicalContacts: [
              {
                displayName: 'Technical Contact 3',
                email: 'technical-contact-3@gmail.com',
                firstName: 'Technical',
                lastName: 'Contact 3',
                phone: '+533-134-5326',
              },
            ],
          },
        ],
      })
    );
  }),
];

export default handlers;
