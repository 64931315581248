import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { cloneDeep } from 'lodash';
import { cart_path, sites_and_storage_used_path } from 'railsRoutes';
import mockCart from '../data/mockCart';
import { Cart } from '../../types/types';

let currentCart: Cart = cloneDeep(mockCart);

const cartHandlers = [
  rest.get(cart_path(), async (req, res, ctx) => {
    if (hasPageUrlQueryParam('emptyCart')) {
      return customResponse(
        ctx.status(201),
        ctx.json({ new_plan: null, selected_addons: [] })
      );
    }

    if (hasPageUrlQueryParam('planOnly')) {
      return customResponse(
        ctx.status(201),
        ctx.json({ ...mockCart, selected_addons: [] })
      );
    }

    if (hasPageUrlQueryParam('addonsOnly')) {
      return customResponse(
        ctx.status(201),
        ctx.json({ ...mockCart, new_plan: null })
      );
    }

    return customResponse(ctx.status(201), ctx.json(currentCart));
  }),
  rest.patch<Cart>(cart_path(), async (req, res, ctx) => {
    currentCart = req.body;
    return customResponse(ctx.status(201), ctx.json(currentCart));
  }),
  rest.get(sites_and_storage_used_path(), async (req, res, ctx) => {
    return customResponse(
      ctx.status(201),
      ctx.json({
        sitesUsed: 1,
        sitesMax: 1,
        storageUsed: 10000,
        storageMax: 20000,
      })
    );
  }),
];
export default cartHandlers;
