import * as React from 'react';
import {
  account_activity_log_path,
  account_users_path,
  api_access_path,
  seamless_login_path,
  single_sign_on_path,
  technical_contacts_path,
} from 'railsRoutes';
import { UsersIcon } from '@wpengine/unicorn/icons';
import SideNavDropdown from '../components/SideNavDropdown/SideNavDropdown';

interface UsersProps {
  currentAccountName: string;
}

const Users: React.FC<UsersProps> = ({ currentAccountName }) => {
  if (currentAccountName === null || currentAccountName === undefined) {
    const links = [
      {
        href: technical_contacts_path(),
        label: 'Technical contacts',
      },
      {
        href: api_access_path(),
        label: 'API access',
      },
      {
        href: single_sign_on_path(),
        label: 'Single Sign-on',
      },
      {
        href: seamless_login_path(),
        label: 'Seamless login',
      },
    ];
    return <SideNavDropdown icon={<UsersIcon />} label="Users" links={links} />;
  }

  const usersLinks = [
    {
      href: account_users_path(currentAccountName),
      label: 'Account users',
    },
    {
      href: technical_contacts_path(),
      label: 'Technical contacts',
    },
    {
      href: account_activity_log_path(currentAccountName),
      label: 'Account activity',
    },
    {
      href: api_access_path(),
      label: 'API access',
    },
    {
      href: single_sign_on_path(),
      label: 'Single Sign-on',
    },
    {
      href: seamless_login_path(),
      label: 'Seamless login',
    },
  ];

  return (
    <SideNavDropdown icon={<UsersIcon />} label="Users" links={usersLinks} />
  );
};

export default Users;
