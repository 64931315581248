import { customResponse } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import endpoints from '../constants/endpoints';
import accountSourceEnvironments from './data/accountSourceEnvironments';

const handlers = [
  rest.get(`*${endpoints.ACCOUNT_SOURCE_ENVIRONMENTS}`, (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(accountSourceEnvironments));
  }),
];

export default handlers;
