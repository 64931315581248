import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const SmartPluginManager: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="SmartPluginManager logo" viewBox="0 0 64 64" {...props}>
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_793_810)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.6893 18.7863C45.3127 19.1902 44.6799 19.2123 44.276 18.8356C43.8623 18.4499 43.4307 18.0839 42.9826 17.7388C42.545 17.4018 42.4635 16.7739 42.8005 16.3364C43.1374 15.8988 43.7653 15.8173 44.2029 16.1542C44.7008 16.5377 45.1804 16.9443 45.64 17.3729C46.0439 17.7496 46.066 18.3824 45.6893 18.7863Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9545 23.366C48.4762 23.6422 47.8646 23.4783 47.5885 23C47.2113 22.3468 46.7936 21.718 46.3375 21.1172C46.0036 20.6773 46.0896 20.05 46.5295 19.7161C46.9694 19.3822 47.5967 19.4681 47.9306 19.908C48.4373 20.5756 48.9015 21.2743 49.3205 22C49.5967 22.4783 49.4328 23.0899 48.9545 23.366Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.0005 31.0085C50.4512 31.0662 49.9591 30.6678 49.9014 30.1185C49.7436 28.6168 49.3974 27.141 48.8711 25.7257C48.6786 25.2081 48.9421 24.6324 49.4598 24.4399C49.9774 24.2474 50.5531 24.5109 50.7456 25.0286C51.3305 26.6011 51.7151 28.2409 51.8904 29.9094C51.9482 30.4587 51.5497 30.9508 51.0005 31.0085Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3107 45.2137C18.6873 44.8098 19.3201 44.7877 19.724 45.1644C20.1377 45.5501 20.5693 45.9161 21.0174 46.2612C21.455 46.5982 21.5365 47.2261 21.1995 47.6637C20.8626 48.1012 20.2347 48.1828 19.7971 47.8458C19.2992 47.4624 18.8196 47.0557 18.36 46.6271C17.9561 46.2504 17.934 45.6177 18.3107 45.2137Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0455 40.634C15.5238 40.3579 16.1354 40.5217 16.4115 41C16.7887 41.6532 17.2065 42.282 17.6625 42.8828C17.9964 43.3227 17.9104 43.95 17.4705 44.2839C17.0306 44.6178 16.4033 44.5319 16.0694 44.092C15.5627 43.4245 15.0985 42.7258 14.6795 42C14.4034 41.5217 14.5672 40.9101 15.0455 40.634Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9996 32.9915C13.5488 32.9338 14.0409 33.3323 14.0986 33.8815C14.2564 35.3832 14.6026 36.8591 15.1289 38.2743C15.3214 38.792 15.0579 39.3677 14.5402 39.5602C14.0226 39.7527 13.4469 39.4891 13.2544 38.9715C12.6696 37.399 12.2849 35.7591 12.1096 34.0906C12.0518 33.5413 12.4503 33.0493 12.9996 32.9915Z"
        fill="#006BD6"
      />
      <path
        d="M25.3695 29.4579C24.9836 29.0627 24.3505 29.0552 23.9554 29.4411C23.6543 29.7352 23.5783 30.1728 23.7299 30.5392C22.707 31.5597 21.5968 33.0064 20.9896 34.6502C20.606 35.6887 20.4102 36.8423 20.6015 38.0281C20.7612 39.0175 21.1832 39.9831 21.9194 40.8835C21.8435 41.0993 21.7845 41.337 21.7428 41.5884C21.639 42.2145 21.6331 42.9844 21.7907 43.829C22.1087 45.5345 23.0935 47.5466 25.289 49.3184C27.563 51.1534 31.1009 51.9855 34.8636 51.5788C38.654 51.1692 42.7737 49.4966 46.2815 46.1801C49.7792 42.8731 51.7047 37.9974 51.9773 33.1231C52.0081 32.5716 51.5861 32.0996 51.0347 32.0688C50.4833 32.0379 50.0112 32.46 49.9804 33.0114C49.7309 37.4729 47.9684 41.8328 44.9074 44.7269C41.7282 47.7327 38.0199 49.2261 34.6487 49.5904C31.2498 49.9578 28.2925 49.1721 26.545 47.7619C24.7191 46.2885 23.9872 44.6977 23.7568 43.4623C23.6866 43.0864 23.6629 42.7433 23.669 42.4456C24.3643 42.8848 25.0894 43.1567 25.8274 43.2829C27.0068 43.4845 28.154 43.3032 29.1873 42.9336C30.7972 42.3579 32.2141 41.2965 33.2232 40.3057C33.6006 40.5193 34.0883 40.4686 34.4141 40.1504C34.8092 39.7645 34.8167 39.1314 34.4309 38.7362L32.0928 36.3422L33.874 34.561C34.2645 34.1705 34.2645 33.5373 33.874 33.1468C33.4835 32.7562 32.8503 32.7562 32.4598 33.1468L30.6953 34.9112L28.9026 33.0755L30.6456 31.3325C31.0361 30.942 31.0361 30.3088 30.6456 29.9183C30.2551 29.5278 29.6219 29.5278 29.2314 29.9183L27.5051 31.6446L25.3695 29.4579Z"
        fill="#006BD6"
      />
      <path
        d="M38.6776 34.3546C39.0635 34.7497 39.6966 34.7572 40.0917 34.3713C40.3928 34.0773 40.4688 33.6397 40.3172 33.2732C41.3401 32.2528 42.4503 30.806 43.0575 29.1623C43.4411 28.1238 43.6369 26.9702 43.4456 25.7843C43.2872 24.8026 42.8704 23.8442 42.1447 22.9497C42.2289 22.741 42.2948 22.5216 42.3444 22.3065C42.4861 21.6918 42.5377 20.9296 42.4129 20.0848C42.1599 18.3721 41.1896 16.3677 38.8775 14.5694C34.1628 10.9022 25.0945 11.0109 17.9043 17.7139C14.6345 20.7621 12.496 25.5615 12.1575 30.6792C12.121 31.2303 12.5382 31.7066 13.0893 31.743C13.6404 31.7795 14.1167 31.3623 14.1531 30.8112C14.463 26.1263 16.4222 21.8298 19.2681 19.1768C25.9055 12.9891 33.8761 13.213 37.6496 16.148C39.5817 17.6508 40.2614 19.2061 40.4344 20.3771C40.4921 20.7674 40.4944 21.1208 40.4651 21.4228C39.7434 20.9507 38.9886 20.661 38.2197 20.5296C37.0404 20.328 35.8932 20.5092 34.8598 20.8788C33.2499 21.4546 31.833 22.5159 30.8239 23.5067C30.4465 23.2932 29.9588 23.3439 29.633 23.6621C29.2379 24.048 29.2304 24.6811 29.6163 25.0762L38.6776 34.3546Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_793_810"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default SmartPluginManager;
