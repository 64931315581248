import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const Multisite: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="Multisite logo" viewBox="0 0 64 64" {...props}>
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_3349_99)"
      />
      <path
        d="M34 27C34 26.4477 34.4477 26 35 26H42C42.5523 26 43 26.4477 43 27C43 27.5523 42.5523 28 42 28H35C34.4477 28 34 27.5523 34 27Z"
        fill="#006BD6"
      />
      <path
        d="M35 30C34.4477 30 34 30.4477 34 31C34 31.5523 34.4477 32 35 32H42C42.5523 32 43 31.5523 43 31C43 30.4477 42.5523 30 42 30H35Z"
        fill="#006BD6"
      />
      <path
        d="M34 35C34 34.4477 34.4477 34 35 34H42C42.5523 34 43 34.4477 43 35C43 35.5523 42.5523 36 42 36H35C34.4477 36 34 35.5523 34 35Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5 26C22.1193 26 21 27.1193 21 28.5V33.5C21 34.8807 22.1193 36 23.5 36H28.5C29.8807 36 31 34.8807 31 33.5V28.5C31 27.1193 29.8807 26 28.5 26H23.5ZM23 28.5C23 28.2239 23.2239 28 23.5 28H28.5C28.7761 28 29 28.2239 29 28.5V33.5C29 33.7761 28.7761 34 28.5 34H23.5C23.2239 34 23 33.7761 23 33.5V28.5Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 20C17 18.3431 18.3431 17 20 17H44C45.6569 17 47 18.3431 47 20V28H49C50.6569 28 52 29.3431 52 31V42C52 43.6569 50.6569 45 49 45H36C34.3431 45 33 43.6569 33 42V41H31V42C31 43.6569 29.6569 45 28 45H15C13.3431 45 12 43.6569 12 42V31C12 29.3431 13.3431 28 15 28H17V20ZM17 30H15C14.4477 30 14 30.4477 14 31V33H17V30ZM17 35H14V42C14 42.5523 14.4477 43 15 43H28C28.5523 43 29 42.5523 29 42V41H20C18.3431 41 17 39.6569 17 38V35ZM20 39C19.4477 39 19 38.5523 19 38V24H45L45 38C45 38.5523 44.5523 39 44 39H20ZM35 41V42C35 42.5523 35.4477 43 36 43H49C49.5523 43 50 42.5523 50 42V35H47V38C47 39.6569 45.6569 41 44 41H35ZM47 33H50V31C50 30.4477 49.5523 30 49 30H47V33ZM22 20C21.4477 20 21 20.4477 21 21C21 21.5523 21.4477 22 22 22H24C24.5523 22 25 21.5523 25 21C25 20.4477 24.5523 20 24 20H22ZM28 20C27.4477 20 27 20.4477 27 21C27 21.5523 27.4477 22 28 22H30C30.5523 22 31 21.5523 31 21C31 20.4477 30.5523 20 30 20H28ZM37 20C36.4477 20 36 20.4477 36 21C36 21.5523 36.4477 22 37 22H42C42.5523 22 43 21.5523 43 21C43 20.4477 42.5523 20 42 20H37Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_3349_99"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default Multisite;
