export const SITE_UUID = '8493173e-0370-4495-a2f7-feb2598ee741';
export const BLANK_UUID = '00000000-0000-0000-0000-000000000000';
export const TRANSFER_SITE_URL = `/sites/${SITE_UUID}/transfer`;
export const TRANSFER_SITE_URL_START = `/sites/${SITE_UUID}/start`;
export const TRANSFER_SITE_ERROR_URL = `${TRANSFER_SITE_URL}?error=true`;
export const TRANSFER_SITE_URL_TRANSFER_ERROR = `${TRANSFER_SITE_URL}?transferError=true`;
export const TRANSFER_SITE_URL_TRANSFER_START_FAILED = `${TRANSFER_SITE_URL}?startTransferFailed=true`;
export const TRANSFER_SITE_URL_TRANSFER_START_PARTIAL = `${TRANSFER_SITE_URL}?startTransferPartialFailed=true`;
export const TRANSFER_SITE_URL_TRANSFER_START_NO_MIGRATION = `${TRANSFER_SITE_URL_START}?startTransferNoMigration=true`;
export const SEGMENT = {
  FLOW: {
    TYPE: 'Transfer site',
    NAME: 'Domestic transfer',
  },
  CLICK: {
    HEADING: 'Transfer site',
    TYPE: {
      BUTTON: 'button',
      RADIO: 'radio',
      DROPDOWN: 'dropdown',
    },
    ACTION: {
      CLOSE: 'close',
    },
  },
};
