function getInstallName() {
  const url = window.location.href;

  // Only extract the install name from the url. Handles urls like:
  // https://my.wpengine.com/installs/myInstall123
  // https://my.wpengine.com/installs/myInstall123/
  // https://my.wpengine.com/installs/myInstall123?pendingInstall=true
  // https://my.wpengine.com/installs/myInstall123#
  // https://my.wpengine.com/installs/myInstall123/plugins_and_themes
  // https://my.wpengine.com/installs/myInstall123/plugins_and_themes#themes
  // eslint-disable-next-line no-useless-escape
  const regex = /\/installs\/([^\/?#]+)/;
  const match = url?.match(regex);

  return match?.[1] || null;
}

export default getInstallName;
