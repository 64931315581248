const sites = [
  {
    name: 'Test Site 1',
    siteId: 1000,
    installs: [
      {
        name: 'testsite100',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite100.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite101',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite101.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite102',
        siteEnv: 'STAGING',
        domainName: 'https://testsite102.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 2',
    siteId: 2000,
    installs: [
      {
        name: 'testsite200',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite200.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite201',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite201.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite202',
        siteEnv: 'STAGING',
        domainName: 'https://testsite202.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 3',
    siteId: 3000,
    installs: [
      {
        name: 'testsite300',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite300.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite301',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite301.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite302',
        siteEnv: 'STAGING',
        domainName: 'https://testsite302.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 4',
    siteId: 4000,
    installs: [
      {
        name: 'testsite400',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite400.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite401',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite401.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite402',
        siteEnv: 'STAGING',
        domainName: 'https://testsite402.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 5',
    siteId: 5000,
    installs: [
      {
        name: 'testsite500',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite500.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite501',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite501.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite502',
        siteEnv: 'STAGING',
        domainName: 'https://testsite502.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 6',
    siteId: 6000,
    installs: [
      {
        name: 'testsite600',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite600.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite601',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite601.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite602',
        siteEnv: 'STAGING',
        domainName: 'https://testsite602.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 7',
    siteId: 7000,
    installs: [
      {
        name: 'testsite700',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite700.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite701',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite701.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite702',
        siteEnv: 'STAGING',
        domainName: 'https://testsite702.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 8',
    siteId: 8000,
    installs: [
      {
        name: 'testsite800',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite800.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite801',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite801.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite802',
        siteEnv: 'STAGING',
        domainName: 'https://testsite802.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 9',
    siteId: 9000,
    installs: [
      {
        name: 'testsite900',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite900.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite901',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite901.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite902',
        siteEnv: 'STAGING',
        domainName: 'https://testsite902.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 10',
    siteId: 10000,
    installs: [
      {
        name: 'testsite1000',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite1000.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite1001',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite1001.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite1002',
        siteEnv: 'STAGING',
        domainName: 'https://testsite1002.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 11',
    siteId: 11000,
    installs: [
      {
        name: 'testsite1100',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite1100.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite1101',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite1101.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite1102',
        siteEnv: 'STAGING',
        domainName: 'https://testsite1102.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 12',
    siteId: 12000,
    installs: [
      {
        name: 'testsite1200',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite1200.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite1201',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite1201.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite1202',
        siteEnv: 'STAGING',
        domainName: 'https://testsite1202.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 13',
    siteId: 13000,
    installs: [
      {
        name: 'testsite1300',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite1300.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite1301',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite1301.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite1302',
        siteEnv: 'STAGING',
        domainName: 'https://testsite1302.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 14',
    siteId: 14000,
    installs: [
      {
        name: 'testsite1400',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite1400.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite1401',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite1401.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite1402',
        siteEnv: 'STAGING',
        domainName: 'https://testsite1402.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 15',
    siteId: 15000,
    installs: [
      {
        name: 'testsite1500',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite1500.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite1501',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite1501.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite1502',
        siteEnv: 'STAGING',
        domainName: 'https://testsite1502.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 16',
    siteId: 16000,
    installs: [
      {
        name: 'testsite2000',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite2000.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite2001',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite2001.wpenginedev.com',
        status: 'active',
      },
    ],
  },
  {
    name: 'Test Site 17',
    siteId: 17000,
    installs: [
      {
        name: 'testsite1700',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite1700.wpenginedev.com',
        status: 'active',
      },
      {
        name: 'testsite1701',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite1701.wpenginedev.com',
        status: 'pending',
      },
      {
        name: 'testsite1702',
        siteEnv: 'STAGING',
        domainName: 'https://testsite1702.wpenginedev.com',
        status: 'pending',
      },
    ],
  },
  {
    name: 'Test Site 18',
    siteId: 18000,
    installs: [
      {
        name: 'prdInstall',
        siteEnv: 'PRODUCTION',
        domainName: 'https://testsite1800.wpenginedev.com',
        status: 'active',
        cluster: 12345,
      },
      {
        name: 'devInstall',
        siteEnv: 'DEVELOPMENT',
        domainName: 'https://testsite1801.wpenginedev.com',
        status: 'pending',
        cluster: 12345,
      },
      {
        name: 'stgInstall',
        siteEnv: 'STAGING',
        domainName: 'https://testsite1802.wpenginedev.com',
        status: 'pending',
        cluster: 12345,
      },
    ],
  },
];

export default sites;
