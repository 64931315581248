import { SiteNav } from 'componentsDir/install/EnvironmentDashboardSideNav/types';
import moment, { Moment } from 'moment';

import {
  NetworkInfoHostnameStatus,
  V1alpha1Domain,
  V1alpha1NetworkType,
} from 'platform_one_api_client';

import { DsaDomainExtended, DsaResult, DsaSslStatus } from '../types';

const seedrandom = require('seedrandom');

const rng = seedrandom('DomainsRefresh mocks');

export const getRandomNumber = (max: number) => Math.floor(rng() * max);

export function getRandomValue<T>(values: T[]) {
  const randId = getRandomNumber(values.length);
  return values[randId];
}

const getRandomSSLExpirationDate = (from: Moment, to: Moment) => {
  const diff = to.diff(from, 'd');
  const randomDateWithinPeriod = moment(from).add(getRandomNumber(diff), 'd');

  return randomDateWithinPeriod.toDate();
};

const getSSLDatesRange = (status: string) => {
  const DAYS_RANGE = 30;
  const isExpired = status === 'expired';

  const todayDate = moment();
  const pastDate = moment().subtract(DAYS_RANGE, 'd');
  const futureDate = moment().add(DAYS_RANGE, 'd');

  const before = getRandomSSLExpirationDate(pastDate, todayDate);

  const after = isExpired
    ? moment(todayDate)
        .subtract(1, 'd')
        .toDate()
    : getRandomSSLExpirationDate(todayDate, futureDate);

  return {
    before,
    after,
  };
};

export const getMockedSsl = (resStatus?: DsaSslStatus) => {
  const sslStatuses = [
    DsaSslStatus.Verified,
    DsaSslStatus.Verified,
    DsaSslStatus.Verified,
    DsaSslStatus.Expired,
    DsaSslStatus.Verified,
    DsaSslStatus.Verified,
    DsaSslStatus.CertError,
    DsaSslStatus.NoHost,
    DsaSslStatus.Verified,
    DsaSslStatus.Verified,
    DsaSslStatus.NoCert,
  ];

  const status = resStatus ?? getRandomValue(sslStatuses);
  const { before, after } = getSSLDatesRange(status);
  const isNoCertError = status === 'cert_error';

  return {
    status,
    issuer: 'Cloudflare',
    not_before: before.toISOString(),
    not_after: after.toISOString(),
    error: isNoCertError ? `'*.badssl.com' certificate is not trusted` : '',
  };
};

export const getMockDomains = (count: number): V1alpha1Domain[] => {
  const networks = [
    V1alpha1NetworkType.An,
    V1alpha1NetworkType.Legacy,
    V1alpha1NetworkType.Ges,
  ];

  const domainNames: any = {};

  const getUniqName = (names: any): string => {
    const name = generateRandomDomain();

    if (names[name]) {
      return getUniqName(names);
    }

    // eslint-disable-next-line no-param-reassign
    names[name] = true;
    return name;
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return new Array(count).fill(null).map((_, i) => {
    const name = getUniqName(domainNames);
    // const name = `domain-${i}.com`;

    const id = getRandomNumber(999999999 - 111111111 + 1) + 111111111;

    return {
      id,
      domainName: name,
      duplicate: false,
      createTime: new Date().toISOString(),
      updateTime: new Date().toISOString(),
      networkType: getRandomValue(networks),
      wpEnvironment: {
        name: 'skdev2',
      },
      atlasApp: null,
      wildcard: rng() > 0.9,
      networkDetails: {
        dnsConfigInfo: {
          cname: 'mock.wpenginepowered.com',
          aRecords: ['1.2.3.4', '1.2.3.5'],
        },
        networkInfo: {
          status: getRandomValue<NetworkInfoHostnameStatus>([
            NetworkInfoHostnameStatus.Active,
            NetworkInfoHostnameStatus.Active,
            NetworkInfoHostnameStatus.Pending,
          ]),
          ssl: { status: 'active' } as any,
          ownershipVerification: {
            type: 'txt',
            name: '_cf-custom-hostname.fakedata.com',
            value: '81e1hg19-fake-data-7d2a-123456789248',
          },
          ownershipVerificationHttp: {
            httpBody: 'http-verification-name',
            httpUrl: 'http-verification-value',
          },
          reconciled: true,
        },
      },
    };
  });
};

export const sideNavProp: SiteNav = {
  production: {
    name: 'skdev2',
    overview: '/installs/skdev2',
    domains: '/installs/skdev2/domains',
    redirectRules: '/installs/skdev2/redirect_rules',
    backupPoints: '/installs/skdev2/backup_points',
    logs: '/installs/skdev2/logs',
    usersSummary: '/installs/skdev2/users_summary',
    gitPush: '/installs/skdev2/git_push',
    ssl: '/installs/skdev2/ssl_certificates',
    advanced: '/installs/skdev2/advanced',
    utilities: '/installs/skdev2/utilities',
    caching: '/installs/skdev2/cache_dashboard',
    siteMigration: '/installs/skdev2/migrate-my-site',
    goLiveChecklist: '/installs/skdev2/go_live_checklist',
    webRules: '/installs/skdev2/wren',
    performance: '/performance',
    performanceAvailable: true,
    pluginsAndThemes: '/plugins_and_themes',
    smartSearchInsights: '/smart_search_insights',
    smartSearchAvailable: true,
    transferable: false,
    sandbox: false,
    advancedAvailable: false,
    cacheDashboardAvailable: true,
    goLiveChecklistAvailable: false,
  },
  staging: null,
  development: {
    name: 'skdev2dev',
    overview: '/installs/skdev2dev',
    domains: '/installs/skdev2dev/domains',
    redirectRules: '/installs/skdev2dev/redirect_rules',
    backupPoints: '/installs/skdev2dev/backup_points',
    logs: '/installs/skdev2dev/logs',
    usersSummary: '/installs/skdev2dev/users_summary',
    gitPush: '/installs/skdev2dev/git_push',
    ssl: '/installs/skdev2dev/ssl_certificates/add',
    advanced: '/installs/skdev2dev/advanced',
    utilities: '/installs/skdev2dev/utilities',
    caching: '/installs/skdev2dev/cache_dashboard',
    siteMigration: '/installs/skdev2dev/migrate-my-site',
    goLiveChecklist: '/installs/skdev2dev/go_live_checklist',
    webRules: '/installs/skdev2dev/wren',
    performance: '/performance',
    performanceAvailable: true,
    pluginsAndThemes: '/plugins_and_themes',
    smartSearchInsights: '/smart_search_insights',
    smartSearchAvailable: true,
    transferable: false,
    sandbox: false,
    advancedAvailable: false,
    cacheDashboardAvailable: true,
    goLiveChecklistAvailable: false,
  },
  canAddEnvironment: true,
  addProductionPath:
    '/installs/new?account=svkonvisar2&selected_tab=blank&site_env=production&site_id=32243',
  addStagingPath:
    '/installs/new?account=svkonvisar2&selected_tab=blank&site_env=staging&site_id=32243',
  addDevelopmentPath:
    '/installs/new?account=svkonvisar2&selected_tab=blank&site_env=development&site_id=32243',
  sandbox: false,
};

export const mockSiteHeaderProps = {
  installName: 'skdev2',
  sourceInstall: {
    name: 'skdev2',
    siteEnv: 'production',
  },
  passwordProtected: false,
  disabled: false,
  copyEnvironmentButtonLink: '/deploys/new?source_install=skdev2',
  phpMyAdminInstallPath: '/installs/skdev2/phpmyadmin',
  wpAdminURL: '/installs/50827/launch_wp_admin',
  allGroups: [
    {
      label: 'Ungrouped',
      value: '',
    },
  ],
  csrfToken: 'csrfToken',
  site: {
    siteId: 'site_32243',
    siteName: 'sk_dev2',
    currentGroup: '',
    siteActions: [
      {
        type: 'edit-site-name',
        text: 'Edit site name',
        enabled: true,
      },
      {
        type: 'delete',
        text: 'Delete site',
        enabled: true,
      },
    ],
    transferable: false,
    sandbox: false,
    siteEnvironmentDropdownItems: [
      {
        label: 'skdev2',
        value: 'skdev2',
        environment: 'production',
        passwordProtected: false,
        url: '/installs/skdev2',
        disabled: false,
        disabledTooltip:
          'A production environment exists for this site, but you do not have access to it.',
      },
      {
        label: 'skdev2dev',
        value: 'skdev2dev',
        environment: 'development',
        passwordProtected: false,
        url: '/installs/skdev2dev',
        disabled: false,
        disabledTooltip:
          'A development environment exists for this site, but you do not have access to it.',
      },
      {
        label: 'Add staging environment',
        value: 'Add staging environment',
        addNewEnvironmentUrl:
          '/installs/new?account=svkonvisar2&selected_tab=blank&site_env=staging&site_id=32243',
        disabled: false,
        disabledTooltip: 'You do not have permission to add an environment.',
      },
    ],
  },
  allSiteNames: [
    'sk_dev2',
    'empty',
    'legacy1',
    'legacy2',
    'legacy3',
    'legacy4',
    'legacy5',
    'legacy6',
    'legacy7',
    'legacy8',
    'legacy9',
    'legacy10',
    'legacy11',
    'legacy12',
    'legacy13',
    'legacy14',
    'legacy15',
    'legacy16',
    'legacy17',
    'legacy18',
    'legacy19',
    'legacy20',
  ],
  sitesPath: '/sites',
};

interface CreateReportData {
  install_name: string;
  install_ip?: string;
  names: string[];
}

export const mockCreateReport = (data: CreateReportData) => {
  const complete = false; // Math.random() > 0.5;

  return {
    admin: false,
    complete: false,
    domains: data.names.map(name => ({
      name,
      complete,
      result: DsaResult.empty,
      dns_provider: complete ? 'Cloudflare' : '',
      ssl: getMockedSsl(),
    })) as DsaDomainExtended[],
    id: generateRandomDomain(),
    install_ip: '1.1.1.1',
    install_name: data.install_name,
  };
};

export const mockGetReport = (
  id: string,
  domains: string[],
  complete = true
) => {
  if (!complete) {
    return {
      id,
      admin: false,
      complete: false,
      domains: domains.map(name => ({
        name,
        complete,
        result: DsaResult.empty,
        dns_provider: complete ? 'Cloudflare' : '',
        ssl: getMockedSsl(),
      })) as DsaDomainExtended[],
      install_ip: '1.1.1.1',
      install_name: 'skdev2',
    };
  }

  return {
    id,
    admin: false,
    complete,
    domains: domains.map(name => ({
      name,
      complete,
      result: getRandomValue<DsaResult>([
        DsaResult.okGlobalIngress,
        DsaResult.okGlobalIngress,
        DsaResult.okGlobalIngress,
        DsaResult.okGlobalEdgeSecurity,
        DsaResult.okGlobalEdgeSecurity,
        DsaResult.okGlobalEdgeSecurity,
        DsaResult.okWPEngine,
        DsaResult.okWPEngine,
        DsaResult.errorNotWPE,
      ]),
      dns_provider: 'Cloudflare',
      ssl: getMockedSsl(),
    })) as DsaDomainExtended[],
    install_ip: '1.1.1.1',
    install_name: 'skdev2',
  };
};

export function generateRandomDomain() {
  const possibleChars = 'abcdefghijklmnopqrstuvwxyz';
  const domainExtensions = ['.com', '.net', '.org', '.io', '.tech', '.co'];

  const domainNameLength = getRandomNumber(26 - 8 + 1) + 8;
  let domainName = '';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < domainNameLength; i++) {
    domainName += possibleChars.charAt(getRandomNumber(possibleChars.length));
  }

  const extension = getRandomValue(domainExtensions);

  return `${domainName}${extension}`;
}

export const entriSettingsResponse = {
  data: {
    application_url: '',
    dc_enabled_providers: {
      dns: {
        cloudflare: true,
        godaddy: false,
        'google-domains': false,
        ionos: true,
      },
      dkim: {
        cloudflare: true,
        godaddy: true,
        'google-domains': true,
        ionos: true,
      },
    },
    auth: true,
    cname_target: '',
    application_id: 'wpengine_dev',
    application_name: 'wpengine - Dev',
    dc_enabled: false,
    demo: false,
    whitelabel_enabled: true,
    logo:
      'https://entri.s3.us-east-2.amazonaws.com/uploads/dc67f7af-152f-41a9-a2a7-db39ceb4aa03.jpeg',
    products: {
      connect: true,
      sell: false,
      power: false,
      secure: false,
    },
  },
};

export const entriCompanyTiersResponse = {
  spf_configuration: true,
  remove_entri_branding: true,
  default_subdomains: true,
  configuration_variables: true,
  www_redirects: true,
  custom_colors: true,
  language_support: true,
  phone_support: true,
  force_subdomains: true,
  prefilled_domains: true,
  dkim_configuration: true,
  add_own_logo: true,
  webhooks: true,
  dns_configuration: true,
  custom_copy: true,
  check_domain_supported: true,
  third_party_dkim: true,
  dedicated_slack_support: true,
};

export const entriValidateDomainResponse = {
  dkim: {
    type: 'unknown',
  },
  dns: {
    Information: [
      {
        Type: 'NS',
        'Domain Name': 'salvador.ns.porkbun.com',
        IP: '',
      },
      {
        Type: 'NS',
        'Domain Name': 'curitiba.ns.porkbun.com',
        IP: '',
      },
      {
        Type: 'NS',
        'Domain Name': 'fortaleza.ns.porkbun.com',
        IP: '',
      },
      {
        Type: 'NS',
        'Domain Name': 'maceio.ns.porkbun.com',
        IP: '',
      },
    ],
    DnsServiceProvider: 'porkbun',
    DnsServiceProviderIdentifier: 'porkbun',
  },
  whois: {
    Registrar: 'Porkbun LLC',
  },
  dmarcFound: false,
};
