import useCart from '../hooks/useCart';
import useUpdateCart from '../hooks/useUpdateCart';

const useRemovePlan = () => {
  const { cart } = useCart();
  const { mutate } = useUpdateCart();

  return () => {
    mutate({ ...cart, new_plan: null });
  };
};

export default useRemovePlan;
