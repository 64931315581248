import {
  InstallsUsers,
  StartTransferResponse,
  TransferDetails,
} from 'componentsDir/shared/repositories/TransferSiteApiClient/types';

export const mockInstallsUsers: InstallsUsers[] = [
  {
    install: 'install1',
    users: [
      '1eeef5ed-54cf-4f1a-8783-3c9aff77be54',
      '941e854f-a314-4b6f-b6ec-ccf041f2816b',
      'e55b4724-5c7e-427b-9ee2-333e6f010ceb',
      '6d29f97c-ea77-41d3-be25-b24ae28e079d',
    ],
  },
  {
    install: 'install2',
    users: [
      '1eeef5ed-54cf-4f1a-8783-3c9aff77be54',
      '941e854f-a314-4b6f-b6ec-ccf041f2816b',
      'e55b4724-5c7e-427b-9ee2-333e6f010ceb',
      '6d29f97c-ea77-41d3-be25-b24ae28e079d',
    ],
  },
  {
    install: 'install3',
    users: [
      '1eeef5ed-54cf-4f1a-8783-3c9aff77be54',
      '941e854f-a314-4b6f-b6ec-ccf041f2816b',
      'e55b4724-5c7e-427b-9ee2-333e6f010ceb',
      '6d29f97c-ea77-41d3-be25-b24ae28e079d',
    ],
  },
];

export const mockTransferDetails: TransferDetails = {
  id: '3fa85f64-5717-4444-b3fc-2c963f66afa6',
  origin_account_wpe_id: 123456,
  destination_account_wpe_id: 789012,
  site_uuid: '3fa85f64-5555-4562-b3fc-2c963f66afa6',
  install_names: ['install1', 'install2'],
  sender_email: 'sender@email.com',
  recipient_email: 'recipient@email.com',
  destination_cluster_id: 999999,
  transfer_config: {
    installs_users: mockInstallsUsers,
    site_notes: true,
    site_tags: ['tag1', 'tag2'],
    account_name: 'Test Destination Account',
    user_uuid: '1234',
    origin_cluster_id: 12345,
  },
  dedicated_transfer: false,
  scheduled_time: '2021-01-01T00:00:00Z',
  created_on: '2021-01-01T00:00:00Z',
  updated_on: '2021-01-01T00:00:00Z',
};

export const mockDedicatedTransferDetails: TransferDetails = {
  ...mockTransferDetails,
  dedicated_transfer: true,
};

export const mockSuccessfulStartTransferResponse: StartTransferResponse = {
  successful: ['install1', 'install2'],
  failed: [],
  migration: true,
};

export const mockSuccessfulStartTransferResponseNoMigration: StartTransferResponse = {
  successful: ['install1', 'install2'],
  failed: [],
  migration: false,
};

export const mockPartialFailedStartTransferResponse: StartTransferResponse = {
  successful: ['install1'],
  failed: ['install2'],
};

export const mockFailedStartTransferResponse: StartTransferResponse = {
  successful: [],
  failed: ['install1', 'install2'],
};
