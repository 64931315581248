import { rest } from 'msw';
import { ENDPOINTS, MSW_PARAMS } from 'componentsDir/AllSites/constants';
import { hasPageUrlQueryParam } from 'mocksDir/utils/browserUtils';

const handlers = [
  rest.get(`*${ENDPOINTS.SITE_EXISTS}*`, (req, res, ctx) => {
    if (hasPageUrlQueryParam(MSW_PARAMS.SITE_EXISTS)) {
      return res(ctx.status(204));
    }

    return res(ctx.status(400));
  }),
];

export default handlers;
