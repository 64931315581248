import {
  borderRadiusLarge,
  borderRadiusMedium,
  borderRadiusSmall,
  yellow200,
  yellow400,
  yellow600,
  red100,
  red300,
  red400,
  red700,
  red900,
  colorWhite,
  drawerWidth,
  green100,
  green300,
  green500,
  green700,
  green800,
  green900,
  elevation1BoxShadow,
  elevation2BoxShadow,
  elevation3BoxShadow,
  fontInter,
  blue100,
  blue300,
  blue400,
  blue600,
  blue700,
  blue800,
  blue900,
  gray25,
  gray200,
  gray300,
  gray400,
  gray500,
  gray600,
  gray700,
  gray900,
  gray1000,
  gray1100,
  gray1150,
  gray1200,
  orange100,
  orange700,
  orange800,
  orange900,
  purple100,
  purple300,
  purple900,
  purple500,
  purple700,
  purple800,
} from 'design-tokens';
import {
  alpha,
  createTheme,
  lighten,
  accordionSummaryClasses,
  paperClasses,
  ThemeOptions,
  PopperProps,
  accordionClasses,
  typographyClasses,
  svgIconClasses,
  accordionDetailsClasses,
  skeletonClasses,
} from '@mui/material';
import CircularProgress from '@wpengine/unicorn/components/GradientCircularProgress';
import StepIcon from '@wpengine/unicorn/components/Stepper/StepIcon';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import {} from '@mui/x-data-grid/themeAugmentation';

import * as React from 'react';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import isChromatic from 'chromatic/isChromatic';

import { createBreakpoints } from '@mui/system';
import { TabPanelProps } from '@mui/lab';
import buttonTheme from './components/Button/buttonTheme';
import chipTheme from './components/Chips/chipTheme';
import iconButtonTheme from './components/IconButton/iconButtonTheme';
import menuItemTheme from './components/MenuItem/menuItemTheme';

/*

Theme configuration variables:
https://mui.com/customization/theming/#theme-configuration-variables

You can check out the default theme section to view the default theme in full.
https://mui.com/customization/default-theme/

If you have any questions, please join #unicorn in Slack and ask us there!

*/

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodyBold: true;
    bodyItalic: true;
    codeSnippet: true;
    helper: true;
    label: true;
    tableHeader: true;
  }
}
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    announcement: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  bodyBold: React.CSSProperties;
  bodyItalic: React.CSSProperties;
  codeSnippet: React.CSSProperties;
  helper: React.CSSProperties;
  label: React.CSSProperties;
  tableHeader: React.CSSProperties;
}

declare module '@mui/material/styles' {
  interface CommonColors {
    border?: string;
  }

  interface Palette {
    admin: Palette['primary'];
    announcement: Palette['primary'];
  }

  interface PaletteColor {
    10?: string;
    30?: string;
    50?: string;
    90?: string;
    110?: string;
  }

  interface PaletteOptions {
    admin?: PaletteOptions['primary'];
    announcement: PaletteOptions['primary'];
  }

  interface SimplePaletteColorOptions {
    10?: string;
    30?: string;
    50?: string;
    90?: string;
    110?: string;
    120?: string;
  }

  interface Theme {
    borderRadius: {
      borderRadiusSmall: string;
      borderRadiusMedium: string;
      borderRadiusLarge: string;
    };
  }

  interface ThemeOptions {
    borderRadius?: {
      borderRadiusSmall?: string;
      borderRadiusMedium?: string;
      borderRadiusLarge?: string;
    };
  }
}

const ignoreSsrFlag =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1200,
    xl: 1440,
  },
});

const palette: ThemeOptions['palette'] = {
  common: {
    black: gray1200,
    border: gray400,
  },
  primary: {
    main: blue700,
    dark: blue800,
    light: blue100,
    contrastText: colorWhite,
    10: blue100,
    30: blue300,
    50: blue400,
    90: blue600,
    110: blue800,
    120: blue900,
  },
  secondary: {
    main: gray1200,
    light: gray1100,
    contrastText: colorWhite,
    50: gray700,
    90: gray1150,
  },
  action: {
    active: gray1200,
    disabled: gray700,
    disabledBackground: gray400,
  },
  error: {
    main: red700,
    light: red100,
    dark: red900,
    contrastText: colorWhite,
    10: red100,
    30: red300,
    50: red400,
    90: red700,
    110: red900,
  },
  warning: {
    dark: orange900,
    light: yellow200,
    main: orange700,
    50: yellow400,
    90: yellow600,
  },
  info: {
    dark: blue800,
    light: blue100,
    main: blue700,
    10: blue100,
    30: blue300,
    50: blue400,
    90: blue600,
    110: blue800,
  },
  success: {
    dark: green900,
    light: green100,
    main: green800,
    10: green100,
    30: green300,
    50: green500,
    90: green700,
    110: green900,
  },
  admin: {
    dark: orange800,
    light: orange100,
    main: orange700,
  },
  text: {
    primary: gray1200,
    secondary: gray1200,
    disabled: gray600,
  },
  announcement: createTheme().palette.augmentColor({
    color: {
      dark: purple300,
      light: purple100,
      main: purple100,
      10: purple100,
      30: purple300,
      50: purple500,
      90: purple700,
      110: purple900,
      contrastText: purple800,
    },
  }),
};

const typography: ExtendedTypographyOptions = {
  fontFamily: fontInter,
  body1: {
    fontSize: '0.875rem', // 14px
    lineHeight: '24px',
  },
  bodyBold: {
    fontSize: '0.875rem', // 14px
    fontWeight: 600,
    lineHeight: '24px',
  },
  bodyItalic: {
    fontSize: '0.875rem', // 14px
    fontStyle: 'italic',
    lineHeight: '24px',
  },
  button: {
    fontSize: '0.875rem', // 14px
    fontWeight: 580,
    textTransform: 'none',
    lineHeight: '24px',
  },
  codeSnippet: {
    fontFamily: '"Roboto Mono", monospace',
    fontSize: '0.875rem', // 14px
    lineHeight: '24px',
  },
  helper: {
    fontSize: '0.75rem', // 12px
    lineHeight: '20.5px',
  },
  h1: {
    fontSize: '1.775rem', // 28.4px
    fontWeight: 620,
    letterSpacing: '-0.2px',
    lineHeight: '40px',
    color: gray1200,
    [breakpoints.down('sm')]: {
      fontSize: '1.625rem', // 26px
      lineHeight: '2.275rem', // 36.4px
    },
  },
  h2: {
    fontSize: '1.5rem', // 24px
    fontWeight: 620,
    letterSpacing: '-0.2px',
    lineHeight: '32px',
    color: gray1200,
    [breakpoints.down('sm')]: {
      fontSize: '1.375rem', // 22px
      lineHeight: '1.925rem', // 30.8px
    },
  },
  h3: {
    fontSize: '1.25rem', // 20px
    fontWeight: 620,
    letterSpacing: '-0.2px',
    lineHeight: '28px',
    color: gray1200,
    [breakpoints.down('sm')]: {
      fontSize: '1.125rem', // 18px
      lineHeight: '1.575rem', // 25.2px
    },
  },
  h4: {
    fontSize: '1.109375rem', // 17.75px
    fontWeight: 620,
    letterSpacing: '-0.2px',
    lineHeight: '24px',
    [breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.4rem', // 22.4px
    },
  },
  h5: {
    fontSize: '0.875rem',
    fontWeight: 620,
    lineHeight: '24px',
  },
  label: {
    color: gray1200,
    fontSize: '0.875rem', // 14px
    fontWeight: 620,
    letterSpacing: '0.1px',
    lineHeight: '24px',
  },
  tableHeader: {
    fontSize: '0.75rem', // 12px
    fontWeight: 620,
    lineHeight: '24px',
  },
};

const defaultTheme = createTheme({
  palette,
});

type MuiPopperType = {
  defaultProps?: Partial<PopperProps> | undefined;
  styleOverrides?:
    | Partial<
        OverridesStyleRules<'root', 'MuiPopper', Omit<Theme, 'components'>>
      >
    | undefined;
};

type MuiTabPanelType = {
  defaultProps?: Partial<TabPanelProps> | undefined;
  styleOverrides?:
    | Partial<
        OverridesStyleRules<'root', 'MuiTabPanel', Omit<Theme, 'components'>>
      >
    | undefined;
};

export const portalThemeOptions: Omit<ThemeOptions, 'typography'> & {
  typography: ExtendedTypographyOptions;
  components: {
    MuiAutocomplete: any;
    MuiDropzoneArea: any;
    MuiDropzonePreviewList: any;
    MuiLoadingButton: any;
    MuiPopper: MuiPopperType;
    MuiTabPanel: MuiTabPanelType;
  };
} = {
  palette,
  typography,
  shadows: [
    'none',
    elevation1BoxShadow,
    elevation2BoxShadow,
    elevation3BoxShadow,
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  breakpoints,
  components: {
    MuiAccordion: {
      defaultProps: {
        square: true,
      },
      styleOverrides: {
        root: {
          border: `1px solid ${defaultTheme.palette.common.border}`,
          color: gray1100,
          padding: defaultTheme.spacing(0),
          '&::before': {
            backgroundColor: defaultTheme.palette.common.border,
            bottom: 0,
            opacity: 0,
            top: 'unset',
          },
          [`& ~ .${accordionClasses.root}`]: {
            borderTop: 0,
          },
          [`&.${accordionClasses.expanded}`]: {
            margin: 0,
          },
          [`&.${paperClasses.root}`]: {
            borderRadius: 0,
          },
          // this syntax is required for disabling "":nth-child is unsafe for SSR" errors
          // https://github.com/emotion-js/emotion/issues/1105
          // https://github.com/mui/material-ui/pull/28982
          '&': {
            [`:nth-child(1 of .${accordionClasses.root}):nth-last-child(1 of .${accordionClasses.root}),
            :nth-child(1 of .${accordionClasses.root}):nth-last-child(1 of .${accordionClasses.root})
            > .${accordionSummaryClasses.root} ${ignoreSsrFlag}`]: {
              borderRadius: borderRadiusLarge,
            },
          },
          '&&': {
            [`:nth-child(1 of .${accordionClasses.root}),
            :nth-child(1 of .${accordionClasses.root}) > .${accordionSummaryClasses.root} ${ignoreSsrFlag}`]: {
              borderTopLeftRadius: borderRadiusLarge,
              borderTopRightRadius: borderRadiusLarge,
            },
          },
          '&&&': {
            [`:nth-last-child(1 of .${accordionClasses.root}),
            :nth-last-child(1 of .${accordionClasses.root}) > .${accordionSummaryClasses.root} ${ignoreSsrFlag}`]: {
              borderBottomLeftRadius: borderRadiusLarge,
              borderBottomRightRadius: borderRadiusLarge,
            },
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: 'block',
          padding: defaultTheme.spacing(3),
          borderTop: `1px solid ${defaultTheme.palette.common.border}`,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '60px',
          padding: defaultTheme.spacing(0, 3),
          [`.${accordionSummaryClasses.content}`]: {
            paddingBlock: defaultTheme.spacing(3),
          },
          [`&&.${accordionSummaryClasses.expanded}`]: {
            minHeight: '60px',
            borderBottomLeftRadius: '0!important',
            borderBottomRightRadius: '0!important',
          },
          [`&.${accordionSummaryClasses.focusVisible}`]: {
            backgroundColor: 'transparent',
            outline: `2px solid ${defaultTheme.palette.primary.main}`,
            zIndex: 1,
          },
          [`&.${accordionSummaryClasses.disabled}`]: {
            opacity: 1,
            [`& .${typographyClasses.root}`]: {
              color: defaultTheme.palette.text.disabled,
            },
            [`& .${svgIconClasses.root}`]: {
              opacity: 0.32,
            },
          },
        },
        content: {
          display: 'block',
          margin: 0,
          padding: defaultTheme.spacing(2, 0),
          [`&.${accordionSummaryClasses.expanded}`]: {
            margin: 0,
          },
        },
        expandIconWrapper: {
          marginRight: -12,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        groupLabel: {
          fontWeight: 'bold',
        },
        inputRoot: {
          padding: '0 0 0 16px',
          '& .MuiInputAdornment-root': {
            color: gray900,
            paddingRight: '8px',
          },
          '&.Mui-disabled .MuiInputAdornment-root': {
            color: defaultTheme.palette.text.disabled,
          },
        },
        listbox: {
          maxHeight: '270px',
          outline: 0,
          overflow: 'auto',
        },
        noOptions: {
          color: gray1200,
          lineHeight: '22px',
          padding: '14px 16px',
        },
        paper: {
          borderRadius: borderRadiusMedium,
          boxShadow: elevation3BoxShadow,
          margin: '4px 0',
        },
        root: {
          marginTop: '.5em',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        invisible: {
          backgroundColor: 'transparent',
        },
        root: {
          backgroundColor: alpha(gray1200, 0.75),
        },
      },
    },
    MuiCard: {
      defaultProps: { variant: 'outlined' },
      styleOverrides: {
        root: {
          border: 0,
          borderRadius: borderRadiusLarge,
          boxShadow: elevation1BoxShadow,
          '&:has(> .MuiCardActionArea-root.Mui-focusVisible)': {
            backgroundColor: 'inherit',
            outline: 'solid',
            outlineColor: defaultTheme.palette.primary[90],
            outlineWidth: '2px',
            outlineOffset: '2px',
          },
          '&:hover:has(> .MuiCardActionArea-root)': {
            boxShadow: elevation2BoxShadow,
          },
          '&:hover:has(> .MuiCardActionArea-root.Mui-disabled)': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          border: `1px solid ${defaultTheme.palette.common.border}`,
          '&.Mui-disabled': {
            backgroundColor: gray200,
            color: defaultTheme.palette.text.disabled,
            '.MuiSvgIcon-root path': {
              fill: defaultTheme.palette.text.disabled,
            },
          },
          height: '100%',
        },
        focusHighlight: {
          display: 'none',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          color: gray1100,
          [`&&& .${accordionClasses.root}`]: {
            borderWidth: '0 0 1px 0',
            borderRadius: '0 !important',
          },
          [`&&&& .${accordionSummaryClasses.root}`]: {
            borderRadius: 0,
          },
          [`& .AccordionGroup`]: {
            marginTop: 0,
          },
        },
      },
    },
    MuiButton: buttonTheme(defaultTheme),
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '8px',
          '& svg': {
            boxShadow: elevation1BoxShadow,
            borderRadius: '4px',
          },
          '&:hover': {
            background: 'transparent',
            color: defaultTheme.palette.primary[110],
          },
          '&:active': {
            color: defaultTheme.palette.primary[90],
            '& svg:first-of-type path:nth-of-type(2)': {
              fill: defaultTheme.palette.primary[10],
            },
          },
          '&.Mui-focusVisible': {
            outline: 'solid',
            outlineColor: defaultTheme.palette.primary[90],
            borderRadius: '12px',
            outlineWidth: '2px',
            outlineOffset: '-6px',
          },
          '&:not(.Mui-checked):not(.MuiCheckbox-indeterminate):not(.Mui-disabled)': {
            color: gray900,
            '&:hover svg rect:nth-of-type(2)': {
              stroke: gray1000,
            },
            '&:active svg rect:nth-of-type(2)': {
              stroke: defaultTheme.palette.primary[90],
            },
          },
          '&.Mui-disabled': {
            color: gray400,
            '& svg': {
              boxShadow: 'none',
            },
            '&:not(.Mui-checked)': {
              '& svg rect': {
                stroke: gray600,
                fill: gray200,
              },
            },
          },
        },
      },
    },
    MuiChip: chipTheme(),
    MuiCircularProgress: {
      defaultProps: {
        size: '16px',
      },
      styleOverrides: {
        colorPrimary: {
          color: blue700,
        },
        root: {
          size: '16px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.common.white,
          borderRadius: borderRadiusLarge,
          border: 'none',
          boxShadow: elevation1BoxShadow,
          color: gray1100,
          overflow: 'hidden',
          '.MuiDataGrid-columnHeaders': {
            backgroundColor: gray25,
          },
          '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          '.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell': {
            whiteSpace: 'break-spaces',
          },
          '.MuiDataGrid-columnHeader': {
            padding: '0 32px',
          },
          '.MuiDataGrid-cell': {
            padding: '0 32px',
            '&:focus': {
              outline: 'none',
            },
          },
          '.MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '.MuiDataGrid-menuIcon': {
            visibility: 'visible',
            width: 'auto',
          },
        },
        panelWrapper: {
          borderRadius: borderRadiusMedium,
          boxShadow: elevation3BoxShadow,
          margin: '16px 0 0 16px',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          elevation: 3,
        },
      },
      styleOverrides: {
        root: {
          padding: '16px',
        },
        paper: {
          borderRadius: borderRadiusLarge,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '32px',
          '.MuiDialogContent-root + &': {
            paddingTop: 0,
          },
          '& > :not(:first-of-type)': {
            marginLeft: defaultTheme.spacing(2), // Apply 16px of spacing between dialog actions
          },
          '& .MuiButton-text:not(:first-of-type)': {
            marginLeft: defaultTheme.spacing(1), // Apply extra 8px of spacing to text buttons
          },
          '& .MuiButton-text:not(:last-child)': {
            marginRight: defaultTheme.spacing(1), // Apply extra 8px of spacing to text buttons
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '32px',
          [`&&& .${accordionClasses.root}`]: {
            borderRadius: `0!important`,
            border: 0,
          },
          [`&& .${accordionSummaryClasses.root}`]: {
            borderRadius: `0!important`,
            borderWidth: '0 0 1px 0',
          },
          [`&& .${accordionDetailsClasses.root}`]: {
            borderTop: 0,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: defaultTheme.palette.common.border,
          opacity: 1,
        },
      },
    },
    MuiDrawer: {
      defaultProps: {
        anchor: 'right',
        PaperProps: {
          elevation: 3,
        },
      },
      styleOverrides: {
        paper: {
          borderRadius: 0,
          minWidth: 'min(100%, 375px)',
          [`& .${accordionClasses.root}`]: {
            border: 0,
          },
        },
        paperAnchorRight: {
          width: drawerWidth,
        },
        root: {
          '.MuiBackdrop-root': {
            background: 'transparent',
          },
        },
      },
    },
    MuiDropzoneArea: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          border: `1px dashed ${gray400}`,
          justifyContent: 'center',
          minHeight: '124px',
          padding: '32px',
        },
        icon: {
          color: gray1100,
          height: '24px',
          width: '24px',
        },
        text: {
          fontSize: '0.875rem',
          fontWeight: '400',
          marginBottom: 0,
          marginTop: '12px',
        },
        textContainer: {
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column-reverse',
        },
      },
    },
    MuiDropzonePreviewList: {
      styleOverrides: {
        root: {
          marginTop: '8px',
          justifyContent: 'center',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          '&:focus': {
            boxShadow: `0 0 0 2pt ${defaultTheme.palette.primary.dark}`,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginBottom: '1em',
          marginTop: 0,
        },
        fullWidth: {
          '& .MuiFormHelperText-root': {
            width: '100%',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: defaultTheme.palette.secondary.light,
          '&$disabled': {
            fontStyle: 'regular',
          },
        },
        label: {
          '&$disabled': {
            color: gray600,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: gray1200,
          fontSize: '0.775rem',
          lineHeight: '20px',
          marginLeft: 0,
          marginTop: '4px',
          '&$disabled': {
            color: gray600,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 620,
          lineHeight: '24px',
          '&.Mui-error': {
            color: gray1200,
          },
          '&.Mui-focused': {
            color: gray1200,
          },
        },
      },
    },
    MuiIconButton: iconButtonTheme(defaultTheme),
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusMedium,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          lineHeight: '24px',
          textDecoration: 'underline',
          textDecorationColor: defaultTheme.palette.primary.main,
          borderRadius: borderRadiusSmall,
          padding: '2px',
          '&.MuiTypography-inherit': {
            fontWeight: 580,
          },
          '&:hover': {
            color: defaultTheme.palette.primary[110],
          },
          '&:focus-visible, &:focus': {
            outlineColor: defaultTheme.palette.primary[90],
            outlineWidth: '2px',
            outlineStyle: 'solid',
            outlineOffset: '2px',
            borderRadius: borderRadiusSmall,
          },
          '&&:focus:not(:focus-visible)': {
            outline: 'none',
          },
        },
        underlineNone: {
          textDecoration: 'none',
          '&:hover, &:focus, &:focus-visible, &:active': {
            textDecoration: 'none',
          },
        },
        underlineHover: {
          textDecoration: 'none',
          '&:focus, &:focus-visible, &:active': {
            textDecoration: 'none',
          },
          '&:hover': {
            textDecoration: 'underline',
          },
        },
        underlineAlways: {
          textDecoration: 'underline',
          '&:hover, &:focus, &:focus-visible, &:active': {
            textDecoration: 'underline',
          },
        },
        button: {
          fontSize: '0.875rem',
          verticalAlign: 'baseline',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$focusVisible': {
            backgroundColor: gray400,
            outline: `2px solid ${defaultTheme.palette.primary.main}`,
            outlineOffset: '-2px', // TODO: This doesn't look quite right
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
          '& .MuiLink-root': {
            fontSize: 'inherit',
            fontWeight: 'inherit',
            '&.MuiLink-underlineHover': {
              textDecoration: 'none',
              '&:hover, &:focus, &:focus-visible': {
                backgroundColor: 'inherit',
                textDecoration: 'none',
                color: 'initial',
              },
            },
          },
          // innerRadius + distance between inner and outer element = outerRadius
          '& > *:has(> .MuiMenuItem-root)': {
            [`&:first-child${ignoreSsrFlag} .MuiMenuItem-root`]: {
              borderTopLeftRadius: `calc(${borderRadiusMedium} - 1px)`,
              borderTopRightRadius: `calc(${borderRadiusMedium} - 1px)`,
            },
            '&:last-child .MuiMenuItem-root': {
              borderBottomLeftRadius: `calc(${borderRadiusMedium} - 1px)`,
              borderBottomRightRadius: `calc(${borderRadiusMedium} - 1px)`,
            },
          },
          '& > .MuiMenuItem-root': {
            [`&:first-child${ignoreSsrFlag}`]: {
              borderTopLeftRadius: `calc(${borderRadiusMedium} - 1px)`,
              borderTopRightRadius: `calc(${borderRadiusMedium} - 1px)`,
            },
            '&:last-child': {
              borderBottomLeftRadius: `calc(${borderRadiusMedium} - 1px)`,
              borderBottomRightRadius: `calc(${borderRadiusMedium} - 1px)`,
            },
          },
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        loadingIndicator: React.createElement(CircularProgress, {
          'aria-label': 'Loading',
          size: '1.5em',
          style: { color: `${defaultTheme.palette.primary.main}` },
          thickness: 5,
        }),
        variant: 'contained',
      },
      styleOverrides: {
        pendingIndicator: {
          '& > .MuiCircularProgress-root': {
            color: defaultTheme.palette.primary.main,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          minWidth: '180px',
          borderRadius: borderRadiusMedium,
          boxShadow: `${elevation3BoxShadow} !important`,
        },
      },
    },
    MuiMenuItem: menuItemTheme(defaultTheme),
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: borderRadiusLarge,
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 2,
      },
      styleOverrides: {
        root: {
          borderRadius: borderRadiusMedium,
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          [`& .${paperClasses.root}`]: {
            borderRadius: borderRadiusMedium,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: gray900,
          padding: '8px',
          '& svg': {
            boxShadow: elevation1BoxShadow,
            borderRadius: '50%',
          },
          '&:hover': {
            color: defaultTheme.palette.primary[110],
            background: 'transparent',
            '&:not(.Mui-checked) svg:first-of-type > circle': {
              stroke: gray1000,
            },
          },
          '&:active': {
            color: defaultTheme.palette.primary[90],
          },
          '&.Mui-focusVisible': {
            color: defaultTheme.palette.primary.main,
            '& svg': {
              borderRadius: '50%',
              outlineColor: defaultTheme.palette.primary.main,
              outlineWidth: '2px',
              outlineStyle: 'solid',
              outlineOffset: '2px',
              '& > circle:first-of-type': {
                strokeWidth: 3,
              },
            },
          },
          '&.Mui-disabled': {
            color: gray400,
            '& svg': {
              boxShadow: 'none',
            },
            'svg:first-of-type > circle:first-of-type': {
              stroke: gray600,
              fill: gray200,
            },
            'svg:first-of-type > circle:nth-of-type(2)': {
              fill: gray600,
            },
          },
          '& label .MuiFormControlLabel-label': {
            color: defaultTheme.palette.secondary.light,
          },
          '& .Mui-disabled label .MuiFormControlLabel-label': {
            color: gray900,
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: borderRadiusMedium,
          [`&.${skeletonClasses.circular}`]: {
            borderRadius: '50%',
          },
        },
      },
    },
    MuiStepLabel: {
      defaultProps: {
        StepIconComponent: StepIcon,
      },
      styleOverrides: {
        label: {
          color: gray600,
          fontWeight: 600,
          '&.MuiStepLabel-alternativeLabel': {
            marginTop: '5px',
          },
          '&.Mui-active': {
            color: defaultTheme.palette.primary.main,
            fontWeight: 600,
          },
          '&.Mui-completed': {
            color: gray1200,
            fontWeight: 600,
          },
          '&.Mui-active.Mui-error': {
            color: red700,
          },
        },
        vertical: {
          padding: '16px 0',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: gray600,
          borderTopWidth: '2px',
        },
        vertical: {
          '.MuiStepConnector-line': {
            borderLeftWidth: '2px',
            minHeight: '40px',
          },
        },
      },
    },
    MuiStepper: {
      defaultProps: {
        alternativeLabel: true,
      },
      styleOverrides: {
        vertical: {
          'div:first-of-type .MuiStepLabel-root': {
            paddingTop: '0px',
          },
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          '.MuiButtonBase-root:hover': {
            '.MuiStepLabel-root': {
              '.MuiStepLabel-label, .MuiSvgIcon-root': {
                color: blue800,
              },
              '&.Mui-error': {
                '.MuiStepLabel-label, .MuiSvgIcon-root': {
                  color: red900,
                },
              },
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          display: 'flex',
          height: 'unset',
          padding: '0',
          margin: '0.75rem',
          overflow: 'visible',
          width: 'unset',
        },
        switchBase: {
          left: 3,
          padding: 0,
          top: 3,
          transform: 'translateX(0px)',
          transition: defaultTheme.transitions.create(['transform'], {
            duration: 200,
          }),
          '&:hover': {
            '& + .MuiSwitch-track': {
              backgroundColor: gray600,
            },
            '&.Mui-checked + .MuiSwitch-track': {
              backgroundColor: blue800,
            },
          },
          '&.Mui-checked': {
            transform: 'translateX(20px)',
            '&:active + .MuiSwitch-track': {
              backgroundColor: blue600,
            },
          },
          '&.Mui-disabled': {
            '&.Mui-checked': {
              '.MuiSwitch-thumb': {
                backgroundColor: blue100,
              },
              '& + .MuiSwitch-track': {
                backgroundColor: blue300,
              },
            },
            '& + .MuiSwitch-track': {
              backgroundColor: gray300,
              boxShadow: 'none',
            },
          },
          '&.Mui-focusVisible': {
            padding: 0,
            '& + .MuiSwitch-track': {
              outline: `2px solid ${blue600}`,
              outlineOffset: '2px',
            },
          },
        },
        thumb: {
          backgroundColor: colorWhite,
          boxSizing: 'border-box',
          boxShadow: 'none',
          borderRadius: 18 / 2,
          height: 18,
          width: 18,
        },
        track: {
          backgroundColor: gray500,
          borderRadius: 24 / 2,
          boxShadow: elevation1BoxShadow,
          boxSizing: 'border-box',
          height: 24,
          opacity: '1 !important',
          width: 44,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: gray900,
          maxWidth: '264px',
          minWidth: '72px',
          padding: '16px',
          textTransform: 'none',
          borderBottom: 0,
          '&:hover:not(.Mui-selected)': {
            color: gray1200,
          },
          '&.Mui-disabled': {
            color: gray600,
          },
          '&:focus, &:focus-visible': {
            outlineColor: defaultTheme.palette.primary[90],
            outlineWidth: '2px',
            outlineStyle: 'solid',
            outlineOffset: '-2px',
          },
          '&:focus:not(:focus-visible)': {
            outline: 'none',
          },
          '&.Mui-selected': {
            color: `${gray1200}!important`,
            fontWeight: '600',
          },
        },
        textColorInherit: {
          color: gray1200,
          opacity: 1,
          '&.Mui-disabled': {
            opacity: 1,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: '35px',
          borderBottom: `1px solid ${defaultTheme.palette.common.border}`,
        },
        indicator: {
          ...(isChromatic && isChromatic() && { display: 'none' }),
          height: '4px',
          borderRadius: '2px 2px 0 0',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: colorWhite,
          borderRadius: borderRadiusLarge,
          boxSizing: 'border-box',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          color: gray1100,
          '&.MuiTableCell-sizeMedium': {
            padding: '0 24px',
            height: '56px',
            maxHeight: '56px',
          },
          '&.MuiTableCell-sizeSmall': {
            fontSize: '0.875rem',
            padding: '0 16px',
            height: '48px',
            maxHeight: '48px',
          },
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${gray400}`,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${gray400}`,
          '& > tr > th.MuiTableCell-root': {
            ...typography.tableHeader,
            backgroundColor: gray25,
            color: gray1100,
            height: '40px',
            maxHeight: '40px',
            '&.MuiTableCell-sizeMedium': {
              padding: '0 24px',
            },
            '&.MuiTableCell-sizeSmall': {
              padding: '0 16px',
            },
            '& > .MuiButtonBase-root:not(.MuiCheckbox-root)': {
              color: gray1100,
              '&:hover, &:focus': {
                opacity: 0.6,
              },
              '&.Mui-active, &.Mui-active:hover': {
                opacity: 1.0,
              },
            },
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
            fontWeight: 400,
            lineHeight: '24px',
            margin: 0,
          },
          '& .MuiInputBase-root .MuiTablePagination-select.MuiSelect-select': {
            background: colorWhite,
            border: `1px solid ${gray400}`,
            borderRadius: borderRadiusSmall,
            padding: '16px 32px 16px 16px',

            '&:focus': {
              boxShadow: `0 0 0 4px ${defaultTheme.palette.primary.main} inset`,
            },
          },
          '& .MuiInputBase-root:active': {
            boxShadow: `0 0 0 4px ${defaultTheme.palette.primary.main} inset`,
          },
          '& .MuiTablePagination-selectIcon': {
            marginRight: '8px',
            fill: gray1200,
          },
          '& .MuiToolbar-root': {
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: colorWhite,
          '&:not(:first-child)': {
            borderTop: `solid 1px ${gray400}`,
          },
          '&.Mui-selected:hover': {
            backgroundColor: lighten(defaultTheme.palette.primary.light, 0.08),
          },
          '&:hover:not(.Mui-selected), &.MuiTableRow-hover:hover:not(.Mui-selected)': {
            backgroundColor: colorWhite,
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            '& .MuiTableSortLabel-icon': {
              color: gray1200,
            },
          },
          '&:focus': {
            outline: 'solid',
            outlineColor: defaultTheme.palette.primary[90],
            borderRadius: '4px',
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: gray300,
          borderRadius: '40px',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 'none',
          borderRadius: '40px !important', // Undo MuiToggleButtonGroup's corner radius override
          color: gray900,
          margin: '2px',
          padding: '2px 12px',
          '&.Mui-selected': {
            backgroundColor: colorWhite,
            boxShadow: elevation1BoxShadow,
            color: gray1100,
            '&:hover': {
              backgroundColor: colorWhite,
              color: gray1200,
            },
          },
          '&:hover': {
            backgroundColor: 'transparent',
            color: gray1100,
          },
          '&:focus-visible': {
            outlineColor: defaultTheme.palette.primary[90],
            outlineWidth: '2px',
            outlineStyle: 'solid',
            outlineOffset: '-2px',
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        arrow: {
          color: colorWhite,
        },
        tooltip: {
          backgroundColor: colorWhite,
          boxShadow: elevation2BoxShadow,
          fontWeight: defaultTheme.typography.body1.fontWeight,
          color: gray1200,
          fontSize: '0.875rem',
          padding: '8px 16px',
          '& a:hover': {
            color: defaultTheme.palette.primary.main,
          },
          '& :before': {
            boxShadow: elevation2BoxShadow,
          },
        },
      },
    },
  },
  borderRadius: {
    borderRadiusSmall,
    borderRadiusMedium,
    borderRadiusLarge,
  },
  shape: {
    borderRadius: 8,
  },
};

const unicornMuiTheme = createTheme(portalThemeOptions);

export default unicornMuiTheme;
