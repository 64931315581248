import deleteSiteHandlers from 'componentsDir/AllSites/mocks/deleteSiteHandlers';
import editSiteHandlers from 'componentsDir/AllSites/mocks/editSiteHandlers';
import getCSVByUserHandlers from 'componentsDir/AllSites/mocks/getCSVByUserHandlers';
import getFiltersByUserHandlers from 'componentsDir/AllSites/mocks/getFiltersByUserHandlers';
import getSiteNotesHandlers from 'componentsDir/AllSites/mocks/getSiteNotesHandlers';
import listSitesByUserHandlers from 'componentsDir/AllSites/mocks/listSitesByUserHandlers';
import siteExistsHandlers from 'componentsDir/AllSites/mocks/siteExistsHandlers';
import upsertSiteNotesHandlers from 'componentsDir/AllSites/mocks/upsertSiteNoteHandlers';
import wpVersionByInstallHandlers from 'componentsDir/AllSites/mocks/wpVersionByInstallHandlers';
import tagHandlers from 'componentsDir/AllSites/mocks/siteTags';
import getUserSpecifiedInstalls from 'componentsDir/AllSites/components/NeedsUpdates/mocks/getUserSpecifiedInstalls';
import upgradeSandboxSiteHandlers from 'componentsDir/AllSites/mocks/upgradeSandboxSiteHandlers';
import getSiteHandlers from 'componentsDir/AllSites/mocks/getSiteHandlers';

const allSitesHandlers = [
  ...deleteSiteHandlers,
  ...editSiteHandlers,
  ...getCSVByUserHandlers,
  ...getFiltersByUserHandlers,
  ...getSiteHandlers,
  ...getSiteNotesHandlers,
  ...listSitesByUserHandlers,
  ...siteExistsHandlers,
  ...tagHandlers,
  ...upsertSiteNotesHandlers,
  ...wpVersionByInstallHandlers,
  ...getUserSpecifiedInstalls,
  ...upgradeSandboxSiteHandlers,
];

export default allSitesHandlers;
