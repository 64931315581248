import { PlanSubscription } from 'componentsDir/addons/types';

const mockPremiumV2Update: PlanSubscription = {
  account_id: 123,
  subscription_plan_id: 'p0b',
  subscription_price: 400.0,
  subscription_plan_name: 'P0b',
  datacenter: 'us',
  plan_base_price: 400.0,
  current_subscription_price: 400.0,
  currency: 'USD',
  locale: 'en',
  v2_update_eligible: true,
  annual_terms_enabled: false,
  v2_savings: {
    month: 35.0,
    annual: 420.0,
  },
  selected_plan_name: 'Premium 0',
  selected_plan_id: 'p0-v2',
  subscription_recurrence: 'MONTHLY',
  selected_plan_price_monthly: 400.0,
  selected_plan_price_annually: 4416.0,
  selected_plan_is_premium: true,
  selected_datacenter: 'us',
  period_end: '2023-03-26T09:01:51.000+00:00',
  terms: 'ON_RECEIPT',
  is_premium: true,
  plan_features: [
    {
      name: 'Sites',
      value: '999,999,999',
    },
    {
      name: 'Visits / month',
      value: '999,999,999',
    },
    {
      name: 'Extra Storage',
      value: '100 GB',
    },
  ],
  display_upgrade_site_addon_notice: false,
  display_upgrade_site_addon_threshold_price: null,
  can_self_change_to_annual: true,
  plan_addons: [
    {
      id: 'smartsearch-hybrid',
      name: 'WP Engine Smart Search AI',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 140.0,
      legacy_entitlement: false,
    },
    {
      id: 'monitoring-site',
      name: 'Site Monitoring',
      limit: 1000,
      qty_included: 1,
      additional_qty: 1,
      subscription_qty: 0,
      price: 5.0,
      legacy_entitlement: false,
      dropdown_value: '1 monitoring license',
    },
    {
      id: 'smart-plugin-manager',
      name: 'Smart Plugin Manager',
      limit: 1000,
      qty_included: 1,
      additional_qty: 1,
      subscription_qty: 0,
      price: 10.0,
      legacy_entitlement: false,
      dropdown_value: '1 environment license',
    },
    {
      id: 'global-edge-security',
      name: 'Global Edge Security',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 250.0,
      legacy_entitlement: false,
    },
    {
      id: 'genesis-pro',
      name: 'Genesis Pro',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 30.0,
      legacy_entitlement: false,
    },
    {
      id: 'page-speed-boost',
      name: 'Page Speed Boost',
      limit: 999999,
      qty_included: 1,
      additional_qty: 1,
      subscription_qty: 0,
      price: 20.0,
      legacy_entitlement: false,
      dropdown_value: '1 environment license',
    },
    {
      id: 'smart-plugin-manager-5-pack',
      name: 'Smart Plugin Manager licenses (5)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 5,
      subscription_qty: 0,
      price: 30.0,
      legacy_entitlement: false,
      dropdown_value: '5 environment licenses',
    },
    {
      id: 'smart-plugin-manager-15-pack',
      name: 'Smart Plugin Manager licenses (15)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 15,
      subscription_qty: 0,
      price: 75.0,
      legacy_entitlement: false,
      dropdown_value: '15 environment licenses',
    },
    {
      id: 'smart-plugin-manager-25-pack',
      name: 'Smart Plugin Manager licenses (25)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 25,
      subscription_qty: 0,
      price: 100.0,
      legacy_entitlement: false,
      dropdown_value: '25 environment licenses',
    },
    {
      id: 'smart-plugin-manager-50-pack',
      name: 'Smart Plugin Manager licenses (50)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 50,
      subscription_qty: 0,
      price: 150.0,
      legacy_entitlement: false,
      dropdown_value: '50 environment licenses',
    },
    {
      id: 'smart-plugin-manager-100-pack',
      name: 'Smart Plugin Manager licenses (100)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 100,
      subscription_qty: 0,
      price: 250.0,
      legacy_entitlement: false,
      dropdown_value: '100 environment licenses',
    },
    {
      id: 'smart-plugin-manager-150-pack',
      name: 'Smart Plugin Manager licenses (150)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 150,
      subscription_qty: 0,
      price: 300.0,
      legacy_entitlement: false,
      dropdown_value: '150 environment licenses',
    },
    {
      id: 'local-storage',
      name: 'Extra Storage',
      limit: 1100,
      qty_included: 100,
      additional_qty: 250,
      subscription_qty: 0,
      price: 100.0,
      legacy_entitlement: false,
    },
    {
      id: 'premium-plus-services',
      name: 'Premium Plus',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 2000.0,
      legacy_entitlement: false,
    },
    {
      id: 'monitoring-site-05',
      name: 'Site Monitoring licenses (5)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 5,
      subscription_qty: 0,
      price: 15.0,
      legacy_entitlement: false,
      dropdown_value: '5 monitoring licenses',
    },
    {
      id: 'monitoring-site-15',
      name: 'Site Monitoring licenses (15)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 15,
      subscription_qty: 0,
      price: 20.0,
      legacy_entitlement: false,
      dropdown_value: '15 monitoring licenses',
    },
    {
      id: 'monitoring-site-25',
      name: 'Site Monitoring licenses (25)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 25,
      subscription_qty: 0,
      price: 25.0,
      legacy_entitlement: false,
      dropdown_value: '25 monitoring licenses',
    },
    {
      id: 'monitoring-site-50',
      name: 'Site Monitoring licenses (50)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 50,
      subscription_qty: 0,
      price: 35.0,
      legacy_entitlement: false,
      dropdown_value: '50 monitoring licenses',
    },
    {
      id: 'monitoring-site-100',
      name: 'Site Monitoring licenses (100)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 100,
      subscription_qty: 0,
      price: 50.0,
      legacy_entitlement: false,
      dropdown_value: '100 monitoring licenses',
    },
    {
      id: 'page-speed-boost-3-pack',
      name: 'Page Speed Boost licenses (3)',
      limit: 999999,
      qty_included: 0,
      additional_qty: 3,
      subscription_qty: 0,
      price: 45.0,
      legacy_entitlement: false,
      dropdown_value: '3 environment licenses',
    },
    {
      id: 'page-speed-boost-5-pack',
      name: 'Page Speed Boost licenses (5)',
      limit: 999999,
      qty_included: 0,
      additional_qty: 5,
      subscription_qty: 0,
      price: 70.0,
      legacy_entitlement: false,
      dropdown_value: '5 environment licenses',
    },
    {
      id: 'page-speed-boost-15-pack',
      name: 'Page Speed Boost licenses (15)',
      limit: 999999,
      qty_included: 0,
      additional_qty: 15,
      subscription_qty: 0,
      price: 175.0,
      legacy_entitlement: false,
      dropdown_value: '15 environment licenses',
    },
    {
      id: 'page-speed-boost-25-pack',
      name: 'Page Speed Boost licenses (25)',
      limit: 999999,
      qty_included: 0,
      additional_qty: 25,
      subscription_qty: 0,
      price: 250.0,
      legacy_entitlement: false,
      dropdown_value: '25 environment licenses',
    },
    {
      id: 'page-speed-boost-50-pack',
      name: 'Page Speed Boost licenses (50)',
      limit: 999999,
      qty_included: 0,
      additional_qty: 50,
      subscription_qty: 0,
      price: 300.0,
      legacy_entitlement: false,
      dropdown_value: '50 environment licenses',
    },
    {
      id: 'page-speed-boost-100-pack',
      name: 'Page Speed Boost licenses (100)',
      limit: 999999,
      qty_included: 0,
      additional_qty: 100,
      subscription_qty: 0,
      price: 450.0,
      legacy_entitlement: false,
      dropdown_value: '100 environment licenses',
    },
  ],
};

export default mockPremiumV2Update;
