import * as React from 'react';
import {
  borderRadiusLarge,
  colorWhite,
  teal400,
  teal900,
  teal1100,
} from 'design-tokens';
import { EnvironmentLabelProps, sharedStyles } from './shared';
import { Box } from '../layout';

interface InnerBoxProps {
  children: React.ReactNode;
  useFullLabel: boolean;
}

const InnerBox: React.FC<InnerBoxProps> = ({ children, useFullLabel }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: colorWhite,
        borderRadius: borderRadiusLarge,
        display: 'flex',
        justifyContent: 'center',
        padding: useFullLabel ? '0px 16px' : '0px 11px',
      }}
    >
      {children}
    </Box>
  );
};

const DevelopmentEnvironmentLabel = ({
  sx = {},
  className,
  component = 'div',
  testid,
  useFullLabel = false,
  onClick = () => null,
  ...props
}: Omit<EnvironmentLabelProps, 'environment'>) => {
  return (
    <Box
      className={className}
      component={component}
      data-testid={testid}
      onClick={onClick}
      {...props}
      sx={{
        ...sx,
        ...sharedStyles(useFullLabel),
        backgroundImage: `repeating-linear-gradient(-75deg, ${teal900}, ${teal900} 8px, ${teal400} 8px, ${teal400} 14px)`,
        color: teal1100,
        padding: '2px',
      }}
    >
      <InnerBox useFullLabel={useFullLabel}>
        {useFullLabel ? 'Development' : 'Dev'}
      </InnerBox>
    </Box>
  );
};

export default DevelopmentEnvironmentLabel;
