import { TaggableSite } from 'componentsDir/ManageSiteTagsPage/types';
import generateRandomWpeId from 'componentsDir/ManageSiteTagsPage/utils/generateRandomWpeId';
import { defaults } from 'lodash';

interface GenerateTaggableSitesProps {
  numberOfAccounts: number;
  numberOfSitesPerAccount: number;
  randomizeNumberOfSitesPerAccount: boolean;
  taggable: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const generateTaggableSites = (
  props?: GenerateTaggableSitesProps
): TaggableSite[] => {
  const {
    numberOfAccounts,
    numberOfSitesPerAccount,
    randomizeNumberOfSitesPerAccount,
    taggable,
  } = defaults(props, {
    numberOfAccounts: 3,
    numberOfSitesPerAccount: 3,
    randomizeNumberOfSitesPerAccount: false,
    taggable: true,
  });

  if (numberOfAccounts <= 0 || numberOfSitesPerAccount <= 0) {
    return [];
  }

  const getRandomNumberOfSites = () =>
    Math.floor(Math.random() * numberOfSitesPerAccount) + 1;

  return Array.from({ length: numberOfAccounts }).flatMap(
    (_x, accountIndex) => {
      const sitesCount = randomizeNumberOfSitesPerAccount
        ? getRandomNumberOfSites()
        : numberOfSitesPerAccount;

      return Array.from({ length: sitesCount }, (_y, siteIndex) => ({
        uuid: `${accountIndex}-${siteIndex}`,
        name: `site${accountIndex}${siteIndex}.com`,
        account: {
          wpeId: generateRandomWpeId(),
        },
        taggable,
      }));
    }
  );
};
