import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getCurrentAccountId = () => {
  return cookies.get('current_account_id');
};

export const getCurrentAccountUUID = () => {
  return cookies.get('current_account_uuid');
};

export const currentAccountWpeIdCookieName = 'current_account_wpe_id';
export const getCurrentAccountWpeId = () => {
  return cookies.get(currentAccountWpeIdCookieName);
};

export const getCurrentAccountName = () => {
  return cookies.get('current_account_name');
};

export const isAllAccountsCookieName = 'all_accounts_view';
export const isAllAccountsView = () => {
  return cookies.get(isAllAccountsCookieName);
};
