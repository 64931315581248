import { rest } from 'msw';
import { MSW_PARAMS, ENDPOINTS } from 'componentsDir/AllSites/constants';
import { hasPageUrlQueryParam } from 'mocksDir/utils/browserUtils';
import responses from 'componentsDir/AllSites/mocks/data/wpVersionByInstall';

const handlers = [
  rest.post(
    `*${ENDPOINTS.ALL_SITES_WP_VERSION_BY_INSTALL}*`,
    (req, res, ctx) => {
      if (hasPageUrlQueryParam(MSW_PARAMS.EMPTY_WP_VERSIONS)) {
        return res(ctx.status(200), ctx.json(responses.empty.versions));
      }

      const installNames = Array.isArray(req.body) ? req.body : [];

      return res(
        ctx.status(200),
        ctx.delay(50),
        ctx.json({
          versions: installNames?.map(installName => ({
            installName,
            wpVersion: '1.2.3',
          })),
        })
      );
    }
  ),
];

export default handlers;
