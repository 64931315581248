import { Box, Typography } from '@wpengine/unicorn/components';
import React, { ComponentProps } from 'react';
import { ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';
import { borderRadiusLarge, gray1200, colorWhite } from 'design-tokens';

const ErrorBoundary = (props: ComponentProps<typeof RollbarErrorBoundary>) => {
  return <RollbarErrorBoundary fallbackUI={FallbackUI} {...props} />;
};

export default ErrorBoundary;

const FallbackUI = () => {
  return (
    <Box
      border={`2px dashed ${gray1200}`}
      p={2}
      my={2}
      mx="auto"
      width="fit-content"
      borderRadius={borderRadiusLarge}
      bgcolor={colorWhite}
    >
      <Typography variant="h4" textAlign="center">
        Oops! An error has occurred.
      </Typography>
      <Typography textAlign="center">Please refresh the page.</Typography>
    </Box>
  );
};
