import { UpdateResponse } from 'componentsDir/install/repositories/UtilitiesApiClient';

export const mockPasswordResponse: UpdateResponse = {
  success: true,
  user: 'myinstall',
  password: 'tops3cret',
};

export const mockDisabledResponse: UpdateResponse = {
  success: true,
  password: null,
  user: null,
};

export const mockPasswordResponseError: UpdateResponse = {
  success: false,
  message: 'An error occurred',
};
