type Callback<T = any> = (data: T) => void;

interface Subscribers {
  [event: string]: Callback[];
}

const subscribers: Subscribers = {};

export const subscribe = <T = any>(event: string, callback: Callback<T>) => {
  if (!subscribers[event]) {
    subscribers[event] = [];
  }
  subscribers[event].push(callback);
  return () => unsubscribe(event, callback);
};

export const publish = <T = any>(event: string, data?: T) => {
  if (!subscribers[event]) return;
  subscribers[event].forEach(callback => callback(data));
};

export const unsubscribe = <T = any>(event: string, callback: Callback<T>) => {
  if (!subscribers[event]) return;
  subscribers[event] = subscribers[event].filter(cb => cb !== callback);
};
