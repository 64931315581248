const generateSampleFeatureUsageV2Data = (
  data?: Partial<FeatureUsageV2>
): FeatureUsageV2Response => {
  return {
    results: {
      elasticsearch: false,
      evercache: false,
      genesis_page_builder: false,
      live_cart: false,
      qss: false,
      spm: false,
      woocommerce: false,
      wpe_payments: false,
      ecom_config: false,
      dynamic_plugin_loading: false,
      ...data,
    },
  };
};

export default generateSampleFeatureUsageV2Data;
