const generatePSBIntegrationData = () => {
  return {
    integrations: [
      {
        name: 'integrations/9b573e09-92fd-4228-8b58-9b9264f17c51',
        installName: 'puredaycent',
        type: 'PAGE_SPEED_BOOST',
        state: 'ACTIVE',
        lastErrorDetails: '',
        lastError: 'NO_ERROR',
        lastErrorPrettyMessage: '',
        templateUuid: '3fe3cddf-590a-401c-8ed5-4368d05355c0',
        activationUuid: '9b573e09-92fd-4228-8b58-9b9264f17c51',
        nitropack: {
          envType: 'PRD',
          nitropackExternalId: 'aivxYomursheFsuYudJGOGpFMINNvLte',
          licensedUri: 'puredaycent.wpenginepoweredstaging.com',
          safeMode: 'DISABLED',
          setting: 'FOUR',
        },
        lastUpdateTime: '2024-03-04T15:46:07.594603Z',
      },
    ],
  };
};

export default generatePSBIntegrationData;
