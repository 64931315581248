import { mockPlanUpgrade } from 'componentsDir/shared/providers/BillingMediator/mocks/data/mockBillingData';
import { Cart } from '../../types/types';

const mockCart: Cart = {
  id: '1',
  account: {
    portal_account_uuid: '',
    name: '',
    plan: '',
    subscription: '',
  },
  new_plan: mockPlanUpgrade,
  selected_addons: [
    {
      base_sku: 'page-speed-boost',
      quantity: 1,
    },
    {
      base_sku: 'sites',
      quantity: 1,
    },
    {
      base_sku: 'multisite',
      quantity: 1,
    },
  ],
  user: {
    email: '',
  },
};

export default mockCart;
