import { DNS_STATUSES, DNS_WARNINGS } from '../constants/dns-statuses';
import { SSL_STATUSES } from '../constants/ssl-statuses';

export interface DsaResponseExtended extends Omit<DsaResponse, 'domains'> {
  domains: DsaDomainExtended[];
}

export enum DsaSslStatus {
  Verified = 'verified',
  Expired = 'expired',
  CertError = 'cert_error',
  NoHost = 'no_host',
  NoCert = 'no_cert',
  DNSError = 'dns_error',
  Unknown = 'unknown',
}

export enum DsaResult {
  okWPEngine = 'ok_wpengine',
  okCNAME = 'ok_cname',
  okNotCNAME = 'ok_not_cname',
  okProxied = 'ok_proxied',
  okGlobalIngress = 'ok_global_ingress',
  okGlobalEdgeSecurity = 'ok_global_edge_security',
  okAtlas = 'ok_atlas',
  errorWrongInstall = 'error_wrong_install',
  errorNotWPE = 'error_not_wpe',
  errorDoesNotExist = 'error_does_not_exist',
  errorHTTP = 'error_http',
  errorProxiedByWPE = 'error_proxied_by_wpe',
  empty = '',
}
export interface DsaDomainExtended extends DsaDomain {
  aaaa_record: string;
  isAdmin?: boolean;
  isRefetching?: boolean;
  result: DsaResult;
  ssl: {
    status: DsaSslStatus;
    issuer: string;
    not_before: string;
    not_after: string;
    error: string;
  };
  dnsStatus?: DNS_STATUSES;
  sslStatus?: SSL_STATUSES;
  warnings?: DNS_WARNINGS[];
}
