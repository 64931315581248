import { EditSiteDataResponse } from 'componentsDir/AllSites/types';

interface Responses {
  [key: string]: EditSiteDataResponse;
}

const responses: Responses = {
  success: {
    id: 1,
    name: '5555',
    accountId: 1,
    groupId: null,
    transferable: false,
    sandbox: false,
  },
};

export default responses;
