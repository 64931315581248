// eslint-disable-next-line import/prefer-default-export
export const templateSlowQueryResponse: SlowQueriesResponse = {
  query: {
    uuid: '46906d5d-7096-4a65-951a-6925c1164a1f',
    createdAt: '2024-03-07T16:25:12.619362Z',
    queryTime: 6000320,
    lockTime: 0,
    requestUrl: 'example.com/wp-cron.php',
    assetType: 'AssetPlugin',
    assetName: 'examplePlugin',
    sourceFile: 'wp-content/plugins/example-plugin/example-plugin.php',
    sourceLine: 26,
    rowsAffected: 7,
    rowsExamined: 8,
    rowsSent: 9,
    killed: false,
    bytesSent: 63,
    queryString: 'SELECT SLEEP ( ? ) WHERE ? = ? OR ? = ?;',
  },
};

export const templateSlowQueryResponseReadableName: SlowQueriesResponse = {
  query: {
    uuid: '46906d5d-7096-4a65-951a-6925c1164a1f',
    createdAt: '2024-03-07T16:25:12.619362Z',
    queryTime: 6000320,
    lockTime: 0,
    requestUrl: 'example.com/wp-cron.php',
    assetType: 'AssetPlugin',
    assetName: 'examplePlugin',
    sourceFile: 'wp-content/plugins/example-plugin/example-plugin.php',
    sourceLine: 26,
    rowsAffected: 7,
    rowsExamined: 8,
    rowsSent: 9,
    killed: false,
    bytesSent: 63,
    queryString: 'SELECT SLEEP ( ? ) WHERE ? = ? OR ? = ?;',
    readableName: 'Example Plugin',
  },
};

export const templateSlowQueryNULL: SlowQueriesResponse = {
  ...templateSlowQueryResponse,
  query: {
    ...templateSlowQueryResponse.query,
    requestUrl: null,
  },
};

export const templateSlowQueryEmpty: SlowQueriesResponse = {
  ...templateSlowQueryResponse,
  query: {
    ...templateSlowQueryResponse.query,
    requestUrl: '',
  },
};

export const templateSQMAggProblematicResponse: SlowQueryAggregationProblematicSources = {
  results: [
    {
      assetName: 'rev-slider',
      assetType: 'plugin',
      readableName: 'RevSlider',
      averageRunTime: '9543211',
      occurrences: '151',
    },
    {
      assetName: 'WooCommerce',
      assetType: 'plugin',
      readableName: 'WooCommerce',
      averageRunTime: '8543211',
      occurrences: '141',
    },
    {
      assetName: 'Twenty Twenty Four',
      assetType: 'theme',
      readableName: 'twentytwentyfour',
      averageRunTime: '7543211',
      occurrences: '95',
    },
    {
      assetName: 'mailchimp-woocommerce',
      assetType: 'plugin',
      readableName: 'Mailchimp for WooCommerce',
      averageRunTime: '7543211',
      occurrences: '63',
    },
    {
      assetName: 'klarna-payments',
      assetType: 'plugin',
      readableName: 'Klarna Payments',
      averageRunTime: '5543211',
      occurrences: '37',
    },
  ],
};

export const templateSQMAggProblematicGenericRecResponse: SlowQueryAggregationProblematicSources = {
  ...templateSQMAggProblematicResponse,
  results: [
    {
      assetName: 'elementor',
      assetType: 'plugin',
      readableName: 'Elementor',
      averageRunTime: '4321123',
      occurrences: '45',
    },
    {
      assetName: 'yoast-seo',
      assetType: 'plugin',
      readableName: 'Yoast SEO',
      averageRunTime: '3211234',
      occurrences: '52',
    },
    {
      assetName: 'jetpack',
      assetType: 'plugin',
      readableName: 'Jetpack',
      averageRunTime: '6543210',
      occurrences: '29',
    },
    {
      assetName: 'twenty-twenty-one',
      assetType: 'theme',
      readableName: 'Twenty Twenty One',
      averageRunTime: '1234567',
      occurrences: '15',
    },
    {
      assetName: 'woocommerce',
      assetType: 'plugin',
      readableName: 'WooCommerce',
      averageRunTime: '9876543',
      occurrences: '37',
    },
    {
      assetName: 'akismet',
      assetType: 'plugin',
      readableName: 'Akismet',
      averageRunTime: '8765432',
      occurrences: '22',
    },
    {
      assetName: 'contact-form-7',
      assetType: 'plugin',
      readableName: 'Contact Form 7',
      averageRunTime: '7654321',
      occurrences: '18',
    },
    {
      assetName: 'twenty-twenty-two',
      assetType: 'theme',
      readableName: 'Twenty Twenty Two',
      averageRunTime: '2345678',
      occurrences: '12',
    },
    {
      assetName: 'google-site-kit',
      assetType: 'plugin',
      readableName: 'Google Site Kit',
      averageRunTime: '3456789',
      occurrences: '25',
    },
    {
      assetName: 'wp-rocket',
      assetType: 'plugin',
      readableName: 'WP Rocket',
      averageRunTime: '4567890',
      occurrences: '30',
    },
  ],
};

export const templateSlowQueryLogResponse: SlowQueryLogResponse = {
  slowQueries: [
    {
      uuid: '1033ff2a-20be-4587-a880-92d0d4624774',
      createdAt: '2024-07-01T16:25:18.886987Z',
      queryTime: 6000305,
      lockTime: 0,
      requestUrl: 'sqmsite.wpenginepoweredstaging.com/?wp-cmd=cron',
      assetType: 'plugin',
      assetName: 'Jetpack - WP Security',
      sourceFile: 'wp-content/plugins/example-plugin/example-plugin.php',
      sourceLine: 26,
      rowsAffected: 0,
      rowsExamined: 0,
      rowsSent: 1,
      killed: false,
      bytesSent: 63,
      queryString: 'SELECT SLEEP ( ? ) WHERE ? = ? OR ? = ?;',
    },
    {
      uuid: '46906d5d-7096-4a65-951a-6925c1164a1f',
      createdAt: '2024-06-30T16:25:12.619362Z',
      queryTime: 5000320,
      lockTime: 0,
      requestUrl:
        'sqmsite.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
      assetType: 'theme',
      assetName: 'Twenty Twenty Four',
      sourceFile: 'wp-content/plugins/example-plugin/example-plugin.php',
      sourceLine: 26,
      rowsAffected: 0,
      rowsExamined: 1,
      rowsSent: 1,
      killed: false,
      bytesSent: 63,
      queryString: 'SELECT SLEEP ( ? ) WHERE ? = ? OR ? = ?;',
    },
    {
      uuid: '88886d5d-7096-4a65-951a-6925c1164a1f',
      createdAt: '2024-04-02T16:25:12.619362Z',
      queryTime: 4000320,
      lockTime: 0,
      requestUrl:
        'mysite3.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
      assetType: 'other',
      assetName: 'My Other Plugin',
      sourceFile: 'wp-content/plugins/other-plugin.php',
      sourceLine: 12,
      rowsAffected: 0,
      rowsExamined: 1,
      rowsSent: 1,
      killed: false,
      bytesSent: 63,
      queryString: 'SELECT EXAMPLEOTHER ( ? ) WHERE OTHER = ? OR ? = ?;',
    },
    {
      uuid: 'a1b2c3d4-e5f6-7g8h-9i0j-123456789abc',
      createdAt: '2024-04-03T10:15:30.123456Z',
      queryTime: 5000450,
      lockTime: 0,
      requestUrl:
        'example1.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
      assetType: 'plugin',
      assetName: 'Yoast SEO',
      sourceFile: 'wp-content/plugins/custom-plugin.php',
      sourceLine: 15,
      rowsAffected: 0,
      rowsExamined: 2,
      rowsSent: 2,
      killed: false,
      bytesSent: 120,
      queryString: 'SELECT PLUGINEXAMPLE ( ? ) WHERE PLUGIN = ? OR ? = ?;',
    },
    {
      uuid: 'b2c3d4e5-f6g7-h8i9-j0k1-234567890def',
      createdAt: '2024-04-04T11:20:35.654321Z',
      queryTime: 6000560,
      lockTime: 0,
      requestUrl:
        'example2.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
      assetType: 'theme',
      assetName: 'Ecommerce Theme',
      sourceFile: 'wp-content/themes/custom-theme/functions.php',
      sourceLine: 22,
      rowsAffected: 1,
      rowsExamined: 3,
      rowsSent: 1,
      killed: false,
      bytesSent: 150,
      queryString: 'SELECT THEMEEXAMPLE ( ? ) WHERE THEME = ? AND ? = ?;',
    },
    {
      uuid: 'c3d4e5f6-g7h8-i9j0-k1l2-345678901ghi',
      createdAt: '2024-04-05T12:25:40.987654Z',
      queryTime: 7000670,
      lockTime: 0,
      requestUrl:
        'example3.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
      assetType: 'other',
      assetName: 'External Service',
      sourceFile: 'wp-content/plugins/external-service/integration.php',
      sourceLine: 29,
      rowsAffected: 2,
      rowsExamined: 4,
      rowsSent: 2,
      killed: false,
      bytesSent: 200,
      queryString: 'SELECT SERVICEEXAMPLE ( ? ) WHERE SERVICE = ? OR ? = ?;',
    },
    {
      uuid: 'd4e5f6g7-h8i9-j0k1-l2m3-456789012jkl',
      createdAt: '2024-04-06T13:30:45.321098Z',
      queryTime: 8000780,
      lockTime: 0,
      requestUrl:
        'example4.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
      assetType: 'plugin',
      assetName: 'Contact Form 7',
      sourceFile: 'wp-content/plugins/seo-plugin/optimizer.php',
      sourceLine: 33,
      rowsAffected: 0,
      rowsExamined: 5,
      rowsSent: 0,
      killed: false,
      bytesSent: 250,
      queryString: 'SELECT SEOEXAMPLE ( ? ) WHERE SEO = ? AND ? = ?;',
    },
    {
      uuid: 'e5f6g7h8-i9j0-k1l2-m3n4-567890123klm',
      createdAt: '2024-04-07T14:35:50.654987Z',
      queryTime: 9000890,
      lockTime: 0,
      requestUrl:
        'example5.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
      assetType: 'theme',
      assetName: 'Responsive Theme',
      sourceFile: 'wp-content/themes/responsive-theme/style.php',
      sourceLine: 40,
      rowsAffected: 1,
      rowsExamined: 6,
      rowsSent: 1,
      killed: false,
      bytesSent: 300,
      queryString: 'SELECT THEMESTYLE ( ? ) WHERE STYLE = ? OR ? = ?;',
    },
    {
      uuid: 'f6g7h8i9-j0k1-l2m3-n4o5-678901234mno',
      createdAt: '2024-04-08T15:40:55.123789Z',
      queryTime: 10000100,
      lockTime: 0,
      requestUrl:
        'example6.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
      assetType: 'other',
      assetName: '3rd Party API',
      sourceFile: 'wp-content/plugins/third-party-api/connector.php',
      sourceLine: 47,
      rowsAffected: 2,
      rowsExamined: 7,
      rowsSent: 2,
      killed: false,
      bytesSent: 350,
      queryString: 'SELECT APIEXAMPLE ( ? ) WHERE API = ? AND ? = ?;',
    },
  ],
  nextPageToken: 'eyJ0eXAdsfsdfsd',
};

export const templateSQMAggBySourceResponse: SlowQueryAggregationBySources = {
  results: [
    {
      type: 'plugin',
      count: '400',
      duration: '106792694',
    },
    {
      type: 'theme',
      count: '100',
      duration: '56792694',
    },
    {
      type: 'other',
      count: '50',
      duration: '10679269',
    },
  ],
};

export const templateSQMAggRecommendationsResponse: SlowQueryRecommendations = {
  totalOccurrences: 100,
  recommendations: [
    {
      uuid: '1033ff2a-20be-4587-a880-92d0d4624774',
      assetName: 'Jetpack - WP Security',
      readableName: 'Jetpack',
      assetType: 'plugin',
      install: 'smoketest',
      type: 'DEPRECATED_FUNCTION',
      subtype: 'SQL_CALC_FOUND_ROWS',
      metadata: { occurrences: 17 },
      createdAt: '2024-01-10T16:25:18.886987Z',
    },
    {
      uuid: 'b12c34d5-67e8-90fa-bcde-1234567890ab',
      assetName: 'Twenty Twenty Five Theme',
      readableName: 'Twenty Twenty Five',
      assetType: 'theme',
      install: 'themeinstall',
      type: 'QUERY_RESOURCE',
      subtype: 'TOTAL_TIME',
      metadata: { occurrences: 15 },
      createdAt: '2024-08-15T12:30:45.123456Z',
    },
    {
      uuid: 'c23d45e6-78f9-01ab-cdef-2345678901bc',
      assetName: 'Twenty Twenty Six Theme',
      readableName: 'Twenty Twenty Six',
      assetType: 'theme',
      install: 'themeinstall2',
      type: 'QUERY_RESOURCE',
      subtype: 'TOTAL_TIME',
      metadata: { occurrences: 42 },
      createdAt: '2024-09-10T09:45:30.654321Z',
    },
    {
      uuid: 'd34e56f7-89a0-12bc-def0-3456789012cd',
      assetName: 'Twenty Twenty Seven Theme',
      readableName: 'Twenty Twenty Seven',
      assetType: 'theme',
      install: 'themeinstall3',
      type: 'QUERY_RESOURCE',
      subtype: 'TOTAL_TIME',
      metadata: { occurrences: 8 },
      createdAt: '2024-10-05T14:20:15.987654Z',
    },
    {
      uuid: 'b1e3d9a8-3c9f-4f1e-9e2b-8f6e9f61b635',
      assetName: 'WooCommerce',
      readableName: 'WooCommerce',
      assetType: 'plugin',
      install: 'woosite',
      type: 'QUERY_RESOURCE',
      subtype: 'ROWS_EXAMINED',
      metadata: { occurrences: 37 },
      createdAt: '2024-07-23T16:25:18.886987Z',
    },
    {
      uuid: 'a2f4e1b9-4d5e-4a2f-8b3c-9d7e8f62c746',
      assetName: 'Elementor',
      readableName: 'Elementor',
      assetType: 'plugin',
      install: 'elementorsite',
      type: 'QUERY_RESOURCE',
      subtype: 'ROWS_EXAMINED',
      metadata: { occurrences: 45 },
      createdAt: '2024-08-12T10:15:30.123456Z',
    },
    {
      uuid: 'b3g5f2c0-5e6f-5b3d-9c4d-0e8f9g73d857',
      assetName: 'Yoast SEO',
      readableName: 'Yoast SEO',
      assetType: 'plugin',
      install: 'yoastsite',
      type: 'QUERY_RESOURCE',
      subtype: 'ROWS_EXAMINED',
      metadata: { occurrences: 52 },
      createdAt: '2024-09-18T14:45:20.654321Z',
    },
    {
      uuid: 'c4h6g3d1-6f7g-6c4e-0d5e-1f9g0h84e968',
      assetName: 'Jetpack',
      readableName: 'Jetpack',
      assetType: 'plugin',
      install: 'jetpacksite',
      type: 'QUERY_RESOURCE',
      subtype: 'ROWS_EXAMINED',
      metadata: { occurrences: 29 },
      createdAt: '2024-10-25T18:30:45.987654Z',
    },
    {
      uuid: '9a76d980-8997-4dca-9270-6d741999c7c8',
      assetName: 'wordpress-seo',
      readableName: 'Yoast SEO',
      assetType: 'plugin',
      install: 'verifiedmarket',
      type: 'DEPRECATED_FUNCTION',
      subtype: 'SQL_CALC_FOUND_ROWS',
      metadata: { occurrences: 37 },
      createdAt: '2024-02-20T03:00:37.214260Z',
    },
    {
      uuid: '9a76d980-8997-4dca-9270-6d741999c7c8',
      assetName: 'vmr-report-search-fillter',
      readableName: 'WooCommerce',
      assetType: 'plugin',
      install: 'yoast-site',
      type: 'DATABASE_PERFORMANCE',
      subtype: 'HPOS_OPPORTUNITY',
      metadata: { occurrences: 35 },
      createdAt: '2024-11-11T03:00:37.214260Z',
    },
    {
      uuid: '9a76d980-8997-4dca-9270-6d741999c7c8',
      assetName: 'vmr-report-search-fillter',
      readableName: 'Slider Revolution',
      assetType: 'plugin',
      install: 'yoast-site',
      type: 'DATABASE_PERFORMANCE',
      subtype: 'MISSING_INDEXES',
      metadata: { occurrences: 35 },
      createdAt: '2024-12-12T03:00:37.214260Z',
    },
  ],
};
