import {
  Subscription,
  SubscriptionPlansV2,
} from 'commonsDir/types/BillingMediator';
import { Cart, CartAddon } from '../types/types';

// Already owned addons at the current plan pricing
const getCurrentAddons = (
  subscription: Subscription,
  subscriptionPlan: SubscriptionPlansV2
): CartAddon[] => {
  const currentAddons = subscription.addons;

  return currentAddons.map(addon => {
    const subscriptionPlanAddon = subscriptionPlan.addons.find(
      planAddon =>
        planAddon.base_sku === addon.base_sku &&
        !planAddon.product.includes('-bundled')
    );
    const price = subscriptionPlanAddon.prices.find(planPrice => {
      return planPrice.quantity === addon.quantity;
    });

    return {
      product: addon.product,
      name: addon.name,
      description: addon.description,
      quantity: addon.quantity,
      base_sku: addon.base_sku,
      price: price.price,
      prices: subscriptionPlanAddon.prices,
      maximum_quantity: subscriptionPlanAddon.maximum_quantity,
    };
  });
};

// Addons in cart using the existing plan pricing, or new plan pricing if present
const getNewAddons = (
  cartState: Cart,
  currentPlan: SubscriptionPlansV2,
  newPlan: SubscriptionPlansV2,
  subscription: Subscription
): CartAddon[] => {
  const selectedAddons = cartState.selected_addons;
  const subscriptionPlanAddons = newPlan ? newPlan.addons : currentPlan.addons;

  return selectedAddons.map(addon => {
    const subscriptionPlanAddon = subscriptionPlanAddons.find(
      planAddon =>
        planAddon.base_sku === addon.base_sku &&
        !planAddon.product.includes('-bundled')
    );

    const price = subscriptionPlanAddon.prices.find(planPrice => {
      return planPrice.quantity === addon.quantity;
    });

    const amount_owned = subscription.addons.find(currentAddon => {
      return currentAddon.base_sku === addon.base_sku;
    })?.quantity;

    return {
      product: subscriptionPlanAddon.product,
      amount_owned,
      base_sku: addon.base_sku,
      description: subscriptionPlanAddon.description,
      maximum_quantity: subscriptionPlanAddon.maximum_quantity,
      name: subscriptionPlanAddon.name,
      price: price.price,
      prices: subscriptionPlanAddon.prices,
      quantity: addon.quantity,
    };
  });
};

// Sum of addon prices
const getAddonsTotal = (currentAddons: CartAddon[]): number => {
  return currentAddons.reduce((total, addon) => total + Number(addon.price), 0);
};

const getIsNewPlanPremium = (newPlan: SubscriptionPlansV2): boolean => {
  if (!newPlan) {
    return false;
  }

  return newPlan?.plan_lines?.some(
    (line: { product: string }) => line.product === 'dedicated-environment'
  );
};

const getRecurrenceText = (
  newSubscriptionPlan: SubscriptionPlansV2,
  subscriptionPlan: SubscriptionPlansV2
) => {
  const recurrenceShort = newSubscriptionPlan
    ? recurrenceTextShort[newSubscriptionPlan.recurrence]
    : recurrenceTextShort[subscriptionPlan.recurrence];
  const recurrenceLong = newSubscriptionPlan
    ? recurrenceTextLong[newSubscriptionPlan.recurrence]
    : recurrenceTextLong[subscriptionPlan.recurrence];

  return { recurrenceShort, recurrenceLong };
};

const localeArray: { [key: string]: string } = {
  USD: 'en-US',
  GBP: 'en-GB',
  EUR: 'en-IE',
  CAD: 'en-CA',
  AUD: 'en-AU',
};

const recurrenceTextShort: { [key: string]: string } = {
  MONTHLY: ' /mo',
  ANNUALLY: ' /yr',
  QUARTERLY: ' /qtr',
  SEMI_ANNUALLY: ' /6 months',
};

const recurrenceTextLong: { [key: string]: string } = {
  MONTHLY: ' / month',
  ANNUALLY: ' / year',
  QUARTERLY: ' / quarter',
  SEMI_ANNUALLY: ' / 6 months',
};

export {
  getCurrentAddons,
  getAddonsTotal,
  getIsNewPlanPremium,
  getNewAddons,
  getRecurrenceText,
  localeArray,
};
