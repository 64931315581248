import * as React from 'react';
import { headless_path } from 'railsRoutes';

import { HeadlessIcon } from '@wpengine/unicorn/icons';
import SideNavLink from '../components/SideNavLink/SideNavLink';

export type HeadlessAccount = 'none' | 'headlessWordPress';

interface HeadlessProps {
  account: HeadlessAccount;
}

const Headless: React.FC<HeadlessProps> = ({ account }) => {
  if (account === 'none') {
    return null;
  }

  return (
    <SideNavLink
      icon={<HeadlessIcon />}
      label="Headless Platform"
      href={headless_path()}
    />
  );
};

export default Headless;
