import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const SiteMonitoring: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="SiteMonitoring logo" viewBox="0 0 64 64" {...props}>
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_793_805)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 27V43C15 43.5523 15.4477 44 16 44H38C38.5523 44 39 44.4477 39 45C39 45.5523 38.5523 46 38 46H16C14.3431 46 13 44.6569 13 43V21C13 19.3431 14.3431 18 16 18H46C47.6568 18 49 19.3431 49 21L49 32C49 32.5523 48.5523 33 48 33C47.4477 33 47 32.5523 47 32V27H15ZM17.9674 23C17.9674 22.4477 18.4151 22 18.9674 22H20.9674C21.5197 22 21.9674 22.4477 21.9674 23C21.9674 23.5523 21.5197 24 20.9674 24H18.9674C18.4151 24 17.9674 23.5523 17.9674 23ZM24 23C24 22.4477 24.4477 22 25 22H27C27.5523 22 28 22.4477 28 23C28 23.5523 27.5523 24 27 24H25C24.4477 24 24 23.5523 24 23ZM37 22C36.4477 22 36 22.4477 36 23C36 23.5523 36.4477 24 37 24H43C43.5523 24 44 23.5523 44 23C44 22.4477 43.5523 22 43 22H37Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 31C18 30.4477 18.4477 30 19 30H32C32.5523 30 33 30.4477 33 31C33 31.5523 32.5523 32 32 32H19C18.4477 32 18 31.5523 18 31Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 35C18 34.4477 18.4477 34 19 34H32C32.5523 34 33 34.4477 33 35C33 35.5523 32.5523 36 32 36H19C18.4477 36 18 35.5523 18 35Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 39C18 38.4477 18.4477 38 19 38H32C32.5523 38 33 38.4477 33 39C33 39.5523 32.5523 40 32 40H19C18.4477 40 18 39.5523 18 39Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.4873 47.935C41.2116 45.2444 39.8305 42.6812 39.0054 37.3161C38.963 37.0403 39.1753 36.7911 39.4585 36.7731C41.7399 36.6288 43.2102 36.1344 45.4178 34.6517C45.7692 34.4156 46.2308 34.4156 46.5822 34.6517C48.7898 36.1344 50.2601 36.6288 52.5415 36.7731C52.8247 36.7911 53.037 37.0403 52.9946 37.3161C52.1695 42.6812 50.7884 45.2444 46.5127 47.935C46.1999 48.1319 45.8001 48.1319 45.4873 47.935ZM46.75 39.25C46.75 38.8358 46.4142 38.5 46 38.5C45.5858 38.5 45.25 38.8358 45.25 39.25V43.25C45.25 43.6642 45.5858 44 46 44C46.4142 44 46.75 43.6642 46.75 43.25V39.25Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_793_805"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default SiteMonitoring;
