import { DomainStatus } from 'componentsDir/DomainNetworks/types';

const mockDomainStatusesData: { [key: string]: DomainStatus[] } = {
  'lilbanx.com': [
    {
      name: 'lilbanx.com',
      install_name: 'fpctestaccount',
      network_configuration: 'Unknown',
      atlas: false,
      legacy: false,
      legacy_cdn: false,
      an: false,
      ges: false,
      errors: {},
      result: 'Unknown',
    },
  ],
  'nodatasite.com': [],
};

export default mockDomainStatusesData;
