import { rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';
import notificationsResponse from 'componentsDir/shared/repositories/NotifyHubClient/mocks/data/notifications';
import messages from 'componentsDir/shared/repositories/NotifyHubClient/mocks/data/messages';
import { mockedIntegrationList } from 'componentsDir/shared/repositories/NotifyHubClient/mocks/data/integrations';
import {
  slackPolicyResponse,
  listSlackPoliciesResponse,
  listEmailPoliciesResponse,
  emailPolicyResponse,
} from 'componentsDir/shared/repositories/NotifyHubClient/mocks/data/policies';
import { slackChannelList } from 'componentsDir/shared/repositories/NotifyHubClient/mocks/data/channels';

const handlers = [
  rest.get('*/v1alpha1/notifications', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(notificationsResponse));
  }),

  rest.get(`*/v1alpha1/notifications:status*`, (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({
        enabled: true,
        latestUnreadCount: notificationsResponse.notifications.filter(
          n => !n.read
        ).length,
      })
    );
  }),

  rest.post('*/notifications/mark', (req, res, ctx) => {
    return customResponse(ctx.status(200));
  }),

  rest.get('*/v1alpha1/messages', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(messages));
  }),

  rest.post('*/slackIntegrations*', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({
        name: 'random_integration_id',
        accountId: 'random_account_id',
        code: (req.body as NotifyHubSlackIntegration).code,
        redirectUri: (req.body as NotifyHubSlackIntegration).redirectUri,
      })
    );
  }),

  rest.get('*/slackIntegrations', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(mockedIntegrationList));
  }),

  rest.get('*/availableChannels*', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(slackChannelList));
  }),

  rest.get('*/slackPolicies*', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(listSlackPoliciesResponse));
  }),

  rest.post('*/slackPolicies*', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(slackPolicyResponse));
  }),

  rest.delete('*/slackPolicies*', (req, res, ctx) => {
    return customResponse(ctx.status(200));
  }),

  rest.delete(
    '*/accounts/random_account_id/slackIntegrations/random_integration_id',
    (req, res, ctx) => {
      return customResponse(ctx.status(200));
    }
  ),

  rest.get('*/emailPolicies*', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(listEmailPoliciesResponse));
  }),

  rest.post('*/emailPolicies*', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(emailPolicyResponse));
  }),

  rest.delete('*/emailPolicies*', (req, res, ctx) => {
    return customResponse(ctx.status(200));
  }),

  rest.get(
    '*/accounts/random_account_id/slackIntegrations/random_integration_id/availableChannels*',
    (req, res, ctx) => {
      return customResponse(
        ctx.status(200),
        ctx.json({
          channels: [
            {
              name:
                'accounts/random_account_id/slackIntegrations/random_integration_id/slackChannels/random_channel',
              channel: 'random_channel',
            },
          ],
        })
      );
    }
  ),
];

export default handlers;
