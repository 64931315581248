import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const BellNotificationIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="bell-notification-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C9.61028 2 7.77972 2.56559 6.57406 3.87238C5.38394 5.16233 4.99579 6.97 4.99579 9V11C4.99579 12.509 3.86855 13.8048 2.70333 14.7107C1.39717 15.7262 1.99445 18 3.80006 18H20.1999C22.0056 18 22.6028 15.7262 21.2967 14.7107C20.1315 13.8048 19.0042 12.509 19.0042 11V9C19.0042 6.97 18.6161 5.16233 17.4259 3.87238C16.2203 2.56559 14.3897 2 12 2ZM6.99699 9C6.99699 7.164 7.35929 5.97168 8.0453 5.22813C8.71577 4.50142 9.88701 4 12 4C14.113 4 15.2842 4.50142 15.9547 5.22813C16.6407 5.97168 17.003 7.164 17.003 9V11C17.003 13.264 18.4907 14.9701 19.7107 16H4.28927C5.50934 14.9701 6.99699 13.264 6.99699 11V9Z"
        />
        <path d="M9.55262 19.1678C9.09301 18.8616 8.47217 18.9859 8.16595 19.4455C7.85972 19.9051 7.98407 20.526 8.44368 20.8322C9.57492 21.5859 10.7652 22 12 22C13.2347 22 14.425 21.5859 15.5562 20.8322C16.0158 20.526 16.1402 19.9051 15.834 19.4455C15.5277 18.9859 14.9069 18.8616 14.4473 19.1678C13.5773 19.7474 12.7664 20 12 20C11.2335 20 10.4226 19.7474 9.55262 19.1678Z" />
      </SvgTemplate>
    );
  }
);
export default BellNotificationIcon;
