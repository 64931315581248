import * as React from 'react';
import GlyphTemplate, {
  accentColors,
  baseColors,
  GlyphTemplateProps,
} from '../GlyphTemplate';

const Update: React.FC<GlyphTemplateProps> = ({
  type = 'TealBlue',
  size = '64',
  ...props
}) => {
  const accent = accentColors[type];
  const base = baseColors[type];
  if (size === '64' || size === 'large') {
    return (
      <GlyphTemplate
        name="Update glyph"
        viewBox="0 0 64 64"
        fill="none"
        size={size}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32 43C38.0751 43 43 38.0751 43 32C43 25.9249 38.0751 21 32 21C25.9249 21 21 25.9249 21 32C21 38.0751 25.9249 43 32 43ZM32 45C39.1797 45 45 39.1797 45 32C45 24.8203 39.1797 19 32 19C24.8203 19 19 24.8203 19 32C19 39.1797 24.8203 45 32 45Z"
          fill={accent}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.0845 46.9092C42.4798 50.3687 37.5591 52.4999 32.1286 52.4999C21.4849 52.4999 12.805 44.3143 12.1543 34.0063L13.6022 35.4355C14.0936 35.9204 14.885 35.9153 15.37 35.4239C15.8549 34.9326 15.8498 34.1412 15.3584 33.6562L11.7433 30.0879C11.2565 29.6074 10.4739 29.6074 9.98707 30.0879L6.3719 33.6562C5.88057 34.1412 5.87541 34.9326 6.36037 35.4239C6.84533 35.9153 7.63677 35.9204 8.1281 35.4355L9.64603 33.9372C10.2648 45.6862 20.1083 54.9999 32.1286 54.9999C38.2254 54.9999 43.7607 52.6044 47.8156 48.713C48.3137 48.235 48.33 47.4437 47.8519 46.9456C47.3739 46.4475 46.5826 46.4312 46.0845 46.9092Z"
          fill={accent}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.8675 45.8981C48.2961 45.5123 48.1483 44.7363 48.5373 44.165C48.9741 43.5236 49.3753 42.8519 49.7376 42.1516C50.055 41.538 50.8102 41.2953 51.4243 41.6096C52.0383 41.9239 52.2788 42.6761 51.9614 43.2897C51.5526 44.0799 51.0996 44.8382 50.6066 45.5623C50.2175 46.1336 49.4389 46.284 48.8675 45.8981Z"
          fill={accent}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9153 17.0907C21.5201 13.6313 26.4408 11.5 31.8712 11.5C42.5149 11.5 51.1948 19.6857 51.8455 29.9936L50.3976 28.5645C49.9063 28.0795 49.1148 28.0847 48.6299 28.576C48.1449 29.0673 48.1501 29.8588 48.6414 30.3437L52.2566 33.912C52.7434 34.3925 53.526 34.3925 54.0128 33.912L57.6279 30.3437C58.1193 29.8588 58.1244 29.0673 57.6395 28.576C57.1545 28.0847 56.3631 28.0795 55.8718 28.5645L54.3538 30.0627C53.7351 18.3138 43.8915 9 31.8712 9C25.7745 9 20.2392 11.3956 16.1843 15.287C15.6862 15.765 15.6699 16.5563 16.1479 17.0543C16.6259 17.5524 17.4172 17.5687 17.9153 17.0907Z"
          fill={base}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1323 18.1018C15.7037 18.4876 15.8516 19.2636 15.4625 19.835C15.0258 20.4764 14.6245 21.1481 14.2623 21.8483C13.9448 22.462 13.1897 22.7046 12.5756 22.3904C11.9615 22.0761 11.721 21.3239 12.0385 20.7102C12.4473 19.92 12.9002 19.1618 13.3933 18.4377C13.7823 17.8663 14.5609 17.716 15.1323 18.1018Z"
          fill={base}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.8839 27.1161C39.372 27.6043 39.372 28.3957 38.8839 28.8839L30.8839 36.8839C30.3957 37.372 29.6043 37.372 29.1161 36.8839L25.1161 32.8839C24.628 32.3957 24.628 31.6043 25.1161 31.1161C25.6043 30.628 26.3957 30.628 26.8839 31.1161L30 34.2322L37.1161 27.1161C37.6043 26.628 38.3957 26.628 38.8839 27.1161Z"
          fill={base}
        />
      </GlyphTemplate>
    );
  }

  return (
    <GlyphTemplate
      name="Update glyph"
      viewBox="0 0 48 48"
      fill="none"
      size={size}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.3128 20.2222C29.7033 20.6127 29.7033 21.2458 29.3128 21.6364L23.1714 27.7778C22.7808 28.1683 22.1477 28.1683 21.7572 27.7778L18.6864 24.7071C18.2959 24.3165 18.2959 23.6834 18.6864 23.2929C19.077 22.9023 19.7101 22.9023 20.1007 23.2929L22.4643 25.6565L27.8986 20.2222C28.2891 19.8316 28.9223 19.8316 29.3128 20.2222Z"
        fill={base}
      />
      <path
        d="M23.9012 8.30298C19.7432 8.30298 15.9756 9.93483 13.2156 12.5836C12.8171 12.966 12.1841 12.953 11.8017 12.5545C11.4192 12.156 11.4323 11.523 11.8307 11.1406C14.9509 8.14627 19.2101 6.30298 23.9012 6.30298C33.1195 6.30298 40.6747 13.4221 41.196 22.421L42.2975 21.3338C42.6906 20.9458 43.3237 20.9499 43.7117 21.343C44.0997 21.7361 44.0955 22.3692 43.7025 22.7572L40.9272 25.4965C40.5377 25.8809 39.9117 25.8809 39.5222 25.4965L36.7469 22.7572C36.3539 22.3692 36.3498 21.7361 36.7377 21.343C37.1257 20.9499 37.7588 20.9458 38.1519 21.3338L39.1876 22.3561C38.6368 14.5145 32.0146 8.30298 23.9012 8.30298Z"
        fill={base}
      />
      <path
        d="M11.3378 14.6837C11.6491 14.2266 11.5308 13.6059 11.0737 13.2972C10.6165 12.9885 9.99364 13.1088 9.6824 13.5659C9.30299 14.1231 8.95444 14.7066 8.63986 15.3147C8.38591 15.8056 8.57829 16.4073 9.06956 16.6588C9.56082 16.9102 10.1649 16.716 10.4189 16.2251C10.6962 15.689 11.0035 15.1748 11.3378 14.6837Z"
        fill={base}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9998 32.4799C28.6831 32.4799 32.4796 28.6833 32.4796 24.0001C32.4796 19.3168 28.6831 15.5203 23.9998 15.5203C19.3166 15.5203 15.52 19.3168 15.52 24.0001C15.52 28.6833 19.3166 32.4799 23.9998 32.4799ZM23.9998 33.9799C29.5115 33.9799 33.9796 29.5118 33.9796 24.0001C33.9796 18.4884 29.5115 14.0203 23.9998 14.0203C18.4881 14.0203 14.02 18.4884 14.02 24.0001C14.02 29.5118 18.4881 33.9799 23.9998 33.9799Z"
        fill={accent}
      />
      <path
        d="M24.0988 39.6971C28.2568 39.6971 32.0244 38.0652 34.7844 35.4165C35.1829 35.0341 35.8159 35.0471 36.1983 35.4456C36.5807 35.844 36.5677 36.4771 36.1692 36.8595C33.0491 39.8538 28.7899 41.6971 24.0988 41.6971C14.8805 41.6971 7.32527 34.5779 6.80399 25.579L5.70248 26.6663C5.30942 27.0543 4.67626 27.0501 4.28829 26.6571C3.90033 26.264 3.90446 25.6308 4.29752 25.2429L7.0728 22.5036C7.46224 22.1192 8.08832 22.1192 8.47776 22.5036L11.253 25.2429C11.6461 25.6308 11.6502 26.264 11.2623 26.6571C10.8743 27.0501 10.2411 27.0543 9.84809 26.6663L8.81235 25.644C9.36317 33.4856 15.9854 39.6971 24.0988 39.6971Z"
        fill={accent}
      />
      <path
        d="M36.6622 33.3163C36.3509 33.7734 36.4692 34.3942 36.9263 34.7029C37.3835 35.0115 38.0064 34.8912 38.3176 34.4342C38.697 33.877 39.0456 33.2935 39.3601 32.6854C39.6141 32.1945 39.4217 31.5927 38.9304 31.3413C38.4392 31.0899 37.835 31.284 37.5811 31.7749C37.3038 32.311 36.9965 32.8253 36.6622 33.3163Z"
        fill={accent}
      />
    </GlyphTemplate>
  );
};

export default Update;
