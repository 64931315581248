import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const CopyIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    <SvgTemplate name="copy-icon" ref={ref} {...props}>
      <path d="M5 2C3.34315 2 2 3.34315 2 5V16C2 16.5523 2.44772 17 3 17C3.55228 17 4 16.5523 4 16V5C4 4.44772 4.44772 4 5 4H16C16.5523 4 17 3.55228 17 3C17 2.44772 16.5523 2 16 2H5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6C7.34315 6 6 7.34315 6 9V19C6 20.6569 7.34315 22 9 22H19C20.6569 22 22 20.6569 22 19V9C22 7.34315 20.6569 6 19 6H9ZM8 9C8 8.44772 8.44772 8 9 8H19C19.5523 8 20 8.44772 20 9V19C20 19.5523 19.5523 20 19 20H9C8.44772 20 8 19.5523 8 19V9Z"
      />
    </SvgTemplate>
  );
});
export default CopyIcon;
