import * as React from 'react';
import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const { children, ...other } = props;
    return (
      <MuiIconButton ref={ref} {...other}>
        {children}
      </MuiIconButton>
    );
  }
);

export default IconButton;
