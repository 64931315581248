import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const StepperFourFilledIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="stepper-four-filled-icon" ref={ref} {...props}>
        <path
          clipRule="evenodd"
          d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM14.5 6.5C14.5 6.08825 14.2476 5.71859 13.8641 5.56866C13.4807 5.41872 13.0445 5.51917 12.7652 5.82172L6.7652 12.3217C6.49579 12.6136 6.42465 13.0372 6.58395 13.4011C6.74326 13.7649 7.1028 14 7.5 14H12.5V16.5C12.5 17.0523 12.9477 17.5 13.5 17.5C14.0523 17.5 14.5 17.0523 14.5 16.5V14C15.0523 14 15.5 13.5523 15.5 13C15.5 12.4477 15.0523 12 14.5 12V6.5ZM12.5 12V9.05765L9.78399 12H12.5Z"
        />
      </SvgTemplate>
    );
  }
);
export default StepperFourFilledIcon;
