import ProvisionStatesEnum from 'componentsDir/products/smart_search/@types/provisionStates';
import { BillingIteration } from 'componentsDir/sites/components/EnvironmentGraphs/types';

export const mockAddonEnablements = {
  globalEdgeSecurity: { account: true, quantity: 3, used: 2 },
  pageSpeedBoost: { account: false, quantity: 100, used: 2 },
  monitoringSite: { account: false, quantity: 5, used: 5 },
  smartPluginManager: { account: false, quantity: 44, used: 0 },
  smartSearch: { account: true, quantity: 1, used: 0 },
  atlasSearch: { account: true, quantity: 1, used: 0 },
};

const mockEnvData: EnvData = {
  environments: [
    {
      id: 1,
      bandwidth: 22255164161,
      billableVisits: 1000,
      environmentType: 'PRODUCTION',
      installName: 'erinspizzaco',
      siteName: 'erinspizzaco',
      storage: 22255164161,
      storageDbSize: 0,
      storageFileSize: 0,
      transferable: false,
      visits: 1,
    },
    {
      id: 2,
      bandwidth: 1225564461,
      billableVisits: 10000,
      environmentType: 'STAGING',
      installName: 'bensbeerco',
      siteName: 'bensbeerco',
      storage: 1225564461,
      storageDbSize: 0,
      storageFileSize: 0,
      transferable: false,
      visits: 0,
    },
    {
      id: 3,
      bandwidth: 12255664161,
      billableVisits: 10,
      environmentType: 'PRODUCTION',
      installName: 'izaschickencoop',
      siteName: 'izaschickencoop',
      storage: 12255664161,
      storageDbSize: 0,
      storageFileSize: 0,
      transferable: true,
      visits: 0,
    },
    {
      id: 4,
      bandwidth: 2325564161,
      billableVisits: 1,
      environmentType: 'DEVELOPMENT',
      installName: 'bobsburgers',
      siteName: 'bobsburgers',
      storage: 2325564161,
      storageDbSize: 2695168,
      storageFileSize: 119861248,
      transferable: false,
      visits: 0,
    },
  ] as EnvironmentRow[],
  totals: {
    bandwidthUsed: 713000000000,
    billableVisitsUsed: 400000,
    storageUsed: 209681923241,
  } as Totals,
};

const emptyMockEnvData = {
  environments: [] as EnvironmentRow[],
  totals: { bandwidthUsed: 0, storageUsed: 0, billableVisitsUsed: 0 } as Totals,
};

const mockResponse = {
  environmentName: 'aaaspookyenv',
  metrics: [
    {
      date: '2024-02-04',
      visitCount: '1',
      networkOriginBytes: '0',
      storageFileBytes: '184451072',
      storageDatabaseBytes: '2048000',
      billableVisits: '1',
      requestOriginCount: '1440',
      storageUpdateTime: '2024-02-04T00:16:23.085516Z',
    },
    {
      date: '2024-02-03',
      visitCount: '1',
      networkOriginBytes: '0',
      billableVisits: '1',
      requestOriginCount: '1440',
    },
    {
      date: '2024-02-02',
      visitCount: '1',
      networkOriginBytes: '0',
      billableVisits: '1',
      requestOriginCount: '1440',
    },
    {
      date: '2024-02-01',
      visitCount: '1',
      networkOriginBytes: '0',
      storageFileBytes: '184451072',
      storageDatabaseBytes: '2048000',
      billableVisits: '1',
      requestOriginCount: '1440',
      storageUpdateTime: '2024-02-01T21:12:29.191830Z',
    },
  ],
  metricsRollup: {
    visitCount: {
      average: '0',
      sum: '4',
    },
    networkOriginBytes: {
      average: '0',
      sum: '0',
    },
    storageFileBytes: {
      latest: {
        date: '2024-02-04',
        value: '184451072',
      },
    },
    storageDatabaseBytes: {
      latest: {
        date: '2024-02-04',
        value: '2048000',
      },
    },
    billableVisits: {
      average: '0',
      sum: '4',
    },
    requestOriginCount: {
      average: '198',
      sum: '5760',
    },
    networkCdnBytes: {
      average: '0',
      sum: '0',
    },
  },
};

const mockUsageProps: UsageContext = {
  accountId: 12345,
  accountName: 'Mock Account Name',
  accountUuid: 'b42bc763-68c6-4d4c-abd8-09e51587bfc3',
  accountWpeId: 54321,
  userEmail: 'test@wpengine.com',
  plan: 'Startup',
  omobonoRecommendation: {
    dismissPath: '',
    hide: false,
    lookbackPeriods: 4,
    recommendedPlan: 'Professional',
    recommendedPlanId: 'managed-hosting-professional',
    savingsPerMonth: '$425',
  },
  overview: {
    bandwidthBreakdown: {
      production: 690000000000,
      staging: 13000000000,
      development: 10000000000,
      transferable: 0,
      nonTransferable: 713000000000,
      originBandwidth: 677000000000,
      cdnBandwidth: 13000000000,
    },
    billableVisitsBreakdown: {
      production: 1500,
      staging: 1000,
      development: 500,
      transferable: 100,
      nonTransferable: 400,
    },
    billingCycles: ['01-May-2023', '31-May-2023'],
    sitesAvailable: 5,
    sitesUsed: 3,
    transferableSites: 0,
    installsByEnvironment: { production: 3, staging: 0, development: 0 },
    maxBandwidth: 900,
    maxBillableVisits: 700000,
    maxStorage: 450,
    nonBillableVisits: 100,
    bandwidthUsed: 713000000000,
    usedBillableVisits: 400000,
    usedStorage: 209681923241,
    storageBreakdown: {
      file: 69893974413,
      database: 139787948828,
      envBreakdown: {
        production: 69893974413,
        staging: 59893974413,
        development: 79893974413,
        transferable: 114840961621,
        nonTransferable: 94840961621,
      },
    },
    billingAccess: true,
    availableAddons: { sites: true, localStorage: false },
    shared: true,
    ubs: false,
  },
  selectedCycle: 'current' as BillingIteration,
  storageDate: null,
  billingCycles: {
    current: {
      billingCycleStart: '2023-12-01',
      billingCycleEnd: '2023-12-31',
    },
    last: {
      billingCycleStart: '2023-11-01',
      billingCycleEnd: '2023-11-30',
    },
    previous: {
      billingCycleStart: null,
      billingCycleEnd: null,
    },
  },
  dropdownBillingCycles: {
    current: '01-Dec-2023 to 31-Dec-2023',
    last: '01-Nov-2023 to 30-Nov-2023',
    previous: null,
    custom: 'Custom Date Range',
  },
  hasBandwidthTotalAccess: true,
  hasIncreasedBandwidthLimit: false,
  showBandwidthCalculationBanner: false,
  sortDirection: 'asc',
  setSortDirection: () => null,
  sortedBy: 'storage',
  setSortedBy: () => {},
  page: 0,
  setPage: () => {},
  rowsPerPage: 10,
  setRowsPerPage: () => {},
  searchValue: '',
  setSearchValue: () => {},
  displayType: 'number',
  setDisplayType: () => {},
  filterValues: [],
  setFilterValues: () => {},
};

const mockChartData = [
  {
    name: 'Apr 2023',
    production: 0,
    staging: 800,
    development: 500,
  },
  {
    name: 'May 2023',
    production: 0.0001,
    staging: 300,
    development: 2000.2,
  },
  {
    name: 'Jun 2023',
    production: 0.01,
    staging: 1500,
    development: 500,
  },
  {
    name: 'Jul 2023',
    production: 0.009,
    staging: 800,
    development: 100,
  },
  {
    name: 'Aug 2023',
    production: 0.12,
    staging: 100,
    development: 500,
  },
];

const mockDonutChart = [
  {
    name: 'Production',
    value: 400,
  },
  {
    name: 'Development',
    value: 200,
  },
  {
    name: 'Staging',
    value: 100,
  },
];

const mockTrendsData: TrendsItems = {
  bandwidth: [
    {
      name: 'August 01, 2023',
      value: 3588096,
      production: 3588096,
      staging: 0,
      development: 0,
      transferable: 0,
      nonTransferable: 3588096,
    },
  ],
  billable_visits: [
    {
      name: 'August 01, 2023',
      value: 9,
      production: 9,
      staging: 0,
      development: 0,
      transferable: 0,
      nonTransferable: 9,
    },
  ],
  storage: [
    {
      name: 'August 06, 2023',
      value: 14845186048,
      production: 14602403840,
      staging: 160051200,
      development: 82731008,
      transferable: 1122168832,
      nonTransferable: 13723017216,
      fileStorage: 14511206400,
      databaseStorage: 333979648,
    },
  ],
};

const mockTotals: Totals = {
  bandwidthUsed: 713000000000,
  billableVisitsUsed: 400000,
  storageUsed: 209681923241,
};

const mockSelectedDateRange: SelectedDateRange = {
  formattedDateRange: '1-Mar-2024 to 31-Mar-2024',
  storageDate: '31-Mar-2024',
};

const mockProvisions = (accountName: string): SmartSearchProvisions => ({
  provisions: [
    {
      name: `accounts/${accountName}/provisions/2a36a63a-954a-4e84-8de1-3191ee691ff7`,
      state: ProvisionStatesEnum.Successful,
      type: 'TYPE_ATLAS_SEARCH',
      atlassearch: {
        config: {
          environment: 'smartsearcher',
        },
        data: {
          searchUrl:
            'https://smartsearcher-91ff7-atlassearch-ayythkhbaa-uc.a.run.app/graphql',
          accessToken: 'c50a6db3-de3f-447c-9ee4-d353632d9b28',
        },
      },
    },
  ],
  nextPageToken: '',
});

export {
  emptyMockEnvData,
  mockChartData,
  mockDonutChart,
  mockEnvData,
  mockProvisions,
  mockSelectedDateRange,
  mockTrendsData,
  mockTotals,
  mockUsageProps,
  mockResponse,
};
