import { PlanSubscription } from 'componentsDir/addons/types';
import mockStartupAnnual from './mockStartupAnnual';

// Use the same mock data as mockStartupAnnual, just change the monthly specific data.
const mockStartupMonthly: PlanSubscription = {
  ...mockStartupAnnual,
  current_subscription_price: 30,
  plan_base_price: 30,
  subscription_price: 30,
  subscription_recurrence: 'MONTHLY',
  period_end: '2023-12-05T10:34:45.000+00:00',
  plan_addons: [
    {
      id: 'sites',
      name: 'Additional sites',
      limit: 25,
      qty_included: 1,
      additional_qty: 1,
      subscription_qty: 0,
      price: 20,
      legacy_entitlement: false,
    },
    {
      id: 'genesis-pro',
      name: 'Genesis Pro',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 30,
      legacy_entitlement: false,
    },
    {
      id: 'global-edge-security',
      name: 'Global Edge Security',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 30.0,
      legacy_entitlement: false,
    },
    {
      id: 'local-storage-small',
      name: 'Local Storage Small',
      limit: 80,
      qty_included: 0,
      additional_qty: 20,
      subscription_qty: 0,
      price: 10.0,
      legacy_entitlement: false,
    },
    {
      id: 'smart-plugin-manager',
      name: 'Smart Plugin Manager',
      limit: 1000,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 10.0,
      legacy_entitlement: false,
      dropdown_value: '1 environment license',
    },
    {
      id: 'smart-plugin-manager-5-pack',
      name: 'Smart Plugin Manager licenses (5)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 5,
      subscription_qty: 0,
      price: 30.0,
      legacy_entitlement: false,
      dropdown_value: '5 environment licenses',
    },
    {
      id: 'smart-plugin-manager-15-pack',
      name: 'Smart Plugin Manager licenses (15)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 15,
      subscription_qty: 0,
      price: 75.0,
      legacy_entitlement: false,
      dropdown_value: '15 environment licenses',
    },
    {
      id: 'smart-plugin-manager-25-pack',
      name: 'Smart Plugin Manager licenses (25)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 25,
      subscription_qty: 0,
      price: 100.0,
      legacy_entitlement: false,
      dropdown_value: '25 environment licenses',
    },
    {
      id: 'smart-plugin-manager-50-pack',
      name: 'Smart Plugin Manager licenses (50)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 50,
      subscription_qty: 0,
      price: 150.0,
      legacy_entitlement: false,
      dropdown_value: '50 environment licenses',
    },
    {
      id: 'smart-plugin-manager-100-pack',
      name: 'Smart Plugin Manager licenses (100)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 100,
      subscription_qty: 0,
      price: 250.0,
      legacy_entitlement: false,
      dropdown_value: '100 environment licenses',
    },
    {
      id: 'smart-plugin-manager-150-pack',
      name: 'Smart Plugin Manager licenses (150)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 150,
      subscription_qty: 0,
      price: 300.0,
      legacy_entitlement: false,
      dropdown_value: '150 environment licenses',
    },
    {
      id: 'monitoring-site',
      name: 'Site Monitoring',
      limit: 1000,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 5.0,
      legacy_entitlement: false,
      dropdown_value: '1 monitoring license',
    },

    {
      id: 'monitoring-site-05',
      name: 'Site Monitoring licenses (5)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 5,
      subscription_qty: 0,
      price: 15.0,
      legacy_entitlement: false,
      dropdown_value: '5 monitoring licenses',
    },
    {
      id: 'monitoring-site-15',
      name: 'Site Monitoring licenses (15)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 15,
      subscription_qty: 0,
      price: 20.0,
      legacy_entitlement: false,
      dropdown_value: '15 monitoring licenses',
    },
    {
      id: 'monitoring-site-25',
      name: 'Site Monitoring licenses (25)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 25,
      subscription_qty: 0,
      price: 25.0,
      legacy_entitlement: false,
      dropdown_value: '25 monitoring licenses',
    },
    {
      id: 'multisite',
      name: 'WordPress multisite',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 60.0,
      legacy_entitlement: false,
    },
  ],
};

export default mockStartupMonthly;
