import * as React from 'react';
import Divider from '@mui/material/Divider';
import { MenuItem as MuiMenuItem } from '@mui/material';
import { MenuItemProps } from '@mui/material/MenuItem';
import { gray300 } from 'design-tokens';

const MenuItem: React.FC<{
  component?: React.ElementType;
  href?: string;
  destructive?: boolean;
} & MenuItemProps> = ({ children, destructive, divider, ...props }) => {
  return (
    <>
      <MuiMenuItem className={`${destructive ? 'destructive' : ''}`} {...props}>
        {children}
      </MuiMenuItem>
      {divider ? (
        <Divider
          sx={{ background: gray300, '&.MuiDivider-root': { margin: 0 } }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MenuItem;
