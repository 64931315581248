export const INSTALL_OPERATION_STATUS_TEXT = {
  backup: {
    success: 'New backup successfully created.',
    error:
      'An error occurred creating a backup. Retry or contact support if the issue persists.',
    in_progress: 'Backup currently in progress...',
  },
};

export const MSW_PARAMS = {
  STATUS: 'status',
  STATUS_TYPE: {
    ACTIVE_COMPLETED: 'activeCompleted',
    ACTIVE_ERROR: 'activeError',
    STALE_COMPLETED: 'staleCompleted',
    STALE_ERROR: 'staleError',
    SYSTEM_INITIATED: 'systemInitiated',
    IN_PROGRESS: 'inProgress',
  },
};

export const QUERY_KEYS = {
  GET_INSTALL_OPERATION_STATUS: 'get_install_operation_status',
};

export const SEGMENT = {
  ACTION: {
    CLOSE: 'Close Drawer',
    DISMISS: 'Dismiss Banner',
    REFRESH: 'Refresh',
    RETRY: 'Retry',
    VIEW_DETAILS: 'View Details',
  },
  LOCATION: {
    ERROR_BANNER: 'Error State Banner',
    DRAWER: 'Status Visibility Drawer',
    IN_PROGRESS_BANNER: 'In Progress Banner',
    SUCCESS_BANNER: 'Success Banner',
  },
  TYPE: {
    BANNER: 'Banner',
    DRAWER: 'Drawer',
  },
};

export const BACKUP_STATUS_TRACKER_STEPS = {
  DEFAULT: [
    {
      title: 'Backing up content',
      description: 'All files and database tables are backed up.',
    },
    {
      title: 'Backup complete',
      description: 'All content has been successfully backed up.',
    },
  ],
  ERROR: [
    {
      title: 'Backing up content',
      description: 'All files and database tables are backed up.',
    },
    {
      title: 'Backup error',
      description: 'An error occurred when completing the backup.',
    },
  ],
};
