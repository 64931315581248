import cacheErrorHandler from 'componentsDir/install/Cache/mocks/msw/cacheError/handler';
import objectCacheEnablementHandler from 'componentsDir/install/Cache/mocks/msw/objectCacheEnablement/handler';
import purgeCacheHandlers from 'componentsDir/install/Cache/mocks/msw/purgeCache/handler';
import whooCommFeatureUsageV2Handlers from './whooCommFeatureUsageV2/handler';
import whooCommFeatureUsageV3Handlers from './whooCommFeatureUsageV3/handler';
import psbIntegrationHandlers from './psbIntegration/handler';
import psbClearCacheHandlers from './psbClearCache/handler';
import allCachesClearedHandlers from './allCachesCleared/handler';
import p1aHandler from './p1a/handler';

const cacheDashboardHandlers = [
  ...allCachesClearedHandlers,
  ...cacheErrorHandler,
  ...objectCacheEnablementHandler,
  ...p1aHandler,
  ...psbClearCacheHandlers,
  ...psbIntegrationHandlers,
  ...purgeCacheHandlers,
  ...whooCommFeatureUsageV2Handlers,
  ...whooCommFeatureUsageV3Handlers,
];

export default cacheDashboardHandlers;
