import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const FilledQuestionIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="filled-question-icon" ref={ref} {...props}>
        <rect width="24" height="24" rx="12" />
        <path
          d="M12 8C11.4262 8 11 8.40402 11 9C11 9.55228 10.5523 10 10 10C9.44771 10 9 9.55228 9 9C9 7.2569 10.3647 6 12 6C13.6353 6 15 7.2569 15 9C15 9.68075 14.8256 10.2412 14.5245 10.7017C14.2345 11.1452 13.8638 11.4378 13.5767 11.6405C13.4315 11.743 13.3271 11.8121 13.2454 11.8661C13.1382 11.9371 13.0701 11.9822 13 12.0431V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V11.9412C11 11.2949 11.3313 10.8545 11.6427 10.5736C11.8295 10.4052 12.0999 10.2243 12.2832 10.1016C12.3398 10.0637 12.3884 10.0312 12.4233 10.0066C12.6362 9.85629 12.7655 9.7372 12.8505 9.60716C12.9244 9.49414 13 9.31925 13 9C13 8.40401 12.5738 8 12 8Z"
          fill="white"
        />
        <path
          d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
          fill="white"
        />
      </SvgTemplate>
    );
  }
);
export default FilledQuestionIcon;
