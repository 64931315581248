import * as React from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ButtonTypeMap as MuiButtonTypeMap } from '@mui/material/Button/Button';
import { Button as MuiButton } from '@mui/material';

interface ButtonTypeMap<P = {}, D extends React.ElementType = 'button'> {
  props: MuiButtonTypeMap<P, D>['props'] & {
    target?: string;
    rel?: string;
  };
  defaultComponent: D;
}

const Button: OverridableComponent<ButtonTypeMap> = React.forwardRef(
  ({ children, color = 'primary', variant = 'contained', ...other }, ref) => {
    return (
      <MuiButton color={color} ref={ref} variant={variant} {...other}>
        {children}
      </MuiButton>
    );
  }
);

export default Button;
