import * as React from 'react';
import { colorWhite, topNavHeight } from 'design-tokens';
import { Box, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { CloseIcon, SearchIcon } from '@wpengine/unicorn/icons';
import NavIconButton from '../NavIconButton';
import OmniSearch from './components/OmniSearch';

interface Props {
  sx?: SxProps;
}

const OmniSearchBase: React.FC<Props> = ({ sx }) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileSearchOpen, setMobileSearchOpen] = React.useState(false);

  return (
    <>
      {smallScreen && (
        <NavIconButton onClick={() => setMobileSearchOpen(true)}>
          <SearchIcon />
        </NavIconButton>
      )}
      {mobileSearchOpen && (
        <>
          <Box
            sx={{
              alignItems: 'center',
              background: colorWhite,
              display: 'flex',
              gap: 2,
              padding: 2,
              height: topNavHeight,
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              zIndex: 2,
            }}
          >
            <OmniSearch sx={sx} />
            <NavIconButton onClick={() => setMobileSearchOpen(false)}>
              <CloseIcon />
            </NavIconButton>
          </Box>
        </>
      )}
      {!smallScreen && <OmniSearch sx={sx} />}
    </>
  );
};

export default OmniSearchBase;
