export const QUERY_KEYS = {
  DELETE_SITES_TAGS: 'delete_sites_tags',
  GET_LIST_TAGGED_SITES_BULK: 'get_list_tagged_sites_bulk',
  GET_TAGGABLE_SITES: 'get_taggable_sites',
  CREATE_SITES_TAGS: 'create_sites_tags',
};

export const MSW_PARAMS = {
  ALPHABET_TAGS_AND_SITES: 'alphabet-tags-and-sites',
  DELETE_SITES_TAGS_SUCCESS: 'delete-sites-tags-success',
  NO_SITE_TAG: 'no-sites-tag',
  NOT_TAGGABLE: 'not-taggable',
  MORE_THAN_FIFTY_SITES: 'more-than-fifty-sites',
  RANDOMIZE_SITE_TAG_SITES_COUNT: 'randomize-site-tag-sites-count',
  SINGLE_SITE_TAG: 'single-sites-tag',
  SINGLE_SITE_TAG_SINGLE_SITE: 'single-sites-tag-single-site',
};

export const ENDPOINTS = {
  BULK_LIST_TAGGED_SITES: 'bulk_list_tagged_sites',
  DELETE_SITES_TAGS: 'delete_sites_tags',
  GET_TAGGABLE_SITES: 'get_taggable_sites',
  CREATE_SITES_TAGS: 'create_sites_tags',
};
