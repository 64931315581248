import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const ErrorIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="error-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C14.125 3 16.078 3.73647 17.6177 4.9681L4.9681 17.6177C3.73647 16.078 3 14.125 3 12ZM6.38231 19.0319C7.92199 20.2635 9.87499 21 12 21C16.9706 21 21 16.9706 21 12C21 9.87499 20.2635 7.92199 19.0319 6.38231L6.38231 19.0319Z"
        />
      </SvgTemplate>
    );
  }
);
export default ErrorIcon;
