import { rest } from 'msw';
import {
  customResponse,
  getQueryParam,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import mockPremiumV2Update from 'componentsDir/addons/mocks/data/mockPremiumV2Update';
import mockPremiumAnnualTerms from 'componentsDir/addons/mocks/data/mockPremiumAnnualTerms';
import mockPremiumAnnual from 'componentsDir/addons/mocks/data/mockPremiumAnnual';
import mockStartupAnnual from './data/mockStartupAnnual';
import mockStartupMonthly from './data/mockStartupMonthly';
import mockStartupToScaleUpgrade from './data/mockStartupToScaleUpgrade';
import mockStartupToAtlasUpgrade from './data/mockStartupToAtlasUpgrade';
import mockPremiumQuarter from './data/mockPremiumQuarter';
import mockPremiumMonthly from './data/mockPremiumMonthly';
import mockScaleToPremiumUpgrade from './data/mockScaleToPremiumUpgrade';
import {
  Recurrence,
  Terms,
  PlanSubscription,
  PlanSubscriptionPayload,
} from '../types';

// Use page url query params to respond with different mock data.
// Example usages:
// http://localhost:3000/modify_plan                                    |   Startup / Monthly (default)
// http://localhost:3000/modify_plan?plan=scale                         |   Upgrading from Startup to Scale (Annual).
// http://localhost:3000/modify_plan?terms=NET_30                       |   Sets terms on subscription. e.g ON_RECEIPT, NET15, NET30 etc.
// http://localhost:3000/modify_plan?premium=true                       |   Sets is_premium flag on subscription.
// http://localhost:3000/modify_plan?subscription_recurrence=ANNUALLY   |   Sets the recurrence e.g MONTHLY or ANNUALLY.
// http://localhost:3000/modify_plan?plan=scale&terms=NET_30            |   Combination of query params.
// http://localhost:3000/modify_plan?current_subscription_price=25      |   Set the current subscription plan price.

let originalSubscription: PlanSubscription;

const handlers = [
  // GET /modify_plan.json
  rest.get('*/modify_plan.json', async (req, res, ctx) => {
    // Get recurrence from the request url params (not page url params).
    const recurrence = req.url.searchParams.get('recurrence');
    let subscription: PlanSubscription =
      recurrence === 'ANNUALLY'
        ? { ...mockStartupAnnual }
        : { ...mockStartupMonthly };

    if (!originalSubscription) {
      originalSubscription = subscription;
    }

    // Get plan from page url params
    if (hasPageUrlQueryParam('plan', 'p0-v2')) {
      subscription = { ...mockPremiumAnnual };
    }

    if (
      hasPageUrlQueryParam('update', 'v2') &&
      hasPageUrlQueryParam('plan', 'p0-v2')
    ) {
      subscription = { ...mockPremiumV2Update };
    }

    if (
      hasPageUrlQueryParam('at', 'true') &&
      hasPageUrlQueryParam('plan', 'p0-v2')
    ) {
      subscription = { ...mockPremiumAnnualTerms };
    }

    if (hasPageUrlQueryParam('plan', 'scale')) {
      subscription = { ...mockStartupToScaleUpgrade };
    }

    if (hasPageUrlQueryParam('plan', 'p01')) {
      subscription = { ...mockPremiumQuarter };
    }

    if (hasPageUrlQueryParam('plan', 'p01-v2')) {
      subscription = { ...mockScaleToPremiumUpgrade };
    }

    if (hasPageUrlQueryParam('plan', 'p02')) {
      subscription = { ...mockPremiumMonthly };
    }

    if (hasPageUrlQueryParam('plan', 'headless-startup')) {
      subscription = { ...mockStartupToAtlasUpgrade };
    }

    if (hasPageUrlQueryParam('comped')) {
      subscription.subscription_plan_id = 'comped';
    }

    // Alter some subscription details if page url query params are used.
    subscription.is_premium = hasPageUrlQueryParam('premium');
    subscription.terms =
      (getQueryParam('terms') as Terms) || subscription.terms;
    subscription.current_subscription_price =
      Number(getQueryParam('current_subscription_price')) ||
      subscription.current_subscription_price;
    subscription.currency = getQueryParam('currency') || subscription.currency;

    // Get recurrence from the page url params (not request url params). This can be useful as
    // we change the UI on the fly without having to manually change the recurrence radio buttons.
    subscription.subscription_recurrence =
      getQueryParam('subscription_recurrence') && !recurrence
        ? (getQueryParam('subscription_recurrence') as Recurrence)
        : subscription.subscription_recurrence;

    return customResponse(ctx.status(200), ctx.json(subscription));
  }),

  // PATCH /modify_plan
  rest.patch<PlanSubscriptionPayload>('*/modify_plan', (req, res, ctx) => {
    // Check if add-ons have been selected by the user.
    const areAddonsSelected = req.body.subscription.addons.some(
      addon => addon.qty_selected > 0
    );

    // Check if the user has changed recurrence.
    let hasRecurrenceChanged = false;
    if (originalSubscription) {
      hasRecurrenceChanged =
        originalSubscription.subscription_recurrence !==
        req.body.subscription.recurrence;
    }

    // If nothing has changed but the user is trying to purchase, return an error.
    if (!areAddonsSelected && !hasRecurrenceChanged) {
      return customResponse(
        ctx.status(422),
        ctx.json({ error_msg: 'Your subscription was not changed.' })
      );
    }

    // Otherwise - return 200 OK.
    return customResponse(
      ctx.status(200),
      ctx.json({ redirect_path: 'http://localhost:3000/my_account' })
    );
  }),
];

export default handlers;
