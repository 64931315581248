import * as React from 'react';
import { gray400, gray900, borderRadiusSmall } from 'design-tokens';
import { debounce, isEmpty } from 'lodash';
import {
  ClickAwayListener,
  Paper,
  Popper,
  InputAdornment,
  SxProps,
} from '@mui/material';
import {
  Box,
  IconButton,
  TextField as MuiTextField,
} from '@wpengine/unicorn/components';
import { ClearTextIcon, SearchIcon } from '@wpengine/unicorn/icons';
import { useTopNavContext } from 'componentsDir/TopNav/contexts/TopNavProvider';
import { siteSearched } from 'analytics/segment';
import { extractDomain } from 'commonsDir/helpers/strings';
import TypeAheadResults from '../TypeAheadResults';
import SearchRepository from '../../repo/SearchRepository';
import useOmniSearch from '../../hooks/useOmniSearch';

interface Props {
  sx?: SxProps;
}

const OmniSearch: React.FC<Props> = ({ sx }) => {
  const { currentAccount } = useTopNavContext();
  // this needs to not be null so we can get rid of the warning
  const [query, setQuery] = React.useState('');
  const [resultsVisible, setResultsVisible] = React.useState(false);
  const [typeAheadResults, setTypeAheadResults] = React.useState([]);
  const [typeAheadTerm, setTypeAheadTerm] = React.useState('');
  const anchorRef = React.useRef<HTMLInputElement>(null);
  const debounceWaitTime = 250;
  useOmniSearch(resultsVisible);

  const repo = new SearchRepository('/omni_search/submit?search_term=');

  const valueChanged = (evt: React.ChangeEvent<any>): void => {
    setQuery(evt.target.value);
  };

  const onSubmitSearch = (searchQuery: string): void => {
    window.location.href = `/omni_search?q=${extractDomain(searchQuery)}`;
  };

  const deleteQuery = (): void => {
    setQuery('');
    setResultsVisible(false);
  };

  const debouncedQuery = React.useRef<(entry: string) => void>(
    debounce((entry: string): void => {
      repo.get(entry).then((results: SearchResult[]) => {
        setTypeAheadTerm(entry);
        setTypeAheadResults(results);
        setResultsVisible(!isEmpty(entry));
      });
    }, debounceWaitTime)
  );

  React.useEffect((): void => {
    if (!isEmpty(query) && query.toString().trim().length >= 3) {
      debouncedQuery.current(extractDomain(query));
    } else {
      setResultsVisible(false);
      setTypeAheadResults([]);
      setTypeAheadTerm('');
    }
  }, [query]);

  const onEnter = (evt: React.KeyboardEvent): void => {
    if (evt.key === 'Enter') {
      onSubmitSearch(query);
      siteSearched({
        search_term: query,
      });
    }
  };

  React.useEffect(() => {
    const onEscape = (evt: KeyboardEvent) => {
      if (evt.key === 'Escape') {
        setResultsVisible(false);
      }
    };

    document.addEventListener('keydown', onEscape);
    return () => {
      document.removeEventListener('keydown', onEscape);
    };
  }, []);

  const endAdornmentIcon = (): React.ReactFragment => {
    if (query.length > 0) {
      return (
        <IconButton
          onClick={deleteQuery}
          tabIndex={0}
          id="omnisearch-clear-search"
        >
          <ClearTextIcon aria-label="Clear search" />
        </IconButton>
      );
    }
    return null;
  };

  return (
    <ClickAwayListener onClickAway={() => setResultsVisible(false)}>
      <Box sx={{ flexBasis: '600px', ...sx }}>
        <MuiTextField
          ref={anchorRef}
          type="search"
          onChange={valueChanged}
          placeholder="Search sites"
          sx={{
            boxSizing: 'border-box',
            borderRadius: borderRadiusSmall,
            width: '100%',
            input: {
              '&::-webkit-search-cancel-button': {
                WebkitAppearance: 'none',
              },
            },
            '& .MuiInputBase-root': {
              border: `1px solid ${gray400}`,
            },
            '& ::placeholder': {
              opacity: 1,
              color: gray900,
            },
          }}
          value={query}
          InputProps={{
            sx: {
              width: '100%',
            },
            onKeyDown: onEnter,
            className: 'search-input',
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon htmlColor={gray900} sx={{ opacity: '0.6' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                {endAdornmentIcon()}
              </InputAdornment>
            ),
          }}
          onClick={(): void => {
            if (!resultsVisible && typeAheadResults?.length > 0) {
              setResultsVisible(true);
            }
          }}
        />
        <Popper
          open={resultsVisible}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          sx={{
            width: anchorRef.current && anchorRef.current.offsetWidth,
            zIndex: '5',
          }}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ]}
        >
          <Paper
            elevation={1}
            sx={{
              padding: '16px',
              border: `1px solid ${gray400}`,
            }}
          >
            <TypeAheadResults
              currentAccountName={currentAccount.name}
              query={typeAheadTerm}
              searchResultBody={typeAheadResults}
            />
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default OmniSearch;
