import { rest } from 'msw';
import { StatusCodes } from 'http-status-codes';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { account_install_collaborators_path } from 'railsRoutes';

const handlers = [
  rest.get('*/environments%2Fmyinstall/sftpUsers', (req, res, ctx) => {
    return res(
      ctx.status(StatusCodes.OK),
      ctx.json({
        users: [
          {
            name: 'myinstall-user1',
            path: '/path',
          },
          {
            name: 'myinstall-user2',
            path: '',
          },
        ],
      })
    );
  }),

  rest.get(
    account_install_collaborators_path(':accountUUID', ':installName'),
    (req, res, ctx) => {
      return customResponse(
        ctx.status(StatusCodes.OK),
        ctx.json([
          {
            id: 1,
            uuid: '550e8400-e29b-41d4-a716-446655440000',
            first_name: 'owner',
            last_name: 'testname',
            email:
              'ownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerowner@ownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerownerowner.com',
            role_name: 'Owner',
          },
          {
            id: 2,
            uuid: '550e8411-e29b-41d4-a716-446655440000',
            first_name: 'partial',
            last_name: 'testname',
            email: 'partial@email.com',
            role_name: 'Partial',
          },
        ])
      );
    }
  ),

  rest.post('*/environments%2Fmyinstall/sftpUsers', (req, res, ctx) => {
    return res(ctx.status(StatusCodes.CREATED));
  }),

  rest.patch(
    '*/environments%2Fmyinstall%2FsftpUsers%2Fmyuser',
    (req, res, ctx) => {
      return res(ctx.status(StatusCodes.NO_CONTENT));
    }
  ),

  rest.delete(
    '*/environments%2Fmyinstall%2FsftpUsers%2Fmyinstall-user1',
    (req, res, ctx) => {
      return res(ctx.status(StatusCodes.NO_CONTENT));
    }
  ),

  rest.post('/users/token', (req, res, ctx) => {
    return res(ctx.json('token'));
  }),
];

export default handlers;
