import { elevation1BoxShadow, gray700 } from 'design-tokens';
import { Components, Theme } from '@mui/material';

type colorType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';

const buttonTheme = (theme: Theme): Components['MuiButton'] => {
  const containedOverride = (color: colorType) => {
    return {
      boxShadow: elevation1BoxShadow,
      ':hover': {
        backgroundColor: theme.palette[color].dark,
        boxShadow: elevation1BoxShadow,
      },
      ':focus-visible': {
        outline: `2px solid ${theme.palette[color][90]}`,
        outlineOffset: '2px',
        boxShadow: elevation1BoxShadow,
      },
      ':active': {
        backgroundColor: theme.palette[color][90],
        boxShadow: elevation1BoxShadow,
      },
    };
  };

  const outlinedOverride = (color: colorType) => {
    return {
      borderColor: theme.palette[color].main,
      boxShadow: elevation1BoxShadow,
      ':hover': {
        borderColor: theme.palette[color].dark,
        color: theme.palette[color].dark,
        boxShadow: elevation1BoxShadow,
      },
      ':focus-visible': {
        outline: `2px solid ${theme.palette[color].main}`,
        outlineOffset: '2px',
        borderColor: theme.palette[color][50],
        transitionDuration: '0s', // Should this be something else?
        boxShadow: elevation1BoxShadow,
      },
      ':active': {
        borderColor: theme.palette[color][90],
        color: theme.palette[color][90],
        boxShadow: elevation1BoxShadow,
      },
    };
  };

  const textOverride = (color: colorType) => {
    return {
      ':hover': {
        backgroundColor: 'transparent',
        color: theme.palette[color].dark,
      },
      ':focus-visible': {
        outline: `2px solid ${theme.palette[color].main}`,
        outlineOffset: '2px',
      },
      ':active': {
        color: theme.palette[color][90],
      },
    };
  };

  return {
    defaultProps: {
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        borderRadius: '100px',
        padding: '16px 24px',
        '&.Mui-disabled': {
          boxShadow: 'none',
        },
      },
      containedPrimary: containedOverride('primary'),
      containedSecondary: containedOverride('secondary'),
      containedError: containedOverride('error'),
      containedWarning: containedOverride('warning'),
      containedInfo: containedOverride('info'),
      containedSuccess: containedOverride('success'),
      outlined: {
        padding: '14px 24px',
        borderWidth: '2px',
        '&.Mui-disabled': {
          borderWidth: '2px',
          borderColor: gray700,
        },
        ':hover': {
          backgroundColor: 'transparent',
          borderWidth: '2px',
        },
      },
      outlinedPrimary: outlinedOverride('primary'),
      outlinedSecondary: outlinedOverride('secondary'),
      outlinedError: outlinedOverride('error'),
      outlinedWarning: outlinedOverride('warning'),
      outlinedInfo: outlinedOverride('info'),
      outlinedSuccess: outlinedOverride('success'),
      sizeSmall: {
        fontSize: '0.875rem',
        padding: '8px 16px',
      },
      text: {
        borderRadius: '4px',
        padding: '0px',
        minWidth: 'auto',
      },
      textSizeSmall: {
        padding: '0px',
      },
      textPrimary: textOverride('primary'),
      textSecondary: textOverride('secondary'),
      textError: textOverride('error'),
      textWarning: textOverride('warning'),
      textInfo: textOverride('info'),
      textSuccess: textOverride('success'),
    },
  };
};

export default buttonTheme;
