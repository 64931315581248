interface ISideNavListeners {
  activeAccount: Array<(account: string) => void>;
  activeItem: Array<(title: string, path: string) => void>;
  expandedItem: Array<(title: string) => void>;
  collapsed: Array<(collapsed: boolean) => void>;
}

const navListeners: ISideNavListeners = {
  activeAccount: [],
  activeItem: [],
  collapsed: [],
  expandedItem: [],
};

export const addSideNavListener = (
  event: EventType,
  callback: (...args: any[]) => void
): void => {
  navListeners[event].push(callback);
};
export const removeSideNavListener = (
  event: EventType,
  callback: (...args: any[]) => void
): void => {
  navListeners[event] = (navListeners as any)[event].filter(
    (cb: any) => cb !== callback
  );
};

export const notifySideNavListeners = (
  event: EventType,
  ...args: any[]
): void => {
  navListeners[event].forEach((listener: any) => listener(...args));
};

type EventType = 'collapsed' | 'activeItem' | 'activeAccount' | 'expandedItem';

export default {
  addSideNavListener,
  notifySideNavListeners,
  removeSideNavListener,
};
