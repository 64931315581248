export const BANNERS = { TRANSITION_TIMEOUT: 100 };

export const BASE_USER_SETTINGS_KEY = 'all_sites_settings';

export const DATA_TEST_IDS = { ACCOUNT_PICKER: 'account-picker' };

export const MIN_SITES_TO_ENABLE_BACK_TO_TOP_BUTTON = 4;

export const ENDPOINTS = {
  ALL_SITES_WP_VERSION_BY_INSTALL: 'all_sites_wp_version_by_install',
  GET_INSTALL_AVATAR: 'install_avatar',
  GET_SITE: 'get_site',
  GET_ALL_SITES: 'get_all_sites',
  GET_ALL_SITES_CSV: 'all_sites_csv',
  GET_ALL_SITES_FILTERS: 'get_all_sites_filters',
  INSTALL_AVATAR: 'install_avatar',
  GET_INCIDENTS: 'incidents',
  PLATFORM_ONE_DELETE: '/v1/stores',
  SITE_DELETE: 'site_delete',
  SITE_EDIT_NAME: 'site_edit_name',
  SITE_EXISTS: 'site_exists',
  SITE_NOTES: 'site_notes',
  SITE_TAGS: 'site_tags',
  SITE_TAG_DELETE: 'site_tag_delete',
  USER_TAGS: 'user_tags',
  GET_ALL_SITES_BY_INSTALLS: 'get_all_sites_by_installs',
  CONVERT_TO_BILLABLE: 'site_convert_to_billable',
  UPGRADE_SANDBOX_SITE: 'upgrade_sandbox_site_to_billable',
};

export const FILTERS = {
  MIN_SITES_TO_ENABLE_FILTER: 2,
  localDefaultSettingsFilterKey: (uuid: string) => `allSitesPageFilter-${uuid}`,
  localNeedsAttentionFilterKey: (uuid: string) =>
    `allSitesPage_needsAttentionFilter-${uuid}`,
  DRAWER_WIDTH: 400,
  DRAWER_PADDING: 8,
};

export const MSW_PARAMS = {
  CSV_ERROR: 'csv-error',
  EMPTY_FILTERS: 'empty-filters',
  EMPTY_FILTER_RESULTS: 'empty-filter-results',
  EMPTY_SITES: 'empty-sites',
  EMPTY_SITES_WP_VERSIONS: 'empty-sites-wp-versions',
  EMPTY_WP_VERSIONS: 'empty-wp-versions',
  FILTER_ENABLED: 'filter-enabled',
  MULTI_PAGE: 'multi-page',
  MULTIPLE_SITES: 'multiple-sites',
  SDS_BANNERS_ENABLED: 'sds-banners-enabled',
  SINGLE_ACCOUNT: 'single-account',
  SINGLE_PARTIAL_WITH_BILLING_ACCOUNT: 'single-partial-with-billing-account',
  SINGLE_PARTIAL_WITHOUT_BILLING_ACCOUNT:
    'single-partial-without-billing-account',
  SINGLE_SITE: 'single-site',
  SITE_EXISTS: 'site-exists',
  SANDBOX_SITES: 'sandbox-sites',
  LESS_THAN_ELEVEN_ACCOUNT_FILTERS: 'less-than-eleven-account-filters',
  UNAUTHORIZED: 'unauthorized',
  UPGRADE_SANDBOX_ERROR: 'upgrade-sandbox-error',
};

export const QUERY_KEYS = {
  GET_INCIDENTS: 'get_incidents',
  GET_INCIDENT_STATS: 'get_incident_stats',
  CACHE_ENVIRONMENT_INCIDENT_MAPS: 'cache_environment_incident_maps',
  GET_FILTERS: 'get_filters',
  GET_CSV: 'get_csv',
  GET_INSTALL_AVATAR: 'install_avatar',
  GET_SITE: 'get_site',
  GET_SITE_TAGS: 'get-site-tags',
  GET_SPECIFIED_INSTALLS: 'get-specified-installs',
  GET_USER_TAGS: 'get-user-tags',
  LIST_SITES_BY_USER: 'list-sites-by-user',
  WP_VERSIONS: 'wp-versions',
  EDIT_SITE_NAME: 'edit-site-name',
  SITE_EXIST: 'site-exist',
  DELETE_SITE: 'delete-site',
  DELETE_ENVIRONMENT: 'delete-environment',
  CONVERT_TO_BILLABLE: 'convert-to-billable',
  UPGRADE_SANDBOX_SITE: 'upgrade-sandbox-site',
};

export const QUERY_PARAMS = {
  ACCOUNT_WPE_ID: 'account_wpe_id',
  ADD_SANDBOX_SITE_SUCCESS: 'add_sandbox_site_success',
  DELETE_SITE_SUCCESS: 'delete_site_success',
  EDIT_SITE_SUCCESS: 'edit_site_success',
  ENVIRONMENT_TYPE: 'environment_type',
  SITE_IDS: 'siteIds',
  TAG: 'tag',
};

export const TABS = {
  ALL_SITES: '0',
  NEEDS_ATTENTION: '1',
  NEEDS_UPDATE: '2',
};

export const NEGATIVE_ONE = -1;

export const REGEX = {
  URL: /(?<protocol>ftp|http|https):\/\/(\w+:?\w*@)?(?<domain>[a-zA-Z0-9-.]+)(\/?):?([0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?/,
  URL_PROTOCOL: /^(https?:\/\/)/,
};

export const MAX_ENVIRONMENTS = 3;
export const MAX_SANDBOX_ENVIRONMENTS = 1;

export const ADD_SITE_UNAUTHORIZED_MESSAGE =
  'Contact an account owner to add a site.';

export const SEGMENT = {
  NAVIGATION_ACTION: {
    OPEN: 'open',
    CLOSE: 'close',
    CHANGE: 'change',
    CLICK: 'click',
    SELECT: 'select',
    DESELECT: 'deselect',
  },
  NAVIGATION_TYPE: {
    BUTTON: 'button',
    TAB: 'tab',
    LINK: 'link',
    MENU_ITEM: 'menuitem',
    CHECKBOX: 'checkbox',
    TAG: 'tag',
  },
  VIEW_TYPE: {
    SINGLE_ACCOUNT_VIEW: 'Sites Single Account View',
    ALL_ACCOUNT_VIEW: 'Sites All Accounts View',
  },
  SECTION: {
    TOP_PANEL: 'Top Panel',
    TOOLBAR: 'Toolbar',
    GRID_VIEW_CARD: 'Grid View Card',
    LIST_VIEW_CARD: 'List View Card',
    FILTER_DRAWER: 'Filter Drawer',
    EMPTY_STATE: 'Empty State',
  },
};
