import { GetPluginIntegrationsResponse } from 'componentsDir/shared/repositories/PluginIntegrationApiClient/types';

const allIntegrations: GetPluginIntegrationsResponse = {
  integrations: [
    {
      installName: 'testsite100',
      lastErrorDetails: '',
      state: 'ACTIVE',
      name: 'integrations/testsite100',
      nitropack: {
        licensedUri: 'testsite100.wpengine.com',
        safeMode: 'DISABLED',
        setting: 'FOUR',
        envType: 'PRD',
      },
      type: 'PAGE_SPEED_BOOST',
      activationUuid: 'uuid-100',
      lastError: 'NO_ERROR',
      lastErrorPrettyMessage: '',
      lastUpdateTime: '2021-06-19T07:06:18.127Z',
    },
    {
      installName: 'testsite101',
      name: 'integrations/testsite101',
      lastErrorDetails: 'Incompatible plugin',
      state: 'FAILED_TO_ACTIVATE',
      nitropack: {
        licensedUri: 'testsite101.wpengine.com',
        safeMode: 'SAFE_MODE_UNSPECIFIED',
        setting: 'SETTING_UNSPECIFIED',
        envType: 'PRD',
      },
      type: 'PAGE_SPEED_BOOST',
      activationUuid: 'uuid-101',
      lastError: 'NO_ERROR',
      lastErrorPrettyMessage: '',
      lastUpdateTime: '2023-07-22T21:56:50.124Z',
    },
    {
      state: 'ACTIVATING',
      name: 'integrations/testsite102',
      installName: 'testsite102',
      lastErrorDetails: '',
      nitropack: {
        licensedUri: 'testsite102.wpengine.com',
        safeMode: 'SAFE_MODE_UNSPECIFIED',
        setting: 'SETTING_UNSPECIFIED',
        envType: 'PRD',
      },
      type: 'PAGE_SPEED_BOOST',
      activationUuid: 'uuid-102',
      lastError: 'NO_ERROR',
      lastErrorPrettyMessage: '',
      lastUpdateTime: '2020-05-04T07:02:43.825Z',
    },
    {
      state: 'ACTIVE',
      name: 'integrations/testsite1000',
      installName: 'testsite1000',
      lastErrorDetails: '',
      nitropack: {
        licensedUri: 'testsite1000.wpengine.com',
        safeMode: 'ENABLED',
        envType: 'PRD',
        setting: 'FOUR',
      },
      type: 'PAGE_SPEED_BOOST',
      activationUuid: 'uuid-1000',
      lastError: 'NO_ERROR',
      lastErrorPrettyMessage: '',
      lastUpdateTime: '2019-11-17T06:50:28.672Z',
    },
    {
      state: 'FAILED_TO_DEACTIVATE',
      installName: 'testsite1001',
      name: 'integrations/testsite10001',
      lastErrorDetails: '',
      nitropack: {
        licensedUri: 'testsite1001.wpengine.com',
        safeMode: 'DISABLED',
        envType: 'STG',
        setting: 'SETTING_UNSPECIFIED',
      },
      type: 'PAGE_SPEED_BOOST',
      activationUuid: 'uuid-104',
      lastError: 'NO_ERROR',
      lastErrorPrettyMessage: '',
      lastUpdateTime: '2021-11-10T09:58:49.704Z',
    },
    {
      installName: 'testsite1002',
      state: 'ACTIVE',
      name: 'integrations/testsite10002',
      lastErrorDetails: '',
      nitropack: {
        licensedUri: 'testsite1002.wpengine.com',
        safeMode: 'SAFE_MODE_UNSPECIFIED',
        envType: 'STG',
        setting: 'SETTING_UNSPECIFIED',
      },
      type: 'PAGE_SPEED_BOOST',
      activationUuid: 'uuid-104',
      lastError: 'NO_ERROR',
      lastErrorPrettyMessage: '',
      lastUpdateTime: '2024-01-02T01:22:44.517Z',
    },
    {
      state: 'ACTIVE',
      name: 'integrations/testsite1003',
      installName: 'testsite1003',
      lastErrorDetails: '',
      nitropack: {
        licensedUri: 'testsite1003.wpengine.com',
        safeMode: 'ENABLED',
        envType: 'PRD',
        setting: 'ONE',
      },
      type: 'PAGE_SPEED_BOOST',
      activationUuid: 'uuid-1003',
      lastError: 'NO_ERROR',
      lastErrorPrettyMessage: '',
      lastUpdateTime: '2024-01-02T01:22:44.517Z',
    },
  ],
  nextPageToken: null,
};

const emptyIntegrations: GetPluginIntegrationsResponse = {
  integrations: [],
  nextPageToken: null,
};

export { allIntegrations, emptyIntegrations };
