import * as React from 'react';
import { Divider, Stack } from '@mui/material';
import {
  Box,
  IconButton,
  Menu,
  Typography,
} from '@wpengine/unicorn/components';
import { AvatarIcon, CloseSmallIcon } from '@wpengine/unicorn/icons';
import { useTopNavContext } from 'componentsDir/TopNav/contexts/TopNavProvider';
import NavItems from './components/NavItems';
import NavIconButton from '../NavIconButton';
import TopNavDrawer from '../TopNavDrawer';

const ProfileDropdown: React.FC = () => {
  const { smallScreen } = useTopNavContext();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const menuButtonId = 'top-nav-profile-menu-button';
  const menuId = 'top-nav-profile-menu';

  return (
    <>
      <NavIconButton
        active={open}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-label="Open menu"
        id={menuButtonId}
        onClick={(): void => {
          setOpen(!open);
        }}
        ref={anchorRef}
        title="Profile Menu"
      >
        <AvatarIcon />
      </NavIconButton>
      {smallScreen ? (
        <TopNavDrawer open={open} onClose={handleClose}>
          <Stack
            alignItems="center"
            direction="row"
            flexWrap="wrap"
            gap={2}
            justifyContent="space-between"
            padding={theme => theme.spacing(0.5, 1.5, 0.5, 2.5)}
            width="100%"
          >
            <Typography variant="h4">Profile</Typography>
            <IconButton color="default" onClick={() => setOpen(false)}>
              <CloseSmallIcon />
            </IconButton>
          </Stack>
          <Divider />
          <Box sx={theme => ({ padding: theme.spacing(1.5) })}>
            <NavItems />
          </Box>
        </TopNavDrawer>
      ) : (
        <Menu
          id={menuId}
          anchorEl={anchorRef.current}
          MenuListProps={{
            'aria-labelledby': menuButtonId,
          }}
          open={open}
          onClose={handleClose}
          sx={theme => ({
            '.MuiPaper-root': {
              marginTop: theme.spacing(2),
              padding: theme.spacing(1.5),
              border: 'none',
            },
          })}
        >
          <NavItems />
        </Menu>
      )}
    </>
  );
};

export default ProfileDropdown;
