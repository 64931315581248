import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const StepperNineIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="stepper-nine-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 13.5C14 14.5774 13.6439 15.1702 13.2782 15.5019C12.886 15.8576 12.3858 16 11.9999 16C11.5339 16 10.9031 15.7839 10.4872 15.226C10.1572 14.7831 9.53068 14.6917 9.08785 15.0217C8.64502 15.3518 8.55359 15.9783 8.88362 16.4211C9.73536 17.5639 11.007 18 11.9999 18C12.8141 18 13.8139 17.716 14.6217 16.9834C15.456 16.2268 16 15.0697 16 13.5L15.9999 10C15.9999 7.83041 14.2161 6 12 6C9.78381 6 8 7.8304 8 10C8 12.1696 9.78385 14 12 14C12.7296 14 13.4123 13.8016 14 13.458L14 13.5ZM13.9999 10C13.9999 11.0792 13.0975 12 12 12C10.9024 12 10 11.0791 10 10C10 8.92087 10.9024 8 12 8C13.0975 8 13.9999 8.92088 13.9999 10Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
        />
      </SvgTemplate>
    );
  }
);
export default StepperNineIcon;
