import { IMockHeadlessEnv } from '../types';

const mockHeadlessEnvironments: IMockHeadlessEnv[] = [
  {
    domains: ['go.google.com', 'www.google.ca'],
    wpEnvironment: 'google',
  },
];

export default mockHeadlessEnvironments;
