import { rest } from 'msw';
import { customResponse, getQueryParam } from 'mocksDir/utils/browserUtils';
import mockFeatureFlags from '../data/mockFeatureFlags';

// Use page url query params to respond with different mock data.
// Example usages:
// http://localhost:3000/my_page?featureFlagsToEnable=all             |   Enable all flags.
// http://localhost:3000/my_page?featureFlagsToEnable=FLAG_1          |   Enable a single flag.
// http://localhost:3000/my_page?featureFlagsToEnable=FLAG_1,FLAG_2   |   Enable multiple flags.
const splitIOHandlers = [
  rest.get('*/splitChanges', async (req, res, ctx) => {
    const flagsToEnable = getQueryParam('featureFlagsToEnable');

    if (flagsToEnable) {
      return customResponse(
        ctx.status(201),
        ctx.json({
          splits: mockFeatureFlags(flagsToEnable),
        })
      );
    }

    // Disable feature flags by default.
    return customResponse(
      ctx.status(201),
      ctx.json({
        splits: [],
      })
    );
  }),
  rest.get('*/mySegments/:id', async (req, res, ctx) => {
    return customResponse(
      ctx.status(201),
      ctx.json({
        mySegments: [],
      })
    );
  }),
  rest.get('*/api/v2/auth', async (req, res, ctx) => {
    return customResponse(ctx.status(201), ctx.json({}));
  }),
  rest.post('*/api/testImpressions/bulk', async (req, res, ctx) => {
    return customResponse(ctx.status(201), ctx.json({}));
  }),
];
export default splitIOHandlers;
