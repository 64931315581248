import * as React from 'react';
import { AccountLookupType } from 'commonsDir/types/Accounts';
import SideNavToggle from '../SideNavToggle';
import SideNavAccountSwitcher from '../SideNavAccountSwitcher';
import SideNavChatPin from '../SideNavChatPin';
import SideNavListStyle from './SideNavListStyle';

interface SideNavListProps {
  allAccountsView: boolean;
  chatPinId: string;
  children: React.ReactNode;
  collapse: boolean;
  currentAccount: AccountLookupType;
  hasMultipleAccounts: boolean;
  setCollapse: (collapse: boolean) => void;
}

const SideNavList: React.FC<SideNavListProps> = ({
  allAccountsView,
  chatPinId,
  children,
  collapse,
  currentAccount,
  hasMultipleAccounts,
  setCollapse,
}) => {
  return (
    <SideNavListStyle>
      <SideNavAccountSwitcher
        activeAccount={currentAccount}
        allAccountsView={allAccountsView}
        multipleAccounts={hasMultipleAccounts}
      />
      {children}
      <SideNavChatPin
        chatPinId={chatPinId}
        accountUserId={currentAccount.accountUserId}
        disabled={allAccountsView}
      />
      <SideNavToggle
        collapse={collapse}
        onClick={() => {
          setCollapse(!collapse);
          localStorage.setItem('sideNavCollapsed', (!collapse).toString());
        }}
      />
    </SideNavListStyle>
  );
};
export default SideNavList;
