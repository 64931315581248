import { mockAccounts } from 'commonsDir/helpers/domTestHelpers';
import { getCurrentUserAccountsFromDOM } from 'commonsDir/helpers/lookupAccounts';

const generateRandomWpeId = () => {
  const currentUserAccounts = getCurrentUserAccountsFromDOM();
  const accounts = currentUserAccounts || mockAccounts;
  const randomIndex = Math.floor(Math.random() * accounts.length);

  return accounts[randomIndex]?.wpe_id;
};

export default generateRandomWpeId;
