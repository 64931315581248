import { QuickBuyAddon } from 'componentsDir/shared/QuickBuyModal/types';

export const mockQuickBuyAddons: QuickBuyAddon[] = [
  {
    dropdownLabel: '1 environment license',
    dropdownValue: 'smart-plugin-manager',
    esm: false,
    id: 'smart-plugin-manager',
    maxQty: 200,
    name: 'Smart Plugin Manager',
    price: 10,
    purchased: false,
    qtyAddonsOwned: 0,
    qtyPerUnit: 1,
    totalCurrentlyOwned: 0,
  },
  {
    dropdownLabel: '5 environment licenses',
    dropdownValue: 'smart-plugin-manager-5-pack',
    esm: false,
    id: 'smart-plugin-manager-5-pack',
    maxQty: 200,
    name: 'Smart Plugin Manager',
    price: 30,
    purchased: false,
    qtyAddonsOwned: 0,
    qtyPerUnit: 1,
    totalCurrentlyOwned: 0,
  },
  {
    dropdownLabel: '15 environment licenses',
    dropdownValue: 'smart-plugin-manager-15-pack',
    esm: false,
    id: 'smart-plugin-manager-15-pack',
    maxQty: 200,
    name: 'Smart Plugin Manager',
    price: 75,
    purchased: false,
    qtyAddonsOwned: 0,
    qtyPerUnit: 1,
    totalCurrentlyOwned: 0,
  },
  {
    dropdownLabel: '',
    dropdownValue: 'monitoring-site',
    esm: false,
    id: 'monitoring-site',
    maxQty: 25,
    name: 'Site Monitoring',
    price: 60,
    purchased: false,
    qtyAddonsOwned: 1,
    qtyPerUnit: 1,
    totalCurrentlyOwned: 0,
  },
  {
    dropdownLabel: '',
    dropdownValue: 'global-edge-security',
    esm: false,
    id: 'global-edge-security',
    maxQty: 1,
    name: 'Global Edge Security',
    price: 30,
    purchased: false,
    qtyAddonsOwned: 0,
    qtyPerUnit: 1,
    totalCurrentlyOwned: 0,
  },
  {
    dropdownLabel: '',
    dropdownValue: 'sites',
    esm: false,
    id: 'sites',
    maxQty: 10,
    name: 'Additional sites',
    price: 50,
    purchased: false,
    qtyAddonsOwned: 0,
    qtyPerUnit: 1,
    totalCurrentlyOwned: 1,
  },
  {
    dropdownLabel: '',
    dropdownValue: 'local-storage',
    esm: false,
    id: 'local-storage',
    maxQty: 100,
    name: 'Additional storage',
    price: 10,
    purchased: false,
    qtyAddonsOwned: 500,
    qtyPerUnit: 250,
    totalCurrentlyOwned: 3,
  },
];

export const mockQBBundledSPMAddons: QuickBuyAddon[] = [
  {
    dropdownLabel: '1 environment license',
    dropdownValue: 'smart-plugin-manager',
    esm: false,
    id: 'smart-plugin-manager',
    maxQty: 1000,
    name: 'Smart Plugin Manager',
    price: 10,
    purchased: false,
    qtyAddonsOwned: 0,
    qtyPerUnit: 1,
    totalCurrentlyOwned: 0,
  },
  {
    dropdownLabel: '5 environment licenses',
    dropdownValue: 'smart-plugin-manager-5-pack',
    esm: false,
    id: 'smart-plugin-manager-5-pack',
    maxQty: 1000,
    name: 'Smart Plugin Manager',
    price: 30,
    purchased: false,
    qtyAddonsOwned: 0,
    qtyPerUnit: 1,
    totalCurrentlyOwned: 0,
  },
  {
    dropdownLabel: '15 environment licenses',
    dropdownValue: 'smart-plugin-manager-15-pack',
    esm: false,
    id: 'smart-plugin-manager-15-pack',
    maxQty: 200,
    name: 'Smart Plugin Manager',
    price: 75,
    purchased: false,
    qtyAddonsOwned: 0,
    qtyPerUnit: 1,
    totalCurrentlyOwned: 0,
  },
];
