import * as React from 'react';
import * as PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { gray1200, gray400, gray600 } from 'design-tokens';
import { searchResultSelected } from 'analytics/segment';

const ResultLink = styled('a')(({ theme }) => ({
  color: gray1200,
  padding: '0.5em 0 0.5em 1em',
  textDecoration: 'none',
  '&:focus, &:hover': {
    backgroundColor: gray400,
    textDecoration: 'none',
  },
  '&:focus': {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: '1',
  },
  '&:active': {
    backgroundColor: gray600,
    outline: 0,
  },
}));

const EmphasisLink: React.FC<EmphasisLinkProps> = ({ url, emphasis, text }) => {
  const index: number = text.toUpperCase().indexOf(emphasis.toUpperCase());
  if (index === -1) {
    return null;
  }
  const splitText: string[] = [
    text.slice(0, index),
    text.slice(index, index + emphasis.length),
    text.slice(index + emphasis.length, text.length),
  ];
  return (
    <ResultLink
      href={url}
      onClick={(): void => {
        searchResultSelected({
          result_title: splitText[1],
          result_url: url,
          search_term: text,
        });
      }}
    >
      {splitText[0]}
      {splitText[1] ? <b>{splitText[1]}</b> : null}
      {splitText[2]}
    </ResultLink>
  );
};

declare interface EmphasisLinkProps {
  url: string;
  emphasis: string;
  text: string;
}

EmphasisLink.propTypes = {
  url: PropTypes.string.isRequired,
  emphasis: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default EmphasisLink;
