import { SiteMonitoringActivation } from 'componentsDir/shared/repositories/ActivationsPrivateAPIClient/types';
import normalizeUrl from './normalizeUrl';

const getMonitorActivationUrl = (
  activation: SiteMonitoringActivation
): string => {
  return normalizeUrl(
    activation.context?.installDomain,
    activation.context?.subpath
  );
};

export default getMonitorActivationUrl;
