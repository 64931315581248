import {
  Subscription,
  SubscriptionPlansV2,
} from 'commonsDir/types/BillingMediator';
import { renderPrice } from 'componentsDir/TopNav/components/Cart/helpers/cart';
import { useQuery } from 'react-query';
import CartClient from 'componentsDir/Cart/repos/CartClient';
import { Cart, CartSummaryContext } from '../types/types';
import {
  getAddonsTotal,
  getCurrentAddons,
  getIsNewPlanPremium,
  getNewAddons,
  getRecurrenceText,
} from '../helpers/summary';

const useCartSummaryContextValue = (
  subscription: Subscription,
  subscriptionPlan: SubscriptionPlansV2,
  newSubscriptionPlan: SubscriptionPlansV2,
  cartState: Cart
): CartSummaryContext => {
  const cartClient = new CartClient();
  const currentAddons = getCurrentAddons(subscription, subscriptionPlan);
  const currentAddonsTotal = getAddonsTotal(currentAddons);
  const newAddons = getNewAddons(
    cartState,
    subscriptionPlan,
    newSubscriptionPlan,
    subscription
  );
  const previousTotal = currentAddonsTotal + Number(subscriptionPlan.price);
  const newAddonsTotal = getAddonsTotal(newAddons);
  const isNewPlanPremium = getIsNewPlanPremium(newSubscriptionPlan);
  const cartTotal = newSubscriptionPlan
    ? newAddonsTotal + Number(newSubscriptionPlan.price)
    : newAddonsTotal;
  const formattedCartTotal = renderPrice(
    cartTotal.toString(),
    newSubscriptionPlan
      ? newSubscriptionPlan.currency
      : subscriptionPlan.currency
  );
  const newTotal = newAddonsTotal + Number(subscriptionPlan.price);
  const formattedNewTotal = renderPrice(
    newTotal.toString(),
    subscriptionPlan.currency
  );
  const newRecurrence = newSubscriptionPlan
    ? newSubscriptionPlan.recurrence
    : '';
  const { recurrenceLong, recurrenceShort } = getRecurrenceText(
    newSubscriptionPlan,
    subscriptionPlan
  );
  const itemCount = newSubscriptionPlan
    ? newAddons.length + 1
    : newAddons.length;

  const { data: sitesAndStorageUsed } = useQuery(
    'sitesAndStorageCartData',
    () => cartClient.getSiteAndStorageData()
  );

  const subscriptionRenewalTotal = (
    currAddonsTotal: number,
    currCartTotal: string
  ) => {
    const newCartTotal = currCartTotal.replace(/[^0-9.]/g, '');
    const parsedCartTotal = parseFloat(newCartTotal);
    return (currAddonsTotal + parsedCartTotal).toFixed(2);
  };

  const cartSummaryContextValue: CartSummaryContext = {
    cartTotal,
    currentPlan: subscriptionPlan,
    currentAddons,
    currentAddonsTotal,
    isNewPlanPremium,
    itemCount,
    formattedCartTotal,
    formattedNewTotal,
    previousRecurrence: subscriptionPlan.recurrence,
    previousTotal,
    newAddons,
    newAddonsTotal,
    newPlan: newSubscriptionPlan,
    newRecurrence,
    newTotal,
    recurrenceShort,
    recurrenceLong,
    sitesAndStorageUsed,
    subscription,
    subscriptionRenewalTotal,
  };

  return cartSummaryContextValue;
};

export default useCartSummaryContextValue;
