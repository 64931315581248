import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const ShoppingCartIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate
        name="shopping-cart-icon"
        ref={ref}
        viewBox="0 0 20 20"
        height="20"
        width="20"
        fill="none"
        {...props}
      >
        <path
          d="M6.66667 13.3333H12.7192C16.4592 13.3333 17.0275 10.9833 17.7175 7.55832C17.9167 6.56916 18.0158 6.07499 17.7767 5.74582C17.5375 5.41666 17.0792 5.41666 16.1617 5.41666H5"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M6.66668 13.3333L4.48251 2.92916C4.39238 2.56859 4.18432 2.2485 3.8914 2.01974C3.59848 1.79099 3.2375 1.66671 2.86584 1.66666H2.08334M7.40001 13.3333H7.05751C5.92084 13.3333 5.00001 14.2925 5.00001 15.4758C4.99901 15.5218 5.00709 15.5675 5.02376 15.6103C5.04044 15.6532 5.06539 15.6923 5.0972 15.7255C5.129 15.7587 5.16704 15.7853 5.20913 15.8038C5.25122 15.8223 5.29654 15.8323 5.34251 15.8333H14.5833"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M8.75 18.3333C9.44036 18.3333 10 17.7737 10 17.0833C10 16.393 9.44036 15.8333 8.75 15.8333C8.05964 15.8333 7.5 16.393 7.5 17.0833C7.5 17.7737 8.05964 18.3333 8.75 18.3333Z"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          d="M14.5833 18.3333C15.2737 18.3333 15.8333 17.7737 15.8333 17.0833C15.8333 16.393 15.2737 15.8333 14.5833 15.8333C13.893 15.8333 13.3333 16.393 13.3333 17.0833C13.3333 17.7737 13.893 18.3333 14.5833 18.3333Z"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </SvgTemplate>
    );
  }
);
export default ShoppingCartIcon;
