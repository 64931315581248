import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import * as responses from './mocksData';
import { MSW_PARAMS } from '../constants';

const longRunningOperationHandlers = [
  rest.get('*/installs/*/install_operation_statuses', (req, res, ctx) => {
    if (
      hasPageUrlQueryParam(
        MSW_PARAMS.STATUS,
        MSW_PARAMS.STATUS_TYPE.ACTIVE_COMPLETED
      )
    ) {
      return customResponse(
        ctx.status(200),
        ctx.json(responses.activeCompleted)
      );
    }

    if (
      hasPageUrlQueryParam(
        MSW_PARAMS.STATUS,
        MSW_PARAMS.STATUS_TYPE.STALE_COMPLETED
      )
    ) {
      return customResponse(
        ctx.status(200),
        ctx.json(responses.staleCompleted)
      );
    }

    if (
      hasPageUrlQueryParam(
        MSW_PARAMS.STATUS,
        MSW_PARAMS.STATUS_TYPE.ACTIVE_ERROR
      )
    ) {
      return customResponse(ctx.status(200), ctx.json(responses.activeError));
    }

    if (
      hasPageUrlQueryParam(
        MSW_PARAMS.STATUS,
        MSW_PARAMS.STATUS_TYPE.STALE_ERROR
      )
    ) {
      return customResponse(ctx.status(200), ctx.json(responses.staleError));
    }

    if (
      hasPageUrlQueryParam(
        MSW_PARAMS.STATUS,
        MSW_PARAMS.STATUS_TYPE.SYSTEM_INITIATED
      )
    ) {
      return customResponse(
        ctx.status(200),
        ctx.json(responses.systemInitiated)
      );
    }

    if (
      hasPageUrlQueryParam(
        MSW_PARAMS.STATUS,
        MSW_PARAMS.STATUS_TYPE.IN_PROGRESS
      )
    ) {
      return customResponse(ctx.status(200), ctx.json(responses.inProgress));
    }

    // Default to in progress if handler is invoked with no particular status specified
    return customResponse(ctx.status(200), ctx.json(responses.inProgress));
  }),
];

export default longRunningOperationHandlers;
