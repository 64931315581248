import {
  V1alpha1UsageMetricsRollup,
  V1alpha1DailyUsageMetrics,
} from 'platform_one_api_client';
import { Entitlement } from 'commonsDir/types/Entitlement';

export const mockP1aMetricsRollUpData: V1alpha1UsageMetricsRollup = {
  networkOriginBytes: {
    average: '2000',
    sum: '1000',
  },
  networkTotalBytes: {
    average: '3000',
    sum: '4000',
  },
  billableVisits: {
    average: '3',
    sum: '2000',
  },
  storageFileBytes: {
    latest: {
      date: '2023-10-11',
      value: '72806400',
    },
  },
  storageDatabaseBytes: {
    latest: {
      date: '2023-10-11',
      value: '1871872',
    },
  },
};

export const mockP1aMetricsData: Array<V1alpha1DailyUsageMetrics> = [
  {
    networkOriginBytes: '254976',
    networkTotalBytes: '75483',
    billableVisits: '10000',
    date: '2023-10-10',
  },
  {
    networkOriginBytes: '40720',
    networkTotalBytes: '999999',
    billableVisits: '100000',
    date: '2023-10-06',
  },
  {
    networkOriginBytes: '30720',
    networkTotalBytes: '543433',
    billableVisits: '105000',
    date: '2023-10-03',
  },
  {
    networkOriginBytes: '0.03923607',
    networkTotalBytes: '82311',
    billableVisits: '1000',
    date: '2023-10-01',
  },
];

export const mockSpmEntitlement: Entitlement = {
  uuid: '1996d0b5-69ab-47d8-84ac-da4fab30b86f',
  product: 'smart-plugin-manager',
  quantity: 350,
  created_on: '2019-10-10T19:01:44.854595Z',
  updated_on: '2019-10-10T19:01:44.854631Z',
  ended_on: null,
  has_ended: false,
  is_active: true,
  vendored_identifiers: [{ vendor: 'portal', id: 0, identifier: '' }],
};

export const mockSiteManagementEntitlement: Entitlement = {
  uuid: '1996d0b5-69ab-47d8-84ac-da4fab30b86f',
  product: 'site-management',
  quantity: 350,
  created_on: '2019-10-10T19:01:44.854595Z',
  updated_on: '2019-10-10T19:01:44.854631Z',
  ended_on: null,
  has_ended: false,
  is_active: true,
  vendored_identifiers: [{ vendor: 'portal', id: 0, identifier: '' }],
};
