import { EnvironmentsResponse } from './rawSpmEnvironments';

const autoupdaterActiveEnvironmentIds = [
  80,
  110,
  70,
  130,
  10,
  40,
  60,
  50,
  90,
  150,
  20,
  120,
  140,
  30,
];

const filterEnvironments = (
  environments: EnvironmentsResponse[],
  filter?: string
) => {
  // called from Site Overview view
  if (filter.includes('provider_metadata.install_name')) {
    const orClauses = filter.split(' OR ');
    const installNames = new Set(
      orClauses.map(orClause => {
        return orClause.split('= ')[1];
      })
    );
    return environments.filter(item =>
      installNames.has(item.provider_metadata.install_name)
    );
  }

  if (filter.includes('provider_metadata.account_id')) {
    const accountWpeId = filter.split('= ')[1];
    return environments.filter(
      environment => environment.provider_metadata.account_id === accountWpeId
    );
  }

  const [updateFilters, environmentTypeFilters] = filter?.split(' AND ') || [];

  if (updateFilters === 'autoupdater_active = true') {
    return environments.filter(environment =>
      autoupdaterActiveEnvironmentIds.includes(environment.id)
    );
  }

  const filteredEnvironments = updateFilters
    ? environments.filter(environment => {
        return updateFilters
          .split(' OR ')
          .some(updateFilter =>
            getUpdateFiltersConditionMap(updateFilter)(environment)
          );
      })
    : environments;

  return environmentTypeFilters
    ? filteredEnvironments.filter(environment => {
        return environmentTypeFilters
          .split(' OR ')
          .some(typeFilter =>
            getEnvironmentTypeCondition(typeFilter)(environment)
          );
      })
    : filteredEnvironments;
};

export default filterEnvironments;

const getUpdateFiltersConditionMap = (filter: string) => {
  switch (filter) {
    case 'outdated_plugins = true':
      return (environment: EnvironmentsResponse) =>
        !!environment.plugins.length;
    case 'outdated_themes = true':
      return (environment: EnvironmentsResponse) => !!environment.themes.length;
    case 'outdated_php = true':
      return (environment: EnvironmentsResponse) => !!environment.php;
    case 'outdated_core = true':
      return (environment: EnvironmentsResponse) => !!environment.core;
    case 'has_outdated_plugins_vulnerabilities = true':
      return (environment: EnvironmentsResponse) =>
        !!environment?.plugins?.filter(env => env.vulnerabilities.length > 0)
          .length;
    case 'has_outdated_themes_vulnerabilities = true':
      return (environment: EnvironmentsResponse) =>
        !!environment?.themes?.filter(env => env.vulnerabilities.length > 0)
          .length;
    case 'has_outdated_php_vulnerabilities = true':
      return (environment: EnvironmentsResponse) => !!environment?.php;
    case 'has_outdated_core_vulnerabilities = true':
      return (environment: EnvironmentsResponse) =>
        !!environment?.core?.vulnerabilities.length;
    default:
      return () => false;
  }
};

const getEnvironmentTypeCondition = (filter: string) => {
  switch (filter) {
    case 'provider_metadata.environment_type = "production"':
      return (environment: EnvironmentsResponse) =>
        environment.provider_metadata.environment_type === 'production';
    case 'provider_metadata.environment_type = "development"':
      return (environment: EnvironmentsResponse) =>
        environment.provider_metadata.environment_type === 'development';
    case 'provider_metadata.environment_type = "staging"':
      return (environment: EnvironmentsResponse) =>
        environment.provider_metadata.environment_type === 'staging';
    default:
      return () => false;
  }
};
