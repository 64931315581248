import * as React from 'react';
import ConditionalRollbarContext from 'componentsDir/shared/Rollbar/ConditionalRollbarContext';
import ErrorBoundary from 'componentsDir/shared/Rollbar/ErrorBoundary';
import { styled } from '@mui/material';
import { Box, InfoTip, Link, Typography } from '@wpengine/unicorn/components';
import { FilledQuestionIcon } from '@wpengine/unicorn/icons';
import { orange700 } from 'design-tokens';
import { useOwnershipToggleListener } from './useOwnershipToggleEvents';
import { PORTAL_TEAMS } from './PortalTeamDirectory';

const TEAM_ID = 'T030ABJ1F';

const OwnershipBox = styled(Box)({
  position: 'relative',
  height: '100%',
});

const OwnershipInfoTip = styled(InfoTip)({
  position: 'absolute',
  left: '50%',
  marginLeft: '-20px',
  color: orange700,
});

interface OwnershipProps {
  inset?: boolean;
  team: keyof typeof PORTAL_TEAMS;
}

const Ownership: React.FC<OwnershipProps> = ({
  children,
  inset = false,
  team: teamAbbreviation,
}) => {
  const { isVisible } = useOwnershipToggleListener();
  const { name, channel, channelId } = PORTAL_TEAMS[teamAbbreviation];

  const title = (
    <Typography>
      This component is owned by Team {name}. Problems should be reported to{' '}
      <Link href={`slack://channel?team=${TEAM_ID}&id=${channelId}`}>
        {channel}
      </Link>
    </Typography>
  );
  if (isVisible) {
    return (
      <OwnershipBox
        sx={{
          outline: `2px dashed ${orange700}`,
          outlineOffset: inset ? '-12px' : '4px',
        }}
      >
        <OwnershipInfoTip
          icon={<FilledQuestionIcon />}
          title={title}
          sx={{
            top: inset ? '-8px' : '-24px',
            zIndex: 3,
          }}
        />
        {children}
      </OwnershipBox>
    );
  }
  return (
    <ConditionalRollbarContext context={`Owner:${name}`}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </ConditionalRollbarContext>
  );
};

export default Ownership;
