type sessionSettings = {
  enabled: boolean;
  expiry: Date;
};

interface useSessionSettingsProps {
  userUuid: string;
}

const useSessionSettings = ({ userUuid }: useSessionSettingsProps) => {
  const userSettingsKey = `wpe_labs_${userUuid}`;

  const getSessionSettings = () => {
    const settings = JSON.parse(sessionStorage.getItem(userSettingsKey));
    return settings ? validatedSettings(settings) : null;
  };

  const setSessionSettings = (enabled: boolean) => {
    sessionStorage.setItem(
      userSettingsKey,
      JSON.stringify({ enabled, expiry: getExpiry() })
    );
  };

  const getExpiry = () => {
    const d = new Date();
    return new Date(d.getTime() + 10 * 60000);
  };

  const validatedSettings = (settings: sessionSettings) => {
    const { expiry } = settings;
    if (new Date(expiry) > new Date()) {
      return settings;
    }
    sessionStorage.removeItem(userSettingsKey);
    return null;
  };

  return {
    getSessionSettings,
    setSessionSettings,
  };
};

export default useSessionSettings;
