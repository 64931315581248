import UserTracking from 'modulesDir/utilities/UserTracking';
import { chain } from 'lodash';
import { Toaster } from '@wpengine/unicorn/components';
import getCsrfToken from 'componentsDir/users/utils/userUtils';

export const PermissionDeniedMsg =
  'Sorry, you do not have sufficient permissions. Currently this action can only be performed by an Account Owner.';

export const truncate = (str: string, n: number) => {
  // Only apply the truncation if the string is bigger than n + 3 characters the periods take up.
  return str?.length > n + 3 ? `${str?.substr(0, n - 1)}...` : str;
};

export const sendPendoEvent = (
  category: string,
  action = '',
  label = ''
): void => {
  UserTracking.sendPendoEvent(category, action, label);
};

export const sendNotificationOpenEvent = (
  source: 'panel' | 'center',
  notificationId: string
): void => {
  UserTracking.sendEvent(
    `[Notifications] Portal user has opened a notification from ${source}`,
    'open',
    notificationId
  );
};

export const formSlackAuthUrl = (config: slackConfig): string => {
  const stateWithCsrf = `${config?.state}::${getCsrfToken()}`;
  return `${config?.authEndpoint}?client_id=${config?.clientId}&scope=${
    config?.scope
  }&state=${encodeURIComponent(stateWithCsrf)}&redirect_uri=${
    config.redirectUri
  }&response_type=code`;
};

export const validateSlackState = (
  stateWithoutCsrf: string,
  state: string
): boolean => {
  const stateWithCsrf = `${stateWithoutCsrf}::${getCsrfToken()}`;
  return decodeURIComponent(state) === stateWithCsrf;
};

// Validate color and group by it. Color defaults to "primary" if invalid (consistent with "Button" class behaviour).
export const buttonGroups = (buttons: NotificationsButton[]) => {
  return chain(buttons)
    .groupBy(button => {
      const notificationButtonTypes = ['primary', 'secondary', 'tertiary'];
      if (button?.color in notificationButtonTypes) {
        return button?.color;
      }
      return 'primary';
    })
    .value();
};

export const toasterMessage = (message: string, type: 'success' | 'error') => {
  Toaster[type](message, { options: { autoClose: 6000 } });
};
