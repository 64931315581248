const notifications: NotifyHubNotification[] = [
  {
    id: '6294946325203532',
    subject:
      'Smart Plugin Manager could not connect to eugenebert.wpenginedev.com',
    description:
      'Smart Plugin Manager attempted to update plugins and themes on eugenebert.wpenginedev.com but was unable to connect to your website.',
    read: false,
    severity: 'warning',
    createTime: '2022-11-16T00:11:42.878209Z',
    buttons: [
      {
        text: 'View summary',
        color: 'secondary',
        link:
          'https://my.dev.wpesvc.net/addons/smart_plugin_manager/redirect/_/eugenebert/updates/10029481',
      },
    ],
    message: 'mesmeric_test_message',
    isActive: true,
    recipientId: 'test_recipient',
    category: 'test_category',
  },
  {
    id: '6296357173988617',
    subject:
      'Smart Plugin Manager could not connect to testsite3dev.wpenginedev.com',
    description:
      'Smart Plugin Manager attempted to update plugins and themes on testsite3dev.wpenginedev.com but was unable to connect to your website.',
    read: false,
    severity: 'warning',
    createTime: '2022-11-16T06:13:15.546649Z',
    buttons: [
      {
        text: 'View summary',
        color: 'secondary',
        link:
          'https://my.dev.wpesvc.net/addons/smart_plugin_manager/redirect/_/testsite3dev/updates/10029623',
      },
    ],
    message: 'mesmeric_test_message',
    isActive: true,
    recipientId: 'test_recipient',
    category: 'test_category',
  },
  {
    id: '6298013554090670',
    subject:
      "jszczepanski4.wpenginedev.com wasn't restored after failed update",
    description:
      "3 plugins were not updated.\nYour site was not restored to its previous state due to failed updates.\nPlease check your site to ensure it's functioning properly.",
    read: true,
    severity: 'critical',
    createTime: '2022-11-16T12:40:50.335015Z',
    buttons: [
      {
        text: 'View summary',
        color: 'secondary',
        link:
          'https://my.dev.wpesvc.net/addons/smart_plugin_manager/redirect/_/jszczepanski4/updates/10029843',
      },
    ],
    message: 'mesmeric_test_message',
    isActive: true,
    recipientId: 'test_recipient',
    category: 'test_category',
  },
  {
    id: '6298013607422134',
    subject: 'jszczepanski3.wpenginedev.com was successfully updated',
    description: '2 plugins and 1 theme were updated.',
    read: true,
    severity: 'success',
    createTime: '2022-11-16T12:41:40.893263Z',
    buttons: [
      {
        text: 'View summary',
        color: 'secondary',
        link:
          'https://my.dev.wpesvc.net/addons/smart_plugin_manager/redirect/_/jszczepanski3/updates/10029842',
      },
    ],
    message: 'mesmeric_test_message',
    isActive: true,
    recipientId: 'test_recipient',
    category: 'test_category',
  },
  {
    id: '6298031767201893',
    subject: 'jszczepanski2.wpenginedev.com was successfully updated',
    description: '4 plugins were updated.',
    read: true,
    severity: 'success',
    createTime: '2022-11-16T12:44:41.239232Z',
    buttons: [
      {
        text: 'View summary',
        color: 'secondary',
        link:
          'https://my.dev.wpesvc.net/addons/smart_plugin_manager/redirect/_/jszczepanski2/updates/10029841',
      },
    ],
    message: 'mesmeric_test_message',
    isActive: true,
    recipientId: 'test_recipient',
    category: 'test_category',
  },
  {
    id: '6300278526900545',
    subject:
      'Smart Plugin Manager could not connect to eugenepro.wpenginedev.com',
    description:
      'Smart Plugin Manager attempted to update plugins and themes on eugenepro.wpenginedev.com but was unable to connect to your website.',
    read: true,
    severity: 'warning',
    createTime: '2022-11-16T18:21:23.898256Z',
    buttons: [
      {
        text: 'View summary',
        color: 'secondary',
        link:
          'https://my.dev.wpesvc.net/addons/smart_plugin_manager/redirect/_/eugenepro/updates/10029956',
      },
    ],
    message: 'mesmeric_test_message',
    isActive: true,
    recipientId: 'test_recipient',
    category: 'test_category',
  },
  {
    id: '6302477725499370',
    subject:
      'Smart Plugin Manager could not connect to eugenebert.wpenginedev.com',
    description:
      'Smart Plugin Manager attempted to update plugins and themes on eugenebert.wpenginedev.com but was unable to connect to your website.',
    read: true,
    severity: 'warning',
    createTime: '2022-11-17T00:19:34.175937Z',
    buttons: [
      {
        text: 'View summary',
        color: 'secondary',
        link:
          'https://my.dev.wpesvc.net/addons/smart_plugin_manager/redirect/_/eugenebert/updates/10030084',
      },
    ],
    message: 'mesmeric_test_message',
    isActive: true,
    recipientId: 'test_recipient',
    category: 'test_category',
  },
  {
    id: '6303853160513789',
    subject:
      'Smart Plugin Manager could not connect to testsite3dev.wpenginedev.com',
    description:
      'Smart Plugin Manager attempted to update plugins and themes on testsite3dev.wpenginedev.com but was unable to connect to your website.',
    read: true,
    severity: 'warning',
    createTime: '2022-11-17T06:18:54.248637Z',
    buttons: [
      {
        text: 'View summary',
        color: 'secondary',
        link:
          'https://my.dev.wpesvc.net/addons/smart_plugin_manager/redirect/_/testsite3dev/updates/10030215',
      },
    ],
    message: 'mesmeric_test_message',
    isActive: true,
    recipientId: 'test_recipient',
    category: 'test_category',
  },
  {
    id: '6305372484897715',
    subject:
      "jszczepanski4.wpenginedev.com wasn't restored after failed update",
    description:
      "3 plugins were not updated.\nYour site was not restored to its previous state due to failed updates.\nPlease check your site to ensure it's functioning properly.",
    read: true,
    severity: 'critical',
    createTime: '2022-11-17T12:36:56.763393Z',
    buttons: [
      {
        text: 'View summary',
        color: 'secondary',
        link:
          'https://my.dev.wpesvc.net/addons/smart_plugin_manager/redirect/_/jszczepanski4/updates/10030417',
      },
    ],
    message: 'mesmeric_test_message',
    isActive: true,
    recipientId: 'test_recipient',
    category: 'test_category',
  },
];
const notificationsResponse: NotifyHubGetNotificationsResponse = {
  notifications,
  nextPageToken:
    'ZP-BAwEBFWxpc3ROb3RpZmljYXRpb25Ub2tlbgH_ggABBQEIT2Zmc2V0SUQBDAABCk9mZnNldFRpbWUB_4QAAQZGaWx0ZXIBDAABB09yZGVyQnkBDAABCENoZWNrc3VtAQYAAAAQ_4MFAQEEVGltZQH_hAAAAEj_ggEQNjMwNTM3MjQ4NDg5NzcxNQEPAQAAAA7bCCHoLYBz6AAAAQ1zcGFuPSJsYXRlc3QiAQtjcmVhdGVfdGltZQH8HHAA3gA=',
  totalSize: 76,
  unreadCount: 4,
  tokenCreationTime: '2023-05-18T14:07:10.577482059Z',
  hasNewNotifications: true,
};

export default notificationsResponse;
