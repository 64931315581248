import * as React from 'react';
import {
  cssTransition,
  toast,
  ToastContainerProps,
  ToastOptions,
} from 'react-toastify';

import { MessageBox, MessageBoxProps } from '../internal/MessageBox';
import 'react-toastify/dist/ReactToastify.css';

import './_Toaster.scss';
import { LinkProps } from '../Link/Link';

export const SmallBounce = cssTransition({
  enter: 'Unicorn__bounce-enter--bottom-center',
  exit: 'Unicorn__bounce-exit--bottom-center',
});

export type UnicornToastProps = React.FC<Omit<MessageBoxProps, 'toast'>>;

export const UnicornToast: UnicornToastProps = ({
  body,
  message,
  title,
  type,
  linkProps,
}) => {
  return (
    <MessageBox
      body={body}
      dismissible
      message={message}
      title={title}
      type={type}
      linkProps={linkProps}
      toast
    />
  );
};

export type ToastType = 'success' | 'error' | 'warning' | 'info' | 'internal';

const defaultContainerProps: ToastContainerProps = {
  autoClose: 8000,
  closeButton: false,
  closeOnClick: true,
  draggable: true,
  hideProgressBar: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  position: toast.POSITION.BOTTOM_RIGHT,
  toastClassName: 'wpe-toastify-wrapper',
  transition: SmallBounce,
};

const configureToast = (options?: ToastContainerProps): void => {
  const containerOptions = { ...defaultContainerProps, ...options };
  toast.configure(containerOptions);
};

const MINIMUM_AUTOCLOSE_TIMEOUT = 6000;

const notifyToast = (
  title: string,
  msg: string | React.ReactElement,
  type: ToastType,
  linkProps?: LinkProps,
  options?: ToastOptions
): void => {
  const toastOptions = { ...options };

  if (
    options?.autoClose !== false &&
    options?.autoClose !== undefined &&
    options?.autoClose < MINIMUM_AUTOCLOSE_TIMEOUT
  ) {
    toastOptions.autoClose = MINIMUM_AUTOCLOSE_TIMEOUT;
  }

  let props;

  if (typeof msg === 'string') {
    props = { message: msg };
  } else {
    props = { body: msg };
  }

  toast(
    <UnicornToast type={type} title={title} linkProps={linkProps} {...props} />,
    toastOptions
  );
};

interface ToasterOptions {
  title?: string;
  linkProps?: LinkProps;
  options?: ToastOptions;
}

const defaultToasterOptions: ToasterOptions = {
  linkProps: undefined,
  options: undefined,
};

const success = (
  msg: string | React.ReactElement,
  toasterOptions?: ToasterOptions
): void => {
  const options = { ...defaultToasterOptions, ...toasterOptions };
  notifyToast(
    options.title ?? 'Success',
    msg,
    'success',
    options.linkProps,
    options.options
  );
};

const error = (
  msg: string | React.ReactElement,
  toasterOptions?: ToasterOptions
): void => {
  const options = { ...defaultToasterOptions, ...toasterOptions };
  notifyToast(
    options.title ?? 'Error',
    msg,
    'error',
    options.linkProps,
    options.options
  );
};

const warning = (
  msg: string | React.ReactElement,
  toasterOptions?: ToasterOptions
): void => {
  const options = { ...defaultToasterOptions, ...toasterOptions };
  notifyToast(
    options.title ?? 'Warning',
    msg,
    'warning',
    options.linkProps,
    options.options
  );
};

const info = (
  msg: string | React.ReactElement,
  toasterOptions?: ToasterOptions
): void => {
  const options = { ...defaultToasterOptions, ...toasterOptions };
  notifyToast(
    options.title ?? 'Info',
    msg,
    'info',
    options.linkProps,
    options.options
  );
};

const internal = (
  msg: string | React.ReactElement,
  toasterOptions?: ToasterOptions
): void => {
  const options = { ...defaultToasterOptions, ...toasterOptions };
  notifyToast(
    options.title ?? 'Internal',
    msg,
    'internal',
    options.linkProps,
    options.options
  );
};

export const Toaster = {
  configure: configureToast,
  error,
  info,
  success,
  warning,
  internal,
};

export default Toaster;
