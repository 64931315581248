import {
  gray1200,
  gray1100,
  gray100,
  gray300,
  gray50,
  red200,
  red800,
} from 'design-tokens';
import { Components, Theme } from '@mui/material';

const menuItemTheme = (theme: Theme): Components['MuiMenuItem'] => {
  return {
    defaultProps: {
      disableGutters: true,
    },
    styleOverrides: {
      root: {
        minWidth: '160px',
        '&&&:focus': {
          boxShadow: 'none',
        },
        '&.MuiButtonBase-root': {
          alignItems: 'center',
          color: gray1100,
          display: 'flex',
          justifyContent: 'flex-start',
          padding: '12px 16px',
          width: 'auto',
          '.MuiTypography-body1': {
            fontWeight: '580',
          },
        },
        '&.Mui-selected': {
          backgroundColor: gray1200,
          color: 'white',
          '.MuiListItemIcon-root': {
            color: 'white',
          },
          '&:hover': {
            backgroundColor: gray1200,
          },
        },
        '.MuiLink-underlineHover': {
          '&:hover, &:focus, &:focus-visible': {
            textDecoration: 'none',
          },
        },
        '.MuiListItemIcon-root': {
          color: gray1200,
        },
        'a:focus&': { outline: 0, textDecoration: 'none' }, // Undo scaffolding.scss styling
        '&:hover': { backgroundColor: gray50 },
        '&:active': { backgroundColor: gray300 },
        '&:focus-visible': {
          backgroundColor: gray100,
          border: `2px solid ${theme.palette.primary.main}`,
        },
        '&.destructive': {
          '&.MuiButtonBase-root': {
            color: red800,
            '.MuiListItemIcon-root': {
              color: red800,
            },
            '.MuiTypography-body2': {
              color: red800,
            },
          },
          '&:hover': {
            backgroundColor: theme.palette.error.light,
          },
          '&:active': {
            backgroundColor: red200,
            '&:focus-visible': {
              color: red800,
            },
          },
          '&:focus-visible': {
            backgroundColor: theme.palette.error.light,
            '&:active': {
              backgroundColor: theme.palette.error.light,
              '.MuiListItemIcon-root': {
                color: red800,
              },
            },
          },
          '&.Mui-disabled': {
            color: gray1200,
            '.MuiListItemIcon-root': {
              color: gray1200,
            },
            '.MuiTypography-body2': {
              color: gray1200,
            },
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.error.light,
            '.MuiListItemIcon-root': {
              color: 'white',
            },
            '.MuiTypography-body2': {
              color: theme.palette.error.light,
            },
            '&:hover': {
              backgroundColor: theme.palette.error.dark,
            },
          },
        },
      },
    },
  };
};

export default menuItemTheme;
