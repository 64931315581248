interface Redirect {}
interface Domain {
  redirects: Redirect[];
  network_configuration?: string;
}
interface InstallConfig {
  site: string;
  cluster: number;
  domains: {
    [domainName: string]: Domain;
  };
  rewrites?: any[];
  'api-random': string;
  php_version: string;
  server_name: string;
  'domain-roots': string[];
  memcached_secret: string;
  unique_cache_tag: string;
  use_object_cache: boolean;
  varnish_no_cache?: string[];
  'domains-main-served': string[];
  varnish_no_cache_args?: string[];
  db_password_encryption: string;
  'serve-wildcard-domains': boolean;
}

interface InstallConfigHash {
  [installName: string]: InstallConfig | null;
}

const mockInstallConfigHashByInstallName: InstallConfigHash = {
  deletefordemo: {
    site: 'deletefordemo',
    cluster: 140255,
    domains: {
      'deletefordemo.wpenginedev.com': {
        redirects: [],
      },
      'deletefordemo.wpenginepoweredstaging.com': {
        redirects: [],
      },
    },
    rewrites: [],
    'api-random': '02SLcy5NcSszvcjt6tMP',
    php_version: '8.2',
    server_name: 'deletefordemo.wpenginepoweredstaging.com',
    'domain-roots': [
      'deletefordemo.wpenginedev.com',
      'deletefordemo.wpenginepoweredstaging.com',
    ],
    memcached_secret: 'fc1666d24a46e3c6',
    unique_cache_tag: 'deletefordemo67052b7642610',
    use_object_cache: true,
    varnish_no_cache: ['mizar'],
    'domains-main-served': [
      'deletefordemo.wpenginedev.com',
      'deletefordemo.wpenginepoweredstaging.com',
    ],
    varnish_no_cache_args: ['mizar'],
    db_password_encryption:
      '{"ciphertype":"aes-256-cbc","iv":"LMFAq8ppEnhH6dgQybw3ag==","key_name":"local-encryption-key"}',
    'serve-wildcard-domains': false,
  },
  findme1: {
    site: 'findme1',
    cluster: 140255,
    domains: {
      'ancustomdomain.com': {
        redirects: [],
        network_configuration: 'AN',
      },
      'www.testdomain.com': {
        redirects: [],
        network_configuration: 'AN',
      },
      'my.customdomai1n.com': {
        redirects: [],
        network_configuration: 'AN',
      },
      'newlyaddedwithefpc.com': {
        redirects: [],
        network_configuration: 'AN',
      },
      'findme1.wpenginedev.com': {
        redirects: [],
        network_configuration: 'Legacy',
      },
      'findme1.customdomain.com': {
        redirects: [],
        network_configuration: 'AN',
      },
      'www.newlyaddedwithefpc.com': {
        redirects: [],
        network_configuration: 'AN',
      },
      'findme1.wpenginepoweredstaging.com': {
        redirects: [],
        network_configuration: 'AN',
      },
    },
    rewrites: [],
    'api-random': 'OVnsfUjDdYhTj5giuaaA',
    php_version: '8.2',
    server_name: 'newlyaddedwithefpc.com',
    'domain-roots': [
      'ancustomdomain.com',
      'customdomain.com',
      'findme1.wpenginedev.com',
      'findme1.wpenginepoweredstaging.com',
      'customdomai1n.com',
      'newlyaddedwithefpc.com',
      'testdomain.com',
    ],
    memcached_secret: '2c15c7e717f34f11',
    unique_cache_tag: 'findme165cb3167cba7c',
    use_object_cache: false,
    varnish_no_cache: ['/temu'],
    'domains-main-served': [
      'ancustomdomain.com',
      'findme1.customdomain.com',
      'findme1.wpenginedev.com',
      'findme1.wpenginepoweredstaging.com',
      'my.customdomai1n.com',
      'newlyaddedwithefpc.com',
      'www.newlyaddedwithefpc.com',
      'www.testdomain.com',
    ],
    varnish_no_cache_args: ['add-to-cart=now'],
    db_password_encryption:
      '{"ciphertype":"aes-256-cbc","iv":"SfeabxrWKPyQJ6rA5Ipeng==","key_name":"local-encryption-key"}',
    'serve-wildcard-domains': false,
  },
  findmedev: {
    site: 'findmedev',
    cluster: 140255,
    domains: {
      'findmedev.wpenginedev.com': {
        redirects: [],
        network_configuration: 'Legacy',
      },
      'findmedev.wpenginepoweredstaging.com': {
        redirects: [],
        network_configuration: 'AN',
      },
    },
    'api-random': '3TzWoQBIKXQuBhBWmVHi',
    php_version: '8.2',
    server_name: 'findmedev.wpenginepoweredstaging.com',
    'domain-roots': [
      'findmedev.wpenginedev.com',
      'findmedev.wpenginepoweredstaging.com',
    ],
    memcached_secret: 'da61dcaac5aa06b8',
    unique_cache_tag: 'findmedev6645e08170868',
    use_object_cache: true,
    'domains-main-served': [
      'findmedev.wpenginedev.com',
      'findmedev.wpenginepoweredstaging.com',
    ],
    db_password_encryption:
      '{"ciphertype":"aes-256-cbc","iv":"wURvSB3idqeGN5OGlwrqEg==","key_name":"local-encryption-key"}',
    'serve-wildcard-domains': false,
  },
  forfpc: {
    site: 'forfpc',
    cluster: 140255,
    domains: {
      'gesonly.com': {
        redirects: [],
      },
      'efpconadd.com': {
        redirects: [],
      },
      'efpcbetaon.net': {
        redirects: [],
      },
      'testmydomain.ph': {
        redirects: [],
      },
      'withgesdomain.com': {
        redirects: [],
      },
      'www.efpcbetaon.net': {
        redirects: [],
      },
      'www.testmydomain.ph': {
        redirects: [],
      },
      'my.customdomains.com': {
        redirects: [],
      },
      'www.withgesdomain.com': {
        redirects: [],
      },
      'forfpc.wpenginedev.com': {
        redirects: [],
      },
      'forfpc.customdomain.com': {
        redirects: [],
      },
      'forfpc.wpenginepoweredstaging.com': {
        redirects: [],
      },
    },
    rewrites: [],
    'api-random': 'bOCZQOzIPNma5jHkwpuz',
    php_version: '8.2',
    server_name: 'efpcbetaon.net',
    'domain-roots': [
      'efpcbetaon.net',
      'efpconadd.com',
      'customdomain.com',
      'forfpc.wpenginedev.com',
      'forfpc.wpenginepoweredstaging.com',
      'gesonly.com',
      'customdomains.com',
      'testmydomain.ph',
      'withgesdomain.com',
    ],
    memcached_secret: '97b16f60637cfb32',
    unique_cache_tag: 'forfpc6671815420639',
    use_object_cache: true,
    varnish_no_cache: ['megrez', '^/dubhe'],
    'domains-main-served': [
      'efpcbetaon.net',
      'efpconadd.com',
      'forfpc.customdomain.com',
      'forfpc.wpenginedev.com',
      'forfpc.wpenginepoweredstaging.com',
      'gesonly.com',
      'my.customdomains.com',
      'testmydomain.ph',
      'withgesdomain.com',
      'www.efpcbetaon.net',
      'www.testmydomain.ph',
      'www.withgesdomain.com',
    ],
    varnish_no_cache_args: ['stars-and-constellations='],
    db_password_encryption:
      '{"ciphertype":"aes-256-cbc","iv":"k3L5CXx\\/K6Zs4O5z3Rar9w==","key_name":"local-encryption-key"}',
    'serve-wildcard-domains': false,
  },
  legacycustom: {
    site: 'legacycustom',
    cluster: 140255,
    domains: {
      'justacustomdomain.com': {
        redirects: [],
        network_configuration: 'Legacy',
      },
      'legacycustom.wpenginedev.com': {
        redirects: [],
        network_configuration: 'Legacy',
      },
      'imacustomdomainonadvancenetwork.com': {
        redirects: [],
        network_configuration: 'AN',
      },
      'legacycustom.wpenginepoweredstaging.com': {
        redirects: [],
        network_configuration: 'AN',
      },
    },
    'api-random': '28bB8kTNIqtpjrt9kyKY',
    php_version: '8.2',
    server_name: 'imacustomdomainonadvancenetwork.com',
    'domain-roots': [
      'imacustomdomainonadvancenetwork.com',
      'justacustomdomain.com',
      'legacycustom.wpenginedev.com',
      'legacycustom.wpenginepoweredstaging.com',
    ],
    memcached_secret: 'ad309ec3f2f8c602',
    unique_cache_tag: 'legacycustom66e2b003875cf',
    use_object_cache: true,
    'domains-main-served': [
      'imacustomdomainonadvancenetwork.com',
      'justacustomdomain.com',
      'legacycustom.wpenginedev.com',
      'legacycustom.wpenginepoweredstaging.com',
    ],
    db_password_encryption:
      '{"ciphertype":"aes-256-cbc","iv":"jViHEfdJTPShE8XduuWITw==","key_name":"local-encryption-key"}',
    'serve-wildcard-domains': false,
  },
  overviewallsit: {
    site: 'overviewallsit',
    cluster: 140255,
    domains: {
      'overviewallsit.wpenginedev.com': {
        redirects: [],
        network_configuration: 'Legacy',
      },
      'overviewallsit.wpenginepoweredstaging.com': {
        redirects: [],
        network_configuration: 'AN',
      },
    },
    'api-random': 'kT5Wv2pJ_4W-ioA-Dnbd',
    php_version: '8.2',
    server_name: 'overviewallsit.wpenginepoweredstaging.com',
    'domain-roots': [
      'overviewallsit.wpenginedev.com',
      'overviewallsit.wpenginepoweredstaging.com',
    ],
    memcached_secret: 'b7459093d0c6d002',
    unique_cache_tag: 'overviewallsit66fbfca646700',
    use_object_cache: true,
    'domains-main-served': [
      'overviewallsit.wpenginedev.com',
      'overviewallsit.wpenginepoweredstaging.com',
    ],
    db_password_encryption:
      '{"ciphertype":"aes-256-cbc","iv":"mp0I06fh32+cK8xcMTPRzg==","key_name":"local-encryption-key"}',
    'serve-wildcard-domains': false,
  },
  sanboxtestdev: {
    site: 'sanboxtestdev',
    cluster: 140255,
    domains: {
      'sanboxtestdev.wpenginedev.com': {
        redirects: [],
        network_configuration: 'Legacy',
      },
      'sanboxtestdev.wpenginepoweredstaging.com': {
        redirects: [],
        network_configuration: 'AN',
      },
    },
    'api-random': 'Ss7eWv_SGoM4Pg8ORPTU',
    php_version: '8.2',
    server_name: 'sanboxtestdev.wpenginedev.com',
    'domain-roots': [
      'sanboxtestdev.wpenginedev.com',
      'sanboxtestdev.wpenginepoweredstaging.com',
    ],
    memcached_secret: 'c13be57583deedbb',
    unique_cache_tag: 'sanboxtestdev66c7101084578',
    use_object_cache: true,
    'domains-main-served': [
      'sanboxtestdev.wpenginedev.com',
      'sanboxtestdev.wpenginepoweredstaging.com',
    ],
    db_password_encryption:
      '{"ciphertype":"aes-256-cbc","iv":"kWqw7vjOIJxN49FK6rIvnA==","key_name":"local-encryption-key"}',
    'serve-wildcard-domains': false,
  },
  installWithNoConfigHash: null,
};

export default mockInstallConfigHashByInstallName;
