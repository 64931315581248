import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const Frost: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="Frost logo" viewBox="0 0 128 128" {...props}>
      <path
        d="M28.48 3.52055L3.52 28.4845C1.26 30.7448 0 33.7853 0 36.9658V121.999C0 125.32 2.68 128 6 128H122C125.32 128 128 125.32 128 121.999V6.00094C128 2.68042 125.32 0 122 0H36.98C33.8 0 30.74 1.2602 28.5 3.52055H28.48Z"
        fill="url(#paint0_radial_146_9465)"
      />
      <g filter="url(#filter0_d_146_9465)">
        <path
          d="M52.0346 36.6791C53.1889 34.4403 56.3901 34.4403 57.5445 36.6791L84.2303 88.4333C85.294 90.4963 83.7964 92.9535 81.4753 92.9535H28.1038C25.7827 92.9535 24.2851 90.4963 25.3488 88.4333L52.0346 36.6791Z"
          fill="white"
        />
        <path
          d="M83.3036 55.4379C84.0732 53.9453 86.2073 53.9453 86.9769 55.4379L104.767 89.9407C105.477 91.316 104.478 92.9541 102.931 92.9541H96.9902C96.2167 92.9541 95.508 92.5222 95.1535 91.8347L80.3333 63.0925C80.0269 62.4983 80.0269 61.7926 80.3333 61.1984L83.3036 55.4379Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_146_9465"
          x="17"
          y="27"
          width="96"
          height="73.9541"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_146_9465"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_146_9465"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_146_9465"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(181.019)"
        >
          <stop stopColor="#0EBCD4" />
          <stop offset="1" stopColor="#00366B" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default Frost;
