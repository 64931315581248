import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const CloseIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="close-icon" ref={ref} {...props}>
        <path d="M6.13663 4.43088C5.60673 3.90098 4.79533 3.85325 4.32431 4.32427C3.85329 4.79529 3.90102 5.60669 4.43091 6.13659L10.2943 12L4.43088 17.8634C3.90098 18.3933 3.85325 19.2047 4.32427 19.6757C4.79529 20.1467 5.60669 20.099 6.13659 19.5691L12 13.7057L17.8634 19.5691C18.3933 20.099 19.2047 20.1467 19.6757 19.6757C20.1467 19.2046 20.099 18.3932 19.5691 17.8634L13.7057 12L19.5691 6.13665C20.099 5.60675 20.1467 4.79535 19.6757 4.32433C19.2046 3.85331 18.3932 3.90104 17.8634 4.43094L12 10.2943L6.13663 4.43088Z" />
      </SvgTemplate>
    );
  }
);
export default CloseIcon;
