import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const HeadlessWordPress: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate
      name="HeadlessWordPress logo"
      viewBox="0 0 128 128"
      {...props}
    >
      <circle cx="64" cy="64" r="64" fill="url(#paint0_radial_127_7435)" />
      <path
        d="M74.6413 101.98V89.9237C74.6413 88.956 75.0308 88.0248 75.7125 87.3432L87.3432 75.7125C88.0309 75.0247 88.956 74.6413 89.9237 74.6413H101.968C103.983 74.6413 105.62 73.0102 105.62 70.9896V57.6365C105.62 55.622 103.983 53.9848 101.968 53.9848H89.9237C88.956 53.9848 88.0248 53.5953 87.3432 52.9136L75.7125 41.2829C75.0247 40.5952 74.6413 39.6701 74.6413 38.7024V26.6517C74.6413 24.6372 73.0102 23 70.9896 23H57.6304C55.6159 23 53.9787 24.6372 53.9787 26.6578V38.7024C53.9787 39.6701 53.5953 40.6013 52.9136 41.2829L41.2829 52.9136C40.5952 53.6014 39.6701 53.9848 38.7024 53.9848H26.6517C24.6372 53.9848 23 55.622 23 57.6365V70.9896C23 73.0041 24.6372 74.6413 26.6517 74.6413H38.7024C39.6701 74.6413 40.6013 75.0247 41.2829 75.7125L52.9136 87.3432C53.6014 88.0309 53.9848 88.956 53.9848 89.9237V101.974C53.9848 103.989 55.622 105.626 57.6365 105.626H70.9896C73.0041 105.626 74.6413 103.995 74.6413 101.98Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_127_7435"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(181.019)"
        >
          <stop stopColor="#0ECAD4" />
          <stop offset="1" stopColor="#7A45E5" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default HeadlessWordPress;
