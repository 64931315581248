import { rest } from 'msw';
import { ENDPOINTS, MSW_PARAMS } from 'mocksDir/shared/constants';
import mockEntitlements from 'mocksDir/shared/data/mockEntitlements';
import { Entitlement } from 'commonsDir/types/Entitlement';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { capi_private_addons_entitlement_path } from 'railsRoutes';

// Use page url query params to respond with different mock data.
// Example usages:
// http://localhost:3000/capi/private/entitlements?noSiteMonitoring=true             |   Returns total monitoring licenses as 0.
// http://localhost:3000/capi/private/entitlements?usedSiteMonitoring=true           |   Matches total monitoring licenses to used.
// http://localhost:3000/capi/private/entitlements?availableSiteMonitoring=true      |   Returns 100 monitoring entitlement quantity.
// http://localhost:3000/capi/private/entitlements?noEntitlements=true               |   Returns empty entitlements.

const handlers = [
  rest.get(`*/${ENDPOINTS.ENTITLEMENTS}`, (req, res, ctx) => {
    const products = req.url.searchParams.get('products');
    const entitlements = { ...mockEntitlements };
    const monitoringProduct = 'monitoring-site';

    if (hasPageUrlQueryParam('entitlementsError')) {
      return customResponse(ctx.status(500), ctx.json({ message: 'Oopsie' }));
    }

    if (hasPageUrlQueryParam('noEntitlements')) {
      return customResponse(ctx.status(200), ctx.json({ entitlements: [] }));
    }

    if (hasPageUrlQueryParam('noSiteMonitoring')) {
      entitlements.entitlements.find(
        entitlement => entitlement.product === monitoringProduct
      ).quantity = 0;
    }

    if (hasPageUrlQueryParam('usedSiteMonitoring')) {
      entitlements.entitlements.find(
        entitlement => entitlement.product === monitoringProduct
      ).quantity = 6;
    }

    if (hasPageUrlQueryParam('availableSiteMonitoring')) {
      entitlements.entitlements.find(
        entitlement => entitlement.product === monitoringProduct
      ).quantity = 100;
    }

    if (hasPageUrlQueryParam('noPSB')) {
      entitlements.entitlements = entitlements.entitlements.filter(
        entitlement => entitlement.product !== 'page-speed-boost'
      );
    }

    if (hasPageUrlQueryParam('availablePSB')) {
      entitlements.entitlements = entitlements.entitlements.map(entitlement => {
        if (entitlement.product === 'page-speed-boost') {
          return { ...entitlement, quantity: 100 };
        }
        return entitlement;
      });
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.GES_AVAILABLE)) {
      entitlements.entitlements = entitlements.entitlements.map(entitlement => {
        if (entitlement.product === 'global-edge-security') {
          return { ...entitlement, quantity: 100 };
        }
        return entitlement;
      });
    }

    // Only return the requested product types
    if (products) {
      const productsArray = products.split(',');

      const productEntitlements: Entitlement[] = productsArray.map(product =>
        entitlements.entitlements.find(
          entitlement => entitlement.product === product
        )
      );

      return customResponse(
        ctx.status(200),
        ctx.json({ entitlements: productEntitlements })
      );
    }

    return customResponse(ctx.status(200), ctx.json(mockEntitlements));
  }),

  // GET /addons_entitlement
  rest.get(capi_private_addons_entitlement_path(), async (req, res, ctx) => {
    const addonsQueryParam = req.url.searchParams.get('products');
    const addonIDs = addonsQueryParam.split(',');

    if (hasPageUrlQueryParam('refreshEmpty')) {
      return customResponse(ctx.status(200), ctx.json([]));
    }
    if (hasPageUrlQueryParam('refreshError')) {
      return customResponse(ctx.status(400), ctx.json([]));
    }

    return customResponse(ctx.status(200), ctx.json(addonIDs));
  }),
];

export default handlers;
