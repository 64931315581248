import * as React from 'react';
import { StepIconProps } from '@mui/material/StepIcon';
import {
  CheckCircleFilledIcon,
  StepperOneIcon,
  StepperOneFilledIcon,
  StepperTwoIcon,
  StepperTwoFilledIcon,
  StepperThreeIcon,
  StepperThreeFilledIcon,
  StepperFourIcon,
  StepperFourFilledIcon,
  StepperFiveIcon,
  StepperFiveFilledIcon,
  StepperSixIcon,
  StepperSixFilledIcon,
  StepperSevenIcon,
  StepperSevenFilledIcon,
  StepperEightIcon,
  StepperEightFilledIcon,
  StepperNineIcon,
  StepperNineFilledIcon,
  WarningIcon,
} from '@wpengine/unicorn/icons';
import { red700 } from 'design-tokens';

const StepIcon = (props: StepIconProps): JSX.Element => {
  const { active = false, completed = false, error = false, icon } = props;

  const styles = [
    {
      color: 'action.disabled',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active && {
      color: 'primary.main',
    },
    error && {
      color: 'error.main',
    },
    completed && {
      color: 'secondary.main',
      zIndex: 1,
    },
  ];

  if (error) {
    return <WarningIcon sx={styles} htmlColor={red700} type="plain" />;
  }

  if (completed) {
    return <CheckCircleFilledIcon sx={styles} />;
  }

  if (active) {
    switch (icon?.toString()) {
      case '1':
        return <StepperOneFilledIcon sx={styles} />;
      case '2':
        return <StepperTwoFilledIcon sx={styles} />;
      case '3':
        return <StepperThreeFilledIcon sx={styles} />;
      case '4':
        return <StepperFourFilledIcon sx={styles} />;
      case '5':
        return <StepperFiveFilledIcon sx={styles} />;
      case '6':
        return <StepperSixFilledIcon sx={styles} />;
      case '7':
        return <StepperSevenFilledIcon sx={styles} />;
      case '8':
        return <StepperEightFilledIcon sx={styles} />;
      case '9':
        return <StepperNineFilledIcon sx={styles} />;
      default:
        return <>{icon}</>;
    }
  }

  switch (icon?.toString()) {
    case '1':
      return <StepperOneIcon sx={styles} />;
    case '2':
      return <StepperTwoIcon sx={styles} />;
    case '3':
      return <StepperThreeIcon sx={styles} />;
    case '4':
      return <StepperFourIcon sx={styles} />;
    case '5':
      return <StepperFiveIcon sx={styles} />;
    case '6':
      return <StepperSixIcon sx={styles} />;
    case '7':
      return <StepperSevenIcon sx={styles} />;
    case '8':
      return <StepperEightIcon sx={styles} />;
    case '9':
      return <StepperNineIcon sx={styles} />;
    default:
      return <>{icon}</>;
  }
};

export default StepIcon;
