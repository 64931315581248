/**
 * File generated by js-routes 2.2.4
 * Based on Rails 7.0.8.5 routes of MyWpe::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /addons/aggregation_service/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_addons_aggregation_service_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"aggregation_service"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /addons/genesis_blocks_pro/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_addons_genesis_blocks_pro_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"genesis_blocks_pro"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /addons/global_edge_security/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_addons_global_edge_security_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"global_edge_security"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /addons/smart_plugin_manager/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_addons_smart_plugin_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"smart_plugin_manager"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /addons/smart_search/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_addons_smart_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"smart_search"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/aggregation_service/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_products_aggregation_service_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"aggregation_service"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/genesis_blocks_pro/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_products_genesis_blocks_pro_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"genesis_blocks_pro"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/global_edge_security/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_products_global_edge_security_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"global_edge_security"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/smart_plugin_manager/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_products_smart_plugin_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"smart_plugin_manager"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/smart_search/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_products_smart_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"smart_search"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/site_monitors/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_site_monitors_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"site_monitors"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /my_account/accept_discount(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_discount_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_account"],[2,[7,"/"],[2,[6,"accept_discount"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/accept(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:id/access_logs_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const access_logs_data_install_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"access_logs_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:id/access_logs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const access_logs_install_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"access_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/activity_log(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_activity_log_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"activity_log"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/add_site(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_add_site_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"add_site"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/api_settings(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_api_settings_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"api_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/atlas(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_atlas_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"atlas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/reports/account_coupons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_coupons_admin_billing_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"account_coupons"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/headless(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_headless_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"headless"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/users/install/:install_name(.:format)
 * @param {any} account_id
 * @param {any} install_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_install_collaborators_path = __jsr.r({"account_id":{"r":true},"install_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"install"],[2,[7,"/"],[2,[3,"install_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/modify_plan(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_modify_plan_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"modify_plan"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /account_nickname(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_nickname_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"account_nickname"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/notifications(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_notifications_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /account_ready(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_ready_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"account_ready"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/products/site_monitors(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_site_monitors_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"site_monitors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/uncached_requests_report(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_uncached_requests_report_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"uncached_requests_report"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/usage(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_usage_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"usage"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/users/:user_id/edit(.:format)
 * @param {any} account_id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_user_edit_path = __jsr.r({"account_id":{"r":true},"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/users/:user_id/update_default_add_site_tile(.:format)
 * @param {any} account_id
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_user_update_default_add_site_tile_path = __jsr.r({"account_id":{"r":true},"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"update_default_add_site_tile"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/users(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_users_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /accounts/:account_id/users/new(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_users_new_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:id/activate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activate_admin_billing_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_certificates/add(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_install_ssl_certificates_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_certificates"],[2,[7,"/"],[2,[6,"add"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /usage/addon_enablement(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addon_enablement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"usage"],[2,[7,"/"],[2,[6,"addon_enablement"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /addons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addons_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /addons/aggregation_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addons_aggregation_service_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"aggregation_service"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /addons/atlas_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addons_atlas_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"atlas_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /addons/atlas_search/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addons_atlas_search_about_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"atlas_search"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /addons/atomic_blocks_pro(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addons_atomic_blocks_pro_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"atomic_blocks_pro"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /addons/atomic_blocks_pro/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addons_atomic_blocks_pro_about_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"atomic_blocks_pro"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /addons/genesis_blocks_pro(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addons_genesis_blocks_pro_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"genesis_blocks_pro"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /addons/genesis_custom_blocks_pro(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addons_genesis_custom_blocks_pro_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"genesis_custom_blocks_pro"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /addons/genesis_pro(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addons_genesis_pro_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"genesis_pro"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /addons/genesis_pro/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addons_genesis_pro_about_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"genesis_pro"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /addons/global_edge_security(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addons_global_edge_security_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"global_edge_security"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /addons/instant_store_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addons_instant_store_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"instant_store_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /addons/smart_plugin_manager(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addons_smart_plugin_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"smart_plugin_manager"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /addons/smart_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addons_smart_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"smart_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/feature_flags/:id(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_account_feature_flag_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"feature_flags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/feature_flags(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_account_feature_flags_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"feature_flags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_account_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/api_keys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_api_keys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"api_keys"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/adjustments(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_account_adjustments_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"adjustments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/invoices/:id(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_account_invoice_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/invoices(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_account_invoices_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/payments(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_account_payments_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/purchases(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_account_purchases_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"purchases"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/refunds(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_account_refunds_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"refunds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/subscription(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_account_subscription_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"subscription"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/adjustment_reasons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_adjustment_reason_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"adjustment_reasons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/adjustment_reasons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_adjustment_reasons_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"adjustment_reasons"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/coupons/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_coupon_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"coupons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/coupons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_coupons_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"coupons"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/failed_payments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_failed_payments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"failed_payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/prices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_prices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"prices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/products/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/refunds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_refund_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"refunds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/refunds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_refunds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"refunds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"reports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/configure_chat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_configure_chat_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"configure_chat"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/cxbi/cancellations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_cxbi_cancellations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cxbi"],[2,[7,"/"],[2,[6,"cancellations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cxbi/downgrades(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_cxbi_downgrades_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cxbi"],[2,[7,"/"],[2,[6,"downgrades"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/domain_networks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_domain_networks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"domain_networks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/email_delivery(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_email_delivery_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"email_delivery"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/entitlements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_entitlements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"entitlements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/entitlements/circuit_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_entitlements_circuit_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"entitlements"],[2,[7,"/"],[2,[6,"circuit_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/entitlements/delete_cache(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_entitlements_delete_cache_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"entitlements"],[2,[7,"/"],[2,[6,"delete_cache"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/entitlements/entitlements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_entitlements_entitlements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"entitlements"],[2,[7,"/"],[2,[6,"entitlements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/managed_migration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_managed_migration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"managed_migration"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /admin/signups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_signups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"signups"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/site_notices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_site_notice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"site_notices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/site_notices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_site_notices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"site_notices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/sites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_sites_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sites"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/ssl_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_ssl_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ssl_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ssl_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_ssl_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ssl_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/support_technicians/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_support_agent_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"support_technicians"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/support_technicians(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_support_agents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"support_technicians"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/sync(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_sync_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sync"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/themes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_themes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"themes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/themes/sync(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_themes_sync_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"themes"],[2,[7,"/"],[2,[6,"sync"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:user_id/announcement_hiders/:id(.:format)
 * @param {any} user_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_announcement_hider_path = __jsr.r({"user_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"announcement_hiders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:user_id/announcement_hiders(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_announcement_hiders_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"announcement_hiders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:user_id/feature_flags/:id(.:format)
 * @param {any} user_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_feature_flag_path = __jsr.r({"user_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"feature_flags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:user_id/feature_flags(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_feature_flags_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"feature_flags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/user_personas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_personas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"user_personas"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/user_personas/reset(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_personas_reset_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"user_personas"],[2,[7,"/"],[2,[6,"reset"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /advanced_network(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const advanced_network_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"advanced_network"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /accounts/all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"all"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/cache_dashboard/all_caches_cleared(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_caches_cleared_install_cache_dashboard_index_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"cache_dashboard"],[2,[7,"/"],[2,[6,"all_caches_cleared"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/reports/all_coupons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_coupons_admin_billing_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"all_coupons"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /all_sites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_sites_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"all_sites"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /announcement_hiders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const announcement_hiders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"announcement_hiders"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /announcement_hiders/manual_expiration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const announcement_hiders_manual_expiration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"announcement_hiders"],[2,[7,"/"],[2,[6,"manual_expiration"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/customers/:name(.:format)
 * @param {any} name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_path = __jsr.r({"name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /profile/api_access(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_access_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"api_access"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/services/v1/inbound_links/:url_name(.:format)
 * @param {any} url_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_services_v1_path = __jsr.r({"url_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"inbound_links"],[2,[7,"/"],[2,[3,"url_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/services/v1/inbound_links(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_services_v1_inbound_links_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"inbound_links"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/services/v1/install_operation_status/add_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_services_v1_install_operation_status_add_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"install_operation_status"],[2,[7,"/"],[2,[6,"add_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/services/v1/install_operation_status/update_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_services_v1_install_operation_status_update_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"install_operation_status"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/services/v1/ssh_keys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_services_v1_ssh_keys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"ssh_keys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/services/v1/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_services_v1_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/ssh/config/:username(.:format)
 * @param {any} username
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_path = __jsr.r({"username":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"ssh"],[2,[7,"/"],[2,[6,"config"],[2,[7,"/"],[2,[3,"username"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/docs/swagger(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_docs_swagger_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"docs"],[2,[7,"/"],[2,[6,"swagger"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/invoices/:id/apply_bad_debt(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apply_bad_debt_admin_billing_account_invoice_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"apply_bad_debt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /atlas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const atlas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"atlas"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/migration/attach_file(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attach_file_install_migration_checklists_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"migration"],[2,[7,"/"],[2,[6,"attach_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /authentication_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const authentication_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"authentication_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /available_licenses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const available_licenses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"available_licenses"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /deploys/backups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const backups_deploys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"deploys"],[2,[7,"/"],[2,[6,"backups"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bapi/countries/:country_code/subdivisions(.:format)
 * @param {any} country_code
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bapi_path = __jsr.r({"country_code":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bapi"],[2,[7,"/"],[2,[6,"countries"],[2,[7,"/"],[2,[3,"country_code"],[2,[7,"/"],[2,[6,"subdivisions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /bapi/countries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bapi_countries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bapi"],[2,[7,"/"],[2,[6,"countries"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bapi/plans/genesis_pro(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bapi_plans_genesis_pro_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bapi"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[6,"genesis_pro"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/utilities/basic_auth(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const basic_auth_install_utilities_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"utilities"],[2,[7,"/"],[2,[6,"basic_auth"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing_mediator(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_mediator_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing_mediator"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /billing_mediator/plan(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_mediator_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing_mediator"],[2,[7,"/"],[2,[6,"plan"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /profile/bitbucket_pipeline(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bitbucket_pipeline_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"bitbucket_pipeline"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/installs/:install_id/domains/bulk(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_capi_v0_install_domains_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[6,"bulk"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/installs/:install_id/domains/bulk(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_capi_v1_install_domains_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[6,"bulk"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/installs/:install_id/domains/bulk(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_papi_v0_install_domains_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[6,"bulk"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/installs/:install_id/domains/bulk(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_papi_v1_install_domains_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[6,"bulk"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/cache_dashboard/cache_error(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cache_error_install_cache_dashboard_index_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"cache_dashboard"],[2,[7,"/"],[2,[6,"cache_error"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /my_account/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_account"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/subscription/cancel(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_admin_billing_account_subscription_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"subscription"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/authorize(/:resource)(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_path = __jsr.r({"resource":{},"id":{},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"authorize"],[2,[1,[2,[7,"/"],[3,"resource"]]],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/activations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_activations_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"activations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/addons/entitlement(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_addons_entitlement_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"entitlement"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/admin/authorize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_admin_authorize_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"authorize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/admin/sync(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_admin_sync_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sync"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/admin/sync_remote_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_admin_sync_remote_user_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sync_remote_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/authorize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_authorize_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"authorize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/authorize/entities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_authorize_entities_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"authorize"],[2,[7,"/"],[2,[6,"entities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_customers_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/entitlements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_entitlements_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"entitlements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/environments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_environments_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"environments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/installs/:install_id/git_push_developers/:id(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_install_git_push_developer_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"git_push_developers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/installs/:install_id/git_push_developers(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_install_git_push_developers_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"git_push_developers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/installs/:install_id/git_push_developers/data(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_install_git_push_developers_data_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"git_push_developers"],[2,[7,"/"],[2,[6,"data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/user/:user_id/lifecycle/reactivate(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_user_path = __jsr.r({"user_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"lifecycle"],[2,[7,"/"],[2,[6,"reactivate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/user/email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_user_email_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/user/factors/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_user_factor_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"factors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/user/factors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_user_factors_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"factors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/user/factors/google/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_user_google_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"factors"],[2,[7,"/"],[2,[6,"google"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/user/factors/google(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_user_google_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"factors"],[2,[7,"/"],[2,[6,"google"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/user/factors/okta(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_user_okta_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"factors"],[2,[7,"/"],[2,[6,"okta"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/user/phone(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_user_phone_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"phone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/user/factors/sms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_user_sm_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"factors"],[2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/private/user/factors/sms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_private_user_sms_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"factors"],[2,[7,"/"],[2,[6,"sms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/*path(.:format)
 * @param {any} path
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_path = __jsr.r({"path":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[5,[3,"path"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_account_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/accounts/:account_id/account_users/:id(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_account_account_user_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"account_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/accounts/:account_id/account_users(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_account_account_users_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"account_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_accounts_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/installs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_install_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/installs/:install_id/backups/:id(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_install_backup_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"backups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/installs/:install_id/backups(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_install_backups_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"backups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/installs/:install_id/domains/:id(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_install_domain_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/installs/:install_id/domains(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_install_domains_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/installs/:install_id/purge_cache(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_install_purge_cache_index_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"purge_cache"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/installs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_installs_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/sites/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_site_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/sites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_sites_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"sites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/ssh_keys/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_ssh_key_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"ssh_keys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/ssh_keys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_ssh_keys_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"ssh_keys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_status_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v0/user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v0_user_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/*path(.:format)
 * @param {any} path
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_path = __jsr.r({"path":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[5,[3,"path"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_account_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/accounts/:account_id/account_users/:id(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_account_account_user_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"account_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/accounts/:account_id/account_users(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_account_account_users_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"account_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_accounts_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/installs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_install_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/installs/:install_id/backups/:id(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_install_backup_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"backups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/installs/:install_id/backups(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_install_backups_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"backups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/installs/:install_id/domains/:id(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_install_domain_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/installs/:install_id/domains(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_install_domains_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/installs/:install_id/purge_cache(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_install_purge_cache_index_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"purge_cache"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/installs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_installs_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/sites/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_site_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/sites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_sites_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"sites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/ssh_keys/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_ssh_key_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"ssh_keys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/ssh_keys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_ssh_keys_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"ssh_keys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_status_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capi_v1_user_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /cart(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cart_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cart"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /capi/v0/installs/:install_id/domains/:id/check_status(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_status_capi_v0_install_domain_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /capi/v1/installs/:install_id/domains/:id/check_status(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_status_capi_v1_install_domain_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"capi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/installs/:install_id/domains/:id/check_status(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_status_papi_v0_install_domain_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/installs/:install_id/domains/:id/check_status(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_status_papi_v1_install_domain_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"check_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/products/clear_cache(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clear_cache_admin_billing_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"clear_cache"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/utilities/clear_cache(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clear_cache_install_utilities_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"utilities"],[2,[7,"/"],[2,[6,"clear_cache"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /usage/clear_usage_cache(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clear_usage_cache_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"usage"],[2,[7,"/"],[2,[6,"clear_usage_cache"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /confirm_email/:integration_id(.:format)
 * @param {any} integration_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirm_email_path = __jsr.r({"integration_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"confirm_email"],[2,[7,"/"],[2,[3,"integration_id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_orders/confirmation(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const confirmation_install_ssl_purchases_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_orders"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /technical_contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contacts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"technical_contacts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /core_enterprise_premium_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const core_enterprise_premium_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"core_enterprise_premium_plans"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /addons/smart_plugin_manager/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_addons_smart_plugin_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"smart_plugin_manager"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/smart_plugin_manager/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_products_smart_plugin_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"smart_plugin_manager"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/domains/create_status_dsa(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_status_dsa_install_domains_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[6,"create_status_dsa"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /create_ticket(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_ticket_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"create_ticket"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /create_zendesk_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_zendesk_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"create_zendesk_user"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /cwv_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cwv_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cwv_reports"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /dashboard_layouts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_layouts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboard_layouts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /deploys/data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_deploys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"deploys"],[2,[7,"/"],[2,[6,"data"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/backup_points/data(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_install_checkpoints_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"backup_points"],[2,[7,"/"],[2,[6,"data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /datacenters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const datacenters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"datacenters"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const default_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /deploys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deploys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"deploys"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /developer_tools(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const developer_tools_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"developer_tools"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /addons/global_edge_security/:install_name/disable/:uuid(.:format)
 * @param {any} install_name
 * @param {any} uuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_addons_global_edge_security_path = __jsr.r({"install_name":{"r":true},"uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"global_edge_security"],[2,[7,"/"],[2,[3,"install_name"],[2,[7,"/"],[2,[6,"disable"],[2,[7,"/"],[2,[3,"uuid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /products/global_edge_security/:install_name/disable/:uuid(.:format)
 * @param {any} install_name
 * @param {any} uuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_products_global_edge_security_path = __jsr.r({"install_name":{"r":true},"uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"global_edge_security"],[2,[7,"/"],[2,[3,"install_name"],[2,[7,"/"],[2,[6,"disable"],[2,[7,"/"],[2,[3,"uuid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /domains/:domain_id/domain_redirects/:id(.:format)
 * @param {any} domain_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const domain_domain_redirect_path = __jsr.r({"domain_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"domain_id"],[2,[7,"/"],[2,[6,"domain_redirects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/domains_and_redirects/:domain_id/refresh_status_gi(.:format)
 * @param {any} install_id
 * @param {any} domain_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const domains_refresh_status_gi_path = __jsr.r({"install_id":{"r":true},"domain_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains_and_redirects"],[2,[7,"/"],[2,[3,"domain_id"],[2,[7,"/"],[2,[6,"refresh_status_gi"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/backup_points/download(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_install_checkpoints_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"backup_points"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_certificates/:ssl_certificate_id/ssl_csr/download(.:format)
 * @param {any} install_id
 * @param {any} ssl_certificate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_install_ssl_certificate_ssl_csr_path = __jsr.r({"install_id":{"r":true},"ssl_certificate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_certificates"],[2,[7,"/"],[2,[3,"ssl_certificate_id"],[2,[7,"/"],[2,[6,"ssl_csr"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ecommerce(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecommerce_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecommerce"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ecommerce/features(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecommerce_features_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecommerce"],[2,[7,"/"],[2,[6,"features"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ecommerce_performance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecommerce_performance_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecommerce_performance"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ecommerce_solution(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecommerce_solution_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecommerce_solution"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_billing_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/subscription/edit(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_billing_account_subscription_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"subscription"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /domains/:domain_id/domain_redirects/:id/edit(.:format)
 * @param {any} domain_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_domain_domain_redirect_path = __jsr.r({"domain_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"domain_id"],[2,[7,"/"],[2,[6,"domain_redirects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_certificates/:ssl_certificate_id/ssl_csr/edit(.:format)
 * @param {any} install_id
 * @param {any} ssl_certificate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_install_ssl_certificate_ssl_csr_path = __jsr.r({"install_id":{"r":true},"ssl_certificate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_certificates"],[2,[7,"/"],[2,[3,"ssl_certificate_id"],[2,[7,"/"],[2,[6,"ssl_csr"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/edit/email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[2,[7,"/"],[2,[6,"email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /addons/genesis_blocks_pro/:install_name(.:format)
 * @param {any} install_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_addons_genesis_blocks_pro_path = __jsr.r({"install_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"genesis_blocks_pro"],[2,[7,"/"],[2,[3,"install_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /addons/genesis_custom_blocks_pro/:install_name(.:format)
 * @param {any} install_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_addons_genesis_custom_blocks_pro_path = __jsr.r({"install_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"genesis_custom_blocks_pro"],[2,[7,"/"],[2,[3,"install_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /addons/global_edge_security/:install_name/enable(.:format)
 * @param {any} install_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_addons_global_edge_security_path = __jsr.r({"install_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"global_edge_security"],[2,[7,"/"],[2,[3,"install_name"],[2,[7,"/"],[2,[6,"enable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /products/genesis_blocks_pro/:install_name(.:format)
 * @param {any} install_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_products_genesis_blocks_pro_path = __jsr.r({"install_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"genesis_blocks_pro"],[2,[7,"/"],[2,[3,"install_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/genesis_custom_blocks_pro/:install_name(.:format)
 * @param {any} install_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_products_genesis_custom_blocks_pro_path = __jsr.r({"install_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"genesis_custom_blocks_pro"],[2,[7,"/"],[2,[3,"install_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/global_edge_security/:install_name/enable(.:format)
 * @param {any} install_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_products_global_edge_security_path = __jsr.r({"install_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"global_edge_security"],[2,[7,"/"],[2,[3,"install_name"],[2,[7,"/"],[2,[6,"enable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/domains/entri_connect_jwt(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const entri_connect_jwt_install_domains_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[6,"entri_connect_jwt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/domains/entri_connect_log(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const entri_connect_log_install_domains_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[6,"entri_connect_log"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /usage/env_usage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const env_usage_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"usage"],[2,[7,"/"],[2,[6,"env_usage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /installs/:id/error_logs_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const error_logs_data_install_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"error_logs_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:id/error_logs_feed_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const error_logs_feed_data_install_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"error_logs_feed_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:id/error_logs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const error_logs_install_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"error_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/reports/failed_payments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const failed_payments_admin_billing_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"failed_payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /addons/global_edge_security/firewall_events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const firewall_events_addons_global_edge_security_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"global_edge_security"],[2,[7,"/"],[2,[6,"firewall_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /addons/global_edge_security/firewall_events_page(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const firewall_events_page_addons_global_edge_security_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"global_edge_security"],[2,[7,"/"],[2,[6,"firewall_events_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/global_edge_security/firewall_events_page(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const firewall_events_page_products_global_edge_security_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"global_edge_security"],[2,[7,"/"],[2,[6,"firewall_events_page"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/global_edge_security/firewall_events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const firewall_events_products_global_edge_security_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"global_edge_security"],[2,[7,"/"],[2,[6,"firewall_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ftu_survey(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ftu_survey_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ftu_survey"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /generate_support_pin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_support_pin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"generate_support_pin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/domains/get_status_dsa(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_status_dsa_install_domains_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[6,"get_status_dsa"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /profile/github_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const github_action_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"github_action"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/domains_and_redirects/:domain_id/global_ingress(.:format)
 * @param {any} install_id
 * @param {any} domain_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const global_ingress_domain_index_path = __jsr.r({"install_id":{"r":true},"domain_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains_and_redirects"],[2,[7,"/"],[2,[3,"domain_id"],[2,[7,"/"],[2,[6,"global_ingress"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/domains_and_redirects/global_ingress/status(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const global_ingress_domain_show_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains_and_redirects"],[2,[7,"/"],[2,[6,"global_ingress"],[2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/domains_and_redirects/:domain_id/global_ingress(.:format)
 * @param {any} install_id
 * @param {any} domain_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const global_ingress_domain_update_path = __jsr.r({"install_id":{"r":true},"domain_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains_and_redirects"],[2,[7,"/"],[2,[3,"domain_id"],[2,[7,"/"],[2,[6,"global_ingress"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/domains_and_redirects/global_ingress/:domain_id/wildcard_status(.:format)
 * @param {any} install_id
 * @param {any} domain_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const global_ingress_domain_wildcard_status_update_path = __jsr.r({"install_id":{"r":true},"domain_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains_and_redirects"],[2,[7,"/"],[2,[6,"global_ingress"],[2,[7,"/"],[2,[3,"domain_id"],[2,[7,"/"],[2,[6,"wildcard_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /google_authenticator_setup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const google_authenticator_setup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"google_authenticator_setup"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /group_notes_migration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const group_notes_migration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"group_notes_migration"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /group_notes_migration/delete_groups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const group_notes_migration_delete_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"group_notes_migration"],[2,[7,"/"],[2,[6,"delete_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /group_notes_migration/migrate_groups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const group_notes_migration_migrate_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"group_notes_migration"],[2,[7,"/"],[2,[6,"migrate_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /growth_suite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const growth_suite_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"growth_suite"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /growth_suite/api/v1/early_access_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const growth_suite_api_v1_early_access_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"growth_suite"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"early_access_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /growth_suite/api/v1/licenses/license_entitlement(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const growth_suite_api_v1_licenses_license_entitlement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"growth_suite"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"licenses"],[2,[7,"/"],[2,[6,"license_entitlement"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /headless(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const headless_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"headless"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /headless_solution(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const headless_solution_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"headless_solution"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /site_notices/:id/hide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hide_site_notice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"site_notices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"hide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/impersonate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const impersonate_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"impersonate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_certificates/import_cert(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const import_cert_install_ssl_certificates_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_certificates"],[2,[7,"/"],[2,[6,"import_cert"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_certificates/index_data(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_data_install_ssl_certificates_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_certificates"],[2,[7,"/"],[2,[6,"index_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/advanced(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_advanced_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"advanced"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/advanced(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_advanced_update_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"advanced"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/migrate-my-site(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_automated_migration_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"migrate-my-site"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /install_available(/:name)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_available_path = __jsr.r({"name":{},"format":{}}, [2,[7,"/"],[2,[6,"install_available"],[2,[1,[2,[7,"/"],[3,"name"]]],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/cache_dashboard(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_cache_dashboard_index_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"cache_dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/backup_points(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_checkpoints_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"backup_points"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/disable_multisite(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_disable_multisite_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"disable_multisite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/domains(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_domains_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/dynamic_plugin_loading(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_dynamic_plugin_loading_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"dynamic_plugin_loading"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/dynamic_plugin_loading/not_found(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_dynamic_plugin_loading_not_found_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"dynamic_plugin_loading"],[2,[7,"/"],[2,[6,"not_found"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ecommerce_payments(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_ecommerce_payments_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ecommerce_payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/enable_multisite(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_enable_multisite_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"enable_multisite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/environment_info/info(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_environment_info_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"environment_info"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/users_summary(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_ftp_users_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"users_summary"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/git_push(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_git_push_developers_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"git_push"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/go_live_checklist(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_go_live_checklist_index_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"go_live_checklist"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/graphs(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_graphs_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"graphs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/install_operation_statuses(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_install_operation_statuses_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"install_operation_statuses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_name_or_id_or_uid/launch_wp_admin(.:format)
 * @param {any} install_name_or_id_or_uid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_launch_wp_admin_path = __jsr.r({"install_name_or_id_or_uid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_name_or_id_or_uid"],[2,[7,"/"],[2,[6,"launch_wp_admin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/maintenance_mode(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_maintenance_mode_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"maintenance_mode"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/manual_cron(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_manual_cron_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"manual_cron"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/migrate-my-site/install-wpesm(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_migrate_my_site_install_wpesm_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"migrate-my-site"],[2,[7,"/"],[2,[6,"install-wpesm"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/migrate-my-site/wpesm-status(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_migrate_my_site_wpesm_status_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"migrate-my-site"],[2,[7,"/"],[2,[6,"wpesm-status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/migration(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_migration_checklists_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"migration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/multisite(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_multisite_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"multisite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/install_name_available(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_name_available_installs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[6,"install_name_available"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /install_operation_status/add_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_operation_status_add_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"install_operation_status"],[2,[7,"/"],[2,[6,"add_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /install_operation_status/update_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_operation_status_update_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"install_operation_status"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/performance(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_performance_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"performance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/performance/reports(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_performance_history_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"performance"],[2,[7,"/"],[2,[6,"reports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/performance/reports/:test_id(.:format)
 * @param {any} install_id
 * @param {any} test_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_performance_report_path = __jsr.r({"install_id":{"r":true},"test_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"performance"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[3,"test_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/perm_reload_apply(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_perm_reload_apply_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"perm_reload_apply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/plugins_and_themes(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_plugins_and_themes_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"plugins_and_themes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/redirect_bots(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_redirect_bots_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"redirect_bots"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/reset_file_permissions(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_reset_file_permissions_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"reset_file_permissions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/redirect_rules/:id(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_rewrite_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"redirect_rules"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/redirect_rules(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_rewrites_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"redirect_rules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/slow_query_logs(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_slow_query_logs_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"slow_query_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/smart_search_insights(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_smart_search_insights_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"smart_search_insights"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_certificates/:id(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_ssl_certificate_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_certificates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_certificates/:ssl_certificate_id/ssl_csr(.:format)
 * @param {any} install_id
 * @param {any} ssl_certificate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_ssl_certificate_ssl_csr_path = __jsr.r({"install_id":{"r":true},"ssl_certificate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_certificates"],[2,[7,"/"],[2,[3,"ssl_certificate_id"],[2,[7,"/"],[2,[6,"ssl_csr"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_certificates(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_ssl_certificates_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_certificates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_csr(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_ssl_csr_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_csr"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_orders(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_ssl_purchases_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/traffic_insights(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_traffic_insights_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"traffic_insights"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/transfer_requests(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_transfer_requests_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"transfer_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/utilities(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_utilities_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"utilities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/woocommerce_installed(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_woocommerce_installed_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"woocommerce_installed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/wp_cli(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_wp_cli_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"wp_cli"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/wp_core/deferral(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_wp_core_deferral_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"wp_core"],[2,[7,"/"],[2,[6,"deferral"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/wp_core/deferral(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_wp_core_deferral_state_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"wp_core"],[2,[7,"/"],[2,[6,"deferral"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/wp_core/upgrade(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_wp_core_upgrade_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"wp_core"],[2,[7,"/"],[2,[6,"upgrade"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/wren(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const install_wren_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"wren"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const installs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"installs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/invoices/:id/internalize_invoice(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internalize_invoice_admin_billing_account_invoice_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"internalize_invoice"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invoice_details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_details_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoice_details"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /labs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const labs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"labs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /labs/disable(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const labs_disable_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"labs"],[2,[7,"/"],[2,[6,"disable"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /labs/enable(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const labs_enable_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"labs"],[2,[7,"/"],[2,[6,"enable"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /legacy_plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const legacy_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"legacy_plans"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_orders/lets_encrypt(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lets_encrypt_install_ssl_purchases_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_orders"],[2,[7,"/"],[2,[6,"lets_encrypt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /letter_opener
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letter_opener_web_path = __jsr.r({}, [2,[7,"/"],[6,"letter_opener"]]);

/**
 * Generates rails route to
 * /letter_opener/clear(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letter_opener_web_clear_letters_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"letter_opener"]],[7,"/"]],[2,[6,"clear"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /letter_opener/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letter_opener_web_delete_letter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"letter_opener"]],[7,"/"]],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /letter_opener/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letter_opener_web_letters_path = __jsr.r({}, [2,[2,[7,"/"],[6,"letter_opener"]],[7,"/"]]);

/**
 * Generates rails route to
 * /letter_opener/:id(/:style)(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letter_opener_web_letter_path = __jsr.r({"id":{"r":true},"style":{},"format":{}}, [2,[2,[2,[7,"/"],[6,"letter_opener"]],[7,"/"]],[2,[3,"id"],[2,[1,[2,[7,"/"],[3,"style"]]],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:id/load_plan_details_header(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const load_plan_details_header_admin_billing_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"load_plan_details_header"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /addons/smart_plugin_manager/local_sites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const local_sites_addons_smart_plugin_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"smart_plugin_manager"],[2,[7,"/"],[2,[6,"local_sites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/smart_plugin_manager/local_sites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const local_sites_products_smart_plugin_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"smart_plugin_manager"],[2,[7,"/"],[2,[6,"local_sites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:id/logs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const logs_install_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /logs_overview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const logs_overview_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"logs_overview"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /logs_overview/logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const logs_overview_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"logs_overview"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /manage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /manage/history(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_history_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"manage"],[2,[7,"/"],[2,[6,"history"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /managed_migration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const managed_migration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"managed_migration"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /managed_migration_success(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const managed_migration_success_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"managed_migration_success"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /profile/mfa_authentication(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mfa_authentication_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"mfa_authentication"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /modify_addons_esm(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modify_addons_esm_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modify_addons_esm"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /modify_addons_esm/modify_subscription(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modify_addons_esm_modify_subscription_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modify_addons_esm"],[2,[7,"/"],[2,[6,"modify_subscription"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /modify_addons_esm/plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modify_addons_esm_plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modify_addons_esm"],[2,[7,"/"],[2,[6,"plans"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /modify_addons_esm/simple_subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modify_addons_esm_simple_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modify_addons_esm"],[2,[7,"/"],[2,[6,"simple_subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /modify_addons_esm/subscription_plans_v2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modify_addons_esm_subscription_plans_v2_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modify_addons_esm"],[2,[7,"/"],[2,[6,"subscription_plans_v2"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /modify_addons_esm/subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modify_addons_esm_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modify_addons_esm"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /modify_addons_esm/update_addons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modify_addons_esm_update_addons_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modify_addons_esm"],[2,[7,"/"],[2,[6,"update_addons"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /modify_plan(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modify_plan_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modify_plan"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /modify_plan_checkout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const modify_plan_checkout_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"modify_plan_checkout"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /my_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_account"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /my_account/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_account_cancel_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_account"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_account/cancel_reasons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_account_cancel_reasons_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_account"],[2,[7,"/"],[2,[6,"cancel_reasons"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_account/contact(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_account_contact_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_account"],[2,[7,"/"],[2,[6,"contact"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_account/fullstory_notification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_account_fullstory_notification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_account"],[2,[7,"/"],[2,[6,"fullstory_notification"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /my_account/pending_refunds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const my_account_pending_refunds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"my_account"],[2,[7,"/"],[2,[6,"pending_refunds"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/adjustments/new(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_billing_account_adjustment_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"adjustments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/payments/new(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_billing_account_payment_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/purchases/new(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_billing_account_purchase_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"purchases"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/refunds/new(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_billing_account_refund_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"refunds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/subscription/new(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_billing_account_subscription_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"subscription"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/adjustment_reasons/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_billing_adjustment_reason_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"adjustment_reasons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/coupons/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_billing_coupon_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"coupons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/products/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_billing_product_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /csp_reports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_csp_report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"csp_reports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /deploys/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_deploys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"deploys"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/domains/new-domain(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_domain_install_domains_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[6,"new-domain"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_install_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_csr/new(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_install_ssl_csr_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_csr"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /new_ticket(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ticket_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"new_ticket"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/invitation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const no_accounts_root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/migration/import_notify_support(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notify_import_install_migration_checklists_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"migration"],[2,[7,"/"],[2,[6,"import_notify_support"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /okta_verify_setup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const okta_verify_setup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"okta_verify_setup"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /omni_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const omni_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"omni_search"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /onboarding/sandbox_sites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const onboarding_sandbox_sites_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"sandbox_sites"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /onboarding/sandbox_sites/log_activity(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const onboarding_sandbox_sites_log_activity_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"onboarding"],[2,[7,"/"],[2,[6,"sandbox_sites"],[2,[7,"/"],[2,[6,"log_activity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/authorize(/:resource)(/:id)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_path = __jsr.r({"resource":{},"id":{},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"authorize"],[2,[1,[2,[7,"/"],[3,"resource"]]],[2,[1,[2,[7,"/"],[3,"id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/activations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_activations_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"activations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/addons/entitlement(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_addons_entitlement_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"entitlement"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/admin/authorize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_admin_authorize_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"authorize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/admin/sync(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_admin_sync_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sync"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/admin/sync_remote_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_admin_sync_remote_user_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"sync_remote_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/authorize(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_authorize_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"authorize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/authorize/entities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_authorize_entities_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"authorize"],[2,[7,"/"],[2,[6,"entities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_customers_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/entitlements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_entitlements_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"entitlements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/environments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_environments_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"environments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/installs/:install_id/git_push_developers/:id(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_install_git_push_developer_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"git_push_developers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/installs/:install_id/git_push_developers(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_install_git_push_developers_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"git_push_developers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/installs/:install_id/git_push_developers/data(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_install_git_push_developers_data_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"git_push_developers"],[2,[7,"/"],[2,[6,"data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/user/:user_id/lifecycle/reactivate(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_user_path = __jsr.r({"user_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"lifecycle"],[2,[7,"/"],[2,[6,"reactivate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/user/email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_user_email_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/user/factors/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_user_factor_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"factors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/user/factors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_user_factors_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"factors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/user/factors/google/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_user_google_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"factors"],[2,[7,"/"],[2,[6,"google"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/user/factors/google(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_user_google_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"factors"],[2,[7,"/"],[2,[6,"google"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/user/factors/okta(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_user_okta_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"factors"],[2,[7,"/"],[2,[6,"okta"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/user/phone(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_user_phone_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"phone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/user/factors/sms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_user_sm_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"factors"],[2,[7,"/"],[2,[6,"sms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/private/user/factors/sms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_private_user_sms_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"private"],[2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"factors"],[2,[7,"/"],[2,[6,"sms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/*path(.:format)
 * @param {any} path
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_path = __jsr.r({"path":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[5,[3,"path"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_account_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/accounts/:account_id/account_users/:id(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_account_account_user_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"account_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/accounts/:account_id/account_users(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_account_account_users_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"account_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_accounts_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/installs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_install_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/installs/:install_id/backups/:id(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_install_backup_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"backups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/installs/:install_id/backups(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_install_backups_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"backups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/installs/:install_id/domains/:id(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_install_domain_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/installs/:install_id/domains(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_install_domains_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/installs/:install_id/purge_cache(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_install_purge_cache_index_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"purge_cache"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/installs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_installs_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"installs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/sites/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_site_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/sites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_sites_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"sites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/ssh_keys/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_ssh_key_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"ssh_keys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/ssh_keys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_ssh_keys_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"ssh_keys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_status_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v0/user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v0_user_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v0"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/*path(.:format)
 * @param {any} path
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_path = __jsr.r({"path":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[5,[3,"path"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_account_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/accounts/:account_id/account_users/:id(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_account_account_user_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"account_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/accounts/:account_id/account_users(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_account_account_users_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"account_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_accounts_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/installs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_install_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/installs/:install_id/backups/:id(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_install_backup_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"backups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/installs/:install_id/backups(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_install_backups_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"backups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/installs/:install_id/domains/:id(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_install_domain_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/installs/:install_id/domains(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_install_domains_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/installs/:install_id/purge_cache(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_install_purge_cache_index_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"purge_cache"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/installs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_installs_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"installs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/sites/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_site_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/sites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_sites_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"sites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/ssh_keys/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_ssh_key_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"ssh_keys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/ssh_keys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_ssh_keys_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"ssh_keys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_status_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /papi/v1/user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const papi_v1_user_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"papi"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /password_reset_required(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_reset_required_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password_reset_required"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /payment_terms_signed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_terms_signed_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"payment_terms_signed"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/invoices/:id/pdf/:type(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {any} type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_admin_billing_account_invoice_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pdf"],[2,[7,"/"],[2,[3,"type"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/pdf/:type(.:format)
 * @param {any} id
 * @param {any} type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pdf_invoice_path = __jsr.r({"id":{"r":true},"type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pdf"],[2,[7,"/"],[2,[3,"type"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:id/pending_refunds(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_refunds_admin_billing_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pending_refunds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /profile/personal-details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const personal_details_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"personal-details"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /installs/:id/phpmyadmin(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phpmyadmin_install_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"phpmyadmin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /phpupgradeguide(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const phpupgradeguide_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"phpupgradeguide"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /plan_dropdown(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_dropdown_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plan_dropdown"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /plans/:plan_id/ticket(.:format)
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_ticket_path = __jsr.r({"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"ticket"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /plan_usage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_usage_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plan_usage"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"plans"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /portal_dev_tools(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_dev_tools_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"portal_dev_tools"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /portal_dev_tools/install_config_hash(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_dev_tools_install_config_hash_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"portal_dev_tools"],[2,[7,"/"],[2,[6,"install_config_hash"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/view_components/*path(.:format)
 * @param {any} path
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_view_component_path = __jsr.r({"path":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"view_components"],[2,[7,"/"],[2,[5,[3,"path"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/view_components(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_view_components_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"view_components"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /privatebin/build_url(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const privatebin_build_url_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"privatebin"],[2,[7,"/"],[2,[6,"build_url"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /privatebin/managed_migration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const privatebin_managed_migration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"privatebin"],[2,[7,"/"],[2,[6,"managed_migration"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /products/aggregation_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_aggregation_service_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"aggregation_service"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /products/application_performance/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_application_performance_about_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"application_performance"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/atlas_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_atlas_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"atlas_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /products/atlas_search/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_atlas_search_about_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"atlas_search"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/atomic_blocks_pro(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_atomic_blocks_pro_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"atomic_blocks_pro"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /products/atomic_blocks_pro/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_atomic_blocks_pro_about_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"atomic_blocks_pro"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/elasticsearch(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_elasticsearch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"elasticsearch"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /products/genesis_blocks_pro(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_genesis_blocks_pro_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"genesis_blocks_pro"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /products/genesis_custom_blocks_pro(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_genesis_custom_blocks_pro_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"genesis_custom_blocks_pro"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /products/genesis_pro(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_genesis_pro_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"genesis_pro"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /products/genesis_pro/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_genesis_pro_about_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"genesis_pro"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/global_edge_security(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_global_edge_security_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"global_edge_security"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /products/instant_store_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_instant_store_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"instant_store_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /products/page_speed_boost(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_page_speed_boost_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"page_speed_boost"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /products/page_speed_boost/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_page_speed_boost_about_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"page_speed_boost"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/page_speed_boost/add(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_page_speed_boost_add_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"page_speed_boost"],[2,[7,"/"],[2,[6,"add"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/page_speed_boost/enable(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_page_speed_boost_enable_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"page_speed_boost"],[2,[7,"/"],[2,[6,"enable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/page_speed_boost/monitoring_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_page_speed_boost_monitoring_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"page_speed_boost"],[2,[7,"/"],[2,[6,"monitoring_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/page_speed_boost/troubleshoot/:install_name(.:format)
 * @param {any} install_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_page_speed_boost_troubleshoot_path = __jsr.r({"install_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"page_speed_boost"],[2,[7,"/"],[2,[6,"troubleshoot"],[2,[7,"/"],[2,[3,"install_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /products/smart_plugin_manager(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_smart_plugin_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"smart_plugin_manager"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /products/smart_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_smart_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"smart_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /products/smart_search_ai/about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const products_smart_search_ai_about_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"smart_search_ai"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /profile/security/*section(/*other)(.:format)
 * @param {any} section
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_sub_nav_path = __jsr.r({"section":{"r":true},"other":{},"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"security"],[2,[7,"/"],[2,[5,[3,"section"]],[2,[1,[2,[7,"/"],[5,[3,"other"]]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]);

/**
 * Generates rails route to
 * /usage/projected_visits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const projected_visits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"usage"],[2,[7,"/"],[2,[6,"projected_visits"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /promets/install_storage_metrics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const promets_install_storage_metrics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"promets"],[2,[7,"/"],[2,[6,"install_storage_metrics"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /promets/timeline(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const promets_timeline_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"promets"],[2,[7,"/"],[2,[6,"timeline"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /addons/smart_plugin_manager/provider_metadata(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const provider_metadata_addons_smart_plugin_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"smart_plugin_manager"],[2,[7,"/"],[2,[6,"provider_metadata"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/smart_plugin_manager/provider_metadata(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const provider_metadata_products_smart_plugin_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"smart_plugin_manager"],[2,[7,"/"],[2,[6,"provider_metadata"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api_access/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_api_key_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api_access"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api_access(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_api_keys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api_access"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /deploys/pull(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pull_deploys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"deploys"],[2,[7,"/"],[2,[6,"pull"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /purchase_summary(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_summary_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"purchase_summary"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/cache_dashboard/purge_network_cache(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purge_network_cache_install_cache_dashboard_index_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"cache_dashboard"],[2,[7,"/"],[2,[6,"purge_network_cache"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /deploys/push(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const push_deploys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"deploys"],[2,[7,"/"],[2,[6,"push"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /queue_check(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const queue_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"queue_check"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /quick_buy_addons(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const quick_buy_addons_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quick_buy_addons"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_orders/rapidssl(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rapidssl_install_ssl_purchases_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_orders"],[2,[7,"/"],[2,[6,"rapidssl"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/invoices/:id/recalculate_taxes(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recalculate_taxes_admin_billing_account_invoice_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"recalculate_taxes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /addons/smart_plugin_manager/redirect/*goto(.:format)
 * @param {any} goto
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const redirect_addons_smart_plugin_manager_path = __jsr.r({"goto":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"smart_plugin_manager"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[5,[3,"goto"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /products/smart_plugin_manager/redirect/*goto(.:format)
 * @param {any} goto
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const redirect_products_smart_plugin_manager_path = __jsr.r({"goto":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"smart_plugin_manager"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[5,[3,"goto"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/invoices/:id/refund(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refund_admin_billing_account_invoice_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refund"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/subscription/addon/:addon_id(.:format)
 * @param {any} account_id
 * @param {any} addon_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_addon_admin_billing_account_subscription_path = __jsr.r({"account_id":{"r":true},"addon_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"subscription"],[2,[7,"/"],[2,[6,"addon"],[2,[7,"/"],[2,[3,"addon_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/redirect_rules/reorder(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reorder_install_rewrites_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"redirect_rules"],[2,[7,"/"],[2,[6,"reorder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/resend_invitation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_invitation_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resend_invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/transfer_requests/reset_code(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reset_code_install_transfer_requests_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"transfer_requests"],[2,[7,"/"],[2,[6,"reset_code"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/reset_password_token(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reset_password_token_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reset_password_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /resque(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resque_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resque"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/backup_points/restore(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const restore_install_checkpoints_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"backup_points"],[2,[7,"/"],[2,[6,"restore"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /route_handlers/modify_plan(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const route_handlers_modify_plan_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"route_handlers"],[2,[7,"/"],[2,[6,"modify_plan"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sales_assisted_landing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sales_assisted_landing_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sales_assisted_landing"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /profile/seamless_login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const seamless_login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"seamless_login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /seamless_login_access(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const seamless_login_access_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"seamless_login_access"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/billing/coupons/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_admin_billing_coupons_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"coupons"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/domains/search_and_replace_domain(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_and_replace_domain_install_domains_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[6,"search_and_replace_domain"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /profile/security(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const security_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"security"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/cache_dashboard/set_exclusions(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_exclusions_install_cache_dashboard_index_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"cache_dashboard"],[2,[7,"/"],[2,[6,"set_exclusions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /domains/:domain_id/domain_redirects/:id/set_redirect(.:format)
 * @param {any} domain_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_redirect_domain_domain_redirect_path = __jsr.r({"domain_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"domains"],[2,[7,"/"],[2,[3,"domain_id"],[2,[7,"/"],[2,[6,"domain_redirects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_redirect"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/set_roles(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const set_roles_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_roles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /profile/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:account_id/invoices/:id/show_historic(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_historic_admin_billing_account_invoice_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_historic"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/show_historic(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_historic_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_historic"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sidekiq(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sidekiq"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signout"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signup/account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signup_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup"],[2,[7,"/"],[2,[6,"account"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signup/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signup_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signup/coupon(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signup_coupon_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup"],[2,[7,"/"],[2,[6,"coupon"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signup/esm_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signup_esm_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup"],[2,[7,"/"],[2,[6,"esm_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signup/genpro(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signup_genpro_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup"],[2,[7,"/"],[2,[6,"genpro"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signup/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signup_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signup/recalculate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signup_recalculate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup"],[2,[7,"/"],[2,[6,"recalculate"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signup/recalculate_account_fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signup_recalculate_account_fields_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup"],[2,[7,"/"],[2,[6,"recalculate_account_fields"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signups/api/v1/account/:account_id(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signups_api_v1_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[3,"account_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /signups/api/v1/account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signups_api_v1_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"account"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /signups/api/v1/docs/swagger(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signups_api_v1_docs_swagger_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"docs"],[2,[7,"/"],[2,[6,"swagger"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /signups/api/v1/status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signups_api_v1_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /profile/single_sign_on(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const single_sign_on_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"single_sign_on"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sites/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const site_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /site_environments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const site_environments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"site_environments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /installs/:id/site_logs_feed_data(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const site_logs_feed_data_install_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"site_logs_feed_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/site_monitors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const site_monitors_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"site_monitors"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sites/:site_uuid/transfer(.:format)
 * @param {any} site_uuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const site_transfer_path = __jsr.r({"site_uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[3,"site_uuid"],[2,[7,"/"],[2,[6,"transfer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /sites/add_site_and_install(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_add_site_and_install_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[6,"add_site_and_install"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sites/add_site_and_install/account_source_environments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_add_site_and_install_account_source_environments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[6,"add_site_and_install"],[2,[7,"/"],[2,[6,"account_source_environments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sites/add_site_and_install/move(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_add_site_and_install_move_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[6,"add_site_and_install"],[2,[7,"/"],[2,[6,"move"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sites/add_site_and_install/subscription_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_add_site_and_install_subscription_data_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[6,"add_site_and_install"],[2,[7,"/"],[2,[6,"subscription_data"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sites_and_storage_used(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_and_storage_used_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites_and_storage_used"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /sites/data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_data_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[6,"data"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sites/datacenters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_datacenters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[6,"datacenters"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sites/env_name_suggestions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_env_name_suggestions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[6,"env_name_suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sites/manage_tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_manage_tags_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[6,"manage_tags"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sites/managed_migration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_managed_migration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[6,"managed_migration"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bulk_updates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_management_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bulk_updates"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /bulk_updates/upload_plugin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_management_upload_plugin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bulk_updates"],[2,[7,"/"],[2,[6,"upload_plugin"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bulk_updates/upload_theme(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_management_upload_theme_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bulk_updates"],[2,[7,"/"],[2,[6,"upload_theme"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sites/manual_migration(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_manual_migration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[6,"manual_migration"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sites/primary_environment/:list_of_environments(.:format)
 * @param {any} list_of_environments
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_primary_environment_path = __jsr.r({"list_of_environments":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[6,"primary_environment"],[2,[7,"/"],[2,[3,"list_of_environments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sites/primary_environment_v2/:environment_name1(.:format)
 * @param {any} environment_name1
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_primary_environment_v2_path = __jsr.r({"environment_name1":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[6,"primary_environment_v2"],[2,[7,"/"],[2,[3,"environment_name1"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sites_transfer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_transfer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites_transfer"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /sites_transfer/accept(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_transfer_accept_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites_transfer"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sites_transfer/account_geo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_transfer_account_geo_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites_transfer"],[2,[7,"/"],[2,[6,"account_geo"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sites_transfer/available_sites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_transfer_available_sites_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites_transfer"],[2,[7,"/"],[2,[6,"available_sites"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sites_transfer/log_activity(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_transfer_log_activity_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites_transfer"],[2,[7,"/"],[2,[6,"log_activity"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sites_transfer/migration_required(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_transfer_migration_required_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites_transfer"],[2,[7,"/"],[2,[6,"migration_required"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sites/update_show_environments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sites_update_show_environments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sites"],[2,[7,"/"],[2,[6,"update_show_environments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /split(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const split_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"split"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /profile/ssh_keys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ssh_keys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profile"],[2,[7,"/"],[2,[6,"ssh_keys"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ssl_csrs/:ssl_csr_id/ssl_csr_files/:id(.:format)
 * @param {any} ssl_csr_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ssl_csr_ssl_csr_file_path = __jsr.r({"ssl_csr_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ssl_csrs"],[2,[7,"/"],[2,[3,"ssl_csr_id"],[2,[7,"/"],[2,[6,"ssl_csr_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_certificates/ssl_third_party(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ssl_third_party_install_ssl_certificates_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_certificates"],[2,[7,"/"],[2,[6,"ssl_third_party"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_certificates/ssl_third_party/validate(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ssl_third_party_validate_install_ssl_certificates_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_certificates"],[2,[7,"/"],[2,[6,"ssl_third_party"],[2,[7,"/"],[2,[6,"validate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sso/authenticate_wp_admin_access(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sso_authenticate_wp_admin_access_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sso"],[2,[7,"/"],[2,[6,"authenticate_wp_admin_access"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /addons/smart_plugin_manager/start_trial(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const start_trial_addons_smart_plugin_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"addons"],[2,[7,"/"],[2,[6,"smart_plugin_manager"],[2,[7,"/"],[2,[6,"start_trial"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /products/smart_plugin_manager/start_trial(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const start_trial_products_smart_plugin_manager_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"smart_plugin_manager"],[2,[7,"/"],[2,[6,"start_trial"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /usage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stats_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"usage"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /usage/extensions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stats_addons_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"usage"],[2,[7,"/"],[2,[6,"extensions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /usage/environments/:id/graphs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stats_environment_graphs_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"usage"],[2,[7,"/"],[2,[6,"environments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"graphs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /usage/environments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stats_environments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"usage"],[2,[7,"/"],[2,[6,"environments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /usage/:id/graphs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stats_graphs_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"usage"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"graphs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /usage/trends(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stats_trends_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"usage"],[2,[7,"/"],[2,[6,"trends"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /omni_search/submit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const submit_omni_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"omni_search"],[2,[7,"/"],[2,[6,"submit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /support(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const support_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"support"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const switch_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /technical_contacts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const technical_contacts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"technical_contacts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /terms_and_conditions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terms_and_conditions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"terms_and_conditions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /theme_favorites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const theme_favorites_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"theme_favorites"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /themes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const themes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"themes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /themes/install(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const themes_install_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"themes"],[2,[7,"/"],[2,[6,"install"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/transfer_requests/transferable_to_live(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transferable_to_live_install_transfer_requests_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"transfer_requests"],[2,[7,"/"],[2,[6,"transferable_to_live"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unauthenticated_root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /admin/users/unimpersonate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unimpersonate_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unimpersonate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /unlink_zendesk_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlink_zendesk_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unlink_zendesk_user"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/unlock(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlock_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /update_billing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_billing_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"update_billing"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:id/contact(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_contact_admin_billing_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contact"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:id/update_environment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_environment_install_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_environment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_certificates/update_general_settings(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_general_settings_install_ssl_certificates_path = __jsr.r({"install_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_certificates"],[2,[7,"/"],[2,[6,"update_general_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:id/update_group(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_group_install_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_group"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/go_live_checklist/update(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_install_go_live_checklist_index_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"go_live_checklist"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing/accounts/:id/update_multi_currency(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_multi_currency_admin_billing_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_multi_currency"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:id/update_php_version(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_php_version_install_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_php_version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /seamless_login_access/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_seamless_login_access_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"seamless_login_access"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/edit/email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_user_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[2,[7,"/"],[2,[6,"email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/edit/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/edit/phone(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_user_phone_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[2,[7,"/"],[2,[6,"phone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/ssl_certificates/:id/upload_cert(.:format)
 * @param {any} install_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_cert_install_ssl_certificate_path = __jsr.r({"install_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"ssl_certificates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_cert"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:id/usage_stats(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const usage_stats_install_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"usage_stats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/cache_dashboard/use_object_cache(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const use_object_cache_install_cache_dashboard_index_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"cache_dashboard"],[2,[7,"/"],[2,[6,"use_object_cache"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/oktaoauth(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_oktaoauth_omniauth_authorize_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"oktaoauth"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/oktaoauth/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_oktaoauth_omniauth_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"oktaoauth"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_edit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/edit/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_edit_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/entitlements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_entitlements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"entitlements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password/reset(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_password_reset_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"reset"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_token_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /wat_access(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wat_access_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"wat_access"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /installs/wp_core/batch_deferral_state(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wp_core_batch_deferral_state_installs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[6,"wp_core"],[2,[7,"/"],[2,[6,"batch_deferral_state"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /wp_core_verify_checksums(/:install_name)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wp_core_verify_checksums_path = __jsr.r({"install_name":{},"format":{}}, [2,[7,"/"],[2,[6,"wp_core_verify_checksums"],[2,[1,[2,[7,"/"],[3,"install_name"]]],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /deploys/wp_db_tables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wp_db_tables_deploys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"deploys"],[2,[7,"/"],[2,[6,"wp_db_tables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /installs/:install_id/go_live_checklist/wp_stats(.:format)
 * @param {any} install_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wp_stats_install_go_live_checklist_index_path = __jsr.r({"install_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"installs"],[2,[7,"/"],[2,[3,"install_id"],[2,[7,"/"],[2,[6,"go_live_checklist"],[2,[7,"/"],[2,[6,"wp_stats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /wpe_wc_toolbox(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wpe_wc_toolbox_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"wpe_wc_toolbox"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /wpengine_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wpengine_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"wpengine_status"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /zendesk_oauth(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const zendesk_oauth_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"zendesk_oauth"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /zendesk_oauth_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const zendesk_oauth_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"zendesk_oauth_callback"],[1,[2,[8,"."],[3,"format"]]]]]);

