import { DefaultRequestBody, rest } from 'msw';
import {
  addDelay,
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { install_environment_info_path } from 'railsRoutes';
import { UpdateResponse } from 'componentsDir/install/repositories/UtilitiesApiClient';
import { V1alpha1DailyUsageMetricsSeries } from 'platform_one_api_client';
import mockEnvironmentInfo from './data/mockEnvironmentInfo';
import mockPasswordSettings from './data/mockPasswordSettings';
import {
  mockDisabledResponse,
  mockPasswordResponse,
  mockPasswordResponseError,
} from './data/mockPasswordResponse';
import { mockP1aMetricsData, mockP1aMetricsRollUpData } from './data/mockData';

// Use page url query params to respond with different mock data.
// Example usages:
// http://localhost:3000/installs/{installName}                             |   Default environment data.
// http://localhost:3000/installs/{installName}?sshLoginDisabled=true       |   SSH login disabled.
// http://localhost:3000/installs/{installName}?sshKeysEmpty=true           |   No SSH keys added.
// http://localhost:3000/installs/{installName}?sshPlanNotSupported=true    |   Plan doesn't support SSH.
// http://localhost:3000/installs/{installName}?primaryDomainNotSet=true    |   Primary domain not set.
// http://localhost:3000/installs/{installName}?hidePrimaryDomain=true      |   Hides the primary domain.
// http://localhost:3000/installs/{installName}?canDeleteInstall=false      |   Prevents ability to delete install.
// http://localhost:3000/installs/{installName}?transferable=true           |   Flags current install as transferable.
// http://localhost:3000/installs/{installName}?sandbox=true                |   Flags current install as sandbox.
// http://localhost:3000/installs/{installName}?passwordDisabled=true       |   Disables password protection.
// http://localhost:3000/installs/{installName}?passwordChangeError=true    |   Returns an error for password change.
// http://localhost:3000/installs/{installName}?cacheError=true             |   Returns an error when clearing all cache.
// http://localhost:3000/installs/{installName}?activeSiteMonitoring=true   |   Active activation for an install.
// http://localhost:3000/installs/{installName}?activePSB=true              |   Active PSB activation for an install.
// http://localhost:3000/installs/{installName}?transferMigrating=true      |   Install is migrating after a transfer.

let storageRefreshExpectedTime: string = null;
const SUCCESSFUL_REFRESH_TIME = '2023-10-09T20:15:40.031Z';

export const MSW_PARAMS = {
  ALL_CACHES_LAST_CLEARED_AT_RETURNS_DATE: 'allCachesLastClearedAtReturnsDate',
};

const handlers = [
  rest.get<DefaultRequestBody, { installName: string }>(
    install_environment_info_path(':installName'),
    async (req, res, ctx) => {
      const { installName } = req.params;
      const environmentInfo = { ...mockEnvironmentInfo(installName) };

      if (hasPageUrlQueryParam('sandbox')) {
        environmentInfo.sandbox = true;
        environmentInfo.installEnv = 'development';
        environmentInfo.primaryDomainName = `${installName}.wpenginepoweredstaging.com`;
        environmentInfo.primaryDomainUrl = `http://${installName}.wpenginepoweredstaging.com`;
      }

      if (hasPageUrlQueryParam('sshLoginDisabled')) {
        environmentInfo.sshLogin = {
          text: 'Contact support to enable SSH Gateway',
          tooltipHtml:
            'The SSH Gateway feature is only available on particular installs. \u003ca href="/support" target="_blank"\u003eContact support\u003c/a\u003e to enable this feature or \u003ca href="https://wpengine.com/support/ssh-gateway-faq/" target="_blank"\u003elearn more about SSH Gateway\u003c/a\u003e.',
        };
      }

      if (hasPageUrlQueryParam('sshKeysEmpty')) {
        environmentInfo.sshLogin = {
          text: 'Add SSH Key',
          link: '/profile/ssh_keys',
        };
      }

      if (hasPageUrlQueryParam('sshPlanNotSupported')) {
        environmentInfo.sshLogin = {
          text: 'Feature not available on Startup plans',
          tooltipHtml:
            'The SSH Gateway feature is only available on select plans. <a href="/support" target="_blank">Contact support</a> to upgrade your plan or <a href="https://wpengine.com/support/ssh-gateway-faq/" target="_blank">learn more about SSH Gateway</a>.',
        };
      }

      if (hasPageUrlQueryParam('primaryDomainNotSet')) {
        environmentInfo.primaryDomainName = null;
      }

      if (hasPageUrlQueryParam('hidePrimaryDomain')) {
        environmentInfo.showPrimaryDomain = false;
      }

      if (hasPageUrlQueryParam('canDeleteInstall', 'false')) {
        environmentInfo.canDeleteInstall = false;
      }

      if (hasPageUrlQueryParam('transferable')) {
        environmentInfo.transferable = true;
      }

      if (hasPageUrlQueryParam('transferMigrating')) {
        environmentInfo.transferMigrating = true;
      }

      if (hasPageUrlQueryParam('installPending')) {
        environmentInfo.installPending = true;
      }

      if (hasPageUrlQueryParam('activeSiteMonitoring')) {
        environmentInfo.installId = 1001;
      }

      if (hasPageUrlQueryParam('activePSB')) {
        environmentInfo.installName = '12345';
      }

      if (hasPageUrlQueryParam('devEnv')) {
        environmentInfo.installEnv = 'development';
      }

      if (
        hasPageUrlQueryParam(MSW_PARAMS.ALL_CACHES_LAST_CLEARED_AT_RETURNS_DATE)
      ) {
        environmentInfo.allCachesLastClearedAt = new Date().toISOString();
      }

      return customResponse(ctx.status(200), ctx.json(environmentInfo));
    }
  ),
  rest.delete('*/installs/:installName', async (req, res, ctx) => {
    return customResponse(ctx.status(200));
  }),
  rest.get('*/installs/:installName/usage_stats', async (req, res, ctx) => {
    return customResponse(ctx.status(200));
  }),
  rest.post('*/installs/:installName/backup_points', async (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({
        msg:
          'Backup started. You will be notified via email when it has completed.',
      })
    );
  }),
  rest.post(
    '*/installs/:installName/clear_all_caches',
    async (req, res, ctx) => {
      if (hasPageUrlQueryParam('cacheError')) {
        return customResponse(ctx.status(500));
      }

      return customResponse(ctx.status(200), addDelay(5000));
    }
  ),
  rest.get(
    '*/installs/:installName/utilities/basic_auth',
    async (req, res, ctx) => {
      const passwordSettings = {
        ...mockPasswordSettings,
        user: req.params.installName,
      };

      if (hasPageUrlQueryParam('passwordDisabled')) {
        passwordSettings.enabled = false;
      }

      return customResponse(ctx.status(200), ctx.json(passwordSettings));
    }
  ),
  rest.patch<
    {
      install: {
        nginx_basic_auth_production: boolean;
        nginx_basic_auth_password: string;
      };
    },
    { installName: string },
    UpdateResponse
  >('*/installs/:installName/utilities', async (req, res, ctx) => {
    const passwordResponse = {
      ...mockPasswordResponse,
      user: req.params.installName,
    };

    if (hasPageUrlQueryParam('passwordChangeError')) {
      return customResponse(
        ctx.status(200),
        ctx.json(mockPasswordResponseError)
      );
    }

    const password = req.body.install.nginx_basic_auth_password;
    if (req.body.install.nginx_basic_auth_production === false) {
      return customResponse(ctx.status(200), ctx.json(mockDisabledResponse));
    }
    if (password) {
      passwordResponse.password = password;
    }
    return customResponse(ctx.status(200), ctx.json(passwordResponse));
  }),
  rest.get(
    '*/environments/:environmentName/daily_usage_metrics',
    async (req, res, ctx) => {
      const dailyUsageMetrics: V1alpha1DailyUsageMetricsSeries = {
        environmentName: req.params.environmentName.toString(),
        metrics: mockP1aMetricsData,
        metricsRollup: mockP1aMetricsRollUpData,
        storageRefreshExpectedTime,
      };

      if (hasPageUrlQueryParam('emptyMetrics')) {
        const emptyDailyUsageMetrics: V1alpha1DailyUsageMetricsSeries = {
          ...dailyUsageMetrics,
          metrics: [],
          storageRefreshExpectedTime: null,
          metricsRollup: {
            storageDatabaseBytes: {
              latest: {},
            },
            storageFileBytes: {
              latest: {},
            },
          },
        };

        return customResponse(
          ctx.status(200),
          ctx.json(emptyDailyUsageMetrics)
        );
      }

      if (hasPageUrlQueryParam('emptyFileStorage')) {
        const emptyFileStorageMetrics: V1alpha1DailyUsageMetricsSeries = {
          ...dailyUsageMetrics,
          metricsRollup: {
            ...dailyUsageMetrics.metricsRollup,
            storageFileBytes: null,
          },
        };

        return customResponse(
          ctx.status(200),
          ctx.json(emptyFileStorageMetrics)
        );
      }

      if (hasPageUrlQueryParam('emptyDatabaseStorage')) {
        const emptyDatabaseStorageMetrics: V1alpha1DailyUsageMetricsSeries = {
          ...dailyUsageMetrics,
          metricsRollup: {
            ...dailyUsageMetrics.metricsRollup,
            storageDatabaseBytes: null,
          },
        };

        return customResponse(
          ctx.status(200),
          ctx.json(emptyDatabaseStorageMetrics)
        );
      }

      return customResponse(ctx.status(200), ctx.json(dailyUsageMetrics));
    }
  ),
  rest.post('*/daily_usage_metrics:refreshDiskUsage', async (req, res, ctx) => {
    storageRefreshExpectedTime = SUCCESSFUL_REFRESH_TIME;
    return customResponse(ctx.status(200), ctx.json({}));
  }),
  rest.post(
    '*/accounts%2F:accountId:refreshAccountDiskUsage',
    async (req, res, ctx) => {
      storageRefreshExpectedTime = SUCCESSFUL_REFRESH_TIME;
      return customResponse(ctx.status(200), ctx.json({}));
    }
  ),
  rest.post('/usage/clear_usage_cache', async (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json({}));
  }),
  rest.get('*/daily_usage_metrics', async (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({ accountStorageRefreshExpectedTime: SUCCESSFUL_REFRESH_TIME })
    );
  }),
];

export default handlers;
