interface SampleOptionProps {
  installName: string;
  value: string;
  index: number;
}

interface SampleBackup {
  count: number;
  installName: 'install1';
  backupsPresent: boolean;
}

export const sampleBackup = ({
  count = 3,
  installName = 'install1',
  backupsPresent = true,
}: Partial<SampleBackup> = {}) => {
  const options = [];

  for (let i = 0; i < count; i += 1) {
    options.push(
      sampleOption({
        installName: `${installName}${i}`,
        value: `${i.toString().padStart(9, '0')}`,
        index: i,
      })
    );
  }

  return {
    backups_present: backupsPresent,
    options,
    create_backup_path: `/installs/${installName}/backup_points`,
  };
};

export const sampleOption = ({
  installName = 'install0',
  value = '000000000',
  index = 0,
}: Partial<SampleOptionProps> = {}) => {
  const addMostRecentPostfix = index === 0 ? ' (most recent)' : '';

  return {
    label: `Apr 29 5:57PM UTC (over 1 day ago) - test ${installName}${addMostRecentPostfix}`,
    value,
    extra: {
      comment: `test ${installName}`,
      tstamp: '2020-08-18T17:57:16.123Z',
    },
  };
};
