import * as React from 'react';
import { gray100, gray1100, colorWhite } from 'design-tokens';
import { Box } from '@mui/material';
import { Ownership } from 'componentsDir/Ownership';
import UnicornProvider from '@wpengine/unicorn/components/UnicornProvider';
import { getCurrentUserAccountsFromDOM } from 'commonsDir/helpers/lookupAccounts';
import { AccountDetails } from 'commonsDir/types/Accounts';
import { ALL_ACCOUNTS_LOOKUP } from 'componentsDir/SideNav/constants';
import SideNavContext from 'componentsDir/SideNav/components/SideNavContext';
import SideNavList from './components/SideNavList/SideNavList';

interface SideNavWrapperProps {
  chatPin: string;
  currentAccount: AccountDetails;
  allAccountsView: boolean;
}

const SideNavWrapper: React.FC<SideNavWrapperProps> = ({
  chatPin,
  children,
  currentAccount: currentAccountProp,
  allAccountsView,
}) => {
  const accounts: AccountDetails[] = getCurrentUserAccountsFromDOM();
  const currentAccount = allAccountsView
    ? ALL_ACCOUNTS_LOOKUP
    : currentAccountProp;
  const hasMultipleAccounts = accounts.length > 1;
  const initialState: boolean =
    localStorage.getItem('sideNavCollapsed') === 'true' ||
    window.location.pathname.startsWith('/installs/');
  const [collapse, setCollapse] = React.useState(initialState);

  return (
    <UnicornProvider>
      <Ownership team="CAFE" inset>
        <SideNavContext.Provider value={{ collapsed: collapse }}>
          <Box
            component="nav"
            className="sidenav"
            sx={{
              borderRight: `2px solid ${gray100}`,
              width: collapse ? '62px' : '15rem',
              height: '100%',
              transition: 'all 120ms ease-in-out',
              backgroundColor: colorWhite,
              color: gray1100,
              overflow: 'hidden auto',
            }}
          >
            <SideNavList
              allAccountsView={allAccountsView}
              chatPinId={chatPin}
              collapse={collapse}
              currentAccount={currentAccount}
              hasMultipleAccounts={hasMultipleAccounts}
              setCollapse={setCollapse}
            >
              {children}
            </SideNavList>
          </Box>
        </SideNavContext.Provider>
      </Ownership>
    </UnicornProvider>
  );
};

export default SideNavWrapper;
