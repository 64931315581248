import useCart from '../hooks/useCart';
import useUpdateCart from '../hooks/useUpdateCart';

const useRemoveAddon = () => {
  const { cart } = useCart();
  const { mutate } = useUpdateCart();

  return (sku: string) => {
    const newAddons = [...cart.selected_addons];
    const updatedAddonIndex = newAddons.findIndex(
      addon => addon.base_sku === sku
    );

    newAddons.splice(updatedAddonIndex, 1);
    mutate({ ...cart, selected_addons: newAddons });
  };
};

export default useRemoveAddon;
