import { useEffect, useState } from 'react';

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    let timeoutId: number;

    const func = () => {
      if (timeoutId) window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(
        () => setWindowWidth(window.innerWidth),
        500
      );
    };

    window.addEventListener('resize', func);
    return () => {
      if (timeoutId) window.clearTimeout(timeoutId);
      window.removeEventListener('resize', func);
    };
  }, []);

  return {
    windowWidth,
  };
};

export default useWindowWidth;
