import {
  blue100,
  blue700,
  blue800,
  gray100,
  gray1100,
  green100,
  green900,
  orange700,
  orange900,
  red100,
  red800,
  yellow200,
} from 'design-tokens';

const chipTheme = () => {
  return {
    styleOverrides: {
      label: {
        fontSize: '0.875rem',
      },
      root: {
        '.MuiChip-icon': {
          width: '18px',
          height: '18px',
        },
        '&.MuiChip-colorSuccess': {
          backgroundColor: green100,
          '.MuiChip-icon, .MuiChip-label': {
            color: green900,
          },
        },
        '&.MuiChip-colorInfo': {
          backgroundColor: blue100,
          '.MuiChip-icon': {
            color: blue700,
          },
          '.MuiChip-label': {
            color: blue800,
          },
        },
        '&.MuiChip-colorError': {
          backgroundColor: red100,
          '.MuiChip-icon, .MuiChip-label': {
            color: red800,
          },
        },
        '&.MuiChip-colorDefault': {
          backgroundColor: gray100,
          '.MuiChip-icon, .MuiChip-label': {
            color: gray1100,
          },
        },
        '&.MuiChip-colorWarning': {
          backgroundColor: yellow200,
          '.MuiChip-icon': {
            color: orange700,
          },
          '.MuiChip-label': {
            color: orange900,
          },
        },
      },
    },
  };
};

export default chipTheme;
