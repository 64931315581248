import * as React from 'react';
import { Tooltip } from '@mui/material';
import { RefreshIcon } from '@wpengine/unicorn/icons';
import { Typography, Box } from '@wpengine/unicorn/components';
import { generate_support_pin_path } from 'railsRoutes';
import getCsrfToken from 'componentsDir/users/utils/userUtils';
import axios from 'axios';
import { gray1100, gray900, gray100, borderRadiusMedium } from 'design-tokens';
import { useTopNavContext } from 'componentsDir/TopNav/contexts/TopNavProvider';
import usePinRefreshHandler, { CHATPIN } from 'commonsDir/hooks/usePinRefresh';
import { SUPPORT_PIN_DISABLED_TOOLTIP } from 'componentsDir/SideNav/components/shared/constants';
import { ItemInteractionProps } from '../../../SideNav/utils';

export interface SupportChatPinProps {
  position?: string;
  inNav?: boolean;
  disabled: boolean;
}

const SupportChatPin: React.FC<SupportChatPinProps> = ({
  inNav = true,
  disabled,
}) => {
  const { chatPinId, currentAccount } = useTopNavContext();
  const [chatPin, setChatPin] = usePinRefreshHandler(
    chatPinId,
    currentAccount.accountUserId
  );
  const [tooltipText, setTooltipText] = React.useState('Click to copy');
  const { allAccountsView } = useTopNavContext();

  const onRefreshPin = (): void => {
    axios({
      method: 'post',
      url: generate_support_pin_path(),
      headers: {
        'X-CSRF-TOKEN': getCsrfToken(),
      },
    }).then(response => {
      if (response.status === 200) {
        setChatPin(response.data.pin);
        document.dispatchEvent(
          new CustomEvent(CHATPIN, { detail: response.data.pin })
        );
      }
    });
  };

  const sx = {
    width: '100%',
    alignItems: 'center',
    backgroundColor: gray100,
    borderRadius: `${
      inNav ? `0 0 ${borderRadiusMedium} 0` : `${borderRadiusMedium}`
    }`,
    padding: '.5rem 0 .5rem 1rem',
    marginTop: '.5rem',
  };

  const supportPin = () => {
    return (
      <Box sx={sx} title={disabled ? null : 'Support PIN'}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center',
            cursor: disabled ? 'not-allowed' : 'default',
          }}
        >
          <Typography sx={{ fontWeight: 600 }}>PIN</Typography>
          {disabled ? (
            'n/a'
          ) : (
            <>
              <Tooltip
                sx={{ padding: 0 }}
                title={tooltipText}
                onOpen={() => {
                  setTooltipText('Click to copy');
                }}
              >
                <Box
                  onClick={() => {
                    setTooltipText('Copied to clipboard');
                    navigator.clipboard.writeText(chatPin);
                  }}
                  component="button"
                  sx={{
                    all: 'unset',
                    fontSize: '14px',
                    borderColor: gray1100,
                    borderBottom: `1px dashed`,
                    cursor: 'pointer',
                  }}
                >
                  {chatPin}
                </Box>
              </Tooltip>
              <Tooltip sx={{ padding: 0 }} title="Refresh PIN">
                <Box
                  component="span"
                  aria-label="Support Refresh PIN"
                  className="focusable"
                  {...ItemInteractionProps(onRefreshPin)}
                  sx={{
                    cursor: 'pointer',
                    padding: '10px',
                  }}
                >
                  <RefreshIcon
                    sx={{
                      color: gray900,
                      display: 'block',
                      height: '16px',
                      width: '16px',
                    }}
                  />
                </Box>
              </Tooltip>
            </>
          )}
        </Box>
      </Box>
    );
  };

  return allAccountsView ? (
    <Tooltip title={SUPPORT_PIN_DISABLED_TOOLTIP}>{supportPin()}</Tooltip>
  ) : (
    supportPin()
  );
};

export default SupportChatPin;
