import { Box, Button } from '@wpengine/unicorn/components';
import React from 'react';

type ActionsProps = {
  onClose: () => void;
};

const Actions: React.FC<ActionsProps> = ({ onClose }) => {
  return (
    <Box
      sx={{
        p: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Button variant="text" onClick={onClose}>
        Continue shopping
      </Button>
      <Button href="/cart" size="small">
        Go to cart
      </Button>
    </Box>
  );
};

export default Actions;
