import * as React from 'react';
import { Grid, GridProps, useTheme } from '@mui/material';

const GridContainer: React.FC<GridProps> = ({
  children,
  padding,
  ...props
}) => {
  const theme = useTheme();
  const defaultPadding = {
    [theme.breakpoints.down('md')]: '0 16px',
    [theme.breakpoints.up('md')]: '0 24px',
  };

  return (
    <Grid
      columns={12}
      container
      direction="row"
      justifyContent="center"
      maxWidth="1200px"
      padding={padding || defaultPadding}
      spacing={{ md: 2, xs: 1 }}
      {...props}
    >
      {children}
    </Grid>
  );
};

export default GridContainer;
