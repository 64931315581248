import React from 'react';
import { Box, Typography } from '@wpengine/unicorn/components';
import { teal100, teal1000 } from 'design-tokens';
import { useCartSummaryContext } from 'componentsDir/shared/providers/Cart/contexts/CartSummaryContext';

const Total: React.FC = () => {
  const { formattedCartTotal, recurrenceShort } = useCartSummaryContext();

  return (
    <Box sx={{ p: 2, bgcolor: teal100 }}>
      <Box display="flex" alignItems="baseline" justifyContent="space-between">
        <Typography variant="h5" color={teal1000}>
          Cart subtotal
        </Typography>
        <Typography variant="bodyBold" color={teal1000}>
          {formattedCartTotal}{' '}
          <Typography display="inline" variant="body1">
            {recurrenceShort}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default Total;
