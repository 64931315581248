import * as mockMonitors from './mockMonitors';

const now = Number(new Date());
const mockIncidentsOne = [
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '878550145',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: mockMonitors.install0PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 15961358,
    endTime: now - 61690,
    accountWpeId: mockMonitors.install0PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '861866950',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: mockMonitors.install0PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 109848972,
    endTime: now - 102858762,
    accountWpeId: mockMonitors.install0PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: 'HTTPError: Server replied with a HTTP 401 response code',
      status: 'Unauthorized',
      statusCode: 401,
    },
    incidentId: '877082890',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: mockMonitors.install0PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 23550609,
    endTime: now - 16020574,
    accountWpeId: mockMonitors.install0PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '867763056',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: mockMonitors.install0PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 76039207,
    endTime: now - 75526901,
    accountWpeId: mockMonitors.install0PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '860052817',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: mockMonitors.install0PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 122664122,
    endTime: now - 111019662,
    accountWpeId: mockMonitors.install0PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '861702560',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: 'https://test.com',
    subpath: '',
    sslTriggered: false,
    startTime: now - 110958730,
    endTime: now - 109910064,
    accountWpeId: mockMonitors.install0PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '862922415',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: mockMonitors.install0PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 102799063,
    endTime: now - 92569040,
    accountWpeId: mockMonitors.install0PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: 'HTTPError: Server replied with a HTTP 401 response code',
      status: 'Unauthorized',
      statusCode: 401,
    },
    incidentId: '864679889',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: mockMonitors.install0PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 92508922,
    endTime: now - 76098014,
    accountWpeId: mockMonitors.install0PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '867865881',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: mockMonitors.install0PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 75518806,
    endTime: now - 75494309,
    accountWpeId: mockMonitors.install0PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '867887428',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: mockMonitors.install0PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 75402429,
    endTime: now - 49480843,
    accountWpeId: mockMonitors.install0PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '867876740',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: mockMonitors.install0PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 75458856,
    endTime: now - 75437089,
    accountWpeId: mockMonitors.install0PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '872264365',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: mockMonitors.install0PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 49477257,
    endTime: now - 23555401,
    accountWpeId: mockMonitors.install0PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '867882501',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: mockMonitors.install0PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 75428733,
    endTime: now - 75407264,
    accountWpeId: mockMonitors.install0PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '881659973',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: mockMonitors.install0PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now,
    endTime: now + 25920604,
    accountWpeId: mockMonitors.install0PrimaryMonitor.accountWpeId,
  },
];

const mockIncidentsTwo = [
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install6PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '879002768',
    incidentStatus: 'open',
    installName: mockMonitors.install6PrimaryMonitor.installName,
    installWpeId: mockMonitors.install6PrimaryMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install6PrimaryMonitor.monitorId,
    domain: mockMonitors.install6PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 15962064,
    endTime: now - 62396,
    accountWpeId: mockMonitors.install6PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: 'HTTPError: Server replied with a HTTP 401 response code',
      status: 'Unauthorized',
      statusCode: 401,
    },
    incidentId: '862395813',
    incidentStatus: 'closed',
    installName: mockMonitors.install0MailMonitor.installName,
    installWpeId: mockMonitors.install0MailMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install0MailMonitor.monitorId,
    domain: mockMonitors.install0MailMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 109851795,
    endTime: now - 102861585,
    accountWpeId: mockMonitors.install0MailMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0MailMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '877741140',
    incidentStatus: 'closed',
    installName: mockMonitors.install0MailMonitor.installName,
    installWpeId: mockMonitors.install0MailMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install0MailMonitor.monitorId,
    domain: mockMonitors.install0MailMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 23555214,
    endTime: now - 16025179,
    accountWpeId: mockMonitors.install0MailMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0MailMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '868125106',
    incidentStatus: 'closed',
    installName: mockMonitors.install0MailMonitor.installName,
    installWpeId: mockMonitors.install0MailMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install0MailMonitor.monitorId,
    domain: mockMonitors.install0MailMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 76044334,
    endTime: now - 75532028,
    accountWpeId: mockMonitors.install0MailMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0MailMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '860362680',
    incidentStatus: 'closed',
    installName: mockMonitors.install0MailMonitor.installName,
    installWpeId: mockMonitors.install0MailMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install0MailMonitor.monitorId,
    domain: mockMonitors.install0MailMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 122666895,
    endTime: now - 111022435,
    accountWpeId: mockMonitors.install0MailMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0MailMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '862519409',
    incidentStatus: 'closed',
    installName: mockMonitors.install0MailMonitor.installName,
    installWpeId: mockMonitors.install0MailMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install0MailMonitor.monitorId,
    domain: mockMonitors.install0MailMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 110964940,
    endTime: now - 109916274,
    accountWpeId: mockMonitors.install0MailMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0MailMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '863780543',
    incidentStatus: 'closed',
    installName: mockMonitors.install0MailMonitor.installName,
    installWpeId: mockMonitors.install0MailMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install0MailMonitor.monitorId,
    domain: mockMonitors.install0MailMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 102805051,
    endTime: now - 92575028,
    accountWpeId: mockMonitors.install0MailMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0MailMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '864801592',
    incidentStatus: 'closed',
    installName: mockMonitors.install0MailMonitor.installName,
    installWpeId: mockMonitors.install0MailMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install0MailMonitor.monitorId,
    domain: mockMonitors.install0MailMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 92516670,
    endTime: now - 76105762,
    accountWpeId: mockMonitors.install0MailMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0MailMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '868232633',
    incidentStatus: 'closed',
    installName: mockMonitors.install0MailMonitor.installName,
    installWpeId: mockMonitors.install0MailMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install0MailMonitor.monitorId,
    domain: mockMonitors.install0MailMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 75521875,
    endTime: now - 75497378,
    accountWpeId: mockMonitors.install0MailMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0MailMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '867957204',
    incidentStatus: 'closed',
    installName: mockMonitors.install0MailMonitor.installName,
    installWpeId: mockMonitors.install0MailMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install0MailMonitor.monitorId,
    domain: mockMonitors.install0MailMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 75410054,
    endTime: now - 49488468,
    accountWpeId: mockMonitors.install0MailMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0MailMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '868021317',
    incidentStatus: 'closed',
    installName: mockMonitors.install0MailMonitor.installName,
    installWpeId: mockMonitors.install0MailMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install0MailMonitor.monitorId,
    domain: mockMonitors.install0MailMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 75466039,
    endTime: now - 75444272,
    accountWpeId: mockMonitors.install0MailMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0MailMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '872823170',
    incidentStatus: 'closed',
    installName: mockMonitors.install0MailMonitor.installName,
    installWpeId: mockMonitors.install0MailMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install0MailMonitor.monitorId,
    domain: mockMonitors.install0MailMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 49480349,
    endTime: now - 23558493,
    accountWpeId: mockMonitors.install0MailMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0MailMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '868413016',
    incidentStatus: 'closed',
    installName: mockMonitors.install0MailMonitor.installName,
    installWpeId: mockMonitors.install0MailMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install0MailMonitor.monitorId,
    domain: mockMonitors.install0MailMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 75431070,
    endTime: now - 75409601,
    accountWpeId: mockMonitors.install0MailMonitor.accountWpeId,
  },
];

const mockIncidentsThree = [
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install7PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '879002768',
    incidentStatus: 'closed',
    installName: mockMonitors.install7PrimaryMonitor.installName,
    installWpeId: mockMonitors.install7PrimaryMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install7PrimaryMonitor.monitorId,
    domain: mockMonitors.install7PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 15962064,
    endTime: now - 62396,
    accountWpeId: mockMonitors.install7PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install7PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '862395813',
    incidentStatus: 'closed',
    installName: mockMonitors.install7PrimaryMonitor.installName,
    installWpeId: mockMonitors.install7PrimaryMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install7PrimaryMonitor.monitorId,
    domain: mockMonitors.install7PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 109851795,
    endTime: now - 102861585,
    accountWpeId: mockMonitors.install7PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: 'HTTPError: Server replied with a HTTP 401 response code',
      status: 'Unauthorized',
      statusCode: 401,
    },
    incidentId: '877741140',
    incidentStatus: 'closed',
    installName: mockMonitors.install7PrimaryMonitor.installName,
    installWpeId: mockMonitors.install7PrimaryMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install7PrimaryMonitor.monitorId,
    domain: mockMonitors.install7PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 23555214,
    endTime: now - 16025179,
    accountWpeId: mockMonitors.install7PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install7PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '868125106',
    incidentStatus: 'closed',
    installName: mockMonitors.install7PrimaryMonitor.installName,
    installWpeId: mockMonitors.install7PrimaryMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install7PrimaryMonitor.monitorId,
    domain: mockMonitors.install7PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 76044334,
    endTime: now - 75532028,
    accountWpeId: mockMonitors.install7PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install7PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '860362680',
    incidentStatus: 'closed',
    installName: mockMonitors.install7PrimaryMonitor.installName,
    installWpeId: mockMonitors.install7PrimaryMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install7PrimaryMonitor.monitorId,
    domain: mockMonitors.install7PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 122666895,
    endTime: now - 111022435,
    accountWpeId: mockMonitors.install7PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install7PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '862519409',
    incidentStatus: 'closed',
    installName: mockMonitors.install7PrimaryMonitor.installName,
    installWpeId: mockMonitors.install7PrimaryMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install7PrimaryMonitor.monitorId,
    domain: mockMonitors.install7PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 110964940,
    endTime: now - 109916274,
    accountWpeId: mockMonitors.install7PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install7PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '863780543',
    incidentStatus: 'closed',
    installName: mockMonitors.install7PrimaryMonitor.installName,
    installWpeId: mockMonitors.install7PrimaryMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install7PrimaryMonitor.monitorId,
    domain: mockMonitors.install7PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 102805051,
    endTime: now - 92575028,
    accountWpeId: mockMonitors.install7PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install7PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '864801592',
    incidentStatus: 'closed',
    installName: mockMonitors.install7PrimaryMonitor.installName,
    installWpeId: mockMonitors.install7PrimaryMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install7PrimaryMonitor.monitorId,
    domain: mockMonitors.install7PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 92516670,
    endTime: now - 76105762,
    accountWpeId: mockMonitors.install7PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install7PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '868232633',
    incidentStatus: 'closed',
    installName: mockMonitors.install7PrimaryMonitor.installName,
    installWpeId: mockMonitors.install7PrimaryMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install7PrimaryMonitor.monitorId,
    domain: mockMonitors.install7PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 75521875,
    endTime: now - 75497378,
    accountWpeId: mockMonitors.install7PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install7PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '867957204',
    incidentStatus: 'closed',
    installName: mockMonitors.install7PrimaryMonitor.installName,
    installWpeId: mockMonitors.install7PrimaryMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install7PrimaryMonitor.monitorId,
    domain: mockMonitors.install7PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 75410054,
    endTime: now - 49488468,
    accountWpeId: mockMonitors.install7PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install7PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '868021317',
    incidentStatus: 'closed',
    installName: mockMonitors.install7PrimaryMonitor.installName,
    installWpeId: mockMonitors.install7PrimaryMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install7PrimaryMonitor.monitorId,
    domain: mockMonitors.install7PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 75466039,
    endTime: now - 75444272,
    accountWpeId: mockMonitors.install7PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: 'HTTPError: Server replied with a HTTP 401 response code',
      status: 'Unauthorized',
      statusCode: 401,
    },
    incidentId: '872823170',
    incidentStatus: 'closed',
    installName: mockMonitors.install7PrimaryMonitor.installName,
    installWpeId: mockMonitors.install7PrimaryMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install7PrimaryMonitor.monitorId,
    domain: mockMonitors.install7PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 49480349,
    endTime: now - 23558493,
    accountWpeId: mockMonitors.install7PrimaryMonitor.accountWpeId,
  },
  {
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install7PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '868413016',
    incidentStatus: 'closed',
    installName: mockMonitors.install7PrimaryMonitor.installName,
    installWpeId: mockMonitors.install7PrimaryMonitor.installWpeId,
    maintenanceStatus: { spm: false },
    monitorId: mockMonitors.install7PrimaryMonitor.monitorId,
    domain: mockMonitors.install7PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
    startTime: now - 75431070,
    endTime: now - 75409601,
    accountWpeId: mockMonitors.install7PrimaryMonitor.accountWpeId,
  },
];

const mockIncidents = [
  ...mockIncidentsOne,
  ...mockIncidentsTwo,
  ...mockIncidentsThree,
];

export const mockAcknowledgedIncidents = [
  {
    acknowledgedAt: '2022-10-11 19:40:35.653601 +0000 UTC',
    acknowledgedBy: 'test@example.com',
    failedCheck: {
      error: `NetworkError: DNS resolution failed for host: ${mockMonitors.install0PrimaryMonitor.installDomain}`,
      status: 'Timed out',
      statusCode: -7,
    },
    incidentId: '881659973',
    incidentStatus: 'closed',
    installName: mockMonitors.install0PrimaryMonitor.installName,
    installWpeId: mockMonitors.install0PrimaryMonitor.installWpeId,
    maintenanceStatus: {
      spm: false,
    },
    monitorId: mockMonitors.install0PrimaryMonitor.monitorId,
    domain: mockMonitors.install0PrimaryMonitor.installDomain,
    subpath: '',
    sslTriggered: false,
  },
];

export default mockIncidents;
