import {
  Genesis,
  GlobalEdgeSecurity,
  PageSpeedBoost,
  SiteMonitoring,
  SmartPluginManager,
  SmartSearch,
} from '@wpengine/unicorn/logos';
import AdditionalSite from '@wpengine/unicorn/logos/AdditionalSite/AdditionalSite';
import Multisite from '@wpengine/unicorn/logos/Multisite/Multisite';

export type IconComponent =
  | typeof Genesis
  | typeof GlobalEdgeSecurity
  | typeof PageSpeedBoost
  | typeof SiteMonitoring
  | typeof SmartPluginManager
  | typeof SmartSearch
  | typeof AdditionalSite
  | typeof Multisite;

// Define the type for the AddonIcons object
type AddonIconsType = {
  [k: string]: IconComponent;
};

const addonIcons: AddonIconsType = {
  'smart-plugin-manager': SmartPluginManager,
  'monitoring-site': SiteMonitoring,
  sites: AdditionalSite,
  'genesis-pro': Genesis,
  'global-edge-security': GlobalEdgeSecurity,
  multisite: Multisite,
  'smart-search': SmartSearch,
  'smartsearch-hybrid': SmartSearch,
  'page-speed-boost': PageSpeedBoost,
};

export default addonIcons;
