enum FeatureFlagName {
  BulkTagManagement = 'BULK_TAG_MANAGEMENT',
  DarkModeBeta = 'DARK_MODE_BETA',
  DisableAddWooSite = 'DISABLE_ADD_WOO_SITE',
  DiscoveryHubQuickBuy = 'DISCOVERY_HUB_QUICK_BUY',
  LongRunningJobStatusVisibility = 'STATUS_VISIBILITY',
  MFAWebAuthn = 'MFA_WEBAUTHN',
  QuickPush = 'QUICK_PUSH',
  SiteSwitcher = 'SITE_SWITCHER',
  TransferSiteV2 = 'TRANSFER_SITE_V2',
  TransferSiteV3 = 'TRANSFER_SITE_V3',
  PerformanceInsightsLighthouse = 'PERFORMANCE_INSIGHTS_LIGHTHOUSE',
}

export default FeatureFlagName;
