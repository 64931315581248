import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { ENDPOINTS, MSW_PARAMS } from 'componentsDir/install/Cache/constants';
import { V1alpha1NetworkType } from 'platform_one_api_client';
import {
  generateSampleDomain,
  generateSampleDomains,
  generateSampleInstall,
} from './data';

const NETWORK_TYPES = [
  V1alpha1NetworkType.An,
  V1alpha1NetworkType.Ges,
  V1alpha1NetworkType.Legacy,
];

const p1aHandler = [
  rest.get(`*${ENDPOINTS.DOMAINS_P1A}*/domains`, (req, _res, ctx) => {
    let domainProps = {};

    NETWORK_TYPES.forEach(type => {
      if (
        hasPageUrlQueryParam(MSW_PARAMS.CUSTOM_DOMAIN, type.toLocaleLowerCase())
      ) {
        domainProps = {
          ...domainProps,
          domain: 'customdomain.com',
          networkType: type,
        };
      }
    });

    NETWORK_TYPES.forEach(type => {
      if (
        hasPageUrlQueryParam(
          MSW_PARAMS.WPENGINE_DOMAIN,
          type.toLocaleLowerCase()
        )
      ) {
        domainProps = {
          ...domainProps,
          domain: 'wpenginepowered.com',
          networkType: type,
        };
      }
    });

    if (hasPageUrlQueryParam(MSW_PARAMS.FAILED_DOMAINS_FETCH)) {
      return customResponse(ctx.status(500), ctx.json(false));
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.EFPC_ENABLED)) {
      domainProps = {
        ...domainProps,
        edgeFullPageCacheEnabled: true,
      };
    }

    return customResponse(
      ctx.status(200),
      ctx.json(generateSampleDomains(domainProps))
    );
  }),
  rest.get(`*${ENDPOINTS.SITES_P1A}/:environmentName`, (req, res, ctx) => {
    let environmentProps = {};

    if (hasPageUrlQueryParam(MSW_PARAMS.FAILED_INSTALL_FETCH)) {
      return customResponse(ctx.status(500), ctx.json(false));
    }

    NETWORK_TYPES.forEach(type => {
      if (
        hasPageUrlQueryParam(MSW_PARAMS.CUSTOM_DOMAIN, type.toLocaleLowerCase())
      ) {
        environmentProps = {
          ...environmentProps,
          domain: 'customdomain.com',
        };
      }
    });

    NETWORK_TYPES.forEach(type => {
      if (
        hasPageUrlQueryParam(
          MSW_PARAMS.WPENGINE_DOMAIN,
          type.toLocaleLowerCase()
        )
      ) {
        environmentProps = {
          ...environmentProps,
          domain: 'wpenginepowered.com',
        };
      }
    });

    return customResponse(
      ctx.status(200),
      ctx.json(generateSampleInstall(environmentProps))
    );
  }),
  rest.patch(
    `*/platform-one/domains/v1alpha1/environments%2F:installName%2Fdomains%2F:domainName`,
    (req, _res, ctx) => {
      const updatedFields: any = req.body ?? {};

      if (hasPageUrlQueryParam(MSW_PARAMS.FAILED_DOMAIN_UPDATE)) {
        return customResponse(ctx.status(500), ctx.json(false));
      }

      const data = generateSampleDomain(updatedFields);
      return customResponse(ctx.status(200), ctx.delay(700), ctx.json(data));
    }
  ),
];

export default p1aHandler;
