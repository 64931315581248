import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { ENDPOINTS, MSW_PARAMS } from 'componentsDir/install/Cache/constants';
import generateSampleFeatureUsageV2Data from 'componentsDir/install/Cache/mocks/msw/whooCommFeatureUsageV2/data';

const whooCommFeatureUsageHandlers = [
  rest.get(`*${ENDPOINTS.WHOO_COMM_FEATURE_USAGE_V2}/*`, (_req, _res, ctx) => {
    if (hasPageUrlQueryParam(MSW_PARAMS.SHOW_WHOO_COMM_FEATURE_USAGE_BANNER)) {
      return customResponse(
        ctx.status(200),
        ctx.json(
          generateSampleFeatureUsageV2Data({
            woocommerce: true,
            evercache: false,
          })
        )
      );
    }

    return customResponse(
      ctx.status(200),
      ctx.json(
        generateSampleFeatureUsageV2Data({
          woocommerce: false,
          evercache: false,
        })
      )
    );
  }),
];

export default whooCommFeatureUsageHandlers;
