import { ExtensionType } from 'componentsDir/products/smart_plugin_manager/utils/interfaces';

const plugins: {
  slug: string;
  name: string;
  type: ExtensionType;
  sites: Array<any>;
}[] = [
  {
    slug: 'contactform7/contactform7.php',
    name: 'Contact Form 7',
    type: 'plugin',
    sites: [
      {
        site_id: 80,
        extension_id: 950704764,
        version: '1.3.3',
        install_name: 'testsite800',
        active: true,
        update: null,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        extension_id: 715527389,
        version: '1.1.5',
        install_name: 'testsite801',
        active: false,
        update: {
          version: '3.4.3',
          released_at: '2022-02-27 05:39:45',
          update_id: 81201,
        },
        vulnerabilities: [],
      },
      {
        site_id: 82,
        extension_id: 278769732,
        version: '1.9.8',
        install_name: 'testsite802',
        active: true,
        update: null,
        vulnerabilities: [],
      },
      {
        site_id: 110,
        extension_id: 81665026,
        version: '1.2.3',
        install_name: 'testsite1100',
        active: true,
        update: {
          version: '3.4.3',
          released_at: '2022-02-27 05:39:45',
          update_id: 72337,
        },
        vulnerabilities: [],
      },
      {
        site_id: 111,
        extension_id: 147062237,
        version: '1.7.14',
        install_name: 'testsite1101',
        active: true,
        update: null,
        vulnerabilities: [],
      },
      {
        site_id: 112,
        extension_id: 950090618,
        version: '1.8.3',
        install_name: 'testsite1102',
        active: true,
        update: null,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'yoastseo/yoastseo.php',
    name: 'Yoast SEO',
    type: 'plugin',
    sites: [
      {
        site_id: 70,
        extension_id: 824727180,
        version: '1.8.1',
        install_name: 'testsite700',
        active: true,
        update: null,
        vulnerabilities: [],
      },
      {
        site_id: 71,
        extension_id: 226085198,
        version: '1.3.13',
        install_name: 'testsite701',
        active: true,
        update: null,
        vulnerabilities: [],
      },
      {
        site_id: 72,
        extension_id: 810771959,
        version: '1.3.14',
        install_name: 'testsite702',
        active: true,
        update: null,
        vulnerabilities: [],
      },
      {
        site_id: 130,
        extension_id: 694133911,
        version: '1.5.13',
        install_name: 'testsite1300',
        active: false,
        update: null,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        extension_id: 189575589,
        version: '1.2.13',
        install_name: 'testsite1301',
        active: false,
        update: null,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        extension_id: 574319728,
        version: '1.8.6',
        install_name: 'testsite1302',
        active: true,
        update: null,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'elementorwebsitebuilder/elementorwebsitebuilder.php',
    name: 'Elementor Website Builder',
    type: 'plugin',
    sites: [
      {
        site_id: 10,
        extension_id: 362315058,
        version: '1.2.9',
        install_name: 'testsite100',
        active: true,
        update: null,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        extension_id: 849501858,
        version: '1.4.7',
        install_name: 'testsite101',
        active: true,
        update: {
          version: '2.0.0',
          released_at: '2022-06-30 10:40:14',
          update_id: 40251,
        },
        vulnerabilities: [
          {
            external_id: 'wsscan uuid1',
            severity: 'high',
            fixed_in_version: '1.2.10',
            type: 'XSS',
            description:
              'Data from an attacker could be interpreted as code by site visitors’ web browsers. The ability to run code in another site visitors’ browser can be abused to steal information, or modify site configuration.',
            last_updated_at: '2022-11-25T12:38:29.000Z',
          },
          {
            external_id: 'wsscan uuid2',
            severity: 'medium',
            fixed_in_version: null,
            type: 'OBJECT INJECTION',
            description:
              'This is an application level vulnerability that could allow an attacker to perform different kinds of malicious attacks, such as Code Injection, SQL Injection, Path Traversal and Application Denial of Service, depending on the context. This could result in sensitive data disclosure or site compromise.',
            last_updated_at: '2020-11-15T00:00:00.000Z',
          },
          {
            external_id: 'wsscan uuid3',
            severity: 'critical',
            fixed_in_version: '1.3.1',
            type: 'TRAVERSAL',
            description:
              'This is an application level vulnerability that could allow an attacker to perform different kinds of malicious attacks, such as Code Injection, SQL Injection, Path Traversal and Application Denial of Service, depending on the context.  This could result in sensitive data disclosure or site compromise.',
            last_updated_at: '2021-04-11T00:00:00.000Z',
          },
          {
            external_id: 'wsscan uuid7',
            severity: 'high',
            fixed_in_version: '1.3.1',
            type: 'NO AUTHORISATION',
            description:
              'The software does not perform an authorization check when an actor attempts to access a resource or perform an action.',
            last_updated_at: '2021-12-04T12:38:29.000Z',
          },
          {
            external_id: 'wsscan uuid4',
            severity: 'low',
            fixed_in_version: '1.3.1',
            type: 'INJECTION',
            description:
              'This is an application level vulnerability that could allow an attacker to perform different kinds of malicious attacks, such as Code Injection, SQL Injection, Path Traversal and Application Denial of Service, depending on the context.  This could result in sensitive data disclosure or site compromise.',
            last_updated_at: '2022-11-25T12:38:29.000Z',
          },
        ],
      },
      {
        site_id: 12,
        extension_id: 242779699,
        version: '1.6.5',
        install_name: 'testsite102',
        active: true,
        update: {
          version: '2.2.3',
          released_at: '2022-06-30 10:40:14',
          update_id: 87580,
        },
        vulnerabilities: [],
      },
      {
        site_id: 110,
        extension_id: 87796919,
        version: '1.1.13',
        install_name: 'testsite1100',
        active: true,
        update: {
          version: '2.2.3',
          released_at: '2022-06-30 10:40:14',
          update_id: 18115,
        },
        vulnerabilities: [],
      },
      {
        site_id: 111,
        extension_id: 559807974,
        version: '1.5.9',
        install_name: 'testsite1101',
        active: true,
        update: {
          version: '2.2.3',
          released_at: '2022-06-30 10:40:14',
          update_id: 46597,
        },
        vulnerabilities: [],
      },
      {
        site_id: 112,
        extension_id: 267607911,
        version: '1.7.10',
        install_name: 'testsite1102',
        active: true,
        update: null,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'classiceditor/classiceditor.php',
    name: 'Classic Editor',
    type: 'plugin',
    sites: [
      {
        site_id: 40,
        version: '1.1.4',
        install_name: 'testsite400',
        active: true,
        update: {
          version: '3.3.3',
          released_at: '2022-06-06 13:35:56',
          update_id: 32249,
        },
        extension_id: 199319836,
        vulnerabilities: [],
      },
      {
        site_id: 41,
        version: '1.6.13',
        install_name: 'testsite401',
        active: true,
        update: null,
        extension_id: 337238804,
        vulnerabilities: [],
      },
      {
        site_id: 42,
        version: '1.8.1',
        install_name: 'testsite402',
        active: true,
        update: null,
        extension_id: 212856927,
        vulnerabilities: [],
      },
      {
        site_id: 80,
        version: '1.4.8',
        install_name: 'testsite800',
        active: true,
        update: null,
        extension_id: 17589854,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        version: '1.8.9',
        install_name: 'testsite801',
        active: true,
        update: null,
        extension_id: 173504962,
        vulnerabilities: [],
      },
      {
        site_id: 82,
        version: '1.1.4',
        install_name: 'testsite802',
        active: true,
        update: {
          version: '3.3.3',
          released_at: '2022-06-06 13:35:56',
          update_id: 61226,
        },
        extension_id: 558277684,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'akismetspamprotection/akismetspamprotection.php',
    name: 'Akismet Spam Protection',
    type: 'plugin',
    sites: [
      {
        site_id: 60,
        version: '1.3.2',
        install_name: 'testsite600',
        active: true,
        update: {
          version: '2.5.5',
          released_at: '2021-10-03 05:45:50',
          update_id: 41734,
        },
        extension_id: 18908101,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.7.5',
        install_name: 'testsite601',
        active: true,
        update: null,
        extension_id: 173537212,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.4.13',
        install_name: 'testsite602',
        active: true,
        update: null,
        extension_id: 680632773,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'woocommerce/woocommerce.php',
    name: 'WooCommerce',
    type: 'plugin',
    sites: [
      {
        site_id: 80,
        version: '1.5.7',
        install_name: 'testsite800',
        active: true,
        update: {
          version: '4.2.5',
          released_at: '2021-11-24 05:54:59',
          update_id: 95871,
        },
        extension_id: 774095090,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        version: '1.2.1',
        install_name: 'testsite801',
        active: true,
        update: {
          version: '4.2.5',
          released_at: '2021-11-24 05:54:59',
          update_id: 97541,
        },
        extension_id: 579715250,
        vulnerabilities: [],
      },
      {
        site_id: 82,
        version: '1.5.13',
        install_name: 'testsite802',
        active: true,
        update: null,
        extension_id: 262774475,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'contactformbywpforms/contactformbywpforms.php',
    name: 'Contact Form by WPForms',
    type: 'plugin',
    sites: [
      {
        site_id: 60,
        version: '1.4.4',
        install_name: 'testsite600',
        active: false,
        update: null,
        extension_id: 379440511,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.6.1',
        install_name: 'testsite601',
        active: false,
        update: null,
        extension_id: 234867229,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.1.2',
        install_name: 'testsite602',
        active: false,
        update: null,
        extension_id: 877069442,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'reallysimplessl/reallysimplessl.php',
    name: 'Really Simple SSL',
    type: 'plugin',
    sites: [
      {
        site_id: 80,
        version: '1.9.12',
        install_name: 'testsite800',
        active: true,
        update: null,
        extension_id: 300573058,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        version: '1.5.2',
        install_name: 'testsite801',
        active: true,
        update: {
          version: '4.2.3',
          released_at: '2022-06-02 11:21:46',
          update_id: 33502,
        },
        extension_id: 224111388,
        vulnerabilities: [],
      },
      {
        site_id: 82,
        version: '1.5.10',
        install_name: 'testsite802',
        active: true,
        update: {
          version: '4.2.3',
          released_at: '2022-06-02 11:21:46',
          update_id: 77002,
        },
        extension_id: 134884159,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'jetpack/jetpack.php',
    name: 'Jetpack',
    type: 'plugin',
    sites: [
      {
        site_id: 50,
        version: '1.5.3',
        install_name: 'testsite500',
        active: true,
        update: {
          version: '4.1.4',
          released_at: '2022-07-26 18:17:00',
          update_id: 28819,
        },
        extension_id: 929897542,
        vulnerabilities: [],
      },
      {
        site_id: 51,
        version: '1.1.1',
        install_name: 'testsite501',
        active: true,
        update: null,
        extension_id: 990500949,
        vulnerabilities: [],
      },
      {
        site_id: 52,
        version: '1.4.8',
        install_name: 'testsite502',
        active: true,
        update: false,
        extension_id: 831253859,
        vulnerabilities: [],
      },
      {
        site_id: 130,
        version: '1.9.4',
        install_name: 'testsite1300',
        active: true,
        update: null,
        extension_id: 933955880,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.1.11',
        install_name: 'testsite1301',
        active: true,
        update: null,
        extension_id: 481835156,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.5.6',
        install_name: 'testsite1302',
        active: true,
        update: {
          version: '2.1.4',
          released_at: '2022-07-26 18:17:00',
          update_id: 65515,
        },
        extension_id: 617480998,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'all-in-onewpmigration/all-in-onewpmigration.php',
    name: 'All-in-One WP Migration',
    type: 'plugin',
    sites: [
      {
        site_id: 90,
        version: '1.1.3',
        install_name: 'testsite900',
        active: true,
        update: null,
        extension_id: 993107212,
        vulnerabilities: [],
      },
      {
        site_id: 91,
        version: '1.2.14',
        install_name: 'testsite901',
        active: true,
        update: null,
        extension_id: 786976177,
        vulnerabilities: [],
      },
      {
        site_id: 92,
        version: '1.9.4',
        install_name: 'testsite902',
        active: true,
        update: null,
        extension_id: 838093266,
        vulnerabilities: [],
      },
      {
        site_id: 130,
        version: '1.5.2',
        install_name: 'testsite1300',
        active: true,
        update: {
          version: '2.5.3',
          released_at: '2022-04-28 02:54:39',
          update_id: 65066,
        },
        extension_id: 782274465,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.8.6',
        install_name: 'testsite1301',
        active: true,
        update: null,
        extension_id: 378295003,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.3.9',
        install_name: 'testsite1302',
        active: true,
        update: null,
        extension_id: 596421025,
        vulnerabilities: [],
      },
      {
        site_id: 150,
        version: '1.3.9',
        install_name: 'testsite1500',
        active: true,
        update: {
          version: '2.5.3',
          released_at: '2022-04-28 02:54:39',
          update_id: 65517,
        },
        extension_id: 917119034,
        vulnerabilities: [],
      },
      {
        site_id: 151,
        version: '1.8.14',
        install_name: 'testsite1501',
        active: true,
        update: {
          version: '2.5.3',
          released_at: '2022-04-28 02:54:39',
          update_id: 74563,
        },
        extension_id: 710905525,
        vulnerabilities: [],
      },
      {
        site_id: 152,
        version: '1.6.9',
        install_name: 'testsite1502',
        active: true,
        update: {
          version: '2.5.3',
          released_at: '2022-04-28 02:54:39',
          update_id: 75545,
        },
        extension_id: 648345110,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'wordfencesecurity/wordfencesecurity.php',
    name: 'Wordfence Security',
    type: 'plugin',
    sites: [
      {
        site_id: 90,
        version: '1.2.14',
        install_name: 'testsite900',
        active: true,
        update: null,
        extension_id: 537317228,
        vulnerabilities: [],
      },
      {
        site_id: 91,
        version: '1.6.3',
        install_name: 'testsite901',
        active: true,
        update: null,
        extension_id: 169669998,
        vulnerabilities: [],
      },
      {
        site_id: 92,
        version: '1.1.9',
        install_name: 'testsite902',
        active: true,
        update: null,
        extension_id: 455922215,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'yoastduplicatepost/yoastduplicatepost.php',
    name: 'Yoast Duplicate Post',
    type: 'plugin',
    sites: [
      {
        site_id: 20,
        version: '1.2.5',
        install_name: 'testsite200',
        active: true,
        update: {
          version: '3.1.1',
          released_at: '2022-02-24 12:13:05',
          update_id: 59597,
        },
        extension_id: 389706997,
        vulnerabilities: [
          {
            external_id: 'wsscan uuid4',
            severity: 'high',
            fixed_in_version: '3.1.1',
            type: 'INJECTION',
            description:
              'This is an application level vulnerability that could allow an attacker to perform different kinds of malicious attacks, such as Code Injection, SQL Injection, Path Traversal and Application Denial of Service, depending on the context.  This could result in sensitive data disclosure or site compromise.',
            last_updated_at: '2022-11-25T12:38:29.000Z',
          },
        ],
      },
      {
        site_id: 21,
        version: '1.6.14',
        install_name: 'testsite201',
        active: true,
        update: {
          version: '3.1.1',
          released_at: '2022-02-24 12:13:05',
          update_id: 25468,
        },
        extension_id: 580452145,
        vulnerabilities: [],
      },
      {
        site_id: 22,
        version: '1.5.13',
        install_name: 'testsite202',
        active: true,
        update: {
          version: '3.1.1',
          released_at: '2022-02-24 12:13:05',
          update_id: 43485,
        },
        extension_id: 880021701,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'wordpressimporter/wordpressimporter.php',
    name: 'WordPress Importer',
    type: 'plugin',
    sites: [
      {
        site_id: 40,
        version: '1.1.6',
        install_name: 'testsite400',
        active: false,
        update: null,
        extension_id: 984276122,
        vulnerabilities: [],
      },
      {
        site_id: 41,
        version: '1.8.9',
        install_name: 'testsite401',
        active: true,
        update: {
          version: '2.4.1',
          released_at: '2022-03-22 11:20:40',
          update_id: 71588,
        },
        extension_id: 649710027,
        vulnerabilities: [],
      },
      {
        site_id: 42,
        version: '1.1.8',
        install_name: 'testsite402',
        active: true,
        update: {
          version: '2.4.1',
          released_at: '2022-03-22 11:20:40',
          update_id: 55115,
        },
        extension_id: 555051186,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug:
      'updraftpluswordpressbackupplugin/updraftpluswordpressbackupplugin.php',
    name: 'UpdraftPlus WordPress Backup Plugin',
    type: 'plugin',
    sites: [
      {
        site_id: 20,
        version: '1.1.14',
        install_name: 'testsite200',
        active: true,
        update: {
          version: '5.1.4',
          released_at: '2022-07-07 01:01:08',
          update_id: 32029,
        },
        extension_id: 969772674,
        vulnerabilities: [],
      },
      {
        site_id: 21,
        version: '1.9.8',
        install_name: 'testsite201',
        active: true,
        update: null,
        extension_id: 903903313,
        vulnerabilities: [],
      },
      {
        site_id: 22,
        version: '1.4.2',
        install_name: 'testsite202',
        active: true,
        update: {
          version: '5.1.4',
          released_at: '2022-07-07 01:01:08',
          update_id: 44242,
        },
        extension_id: 355492385,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'litespeedcache/litespeedcache.php',
    name: 'LiteSpeed Cache',
    type: 'plugin',
    sites: [
      {
        site_id: 10,
        version: '1.5.13',
        install_name: 'testsite100',
        active: true,
        update: {
          version: '4.4.2',
          released_at: '2022-03-15 16:16:53',
          update_id: 55255,
        },
        extension_id: 595872419,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        version: '1.3.11',
        install_name: 'testsite101',
        active: true,
        update: {
          version: '4.4.2',
          released_at: '2022-03-15 16:16:53',
          update_id: 21314,
        },
        extension_id: 737233340,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.4.11',
        install_name: 'testsite102',
        active: true,
        update: null,
        extension_id: 319218332,
        vulnerabilities: [],
      },
      {
        site_id: 120,
        version: '1.1.11',
        install_name: 'testsite1200',
        active: true,
        update: null,
        extension_id: 801295414,
        vulnerabilities: [],
      },
      {
        site_id: 121,
        version: '1.5.7',
        install_name: 'testsite1201',
        active: true,
        update: null,
        extension_id: 123264016,
        vulnerabilities: [],
      },
      {
        site_id: 122,
        version: '1.4.7',
        install_name: 'testsite1202',
        active: true,
        update: null,
        extension_id: 186866043,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'monsterinsights/monsterinsights.php',
    name: 'MonsterInsights',
    type: 'plugin',
    sites: [
      {
        site_id: 50,
        version: '1.8.14',
        install_name: 'testsite500',
        active: true,
        update: {
          version: '3.4.1',
          released_at: '2022-11-25 12:41:55',
          update_id: 26113,
        },
        extension_id: 115233799,
        vulnerabilities: [
          {
            external_id: 'wsscan uuid5',
            severity: 'high',
            fixed_in_version: '3.4.1',
            type: 'FILE DELETION',
            description:
              'A vulnerability exists wherein a user could read, upload, delete, or access an arbitrary file from your site. This could result in the disclosure of sensitive information or lead to complete site compromise.',
            last_updated_at: '2022-11-25T12:38:29.000Z',
          },
        ],
      },
      {
        site_id: 51,
        version: '1.4.14',
        install_name: 'testsite501',
        active: true,
        update: {
          version: '3.4.1',
          released_at: '2022-04-05 12:41:55',
          update_id: 37992,
        },
        extension_id: 991274572,
        vulnerabilities: [],
      },
      {
        site_id: 52,
        version: '1.3.13',
        install_name: 'testsite502',
        active: true,
        update: null,
        extension_id: 728219074,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'allinoneseo/allinoneseo.php',
    name: 'All in One SEO',
    type: 'plugin',
    sites: [
      {
        site_id: 10,
        version: '1.8.6',
        install_name: 'testsite100',
        active: true,
        update: {
          version: '5.3.4',
          released_at: '2022-06-30 00:52:10',
          update_id: 97821,
        },
        extension_id: 185027409,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        version: '1.6.8',
        install_name: 'testsite101',
        active: true,
        update: {
          version: '5.3.4',
          released_at: '2022-06-30 00:52:10',
          update_id: 40868,
        },
        extension_id: 792297169,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.9.5',
        install_name: 'testsite102',
        active: true,
        update: {
          version: '5.3.4',
          released_at: '2022-06-30 00:52:10',
          update_id: 99118,
        },
        extension_id: 200139860,
        vulnerabilities: [],
      },
      {
        site_id: 110,
        version: '1.7.11',
        install_name: 'testsite1100',
        active: true,
        update: {
          version: '5.3.4',
          released_at: '2022-06-30 00:52:10',
          update_id: 56219,
        },
        extension_id: 347554758,
        vulnerabilities: [],
      },
      {
        site_id: 111,
        version: '1.2.13',
        install_name: 'testsite1101',
        active: true,
        update: null,
        extension_id: 755195577,
        vulnerabilities: [],
      },
      {
        site_id: 112,
        version: '1.2.7',
        install_name: 'testsite1102',
        active: true,
        update: {
          version: '5.3.4',
          released_at: '2022-06-30 00:52:10',
          update_id: 45471,
        },
        extension_id: 356549472,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'wpmailsmtpbywpforms/wpmailsmtpbywpforms.php',
    name: 'WP Mail SMTP by WPForms',
    type: 'plugin',
    sites: [
      {
        site_id: 140,
        version: '1.6.2',
        install_name: 'testsite1400',
        active: true,
        update: null,
        extension_id: 195445973,
        vulnerabilities: [],
      },
      {
        site_id: 141,
        version: '1.3.1',
        install_name: 'testsite1401',
        active: true,
        update: null,
        extension_id: 622992987,
        vulnerabilities: [],
      },
      {
        site_id: 142,
        version: '1.5.2',
        install_name: 'testsite1402',
        active: true,
        update: null,
        extension_id: 491877176,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'advancededitortools/advancededitortools.php',
    name: 'Advanced Editor Tools',
    type: 'plugin',
    sites: [
      {
        site_id: 70,
        version: '1.8.11',
        install_name: 'testsite700',
        active: true,
        update: {
          version: '2.1.2',
          released_at: '2021-12-06 06:02:14',
          update_id: 63410,
        },
        extension_id: 765602365,
        vulnerabilities: [],
      },
      {
        site_id: 71,
        version: '1.9.9',
        install_name: 'testsite701',
        active: false,
        update: null,
        extension_id: 806126169,
        vulnerabilities: [],
      },
      {
        site_id: 72,
        version: '1.9.5',
        install_name: 'testsite702',
        active: true,
        update: null,
        extension_id: 143249650,
        vulnerabilities: [],
      },
      {
        site_id: 110,
        version: '1.1.10',
        install_name: 'testsite1100',
        active: true,
        update: {
          version: '2.1.2',
          released_at: '2021-12-06 06:02:14',
          update_id: 36548,
        },
        extension_id: 424430671,
        vulnerabilities: [],
      },
      {
        site_id: 111,
        version: '1.2.9',
        install_name: 'testsite1101',
        active: true,
        update: null,
        extension_id: 316046607,
        vulnerabilities: [],
      },
      {
        site_id: 112,
        version: '1.4.9',
        install_name: 'testsite1102',
        active: true,
        update: null,
        extension_id: 366294191,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'duplicatepage/duplicatepage.php',
    name: 'Duplicate Page',
    type: 'plugin',
    sites: [
      {
        site_id: 60,
        version: '1.8.11',
        install_name: 'testsite600',
        active: true,
        update: null,
        extension_id: 262449420,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.5.11',
        install_name: 'testsite601',
        active: true,
        update: null,
        extension_id: 413496788,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.3.2',
        install_name: 'testsite602',
        active: true,
        update: {
          version: '5.3.1',
          released_at: '2022-04-07 22:44:43',
          update_id: 64046,
        },
        extension_id: 638546530,
        vulnerabilities: [],
      },
      {
        site_id: 120,
        version: '1.4.10',
        install_name: 'testsite1200',
        active: true,
        update: {
          version: '5.3.1',
          released_at: '2022-04-07 22:44:43',
          update_id: 56903,
        },
        extension_id: 577033918,
        vulnerabilities: [],
      },
      {
        site_id: 121,
        version: '1.3.4',
        install_name: 'testsite1201',
        active: true,
        update: null,
        extension_id: 439276963,
        vulnerabilities: [],
      },
      {
        site_id: 122,
        version: '1.2.1',
        install_name: 'testsite1202',
        active: true,
        update: null,
        extension_id: 788732667,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'redirection/redirection.php',
    name: 'Redirection',
    type: 'plugin',
    sites: [
      {
        site_id: 20,
        version: '1.5.10',
        install_name: 'testsite200',
        active: true,
        update: null,
        extension_id: 981472880,
        vulnerabilities: [],
      },
      {
        site_id: 21,
        version: '1.7.1',
        install_name: 'testsite201',
        active: true,
        update: null,
        extension_id: 753101342,
        vulnerabilities: [],
      },
      {
        site_id: 22,
        version: '1.4.14',
        install_name: 'testsite202',
        active: true,
        update: {
          version: '4.5.2',
          released_at: '2022-08-03 08:40:20',
          update_id: 87810,
        },
        extension_id: 443258320,
        vulnerabilities: [],
      },
      {
        site_id: 140,
        version: '1.2.4',
        install_name: 'testsite1400',
        active: true,
        update: {
          version: '4.5.2',
          released_at: '2022-08-03 08:40:20',
          update_id: 46469,
        },
        extension_id: 759075218,
        vulnerabilities: [],
      },
      {
        site_id: 141,
        version: '1.6.2',
        install_name: 'testsite1401',
        active: true,
        update: {
          version: '4.5.2',
          released_at: '2022-08-03 08:40:20',
          update_id: 79379,
        },
        extension_id: 86347537,
        vulnerabilities: [],
      },
      {
        site_id: 142,
        version: '1.8.14',
        install_name: 'testsite1402',
        active: true,
        update: null,
        extension_id: 509226600,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'sitekitbygoogle/sitekitbygoogle.php',
    name: 'Site Kit by Google',
    type: 'plugin',
    sites: [
      {
        site_id: 120,
        version: '1.1.3',
        install_name: 'testsite1200',
        active: true,
        update: {
          version: '2.3.2',
          released_at: '2021-10-26 07:22:11',
          update_id: 92282,
        },
        extension_id: 792681380,
        vulnerabilities: [],
      },
      {
        site_id: 121,
        version: '1.2.11',
        install_name: 'testsite1201',
        active: true,
        update: null,
        extension_id: 500035498,
        vulnerabilities: [],
      },
      {
        site_id: 122,
        version: '1.4.2',
        install_name: 'testsite1202',
        active: true,
        update: null,
        extension_id: 393206260,
        vulnerabilities: [],
      },
      {
        site_id: 150,
        version: '1.2.10',
        install_name: 'testsite1500',
        active: true,
        update: {
          version: '2.3.2',
          released_at: '2021-10-26 07:22:11',
          update_id: 96155,
        },
        extension_id: 312038978,
        vulnerabilities: [],
      },
      {
        site_id: 151,
        version: '1.8.7',
        install_name: 'testsite1501',
        active: true,
        update: null,
        extension_id: 170979738,
        vulnerabilities: [],
      },
      {
        site_id: 152,
        version: '1.8.12',
        install_name: 'testsite1502',
        active: true,
        update: null,
        extension_id: 324454690,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'mailchimpforwordpress/mailchimpforwordpress.php',
    name: 'Mailchimp for WordPress',
    type: 'plugin',
    sites: [
      {
        site_id: 60,
        version: '1.4.10',
        install_name: 'testsite600',
        active: true,
        update: {
          version: '3.1.1',
          released_at: '2022-06-19 23:57:15',
          update_id: 38970,
        },
        extension_id: 60860451,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.1.4',
        install_name: 'testsite601',
        active: true,
        update: {
          version: '3.1.1',
          released_at: '2022-06-19 23:57:15',
          update_id: 89744,
        },
        extension_id: 754302813,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.3.5',
        install_name: 'testsite602',
        active: true,
        update: {
          version: '3.1.1',
          released_at: '2022-06-19 23:57:15',
          update_id: 38832,
        },
        extension_id: 628451994,
        vulnerabilities: [],
      },
      {
        site_id: 70,
        version: '1.5.2',
        install_name: 'testsite700',
        active: true,
        update: null,
        extension_id: 825149142,
        vulnerabilities: [],
      },
      {
        site_id: 71,
        version: '1.7.9',
        install_name: 'testsite701',
        active: true,
        update: {
          version: '3.1.1',
          released_at: '2022-06-19 23:57:15',
          update_id: 87660,
        },
        extension_id: 611829750,
        vulnerabilities: [],
      },
      {
        site_id: 72,
        version: '1.3.13',
        install_name: 'testsite702',
        active: true,
        update: null,
        extension_id: 647508968,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'advancedcustomfields/advancedcustomfields.php',
    name: 'Advanced Custom Fields',
    type: 'plugin',
    sites: [
      {
        site_id: 50,
        version: '1.9.12',
        install_name: 'testsite500',
        active: true,
        update: {
          version: '5.2.1',
          released_at: '2021-09-09 13:26:32',
          update_id: 38210,
        },
        extension_id: 906340100,
        vulnerabilities: [],
      },
      {
        site_id: 51,
        version: '1.1.12',
        install_name: 'testsite501',
        active: true,
        update: {
          version: '5.2.1',
          released_at: '2021-09-09 13:26:32',
          update_id: 72131,
        },
        extension_id: 356240640,
        vulnerabilities: [],
      },
      {
        site_id: 52,
        version: '1.4.2',
        install_name: 'testsite502',
        active: true,
        update: {
          version: '5.2.1',
          released_at: '2021-09-09 13:26:32',
          update_id: 59476,
        },
        extension_id: 274136180,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'limitloginattemptsreloaded/limitloginattemptsreloaded.php',
    name: 'Limit Login Attempts Reloaded',
    type: 'plugin',
    sites: [
      {
        site_id: 120,
        version: '1.6.13',
        install_name: 'testsite1200',
        active: true,
        update: {
          version: '3.4.1',
          released_at: '2022-06-02 22:03:39',
          update_id: 92673,
        },
        extension_id: 647650566,
        vulnerabilities: [],
      },
      {
        site_id: 121,
        version: '1.7.6',
        install_name: 'testsite1201',
        active: true,
        update: null,
        extension_id: 205903391,
        vulnerabilities: [],
      },
      {
        site_id: 122,
        version: '1.9.5',
        install_name: 'testsite1202',
        active: true,
        update: {
          version: '3.4.1',
          released_at: '2022-06-02 22:03:39',
          update_id: 51340,
        },
        extension_id: 811919695,
        vulnerabilities: [],
      },
      {
        site_id: 150,
        version: '1.3.6',
        install_name: 'testsite1500',
        active: true,
        update: {
          version: '3.4.1',
          released_at: '2022-06-02 22:03:39',
          update_id: 63286,
        },
        extension_id: 401594396,
        vulnerabilities: [],
      },
      {
        site_id: 151,
        version: '1.4.8',
        install_name: 'testsite1501',
        active: true,
        update: null,
        extension_id: 167076887,
        vulnerabilities: [],
      },
      {
        site_id: 152,
        version: '1.9.10',
        install_name: 'testsite1502',
        active: true,
        update: {
          version: '3.4.1',
          released_at: '2022-06-02 22:03:39',
          update_id: 68413,
        },
        extension_id: 23339067,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'wpsupercache/wpsupercache.php',
    name: 'WP Super Cache',
    type: 'plugin',
    sites: [
      {
        site_id: 40,
        version: '1.7.7',
        install_name: 'testsite400',
        active: true,
        update: null,
        extension_id: 560354426,
        vulnerabilities: [],
      },
      {
        site_id: 41,
        version: '1.4.8',
        install_name: 'testsite401',
        active: true,
        update: null,
        extension_id: 771482147,
        vulnerabilities: [],
      },
      {
        site_id: 42,
        version: '1.6.9',
        install_name: 'testsite402',
        active: true,
        update: null,
        extension_id: 170533702,
        vulnerabilities: [],
      },
      {
        site_id: 60,
        version: '1.7.1',
        install_name: 'testsite600',
        active: true,
        update: null,
        extension_id: 308087185,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.9.14',
        install_name: 'testsite601',
        active: true,
        update: null,
        extension_id: 484699737,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.7.8',
        install_name: 'testsite602',
        active: true,
        update: null,
        extension_id: 17015482,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'xmlsitemaps/xmlsitemaps.php',
    name: 'XML Sitemaps',
    type: 'plugin',
    sites: [
      {
        site_id: 40,
        version: '1.6.8',
        install_name: 'testsite400',
        active: true,
        update: null,
        extension_id: 777725070,
        vulnerabilities: [],
      },
      {
        site_id: 41,
        version: '1.4.6',
        install_name: 'testsite401',
        active: true,
        update: null,
        extension_id: 589078346,
        vulnerabilities: [],
      },
      {
        site_id: 42,
        version: '1.5.10',
        install_name: 'testsite402',
        active: true,
        update: null,
        extension_id: 444171682,
        vulnerabilities: [],
      },
      {
        site_id: 130,
        version: '1.3.9',
        install_name: 'testsite1300',
        active: true,
        update: null,
        extension_id: 251098233,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.5.3',
        install_name: 'testsite1301',
        active: true,
        update: null,
        extension_id: 4452012,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.7.8',
        install_name: 'testsite1302',
        active: true,
        update: {
          version: '2.3.2',
          released_at: '2021-09-02 16:06:54',
          update_id: 14918,
        },
        extension_id: 730880496,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'wpcode/wpcode.php',
    name: 'WPCode',
    type: 'plugin',
    sites: [
      {
        site_id: 70,
        version: '1.1.8',
        install_name: 'testsite700',
        active: true,
        update: null,
        extension_id: 267130612,
        vulnerabilities: [],
      },
      {
        site_id: 71,
        version: '1.3.3',
        install_name: 'testsite701',
        active: true,
        update: null,
        extension_id: 639793200,
        vulnerabilities: [],
      },
      {
        site_id: 72,
        version: '1.5.3',
        install_name: 'testsite702',
        active: true,
        update: null,
        extension_id: 301727255,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'duplicator/duplicator.php',
    name: 'Duplicator',
    type: 'plugin',
    sites: [
      {
        site_id: 90,
        version: '1.2.13',
        install_name: 'testsite900',
        active: true,
        update: {
          version: '2.4.1',
          released_at: '2022-06-24 15:17:17',
          update_id: 72071,
        },
        extension_id: 556657955,
        vulnerabilities: [],
      },
      {
        site_id: 91,
        version: '1.4.5',
        install_name: 'testsite901',
        active: true,
        update: null,
        extension_id: 33795332,
        vulnerabilities: [],
      },
      {
        site_id: 92,
        version: '1.5.6',
        install_name: 'testsite902',
        active: true,
        update: null,
        extension_id: 395046459,
        vulnerabilities: [],
      },
      {
        site_id: 120,
        version: '1.7.2',
        install_name: 'testsite1200',
        active: true,
        update: null,
        extension_id: 463382614,
        vulnerabilities: [],
      },
      {
        site_id: 121,
        version: '1.6.5',
        install_name: 'testsite1201',
        active: true,
        update: null,
        extension_id: 854294870,
        vulnerabilities: [],
      },
      {
        site_id: 122,
        version: '1.6.3',
        install_name: 'testsite1202',
        active: true,
        update: {
          version: '2.4.1',
          released_at: '2022-06-24 15:17:17',
          update_id: 25671,
        },
        extension_id: 618081831,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'smush/smush.php',
    name: 'Smush',
    type: 'plugin',
    sites: [
      {
        site_id: 50,
        version: '1.3.6',
        install_name: 'testsite500',
        active: false,
        update: null,
        extension_id: 771852076,
        vulnerabilities: [],
      },
      {
        site_id: 51,
        version: '1.3.5',
        install_name: 'testsite501',
        active: false,
        update: null,
        extension_id: 956780992,
        vulnerabilities: [],
      },
      {
        site_id: 52,
        version: '1.2.10',
        install_name: 'testsite502',
        active: false,
        update: null,
        extension_id: 154840122,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'locotranslate/locotranslate.php',
    name: 'Loco Translate',
    type: 'plugin',
    sites: [
      {
        site_id: 70,
        version: '1.4.3',
        install_name: 'testsite700',
        active: true,
        update: {
          version: '2.1.3',
          released_at: '2022-07-05 13:25:49',
          update_id: 93974,
        },
        extension_id: 84043356,
        vulnerabilities: [],
      },
      {
        site_id: 71,
        version: '1.3.1',
        install_name: 'testsite701',
        active: true,
        update: null,
        extension_id: 797214388,
        vulnerabilities: [],
      },
      {
        site_id: 72,
        version: '1.1.5',
        install_name: 'testsite702',
        active: true,
        update: null,
        extension_id: 283042737,
        vulnerabilities: [],
      },
      {
        site_id: 80,
        version: '1.3.8',
        install_name: 'testsite800',
        active: true,
        update: null,
        extension_id: 871425671,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        version: '1.6.4',
        install_name: 'testsite801',
        active: true,
        update: {
          version: '2.1.3',
          released_at: '2022-07-05 13:25:49',
          update_id: 44735,
        },
        extension_id: 217078760,
        vulnerabilities: [],
      },
      {
        site_id: 82,
        version: '1.1.12',
        install_name: 'testsite802',
        active: true,
        update: {
          version: '2.1.3',
          released_at: '2022-07-05 13:25:49',
          update_id: 10451,
        },
        extension_id: 53011047,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'regeneratethumbnails/regeneratethumbnails.php',
    name: 'Regenerate Thumbnails',
    type: 'plugin',
    sites: [
      {
        site_id: 140,
        version: '1.5.4',
        install_name: 'testsite1400',
        active: true,
        update: null,
        extension_id: 390087169,
        vulnerabilities: [],
      },
      {
        site_id: 141,
        version: '1.7.12',
        install_name: 'testsite1401',
        active: true,
        update: {
          version: '3.1.3',
          released_at: '2022-05-02 11:16:56',
          update_id: 68384,
        },
        extension_id: 139197743,
        vulnerabilities: [],
      },
      {
        site_id: 142,
        version: '1.8.12',
        install_name: 'testsite1402',
        active: true,
        update: null,
        extension_id: 176437071,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'essentialaddonsforelementor/essentialaddonsforelementor.php',
    name: 'Essential Addons for Elementor',
    type: 'plugin',
    sites: [
      {
        site_id: 50,
        version: '1.4.8',
        install_name: 'testsite500',
        active: true,
        update: {
          version: '2.2.5',
          released_at: '2022-03-24 21:39:30',
          update_id: 96475,
        },
        extension_id: 904649485,
        vulnerabilities: [],
      },
      {
        site_id: 51,
        version: '1.9.8',
        install_name: 'testsite501',
        active: true,
        update: null,
        extension_id: 729766898,
        vulnerabilities: [],
      },
      {
        site_id: 52,
        version: '1.4.6',
        install_name: 'testsite502',
        active: true,
        update: null,
        extension_id: 486180201,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'disablecomments/disablecomments.php',
    name: 'Disable Comments',
    type: 'plugin',
    sites: [
      {
        site_id: 110,
        version: '1.1.4',
        install_name: 'testsite1100',
        active: true,
        update: {
          version: '4.2.3',
          released_at: '2021-10-13 02:52:53',
          update_id: 55486,
        },
        extension_id: 605157895,
        vulnerabilities: [],
      },
      {
        site_id: 111,
        version: '1.4.3',
        install_name: 'testsite1101',
        active: true,
        update: {
          version: '4.2.3',
          released_at: '2021-10-13 02:52:53',
          update_id: 64139,
        },
        extension_id: 125636789,
        vulnerabilities: [],
      },
      {
        site_id: 112,
        version: '1.8.12',
        install_name: 'testsite1102',
        active: true,
        update: {
          version: '4.2.3',
          released_at: '2021-10-13 02:52:53',
          update_id: 47806,
        },
        extension_id: 77591736,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'wpfastestcache/wpfastestcache.php',
    name: 'WP Fastest Cache',
    type: 'plugin',
    sites: [
      {
        site_id: 20,
        version: '1.5.6',
        install_name: 'testsite200',
        active: true,
        update: null,
        extension_id: 751707786,
        vulnerabilities: [],
      },
      {
        site_id: 21,
        version: '1.8.2',
        install_name: 'testsite201',
        active: true,
        update: null,
        extension_id: 677530943,
        vulnerabilities: [],
      },
      {
        site_id: 22,
        version: '1.9.8',
        install_name: 'testsite202',
        active: true,
        update: null,
        extension_id: 268816728,
        vulnerabilities: [],
      },
      {
        site_id: 150,
        version: '1.1.11',
        install_name: 'testsite1500',
        active: true,
        update: null,
        extension_id: 905490047,
        vulnerabilities: [],
      },
      {
        site_id: 151,
        version: '1.1.12',
        install_name: 'testsite1501',
        active: true,
        update: {
          version: '2.1.1',
          released_at: '2021-10-20 12:42:59',
          update_id: 62719,
        },
        extension_id: 577425741,
        vulnerabilities: [],
      },
      {
        site_id: 152,
        version: '1.4.7',
        install_name: 'testsite1502',
        active: true,
        update: null,
        extension_id: 595387926,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'startertemplates/startertemplates.php',
    name: 'Starter Templates',
    type: 'plugin',
    sites: [
      {
        site_id: 50,
        version: '1.6.11',
        install_name: 'testsite500',
        active: true,
        update: null,
        extension_id: 148553154,
        vulnerabilities: [],
      },
      {
        site_id: 51,
        version: '1.7.10',
        install_name: 'testsite501',
        active: true,
        update: {
          version: '5.4.4',
          released_at: '2022-06-22 13:45:21',
          update_id: 13458,
        },
        extension_id: 896829171,
        vulnerabilities: [],
      },
      {
        site_id: 52,
        version: '1.2.2',
        install_name: 'testsite502',
        active: true,
        update: {
          version: '5.4.4',
          released_at: '2022-06-22 13:45:21',
          update_id: 46156,
        },
        extension_id: 393060165,
        vulnerabilities: [],
      },
      {
        site_id: 60,
        version: '1.3.9',
        install_name: 'testsite600',
        active: true,
        update: null,
        extension_id: 900861430,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.4.3',
        install_name: 'testsite601',
        active: true,
        update: {
          version: '5.4.4',
          released_at: '2022-06-22 13:45:21',
          update_id: 71918,
        },
        extension_id: 549256689,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.5.7',
        install_name: 'testsite602',
        active: true,
        update: null,
        extension_id: 488731871,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'sitegroundoptimizer/sitegroundoptimizer.php',
    name: 'SiteGround Optimizer',
    type: 'plugin',
    sites: [
      {
        site_id: 60,
        version: '1.1.9',
        install_name: 'testsite600',
        active: true,
        update: {
          version: '2.2.2',
          released_at: '2021-08-23 02:59:02',
          update_id: 26050,
        },
        extension_id: 181862338,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.7.6',
        install_name: 'testsite601',
        active: true,
        update: null,
        extension_id: 19541899,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.3.8',
        install_name: 'testsite602',
        active: true,
        update: {
          version: '2.2.2',
          released_at: '2021-08-23 02:59:02',
          update_id: 32976,
        },
        extension_id: 62774089,
        vulnerabilities: [],
      },
      {
        site_id: 130,
        version: '1.5.5',
        install_name: 'testsite1300',
        active: true,
        update: {
          version: '2.2.2',
          released_at: '2021-08-23 02:59:02',
          update_id: 11441,
        },
        extension_id: 628752197,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.6.3',
        install_name: 'testsite1301',
        active: true,
        update: {
          version: '2.2.2',
          released_at: '2021-08-23 02:59:02',
          update_id: 33346,
        },
        extension_id: 466211054,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.2.11',
        install_name: 'testsite1302',
        active: true,
        update: null,
        extension_id: 935544278,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'autoptimize/autoptimize.php',
    name: 'Autoptimize',
    type: 'plugin',
    sites: [
      {
        site_id: 30,
        version: '1.5.5',
        install_name: 'testsite300',
        active: true,
        update: null,
        extension_id: 249341804,
        vulnerabilities: [],
      },
      {
        site_id: 31,
        version: '1.7.4',
        install_name: 'testsite301',
        active: true,
        update: null,
        extension_id: 832866552,
        vulnerabilities: [],
      },
      {
        site_id: 32,
        version: '1.7.10',
        install_name: 'testsite302',
        active: true,
        update: {
          version: '2.2.1',
          released_at: '2021-08-31 21:21:01',
          update_id: 45837,
        },
        extension_id: 884361271,
        vulnerabilities: [],
      },
      {
        site_id: 40,
        version: '1.3.5',
        install_name: 'testsite400',
        active: true,
        update: {
          version: '2.2.1',
          released_at: '2021-08-31 21:21:01',
          update_id: 68402,
        },
        extension_id: 857379118,
        vulnerabilities: [],
      },
      {
        site_id: 41,
        version: '1.4.10',
        install_name: 'testsite401',
        active: true,
        update: null,
        extension_id: 728641000,
        vulnerabilities: [],
      },
      {
        site_id: 42,
        version: '1.9.4',
        install_name: 'testsite402',
        active: true,
        update: {
          version: '2.2.1',
          released_at: '2021-08-31 21:21:01',
          update_id: 19152,
        },
        extension_id: 585920209,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'loginizer/loginizer.php',
    name: 'Loginizer',
    type: 'plugin',
    sites: [
      {
        site_id: 80,
        version: '1.7.3',
        install_name: 'testsite800',
        active: true,
        update: null,
        extension_id: 218929301,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        version: '1.9.10',
        install_name: 'testsite801',
        active: true,
        update: {
          version: '2.1.4',
          released_at: '2022-08-05 03:37:15',
          update_id: 14305,
        },
        extension_id: 593958522,
        vulnerabilities: [],
      },
      {
        site_id: 82,
        version: '1.2.4',
        install_name: 'testsite802',
        active: true,
        update: {
          version: '2.1.4',
          released_at: '2022-08-05 03:37:15',
          update_id: 29923,
        },
        extension_id: 52723607,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'classicwidgets/classicwidgets.php',
    name: 'Classic Widgets',
    type: 'plugin',
    sites: [
      {
        site_id: 110,
        version: '1.3.7',
        install_name: 'testsite1100',
        active: true,
        update: null,
        extension_id: 710234657,
        vulnerabilities: [],
      },
      {
        site_id: 111,
        version: '1.5.9',
        install_name: 'testsite1101',
        active: true,
        update: {
          version: '2.4.5',
          released_at: '2022-01-08 16:37:13',
          update_id: 58814,
        },
        extension_id: 258852717,
        vulnerabilities: [],
      },
      {
        site_id: 112,
        version: '1.2.4',
        install_name: 'testsite1102',
        active: true,
        update: {
          version: '2.4.5',
          released_at: '2022-01-08 16:37:13',
          update_id: 86554,
        },
        extension_id: 42837737,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'rankmathseo/rankmathseo.php',
    name: 'Rank Math SEO',
    type: 'plugin',
    sites: [
      {
        site_id: 110,
        version: '1.6.11',
        install_name: 'testsite1100',
        active: true,
        update: null,
        extension_id: 995969499,
        vulnerabilities: [],
      },
      {
        site_id: 111,
        version: '1.6.8',
        install_name: 'testsite1101',
        active: true,
        update: null,
        extension_id: 448908630,
        vulnerabilities: [],
      },
      {
        site_id: 112,
        version: '1.8.14',
        install_name: 'testsite1102',
        active: true,
        update: {
          version: '2.5.4',
          released_at: '2022-06-15 13:24:14',
          update_id: 81194,
        },
        extension_id: 331932168,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'elementorheaderfooterbuilder/elementorheaderfooterbuilder.php',
    name: 'Elementor Header & Footer Builder',
    type: 'plugin',
    sites: [
      {
        site_id: 30,
        version: '1.6.10',
        install_name: 'testsite300',
        active: true,
        update: null,
        extension_id: 850241899,
        vulnerabilities: [],
      },
      {
        site_id: 31,
        version: '1.8.6',
        install_name: 'testsite301',
        active: true,
        update: {
          version: '4.5.2',
          released_at: '2022-01-17 08:14:53',
          update_id: 87445,
        },
        extension_id: 23049537,
        vulnerabilities: [],
      },
      {
        site_id: 32,
        version: '1.4.11',
        install_name: 'testsite302',
        active: true,
        update: {
          version: '4.5.2',
          released_at: '2022-01-17 08:14:53',
          update_id: 29409,
        },
        extension_id: 323669738,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'cookieyes/cookieyes.php',
    name: 'CookieYes',
    type: 'plugin',
    sites: [
      {
        site_id: 30,
        version: '1.2.12',
        install_name: 'testsite300',
        active: true,
        update: {
          version: '3.4.3',
          released_at: '2021-08-10 09:34:40',
          update_id: 12816,
        },
        extension_id: 477961561,
        vulnerabilities: [],
      },
      {
        site_id: 31,
        version: '1.7.9',
        install_name: 'testsite301',
        active: true,
        update: {
          version: '3.4.3',
          released_at: '2021-08-10 09:34:40',
          update_id: 11395,
        },
        extension_id: 942967873,
        vulnerabilities: [],
      },
      {
        site_id: 32,
        version: '1.1.11',
        install_name: 'testsite302',
        active: true,
        update: {
          version: '3.4.3',
          released_at: '2021-08-10 09:34:40',
          update_id: 57316,
        },
        extension_id: 124721596,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'cookienotice/cookienotice.php',
    name: 'Cookie Notice',
    type: 'plugin',
    sites: [
      {
        site_id: 90,
        version: '1.5.10',
        install_name: 'testsite900',
        active: true,
        update: null,
        extension_id: 971068988,
        vulnerabilities: [],
      },
      {
        site_id: 91,
        version: '1.8.6',
        install_name: 'testsite901',
        active: true,
        update: {
          version: '2.2.4',
          released_at: '2022-01-30 07:12:19',
          update_id: 20517,
        },
        extension_id: 620612081,
        vulnerabilities: [],
      },
      {
        site_id: 92,
        version: '1.5.9',
        install_name: 'testsite902',
        active: true,
        update: {
          version: '2.2.4',
          released_at: '2022-01-30 07:12:19',
          update_id: 23783,
        },
        extension_id: 769438223,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'popupbuilder/popupbuilder.php',
    name: 'Popup Builder',
    type: 'plugin',
    sites: [
      {
        site_id: 30,
        version: '1.3.2',
        install_name: 'testsite300',
        active: true,
        update: null,
        extension_id: 417466901,
        vulnerabilities: [],
      },
      {
        site_id: 31,
        version: '1.4.1',
        install_name: 'testsite301',
        active: true,
        update: null,
        extension_id: 900806222,
        vulnerabilities: [],
      },
      {
        site_id: 32,
        version: '1.4.14',
        install_name: 'testsite302',
        active: true,
        update: {
          version: '4.5.5',
          released_at: '2022-03-14 13:03:31',
          update_id: 66458,
        },
        extension_id: 467744110,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'bettersearchreplace/bettersearchreplace.php',
    name: 'Better Search Replace',
    type: 'plugin',
    sites: [
      {
        site_id: 10,
        version: '1.7.3',
        install_name: 'testsite100',
        active: true,
        update: null,
        extension_id: 689616815,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        version: '1.2.10',
        install_name: 'testsite101',
        active: true,
        update: null,
        extension_id: 394122438,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.4.14',
        install_name: 'testsite102',
        active: true,
        update: {
          version: '2.1.4',
          released_at: '2022-05-01 12:54:43',
          update_id: 19762,
        },
        extension_id: 141054861,
        vulnerabilities: [],
      },
      {
        site_id: 100,
        version: '1.1.10',
        install_name: 'testsite1000',
        active: true,
        update: null,
        extension_id: 46249305,
        vulnerabilities: [],
      },
      {
        site_id: 101,
        version: '1.1.8',
        install_name: 'testsite1001',
        active: true,
        update: null,
        extension_id: 924041242,
        vulnerabilities: [],
      },
      {
        site_id: 102,
        version: '1.1.6',
        install_name: 'testsite1002',
        active: true,
        update: null,
        extension_id: 736302418,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'managewpworker/managewpworker.php',
    name: 'ManageWP Worker',
    type: 'plugin',
    sites: [
      {
        site_id: 110,
        version: '1.5.2',
        install_name: 'testsite1100',
        active: true,
        update: null,
        extension_id: 721824873,
        vulnerabilities: [],
      },
      {
        site_id: 111,
        version: '1.5.11',
        install_name: 'testsite1101',
        active: true,
        update: {
          version: '4.3.3',
          released_at: '2021-12-19 18:19:14',
          update_id: 16042,
        },
        extension_id: 562019532,
        vulnerabilities: [],
      },
      {
        site_id: 112,
        version: '1.5.12',
        install_name: 'testsite1102',
        active: true,
        update: {
          version: '4.3.3',
          released_at: '2021-12-19 18:19:14',
          update_id: 92518,
        },
        extension_id: 823757755,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'smashballoonsocialphotofeed/smashballoonsocialphotofeed.php',
    name: 'Smash Balloon Social Photo Feed',
    type: 'plugin',
    sites: [
      {
        site_id: 60,
        version: '1.5.8',
        install_name: 'testsite600',
        active: true,
        update: {
          version: '2.4.1',
          released_at: '2021-11-02 11:03:50',
          update_id: 43531,
        },
        extension_id: 547611691,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.3.6',
        install_name: 'testsite601',
        active: true,
        update: {
          version: '2.4.1',
          released_at: '2021-11-02 11:03:50',
          update_id: 88026,
        },
        extension_id: 284066210,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.7.1',
        install_name: 'testsite602',
        active: true,
        update: {
          version: '2.4.1',
          released_at: '2021-11-02 11:03:50',
          update_id: 97323,
        },
        extension_id: 314197728,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'w3totalcache/w3totalcache.php',
    name: 'W3 Total Cache',
    type: 'plugin',
    sites: [
      {
        site_id: 60,
        version: '1.9.13',
        install_name: 'testsite600',
        active: true,
        update: null,
        extension_id: 210825821,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.3.8',
        install_name: 'testsite601',
        active: true,
        update: {
          version: '2.2.3',
          released_at: '2021-11-26 18:26:42',
          update_id: 67475,
        },
        extension_id: 72076853,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.4.11',
        install_name: 'testsite602',
        active: true,
        update: null,
        extension_id: 244201314,
        vulnerabilities: [],
      },
      {
        site_id: 90,
        version: '1.7.2',
        install_name: 'testsite900',
        active: true,
        update: null,
        extension_id: 621627891,
        vulnerabilities: [],
      },
      {
        site_id: 91,
        version: '1.1.8',
        install_name: 'testsite901',
        active: true,
        update: null,
        extension_id: 399185750,
        vulnerabilities: [],
      },
      {
        site_id: 92,
        version: '1.4.5',
        install_name: 'testsite902',
        active: true,
        update: null,
        extension_id: 671716967,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'wpshidelogin/wpshidelogin.php',
    name: 'WPS Hide Login',
    type: 'plugin',
    sites: [
      {
        site_id: 40,
        version: '1.1.10',
        install_name: 'testsite400',
        active: true,
        update: null,
        extension_id: 251330680,
        vulnerabilities: [],
      },
      {
        site_id: 41,
        version: '1.7.3',
        install_name: 'testsite401',
        active: true,
        update: {
          version: '5.5.2',
          released_at: '2022-02-23 07:18:33',
          update_id: 85580,
        },
        extension_id: 400229639,
        vulnerabilities: [],
      },
      {
        site_id: 42,
        version: '1.4.8',
        install_name: 'testsite402',
        active: true,
        update: {
          version: '5.5.2',
          released_at: '2022-02-23 07:18:33',
          update_id: 32891,
        },
        extension_id: 354977665,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'oneclickdemoimport/oneclickdemoimport.php',
    name: 'One Click Demo Import',
    type: 'plugin',
    sites: [
      {
        site_id: 70,
        version: '1.9.7',
        install_name: 'testsite700',
        active: true,
        update: null,
        extension_id: 620025471,
        vulnerabilities: [],
      },
      {
        site_id: 71,
        version: '1.5.6',
        install_name: 'testsite701',
        active: true,
        update: null,
        extension_id: 391202882,
        vulnerabilities: [],
      },
      {
        site_id: 72,
        version: '1.6.2',
        install_name: 'testsite702',
        active: true,
        update: null,
        extension_id: 818788548,
        vulnerabilities: [],
      },
      {
        site_id: 120,
        version: '1.1.5',
        install_name: 'testsite1200',
        active: true,
        update: null,
        extension_id: 498778437,
        vulnerabilities: [],
      },
      {
        site_id: 121,
        version: '1.7.3',
        install_name: 'testsite1201',
        active: true,
        update: {
          version: '2.4.3',
          released_at: '2021-08-18 20:43:27',
          update_id: 31170,
        },
        extension_id: 883402025,
        vulnerabilities: [],
      },
      {
        site_id: 122,
        version: '1.9.12',
        install_name: 'testsite1202',
        active: true,
        update: null,
        extension_id: 340412472,
        vulnerabilities: [],
      },
      {
        site_id: 140,
        version: '1.7.1',
        install_name: 'testsite1400',
        active: true,
        update: null,
        extension_id: 66220241,
        vulnerabilities: [],
      },
      {
        site_id: 141,
        version: '1.5.9',
        install_name: 'testsite1401',
        active: true,
        update: {
          version: '2.4.3',
          released_at: '2021-08-18 20:43:27',
          update_id: 79093,
        },
        extension_id: 290667897,
        vulnerabilities: [],
      },
      {
        site_id: 142,
        version: '1.1.10',
        install_name: 'testsite1402',
        active: true,
        update: null,
        extension_id: 177697952,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'customposttypeui/customposttypeui.php',
    name: 'Custom Post Type UI',
    type: 'plugin',
    sites: [
      {
        site_id: 70,
        version: '1.2.14',
        install_name: 'testsite700',
        active: true,
        update: null,
        extension_id: 259523409,
        vulnerabilities: [],
      },
      {
        site_id: 71,
        version: '1.8.6',
        install_name: 'testsite701',
        active: true,
        update: {
          version: '4.4.4',
          released_at: '2022-07-02 11:58:35',
          update_id: 98566,
        },
        extension_id: 461508881,
        vulnerabilities: [],
      },
      {
        site_id: 72,
        version: '1.4.1',
        install_name: 'testsite702',
        active: true,
        update: null,
        extension_id: 848262182,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'wp-optimize/wp-optimize.php',
    name: 'WP-Optimize',
    type: 'plugin',
    sites: [
      {
        site_id: 10,
        version: '1.7.6',
        install_name: 'testsite100',
        active: true,
        update: null,
        extension_id: 382335043,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        version: '1.8.11',
        install_name: 'testsite101',
        active: true,
        update: {
          version: '2.4.5',
          released_at: '2022-01-08 06:18:13',
          update_id: 32097,
        },
        extension_id: 289068096,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.3.12',
        install_name: 'testsite102',
        active: true,
        update: {
          version: '2.4.5',
          released_at: '2022-01-08 06:18:13',
          update_id: 50343,
        },
        extension_id: 390445027,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'autoupdater/autoupdater.php',
    name: 'WP Engine Smart Plugin Manager',
    type: 'plugin',
    sites: [
      {
        site_id: 100,
        version: '1.7.3',
        install_name: 'testsite1000',
        active: true,
        update: {
          version: '2.3.3',
          released_at: '2022-04-19 04:57:23',
          update_id: 31392,
        },
        extension_id: 180608156,
        vulnerabilities: [],
      },
      {
        site_id: 101,
        version: '1.2.2',
        install_name: 'testsite1001',
        active: true,
        update: {
          version: '2.3.3',
          released_at: '2022-04-19 04:57:23',
          update_id: 71687,
        },
        extension_id: 369425782,
        vulnerabilities: [],
      },
      {
        site_id: 102,
        version: '1.7.1',
        install_name: 'testsite1002',
        active: true,
        update: null,
        extension_id: 612744203,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug:
      'gutenbergtemplateandpatternlibrary/gutenbergtemplateandpatternlibrary.php',
    name: 'Gutenberg Template and Pattern Library',
    type: 'plugin',
    sites: [
      {
        site_id: 130,
        version: '1.3.2',
        install_name: 'testsite1300',
        active: true,
        update: null,
        extension_id: 367948366,
        vulnerabilities: [],
      },
      {
        site_id: 131,
        version: '1.9.3',
        install_name: 'testsite1301',
        active: true,
        update: null,
        extension_id: 348084899,
        vulnerabilities: [],
      },
      {
        site_id: 132,
        version: '1.3.3',
        install_name: 'testsite1302',
        active: true,
        update: {
          version: '4.5.5',
          released_at: '2022-05-15 10:14:16',
          update_id: 78303,
        },
        extension_id: 790466598,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'wpmultibytepatch/wpmultibytepatch.php',
    name: 'WP Multibyte Patch',
    type: 'plugin',
    sites: [
      {
        site_id: 10,
        version: '1.6.4',
        install_name: 'testsite100',
        active: true,
        update: null,
        extension_id: 954170394,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        version: '1.9.1',
        install_name: 'testsite101',
        active: true,
        update: {
          version: '3.4.1',
          released_at: '2021-09-11 13:33:03',
          update_id: 39130,
        },
        extension_id: 924712040,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.8.1',
        install_name: 'testsite102',
        active: true,
        update: null,
        extension_id: 787326433,
        vulnerabilities: [],
      },
      {
        site_id: 50,
        version: '1.3.11',
        install_name: 'testsite500',
        active: true,
        update: {
          version: '3.4.1',
          released_at: '2021-09-11 13:33:03',
          update_id: 18051,
        },
        extension_id: 164135224,
        vulnerabilities: [],
      },
      {
        site_id: 51,
        version: '1.7.7',
        install_name: 'testsite501',
        active: true,
        update: null,
        extension_id: 925786025,
        vulnerabilities: [],
      },
      {
        site_id: 52,
        version: '1.3.8',
        install_name: 'testsite502',
        active: true,
        update: null,
        extension_id: 242054817,
        vulnerabilities: [],
      },
      {
        site_id: 100,
        version: '1.6.9',
        install_name: 'testsite1000',
        active: true,
        update: {
          version: '3.4.1',
          released_at: '2021-09-11 13:33:03',
          update_id: 37380,
        },
        extension_id: 343618217,
        vulnerabilities: [],
      },
      {
        site_id: 101,
        version: '1.9.3',
        install_name: 'testsite1001',
        active: true,
        update: null,
        extension_id: 883451882,
        vulnerabilities: [],
      },
      {
        site_id: 102,
        version: '1.7.5',
        install_name: 'testsite1002',
        active: true,
        update: null,
        extension_id: 636734257,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'ithemessecurity/ithemessecurity.php',
    name: 'iThemes Security',
    type: 'plugin',
    sites: [
      {
        site_id: 60,
        version: '1.4.12',
        install_name: 'testsite600',
        active: true,
        update: null,
        extension_id: 905435878,
        vulnerabilities: [],
      },
      {
        site_id: 61,
        version: '1.1.5',
        install_name: 'testsite601',
        active: true,
        update: null,
        extension_id: 76081912,
        vulnerabilities: [],
      },
      {
        site_id: 62,
        version: '1.5.13',
        install_name: 'testsite602',
        active: true,
        update: {
          version: '2.3.2',
          released_at: '2022-04-09 14:35:54',
          update_id: 91145,
        },
        extension_id: 929425609,
        vulnerabilities: [],
      },
      {
        site_id: 80,
        version: '1.7.14',
        install_name: 'testsite800',
        active: true,
        update: null,
        extension_id: 983113768,
        vulnerabilities: [],
      },
      {
        site_id: 81,
        version: '1.4.13',
        install_name: 'testsite801',
        active: true,
        update: {
          version: '2.3.2',
          released_at: '2022-04-09 14:35:54',
          update_id: 37275,
        },
        extension_id: 799890500,
        vulnerabilities: [],
      },
      {
        site_id: 82,
        version: '1.2.13',
        install_name: 'testsite802',
        active: true,
        update: {
          version: '2.3.2',
          released_at: '2022-04-09 14:35:54',
          update_id: 70189,
        },
        extension_id: 429825824,
        vulnerabilities: [],
      },
      {
        site_id: 100,
        version: '1.1.9',
        install_name: 'testsite1000',
        active: true,
        update: null,
        extension_id: 862056075,
        vulnerabilities: [],
      },
      {
        site_id: 101,
        version: '1.7.10',
        install_name: 'testsite1001',
        active: true,
        update: {
          version: '2.3.2',
          released_at: '2022-04-09 14:35:54',
          update_id: 44358,
        },
        extension_id: 141718338,
        vulnerabilities: [],
      },
      {
        site_id: 102,
        version: '1.7.2',
        install_name: 'testsite1002',
        active: true,
        update: {
          version: '2.3.2',
          released_at: '2022-04-09 14:35:54',
          update_id: 40900,
        },
        extension_id: 597011610,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'allinonewpsecurity/allinonewpsecurity.php',
    name: 'All In One WP Security',
    type: 'plugin',
    sites: [
      {
        site_id: 10,
        version: '1.3.4',
        install_name: 'testsite100',
        active: true,
        update: {
          version: '3.1.2',
          released_at: '2022-01-13 11:50:48',
          update_id: 96231,
        },
        extension_id: 586716910,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        version: '1.4.4',
        install_name: 'testsite101',
        active: true,
        update: {
          version: '3.1.2',
          released_at: '2022-01-13 11:50:48',
          update_id: 82980,
        },
        extension_id: 547321499,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.7.2',
        install_name: 'testsite102',
        active: true,
        update: null,
        extension_id: 12223613,
        vulnerabilities: [],
      },
      {
        site_id: 100,
        version: '1.7.6',
        install_name: 'testsite1000',
        active: true,
        update: {
          version: '3.1.2',
          released_at: '2022-01-13 11:50:48',
          update_id: 38087,
        },
        extension_id: 835110110,
        vulnerabilities: [],
      },
      {
        site_id: 101,
        version: '1.6.4',
        install_name: 'testsite1001',
        active: true,
        update: {
          version: '3.1.2',
          released_at: '2022-01-13 11:50:48',
          update_id: 14230,
        },
        extension_id: 627661402,
        vulnerabilities: [],
      },
      {
        site_id: 102,
        version: '1.6.11',
        install_name: 'testsite1002',
        active: true,
        update: null,
        extension_id: 443240048,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'ninjaformscontactform/ninjaformscontactform.php',
    name: 'Ninja Forms Contact Form',
    type: 'plugin',
    sites: [
      {
        site_id: 10,
        version: '1.1.7',
        install_name: 'testsite100',
        active: true,
        update: {
          version: '2.3.1',
          released_at: '2022-03-08 16:46:55',
          update_id: 77696,
        },
        extension_id: 716884899,
        vulnerabilities: [],
      },
      {
        site_id: 11,
        version: '1.5.11',
        install_name: 'testsite101',
        active: true,
        update: {
          version: '2.3.1',
          released_at: '2022-03-08 16:46:55',
          update_id: 65291,
        },
        extension_id: 737186727,
        vulnerabilities: [],
      },
      {
        site_id: 12,
        version: '1.2.13',
        install_name: 'testsite102',
        active: true,
        update: {
          version: '2.3.1',
          released_at: '2022-03-08 16:46:55',
          update_id: 62469,
        },
        extension_id: 401173285,
        vulnerabilities: [],
      },
      {
        site_id: 20,
        version: '1.1.13',
        install_name: 'testsite200',
        active: true,
        update: null,
        extension_id: 530807777,
        vulnerabilities: [],
      },
      {
        site_id: 21,
        version: '1.7.2',
        install_name: 'testsite201',
        active: true,
        update: {
          version: '2.3.1',
          released_at: '2022-03-08 16:46:55',
          update_id: 15982,
        },
        extension_id: 587517555,
        vulnerabilities: [],
      },
      {
        site_id: 22,
        version: '1.4.7',
        install_name: 'testsite202',
        active: true,
        update: {
          version: '2.3.1',
          released_at: '2022-03-08 16:46:55',
          update_id: 13189,
        },
        extension_id: 51212800,
        vulnerabilities: [],
      },
    ],
  },
  {
    slug: 'woocommerceshipping/woocommerceshipping.php',
    name: 'WooCommerce Shipping',
    type: 'plugin',
    sites: [
      {
        site_id: 20,
        version: '1.2.2',
        install_name: 'testsite200',
        active: true,
        update: {
          version: '2.2.4',
          released_at: '2022-04-26 01:24:08',
          update_id: 86471,
        },
        extension_id: 818929707,
        vulnerabilities: [],
      },
      {
        site_id: 21,
        version: '1.2.5',
        install_name: 'testsite201',
        active: true,
        update: null,
        extension_id: 517902057,
        vulnerabilities: [],
      },
      {
        site_id: 22,
        version: '1.4.11',
        install_name: 'testsite202',
        active: true,
        update: {
          version: '2.2.4',
          released_at: '2022-04-26 01:24:08',
          update_id: 19791,
        },
        extension_id: 919733374,
        vulnerabilities: [],
      },
    ],
  },
];

export default plugins;
