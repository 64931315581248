import { support_path } from 'railsRoutes';

type LinkData = {
  href: string;
  text: string;
};

export const BuilderCommunity: LinkData = {
  href: 'https://wpengine.com/builders/',
  text: 'Builder community',
};

export const HeadlessCommunity: LinkData = {
  href: 'https://wpengine.com/builders/headless/',
  text: 'Headless community',
};

export const WpEngineApi: LinkData = {
  href: 'https://wpengineapi.com/',
  text: 'WP Engine API',
};

export const AgencyDirectory: LinkData = {
  href: 'https://wpengine.com/partners/agencies/',
  text: 'Agency directory',
};

export const ResourceCenter: LinkData = {
  href: 'https://wpengine.com/resources/',
  text: 'Resource center',
};

export const TorqueMagazine: LinkData = {
  href: 'https://torquemag.io/',
  text: 'Torque Magazine',
};

export const Velocitize: LinkData = {
  href: 'https://velocitize.com/',
  text: 'Velocitize',
};

export const Blog: LinkData = {
  href: 'https://wpengine.com/blog/',
  text: 'Blog',
};

export const EventsAndWebinars: LinkData = {
  href: 'https://wpengine.com/newsroom/events/',
  text: 'Events & webinars',
};

export const Newsroom: LinkData = {
  href: 'https://wpengine.com/newsroom/',
  text: 'Newsroom',
};

export const SupportCenter: LinkData = {
  href: support_path(),
  text: 'Support center',
};

export const ProductFeedback: LinkData = {
  href: 'https://wpengine-product.canny.io/user-portal',
  text: 'Product feedback',
};

export const MySupportTickets: LinkData = {
  href: 'https://help.wpengine.com/requests',
  text: 'My support tickets',
};
