import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const ApplicationPerformance: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate
      name="ApplicationPerformance logo"
      viewBox="0 0 64 64"
      {...props}
    >
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_1677_793)"
      />
      <path
        d="M44.0013 18.5845C40.8166 15.7336 36.6107 14 32 14C28.8177 14 25.8283 14.8258 23.2344 16.2748C22.8113 16.5112 22.2715 16.3552 21.9775 15.9698C21.6396 15.5269 21.681 14.8551 22.1659 14.5808C25.0698 12.9378 28.4254 12 32 12C37.1631 12 41.8692 13.9564 45.4174 17.1683L46.2929 16.2929C46.6834 15.9024 47.3166 15.9024 47.7071 16.2929C48.0976 16.6834 48.0976 17.3166 47.7071 17.7071L36.1716 29.2426C36.6951 30.033 37 30.9809 37 32C37 34.7614 34.7614 37 32 37C29.2386 37 27 34.7614 27 32C27 29.2386 29.2386 27 32 27C33.0191 27 33.967 27.3049 34.7574 27.8284L38.3287 24.2571C36.6049 22.8464 34.4013 22 32 22C27.7421 22 24.1058 24.6611 22.6634 28.4109C22.4986 28.8393 22.0402 29.0871 21.5843 29.0339C21.0024 28.9661 20.5265 28.4249 20.7279 27.8747C22.4101 23.2795 26.8221 20 32 20C34.9537 20 37.6582 21.0672 39.7488 22.8369L44.0013 18.5845Z"
        fill="#006BD6"
      />
      <path
        d="M50 32C50 30.7436 49.8713 29.5174 49.6263 28.3336C49.5144 27.7929 49.8288 27.2456 50.3624 27.1033C50.8964 26.9609 51.4473 27.2782 51.5623 27.8188C51.8491 29.1673 52 30.566 52 32C52 43.0457 43.0457 52 32 52C20.9543 52 12 43.0457 12 32C12 28.9531 12.6813 26.0653 13.9 23.4808C14.1376 22.9767 14.8035 22.885 15.2701 23.1897C15.6758 23.4546 15.8728 23.9818 15.6685 24.4212C14.5978 26.7247 14 29.2925 14 32C14 41.9411 22.0589 50 32 50C41.9411 50 50 41.9411 50 32Z"
        fill="#006BD6"
      />
      <path
        d="M44 32C44 31.2781 43.9362 30.571 43.8141 29.8842C43.7173 29.34 43.1646 29.0227 42.6306 29.1651C42.0971 29.3074 41.785 29.8548 41.8727 30.4C41.9565 30.921 42 31.4554 42 32C42 32.5404 41.9571 33.0708 41.8746 33.588C41.8086 34.002 42.0368 34.413 42.4043 34.6149C42.9521 34.916 43.7102 34.7072 43.8184 34.0915C43.9377 33.4123 44 32.7134 44 32Z"
        fill="#006BD6"
      />
      <path
        d="M18 22C17.6937 22 17.3981 21.9541 17.1198 21.8688C16.4568 21.6656 15.8916 21.239 15.5119 20.6767C15.1887 20.198 15 19.621 15 19C15 17.3431 16.3431 16 18 16C18.542 16 19.0504 16.1437 19.4893 16.3952C20.0809 16.7341 20.546 17.2688 20.7962 17.911C20.9278 18.2486 21 18.6159 21 19C21 20.6569 19.6569 22 18 22Z"
        fill="#006BD6"
      />
      <path
        d="M35.9724 41.9635C36.3432 42.4665 36.239 43.2473 35.6435 43.4369C34.4944 43.8026 33.2703 44 32 44C28.3643 44 25.1062 42.3831 22.9056 39.8293C22.5233 39.3856 22.7854 38.7086 23.3049 38.4382C23.7112 38.2268 24.2203 38.3001 24.5246 38.6424C26.3563 40.7023 29.0266 42 32 42C33.0029 42 33.9714 41.8524 34.8848 41.5776C35.2867 41.4567 35.7235 41.6256 35.9724 41.9635Z"
        fill="#006BD6"
      />
      <path
        d="M40 42C39.6211 42 39.2586 41.9298 38.9248 41.8016C38.2584 41.5457 37.7066 41.0589 37.3671 40.4392C37.1331 40.0119 37 39.5215 37 39C37 37.3431 38.3431 36 40 36C40.38 36 40.7435 36.0707 41.0781 36.1995C41.7444 36.4562 42.2959 36.9438 42.6348 37.5642C42.8676 37.9907 43 38.4799 43 39C43 40.6569 41.6569 42 40 42Z"
        fill="#006BD6"
      />
      <path
        d="M20.0289 31.1607C18.8486 31.5642 18 32.683 18 34C18 35.6569 19.3431 37 21 37L21.0097 37C21.0357 36.9999 21.0617 36.9995 21.0875 36.9987C21.801 36.9783 22.452 36.7088 22.9568 36.274C23.5956 35.7239 24 34.9092 24 34C24 32.7061 23.1809 31.6036 22.0329 31.1826C21.7109 31.0645 21.363 31 21 31C20.6601 31 20.3335 31.0565 20.0289 31.1607Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1677_793"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default ApplicationPerformance;
