import { customResponse } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import ENDPOINTS from 'componentsDir/sites/components/AddSite/constants/endpoints';

const {
  SITES_ADD_SITE_AND_INSTALL,
  SITES_ADD_SITE_AND_INSTALL_MOVE,
} = ENDPOINTS;

const handlers = [
  rest.post(`*${SITES_ADD_SITE_AND_INSTALL}`, (req, res, ctx) => {
    return customResponse(ctx.status(201));
  }),

  rest.post(`*${SITES_ADD_SITE_AND_INSTALL_MOVE}`, (req, res, ctx) => {
    return customResponse(ctx.status(201));
  }),
];

export default handlers;
