export interface InstallOperationStatus {
  id: number;
  jobType: 'backup';
  jobStatus: InstallOperationStatusType;
  startedTime: string;
  completedTime: string | null;
  updatedAt: string;
  initiatedBy: string | 'System';
  metadata: InstallOperationStatusMetadata;
  startedBy: string;
}

export enum InstallOperationStatusType {
  inProgress = 'in_progress',
  success = 'success',
  error = 'error',
}

interface InstallOperationStatusMetadata {
  description?: string;
  stepStates?: TrackerStepState[];
}

export interface TrackerStep {
  title: string;
  description: string;
  status: TrackerStepStatusType;
  timestamp: string;
}

export interface TrackerStepState {
  status: TrackerStepStatusType;
  timestamp: string;
}

export enum TrackerStepStatusType {
  current = 'current',
  pending = 'pending',
  completed = 'completed',
}
