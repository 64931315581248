import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const AvatarIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="avatar-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 14.561 19.9303 16.8721 18.2134 18.511C17.5598 16.8766 16.5174 15.6124 15.1982 14.8436C16.2992 13.9264 17 12.545 17 11C17 8.23858 14.7614 6 12 6C9.23857 6 7 8.23858 7 11C7 12.545 7.70079 13.9264 8.80184 14.8436C7.48259 15.6124 6.44017 16.8766 5.78658 18.511C4.06966 16.8721 3 14.561 3 12ZM7.45808 19.7716C8.79129 20.5524 10.3434 21 12 21C13.6566 21 15.2087 20.5524 16.5419 19.7716C15.742 17.2618 14.0253 16 12 16C9.97469 16 8.25801 17.2618 7.45808 19.7716ZM12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11Z"
        />
      </SvgTemplate>
    );
  }
);
export default AvatarIcon;
