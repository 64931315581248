import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { ENDPOINTS, MSW_PARAMS } from 'componentsDir/install/Cache/constants';

const purgeCacheHandlers = [
  rest.post(`*${ENDPOINTS.PURGE_CACHE}*`, (req, _res, ctx) => {
    if (hasPageUrlQueryParam(MSW_PARAMS.PURGE_CACHE_ERROR)) {
      return customResponse(ctx.status(500), ctx.json(false));
    }
    // @ts-ignore
    if (req?.body?.type === 'page') {
      if (hasPageUrlQueryParam(MSW_PARAMS.FAILED_PAGE_CACHING_PURGE)) {
        return customResponse(ctx.status(500), ctx.json(false));
      }
      return customResponse(ctx.status(202), ctx.json(true));
    }

    // @ts-ignore
    if (req?.body?.type === 'object') {
      if (hasPageUrlQueryParam(MSW_PARAMS.FAILED_OBJECT_CACHING_PURGE)) {
        return customResponse(ctx.status(500), ctx.json(false));
      }

      return customResponse(ctx.status(202), ctx.json(true));
    }

    // @ts-ignore
    if (req?.body?.type === 'cdn') {
      if (hasPageUrlQueryParam(MSW_PARAMS.FAILED_NETWORK_CACHING_PURGE)) {
        return customResponse(ctx.status(500), ctx.json(false));
      }

      return customResponse(ctx.status(202), ctx.json(true));
    }

    return customResponse(ctx.status(400), ctx.json(false));
  }),
];

export default purgeCacheHandlers;
