import FeatureFlagName from '../../constants/featureFlagName';
import { SplitFeatureFlag } from '../../types';

const generateFeatureFlag = (featureFlagName: string): SplitFeatureFlag => ({
  name: featureFlagName,
  status: 'ACTIVE',
  conditions: [
    {
      matcherGroup: {
        combiner: 'AND',
        matchers: [
          {
            keySelector: {
              trafficType: 'user',
            },
            matcherType: 'ALL_KEYS',
          },
        ],
      },
      partitions: [
        {
          treatment: 'on',
          size: 100,
        },
        {
          treatment: 'off',
          size: 0,
        },
      ],
    },
  ],
  defaultTreatment: 'on',
});

const mockFeatureFlags = (
  flagsToEnable: 'all' | string
): SplitFeatureFlag[] => {
  if (flagsToEnable === 'all') {
    // Lookup feature flags currently being used in Portal and enable them all.
    return Object.values(FeatureFlagName).map(flagName =>
      generateFeatureFlag(flagName)
    );
  }

  if (flagsToEnable === 'TEST_FEATURE') {
    // Used when testing general feature flag functionality not tied to a specific feature flag.
    return [generateFeatureFlag('TEST_FEATURE')];
  }

  // Only enable the flags specified in the page url query params. e.g ?featureFlagsToEnable=FLAG_1,FLAG_2
  return flagsToEnable
    .split(',')
    .map(flagName => generateFeatureFlag(flagName));
};

export default mockFeatureFlags;
