import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { colorWhite, blue1100, teal700 } from 'design-tokens';
import { useAppearance } from '../../components/DarkMode';

const WpEngineLogo = (props: SvgIconProps): JSX.Element => {
  const { isDark } = useAppearance();
  if (isDark) {
    return (
      <SvgIcon
        style={{ height: '2rem', width: '10rem' }}
        viewBox="0 0 1064 208"
        fill="none"
        {...props}
      >
        <path
          d="M1058.44 56.71L1054.62 65.8L1050.83 56.71H1048.14V69.17H1050.35V60.78L1054.04 69.17H1055.25L1058.9 60.81V69.17H1061.16V56.71H1058.44Z"
          fill={colorWhite}
        />
        <path
          d="M1036.16 58.66H1039.93V69.17H1042.18V58.66H1045.95V56.71H1036.16V58.66Z"
          fill={colorWhite}
        />
        <path
          d="M387.93 56.01H364.88C364.41 56.01 364 56.33 363.9 56.79C362.34 64.07 350.48 119.72 349.82 124.39C349.77 124.74 349.55 124.74 349.5 124.39C348.76 119.72 335.93 63.99 334.27 56.77C334.17 56.32 333.76 56 333.3 56H311.79C311.32 56 310.92 56.31 310.82 56.77C309.15 63.98 296.28 119.72 295.56 124.39C295.49 124.82 295.24 124.82 295.17 124.39C294.49 119.72 282.67 64.08 281.12 56.79C281.02 56.33 280.62 56 280.14 56H257.09C256.43 56 255.95 56.62 256.12 57.26L281.19 151.13C281.31 151.57 281.7 151.87 282.16 151.87H306.4C306.86 151.87 307.26 151.56 307.37 151.11C308.89 144.7 319.55 99.78 321.96 89.27C322.08 88.75 322.81 88.75 322.93 89.27C325.37 99.77 336.16 144.71 337.69 151.11C337.8 151.56 338.2 151.87 338.66 151.87H362.84C363.29 151.87 363.69 151.57 363.81 151.13L388.88 57.26C389.05 56.63 388.57 56 387.91 56L387.93 56.01Z"
          fill={colorWhite}
        />
        <path
          d="M462.52 60.19C457.29 57.4 451.07 56.01 443.86 56.01H406.59C406.04 56.01 405.59 56.46 405.59 57.01V150.88C405.59 151.43 406.04 151.88 406.59 151.88H427.3C427.85 151.88 428.3 151.43 428.3 150.88V121.96H443.21C450.54 121.96 456.87 120.59 462.19 117.84C467.51 115.1 471.61 111.28 474.48 106.39C477.35 101.5 478.79 95.75 478.79 89.15C478.79 82.55 477.38 76.82 474.57 71.84C471.76 66.86 467.74 62.98 462.51 60.19H462.52ZM455.41 96.93C454.21 99.16 452.41 100.9 450 102.14C447.6 103.38 444.62 104.01 441.06 104.01H428.31V74.41H441C444.56 74.41 447.55 75.01 449.97 76.21C452.39 77.41 454.2 79.12 455.41 81.33C456.61 83.54 457.21 86.15 457.21 89.15C457.21 92.15 456.61 94.71 455.41 96.94V96.93Z"
          fill={colorWhite}
        />
        <path
          d="M583.93 67.87C580.2 64.11 576 61.31 571.33 59.47C566.66 57.63 561.8 56.71 556.75 56.71C548.73 56.71 541.69 58.77 535.63 62.89C529.56 67.01 524.84 72.66 521.47 79.85C518.09 87.04 516.41 95.28 516.41 104.55C516.41 113.82 518.13 122.04 521.56 129.17C524.99 136.31 529.85 141.89 536.14 145.93C542.43 149.96 549.88 151.98 558.5 151.98C564.7 151.98 570.19 150.98 574.97 148.97C579.75 146.97 583.71 144.32 586.87 141.02C589.75 138.01 591.91 134.78 593.35 131.32C593.57 130.78 593.27 130.17 592.72 129.99L583.98 127.21C583.49 127.06 582.98 127.3 582.77 127.76C581.68 130.14 580.14 132.35 578.14 134.39C575.92 136.67 573.17 138.5 569.91 139.87C566.64 141.24 562.87 141.93 558.59 141.93C552.28 141.93 546.8 140.44 542.16 137.44C537.52 134.45 533.94 130.25 531.41 124.84C529.05 119.79 527.81 113.95 527.65 107.34H594.97C595.52 107.34 595.97 106.89 595.97 106.34V102.48C595.97 94.69 594.9 87.91 592.76 82.14C590.62 76.38 587.68 71.61 583.95 67.85L583.93 67.87ZM531.28 83.55C533.72 78.5 537.13 74.44 541.49 71.36C545.85 68.29 550.95 66.75 556.77 66.75C562.59 66.75 567.67 68.26 571.84 71.28C576.01 74.3 579.22 78.42 581.47 83.63C583.3 87.87 584.38 92.57 584.72 97.71H527.69C527.98 92.62 529.17 87.9 531.28 83.55Z"
          fill={colorWhite}
        />
        <path
          d="M672.03 60.62C667.34 58.01 661.86 56.71 655.6 56.71C648.63 56.71 642.44 58.49 637.03 62.06C632.71 64.91 629.32 69.2 626.84 74.89L626.78 58.94C626.78 58.39 626.33 57.94 625.78 57.94H616.99C616.44 57.94 615.99 58.39 615.99 58.94V149C615.99 149.55 616.44 150 616.99 150H626.19C626.74 150 627.19 149.55 627.19 149V92.61C627.19 87.34 628.3 82.77 630.52 78.9C632.74 75.03 635.78 72.07 639.62 70.01C643.46 67.95 647.8 66.92 652.63 66.92C659.71 66.92 665.36 69.1 669.59 73.47C673.82 77.83 675.93 83.78 675.93 91.3V149.01C675.93 149.56 676.38 150.01 676.93 150.01H686.05C686.6 150.01 687.05 149.56 687.05 149.01V90.48C687.05 83.18 685.72 77.03 683.06 72.03C680.4 67.04 676.72 63.23 672.03 60.63V60.62Z"
          fill={colorWhite}
        />
        <path
          d="M783.6 57.94H774.73C774.18 57.94 773.73 58.39 773.73 58.94V75.64H772.58C771.37 72.84 769.63 69.97 767.35 67.04C765.07 64.1 762.07 61.65 758.33 59.67C754.6 57.69 749.93 56.71 744.33 56.71C736.81 56.71 730.19 58.69 724.48 62.64C718.77 66.59 714.32 72.1 711.14 79.15C707.96 86.2 706.36 94.43 706.36 103.81C706.36 113.19 707.98 121.27 711.22 128.02C714.46 134.77 718.92 139.96 724.6 143.58C730.28 147.2 736.77 149.01 744.07 149.01C749.56 149.01 754.17 148.12 757.9 146.33C761.63 144.55 764.67 142.27 767 139.5C769.33 136.73 771.1 133.91 772.31 131.06H773.38V151.73C773.38 160.18 770.91 166.43 765.97 170.46C761.03 174.5 754.5 176.51 746.37 176.51C741.26 176.51 736.95 175.81 733.44 174.41C729.93 173.01 727.06 171.2 724.83 168.98C722.88 167.03 721.28 165.03 720.03 162.97C719.74 162.5 719.13 162.34 718.66 162.62L711.11 167.2C710.66 167.48 710.49 168.06 710.74 168.53C712.51 171.81 714.89 174.74 717.88 177.33C721.12 180.13 725.11 182.34 729.86 183.96C734.61 185.58 740.11 186.39 746.37 186.39C753.73 186.39 760.29 185.17 766.05 182.73C771.81 180.29 776.34 176.54 779.64 171.49C782.93 166.44 784.58 160.02 784.58 152.22V58.94C784.58 58.39 784.13 57.94 783.58 57.94H783.6ZM770.28 122.54C768.08 127.84 764.91 131.9 760.77 134.73C756.62 137.56 751.62 138.97 745.74 138.97C739.86 138.97 734.68 137.48 730.51 134.48C726.34 131.49 723.15 127.33 720.96 122.01C718.76 116.69 717.67 110.54 717.67 103.56C717.67 96.58 718.75 90.5 720.92 84.95C723.09 79.41 726.26 75 730.43 71.73C734.6 68.46 739.71 66.83 745.75 66.83C751.79 66.83 756.78 68.41 760.9 71.56C765.02 74.72 768.16 79.05 770.33 84.57C772.5 90.09 773.58 96.41 773.58 103.55C773.58 110.69 772.48 117.23 770.29 122.53L770.28 122.54Z"
          fill={colorWhite}
        />
        <path
          d="M823.62 57.94H814.42C813.868 57.94 813.42 58.3877 813.42 58.94V149C813.42 149.552 813.868 150 814.42 150H823.62C824.172 150 824.62 149.552 824.62 149V58.94C824.62 58.3877 824.172 57.94 823.62 57.94Z"
          fill={colorWhite}
        />
        <path
          d="M819.02 44.89C822.516 44.89 825.35 42.0559 825.35 38.56C825.35 35.064 822.516 32.23 819.02 32.23C815.524 32.23 812.69 35.064 812.69 38.56C812.69 42.0559 815.524 44.89 819.02 44.89Z"
          fill={colorWhite}
        />
        <path
          d="M910.3 60.62C905.61 58.01 900.13 56.71 893.87 56.71C886.9 56.71 880.71 58.49 875.3 62.06C870.98 64.91 867.59 69.2 865.11 74.89L865.05 58.94C865.05 58.39 864.6 57.94 864.05 57.94H855.26C854.71 57.94 854.26 58.39 854.26 58.94V149C854.26 149.55 854.71 150 855.26 150H864.46C865.01 150 865.46 149.55 865.46 149V92.61C865.46 87.34 866.57 82.77 868.79 78.9C871.01 75.03 874.05 72.07 877.89 70.01C881.73 67.95 886.07 66.92 890.9 66.92C897.98 66.92 903.63 69.1 907.86 73.47C912.09 77.83 914.2 83.78 914.2 91.3V149.01C914.2 149.56 914.65 150.01 915.2 150.01H924.32C924.87 150.01 925.32 149.56 925.32 149.01V90.48C925.32 83.18 923.99 77.03 921.33 72.03C918.67 67.04 914.99 63.23 910.3 60.63V60.62Z"
          fill={colorWhite}
        />
        <path
          d="M1012.78 67.87C1009.05 64.11 1004.85 61.31 1000.18 59.47C995.51 57.63 990.65 56.71 985.6 56.71C977.58 56.71 970.54 58.77 964.48 62.89C958.41 67.01 953.69 72.66 950.32 79.85C946.94 87.04 945.26 95.28 945.26 104.55C945.26 113.82 946.98 122.04 950.41 129.17C953.84 136.31 958.7 141.89 964.99 145.93C971.28 149.96 978.73 151.98 987.35 151.98C993.55 151.98 999.04 150.98 1003.82 148.97C1008.6 146.97 1012.56 144.32 1015.72 141.02C1018.6 138.01 1020.76 134.78 1022.2 131.32C1022.42 130.78 1022.12 130.17 1021.57 129.99L1012.83 127.21C1012.34 127.06 1011.83 127.3 1011.62 127.76C1010.53 130.14 1008.99 132.35 1006.99 134.39C1004.77 136.67 1002.02 138.5 998.76 139.87C995.49 141.24 991.72 141.93 987.44 141.93C981.13 141.93 975.65 140.44 971.01 137.44C966.37 134.45 962.79 130.25 960.26 124.84C957.9 119.79 956.66 113.95 956.5 107.34H1023.82C1024.37 107.34 1024.82 106.89 1024.82 106.34V102.48C1024.82 94.69 1023.75 87.91 1021.61 82.14C1019.47 76.38 1016.53 71.61 1012.8 67.85L1012.78 67.87ZM960.12 83.55C962.56 78.5 965.97 74.44 970.33 71.36C974.69 68.29 979.79 66.75 985.61 66.75C991.43 66.75 996.51 68.26 1000.68 71.28C1004.85 74.3 1008.06 78.42 1010.31 83.63C1012.14 87.87 1013.22 92.57 1013.56 97.71H956.53C956.82 92.62 958.01 87.9 960.12 83.55Z"
          fill={colorWhite}
        />
        <path
          d="M133 208C134.66 208 136 206.66 136 205V162.49C136 160.9 135.37 159.37 134.24 158.25L121.75 145.76C120.62 144.63 119.1 144 117.51 144H90.48C88.89 144 87.36 144.63 86.24 145.76L73.75 158.25C72.62 159.38 71.99 160.9 71.99 162.49V205C71.99 206.66 73.33 208 74.99 208H133Z"
          fill={teal700}
        />
        <path
          d="M158.24 73.76L145.75 86.25C144.62 87.38 143.99 88.9 143.99 90.49V117.52C143.99 119.11 144.62 120.64 145.75 121.76L158.24 134.25C159.37 135.38 160.89 136.01 162.48 136.01H204.99C206.65 136.01 207.99 134.67 207.99 133.01V75.01C207.99 73.35 206.65 72.01 204.99 72.01H162.48C160.89 72.01 159.36 72.64 158.24 73.77V73.76Z"
          fill={teal700}
        />
        <path
          d="M75 0C73.34 0 72 1.34 72 3V45.51C72 47.1 72.63 48.63 73.76 49.75L86.25 62.24C87.38 63.37 88.9 64 90.49 64H117.52C119.11 64 120.64 63.37 121.76 62.24L134.25 49.75C135.38 48.62 136.01 47.1 136.01 45.51V3C136.01 1.34 134.67 0 133.01 0H75Z"
          fill={teal700}
        />
        <path
          d="M205 144H162.49C160.9 144 159.37 144.63 158.25 145.76L145.76 158.25C144.63 159.38 144 160.9 144 162.49V205C144 206.66 145.34 208 147 208H205C206.66 208 208 206.66 208 205V147C208 145.34 206.66 144 205 144Z"
          fill={teal700}
        />
        <path
          d="M61 0H18.49C16.89 0 15.37 0.63 14.24 1.76L1.76 14.24C0.63 15.37 0 16.89 0 18.49V61C0 62.66 1.34 64 3 64H45.51C47.1 64 48.63 63.37 49.75 62.24L62.24 49.75C63.37 48.62 64 47.1 64 45.51V3C64 1.34 62.66 0 61 0Z"
          fill={teal700}
        />
        <path
          d="M144 3V45.51C144 47.1 144.63 48.63 145.76 49.75L158.25 62.24C159.38 63.37 160.9 64 162.49 64H205C206.66 64 208 62.66 208 61V3C208 1.34 206.66 0 205 0H147C145.34 0 144 1.34 144 3Z"
          fill={teal700}
        />
        <path
          d="M104 120C95.16 120 88 112.84 88 104C88 95.16 95.17 88 104 88C112.83 88 120 95.16 120 104C120 112.84 112.83 120 104 120Z"
          fill={teal700}
        />
        <path
          d="M0 147V205C0 206.66 1.34 208 3 208H61C62.66 208 64 206.66 64 205V162.49C64 160.9 63.37 159.37 62.24 158.25L49.75 145.76C48.62 144.63 47.1 144 45.51 144H3C1.34 144 0 145.34 0 147Z"
          fill={teal700}
        />
        <path
          d="M62.24 86.24L49.75 73.75C48.62 72.62 47.1 71.99 45.51 71.99H3C1.34 72 0 73.34 0 75V133C0 134.66 1.34 136 3 136H46.2C47.79 136 49.32 135.37 50.44 134.24L62.24 122.45C63.37 121.32 64 119.8 64 118.21V90.49C64 88.9 63.37 87.37 62.24 86.25V86.24Z"
          fill={teal700}
        />
      </SvgIcon>
    );
  }
  return (
    <SvgIcon
      style={{ height: '2rem', width: '10rem' }}
      viewBox="0 0 1064 208"
      fill="none"
      {...props}
    >
      <path
        d="M1058.44 56.71L1054.62 65.8L1050.83 56.71H1048.14V69.17H1050.35V60.78L1054.04 69.17H1055.25L1058.9 60.81V69.17H1061.16V56.71H1058.44Z"
        fill={blue1100}
      />
      <path
        d="M1036.16 58.66H1039.93V69.17H1042.18V58.66H1045.95V56.71H1036.16V58.66Z"
        fill={blue1100}
      />
      <path
        d="M387.93 56.01H364.88C364.41 56.01 364 56.33 363.9 56.79C362.34 64.07 350.48 119.72 349.82 124.39C349.77 124.74 349.55 124.74 349.5 124.39C348.76 119.72 335.93 63.99 334.27 56.77C334.17 56.32 333.76 56 333.3 56H311.79C311.32 56 310.92 56.31 310.82 56.77C309.15 63.98 296.28 119.72 295.56 124.39C295.49 124.82 295.24 124.82 295.17 124.39C294.49 119.72 282.67 64.08 281.12 56.79C281.02 56.33 280.62 56 280.14 56H257.09C256.43 56 255.95 56.62 256.12 57.26L281.19 151.13C281.31 151.57 281.7 151.87 282.16 151.87H306.4C306.86 151.87 307.26 151.56 307.37 151.11C308.89 144.7 319.55 99.78 321.96 89.27C322.08 88.75 322.81 88.75 322.93 89.27C325.37 99.77 336.16 144.71 337.69 151.11C337.8 151.56 338.2 151.87 338.66 151.87H362.84C363.29 151.87 363.69 151.57 363.81 151.13L388.88 57.26C389.05 56.63 388.57 56 387.91 56L387.93 56.01Z"
        fill={blue1100}
      />
      <path
        d="M462.52 60.19C457.29 57.4 451.07 56.01 443.86 56.01H406.59C406.04 56.01 405.59 56.46 405.59 57.01V150.88C405.59 151.43 406.04 151.88 406.59 151.88H427.3C427.85 151.88 428.3 151.43 428.3 150.88V121.96H443.21C450.54 121.96 456.87 120.59 462.19 117.84C467.51 115.1 471.61 111.28 474.48 106.39C477.35 101.5 478.79 95.75 478.79 89.15C478.79 82.55 477.38 76.82 474.57 71.84C471.76 66.86 467.74 62.98 462.51 60.19H462.52ZM455.41 96.93C454.21 99.16 452.41 100.9 450 102.14C447.6 103.38 444.62 104.01 441.06 104.01H428.31V74.41H441C444.56 74.41 447.55 75.01 449.97 76.21C452.39 77.41 454.2 79.12 455.41 81.33C456.61 83.54 457.21 86.15 457.21 89.15C457.21 92.15 456.61 94.71 455.41 96.94V96.93Z"
        fill={blue1100}
      />
      <path
        d="M583.93 67.87C580.2 64.11 576 61.31 571.33 59.47C566.66 57.63 561.8 56.71 556.75 56.71C548.73 56.71 541.69 58.77 535.63 62.89C529.56 67.01 524.84 72.66 521.47 79.85C518.09 87.04 516.41 95.28 516.41 104.55C516.41 113.82 518.13 122.04 521.56 129.17C524.99 136.31 529.85 141.89 536.14 145.93C542.43 149.96 549.88 151.98 558.5 151.98C564.7 151.98 570.19 150.98 574.97 148.97C579.75 146.97 583.71 144.32 586.87 141.02C589.75 138.01 591.91 134.78 593.35 131.32C593.57 130.78 593.27 130.17 592.72 129.99L583.98 127.21C583.49 127.06 582.98 127.3 582.77 127.76C581.68 130.14 580.14 132.35 578.14 134.39C575.92 136.67 573.17 138.5 569.91 139.87C566.64 141.24 562.87 141.93 558.59 141.93C552.28 141.93 546.8 140.44 542.16 137.44C537.52 134.45 533.94 130.25 531.41 124.84C529.05 119.79 527.81 113.95 527.65 107.34H594.97C595.52 107.34 595.97 106.89 595.97 106.34V102.48C595.97 94.69 594.9 87.91 592.76 82.14C590.62 76.38 587.68 71.61 583.95 67.85L583.93 67.87ZM531.28 83.55C533.72 78.5 537.13 74.44 541.49 71.36C545.85 68.29 550.95 66.75 556.77 66.75C562.59 66.75 567.67 68.26 571.84 71.28C576.01 74.3 579.22 78.42 581.47 83.63C583.3 87.87 584.38 92.57 584.72 97.71H527.69C527.98 92.62 529.17 87.9 531.28 83.55Z"
        fill={blue1100}
      />
      <path
        d="M672.03 60.62C667.34 58.01 661.86 56.71 655.6 56.71C648.63 56.71 642.44 58.49 637.03 62.06C632.71 64.91 629.32 69.2 626.84 74.89L626.78 58.94C626.78 58.39 626.33 57.94 625.78 57.94H616.99C616.44 57.94 615.99 58.39 615.99 58.94V149C615.99 149.55 616.44 150 616.99 150H626.19C626.74 150 627.19 149.55 627.19 149V92.61C627.19 87.34 628.3 82.77 630.52 78.9C632.74 75.03 635.78 72.07 639.62 70.01C643.46 67.95 647.8 66.92 652.63 66.92C659.71 66.92 665.36 69.1 669.59 73.47C673.82 77.83 675.93 83.78 675.93 91.3V149.01C675.93 149.56 676.38 150.01 676.93 150.01H686.05C686.6 150.01 687.05 149.56 687.05 149.01V90.48C687.05 83.18 685.72 77.03 683.06 72.03C680.4 67.04 676.72 63.23 672.03 60.63V60.62Z"
        fill={blue1100}
      />
      <path
        d="M783.6 57.94H774.73C774.18 57.94 773.73 58.39 773.73 58.94V75.64H772.58C771.37 72.84 769.63 69.97 767.35 67.04C765.07 64.1 762.07 61.65 758.33 59.67C754.6 57.69 749.93 56.71 744.33 56.71C736.81 56.71 730.19 58.69 724.48 62.64C718.77 66.59 714.32 72.1 711.14 79.15C707.96 86.2 706.36 94.43 706.36 103.81C706.36 113.19 707.98 121.27 711.22 128.02C714.46 134.77 718.92 139.96 724.6 143.58C730.28 147.2 736.77 149.01 744.07 149.01C749.56 149.01 754.17 148.12 757.9 146.33C761.63 144.55 764.67 142.27 767 139.5C769.33 136.73 771.1 133.91 772.31 131.06H773.38V151.73C773.38 160.18 770.91 166.43 765.97 170.46C761.03 174.5 754.5 176.51 746.37 176.51C741.26 176.51 736.95 175.81 733.44 174.41C729.93 173.01 727.06 171.2 724.83 168.98C722.88 167.03 721.28 165.03 720.03 162.97C719.74 162.5 719.13 162.34 718.66 162.62L711.11 167.2C710.66 167.48 710.49 168.06 710.74 168.53C712.51 171.81 714.89 174.74 717.88 177.33C721.12 180.13 725.11 182.34 729.86 183.96C734.61 185.58 740.11 186.39 746.37 186.39C753.73 186.39 760.29 185.17 766.05 182.73C771.81 180.29 776.34 176.54 779.64 171.49C782.93 166.44 784.58 160.02 784.58 152.22V58.94C784.58 58.39 784.13 57.94 783.58 57.94H783.6ZM770.28 122.54C768.08 127.84 764.91 131.9 760.77 134.73C756.62 137.56 751.62 138.97 745.74 138.97C739.86 138.97 734.68 137.48 730.51 134.48C726.34 131.49 723.15 127.33 720.96 122.01C718.76 116.69 717.67 110.54 717.67 103.56C717.67 96.58 718.75 90.5 720.92 84.95C723.09 79.41 726.26 75 730.43 71.73C734.6 68.46 739.71 66.83 745.75 66.83C751.79 66.83 756.78 68.41 760.9 71.56C765.02 74.72 768.16 79.05 770.33 84.57C772.5 90.09 773.58 96.41 773.58 103.55C773.58 110.69 772.48 117.23 770.29 122.53L770.28 122.54Z"
        fill={blue1100}
      />
      <path
        d="M823.62 57.94H814.42C813.868 57.94 813.42 58.3877 813.42 58.94V149C813.42 149.552 813.868 150 814.42 150H823.62C824.172 150 824.62 149.552 824.62 149V58.94C824.62 58.3877 824.172 57.94 823.62 57.94Z"
        fill={blue1100}
      />
      <path
        d="M819.02 44.89C822.516 44.89 825.35 42.0559 825.35 38.56C825.35 35.064 822.516 32.23 819.02 32.23C815.524 32.23 812.69 35.064 812.69 38.56C812.69 42.0559 815.524 44.89 819.02 44.89Z"
        fill={blue1100}
      />
      <path
        d="M910.3 60.62C905.61 58.01 900.13 56.71 893.87 56.71C886.9 56.71 880.71 58.49 875.3 62.06C870.98 64.91 867.59 69.2 865.11 74.89L865.05 58.94C865.05 58.39 864.6 57.94 864.05 57.94H855.26C854.71 57.94 854.26 58.39 854.26 58.94V149C854.26 149.55 854.71 150 855.26 150H864.46C865.01 150 865.46 149.55 865.46 149V92.61C865.46 87.34 866.57 82.77 868.79 78.9C871.01 75.03 874.05 72.07 877.89 70.01C881.73 67.95 886.07 66.92 890.9 66.92C897.98 66.92 903.63 69.1 907.86 73.47C912.09 77.83 914.2 83.78 914.2 91.3V149.01C914.2 149.56 914.65 150.01 915.2 150.01H924.32C924.87 150.01 925.32 149.56 925.32 149.01V90.48C925.32 83.18 923.99 77.03 921.33 72.03C918.67 67.04 914.99 63.23 910.3 60.63V60.62Z"
        fill={blue1100}
      />
      <path
        d="M1012.78 67.87C1009.05 64.11 1004.85 61.31 1000.18 59.47C995.51 57.63 990.65 56.71 985.6 56.71C977.58 56.71 970.54 58.77 964.48 62.89C958.41 67.01 953.69 72.66 950.32 79.85C946.94 87.04 945.26 95.28 945.26 104.55C945.26 113.82 946.98 122.04 950.41 129.17C953.84 136.31 958.7 141.89 964.99 145.93C971.28 149.96 978.73 151.98 987.35 151.98C993.55 151.98 999.04 150.98 1003.82 148.97C1008.6 146.97 1012.56 144.32 1015.72 141.02C1018.6 138.01 1020.76 134.78 1022.2 131.32C1022.42 130.78 1022.12 130.17 1021.57 129.99L1012.83 127.21C1012.34 127.06 1011.83 127.3 1011.62 127.76C1010.53 130.14 1008.99 132.35 1006.99 134.39C1004.77 136.67 1002.02 138.5 998.76 139.87C995.49 141.24 991.72 141.93 987.44 141.93C981.13 141.93 975.65 140.44 971.01 137.44C966.37 134.45 962.79 130.25 960.26 124.84C957.9 119.79 956.66 113.95 956.5 107.34H1023.82C1024.37 107.34 1024.82 106.89 1024.82 106.34V102.48C1024.82 94.69 1023.75 87.91 1021.61 82.14C1019.47 76.38 1016.53 71.61 1012.8 67.85L1012.78 67.87ZM960.12 83.55C962.56 78.5 965.97 74.44 970.33 71.36C974.69 68.29 979.79 66.75 985.61 66.75C991.43 66.75 996.51 68.26 1000.68 71.28C1004.85 74.3 1008.06 78.42 1010.31 83.63C1012.14 87.87 1013.22 92.57 1013.56 97.71H956.53C956.82 92.62 958.01 87.9 960.12 83.55Z"
        fill={blue1100}
      />
      <path
        d="M133 208C134.66 208 136 206.66 136 205V162.49C136 160.9 135.37 159.37 134.24 158.25L121.75 145.76C120.62 144.63 119.1 144 117.51 144H90.48C88.89 144 87.36 144.63 86.24 145.76L73.75 158.25C72.62 159.38 71.99 160.9 71.99 162.49V205C71.99 206.66 73.33 208 74.99 208H133Z"
        fill={teal700}
      />
      <path
        d="M158.24 73.76L145.75 86.25C144.62 87.38 143.99 88.9 143.99 90.49V117.52C143.99 119.11 144.62 120.64 145.75 121.76L158.24 134.25C159.37 135.38 160.89 136.01 162.48 136.01H204.99C206.65 136.01 207.99 134.67 207.99 133.01V75.01C207.99 73.35 206.65 72.01 204.99 72.01H162.48C160.89 72.01 159.36 72.64 158.24 73.77V73.76Z"
        fill={teal700}
      />
      <path
        d="M75 0C73.34 0 72 1.34 72 3V45.51C72 47.1 72.63 48.63 73.76 49.75L86.25 62.24C87.38 63.37 88.9 64 90.49 64H117.52C119.11 64 120.64 63.37 121.76 62.24L134.25 49.75C135.38 48.62 136.01 47.1 136.01 45.51V3C136.01 1.34 134.67 0 133.01 0H75Z"
        fill={teal700}
      />
      <path
        d="M205 144H162.49C160.9 144 159.37 144.63 158.25 145.76L145.76 158.25C144.63 159.38 144 160.9 144 162.49V205C144 206.66 145.34 208 147 208H205C206.66 208 208 206.66 208 205V147C208 145.34 206.66 144 205 144Z"
        fill={teal700}
      />
      <path
        d="M61 0H18.49C16.89 0 15.37 0.63 14.24 1.76L1.76 14.24C0.63 15.37 0 16.89 0 18.49V61C0 62.66 1.34 64 3 64H45.51C47.1 64 48.63 63.37 49.75 62.24L62.24 49.75C63.37 48.62 64 47.1 64 45.51V3C64 1.34 62.66 0 61 0Z"
        fill={teal700}
      />
      <path
        d="M144 3V45.51C144 47.1 144.63 48.63 145.76 49.75L158.25 62.24C159.38 63.37 160.9 64 162.49 64H205C206.66 64 208 62.66 208 61V3C208 1.34 206.66 0 205 0H147C145.34 0 144 1.34 144 3Z"
        fill={teal700}
      />
      <path
        d="M104 120C95.16 120 88 112.84 88 104C88 95.16 95.17 88 104 88C112.83 88 120 95.16 120 104C120 112.84 112.83 120 104 120Z"
        fill={teal700}
      />
      <path
        d="M0 147V205C0 206.66 1.34 208 3 208H61C62.66 208 64 206.66 64 205V162.49C64 160.9 63.37 159.37 62.24 158.25L49.75 145.76C48.62 144.63 47.1 144 45.51 144H3C1.34 144 0 145.34 0 147Z"
        fill={teal700}
      />
      <path
        d="M62.24 86.24L49.75 73.75C48.62 72.62 47.1 71.99 45.51 71.99H3C1.34 72 0 73.34 0 75V133C0 134.66 1.34 136 3 136H46.2C47.79 136 49.32 135.37 50.44 134.24L62.24 122.45C63.37 121.32 64 119.8 64 118.21V90.49C64 88.9 63.37 87.37 62.24 86.25V86.24Z"
        fill={teal700}
      />
    </SvgIcon>
  );
};

export default WpEngineLogo;
