const normalizeUrl = (
  baseUrl: string,
  subpath?: string,
  includeProtocol = true
) => {
  if (!baseUrl) {
    return '';
  }

  const protocol = baseUrl.startsWith('http://') ? 'http://' : 'https://';

  const urlWithoutProtocol = baseUrl.replace(protocol, '');
  const trimmedDomain = urlWithoutProtocol.endsWith('/')
    ? urlWithoutProtocol.slice(0, -1)
    : urlWithoutProtocol;
  let fullUrl = includeProtocol ? `${protocol}${trimmedDomain}` : trimmedDomain;
  if (subpath) {
    const trimmedSubpath = subpath.replace(/^\/|\/+$/g, '');
    fullUrl += `/${trimmedSubpath}`;
  }

  return fullUrl;
};

export default normalizeUrl;
