import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const AdditionalSite: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="AdditionalSite logo" viewBox="0 0 64 64" {...props}>
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_3349_98)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 43V27H47V32C47 32.5523 47.4477 33 48 33C48.5523 33 49 32.5523 49 32L49 21C49 19.3431 47.6568 18 46 18H16C14.3431 18 13 19.3431 13 21V43C13 44.6569 14.3431 46 16 46H36C36.5523 46 37 45.5523 37 45C37 44.4477 36.5523 44 36 44H16C15.4477 44 15 43.5523 15 43ZM17.9674 23C17.9674 22.4477 18.4151 22 18.9674 22H20.9674C21.5197 22 21.9674 22.4477 21.9674 23C21.9674 23.5523 21.5197 24 20.9674 24H18.9674C18.4151 24 17.9674 23.5523 17.9674 23ZM25 22C24.4477 22 24 22.4477 24 23C24 23.5523 24.4477 24 25 24H27C27.5523 24 28 23.5523 28 23C28 22.4477 27.5523 22 27 22H25ZM36 23C36 22.4477 36.4477 22 37 22H43C43.5523 22 44 22.4477 44 23C44 23.5523 43.5523 24 43 24H37C36.4477 24 36 23.5523 36 23Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 31C18 30.4477 18.4477 30 19 30H32C32.5523 30 33 30.4477 33 31C33 31.5523 32.5523 32 32 32H19C18.4477 32 18 31.5523 18 31Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 35C18 34.4477 18.4477 34 19 34H32C32.5523 34 33 34.4477 33 35C33 35.5523 32.5523 36 32 36H19C18.4477 36 18 35.5523 18 35Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 39C18 38.4477 18.4477 38 19 38H32C32.5523 38 33 38.4477 33 39C33 39.5523 32.5523 40 32 40H19C18.4477 40 18 39.5523 18 39Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 48C48.866 48 52 44.866 52 41C52 37.134 48.866 34 45 34C41.134 34 38 37.134 38 41C38 44.866 41.134 48 45 48ZM44 44V42H42C41.4477 42 41 41.5523 41 41C41 40.4477 41.4477 40 42 40H44V38C44 37.4477 44.4477 37 45 37C45.5523 37 46 37.4477 46 38V40H48C48.5523 40 49 40.4477 49 41C49 41.5523 48.5523 42 48 42H46V44C46 44.5523 45.5523 45 45 45C44.4477 45 44 44.5523 44 44Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_3349_98"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default AdditionalSite;
