import { AccountLookupType } from 'commonsDir/types/Accounts';
import * as React from 'react';

export interface TopNavContextProps {
  allAccountsView: boolean;
  chatPinId: string;
  csrfToken?: string;
  currentAccount: AccountLookupType;
  currentUserUuid: string;
  impersonating: boolean;
  isAdmin: boolean;
  hasAccounts: boolean;
  hasNotificationsDeveloperAccess: boolean;
  hasWpeLabsAccess: boolean;
  isAccountOwner: boolean;
  notificationsEnabled: boolean;
  showOmniSearch: boolean;
  showSshKeys: boolean;
  largeScreen: boolean;
  smallScreen: boolean;
}

export const TopNavContext = React.createContext<TopNavContextProps>({
  allAccountsView: false,
  chatPinId: null,
  csrfToken: null,
  currentAccount: null,
  currentUserUuid: null,
  impersonating: null,
  isAdmin: null,
  hasAccounts: null,
  hasNotificationsDeveloperAccess: null,
  hasWpeLabsAccess: null,
  isAccountOwner: null,
  notificationsEnabled: null,
  showOmniSearch: null,
  showSshKeys: null,
  largeScreen: null,
  smallScreen: null,
});

interface TopNavProviderProps {
  allAccountsView: boolean;
  chatPinId: string;
  csrfToken?: string;
  currentAccount: AccountLookupType;
  currentUserUuid: string;
  impersonating: boolean;
  isAdmin: boolean;
  hasAccounts: boolean;
  hasNotificationsDeveloperAccess: boolean;
  hasWpeLabsAccess: boolean;
  isAccountOwner: boolean;
  notificationsEnabled: boolean;
  showOmniSearch: boolean;
  showSshKeys: boolean;
  smallScreen: boolean;
}

const TopNavProvider: React.FC<TopNavProviderProps> = ({
  allAccountsView,
  chatPinId,
  csrfToken,
  currentAccount,
  currentUserUuid,
  isAdmin,
  impersonating,
  hasAccounts,
  hasNotificationsDeveloperAccess,
  hasWpeLabsAccess,
  isAccountOwner,
  notificationsEnabled,
  showOmniSearch,
  showSshKeys,
  smallScreen,
  children,
}) => {
  return (
    <TopNavContext.Provider
      value={{
        allAccountsView,
        chatPinId,
        csrfToken,
        currentAccount,
        currentUserUuid,
        isAdmin,
        impersonating,
        hasAccounts,
        hasNotificationsDeveloperAccess,
        hasWpeLabsAccess,
        isAccountOwner,
        notificationsEnabled,
        showOmniSearch,
        showSshKeys,
        largeScreen: !smallScreen,
        smallScreen,
      }}
    >
      {children}
    </TopNavContext.Provider>
  );
};

const useTopNavContext = (): TopNavContextProps => {
  const context = React.useContext(TopNavContext);
  if (context === undefined) {
    throw new Error('useTopNavContext must be used within a TopNavProvider');
  }
  return context;
};

export { TopNavProvider, useTopNavContext };
