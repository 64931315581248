import React from 'react';
import { Box, Typography } from '@wpengine/unicorn/components';
import {
  DropdownTemplate,
  Option,
} from '@wpengine/unicorn/components/Dropdown/Dropdown';

type AddonDropdownOption = Option & {
  price: string;
  priceLabel: string;
};

const AddonDropdownItem: DropdownTemplate<AddonDropdownOption> = ({
  label,
  price,
  priceLabel,
}) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        pr: '1rem',
        width: '100%',
      }}
    >
      <Box>{label}</Box>
      <Typography variant="bodyBold">
        {price} <Typography display="inline">{priceLabel}</Typography>
      </Typography>
    </Box>
  );
};

export default AddonDropdownItem;
