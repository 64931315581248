import { rest } from 'msw';
import { ENDPOINTS } from 'mocksDir/shared/constants';
import mockDatacenters from 'mocksDir/shared/data/mockDatacenters';
import { customResponse } from 'mocksDir/utils/browserUtils';

const handlers = [
  rest.get(`*/${ENDPOINTS.DATACENTERS}`, (req, res, ctx) => {
    const datacenters = mockDatacenters;

    return customResponse(ctx.status(200), ctx.json(datacenters));
  }),
];

export default handlers;
