import { rest } from 'msw';
import { ENDPOINTS, MSW_PARAMS } from 'componentsDir/install/Cache/constants';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import getInstallName from 'componentsDir/install/Overview/utils/getInstallName';
import {
  generateSampleFeatureUsageV3Data,
  generateSampleFeatureV3Meta,
} from './data';

const featureUsageV3DataSample = generateSampleFeatureUsageV3Data();

const whooCommFeatureUsageV3Handlers = [
  rest.get(`*${ENDPOINTS.WHOO_COMM_FEATURE_USAGE_V3}*`, (_, res, ctx) => {
    if (hasPageUrlQueryParam(MSW_PARAMS.SHOW_ECOMM_UPGRADE_BANNER)) {
      const featureUsageV3DataSampleWithEcommUpgradeBanner = generateSampleFeatureUsageV3Data(
        {
          woocommerce: generateSampleFeatureV3Meta({ enabled: true }),
          installName: getInstallName(),
        }
      );
      return res(
        ctx.status(200),
        ctx.json(featureUsageV3DataSampleWithEcommUpgradeBanner)
      );
    }

    return customResponse(ctx.status(200), ctx.json(featureUsageV3DataSample));
  }),
];

export default whooCommFeatureUsageV3Handlers;
