import { Box, Typography } from '@wpengine/unicorn/components';
import React from 'react';

const ErrorCart = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" p={6}>
      <Typography variant="h3">Error retrieving cart</Typography>
    </Box>
  );
};

export default ErrorCart;
