import { rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { ENDPOINTS } from 'componentsDir/install/Cache/constants';
import generatePSBIntegrationData from './data';

const psbIntegrationHandlers = [
  rest.get(`*${ENDPOINTS.PSB_INTEGRATION}/*`, (_req, _res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json(generatePSBIntegrationData())
    );
  }),
];

export default psbIntegrationHandlers;
