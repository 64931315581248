import { RestRequest } from 'msw';
import { pick } from 'lodash';
import { ExtensionResponse, MockUpdate } from '../../../../sites/mocks/types';
/* eslint-disable no-param-reassign */
import rawPlugins from './data/rawPlugins';
import rawThemes from './data/rawThemes';

class BulkUpdatesMockDataStore {
  updateDelay = 10_000;

  plugins = rawPlugins;

  themes = rawThemes;

  updatesBySiteId = {} as Record<string, MockUpdate>;

  requestLog: RestRequest[] = [];

  completeUpdateTimeout: ReturnType<typeof setTimeout>;

  constructor() {
    this.reset();
  }

  reset() {
    if (this.completeUpdateTimeout) {
      clearTimeout(this.completeUpdateTimeout);
    }
    this.updateDelay = 10_000;
    this.plugins = rawPlugins;
    this.themes = rawThemes;
    this.updatesBySiteId = {};
    this.requestLog = [];
    this.completeUpdateTimeout = undefined;
  }

  logRequest(req: RestRequest) {
    this.requestLog.push(req);
  }

  getPlugins(search: string): ExtensionResponse[] {
    return search
      ? this.plugins.filter(
          p => p.name.includes(search) || p.slug.includes(search)
        )
      : this.plugins;
  }

  getThemes(search: string): ExtensionResponse[] {
    return search
      ? this.themes.filter(
          t => t.name.includes(search) || t.slug.includes(search)
        )
      : this.themes;
  }

  changePluginActiveState(
    siteId: number,
    extensionId: number,
    active: boolean
  ) {
    this.plugins.forEach(plugin => {
      plugin.sites.forEach(site => {
        if (site.site_id === siteId && site.extension_id === extensionId) {
          site.active = active;
        }
      });
    });
  }

  deleteExtension(siteId: number, extensionId: number) {
    this.plugins = this.plugins
      .map(plugin => ({
        ...plugin,
        sites: plugin.sites.filter(
          site =>
            !(site.site_id === siteId && site.extension_id === extensionId)
        ),
      }))
      .filter(plugin => plugin.sites.length);
    this.themes = this.themes
      .map(theme => ({
        ...theme,
        sites: theme.sites.filter(
          site =>
            !(site.site_id === siteId && site.extension_id === extensionId)
        ),
      }))
      .filter(theme => theme.sites.length);
  }

  getUpdates(siteIds: string[]) {
    return Object.values(pick(this.updatesBySiteId, siteIds));
  }

  completeProcessingUpdate(siteId: number) {
    if (this.updatesBySiteId[siteId]) {
      const { extensions } = this.updatesBySiteId[siteId];
      extensions.forEach(e => {
        [...this.plugins, ...this.themes].forEach(p => {
          p.sites.forEach(s => {
            if (s.extension_id === e.id) {
              s.update = null;
            }
          });
        });
      });
      delete this.updatesBySiteId[siteId];
    }
  }

  slugToExtensionId(
    slugs: string[],
    siteId: number,
    extensionKey: 'plugins' | 'themes'
  ) {
    return slugs.map(slug => {
      const slugExtension = this[extensionKey].find(
        extension => extension.slug === slug
      );
      const updateSite = slugExtension.sites.find(
        site => site.site_id === siteId
      );
      return {
        id: updateSite?.extension_id,
      };
    });
  }

  updateExtension(
    siteId: number,
    pluginSlugs?: string[],
    themeSlugs?: string[]
  ) {
    const pluginsUpdate = this.slugToExtensionId(
      pluginSlugs,
      siteId,
      'plugins'
    );
    const themesUpdate = this.slugToExtensionId(themeSlugs, siteId, 'themes');

    this.updatesBySiteId[siteId] = {
      id: Math.round(Math.random() * 1000),
      site_id: siteId,
      started_at: String(new Date()),
      extensions: [...pluginsUpdate, ...themesUpdate],
    };
    this.completeUpdateTimeout = setTimeout(
      () => this.completeProcessingUpdate(siteId),
      this.updateDelay
    );
  }
}

const mockDataStore = new BulkUpdatesMockDataStore();

export default mockDataStore;
