import * as React from 'react';
import { BoxProps } from '@mui/material/Box';
import Box from '../Box/Box';

export interface ColumnProps extends BoxProps {
  /**
   * padding-left and padding-right
   */
  px: number;
  /**
   * flex shorthand settings
   */
  flex: string;
  /**
   * Children to be rendered in Column
   */
  children?: JSX.Element | JSX.Element[] | string;
  /**
   * Class names to be applied to Column
   */
  className?: string;
}

const Column = ({
  flex = '1 1 auto',
  px = 3,
  ...props
}): React.ReactElement<ColumnProps> => <Box flex={flex} px={px} {...props} />;

export default Column;
