import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Typography,
  Link,
  UnicornProvider,
} from '@wpengine/unicorn/components';
import { gray400 } from 'design-tokens';
import { LOCAL_STORAGE } from 'componentsDir/admin/PortalDevTools/constants';
import { Suspense, lazy } from 'react';

const PortalDevToolsDrawerButton = lazy(() =>
  import('componentsDir/admin/PortalDevTools/components/drawer/DrawerButton')
);

const StyledFooter = styled('footer')({
  borderTop: `1px solid ${gray400}`,
  padding: 32,
  marginTop: '4rem',
});

interface FooterProps {
  userSignedIn: boolean;
  canDisplayPortalDevTools: boolean;
}

const Footer: React.FC<FooterProps> = ({
  userSignedIn,
  canDisplayPortalDevTools,
}): JSX.Element => {
  const year = new Date().getFullYear();
  const localDevToolsEnabled =
    localStorage.getItem(LOCAL_STORAGE.KEYS.PORTAL_DEV_TOOLS_ENABLED) ===
    'true';

  return (
    <UnicornProvider>
      <StyledFooter>
        <Typography variant="helper" component="p">
          &copy; {year} WPEngine, Inc. All Rights Reserved.
          <br />
          WP ENGINE&reg;, VELOCITIZE&reg;, TORQUE&reg;, EVERCACHE&reg;, and the
          cog logo service marks are owned by WPEngine, Inc.
        </Typography>
        <Link
          href="https://wpengine.com/legal/privacy/"
          target="blank"
          isExternal
          sx={{ mr: 2, ml: '-2px' }}
        >
          Privacy policy
        </Link>
        <Link
          href="https://wpenginestatus.com/"
          target="blank"
          isExternal
          sx={{ mr: 2 }}
        >
          Service status
        </Link>
        <Link
          href="https://wpengine.com/partners/"
          target="blank"
          isExternal
          sx={{ mr: 2 }}
        >
          Partner programs
        </Link>
        {userSignedIn && (
          <Link
            href="https://wpengine-product.canny.io/user-portal"
            target="blank"
            isExternal
            sx={{ mr: 2 }}
          >
            Product feedback
          </Link>
        )}
        {canDisplayPortalDevTools && localDevToolsEnabled && (
          <Suspense fallback={<></>}>
            <PortalDevToolsDrawerButton />
          </Suspense>
        )}
      </StyledFooter>
    </UnicornProvider>
  );
};

export default Footer;
