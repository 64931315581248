import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const EverCache: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="EverCache logo" viewBox="0 0 64 64" {...props}>
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_1438_261)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8223 30.3498C23.0999 29.5422 23.8597 29 24.7137 29H47.6194C48.9806 29 49.9441 30.3302 49.5197 31.6235L44.926 45.6235C44.6565 46.4448 43.89 47 43.0257 47H19.9012C18.5282 47 17.5635 45.6483 18.0098 44.3498L22.8223 30.3498ZM32.2582 33.2453L29.0099 42.7547H29.0197C28.9706 42.8921 29.0393 43 29.1767 43H35.1728C35.3102 43 35.4574 42.8921 35.5065 42.7547L35.8499 41.7341C35.899 41.5967 35.8303 41.4887 35.6929 41.4887H31.4535C31.3259 41.4887 31.2474 41.3808 31.2964 41.2434L32.0815 38.947C32.1306 38.8096 32.2778 38.7017 32.4152 38.7017H36.2817C36.4191 38.7017 36.5663 38.5937 36.6154 38.4563L36.9589 37.4553C37.0079 37.318 36.9392 37.21 36.8018 37.21H32.9353C32.8077 37.21 32.7292 37.1021 32.7783 36.9647L33.5339 34.7566C33.583 34.6192 33.7302 34.5113 33.8676 34.5113H38.0776C38.215 34.5113 38.3622 34.4033 38.4113 34.2659L38.7547 33.2453C38.8038 33.1079 38.7351 33 38.5977 33H32.5918C32.4544 33 32.3072 33.1079 32.2582 33.2453Z"
        fill="#006BD6"
      />
      <path
        d="M28.677 18C27.8592 18 27.1238 18.4979 26.8201 19.2572L25.3458 22.9429C24.9517 23.9282 25.6773 25 26.7385 25H44.1615C44.7748 25 45.3264 24.6266 45.5542 24.0571L46.1542 22.5571C46.5483 21.5718 45.8227 20.5 44.7615 20.5L34.2306 20.5L32.8311 18.7506C32.4516 18.2762 31.8769 18 31.2694 18H28.677Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.25 24C16.25 23.5858 16.5858 23.25 17 23.25L23 23.25C23.4142 23.25 23.75 23.5858 23.75 24C23.75 24.4142 23.4142 24.75 23 24.75L17 24.75C16.5858 24.75 16.25 24.4142 16.25 24Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.25 33C16.25 32.5858 16.5858 32.25 17 32.25H19C19.4142 32.25 19.75 32.5858 19.75 33C19.75 33.4142 19.4142 33.75 19 33.75H17C16.5858 33.75 16.25 33.4142 16.25 33Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 27C13.25 26.5858 13.5858 26.25 14 26.25L25 26.25C25.4142 26.25 25.75 26.5858 25.75 27C25.75 27.4142 25.4142 27.75 25 27.75L14 27.75C13.5858 27.75 13.25 27.4142 13.25 27Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 30C12.25 29.5858 12.5858 29.25 13 29.25H21C21.4142 29.25 21.75 29.5858 21.75 30C21.75 30.4142 21.4142 30.75 21 30.75H13C12.5858 30.75 12.25 30.4142 12.25 30Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.25 27C27.25 26.5858 27.5858 26.25 28 26.25L47 26.25C47.4142 26.25 47.75 26.5858 47.75 27C47.75 27.4142 47.4142 27.75 47 27.75L28 27.75C27.5858 27.75 27.25 27.4142 27.25 27Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1438_261"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default EverCache;
