import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const SitesIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="sites-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 4C1 3.44772 1.44772 3 2 3H22C22.5523 3 23 3.44772 23 4V20C23 20.5523 22.5523 21 22 21H2C1.44772 21 1 20.5523 1 20V4ZM9 19H21V9H9V19ZM7 9V19H3V9H7ZM21 7V5H3V7H21Z"
        />
      </SvgTemplate>
    );
  }
);
export default SitesIcon;
