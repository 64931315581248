import { ENDPOINTS } from 'componentsDir/AllSites/constants';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';

const handlers = [
  rest.get(`*${ENDPOINTS.GET_ALL_SITES_CSV}`, (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.text(
        `Account name,Site name,Transferable,Sandbox,Install name,WP Site URL,Environment type (PRD/STG/DEV),Domain,PHP version,WordPress version,DB storage (GB),File storage (GB),Total storage (GB),Bandwidth 30 day avg (GB),Bandwidth 30 day sum (GB),Total Visits 30 day avg,Total Visits 30 day sum,Cluster,Group name
        testaccount,testsite100,false,false,testsite100,http://testsite100.wpengine.com,PRD,testsite100.wpengine.com,7.4,6.3-beta4,0.01865387,0.24230957,0.26096344,0.00192531,0.05968475,1,61,141132,
        testaccount,testsite101,false,false,testsite101,http://testsite101.wpengine.com,PRD,testsite101.wpengine.com,7.4,6.3,0.00978088,0.11738968,0.12717056,0.00000000,0.00000000,0,1,141132,
        testaccount,testsite102,false,false,testsite102,http://testsite102.wpengine.com,PRD,testsite102.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite1000,false,false,testsite1000,http://testsite1000.wpengine.com,PRD,testsite1000.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite1001,false,false,testsite1001,http://testsite1001.wpengine.com,STG,testsite1001.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite400,false,false,testsite400,http://testsite400.wpengine.com,PRD,testsite400.wpengine.com,7.4,6.3,0.00978088,0.11738968,0.12717056,0.00000000,0.00000000,0,1,141132,
        testaccount,testsite401,false,false,testsite401,http://testsite401.wpengine.com,PRD,testsite401.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite402,false,false,testsite402,http://testsite402.wpengine.com,PRD,testsite402.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite800,false,false,testsite800,http://testsite800.wpengine.com,PRD,testsite800.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite801,false,false,testsite801,http://testsite801.wpengine.com,PRD,testsite801.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite802,false,false,testsite802,http://testsite802.wpengine.com,PRD,testsite802.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite700,false,false,testsite700,http://testsite700.wpengine.com,PRD,testsite700.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite701,false,false,testsite701,http://testsite701.wpengine.com,PRD,testsite701.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite702,false,false,testsite702,http://testsite702.wpengine.com,PRD,testsite702.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite500,false,false,testsite500,http://testsite500.wpengine.com,PRD,testsite500.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite501,false,false,testsite501,http://testsite501.wpengine.com,PRD,testsite501.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite502,false,false,testsite502,http://testsite502.wpengine.com,PRD,testsite502.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite600,false,false,testsite600,http://testsite600.wpengine.com,STG,testsite600.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testsite601,false,false,testsite601,http://testsite601.wpengine.com,DEV,testsite601.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testPendingInstall,false,false,testPendingInstall,http://,PRD,testPendingInstall.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testTransferableInstall,true,false,testTransferableInstall,http://testTransferableInstall.wpengine.com,PRD,testTransferableInstall.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        testaccount,testSandboxInstall,false,true,testSandboxInstall,http://testSandboxInstall.wpengine.com,PRD,testSandboxInstall.wpengine.com,7.4,6.3,0.01038742,0.06711197,0.07749939,0.00042709,0.01323986,2,64,141132,
        `
      )
    );
  }),
];

export default handlers;
