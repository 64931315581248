import { labs_path, labs_enable_path, labs_disable_path } from 'railsRoutes';
import BaseApiClient from 'componentsDir/Labs/clients/BaseApiClient';

class WpeLabsClient extends BaseApiClient {
  public getLabs = async (): Promise<any> => {
    return this.axiosClient.get(labs_path()).then((res: any) => res.data);
  };

  public enableLabs = async (): Promise<any> => {
    return this.axiosClient.post(labs_enable_path()).then((res: any) => res);
  };

  public disableLabs = async (): Promise<any> => {
    return this.axiosClient.post(labs_disable_path()).then((res: any) => res);
  };
}

export default WpeLabsClient;
