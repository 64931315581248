import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const LogsOverviewIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="logs-overview-icon" ref={ref} {...props}>
        <path d="M14.5637 2.64888C14.71 2.25857 15.0832 2 15.5 2C15.9168 2 16.29 2.25857 16.4363 2.64888L17.693 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H17C16.5832 8 16.21 7.74143 16.0637 7.35112L15.5 5.848L14.9363 7.35112C14.79 7.74143 14.4168 8 14 8H10.693L9.43633 11.3511C9.28997 11.7414 8.91684 12 8.5 12C8.08316 12 7.71003 11.7414 7.56367 11.3511L6.307 8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H7C7.41684 6 7.78997 6.25857 7.93633 6.64888L8.5 8.152L9.06367 6.64888C9.21003 6.25857 9.58316 6 10 6H13.307L14.5637 2.64888Z" />
        <path d="M14.5637 12.6489C14.71 12.2586 15.0832 12 15.5 12C15.9168 12 16.29 12.2586 16.4363 12.6489L17.693 16H21C21.5523 16 22 16.4477 22 17C22 17.5523 21.5523 18 21 18H17C16.5832 18 16.21 17.7414 16.0637 17.3511L15.5 15.848L14.9363 17.3511C14.79 17.7414 14.4168 18 14 18H10.693L9.43633 21.3511C9.28997 21.7414 8.91684 22 8.5 22C8.08316 22 7.71003 21.7414 7.56367 21.3511L6.307 18H3C2.44772 18 2 17.5523 2 17C2 16.4477 2.44772 16 3 16H7C7.41684 16 7.78997 16.2586 7.93633 16.6489L8.5 18.152L9.06367 16.6489C9.21003 16.2586 9.58316 16 10 16H13.307L14.5637 12.6489Z" />
      </SvgTemplate>
    );
  }
);
export default LogsOverviewIcon;
