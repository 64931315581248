import { PlanSubscription } from 'componentsDir/addons/types';
import mockStartupAnnual from './mockStartupAnnual';

const mockStartupToAtlasUpgrade: PlanSubscription = {
  ...mockStartupAnnual,
  selected_plan_name: 'Atlas Startup',
  selected_plan_id: 'headless-startup',
  subscription_price: 49,
  plan_base_price: 49,
  selected_plan_price_monthly: 49,
  selected_plan_price_annually: 490,
  terms: 'ON_RECEIPT',
  period_end: '2023-02-05T10:34:45.000+00:00',
  plan_features: [
    {
      name: 'Sites',
      value: '1',
    },
    {
      name: 'Visits / month',
      value: '25,000',
    },
    {
      name: 'Extra Storage',
      value: '10 GB',
    },
  ],
  plan_addons: [
    {
      id: 'sites',
      name: 'Additional sites',
      limit: 25,
      qty_included: 1,
      additional_qty: 1,
      subscription_qty: 0,
      price: 35.0,
      legacy_entitlement: false,
    },
    {
      id: 'smart-search',
      name: 'Smart Search',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 15.0,
      legacy_entitlement: false,
    },
    {
      id: 'smart-plugin-manager',
      name: 'Smart Plugin Manager',
      limit: 1000,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 10.0,
      legacy_entitlement: false,
      dropdown_value: '1 environment license',
    },
    {
      id: 'genesis-pro',
      name: 'Genesis Pro',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 30.0,
      legacy_entitlement: false,
    },
    {
      id: 'geotarget',
      name: 'GeoTarget',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 15.0,
      legacy_entitlement: false,
    },
    {
      id: 'multisite',
      name: 'WordPress multisite',
      limit: 1,
      qty_included: 0,
      additional_qty: 1,
      subscription_qty: 0,
      price: 20.0,
      legacy_entitlement: false,
    },
    {
      id: 'smart-plugin-manager-5-pack',
      name: 'Smart Plugin Manager licenses (5)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 5,
      subscription_qty: 0,
      price: 30.0,
      legacy_entitlement: false,
      dropdown_value: '5 environment licenses',
    },
    {
      id: 'smart-plugin-manager-15-pack',
      name: 'Smart Plugin Manager licenses (15)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 15,
      subscription_qty: 0,
      price: 75.0,
      legacy_entitlement: false,
      dropdown_value: '15 environment licenses',
    },
    {
      id: 'smart-plugin-manager-25-pack',
      name: 'Smart Plugin Manager licenses (25)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 25,
      subscription_qty: 0,
      price: 100.0,
      legacy_entitlement: false,
      dropdown_value: '25 environment licenses',
    },
    {
      id: 'smart-plugin-manager-50-pack',
      name: 'Smart Plugin Manager licenses (50)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 50,
      subscription_qty: 0,
      price: 150.0,
      legacy_entitlement: false,
      dropdown_value: '50 environment licenses',
    },
    {
      id: 'smart-plugin-manager-100-pack',
      name: 'Smart Plugin Manager licenses (100)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 100,
      subscription_qty: 0,
      price: 250.0,
      legacy_entitlement: false,
      dropdown_value: '100 environment licenses',
    },
    {
      id: 'smart-plugin-manager-150-pack',
      name: 'Smart Plugin Manager licenses (150)',
      limit: 1000,
      qty_included: 0,
      additional_qty: 150,
      subscription_qty: 0,
      price: 300.0,
      legacy_entitlement: false,
      dropdown_value: '150 environment licenses',
    },
  ],
};

export default mockStartupToAtlasUpgrade;
