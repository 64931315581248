interface Extension {
  id: number;
  slug: string;
  name: string;
  version: string;
  update: {
    version: string;
    released_at: string;
    update_id: number;
  };
  vulnerabilities: any[];
  updates_enabled?: boolean;
  active?: boolean;
}

interface Update {
  version: string;
  update: {
    version: string;
  };
}

interface CoreUpdate extends Update {
  vulnerabilities: any[];
}

interface LighthouseMonitoringConfig {
  notification_score_drop_accessibility_enabled: boolean;
  notification_score_drop_best_practices_enabled: boolean;
  notification_score_drop_performance_enabled: boolean;
  notification_score_drop_seo_enabled: boolean;
  notification_score_threshold_accessibility_enabled: boolean;
  notification_score_threshold_best_practices_enabled: boolean;
  notification_score_threshold_performance_enabled: boolean;
  notification_score_threshold_seo_enabled: boolean;
  notification_score_drop_accessibility: number;
  notification_score_drop_best_practices: number;
  notification_score_drop_performance: number;
  notification_score_drop_seo: number;
  notification_score_threshold_accessibility: number;
  notification_score_threshold_best_practices: number;
  notification_score_threshold_performance: number;
  notification_score_threshold_seo: number;
}

export interface EnvironmentsResponse {
  id: number;
  plugins: Extension[];
  themes: Extension[];
  core?: CoreUpdate;
  php?: Update;
  provider_metadata: {
    environment_type: 'production' | 'staging' | 'development';
    install_name: string;
    site_id: number;
    account_name: string;
    account_id: string;
  };
  autoupdater_active: boolean;
  autoupdater_enabled: boolean;
  autoupdater_activated_at: string;
  autoupdate_frequency: string;
  autoupdate_days: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  autoupdate_at: number;
  update_themes: boolean;
  update_plugins: boolean;
  extensions_sync_time: string;
  autoupdate_scheduled_at: string;
  lastRun?: any;
  lighthouse_monitoring_active?: boolean;
  lighthouse_monitoring_config?: LighthouseMonitoringConfig;
}

const rawSpmEnvironments: EnvironmentsResponse[] = [
  {
    id: 80,
    plugins: [
      {
        id: 774095090,
        slug: 'woocommerce/woocommerce.php',
        name: 'WooCommerce',
        active: false,
        version: '1.5.7',
        update: {
          version: '4.2.5',
          released_at: '2021-11-24 05:54:59',
          update_id: 95871,
        },
        vulnerabilities: [
          {
            external_id: 'wsscan uuid1',
            severity: 'high',
            fixed_in_version: '4.2.6',
            type: 'XSS',
            description:
              'Data from an attacker could be interpreted as code by site visitors’ web browsers. The ability to run code in another site visitors’ browser can be abused to steal information, or modify site configuration.',
            last_updated_at: '2022-11-25T12:38:29.000Z',
          },
        ],
      },
    ],
    themes: [
      {
        id: 96636891,
        slug: 'ashe/ashe.php',
        name: 'Ashe',
        version: '1.4.5',
        updates_enabled: true,
        update: {
          version: '4.3.2',
          released_at: '2022-02-11 17:07:46',
          update_id: 98458,
        },
        vulnerabilities: [],
      },
      {
        id: 97448836,
        slug: 'colormag/colormag.php',
        name: 'ColorMag',
        version: '1.12.9',
        updates_enabled: false,
        update: {
          version: '2.1.3',
          released_at: '2021-11-02 02:03:51',
          update_id: 40667,
        },
        vulnerabilities: [],
      },
      {
        id: 9677688,
        slug: 'bravada/bravada.php',
        name: 'Bravada',
        version: '1.11.5',
        update: {
          version: '4.1.1',
          released_at: '2022-01-25 05:16:57',
          update_id: 95227,
        },
        vulnerabilities: [],
      },
      {
        id: 54599990,
        slug: 'envoshopper/envoshopper.php',
        name: 'Envo Shopper',
        version: '1.7.4',
        update: {
          version: '3.4.1',
          released_at: '2021-11-23 08:03:57',
          update_id: 90162,
        },
        vulnerabilities: [],
      },
      {
        id: 89539213,
        slug: 'pathway/pathway.php',
        name: 'Pathway',
        version: '1.11.12',
        update: {
          version: '3.4.3',
          released_at: '2022-02-05 15:02:25',
          update_id: 77073,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite800',
      environment_type: 'production',
      site_id: 0,
      account_name: 'account-name-2',
      account_id: '2',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [
        {
          external_id: 'wsscan uuid1',
          severity: 'high',
          fixed_in_version: '6.0.1',
          type: 'XSS',
          description:
            'Data from an attacker could be interpreted as code by site visitors’ web browsers. The ability to run code in another site visitors’ browser can be abused to steal information, or modify site configuration.',
          last_updated_at: '2022-11-25T12:38:29.000Z',
        },
        {
          external_id: 'wsscan uuid2',
          severity: 'medium',
          fixed_in_version: null,
          type: 'OBJECT INJECTION',
          description:
            'This is an application level vulnerability that could allow an attacker to perform different kinds of malicious attacks, such as Code Injection, SQL Injection, Path Traversal and Application Denial of Service, depending on the context. This could result in sensitive data disclosure or site compromise.',
          last_updated_at: '2020-11-15T00:00:00.000Z',
        },
        {
          external_id: 'wsscan uuid3',
          severity: 'critical',
          fixed_in_version: '6.0.1',
          type: 'TRAVERSAL',
          description:
            'This is an application level vulnerability that could allow an attacker to perform different kinds of malicious attacks, such as Code Injection, SQL Injection, Path Traversal and Application Denial of Service, depending on the context.  This could result in sensitive data disclosure or site compromise.',
          last_updated_at: '2021-04-11T00:00:00.000Z',
        },
        {
          external_id: 'wsscan uuid7',
          severity: 'high',
          fixed_in_version: '6.0.1',
          type: 'NO AUTHORISATION',
          description:
            'The software does not perform an authorization check when an actor attempts to access a resource or perform an action.',
          last_updated_at: '2021-12-04T12:38:29.000Z',
        },
        {
          external_id: 'wsscan uuid4',
          severity: 'low',
          fixed_in_version: '6.0.1',
          type: 'INJECTION',
          description:
            'This is an application level vulnerability that could allow an attacker to perform different kinds of malicious attacks, such as Code Injection, SQL Injection, Path Traversal and Application Denial of Service, depending on the context.  This could result in sensitive data disclosure or site compromise.',
          last_updated_at: '2022-11-25T12:38:29.000Z',
        },
      ],
    },
    php: {
      version: '7.4.0',
      update: {
        version: '8.0.0',
      },
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-28T06:00:00.000Z',
    lastRun: {
      environmentId: 80,
      id: 80,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'failure',
      finish_message: 'failure',
    },
  },
  {
    id: 81,
    plugins: [],
    themes: [
      {
        id: 68709116,
        slug: 'ashe/ashe.php',
        name: 'Ashe',
        version: '1.13.2',
        update: {
          version: '4.3.2',
          released_at: '2022-02-11 17:07:46',
          update_id: 13093,
        },
        vulnerabilities: [],
      },
      {
        id: 56446232,
        slug: 'phlox/phlox.php',
        name: 'Phlox',
        version: '1.15.12',
        update: {
          version: '4.3.3',
          released_at: '2022-01-11 02:44:08',
          update_id: 28776,
        },
        vulnerabilities: [],
      },
      {
        id: 71183937,
        slug: 'envoshopper/envoshopper.php',
        name: 'Envo Shopper',
        version: '1.4.11',
        update: {
          version: '3.4.1',
          released_at: '2021-11-23 08:03:57',
          update_id: 75041,
        },
        vulnerabilities: [],
      },
      {
        id: 97739487,
        slug: 'pathway/pathway.php',
        name: 'Pathway',
        version: '1.8.14',
        update: {
          version: '3.4.3',
          released_at: '2022-02-05 15:02:25',
          update_id: 77870,
        },
        vulnerabilities: [],
      },
      {
        id: 15308583,
        slug: 'blogstream/blogstream.php',
        name: 'Blogstream',
        version: '1.9.9',
        update: {
          version: '2.1.5',
          released_at: '2021-10-13 04:58:05',
          update_id: 35850,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite801',
      environment_type: 'development',
      site_id: 10000,
      account_name: 'account-name-2',
      account_id: '2',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [
        {
          external_id: 'wsscan uuid1',
          severity: 'high',
          fixed_in_version: '6.0.0',
          type: 'XSS',
          description:
            'Data from an attacker could be interpreted as code by site visitors’ web browsers. The ability to run code in another site visitors’ browser can be abused to steal information, or modify site configuration.',
          last_updated_at: '2022-11-25T12:38:29.000Z',
        },
      ],
    },
    autoupdater_active: false,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-29T06:00:00.000Z',
    lastRun: {
      environmentId: 81,
      id: 81,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_rolled_back',
      finish_message: 'warning',
    },
  },
  {
    id: 82,
    plugins: [
      {
        id: 558277684,
        slug: 'classiceditor/classiceditor.php',
        name: 'Classic Editor',
        version: '1.1.4',
        updates_enabled: true,
        update: {
          version: '3.3.3',
          released_at: '2022-06-06 13:35:56',
          update_id: 61226,
        },
        vulnerabilities: [],
      },
      {
        id: 134884159,
        slug: 'reallysimplessl/reallysimplessl.php',
        name: 'Really Simple SSL',
        version: '1.5.10',
        updates_enabled: false,
        update: {
          version: '4.2.3',
          released_at: '2022-06-02 11:21:46',
          update_id: 77002,
        },
        vulnerabilities: [],
      },
      {
        id: 53011047,
        slug: 'locotranslate/locotranslate.php',
        name: 'Loco Translate',
        version: '1.1.12',
        update: {
          version: '2.1.3',
          released_at: '2022-07-05 13:25:49',
          update_id: 10451,
        },
        vulnerabilities: [],
      },
      {
        id: 52723607,
        slug: 'loginizer/loginizer.php',
        name: 'Loginizer',
        version: '1.2.4',
        update: {
          version: '2.1.4',
          released_at: '2022-08-05 03:37:15',
          update_id: 29923,
        },
        vulnerabilities: [],
      },
      {
        id: 429825824,
        slug: 'ithemessecurity/ithemessecurity.php',
        name: 'iThemes Security',
        version: '1.2.13',
        update: {
          version: '2.3.2',
          released_at: '2022-04-09 14:35:54',
          update_id: 70189,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 24442244,
        slug: 'twentysixteen/twentysixteen.php',
        name: 'Twenty Sixteen',
        version: '1.11.12',
        update: {
          version: '5.4.3',
          released_at: '2021-10-28 22:37:35',
          update_id: 98096,
        },
        vulnerabilities: [],
      },
      {
        id: 27771987,
        slug: 'ashe/ashe.php',
        name: 'Ashe',
        version: '1.6.7',
        update: {
          version: '4.3.2',
          released_at: '2022-02-11 17:07:46',
          update_id: 27079,
        },
        vulnerabilities: [],
      },
      {
        id: 50178269,
        slug: 'colormag/colormag.php',
        name: 'ColorMag',
        version: '1.6.2',
        update: {
          version: '2.1.3',
          released_at: '2021-11-02 02:03:51',
          update_id: 58026,
        },
        vulnerabilities: [],
      },
      {
        id: 46149166,
        slug: 'bravada/bravada.php',
        name: 'Bravada',
        version: '1.12.10',
        update: {
          version: '4.1.1',
          released_at: '2022-01-25 05:16:57',
          update_id: 82826,
        },
        vulnerabilities: [],
      },
      {
        id: 39009417,
        slug: 'phlox/phlox.php',
        name: 'Phlox',
        version: '1.12.2',
        update: {
          version: '4.3.3',
          released_at: '2022-01-11 02:44:08',
          update_id: 36143,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite802',
      environment_type: 'staging',
      site_id: 20000,
      account_name: 'account-name-3',
      account_id: '3',
    },
    php: {
      version: '7.4',
      update: {
        version: '8.0',
      },
    },
    autoupdater_active: true,
    autoupdater_enabled: false,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-11T06:00:00.000Z',
    lastRun: {
      environmentId: 82,
      id: 82,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed',
      finish_message: 'update passed',
    },
  },
  {
    id: 110,
    plugins: [
      {
        id: 81665026,
        slug: 'contactform7/contactform7.php',
        name: 'Contact Form 7',
        version: '1.2.3',
        update: {
          version: '3.4.3',
          released_at: '2022-02-27 05:39:45',
          update_id: 72337,
        },
        vulnerabilities: [],
      },
      {
        id: 87796919,
        slug: 'elementorwebsitebuilder/elementorwebsitebuilder.php',
        name: 'Elementor Website Builder',
        version: '1.1.13',
        update: {
          version: '2.2.3',
          released_at: '2022-06-30 10:40:14',
          update_id: 18115,
        },
        vulnerabilities: [],
      },
      {
        id: 347554758,
        slug: 'allinoneseo/allinoneseo.php',
        name: 'All in One SEO',
        version: '1.7.11',
        update: {
          version: '5.3.4',
          released_at: '2022-06-30 00:52:10',
          update_id: 56219,
        },
        vulnerabilities: [],
      },
      {
        id: 424430671,
        slug: 'advancededitortools/advancededitortools.php',
        name: 'Advanced Editor Tools',
        version: '1.1.10',
        update: {
          version: '2.1.2',
          released_at: '2021-12-06 06:02:14',
          update_id: 36548,
        },
        vulnerabilities: [],
      },
      {
        id: 605157895,
        slug: 'disablecomments/disablecomments.php',
        name: 'Disable Comments',
        version: '1.1.4',
        update: {
          version: '4.2.3',
          released_at: '2021-10-13 02:52:53',
          update_id: 55486,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 62600250,
        slug: 'flavita/flavita.php',
        name: 'Flavita',
        version: '1.7.14',
        update: {
          version: '2.5.4',
          released_at: '2022-06-08 02:21:39',
          update_id: 26090,
        },
        vulnerabilities: [],
      },
      {
        id: 4705794,
        slug: 'newsway/newsway.php',
        name: 'News Way',
        version: '1.11.5',
        update: {
          version: '3.3.3',
          released_at: '2022-04-15 20:54:37',
          update_id: 97976,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1100',
      environment_type: 'production',
      site_id: 30000,
      account_name: 'account-name-2',
      account_id: '2',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: false,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-14T06:00:00.000Z',
    lastRun: {
      environmentId: 110,
      id: 110,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed_and_rolled_back',
      finish_message: 'update passed and rolled back',
    },
  },
  {
    id: 111,
    plugins: [
      {
        id: 559807974,
        slug: 'elementorwebsitebuilder/elementorwebsitebuilder.php',
        name: 'Elementor Website Builder',
        version: '1.5.9',
        update: {
          version: '2.2.3',
          released_at: '2022-06-30 10:40:14',
          update_id: 46597,
        },
        vulnerabilities: [],
      },
      {
        id: 125636789,
        slug: 'disablecomments/disablecomments.php',
        name: 'Disable Comments',
        version: '1.4.3',
        update: {
          version: '4.2.3',
          released_at: '2021-10-13 02:52:53',
          update_id: 64139,
        },
        vulnerabilities: [],
      },
      {
        id: 258852717,
        slug: 'classicwidgets/classicwidgets.php',
        name: 'Classic Widgets',
        version: '1.5.9',
        update: {
          version: '2.4.5',
          released_at: '2022-01-08 16:37:13',
          update_id: 58814,
        },
        vulnerabilities: [],
      },
      {
        id: 562019532,
        slug: 'managewpworker/managewpworker.php',
        name: 'ManageWP Worker',
        version: '1.5.11',
        update: {
          version: '4.3.3',
          released_at: '2021-12-19 18:19:14',
          update_id: 16042,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 84733963,
        slug: 'twentytwenty-one/twentytwenty-one.php',
        name: 'Twenty Twenty-One',
        version: '1.5.3',
        update: {
          version: '5.4.4',
          released_at: '2022-04-24 03:42:12',
          update_id: 70461,
        },
        vulnerabilities: [],
      },
      {
        id: 98909592,
        slug: 'flavita/flavita.php',
        name: 'Flavita',
        version: '1.3.1',
        update: {
          version: '2.5.4',
          released_at: '2022-06-08 02:21:39',
          update_id: 76767,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1101',
      environment_type: 'development',
      site_id: 40000,
      account_name: 'account-name-1',
      account_id: '1',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [
        {
          externalId: '0',
          severity: 'medium',
          fixedInVersion: '1.0',
          type: 'multi',
          description: 'description',
          lastUpdatedAt: '',
        },
      ],
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: false,
    autoupdate_scheduled_at: '2022-09-13T06:00:00.000Z',
    lastRun: {
      environmentId: 111,
      id: 111,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_rolled_back',
      finish_message: 'warning',
    },
  },
  {
    id: 112,
    plugins: [
      {
        id: 356549472,
        slug: 'allinoneseo/allinoneseo.php',
        name: 'All in One SEO',
        version: '1.2.7',
        update: {
          version: '5.3.4',
          released_at: '2022-06-30 00:52:10',
          update_id: 45471,
        },
        vulnerabilities: [],
      },
      {
        id: 77591736,
        slug: 'disablecomments/disablecomments.php',
        name: 'Disable Comments',
        version: '1.8.12',
        update: {
          version: '4.2.3',
          released_at: '2021-10-13 02:52:53',
          update_id: 47806,
        },
        vulnerabilities: [],
      },
      {
        id: 42837737,
        slug: 'classicwidgets/classicwidgets.php',
        name: 'Classic Widgets',
        version: '1.2.4',
        update: {
          version: '2.4.5',
          released_at: '2022-01-08 16:37:13',
          update_id: 86554,
        },
        vulnerabilities: [],
      },
      {
        id: 331932168,
        slug: 'rankmathseo/rankmathseo.php',
        name: 'Rank Math SEO',
        version: '1.8.14',
        update: {
          version: '2.5.4',
          released_at: '2022-06-15 13:24:14',
          update_id: 81194,
        },
        vulnerabilities: [],
      },
      {
        id: 823757755,
        slug: 'managewpworker/managewpworker.php',
        name: 'ManageWP Worker',
        version: '1.5.12',
        update: {
          version: '4.3.3',
          released_at: '2021-12-19 18:19:14',
          update_id: 92518,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 31022655,
        slug: 'twentytwenty-one/twentytwenty-one.php',
        name: 'Twenty Twenty-One',
        version: '1.10.7',
        update: {
          version: '5.4.4',
          released_at: '2022-04-24 03:42:12',
          update_id: 32910,
        },
        vulnerabilities: [],
      },
      {
        id: 66602716,
        slug: 'twentyseventeen/twentyseventeen.php',
        name: 'Twenty Seventeen',
        version: '1.4.14',
        update: {
          version: '5.1.5',
          released_at: '2021-12-15 09:32:26',
          update_id: 35586,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1102',
      environment_type: 'staging',
      site_id: 50000,
      account_name: 'account-name-3',
      account_id: '3',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-20T06:00:00.000Z',
    lastRun: {
      environmentId: 112,
      id: 112,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'no_updates',
      finish_message: 'success',
    },
  },
  {
    id: 70,
    plugins: [
      {
        id: 765602365,
        slug: 'advancededitortools/advancededitortools.php',
        name: 'Advanced Editor Tools',
        updates_enabled: true,
        version: '1.8.11',
        update: {
          version: '2.1.2',
          released_at: '2021-12-06 06:02:14',
          update_id: 63410,
        },
        vulnerabilities: [],
      },
      {
        id: 84043356,
        slug: 'locotranslate/locotranslate.php',
        name: 'Loco Translate',
        updates_enabled: false,
        version: '1.4.3',
        update: {
          version: '2.1.3',
          released_at: '2022-07-05 13:25:49',
          update_id: 93974,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 47460589,
        slug: 'twentyeleven/twentyeleven.php',
        name: 'Twenty Eleven',
        version: '1.13.1',
        update: {
          version: '4.3.1',
          released_at: '2022-06-09 01:07:38',
          update_id: 89740,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite700',
      environment_type: 'production',
      site_id: 1,
      account_name: 'account-name-2',
      account_id: '2',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-26T06:00:00.000Z',
    lastRun: {
      environmentId: 70,
      id: 70,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed',
      finish_message: 'update passed',
    },
  },
  {
    id: 71,
    plugins: [
      {
        id: 611829750,
        slug: 'mailchimpforwordpress/mailchimpforwordpress.php',
        name: 'Mailchimp for WordPress',
        version: '1.7.9',
        update: {
          version: '3.1.1',
          released_at: '2022-06-19 23:57:15',
          update_id: 87660,
        },
        vulnerabilities: [],
      },
      {
        id: 461508881,
        slug: 'customposttypeui/customposttypeui.php',
        name: 'Custom Post Type UI',
        version: '1.8.6',
        update: {
          version: '4.4.4',
          released_at: '2022-07-02 11:58:35',
          update_id: 98566,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 31664774,
        slug: 'blocksy/blocksy.php',
        name: 'Blocksy',
        version: '1.15.7',
        update: {
          version: '2.1.5',
          released_at: '2021-10-13 23:47:34',
          update_id: 61838,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite701',
      environment_type: 'development',
      site_id: 10001,
      account_name: 'account-name-2',
      account_id: '2',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [
        {
          externalId: '0',
          severity: 'medium',
          fixedInVersion: '1.0',
          type: 'multi',
          description: 'description',
          lastUpdatedAt: '',
        },
      ],
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-24T06:00:00.000Z',
    lastRun: {
      environmentId: 71,
      id: 71,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'no_updates',
      finish_message: 'success',
    },
  },
  {
    id: 72,
    plugins: [],
    themes: [],
    provider_metadata: {
      install_name: 'testsite702',
      environment_type: 'staging',
      site_id: 20001,
      account_name: 'account-name-1',
      account_id: '1',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [
        {
          externalId: '0',
          severity: 'medium',
          fixedInVersion: '1.0',
          type: 'multi',
          description: 'description',
          lastUpdatedAt: '',
        },
      ],
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-26T06:00:00.000Z',
    lastRun: {
      environmentId: 72,
      id: 72,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'no_updates',
      finish_message: 'no updates',
    },
  },
  {
    id: 130,
    plugins: [
      {
        id: 782274465,
        slug: 'all-in-onewpmigration/all-in-onewpmigration.php',
        name: 'All-in-One WP Migration',
        version: '1.5.2',
        update: {
          version: '2.5.3',
          released_at: '2022-04-28 02:54:39',
          update_id: 65066,
        },
        vulnerabilities: [],
      },
      {
        id: 628752197,
        slug: 'sitegroundoptimizer/sitegroundoptimizer.php',
        name: 'SiteGround Optimizer',
        version: '1.5.5',
        update: {
          version: '2.2.2',
          released_at: '2021-08-23 02:59:02',
          update_id: 11441,
        },
        vulnerabilities: [],
      },
    ],
    themes: [],
    provider_metadata: {
      install_name: 'testsite1300',
      environment_type: 'production',
      site_id: 30001,
      account_name: 'account-name-2',
      account_id: '2',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-28T06:00:00.000Z',
    lastRun: {
      environmentId: 130,
      id: 130,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_rolled_back',
      finish_message: 'warning',
    },
  },
  {
    id: 131,
    plugins: [
      {
        id: 466211054,
        slug: 'sitegroundoptimizer/sitegroundoptimizer.php',
        name: 'SiteGround Optimizer',
        version: '1.6.3',
        update: {
          version: '2.2.2',
          released_at: '2021-08-23 02:59:02',
          update_id: 33346,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 86650067,
        slug: 'twentytwenty-one/twentytwenty-one.php',
        name: 'Twenty Twenty-One',
        version: '1.8.10',
        update: {
          version: '5.4.4',
          released_at: '2022-04-24 03:42:12',
          update_id: 59488,
        },
        vulnerabilities: [],
      },
      {
        id: 94916755,
        slug: 'helloelementor/helloelementor.php',
        name: 'Hello Elementor',
        version: '1.8.15',
        update: {
          version: '2.3.1',
          released_at: '2022-03-27 07:44:50',
          update_id: 24012,
        },
        vulnerabilities: [],
      },
      {
        id: 6084028,
        slug: 'kadence/kadence.php',
        name: 'Kadence',
        version: '1.15.12',
        update: {
          version: '3.1.4',
          released_at: '2021-11-16 18:49:26',
          update_id: 40064,
        },
        vulnerabilities: [],
      },
      {
        id: 71882880,
        slug: 'generatepress/generatepress.php',
        name: 'GeneratePress',
        version: '1.14.1',
        update: {
          version: '5.3.1',
          released_at: '2021-12-20 19:22:38',
          update_id: 48823,
        },
        vulnerabilities: [],
      },
      {
        id: 41025925,
        slug: 'hestia/hestia.php',
        name: 'Hestia',
        version: '1.14.1',
        update: {
          version: '2.1.1',
          released_at: '2022-08-04 21:48:42',
          update_id: 97006,
        },
        vulnerabilities: [],
      },
      {
        id: 58801863,
        slug: 'rifefree/rifefree.php',
        name: 'Rife Free',
        version: '1.3.12',
        update: {
          version: '5.1.4',
          released_at: '2021-12-02 20:34:55',
          update_id: 13107,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1301',
      environment_type: 'development',
      site_id: 40001,
      account_name: 'account-name-1',
      account_id: '1',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-12T06:00:00.000Z',
    lastRun: {
      environmentId: 131,
      id: 131,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed',
      finish_message: 'update passed',
    },
  },
  {
    id: 132,
    plugins: [
      {
        id: 617480998,
        slug: 'jetpack/jetpack.php',
        name: 'Jetpack',
        version: '1.5.6',
        update: {
          version: '2.1.4',
          released_at: '2022-07-26 18:17:00',
          update_id: 65515,
        },
        vulnerabilities: [],
      },
      {
        id: 730880496,
        slug: 'xmlsitemaps/xmlsitemaps.php',
        name: 'XML Sitemaps',
        version: '1.7.8',
        update: {
          version: '2.3.2',
          released_at: '2021-09-02 16:06:54',
          update_id: 14918,
        },
        vulnerabilities: [],
      },
      {
        id: 790466598,
        slug:
          'gutenbergtemplateandpatternlibrary/gutenbergtemplateandpatternlibrary.php',
        name: 'Gutenberg Template and Pattern Library',
        version: '1.3.3',
        update: {
          version: '4.5.5',
          released_at: '2022-05-15 10:14:16',
          update_id: 78303,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 70651057,
        slug: 'helloelementor/helloelementor.php',
        name: 'Hello Elementor',
        version: '1.12.1',
        update: {
          version: '2.3.1',
          released_at: '2022-03-27 07:44:50',
          update_id: 22149,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1302',
      environment_type: 'staging',
      site_id: 50001,
      account_name: 'account-name-1',
      account_id: '1',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [
        {
          externalId: '0',
          severity: 'medium',
          fixedInVersion: '1.0',
          type: 'multi',
          description: 'description',
          lastUpdatedAt: '',
        },
      ],
    },
    php: {
      version: '7.4.0',
      update: {
        version: '8.0.0',
      },
    },
    autoupdater_active: false,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-11T06:00:00.000Z',
    lastRun: {
      environmentId: 132,
      id: 132,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed_and_rolled_back',
      finish_message: 'update passed and rolled back',
    },
  },
  {
    id: 10,
    plugins: [
      {
        id: 595872419,
        slug: 'litespeedcache/litespeedcache.php',
        name: 'LiteSpeed Cache',
        active: true,
        version: '1.5.13',
        update: {
          version: '4.4.2',
          released_at: '2022-03-15 16:16:53',
          update_id: 55255,
        },
        vulnerabilities: [],
      },
      {
        id: 19786512,
        slug: 'nitropack/main.php',
        name: 'NitroPack',
        active: true,
        version: '1.10.0',
        update: null,
        vulnerabilities: [],
      },
      {
        id: 185027409,
        slug: 'allinoneseo/allinoneseo.php',
        name: 'All in One SEO',
        version: '1.8.6',
        update: {
          version: '5.3.4',
          released_at: '2022-06-30 00:52:10',
          update_id: 97821,
        },
        vulnerabilities: [],
      },
      {
        id: 586716910,
        slug: 'allinonewpsecurity/allinonewpsecurity.php',
        name: 'All In One WP Security',
        version: '1.3.4',
        update: {
          version: '3.1.2',
          released_at: '2022-01-13 11:50:48',
          update_id: 96231,
        },
        vulnerabilities: [],
      },
      {
        id: 716884899,
        slug: 'ninjaformscontactform/ninjaformscontactform.php',
        name: 'Ninja Forms Contact Form',
        version: '1.1.7',
        update: {
          version: '2.3.1',
          released_at: '2022-03-08 16:46:55',
          update_id: 77696,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 83550564,
        slug: 'blankcanvas/blankcanvas.php',
        name: 'Blank Canvas',
        version: '1.7.7',
        update: {
          version: '2.1.5',
          released_at: '2021-11-30 21:45:23',
          update_id: 47827,
        },
        vulnerabilities: [],
      },
      {
        id: 37272122,
        slug: 'newsup/newsup.php',
        name: 'Newsup',
        version: '1.11.13',
        update: {
          version: '3.3.3',
          released_at: '2021-08-23 23:19:34',
          update_id: 80444,
        },
        vulnerabilities: [],
      },
      {
        id: 92491016,
        slug: 'mesmerize/mesmerize.php',
        name: 'Mesmerize',
        version: '1.8.2',
        update: {
          version: '2.5.3',
          released_at: '2021-12-04 04:35:10',
          update_id: 24696,
        },
        vulnerabilities: [],
      },
      {
        id: 18115862,
        slug: 'blogsite/blogsite.php',
        name: 'BlogSite',
        version: '1.4.1',
        update: {
          version: '3.5.4',
          released_at: '2022-04-18 05:18:50',
          update_id: 57517,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite100',
      environment_type: 'production',
      site_id: 2,
      account_name: 'account-name-2',
      account_id: '2',
    },
    php: {
      version: '7.4.0',
      update: {
        version: '8.0.0',
      },
    },
    autoupdater_active: true,
    autoupdater_enabled: false,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-13T06:00:00.000Z',
    lastRun: {
      environmentId: 10,
      id: 10,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'no_updates',
      finish_message: 'no updates',
    },
  },
  {
    id: 11,
    plugins: [
      {
        id: 849501858,
        slug: 'elementorwebsitebuilder/elementorwebsitebuilder.php',
        name: 'Elementor Website Builder',
        version: '1.4.7',
        update: {
          version: '2.2.3',
          released_at: '2022-06-30 10:40:14',
          update_id: 40251,
        },
        vulnerabilities: [],
      },
      {
        id: 737233340,
        slug: 'litespeedcache/litespeedcache.php',
        active: true,
        name: 'LiteSpeed Cache',
        version: '1.3.11',
        update: {
          version: '4.4.2',
          released_at: '2022-03-15 16:16:53',
          update_id: 21314,
        },
        vulnerabilities: [],
      },
      {
        id: 792297169,
        slug: 'allinoneseo/allinoneseo.php',
        name: 'All in One SEO',
        version: '1.6.8',
        update: {
          version: '5.3.4',
          released_at: '2022-06-30 00:52:10',
          update_id: 40868,
        },
        vulnerabilities: [],
      },
      {
        id: 289068096,
        slug: 'wp-optimize/wp-optimize.php',
        active: true,
        name: 'WP-Optimize',
        version: '1.8.11',
        update: {
          version: '2.4.5',
          released_at: '2022-01-08 06:18:13',
          update_id: 32097,
        },
        vulnerabilities: [],
      },
      {
        id: 924712040,
        slug: 'wpmultibytepatch/wpmultibytepatch.php',
        name: 'WP Multibyte Patch',
        version: '1.9.1',
        update: {
          version: '3.4.1',
          released_at: '2021-09-11 13:33:03',
          update_id: 39130,
        },
        vulnerabilities: [],
      },
      {
        id: 547321499,
        slug: 'allinonewpsecurity/allinonewpsecurity.php',
        name: 'All In One WP Security',
        version: '1.4.4',
        update: {
          version: '3.1.2',
          released_at: '2022-01-13 11:50:48',
          update_id: 82980,
        },
        vulnerabilities: [],
      },
      {
        id: 737186727,
        slug: 'ninjaformscontactform/ninjaformscontactform.php',
        name: 'Ninja Forms Contact Form',
        version: '1.5.11',
        update: {
          version: '2.3.1',
          released_at: '2022-03-08 16:46:55',
          update_id: 65291,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 19005754,
        slug: 'blankcanvas/blankcanvas.php',
        name: 'Blank Canvas',
        version: '1.5.12',
        update: {
          version: '2.1.5',
          released_at: '2021-11-30 21:45:23',
          update_id: 48869,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite101',
      environment_type: 'development',
      site_id: 10002,
      account_name: 'account-name-3',
      account_id: '3',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [
        {
          externalId: '0',
          severity: 'medium',
          fixedInVersion: '1.0',
          type: 'multi',
          description: 'description',
          lastUpdatedAt: '',
        },
      ],
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: false,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-21T06:00:00.000Z',
    lastRun: {
      environmentId: 11,
      id: 11,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_rolled_back',
      finish_message: 'warning',
    },
  },
  {
    id: 12,
    plugins: [
      {
        id: 242779699,
        slug: 'elementorwebsitebuilder/elementorwebsitebuilder.php',
        name: 'Elementor Website Builder',
        version: '1.6.5',
        update: {
          version: '2.2.3',
          released_at: '2022-06-30 10:40:14',
          update_id: 87580,
        },
        vulnerabilities: [],
      },
      {
        id: 200139860,
        slug: 'allinoneseo/allinoneseo.php',
        name: 'All in One SEO',
        version: '1.9.5',
        update: {
          version: '5.3.4',
          released_at: '2022-06-30 00:52:10',
          update_id: 99118,
        },
        vulnerabilities: [],
      },
      {
        id: 141054861,
        slug: 'bettersearchreplace/bettersearchreplace.php',
        name: 'Better Search Replace',
        version: '1.4.14',
        update: {
          version: '2.1.4',
          released_at: '2022-05-01 12:54:43',
          update_id: 19762,
        },
        vulnerabilities: [],
      },
      {
        id: 390445027,
        slug: 'wp-optimize/wp-optimize.php',
        name: 'WP-Optimize',
        version: '1.3.12',
        update: {
          version: '2.4.5',
          released_at: '2022-01-08 06:18:13',
          update_id: 50343,
        },
        vulnerabilities: [],
      },
      {
        id: 401173285,
        slug: 'ninjaformscontactform/ninjaformscontactform.php',
        name: 'Ninja Forms Contact Form',
        version: '1.2.13',
        update: {
          version: '2.3.1',
          released_at: '2022-03-08 16:46:55',
          update_id: 62469,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 16424435,
        slug: 'royalelementorkit/royalelementorkit.php',
        name: 'Royal Elementor Kit',
        version: '1.13.14',
        update: {
          version: '2.4.2',
          released_at: '2022-05-30 09:37:50',
          update_id: 11835,
        },
        vulnerabilities: [],
      },
      {
        id: 10057386,
        slug: 'zakra/zakra.php',
        name: 'Zakra',
        version: '1.15.13',
        update: {
          version: '2.1.5',
          released_at: '2022-02-01 17:36:02',
          update_id: 80217,
        },
        vulnerabilities: [],
      },
      {
        id: 72482269,
        slug: 'onepress/onepress.php',
        name: 'OnePress',
        version: '1.13.15',
        update: {
          version: '2.4.4',
          released_at: '2022-07-05 09:30:52',
          update_id: 29182,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite102',
      environment_type: 'staging',
      site_id: 20002,
      account_name: 'account-name-2',
      account_id: '2',
    },
    autoupdater_active: true,
    autoupdater_enabled: false,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: false,
    autoupdate_scheduled_at: '2022-09-27T06:00:00.000Z',
    lastRun: {
      environmentId: 12,
      id: 12,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'no_updates',
      finish_message: 'success',
    },
  },
  {
    id: 40,
    plugins: [
      {
        id: 595872419,
        slug: 'litespeedcache/litespeedcache.php',
        name: 'LiteSpeed Cache',
        version: '1.5.13',
        update: {
          version: '4.4.2',
          released_at: '2022-03-15 16:16:53',
          update_id: 55255,
        },
        vulnerabilities: [],
      },
      {
        id: 185027409,
        slug: 'imagify/imagify.php',
        name: 'Imagify',
        version: '1.8.6',
        update: {
          version: '5.3.4',
          released_at: '2022-06-30 00:52:10',
          update_id: 97821,
        },
        vulnerabilities: [],
      },
      {
        id: 586716910,
        slug: 'allinonewpsecurity/allinonewpsecurity.php',
        name: 'All In One WP Security',
        version: '1.3.4',
        update: {
          version: '3.1.2',
          released_at: '2022-01-13 11:50:48',
          update_id: 96231,
        },
        vulnerabilities: [],
      },
      {
        id: 716884899,
        slug: 'ninjaformscontactform/ninjaformscontactform.php',
        name: 'Ninja Forms Contact Form',
        version: '1.1.7',
        update: {
          version: '2.3.1',
          released_at: '2022-03-08 16:46:55',
          update_id: 77696,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 83550564,
        slug: 'blankcanvas/blankcanvas.php',
        name: 'Blank Canvas',
        version: '1.7.7',
        update: {
          version: '2.1.5',
          released_at: '2021-11-30 21:45:23',
          update_id: 47827,
        },
        vulnerabilities: [],
      },
      {
        id: 37272122,
        slug: 'newsup/newsup.php',
        name: 'Newsup',
        version: '1.11.13',
        update: {
          version: '3.3.3',
          released_at: '2021-08-23 23:19:34',
          update_id: 80444,
        },
        vulnerabilities: [],
      },
      {
        id: 92491016,
        slug: 'mesmerize/mesmerize.php',
        name: 'Mesmerize',
        version: '1.8.2',
        update: {
          version: '2.5.3',
          released_at: '2021-12-04 04:35:10',
          update_id: 24696,
        },
        vulnerabilities: [],
      },
      {
        id: 18115862,
        slug: 'blogsite/blogsite.php',
        name: 'BlogSite',
        version: '1.4.1',
        update: {
          version: '3.5.4',
          released_at: '2022-04-18 05:18:50',
          update_id: 57517,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite400',
      environment_type: 'production',
      site_id: 3,
      account_name: 'account-name-1',
      account_id: '1',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [],
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-19T06:00:00.000Z',
    lighthouse_monitoring_active: true,
    lighthouse_monitoring_config: {
      notification_score_drop_accessibility_enabled: true,
      notification_score_drop_best_practices_enabled: true,
      notification_score_drop_performance_enabled: true,
      notification_score_drop_seo_enabled: true,
      notification_score_threshold_accessibility_enabled: true,
      notification_score_threshold_best_practices_enabled: true,
      notification_score_threshold_performance_enabled: true,
      notification_score_threshold_seo_enabled: true,
      notification_score_drop_accessibility: 1,
      notification_score_drop_best_practices: 1,
      notification_score_drop_performance: 1,
      notification_score_drop_seo: 1,
      notification_score_threshold_accessibility: 100,
      notification_score_threshold_best_practices: 100,
      notification_score_threshold_performance: 100,
      notification_score_threshold_seo: 80,
    },
    lastRun: {
      environmentId: 40,
      id: 40,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_failed',
      finish_message: 'update failed',
    },
  },
  {
    id: 41,
    plugins: [
      {
        id: 649710027,
        slug: 'wordpressimporter/wordpressimporter.php',
        name: 'WordPress Importer',
        version: '1.8.9',
        update: {
          version: '2.4.1',
          released_at: '2022-03-22 11:20:40',
          update_id: 71588,
        },
        vulnerabilities: [],
      },
      {
        id: 19786512,
        slug: 'nitropack/main.php',
        name: 'NitroPack',
        active: true,
        version: '1.10.0',
        update: null,
        vulnerabilities: [],
      },
      {
        id: 400229639,
        slug: 'wpshidelogin/wpshidelogin.php',
        name: 'WPS Hide Login',
        version: '1.7.3',
        update: {
          version: '5.5.2',
          released_at: '2022-02-23 07:18:33',
          update_id: 85580,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 17390509,
        slug: 'oceanwp/oceanwp.php',
        name: 'OceanWP',
        version: '1.5.11',
        update: {
          version: '5.4.5',
          released_at: '2022-04-30 02:22:40',
          update_id: 52601,
        },
        vulnerabilities: [],
      },
      {
        id: 90675103,
        slug: 'shapely/shapely.php',
        name: 'Shapely',
        version: '1.13.15',
        update: {
          version: '5.3.1',
          released_at: '2022-05-18 12:44:42',
          update_id: 81159,
        },
        vulnerabilities: [],
      },
      {
        id: 93805641,
        slug: 'lightning/lightning.php',
        name: 'Lightning',
        version: '1.2.14',
        update: {
          version: '2.4.5',
          released_at: '2021-11-19 06:03:30',
          update_id: 33221,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite401',
      environment_type: 'development',
      site_id: 10003,
      account_name: 'account-name-3',
      account_id: '3',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-21T06:00:00.000Z',
    lastRun: {
      environmentId: 41,
      id: 41,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'failure',
      finish_message: 'failure',
    },
  },
  {
    id: 42,
    plugins: [
      {
        id: 555051186,
        slug: 'wordpressimporter/wordpressimporter.php',
        name: 'WordPress Importer',
        version: '1.1.8',
        update: {
          version: '2.4.1',
          released_at: '2022-03-22 11:20:40',
          update_id: 55115,
        },
        vulnerabilities: [],
      },
      {
        id: 19786512,
        slug: 'nitropack/main.php',
        name: 'NitroPack',
        active: true,
        version: '1.10.0',
        update: null,
        vulnerabilities: [],
      },
      {
        id: 585920209,
        slug: 'autoptimize/autoptimize.php',
        name: 'Autoptimize',
        version: '1.9.4',
        update: {
          version: '2.2.1',
          released_at: '2021-08-31 21:21:01',
          update_id: 19152,
        },
        vulnerabilities: [],
      },
      {
        id: 354977665,
        slug: 'wpshidelogin/wpshidelogin.php',
        name: 'WPS Hide Login',
        version: '1.4.8',
        update: {
          version: '5.5.2',
          released_at: '2022-02-23 07:18:33',
          update_id: 32891,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 33643683,
        slug: 'sinatra/sinatra.php',
        name: 'Sinatra',
        version: '1.9.8',
        update: {
          version: '2.5.2',
          released_at: '2021-10-13 10:30:23',
          update_id: 13395,
        },
        vulnerabilities: [],
      },
      {
        id: 1114103,
        slug: 'twentytwelve/twentytwelve.php',
        name: 'Twenty Twelve',
        version: '1.12.15',
        update: {
          version: '4.3.2',
          released_at: '2021-12-12 19:30:22',
          update_id: 18370,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite402',
      environment_type: 'staging',
      site_id: 20003,
      account_name: 'account-name-3',
      account_id: '3',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-23T06:00:00.000Z',
    lastRun: {
      environmentId: 42,
      id: 42,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_rolled_back',
      finish_message: 'warning',
    },
  },
  {
    id: 60,
    plugins: [
      {
        id: 18908101,
        slug: 'akismetspamprotection/akismetspamprotection.php',
        name: 'Akismet Spam Protection',
        version: '1.3.2',
        update: {
          version: '2.5.5',
          released_at: '2021-10-03 05:45:50',
          update_id: 41734,
        },
        vulnerabilities: [],
      },
      {
        id: 60860451,
        slug: 'mailchimpforwordpress/mailchimpforwordpress.php',
        name: 'Mailchimp for WordPress',
        version: '1.4.10',
        update: {
          version: '3.1.1',
          released_at: '2022-06-19 23:57:15',
          update_id: 38970,
        },
        vulnerabilities: [],
      },
      {
        id: 181862338,
        slug: 'sitegroundoptimizer/sitegroundoptimizer.php',
        name: 'SiteGround Optimizer',
        version: '1.1.9',
        update: {
          version: '2.2.2',
          released_at: '2021-08-23 02:59:02',
          update_id: 26050,
        },
        vulnerabilities: [],
      },
      {
        id: 547611691,
        slug: 'smashballoonsocialphotofeed/smashballoonsocialphotofeed.php',
        name: 'Smash Balloon Social Photo Feed',
        version: '1.5.8',
        update: {
          version: '2.4.1',
          released_at: '2021-11-02 11:03:50',
          update_id: 43531,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 91986132,
        slug: 'mesmerize/mesmerize.php',
        name: 'Mesmerize',
        version: '1.14.4',
        update: {
          version: '2.5.3',
          released_at: '2021-12-04 04:35:10',
          update_id: 26594,
        },
        vulnerabilities: [],
      },
      {
        id: 61084912,
        slug: 'futurio/futurio.php',
        name: 'Futurio',
        version: '1.4.12',
        update: {
          version: '3.5.5',
          released_at: '2022-04-20 03:54:05',
          update_id: 70493,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite600',
      environment_type: 'production',
      site_id: 4,
      account_name: 'account-name-2',
      account_id: '2',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-25T06:00:00.000Z',
    lighthouse_monitoring_active: false,
    lighthouse_monitoring_config: {
      notification_score_drop_accessibility_enabled: true,
      notification_score_drop_best_practices_enabled: true,
      notification_score_drop_performance_enabled: true,
      notification_score_drop_seo_enabled: true,
      notification_score_threshold_accessibility_enabled: true,
      notification_score_threshold_best_practices_enabled: true,
      notification_score_threshold_performance_enabled: true,
      notification_score_threshold_seo_enabled: true,
      notification_score_drop_accessibility: 1,
      notification_score_drop_best_practices: 1,
      notification_score_drop_performance: 1,
      notification_score_drop_seo: 1,
      notification_score_threshold_accessibility: 100,
      notification_score_threshold_best_practices: 100,
      notification_score_threshold_performance: 100,
      notification_score_threshold_seo: 80,
    },
    lastRun: {
      environmentId: 60,
      id: 60,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed',
      finish_message: 'update passed',
    },
  },
  {
    id: 61,
    plugins: [
      {
        id: 754302813,
        slug: 'mailchimpforwordpress/mailchimpforwordpress.php',
        name: 'Mailchimp for WordPress',
        version: '1.1.4',
        update: {
          version: '3.1.1',
          released_at: '2022-06-19 23:57:15',
          update_id: 89744,
        },
        vulnerabilities: [],
      },
      {
        id: 549256689,
        slug: 'startertemplates/startertemplates.php',
        name: 'Starter Templates',
        version: '1.4.3',
        update: {
          version: '5.4.4',
          released_at: '2022-06-22 13:45:21',
          update_id: 71918,
        },
        vulnerabilities: [],
      },
      {
        id: 284066210,
        slug: 'smashballoonsocialphotofeed/smashballoonsocialphotofeed.php',
        name: 'Smash Balloon Social Photo Feed',
        version: '1.3.6',
        update: {
          version: '2.4.1',
          released_at: '2021-11-02 11:03:50',
          update_id: 88026,
        },
        vulnerabilities: [],
      },
      {
        id: 72076853,
        slug: 'w3totalcache/w3totalcache.php',
        name: 'W3 Total Cache',
        version: '1.3.8',
        update: {
          version: '2.2.3',
          released_at: '2021-11-26 18:26:42',
          update_id: 67475,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 14123272,
        slug: 'chromenews/chromenews.php',
        name: 'ChromeNews',
        version: '1.7.2',
        update: {
          version: '3.2.3',
          released_at: '2022-08-01 04:27:59',
          update_id: 51584,
        },
        vulnerabilities: [],
      },
      {
        id: 94633266,
        slug: 'total/total.php',
        name: 'Total',
        version: '1.11.10',
        update: {
          version: '2.5.2',
          released_at: '2022-03-21 15:57:23',
          update_id: 91804,
        },
        vulnerabilities: [],
      },
      {
        id: 86909515,
        slug: 'mesmerize/mesmerize.php',
        name: 'Mesmerize',
        version: '1.2.4',
        update: {
          version: '2.5.3',
          released_at: '2021-12-04 04:35:10',
          update_id: 86064,
        },
        vulnerabilities: [],
      },
      {
        id: 51734071,
        slug: 'refinedblocks/refinedblocks.php',
        name: 'Refined Blocks',
        version: '1.7.2',
        update: {
          version: '5.2.1',
          released_at: '2022-05-24 21:16:07',
          update_id: 87838,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite601',
      environment_type: 'development',
      site_id: 10004,
      account_name: 'account-name-3',
      account_id: '3',
    },
    php: {
      version: '7.4.0',
      update: {
        version: '8.0.0',
      },
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-10T06:00:00.000Z',
    lastRun: {
      environmentId: 61,
      id: 61,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_failed',
      finish_message: 'update failed',
    },
  },
  {
    id: 62,
    plugins: [
      {
        id: 638546530,
        slug: 'duplicatepage/duplicatepage.php',
        name: 'Duplicate Page',
        version: '1.3.2',
        update: {
          version: '5.3.1',
          released_at: '2022-04-07 22:44:43',
          update_id: 64046,
        },
        vulnerabilities: [],
      },
      {
        id: 628451994,
        slug: 'mailchimpforwordpress/mailchimpforwordpress.php',
        name: 'Mailchimp for WordPress',
        version: '1.3.5',
        update: {
          version: '3.1.1',
          released_at: '2022-06-19 23:57:15',
          update_id: 38832,
        },
        vulnerabilities: [],
      },
      {
        id: 62774089,
        slug: 'sitegroundoptimizer/sitegroundoptimizer.php',
        name: 'SiteGround Optimizer',
        version: '1.3.8',
        update: {
          version: '2.2.2',
          released_at: '2021-08-23 02:59:02',
          update_id: 32976,
        },
        vulnerabilities: [],
      },
      {
        id: 314197728,
        slug: 'smashballoonsocialphotofeed/smashballoonsocialphotofeed.php',
        name: 'Smash Balloon Social Photo Feed',
        version: '1.7.1',
        update: {
          version: '2.4.1',
          released_at: '2021-11-02 11:03:50',
          update_id: 97323,
        },
        vulnerabilities: [],
      },
      {
        id: 929425609,
        slug: 'ithemessecurity/ithemessecurity.php',
        name: 'iThemes Security',
        version: '1.5.13',
        update: {
          version: '2.3.2',
          released_at: '2022-04-09 14:35:54',
          update_id: 91145,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 95523200,
        slug: 'chromenews/chromenews.php',
        name: 'ChromeNews',
        version: '1.5.14',
        update: {
          version: '3.2.3',
          released_at: '2022-08-01 04:27:59',
          update_id: 97472,
        },
        vulnerabilities: [],
      },
      {
        id: 65636206,
        slug: 'total/total.php',
        name: 'Total',
        version: '1.7.2',
        update: {
          version: '2.5.2',
          released_at: '2022-03-21 15:57:23',
          update_id: 45252,
        },
        vulnerabilities: [],
      },
      {
        id: 6318545,
        slug: 'refinedblocks/refinedblocks.php',
        name: 'Refined Blocks',
        version: '1.14.8',
        update: {
          version: '5.2.1',
          released_at: '2022-05-24 21:16:07',
          update_id: 70632,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite602',
      environment_type: 'staging',
      site_id: 20004,
      account_name: 'account-name-3',
      account_id: '3',
    },
    php: {
      version: '7.4.0',
      update: {
        version: '8.0.0',
      },
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-17T06:00:00.000Z',
    lastRun: {
      environmentId: 62,
      id: 62,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed_and_failed',
      finish_message: 'update passed and failed',
    },
  },
  {
    id: 50,
    plugins: [
      {
        id: 929897542,
        slug: 'jetpack/jetpack.php',
        name: 'Jetpack',
        version: '1.5.3',
        update: {
          version: '4.1.4',
          released_at: '2022-07-26 18:17:00',
          update_id: 28819,
        },
        vulnerabilities: [],
      },
      {
        id: 115233799,
        slug: 'monsterinsights/monsterinsights.php',
        name: 'MonsterInsights',
        version: '1.8.14',
        update: {
          version: '3.4.1',
          released_at: '2022-04-05 12:41:55',
          update_id: 26113,
        },
        vulnerabilities: [],
      },
      {
        id: 906340100,
        slug: 'advancedcustomfields/advancedcustomfields.php',
        name: 'Advanced Custom Fields',
        version: '1.9.12',
        update: {
          version: '5.2.1',
          released_at: '2021-09-09 13:26:32',
          update_id: 38210,
        },
        vulnerabilities: [],
      },
      {
        id: 904649485,
        slug: 'essentialaddonsforelementor/essentialaddonsforelementor.php',
        name: 'Essential Addons for Elementor',
        version: '1.4.8',
        update: {
          version: '2.2.5',
          released_at: '2022-03-24 21:39:30',
          update_id: 96475,
        },
        vulnerabilities: [],
      },
      {
        id: 164135224,
        slug: 'wpmultibytepatch/wpmultibytepatch.php',
        name: 'WP Multibyte Patch',
        version: '1.3.11',
        update: {
          version: '3.4.1',
          released_at: '2021-09-11 13:33:03',
          update_id: 18051,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 64391794,
        slug: 'helloelementor/helloelementor.php',
        name: 'Hello Elementor',
        version: '1.8.11',
        update: {
          version: '2.3.1',
          released_at: '2022-03-27 07:44:50',
          update_id: 91681,
        },
        vulnerabilities: [],
      },
      {
        id: 41759591,
        slug: 'oceanwp/oceanwp.php',
        name: 'OceanWP',
        version: '1.2.8',
        update: {
          version: '5.4.5',
          released_at: '2022-04-30 02:22:40',
          update_id: 65118,
        },
        vulnerabilities: [],
      },
      {
        id: 19079500,
        slug: 'twentyseventeen/twentyseventeen.php',
        name: 'Twenty Seventeen',
        version: '1.1.3',
        update: {
          version: '5.1.5',
          released_at: '2021-12-15 09:32:26',
          update_id: 93946,
        },
        vulnerabilities: [],
      },
      {
        id: 39544139,
        slug: 'popularfx/popularfx.php',
        name: 'PopularFX',
        version: '1.5.5',
        update: {
          version: '2.1.5',
          released_at: '2022-07-15 22:34:12',
          update_id: 25362,
        },
        vulnerabilities: [],
      },
      {
        id: 55339572,
        slug: 'flavita/flavita.php',
        name: 'Flavita',
        version: '1.15.4',
        update: {
          version: '2.5.4',
          released_at: '2022-06-08 02:21:39',
          update_id: 65386,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite500',
      environment_type: 'production',
      site_id: 8,
      account_name: 'account-name-2',
      account_id: '2',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [],
    },
    autoupdater_active: false,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-14T06:00:00.000Z',
    lighthouse_monitoring_active: true,
    lighthouse_monitoring_config: {
      notification_score_drop_accessibility_enabled: true,
      notification_score_drop_best_practices_enabled: true,
      notification_score_drop_performance_enabled: false,
      notification_score_drop_seo_enabled: false,
      notification_score_threshold_accessibility_enabled: true,
      notification_score_threshold_best_practices_enabled: true,
      notification_score_threshold_performance_enabled: false,
      notification_score_threshold_seo_enabled: false,
      notification_score_drop_accessibility: 1,
      notification_score_drop_best_practices: 1,
      notification_score_drop_performance: 1,
      notification_score_drop_seo: 1,
      notification_score_threshold_accessibility: 100,
      notification_score_threshold_best_practices: 100,
      notification_score_threshold_performance: 100,
      notification_score_threshold_seo: 100,
    },
    lastRun: {
      environmentId: 50,
      id: 50,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed_and_failed',
      finish_message: 'update passed and failed',
    },
  },
  {
    id: 51,
    plugins: [
      {
        id: 991274572,
        slug: 'monsterinsights/monsterinsights.php',
        name: 'MonsterInsights',
        version: '1.4.14',
        update: {
          version: '3.4.1',
          released_at: '2022-04-05 12:41:55',
          update_id: 37992,
        },
        vulnerabilities: [],
      },
      {
        id: 356240640,
        slug: 'advancedcustomfields/advancedcustomfields.php',
        name: 'Advanced Custom Fields',
        version: '1.1.12',
        update: {
          version: '5.2.1',
          released_at: '2021-09-09 13:26:32',
          update_id: 72131,
        },
        vulnerabilities: [],
      },
      {
        id: 896829171,
        slug: 'startertemplates/startertemplates.php',
        name: 'Starter Templates',
        version: '1.7.10',
        update: {
          version: '5.4.4',
          released_at: '2022-06-22 13:45:21',
          update_id: 13458,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 99039136,
        slug: 'helloelementor/helloelementor.php',
        name: 'Hello Elementor',
        version: '1.13.3',
        update: {
          version: '2.3.1',
          released_at: '2022-03-27 07:44:50',
          update_id: 42331,
        },
        vulnerabilities: [],
      },
      {
        id: 26999014,
        slug: 'go/go.php',
        name: 'Go',
        version: '1.5.9',
        update: {
          version: '5.2.3',
          released_at: '2022-04-19 17:27:57',
          update_id: 46941,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite501',
      environment_type: 'development',
      site_id: 10008,
      account_name: 'account-name-1',
      account_id: '1',
    },
    php: {
      version: '7.4.0',
      update: {
        version: '8.0.0',
      },
    },
    autoupdater_active: true,
    autoupdater_enabled: false,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-19T06:00:00.000Z',
    lastRun: {
      environmentId: 51,
      id: 51,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'failure',
      finish_message: 'failure',
    },
  },
  {
    id: 52,
    plugins: [
      {
        id: 274136180,
        slug: 'advancedcustomfields/advancedcustomfields.php',
        name: 'Advanced Custom Fields',
        version: '1.4.2',
        update: {
          version: '5.2.1',
          released_at: '2021-09-09 13:26:32',
          update_id: 59476,
        },
        vulnerabilities: [],
      },
      {
        id: 393060165,
        slug: 'startertemplates/startertemplates.php',
        name: 'Starter Templates',
        version: '1.2.2',
        update: {
          version: '5.4.4',
          released_at: '2022-06-22 13:45:21',
          update_id: 46156,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 58756351,
        slug: 'popularfx/popularfx.php',
        name: 'PopularFX',
        version: '1.3.4',
        update: {
          version: '2.1.5',
          released_at: '2022-07-15 22:34:12',
          update_id: 15471,
        },
        vulnerabilities: [],
      },
      {
        id: 63303331,
        slug: 'go/go.php',
        name: 'Go',
        version: '1.11.10',
        update: {
          version: '5.2.3',
          released_at: '2022-04-19 17:27:57',
          update_id: 58586,
        },
        vulnerabilities: [],
      },
      {
        id: 66443895,
        slug: 'zakra/zakra.php',
        name: 'Zakra',
        version: '1.13.11',
        update: {
          version: '2.1.5',
          released_at: '2022-02-01 17:36:02',
          update_id: 83159,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite502',
      environment_type: 'staging',
      site_id: 20008,
      account_name: 'account-name-2',
      account_id: '2',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [],
    },
    php: {
      version: '7.4.0',
      update: {
        version: '8.0.0',
      },
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: false,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-26T06:00:00.000Z',
    lastRun: {
      environmentId: 52,
      id: 52,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_rolled_back',
      finish_message: 'warning',
    },
  },
  {
    id: 90,
    plugins: [
      {
        id: 556657955,
        slug: 'duplicator/duplicator.php',
        name: 'Duplicator',
        version: '1.2.13',
        update: {
          version: '2.4.1',
          released_at: '2022-06-24 15:17:17',
          update_id: 72071,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 63389638,
        slug: 'go/go.php',
        name: 'Go',
        version: '1.7.5',
        update: {
          version: '5.2.3',
          released_at: '2022-04-19 17:27:57',
          update_id: 62726,
        },
        vulnerabilities: [],
      },
      {
        id: 19846273,
        slug: 'onepress/onepress.php',
        name: 'OnePress',
        version: '1.2.3',
        update: {
          version: '2.4.4',
          released_at: '2022-07-05 09:30:52',
          update_id: 69429,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite900',
      environment_type: 'production',
      site_id: 9,
      account_name: 'account-name-1',
      account_id: '1',
    },
    php: {
      version: '7.4.0',
      update: {
        version: '8.0.0',
      },
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: false,
    autoupdate_scheduled_at: '2022-09-25T06:00:00.000Z',
    lastRun: {
      environmentId: 90,
      id: 90,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_rolled_back',
      finish_message: 'warning',
    },
  },
  {
    id: 91,
    plugins: [
      {
        id: 620612081,
        slug: 'cookienotice/cookienotice.php',
        name: 'Cookie Notice',
        version: '1.8.6',
        update: {
          version: '2.2.4',
          released_at: '2022-01-30 07:12:19',
          update_id: 20517,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 99612951,
        slug: 'go/go.php',
        name: 'Go',
        version: '1.4.9',
        update: {
          version: '5.2.3',
          released_at: '2022-04-19 17:27:57',
          update_id: 21446,
        },
        vulnerabilities: [],
      },
      {
        id: 55773280,
        slug: 'zakra/zakra.php',
        name: 'Zakra',
        version: '1.12.2',
        update: {
          version: '2.1.5',
          released_at: '2022-02-01 17:36:02',
          update_id: 43029,
        },
        vulnerabilities: [],
      },
      {
        id: 66932202,
        slug: 'onepress/onepress.php',
        name: 'OnePress',
        version: '1.14.1',
        update: {
          version: '2.4.4',
          released_at: '2022-07-05 09:30:52',
          update_id: 89455,
        },
        vulnerabilities: [],
      },
      {
        id: 50879103,
        slug: 'brite/brite.php',
        name: 'Brite',
        version: '1.7.10',
        update: {
          version: '2.1.3',
          released_at: '2022-02-12 05:19:03',
          update_id: 48339,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite901',
      environment_type: 'development',
      site_id: 10009,
      account_name: 'account-name-1',
      account_id: '1',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-13T06:00:00.000Z',

    lastRun: {
      environmentId: 91,
      id: 91,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_failed',
      finish_message: 'update failed',
    },
  },
  {
    id: 92,
    plugins: [
      {
        id: 769438223,
        slug: 'cookienotice/cookienotice.php',
        name: 'Cookie Notice',
        version: '1.5.9',
        update: {
          version: '2.2.4',
          released_at: '2022-01-30 07:12:19',
          update_id: 23783,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 31226025,
        slug: 'onepress/onepress.php',
        name: 'OnePress',
        version: '1.11.1',
        update: {
          version: '2.4.4',
          released_at: '2022-07-05 09:30:52',
          update_id: 89628,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite902',
      environment_type: 'staging',
      site_id: 20009,
      account_name: 'account-name-1',
      account_id: '1',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-17T06:00:00.000Z',
    lastRun: {
      environmentId: 92,
      id: 92,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed',
      finish_message: 'update passed',
    },
  },
  {
    id: 150,
    plugins: [
      {
        id: 917119034,
        slug: 'all-in-onewpmigration/all-in-onewpmigration.php',
        name: 'All-in-One WP Migration',
        version: '1.3.9',
        update: {
          version: '2.5.3',
          released_at: '2022-04-28 02:54:39',
          update_id: 65517,
        },
        vulnerabilities: [],
      },
      {
        id: 312038978,
        slug: 'sitekitbygoogle/sitekitbygoogle.php',
        name: 'Site Kit by Google',
        version: '1.2.10',
        update: {
          version: '2.3.2',
          released_at: '2021-10-26 07:22:11',
          update_id: 96155,
        },
        vulnerabilities: [],
      },
      {
        id: 401594396,
        slug: 'limitloginattemptsreloaded/limitloginattemptsreloaded.php',
        name: 'Limit Login Attempts Reloaded',
        version: '1.3.6',
        update: {
          version: '3.4.1',
          released_at: '2022-06-02 22:03:39',
          update_id: 63286,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 35887898,
        slug: 'astra/astra.php',
        name: 'Astra',
        version: '1.5.5',
        update: {
          version: '5.3.2',
          released_at: '2021-12-01 02:33:21',
          update_id: 83409,
        },
        vulnerabilities: [],
      },
      {
        id: 46055385,
        slug: 'inspiro/inspiro.php',
        name: 'Inspiro',
        version: '1.12.15',
        update: {
          version: '3.1.2',
          released_at: '2022-06-12 07:42:25',
          update_id: 77333,
        },
        vulnerabilities: [],
      },
      {
        id: 27098203,
        slug: 'newsment/newsment.php',
        name: 'Newsment',
        version: '1.12.4',
        update: {
          version: '3.1.5',
          released_at: '2021-10-17 00:00:56',
          update_id: 84190,
        },
        vulnerabilities: [],
      },
      {
        id: 45161450,
        slug: 'shapely/shapely.php',
        name: 'Shapely',
        version: '1.12.14',
        update: {
          version: '5.3.1',
          released_at: '2022-05-18 12:44:42',
          update_id: 46624,
        },
        vulnerabilities: [],
      },
      {
        id: 75929249,
        slug: 'refinedblocks/refinedblocks.php',
        name: 'Refined Blocks',
        version: '1.15.11',
        update: {
          version: '5.2.1',
          released_at: '2022-05-24 21:16:07',
          update_id: 42096,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1500',
      environment_type: 'production',
      site_id: 60009,
      account_name: 'account-name-3',
      account_id: '3',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-23T06:00:00.000Z',
    lastRun: {
      environmentId: 150,
      id: 150,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'no_updates',
      finish_message: 'success',
    },
  },
  {
    id: 151,
    plugins: [
      {
        id: 710905525,
        slug: 'all-in-onewpmigration/all-in-onewpmigration.php',
        name: 'All-in-One WP Migration',
        version: '1.8.14',
        update: {
          version: '2.5.3',
          released_at: '2022-04-28 02:54:39',
          update_id: 74563,
        },
        vulnerabilities: [],
      },
      {
        id: 577425741,
        slug: 'wpfastestcache/wpfastestcache.php',
        name: 'WP Fastest Cache',
        version: '1.1.12',
        update: {
          version: '2.1.1',
          released_at: '2021-10-20 12:42:59',
          update_id: 62719,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 78575947,
        slug: 'shapely/shapely.php',
        name: 'Shapely',
        version: '1.3.10',
        update: {
          version: '5.3.1',
          released_at: '2022-05-18 12:44:42',
          update_id: 85414,
        },
        vulnerabilities: [],
      },
      {
        id: 91301100,
        slug: 'qi/qi.php',
        name: 'Qi',
        version: '1.8.15',
        update: {
          version: '3.5.5',
          released_at: '2022-01-23 18:07:58',
          update_id: 22744,
        },
        vulnerabilities: [],
      },
      {
        id: 77179451,
        slug: 'refinedblocks/refinedblocks.php',
        name: 'Refined Blocks',
        version: '1.11.8',
        update: {
          version: '5.2.1',
          released_at: '2022-05-24 21:16:07',
          update_id: 64877,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1501',
      environment_type: 'development',
      site_id: 70009,
      account_name: 'account-name-1',
      account_id: '1',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [],
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-27T06:00:00.000Z',
    lastRun: {
      environmentId: 151,
      id: 151,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_failed',
      finish_message: 'update failed',
    },
  },
  {
    id: 152,
    plugins: [
      {
        id: 648345110,
        slug: 'all-in-onewpmigration/all-in-onewpmigration.php',
        name: 'All-in-One WP Migration',
        version: '1.6.9',
        update: {
          version: '2.5.3',
          released_at: '2022-04-28 02:54:39',
          update_id: 75545,
        },
        vulnerabilities: [],
      },
      {
        id: 23339067,
        slug: 'limitloginattemptsreloaded/limitloginattemptsreloaded.php',
        name: 'Limit Login Attempts Reloaded',
        version: '1.9.10',
        update: {
          version: '3.4.1',
          released_at: '2022-06-02 22:03:39',
          update_id: 68413,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 40098739,
        slug: 'astra/astra.php',
        name: 'Astra',
        version: '1.7.3',
        update: {
          version: '5.3.2',
          released_at: '2021-12-01 02:33:21',
          update_id: 78807,
        },
        vulnerabilities: [],
      },
      {
        id: 34463346,
        slug: 'inspiro/inspiro.php',
        name: 'Inspiro',
        version: '1.2.10',
        update: {
          version: '3.1.2',
          released_at: '2022-06-12 07:42:25',
          update_id: 84259,
        },
        vulnerabilities: [],
      },
      {
        id: 94802860,
        slug: 'newsment/newsment.php',
        name: 'Newsment',
        version: '1.7.12',
        update: {
          version: '3.1.5',
          released_at: '2021-10-17 00:00:56',
          update_id: 10811,
        },
        vulnerabilities: [],
      },
      {
        id: 39194350,
        slug: 'refinedblocks/refinedblocks.php',
        name: 'Refined Blocks',
        version: '1.13.7',
        update: {
          version: '5.2.1',
          released_at: '2022-05-24 21:16:07',
          update_id: 99414,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1502',
      environment_type: 'staging',
      site_id: 80009,
      account_name: 'account-name-1',
      account_id: '1',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-24T06:00:00.000Z',
    lastRun: {
      environmentId: 152,
      id: 152,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_rolled_back',
      finish_message: 'warning',
    },
  },
  {
    id: 20,
    plugins: [
      {
        id: 389706997,
        slug: 'yoastduplicatepost/yoastduplicatepost.php',
        name: 'Yoast Duplicate Post',
        version: '1.2.5',
        update: {
          version: '3.1.1',
          released_at: '2022-02-24 12:13:05',
          update_id: 59597,
        },
        vulnerabilities: [],
      },
      {
        id: 969772674,
        slug:
          'updraftpluswordpressbackupplugin/updraftpluswordpressbackupplugin.php',
        name: 'UpdraftPlus WordPress Backup Plugin',
        version: '1.1.14',
        update: {
          version: '5.1.4',
          released_at: '2022-07-07 01:01:08',
          update_id: 32029,
        },
        vulnerabilities: [],
      },
      {
        id: 818929707,
        slug: 'woocommerceshipping/woocommerceshipping.php',
        name: 'WooCommerce Shipping',
        version: '1.2.2',
        update: {
          version: '2.2.4',
          released_at: '2022-04-26 01:24:08',
          update_id: 86471,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 3990897,
        slug: 'twentyfourteen/twentyfourteen.php',
        name: 'Twenty Fourteen',
        version: '1.2.7',
        update: {
          version: '3.4.4',
          released_at: '2022-05-24 16:20:46',
          update_id: 56208,
        },
        vulnerabilities: [],
      },
      {
        id: 55261297,
        slug: 'newsup/newsup.php',
        name: 'Newsup',
        version: '1.14.12',
        update: {
          version: '3.3.3',
          released_at: '2021-08-23 23:19:34',
          update_id: 95401,
        },
        vulnerabilities: [],
      },
      {
        id: 82671043,
        slug: 'newsway/newsway.php',
        name: 'News Way',
        version: '1.1.3',
        update: {
          version: '3.3.3',
          released_at: '2022-04-15 20:54:37',
          update_id: 88249,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite200',
      environment_type: 'production',
      site_id: 11,
      account_name: 'account-name-1',
      account_id: '1',
    },
    php: {
      version: '7.4.0',
      update: {
        version: '8.0.0',
      },
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-12T06:00:00.000Z',
    lastRun: {
      environmentId: 20,
      id: 20,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed_and_rolled_back',
      finish_message: 'update passed and rolled back',
    },
  },
  {
    id: 21,
    plugins: [
      {
        id: 580452145,
        slug: 'yoastduplicatepost/yoastduplicatepost.php',
        name: 'Yoast Duplicate Post',
        version: '1.6.14',
        update: {
          version: '3.1.1',
          released_at: '2022-02-24 12:13:05',
          update_id: 25468,
        },
        vulnerabilities: [],
      },
      {
        id: 587517555,
        slug: 'ninjaformscontactform/ninjaformscontactform.php',
        name: 'Ninja Forms Contact Form',
        version: '1.7.2',
        update: {
          version: '2.3.1',
          released_at: '2022-03-08 16:46:55',
          update_id: 15982,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 61038928,
        slug: 'go/go.php',
        name: 'Go',
        version: '1.14.5',
        update: {
          version: '5.2.3',
          released_at: '2022-04-19 17:27:57',
          update_id: 74855,
        },
        vulnerabilities: [],
      },
      {
        id: 6791516,
        slug: 'newsup/newsup.php',
        name: 'Newsup',
        version: '1.5.8',
        update: {
          version: '3.3.3',
          released_at: '2021-08-23 23:19:34',
          update_id: 56065,
        },
        vulnerabilities: [],
      },
      {
        id: 17640675,
        slug: 'newsway/newsway.php',
        name: 'News Way',
        version: '1.13.12',
        update: {
          version: '3.3.3',
          released_at: '2022-04-15 20:54:37',
          update_id: 26393,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite201',
      environment_type: 'development',
      site_id: 10011,
      account_name: 'account-name-2',
      account_id: '2',
    },
    php: {
      version: '7.4.0',
      update: {
        version: '8.0.0',
      },
    },
    autoupdater_active: false,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-17T06:00:00.000Z',
    lastRun: {
      environmentId: 21,
      id: 21,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_failed',
      finish_message: 'update failed',
    },
  },
  {
    id: 22,
    plugins: [
      {
        id: 880021701,
        slug: 'yoastduplicatepost/yoastduplicatepost.php',
        name: 'Yoast Duplicate Post',
        version: '1.5.13',
        update: {
          version: '3.1.1',
          released_at: '2022-02-24 12:13:05',
          update_id: 43485,
        },
        vulnerabilities: [],
      },
      {
        id: 355492385,
        slug:
          'updraftpluswordpressbackupplugin/updraftpluswordpressbackupplugin.php',
        name: 'UpdraftPlus WordPress Backup Plugin',
        version: '1.4.2',
        update: {
          version: '5.1.4',
          released_at: '2022-07-07 01:01:08',
          update_id: 44242,
        },
        vulnerabilities: [],
      },
      {
        id: 443258320,
        slug: 'redirection/redirection.php',
        name: 'Redirection',
        version: '1.4.14',
        update: {
          version: '4.5.2',
          released_at: '2022-08-03 08:40:20',
          update_id: 87810,
        },
        vulnerabilities: [],
      },
      {
        id: 51212800,
        slug: 'ninjaformscontactform/ninjaformscontactform.php',
        name: 'Ninja Forms Contact Form',
        version: '1.4.7',
        update: {
          version: '2.3.1',
          released_at: '2022-03-08 16:46:55',
          update_id: 13189,
        },
        vulnerabilities: [],
      },
      {
        id: 919733374,
        slug: 'woocommerceshipping/woocommerceshipping.php',
        name: 'WooCommerce Shipping',
        version: '1.4.11',
        update: {
          version: '2.2.4',
          released_at: '2022-04-26 01:24:08',
          update_id: 19791,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 50445158,
        slug: 'go/go.php',
        name: 'Go',
        version: '1.13.11',
        update: {
          version: '5.2.3',
          released_at: '2022-04-19 17:27:57',
          update_id: 75937,
        },
        vulnerabilities: [],
      },
      {
        id: 82915950,
        slug: 'chromenews/chromenews.php',
        name: 'ChromeNews',
        version: '1.15.15',
        update: {
          version: '3.2.3',
          released_at: '2022-08-01 04:27:59',
          update_id: 35054,
        },
        vulnerabilities: [],
      },
      {
        id: 81577662,
        slug: 'newsup/newsup.php',
        name: 'Newsup',
        version: '1.11.3',
        update: {
          version: '3.3.3',
          released_at: '2021-08-23 23:19:34',
          update_id: 18143,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite202',
      environment_type: 'staging',
      site_id: 20011,
      account_name: 'account-name-1',
      account_id: '1',
    },
    autoupdater_active: true,
    autoupdater_enabled: false,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-27T06:00:00.000Z',
    lastRun: {
      environmentId: 22,
      id: 22,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_rolled_back',
      finish_message: 'warning',
    },
  },
  {
    id: 120,
    plugins: [
      {
        id: 577033918,
        slug: 'duplicatepage/duplicatepage.php',
        name: 'Duplicate Page',
        version: '1.4.10',
        update: {
          version: '5.3.1',
          released_at: '2022-04-07 22:44:43',
          update_id: 56903,
        },
        vulnerabilities: [],
      },
      {
        id: 792681380,
        slug: 'sitekitbygoogle/sitekitbygoogle.php',
        name: 'Site Kit by Google',
        version: '1.1.3',
        update: {
          version: '2.3.2',
          released_at: '2021-10-26 07:22:11',
          update_id: 92282,
        },
        vulnerabilities: [],
      },
      {
        id: 647650566,
        slug: 'limitloginattemptsreloaded/limitloginattemptsreloaded.php',
        name: 'Limit Login Attempts Reloaded',
        version: '1.6.13',
        update: {
          version: '3.4.1',
          released_at: '2022-06-02 22:03:39',
          update_id: 92673,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 69676705,
        slug: 'minimalistnewspaper/minimalistnewspaper.php',
        name: 'Minimalist Newspaper',
        version: '1.5.15',
        update: {
          version: '3.3.2',
          released_at: '2022-05-17 11:23:28',
          update_id: 80506,
        },
        vulnerabilities: [],
      },
      {
        id: 17169854,
        slug: 'twentytwelve/twentytwelve.php',
        name: 'Twenty Twelve',
        version: '1.3.5',
        update: {
          version: '4.3.2',
          released_at: '2021-12-12 19:30:22',
          update_id: 19058,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1200',
      environment_type: 'production',
      site_id: 30014,
      account_name: 'account-name-2',
      account_id: '2',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: false,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-22T06:00:00.000Z',
    lastRun: {
      environmentId: 120,
      id: 120,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'no_updates',
      finish_message: 'no updates',
    },
  },
  {
    id: 121,
    plugins: [
      {
        id: 883402025,
        slug: 'oneclickdemoimport/oneclickdemoimport.php',
        name: 'One Click Demo Import',
        version: '1.7.3',
        update: {
          version: '2.4.3',
          released_at: '2021-08-18 20:43:27',
          update_id: 31170,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 13561870,
        slug: 'kadence/kadence.php',
        name: 'Kadence',
        version: '1.15.15',
        update: {
          version: '3.1.4',
          released_at: '2021-11-16 18:49:26',
          update_id: 52921,
        },
        vulnerabilities: [],
      },
      {
        id: 32448506,
        slug: 'botiga/botiga.php',
        name: 'Botiga',
        version: '1.1.5',
        update: {
          version: '4.5.2',
          released_at: '2021-12-12 06:30:01',
          update_id: 33895,
        },
        vulnerabilities: [],
      },
      {
        id: 83930033,
        slug: 'minimalistnewspaper/minimalistnewspaper.php',
        name: 'Minimalist Newspaper',
        version: '1.10.6',
        update: {
          version: '3.3.2',
          released_at: '2022-05-17 11:23:28',
          update_id: 77498,
        },
        vulnerabilities: [],
      },
      {
        id: 67799036,
        slug: 'pathway/pathway.php',
        name: 'Pathway',
        version: '1.7.10',
        update: {
          version: '3.4.3',
          released_at: '2022-02-05 15:02:25',
          update_id: 47175,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1201',
      environment_type: 'development',
      site_id: 40014,
      account_name: 'account-name-3',
      account_id: '3',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: false,
    autoupdate_scheduled_at: '2022-09-11T06:00:00.000Z',
    lastRun: {
      environmentId: 121,
      id: 121,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_failed',
      finish_message: 'update failed',
    },
  },
  {
    id: 122,
    plugins: [
      {
        id: 811919695,
        slug: 'limitloginattemptsreloaded/limitloginattemptsreloaded.php',
        name: 'Limit Login Attempts Reloaded',
        version: '1.9.5',
        update: {
          version: '3.4.1',
          released_at: '2022-06-02 22:03:39',
          update_id: 51340,
        },
        vulnerabilities: [],
      },
      {
        id: 618081831,
        slug: 'duplicator/duplicator.php',
        name: 'Duplicator',
        version: '1.6.3',
        update: {
          version: '2.4.1',
          released_at: '2022-06-24 15:17:17',
          update_id: 25671,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 79773014,
        slug: 'twentynineteen/twentynineteen.php',
        name: 'Twenty Nineteen',
        version: '1.5.13',
        update: {
          version: '5.3.5',
          released_at: '2021-09-10 07:22:45',
          update_id: 63613,
        },
        vulnerabilities: [],
      },
      {
        id: 62413074,
        slug: 'twentyfifteen/twentyfifteen.php',
        name: 'Twenty Fifteen',
        version: '1.3.9',
        update: {
          version: '2.3.5',
          released_at: '2021-11-20 19:50:19',
          update_id: 47864,
        },
        vulnerabilities: [],
      },
      {
        id: 85651524,
        slug: 'botiga/botiga.php',
        name: 'Botiga',
        version: '1.12.12',
        update: {
          version: '4.5.2',
          released_at: '2021-12-12 06:30:01',
          update_id: 31638,
        },
        vulnerabilities: [],
      },
      {
        id: 78887645,
        slug: 'twentytwelve/twentytwelve.php',
        name: 'Twenty Twelve',
        version: '1.13.4',
        update: {
          version: '4.3.2',
          released_at: '2021-12-12 19:30:22',
          update_id: 10543,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1202',
      environment_type: 'staging',
      site_id: 50014,
      account_name: 'account-name-1',
      account_id: '1',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [],
    },
    php: {
      version: '7.4.0',
      update: {
        version: '8.0.0',
      },
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-27T06:00:00.000Z',
    lastRun: {
      environmentId: 122,
      id: 122,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed_and_failed',
      finish_message: 'update passed and failed',
    },
  },
  {
    id: 140,
    plugins: [
      {
        id: 759075218,
        slug: 'redirection/redirection.php',
        name: 'Redirection',
        version: '1.2.4',
        update: {
          version: '4.5.2',
          released_at: '2022-08-03 08:40:20',
          update_id: 46469,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 44929304,
        slug: 'neve/neve.php',
        name: 'Neve',
        version: '1.15.2',
        update: {
          version: '5.4.5',
          released_at: '2021-10-15 15:52:44',
          update_id: 94177,
        },
        vulnerabilities: [],
      },
      {
        id: 92539429,
        slug: 'customify/customify.php',
        name: 'Customify',
        version: '1.15.12',
        update: {
          version: '4.2.4',
          released_at: '2022-05-01 07:44:59',
          update_id: 54690,
        },
        vulnerabilities: [],
      },
      {
        id: 77890382,
        slug: 'vantage/vantage.php',
        name: 'Vantage',
        version: '1.4.8',
        update: {
          version: '3.4.4',
          released_at: '2022-03-07 06:34:02',
          update_id: 42849,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1400',
      environment_type: 'production',
      site_id: 17,
      account_name: 'account-name-3',
      account_id: '3',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-10T06:00:00.000Z',
    lastRun: {
      environmentId: 140,
      id: 140,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'no_updates',
      finish_message: 'success',
    },
  },
  {
    id: 141,
    plugins: [
      {
        id: 86347537,
        slug: 'redirection/redirection.php',
        name: 'Redirection',
        version: '1.6.2',
        update: {
          version: '4.5.2',
          released_at: '2022-08-03 08:40:20',
          update_id: 79379,
        },
        vulnerabilities: [],
      },
      {
        id: 139197743,
        slug: 'regeneratethumbnails/regeneratethumbnails.php',
        name: 'Regenerate Thumbnails',
        version: '1.7.12',
        update: {
          version: '3.1.3',
          released_at: '2022-05-02 11:16:56',
          update_id: 68384,
        },
        vulnerabilities: [],
      },
      {
        id: 290667897,
        slug: 'oneclickdemoimport/oneclickdemoimport.php',
        name: 'One Click Demo Import',
        version: '1.5.9',
        update: {
          version: '2.4.3',
          released_at: '2021-08-18 20:43:27',
          update_id: 79093,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 31143256,
        slug: 'neve/neve.php',
        name: 'Neve',
        version: '1.2.12',
        update: {
          version: '5.4.5',
          released_at: '2021-10-15 15:52:44',
          update_id: 61820,
        },
        vulnerabilities: [],
      },
      {
        id: 64035704,
        slug: 'customify/customify.php',
        name: 'Customify',
        version: '1.5.13',
        update: {
          version: '4.2.4',
          released_at: '2022-05-01 07:44:59',
          update_id: 84504,
        },
        vulnerabilities: [],
      },
      {
        id: 33451161,
        slug: 'phlox/phlox.php',
        name: 'Phlox',
        version: '1.7.5',
        update: {
          version: '4.3.3',
          released_at: '2022-01-11 02:44:08',
          update_id: 86909,
        },
        vulnerabilities: [],
      },
      {
        id: 56572366,
        slug: 'vantage/vantage.php',
        name: 'Vantage',
        version: '1.12.3',
        update: {
          version: '3.4.4',
          released_at: '2022-03-07 06:34:02',
          update_id: 59333,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1401',
      environment_type: 'development',
      site_id: 10017,
      account_name: 'account-name-2',
      account_id: '2',
    },
    php: {
      version: '7.4.0',
      update: {
        version: '8.0.0',
      },
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-23T06:00:00.000Z',
    lastRun: {
      environmentId: 141,
      id: 141,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed_and_rolled_back',
      finish_message: 'update passed and rolled back',
    },
  },
  {
    id: 142,
    plugins: [],
    themes: [
      {
        id: 65119178,
        slug: 'twentynineteen/twentynineteen.php',
        name: 'Twenty Nineteen',
        version: '1.3.6',
        update: {
          version: '5.3.5',
          released_at: '2021-09-10 07:22:45',
          update_id: 76560,
        },
        vulnerabilities: [],
      },
      {
        id: 47847678,
        slug: 'vantage/vantage.php',
        name: 'Vantage',
        version: '1.11.5',
        update: {
          version: '3.4.4',
          released_at: '2022-03-07 06:34:02',
          update_id: 18955,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1402',
      environment_type: 'staging',
      site_id: 20017,
      account_name: 'account-name-2',
      account_id: '2',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-17T06:00:00.000Z',
    lastRun: {
      environmentId: 142,
      id: 142,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed_and_failed',
      finish_message: 'update passed and failed',
    },
  },
  {
    id: 30,
    plugins: [
      {
        id: 477961561,
        slug: 'cookieyes/cookieyes.php',
        name: 'CookieYes',
        version: '1.2.12',
        update: {
          version: '3.4.3',
          released_at: '2021-08-10 09:34:40',
          update_id: 12816,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 59339858,
        slug: 'twentytwenty-two/twentytwenty-two.php',
        name: 'Twenty Twenty-Two',
        version: '1.6.15',
        update: {
          version: '2.1.4',
          released_at: '2022-02-22 20:02:10',
          update_id: 68745,
        },
        vulnerabilities: [],
      },
      {
        id: 14905576,
        slug: 'sydney/sydney.php',
        name: 'Sydney',
        version: '1.3.1',
        update: {
          version: '2.5.4',
          released_at: '2021-10-26 03:59:41',
          update_id: 68077,
        },
        vulnerabilities: [],
      },
      {
        id: 23350286,
        slug: 'newsup/newsup.php',
        name: 'Newsup',
        version: '1.5.15',
        update: {
          version: '3.3.3',
          released_at: '2021-08-23 23:19:34',
          update_id: 45165,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite300',
      environment_type: 'production',
      site_id: 37,
      account_name: 'account-name-2',
      account_id: '2',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [],
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-16T06:00:00.000Z',
    lastRun: {
      environmentId: 30,
      id: 30,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'no_updates',
      finish_message: 'success',
    },
  },
  {
    id: 31,
    plugins: [
      {
        id: 23049537,
        slug: 'elementorheaderfooterbuilder/elementorheaderfooterbuilder.php',
        name: 'Elementor Header & Footer Builder',
        version: '1.8.6',
        update: {
          version: '4.5.2',
          released_at: '2022-01-17 08:14:53',
          update_id: 87445,
        },
        vulnerabilities: [],
      },
      {
        id: 942967873,
        slug: 'cookieyes/cookieyes.php',
        name: 'CookieYes',
        version: '1.7.9',
        update: {
          version: '3.4.3',
          released_at: '2021-08-10 09:34:40',
          update_id: 11395,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 81527600,
        slug: 'twentytwenty/twentytwenty.php',
        name: 'Twenty Twenty',
        version: '1.1.11',
        update: {
          version: '4.1.1',
          released_at: '2022-04-17 18:23:29',
          update_id: 93012,
        },
        vulnerabilities: [],
      },
      {
        id: 1934787,
        slug: 'inspiro/inspiro.php',
        name: 'Inspiro',
        version: '1.6.13',
        update: {
          version: '3.1.2',
          released_at: '2022-06-12 07:42:25',
          update_id: 37071,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite301',
      environment_type: 'development',
      site_id: 10037,
      account_name: 'account-name-3',
      account_id: '3',
    },
    php: {
      version: '7.4.0',
      update: {
        version: '8.0.0',
      },
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-10T06:00:00.000Z',
    lastRun: {
      environmentId: 31,
      id: 31,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_rolled_back',
      finish_message: 'warning',
    },
  },
  {
    id: 32,
    plugins: [
      {
        id: 884361271,
        slug: 'autoptimize/autoptimize.php',
        name: 'Autoptimize',
        version: '1.7.10',
        update: {
          version: '2.2.1',
          released_at: '2021-08-31 21:21:01',
          update_id: 45837,
        },
        vulnerabilities: [],
      },
      {
        id: 323669738,
        slug: 'elementorheaderfooterbuilder/elementorheaderfooterbuilder.php',
        name: 'Elementor Header & Footer Builder',
        version: '1.4.11',
        update: {
          version: '4.5.2',
          released_at: '2022-01-17 08:14:53',
          update_id: 29409,
        },
        vulnerabilities: [],
      },
      {
        id: 124721596,
        slug: 'cookieyes/cookieyes.php',
        name: 'CookieYes',
        version: '1.1.11',
        update: {
          version: '3.4.3',
          released_at: '2021-08-10 09:34:40',
          update_id: 57316,
        },
        vulnerabilities: [],
      },
      {
        id: 467744110,
        slug: 'popupbuilder/popupbuilder.php',
        name: 'Popup Builder',
        version: '1.4.14',
        update: {
          version: '4.5.5',
          released_at: '2022-03-14 13:03:31',
          update_id: 66458,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 39608071,
        slug: 'twentytwenty/twentytwenty.php',
        name: 'Twenty Twenty',
        version: '1.5.10',
        update: {
          version: '4.1.1',
          released_at: '2022-04-17 18:23:29',
          update_id: 86321,
        },
        vulnerabilities: [],
      },
      {
        id: 73136569,
        slug: 'inspiro/inspiro.php',
        name: 'Inspiro',
        version: '1.5.11',
        update: {
          version: '3.1.2',
          released_at: '2022-06-12 07:42:25',
          update_id: 45938,
        },
        vulnerabilities: [],
      },
      {
        id: 15811929,
        slug: 'sydney/sydney.php',
        name: 'Sydney',
        version: '1.14.8',
        update: {
          version: '2.5.4',
          released_at: '2021-10-26 03:59:41',
          update_id: 94254,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite302',
      environment_type: 'staging',
      site_id: 20037,
      account_name: 'account-name-1',
      account_id: '1',
    },
    autoupdater_active: false,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-22T06:00:00.000Z',
    lastRun: {
      environmentId: 32,
      id: 32,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed_and_failed',
      finish_message: 'update passed and failed',
    },
  },
  {
    id: 100,
    plugins: [
      {
        id: 180608156,
        slug: 'autoupdater/autoupdater.php',
        name: 'WP Engine Smart Plugin Manager',
        version: '1.7.3',
        update: {
          version: '2.3.3',
          released_at: '2022-04-19 04:57:23',
          update_id: 31392,
        },
        vulnerabilities: [],
      },
      {
        id: 343618217,
        slug: 'wpmultibytepatch/wpmultibytepatch.php',
        name: 'WP Multibyte Patch',
        version: '1.6.9',
        update: {
          version: '3.4.1',
          released_at: '2021-09-11 13:33:03',
          update_id: 37380,
        },
        vulnerabilities: [],
      },
      {
        id: 835110110,
        slug: 'allinonewpsecurity/allinonewpsecurity.php',
        name: 'All In One WP Security',
        version: '1.7.6',
        update: {
          version: '3.1.2',
          released_at: '2022-01-13 11:50:48',
          update_id: 38087,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 83142916,
        slug: 'zeever/zeever.php',
        name: 'Zeever',
        version: '1.6.15',
        update: {
          version: '2.3.1',
          released_at: '2021-09-26 16:40:02',
          update_id: 83004,
        },
        vulnerabilities: [],
      },
      {
        id: 65560738,
        slug: 'phlox/phlox.php',
        name: 'Phlox',
        version: '1.8.3',
        update: {
          version: '4.3.3',
          released_at: '2022-01-11 02:44:08',
          update_id: 48556,
        },
        vulnerabilities: [],
      },
      {
        id: 21276157,
        slug: 'total/total.php',
        name: 'Total',
        version: '1.4.14',
        update: {
          version: '2.5.2',
          released_at: '2022-03-21 15:57:23',
          update_id: 91118,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1000',
      environment_type: 'production',
      site_id: 30045,
      account_name: 'account-name-1',
      account_id: '1',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [],
    },
    autoupdater_active: true,
    autoupdater_enabled: false,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-16T06:00:00.000Z',
    lastRun: {
      environmentId: 100,
      id: 100,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_failed',
      finish_message: 'update failed',
    },
  },
  {
    id: 101,
    plugins: [
      {
        id: 369425782,
        slug: 'autoupdater/autoupdater.php',
        name: 'WP Engine Smart Plugin Manager',
        version: '1.2.2',
        update: {
          version: '2.3.3',
          released_at: '2022-04-19 04:57:23',
          update_id: 71687,
        },
        vulnerabilities: [],
      },
      {
        id: 141718338,
        slug: 'ithemessecurity/ithemessecurity.php',
        name: 'iThemes Security',
        version: '1.7.10',
        update: {
          version: '2.3.2',
          released_at: '2022-04-09 14:35:54',
          update_id: 44358,
        },
        vulnerabilities: [],
      },
      {
        id: 627661402,
        slug: 'allinonewpsecurity/allinonewpsecurity.php',
        name: 'All In One WP Security',
        version: '1.6.4',
        update: {
          version: '3.1.2',
          released_at: '2022-01-13 11:50:48',
          update_id: 14230,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 24992048,
        slug: 'twentyseventeen/twentyseventeen.php',
        name: 'Twenty Seventeen',
        version: '1.6.9',
        update: {
          version: '5.1.5',
          released_at: '2021-12-15 09:32:26',
          update_id: 70645,
        },
        vulnerabilities: [],
      },
      {
        id: 54717954,
        slug: 'vantage/vantage.php',
        name: 'Vantage',
        version: '1.14.13',
        update: {
          version: '3.4.4',
          released_at: '2022-03-07 06:34:02',
          update_id: 25250,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1001',
      environment_type: 'development',
      site_id: 40045,
      account_name: 'account-name-1',
      account_id: '1',
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: false,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-29T06:00:00.000Z',
    lastRun: {
      environmentId: 101,
      id: 101,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_rolled_back',
      finish_message: 'warning',
    },
  },
  {
    id: 102,
    plugins: [
      {
        id: 597011610,
        slug: 'ithemessecurity/ithemessecurity.php',
        name: 'iThemes Security',
        version: '1.7.2',
        update: {
          version: '2.3.2',
          released_at: '2022-04-09 14:35:54',
          update_id: 40900,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 15218502,
        slug: 'twentyseventeen/twentyseventeen.php',
        name: 'Twenty Seventeen',
        version: '1.11.7',
        update: {
          version: '5.1.5',
          released_at: '2021-12-15 09:32:26',
          update_id: 38068,
        },
        vulnerabilities: [],
      },
      {
        id: 50791030,
        slug: 'phlox/phlox.php',
        name: 'Phlox',
        version: '1.13.5',
        update: {
          version: '4.3.3',
          released_at: '2022-01-11 02:44:08',
          update_id: 33155,
        },
        vulnerabilities: [],
      },
      {
        id: 25288218,
        slug: 'total/total.php',
        name: 'Total',
        version: '1.4.1',
        update: {
          version: '2.5.2',
          released_at: '2022-03-21 15:57:23',
          update_id: 59384,
        },
        vulnerabilities: [],
      },
      {
        id: 19820674,
        slug: 'vantage/vantage.php',
        name: 'Vantage',
        version: '1.3.7',
        update: {
          version: '3.4.4',
          released_at: '2022-03-07 06:34:02',
          update_id: 68762,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite1002',
      environment_type: 'staging',
      site_id: 50045,
      account_name: 'account-name-3',
      account_id: '3',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [],
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: false,
    autoupdate_scheduled_at: '2022-09-13T06:00:00.000Z',
    lastRun: {
      environmentId: 102,
      id: 102,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'no_updates',
      finish_message: 'no updates',
    },
  },
  {
    id: 2000,
    plugins: [
      {
        id: 180608156,
        slug: 'autoupdater/autoupdater.php',
        name: 'WP Engine Smart Plugin Manager',
        version: '1.7.3',
        update: {
          version: '2.3.3',
          released_at: '2022-04-19 04:57:23',
          update_id: 31392,
        },
        vulnerabilities: [],
      },
    ],
    themes: [
      {
        id: 83142916,
        slug: 'zeever/zeever.php',
        name: 'Zeever',
        version: '1.6.15',
        update: {
          version: '2.3.1',
          released_at: '2021-09-26 16:40:02',
          update_id: 83004,
        },
        vulnerabilities: [],
      },
    ],
    provider_metadata: {
      install_name: 'testsite2000',
      environment_type: 'production',
      site_id: 30045,
      account_name: 'account-name-1',
      account_id: '1',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [],
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-16T06:00:00.000Z',
    lastRun: null,
  },
  {
    id: 2100,
    plugins: [
      {
        id: 83142916,
        slug: 'zeever/zeever.php',
        name: 'Zeever',
        version: '1.6.15',
        update: {
          version: '2.3.1',
          released_at: '2021-09-26 16:40:02',
          update_id: 83004,
        },
        vulnerabilities: [],
      },
    ],
    themes: [],
    provider_metadata: {
      install_name: 'testsite2001',
      environment_type: 'production',
      site_id: 30045,
      account_name: 'account-name-2',
      account_id: '2',
    },
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [],
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-16T06:00:00.000Z',
    lastRun: {
      environmentId: 102,
      id: 102,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed_and_rolled_back',
      finish_message: 'no updates',
    },
  },
  {
    id: 1316377,
    provider_metadata: {
      install_name: 'blanksite3',
      environment_type: 'production',
      site_id: 1316377,
      account_name: 'account-name-2',
      account_id: '2',
    },
    plugins: [
      {
        id: 83142916,
        slug: 'zeever/zeever.php',
        name: 'Zeever',
        version: '1.6.15',
        update: {
          version: '2.3.1',
          released_at: '2021-09-26 16:40:02',
          update_id: 83004,
        },
        vulnerabilities: [],
      },
    ],
    themes: [],
    core: {
      version: '5.5.1',
      update: {
        version: '6.0.1',
      },
      vulnerabilities: [],
    },
    autoupdater_active: true,
    autoupdater_enabled: true,
    autoupdate_frequency: 'daily',
    autoupdate_at: 0,
    autoupdater_activated_at: '2022-09-28T01:00:00.000Z',
    extensions_sync_time: '2023-06-23T01:00:00.000Z',
    autoupdate_days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    update_themes: true,
    update_plugins: true,
    autoupdate_scheduled_at: '2022-09-16T06:00:00.000Z',
    lastRun: {
      environmentId: 102,
      id: 102,
      state: 'completed',
      started_at: '2022-10-19T18:06:27.000Z',
      finished_at: '2022-10-19T18:07:06.000Z',
      finish_reason: 'update_passed_and_rolled_back',
      finish_message: 'no updates',
    },
  },
];

export default rawSpmEnvironments;
