import { rest } from 'msw';
import { ENDPOINTS, MSW_PARAMS } from 'componentsDir/AllSites/constants';
import { hasPageUrlQueryParam } from 'mocksDir/utils/browserUtils';
import responses from './data/editSite';

const handlers = [
  rest.patch(`*${ENDPOINTS.SITE_EDIT_NAME}*`, (req, res, ctx) => {
    if (hasPageUrlQueryParam(MSW_PARAMS.SITE_EXISTS)) {
      return res(ctx.status(400));
    }

    return res(ctx.status(200), ctx.json(responses.success));
  }),
];

export default handlers;
