import { rest } from 'msw';
import { install_advanced_update_path } from 'railsRoutes';
import { hasPageUrlQueryParam } from 'mocksDir/utils/browserUtils';

export const MSW_PARAMS = {
  INSTALL_ADVANCED_UPDATE_PATH_ERROR: 'install_advanced_update_path_error',
};

const handlers = [
  rest.patch(install_advanced_update_path(':installName'), (_req, res, ctx) => {
    if (hasPageUrlQueryParam(MSW_PARAMS.INSTALL_ADVANCED_UPDATE_PATH_ERROR)) {
      return res(ctx.status(500));
    }

    return res(ctx.status(200));
  }),
];

export default handlers;
