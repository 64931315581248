import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const WarningIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="warning-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.94134 1.82634C10.5398 1.38769 11.2567 1 12 1C12.7433 1 13.4602 1.38769 14.0587 1.82634C14.6893 2.28857 15.3462 2.92608 15.9957 3.66023C17.2978 5.13221 18.6603 7.09604 19.8274 9.12165C20.9927 11.1443 21.9924 13.279 22.5416 15.1C22.815 16.0067 22.9919 16.8843 22.9997 17.6557C23.0073 18.4027 22.8568 19.2337 22.267 19.8263C21.807 20.2885 21.1576 20.6245 20.4726 20.88C19.7703 21.1419 18.9431 21.3507 18.0497 21.5139C16.2618 21.8406 14.1215 22 12 22C9.87852 22 7.73823 21.8406 5.95027 21.5139C5.05691 21.3507 4.22964 21.1419 3.52738 20.88C2.84242 20.6245 2.19299 20.2885 1.73296 19.8263C1.14318 19.2337 0.992684 18.4027 1.00027 17.6557C1.0081 16.8843 1.18495 16.0067 1.45838 15.1C2.00755 13.279 3.00728 11.1443 4.17264 9.12165C5.33973 7.09604 6.70218 5.13221 8.00432 3.66023C8.65376 2.92608 9.31069 2.28857 9.94134 1.82634ZM3.00017 17.676C2.99476 18.2079 3.11699 18.3817 3.15057 18.4155C3.3139 18.5796 3.65147 18.7917 4.22622 19.006C4.78367 19.2139 5.48884 19.3965 6.30972 19.5465C7.95025 19.8462 9.96571 20 12 20C14.0343 20 16.0497 19.8462 17.6903 19.5465C18.5112 19.3965 19.2163 19.2139 19.7738 19.006C20.3485 18.7917 20.6861 18.5796 20.8494 18.4155C20.883 18.3817 21.0052 18.2079 20.9998 17.676C20.9947 17.1686 20.8728 16.4933 20.6268 15.6775C20.137 14.0534 19.2147 12.0645 18.0944 10.1201C16.9758 8.17869 15.689 6.33208 14.4977 4.98538C13.9004 4.31018 13.3496 3.78629 12.8763 3.43946C12.371 3.06904 12.0879 3 12 3C11.9121 3 11.629 3.06904 11.1236 3.43946C10.6504 3.78629 10.0996 4.31018 9.50231 4.98538C8.311 6.33208 7.02416 8.17869 5.90558 10.1201C4.78528 12.0645 3.86297 14.0534 3.3732 15.6775C3.12717 16.4933 3.00532 17.1686 3.00017 17.676Z"
        />
        <path d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 7C12.5523 7 13 7.44772 13 8V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V8C11 7.44772 11.4477 7 12 7Z"
        />
      </SvgTemplate>
    );
  }
);
export default WarningIcon;
