import { rest } from 'msw';
import { ENDPOINTS } from 'componentsDir/AllSites/constants';
import { hasPageUrlQueryParam } from 'mocksDir/utils/browserUtils';

const handlers = [
  rest.post(`*${ENDPOINTS.SITE_DELETE}*`, (req, res, ctx) => {
    if (hasPageUrlQueryParam('deleteSiteError')) {
      return res(ctx.status(500));
    }
    return res(ctx.status(204));
  }),
];

export default handlers;
