import { Drawer as MuiDrawer, styled } from '@mui/material';
import { topNavHeight } from 'design-tokens';

const TopNavDrawer = styled(MuiDrawer)(() => ({
  '& .MuiDrawer-paper': {
    top: topNavHeight,
  },
}));

TopNavDrawer.defaultProps = {
  disableScrollLock: true,
};

export default TopNavDrawer;
