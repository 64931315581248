export interface IItemInteractionProps {
  onClick: (evt: React.SyntheticEvent) => void;
  onKeyPress: (event: React.KeyboardEvent) => void;
  onMouseDown: (evt: React.MouseEvent) => void;
  tabIndex: number;
  title?: string;
}

const ItemInteractionProps = (
  onAction: (evt: React.SyntheticEvent) => void,
  title: string = null
): IItemInteractionProps => {
  const onKeyPressedAction = (evt: React.KeyboardEvent): void => {
    if (evt.key === 'Enter') {
      onAction(evt);
    }
  };

  const onMouseDown = (evt: React.MouseEvent): void => {
    evt.preventDefault();
  };

  return {
    onClick: onAction,
    onKeyPress: onKeyPressedAction,
    onMouseDown,
    tabIndex: 0,
    title,
  };
};

export default ItemInteractionProps;
