import { SiteFilterData } from 'componentsDir/AllSites/types';

interface Responses {
  [key: string]: SiteFilterData;
}

const responses: Responses = {
  allFilters: {
    environments: [
      {
        id: 'production',
        name: 'production',
        count: 1,
      },
      {
        id: 'staging',
        name: 'staging',
        count: 2,
      },
      {
        id: 'development',
        name: 'development',
        count: 3,
      },
    ],
    accounts: [
      {
        id: '1',
        name: 'account0',
        count: 2,
      },
      {
        id: '2',
        name: 'account1',
        count: 5,
      },
      {
        id: '3',
        name: 'account2',
        count: 1,
      },
      {
        id: '4',
        name: 'account3',
        count: 1,
      },
      {
        id: '5',
        name: 'account4',
        count: 1,
      },
      {
        id: '6',
        name: 'account5',
        count: 1,
      },
      {
        id: '7',
        name: 'account6',
        count: 1,
      },
      {
        id: '8',
        name: 'account7',
        count: 1,
      },
      {
        id: '9',
        name: 'account8',
        count: 1,
      },
      {
        id: '10',
        name: 'account9',
        count: 1,
      },
      {
        id: '11',
        name: 'account10',
        count: 1,
      },
    ],
    tags: [],
  },
  lessThanElevenAccountFilters: {
    environments: [
      {
        id: 'production',
        name: 'production',
        count: 1,
      },
      {
        id: 'staging',
        name: 'staging',
        count: 2,
      },
      {
        id: 'development',
        name: 'development',
        count: 3,
      },
    ],
    accounts: [
      {
        id: '1',
        name: 'lessthanelevenaccount1',
        count: 2,
      },
      {
        id: '2',
        name: 'lessthanelevenaccount2',
        count: 5,
      },
      {
        id: '3',
        name: 'lessthanelevenaccount3',
        count: 1,
      },
      {
        id: '4',
        name: 'lessthanelevenaccount4',
        count: 1,
      },
    ],
    tags: [],
  },
  singleAccountFilters: {
    environments: [
      {
        id: 'production',
        name: 'production',
        count: 1,
      },
      {
        id: 'staging',
        name: 'staging',
        count: 2,
      },
      {
        id: 'development',
        name: 'development',
        count: 3,
      },
    ],
    accounts: [
      {
        id: '1',
        name: 'singleAccount',
        count: 2,
      },
    ],
    tags: [],
  },
  preSelectedDevAndAccount: {
    accounts: [
      {
        name: 'account0',
        count: 3,
        id: '0',
        checked: true,
      },
      {
        name: 'account1',
        count: 3,
        id: '1',
      },
    ],
    environments: [
      { name: 'development', count: 1, id: 'development', checked: true },
      { name: 'production', count: 1, id: 'production' },
      { name: 'staging', count: 1, id: 'staging' },
    ],
    tags: [],
  },
  preSelectedDevTagAndAccount: {
    accounts: [
      {
        name: 'account0',
        count: 3,
        id: '0',
        checked: true,
      },
      {
        name: 'account1',
        count: 3,
        id: '1',
      },
    ],
    environments: [
      { name: 'development', count: 1, id: 'development', checked: true },
      { name: 'production', count: 1, id: 'production' },
      { name: 'staging', count: 1, id: 'staging' },
    ],
    tags: [
      {
        name: 'tag0',
        count: 1,
        id: '0',
        checked: true,
      },
      {
        name: 'tag1',
        count: 1,
        id: '1',
      },
    ],
  },
};

export default responses;
