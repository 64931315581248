import { SxProps } from '@mui/material';
import { borderRadiusLarge } from 'design-tokens';

export interface EnvironmentLabelProps {
  environment: 'development' | 'staging' | 'production';
  useFullLabel?: boolean;
  sx?: SxProps;
  className?: string;
  component?: 'button' | 'div' | 'span';
  onClick?: () => void;
  testid?: string;
}

export const sharedStyles = (useFullLabel: boolean) => ({
  alignItems: 'center',
  border: 'none',
  borderRadius: borderRadiusLarge,
  display: 'flex',
  fontSize: '0.875rem',
  fontWeight: 600,
  height: '28px',
  lineHeight: '24px',
  justifyContent: 'center',
  padding: `${useFullLabel ? '0px 16px' : '4px 8px'}`,
  width: `${useFullLabel ? 'auto' : '53px'}`,
});
