export default [
  {
    label: 'Site Name 1',
    className: 'selector-site-name',
    options: [
      {
        label: 'stgenv1',
        value: {
          site: 'ebc0c9d4-71a5-4f5f-b414-6da5b487b3c8',
          site_env: 'staging',
        },
        disabled: true,
      },
      {
        label: 'Available - PROD - unique identifier',
        value: {
          site: 'ebc0c9d4-71a5-4f5f-b414-6da5b487b3c8',
          site_env: 'production',
        },
        disabled: false,
      },
      {
        label: 'Available - DEV',
        value: {
          site: 'ebc0c9d4-71a5-4f5f-b414-6da5b487b3c8',
          site_env: 'development',
        },
        disabled: false,
      },
    ],
  },
  {
    label: 'Site Name 2',
    className: 'selector-site-name',
    options: [
      {
        label: 'Available - PROD',
        value: {
          site: '482bc127-2717-41ba-8f6c-6edeb7ad95df',
          site_env: 'production',
        },
        disabled: false,
      },
      {
        label: 'Available - STAGE',
        value: {
          site: '482bc127-2717-41ba-8f6c-6edeb7ad95df',
          site_env: 'staging',
        },
        disabled: false,
      },
      {
        label: 'Available - DEV',
        value: {
          site: '482bc127-2717-41ba-8f6c-6edeb7ad95df',
          site_env: 'development',
        },
        disabled: false,
      },
    ],
  },
];
