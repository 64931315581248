import * as React from 'react';
import { colorWhite, teal400, teal900, teal1100 } from 'design-tokens';
import { Box } from '../layout';
import { EnvironmentLabelProps, sharedStyles } from './shared';
import DevelopmentEnvironmentLabel from './DevelopmentEnvironmentLabel';

export const EnvironmentLabelDefinitions = {
  development: {
    label: 'Dev',
    fullLabel: 'Development',
    bgColor: colorWhite,
    textColor: teal1100,
  },
  staging: {
    label: 'Stg',
    fullLabel: 'Staging',
    bgColor: teal400,
    textColor: teal1100,
  },
  production: {
    label: 'Prd',
    fullLabel: 'Production',
    bgColor: teal900,
    textColor: colorWhite,
  },
};

const EnvironmentLabel = ({
  environment,
  sx = {},
  className,
  component = 'div',
  testid,
  onClick = () => null,
  useFullLabel = false,
  ...props
}: EnvironmentLabelProps) => {
  const envTypes = EnvironmentLabelDefinitions[environment];

  if (environment === 'development') {
    return (
      <DevelopmentEnvironmentLabel
        className={className}
        component={component}
        testid={testid}
        useFullLabel={useFullLabel}
        onClick={onClick}
        sx={sx}
        {...props}
      />
    );
  }

  return envTypes ? (
    <Box
      className={className}
      component={component}
      data-testid={testid}
      onClick={onClick}
      {...props}
      sx={{
        ...sx,
        ...sharedStyles(useFullLabel),
        backgroundColor: envTypes.bgColor,
        color: envTypes.textColor,
      }}
    >
      {useFullLabel ? envTypes.fullLabel : envTypes.label}
    </Box>
  ) : null;
};

export default EnvironmentLabel;
