import { rest } from 'msw';
import ssoHandlers from 'componentsDir/singleSignOn/mocks/handlers';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { transformObject } from 'componentsDir/products/smart_plugin_manager/utils/functions';
import { toSnakeCase } from 'componentsDir/products/smart_plugin_manager/utils/string';
import mockDataStore from './store';
import mockHeadlessEnvironments from './data/mockHeadlessEnvironments';
import mockWpengineStatus from './data/mockWpengineStatus';
import { mockGooglePhpLogs, mockYahooPhpLogs } from './data/mockPhpLogs';
import { mockGoogleNginxLogs, mockYahooNginxLogs } from './data/mockNginxLogs';
import { mockGoogleErrorLogs, mockYahooErrorLogs } from './data/mockErrorLogs';

// simulate a decent delay for dev, but don't slow tests down
const LOG_FETCH_DELAY = process.env.NODE_ENV === 'test' ? 10 : 3_000;

const handlers = [
  ...ssoHandlers,

  // GET /uptime-monitoring-agent/v1/monitors
  rest.get(`*/uptime-monitoring-agent/v1/monitors`, (req, res, ctx) => {
    const accountWpeId: number = Number(
      req.url.searchParams.get('accountWpeId')
    );

    mockDataStore.logRequest(req);
    const monitorIds: string[] = req?.url?.searchParams
      ?.get('monitorIds')
      ?.split(',');
    const monitorsResponse = monitorIds
      ? mockDataStore.getMonitors(accountWpeId, monitorIds)
      : mockDataStore.getMonitors(accountWpeId);
    return customResponse(ctx.status(200), ctx.json(monitorsResponse));
  }),

  // GET /uptime-monitoring-agent/v1/monitors/results
  rest.get(`*/uptime-monitoring-agent/v1/monitors/results`, (req, res, ctx) => {
    mockDataStore.logRequest(req);
    const monitorsResponse = mockDataStore.getMonitorsData();
    return customResponse(ctx.status(200), ctx.json(monitorsResponse));
  }),

  // GET /v1/installs/:installUuid/domains
  rest.get(`*/v1/installs/:installUuid/domains`, (req, res, ctx) => {
    mockDataStore.logRequest(req);
    const domainsResponse: any = {
      count: mockDataStore.domains.length,
      next: null,
      previous: null,
      results: mockDataStore.domains,
    };
    return customResponse(
      ctx.status(200),

      ctx.json(transformObject(domainsResponse, toSnakeCase))
    );
  }),

  // GET /v1/installs/:installUuid/domains
  rest.get(`*/v1/accounts/:accountId/apps/-/environments`, (req, res, ctx) => {
    mockDataStore.logRequest(req);
    // const { accountId } = req.params
    const environmentsResponse: any = {
      nextPageToken: '',
      environments: mockHeadlessEnvironments,
    };
    return customResponse(
      ctx.status(200),

      ctx.json(environmentsResponse)
    );
  }),

  // GET /wpengine_status
  rest.get(`*/wpengine_status`, (req, res, ctx) => {
    mockDataStore.logRequest(req);
    return res(ctx.status(200), ctx.json(mockWpengineStatus));
  }),

  // GET /incidents
  rest.get(`*/incidents`, (req, res, ctx) => {
    mockDataStore.logRequest(req);
    const { searchParams } = req.url;
    const accountWpeIdsRaw = searchParams.get('accountWpeId');
    const accountWpeIds: number[] | null = accountWpeIdsRaw
      ?.split(',')
      ?.map(Number);
    const offset = Number(searchParams.get('offset'));
    const limit = Number(searchParams.get('limit'));
    const query = searchParams.get('query');
    const startTime = Number(searchParams.get('startTime'));
    const sortBy = searchParams.get('sortBy');
    const { incidents, total } = mockDataStore.getIncidents({
      offset,
      limit,
      query,
      startTime,
      sortBy,
      accountWpeIds,
    });

    const offsetParam = offset ? `offset=${offset + limit}` : '';
    const limitParam = limit ? `limit=${limit}` : limit;
    const queryParam = query ? `query=${query}` : query;
    const startTimeParam = startTime ? `startTime=${startTime}` : '';
    const sortByParam = sortBy ? `sortBy=${sortBy}` : '';
    const params = [
      offsetParam,
      limitParam,
      queryParam,
      startTimeParam,
      sortByParam,
    ]
      .filter(x => x)
      .join('&');
    const next = (offset + 1) * limit <= total ? `/incidents?${params}` : '';

    return res(
      ctx.status(200),
      ctx.json(incidents),
      ctx.set('total-count', String(total)),
      ctx.set('next', next)
    );
  }),

  // GET /installs/:installName/site_logs_feed_data
  rest.get(`*/installs/:installName/site_logs_feed_data`, (req, res, ctx) => {
    mockDataStore.logRequest(req);

    const { installName } = req.params;
    const environment = req.url.searchParams.get('environment');

    if (installName === 'google' && environment === 'php') {
      return res(
        ctx.status(200),
        ctx.delay(LOG_FETCH_DELAY),
        ctx.json(mockGooglePhpLogs)
      );
    }
    if (installName === 'google' && environment === 'nginx') {
      return res(
        ctx.status(200),
        ctx.delay(LOG_FETCH_DELAY),
        ctx.json(mockGoogleNginxLogs)
      );
    }
    if (installName === 'yahoo' && environment === 'php') {
      return res(
        ctx.status(200),
        ctx.delay(LOG_FETCH_DELAY),
        ctx.json(mockYahooPhpLogs)
      );
    }
    if (installName === 'yahoo' && environment === 'nginx') {
      return res(
        ctx.status(200),
        ctx.delay(LOG_FETCH_DELAY),
        ctx.json(mockYahooNginxLogs)
      );
    }

    return res(
      ctx.status(200),
      ctx.delay(LOG_FETCH_DELAY),
      ctx.json({ logs: [] })
    );
  }),

  // GET /installs/:installName/error_logs_feed_data
  rest.get(`*/installs/:installName/error_logs_feed_data`, (req, res, ctx) => {
    mockDataStore.logRequest(req);

    const { installName } = req.params;

    if (installName === 'google') {
      return res(
        ctx.status(200),
        ctx.delay(LOG_FETCH_DELAY),
        ctx.json(mockGoogleErrorLogs)
      );
    }
    if (installName === 'yahoo') {
      return res(
        ctx.status(200),
        ctx.delay(LOG_FETCH_DELAY),
        ctx.json(mockYahooErrorLogs)
      );
    }

    return res(
      ctx.status(200),
      ctx.delay(LOG_FETCH_DELAY),
      ctx.json({ logs: [] })
    );
  }),

  // GET /incidents/stats
  rest.get(`*/incidents/stats`, (req, res, ctx) => {
    mockDataStore.logRequest(req);
    const { searchParams } = req.url;
    const accountWpeIdsRaw = searchParams.get('accountWpeId');
    const accountWpeIds: number[] | null = accountWpeIdsRaw
      ?.split(',')
      ?.map(Number);

    return res(
      ctx.status(200),
      ctx.json(mockDataStore.getIncidentStats(accountWpeIds))
    );
  }),
];

export default handlers;
