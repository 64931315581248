import * as React from 'react';
import { Stack, Tooltip } from '@mui/material';
import {
  yellow100,
  yellow300,
  teal100,
  teal300,
  teal900,
  orange900,
  topNavHeight,
} from 'design-tokens';
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Link,
  Typography,
  UnicornProvider,
} from '@wpengine/unicorn/components';
import { AnnouncementIcon, ClockIcon } from '@wpengine/unicorn/icons';
import useWindowWidth from 'commonsDir/hooks/useWindowWidth';
import AccountUsersDropdown, { AccountUser } from './AccountUsersDropdown';

export interface ImpersonationBannerProps {
  userPersona: boolean;
  impersonatingUserName: string;
  impersonatingUserEmail: string;
  impersonatingAccount?: string;
  overdriveAccountPath?: string;
  impersonatingInstall?: string;
  overdriveInstallPath?: string;
  unimpersonatePath: string;
  accountUsers?: Array<AccountUser>;
}

const ImpersonationBanner = (props: ImpersonationBannerProps) => {
  const {
    impersonatingUserName,
    impersonatingUserEmail,
    impersonatingAccount,
    overdriveAccountPath,
    impersonatingInstall,
    overdriveInstallPath,
    unimpersonatePath,
    userPersona,
    accountUsers,
  } = props;

  const ref = React.useRef(null);
  const { windowWidth } = useWindowWidth();
  const [open, setOpen] = React.useState(true);
  const [hovered, setHovered] = React.useState(false);
  const [marginTop, setMarginTop] = React.useState('0');

  React.useEffect(() => {
    const bannerHeight = ref.current?.offsetHeight || 98;

    if (hovered) {
      setMarginTop(`-${bannerHeight - 20}px`);
    } else if (open) {
      setMarginTop('0');
    } else {
      setMarginTop(`-${bannerHeight - 10}px`);
    }
  }, [windowWidth, open, hovered]);

  const styles = {
    backgroundColor: userPersona ? yellow100 : teal100,
    borderColor: userPersona ? yellow300 : teal300,
    color: userPersona ? orange900 : teal900,
  };

  return (
    <UnicornProvider>
      <Box
        ref={ref}
        role="banner"
        display="flex"
        sx={{
          backgroundColor: styles.backgroundColor,
          boxShadow: `inset 0 -8px ${styles.borderColor}`,
          border: `solid 1px ${styles.borderColor}`,
          color: styles.color,
          cursor: hovered ? 'pointer' : 'default',
          fontSize: '0.875rem',
          marginTop,
          minHeight: topNavHeight,
          transition: 'margin-top 0.3s ease',
          '.MuiLink-root, .MuiButtonBase-root': {
            color: styles.color,
            textDecorationColor: styles.color,
            '&:hover, &:focus, &:active, &:focus-visible': {
              color: `${styles.color} !important`,
              textDecorationColor: `${styles.color} !important`,
              borderColor: 'inherit',
            },
          },
        }}
        id="impersonation-notice"
        onClick={() => {
          if (!open) {
            setHovered(false);
            setOpen(true);
          }
        }}
        onMouseEnter={() => {
          if (!open) setHovered(true);
        }}
        onMouseLeave={() => {
          if (!open) setHovered(false);
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '0.5rem',
            minWidth: 88,
          }}
        >
          <AnnouncementIcon />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            gap: '0.5rem',
            margin: '1rem 0 1.5rem',
          }}
        >
          <Box>
            <Typography display="inline" variant="bodyBold">
              Impersonating user:{` `}
            </Typography>
            <Typography display="inline">
              {impersonatingUserName} ({impersonatingUserEmail})
            </Typography>
          </Box>
          <Stack
            columnGap={2}
            direction="row"
            flexWrap="wrap"
            rowGap={1}
            useFlexGap
          >
            <div>
              <Typography display="inline" variant="bodyBold">
                Associated account:{` `}
              </Typography>
              {overdriveAccountPath ? (
                <Link href={overdriveAccountPath} isExternal target="_blank">
                  {impersonatingAccount}
                </Link>
              ) : (
                <Typography display="inline">
                  {impersonatingAccount || 'None'}
                </Typography>
              )}
            </div>
            <div>
              <Typography display="inline" variant="bodyBold">
                Associated install:{` `}
              </Typography>
              {overdriveInstallPath ? (
                <Link href={overdriveInstallPath} isExternal target="_blank">
                  {impersonatingInstall}
                </Link>
              ) : (
                <Typography display="inline">
                  {impersonatingInstall || 'None'}
                </Typography>
              )}
            </div>
          </Stack>
          {userPersona && (
            <Typography>
              You are impersonating a User Persona! Please don&apos;t modify
              this account or user.
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            margin: '1rem 3rem 1.5rem 1rem',
          }}
        >
          <ButtonGroup>
            <Tooltip title="Hide impersonation banner for 10 seconds.">
              <IconButton
                onClick={() => {
                  setOpen(false);
                  setTimeout(() => {
                    setHovered(false);
                    setOpen(true);
                  }, 10000);
                }}
              >
                <ClockIcon />
              </IconButton>
            </Tooltip>
            <AccountUsersDropdown accountUsers={accountUsers} />
            <Button
              variant="outlined"
              href={unimpersonatePath}
              data-method="post"
              size="small"
              sx={{
                borderColor: styles.color,
              }}
            >
              Stop impersonating
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </UnicornProvider>
  );
};

export default ImpersonationBanner;
