import React, { lazy, Suspense } from 'react';
import { useTopNavContext } from 'componentsDir/TopNav/contexts/TopNavProvider';
import NotificationsPanelBell from './NotificationsPanelBell';

const SuspenseHoc = (WrappedComponent: any, fallback: any = null) => (
  props: any
) => (
  <Suspense fallback={fallback}>
    <WrappedComponent {...props} />
  </Suspense>
);

const NotificationsPanelLazyLoad = lazy(() =>
  import(
    /* webpackChunkName: "notifications-panel" */ 'componentsDir/Notifications/NotificationsPanel'
  )
);

const FallbackBellComponent = () => {
  return (
    <NotificationsPanelBell
      handleClick={() => {}}
      open={false}
      hasNewNotifications={false}
    />
  );
};

const NotificationsPanel = SuspenseHoc(
  NotificationsPanelLazyLoad,
  <FallbackBellComponent />
);

// Notifications Panel imports a component that uses settings, and components that use settings need to be lazy loaded
// Otherwise, there is a race condition.

const NotificationsPanelLazy: React.FC = () => {
  const {
    notificationsEnabled,
    hasNotificationsDeveloperAccess,
  } = useTopNavContext();

  if (notificationsEnabled || hasNotificationsDeveloperAccess) {
    return <NotificationsPanel />;
  }

  return null;
};

export default NotificationsPanelLazy;
