import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const Local: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="Local logo" viewBox="0 0 128 128" {...props}>
      <path
        d="M28.48 3.52055L3.52 28.4845C1.26 30.7448 0 33.7853 0 36.9658V121.999C0 125.32 2.68 128 6 128H122C125.32 128 128 125.32 128 121.999V6.00094C128 2.68042 125.32 0 122 0H36.98C33.8 0 30.74 1.2602 28.5 3.52055H28.48Z"
        fill="url(#paint0_radial_131_7927)"
      />
      <g filter="url(#filter0_d_131_7927)">
        <path
          d="M61.9227 40.4641V27.2779C61.9227 26.3519 61.3733 25.5309 60.5152 25.1729C60.2374 25.0556 59.9472 25 59.6447 25C59.0336 25 58.4657 25.2346 58.0335 25.6667L28.6672 55.0392C22.4878 61.2742 22.4446 67.5215 28.5376 73.6146L58.0397 103.117C58.4718 103.549 59.0398 103.783 59.6509 103.783C59.9534 103.783 60.2436 103.728 60.5214 103.611C61.3733 103.259 61.9289 102.431 61.9289 101.505V88.1835C61.9289 87.5847 61.6881 86.9982 61.2621 86.5723L39.0075 64.3238L61.256 42.0691C61.6881 41.637 61.9227 41.069 61.9227 40.4579V40.4641ZM57.373 96.0112L35.8097 74.448C35.7356 74.343 35.6492 74.2443 35.5628 74.1516C33.7293 72.3182 33.8219 70.1699 35.8591 67.608L57.373 89.1219V96.0112ZM57.373 32.7722V39.5196L33.8404 63.0521C32.0317 64.8609 30.8032 66.719 30.1797 68.5957C27.5807 65.114 28.1116 62.0644 31.8959 58.2493L57.373 32.7722Z"
          fill="white"
        />
        <path
          d="M99.4624 55.1688L69.9603 25.6667C69.5282 25.2346 68.9602 25 68.3491 25C68.0466 25 67.7564 25.0556 67.4786 25.1729C66.6267 25.5247 66.0711 26.3519 66.0711 27.2779V40.5999C66.0711 41.2049 66.3057 41.779 66.7379 42.2111L88.9864 64.4596L66.7379 86.7081C66.3119 87.1341 66.0711 87.7205 66.0711 88.3193V101.505C66.0711 102.431 66.6206 103.252 67.4786 103.611C67.7564 103.728 68.0466 103.783 68.3491 103.783C68.9602 103.783 69.5282 103.549 69.9603 103.117L99.3328 73.7442C105.512 67.5092 105.555 61.2618 99.4624 55.1688ZM70.627 32.7722L92.1903 54.3354C92.2644 54.4404 92.3508 54.5391 92.4372 54.6317C93.6225 55.817 93.9867 56.9838 93.6101 58.4036C93.3817 59.2802 92.8817 60.2062 92.1347 61.1754L70.627 39.6677V32.7722ZM96.1041 70.5341L70.627 96.0112V89.2638L94.1596 65.7313C95.4251 64.4658 97.0363 62.515 97.8265 60.1939C100.425 63.6694 99.8945 66.7252 96.1103 70.5403L96.1041 70.5341Z"
          fill="white"
        />
        <path
          d="M76.498 62.1137H51.5023C50.2492 62.1137 49.2244 63.1323 49.2244 64.3917C49.2244 65.651 50.243 66.6696 51.5023 66.6696H76.498C77.7511 66.6696 78.7759 65.651 78.7759 64.3917C78.7759 63.1323 77.7573 62.1137 76.498 62.1137Z"
          fill="white"
        />
        <path
          d="M66.4602 52.076H61.5401C60.2869 52.076 59.2622 53.0946 59.2622 54.3539C59.2622 55.6133 60.2808 56.6318 61.5401 56.6318H66.4602C67.7134 56.6318 68.7382 55.6133 68.7382 54.3539C68.7382 53.0946 67.7196 52.076 66.4602 52.076Z"
          fill="white"
        />
        <path
          d="M66.4602 72.1515H61.5401C60.2869 72.1515 59.2622 73.1701 59.2622 74.4294C59.2622 75.6888 60.2808 76.7074 61.5401 76.7074H66.4602C67.7134 76.7074 68.7382 75.6888 68.7382 74.4294C68.7382 73.1701 67.7196 72.1515 66.4602 72.1515Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_131_7927"
          x="16"
          y="17"
          width="96"
          height="94.7834"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_131_7927"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_131_7927"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_131_7927"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(181.019)"
        >
          <stop stopColor="#0ECAD4" />
          <stop offset="1" stopColor="#039B5C" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default Local;
