import { Site, SiteTag } from 'componentsDir/ManageSiteTagsPage/types';
import generateRandomWpeId from 'componentsDir/ManageSiteTagsPage/utils/generateRandomWpeId';
import { defaults } from 'lodash';

export const generateSitesData = (props?: {
  numberOfSites?: number;
}): Site[] => {
  const { numberOfSites } = defaults(props, {
    numberOfSites: 3,
  });

  if (numberOfSites <= 0) {
    return [];
  }

  return Array.from(
    { length: numberOfSites },
    (_, index): Site => {
      return {
        uuid: `${index}-site`,
        name: `site${index}.com`,
        account: {
          wpe_id: generateRandomWpeId(),
        },
      };
    }
  );
};

const generateAlphabets = () => {
  const alphabets: string[] = [];
  for (let i = 0; i < 26; i += 1) {
    alphabets.push(String.fromCharCode(65 + i));
  }
  return alphabets;
};

export const generateSitesAlphabetically = () => {
  return generateAlphabets().map((siteAlphabet, siteIndex) => {
    return {
      uuid: `${siteIndex}`,
      name: `${siteAlphabet}.com`,
      account: {
        wpe_id: generateRandomWpeId(),
      },
    };
  });
};

export const generateSitesTagsDataAlphabetically = () => {
  return generateAlphabets().map((alphabet, index) => {
    return {
      id: index,
      name: `${alphabet}-tag`,
      sites: generateSitesAlphabetically(),
    };
  });
};

export const generateSiteTagsData = (props?: {
  numberOfTags: number;
  numberOfSitesPerTag: number;
  randomizeNumberOfSites: boolean;
}): SiteTag[] => {
  const {
    numberOfTags,
    numberOfSitesPerTag,
    randomizeNumberOfSites,
  } = defaults(props, {
    numberOfTags: 3,
    numberOfSitesPerTag: 3,
    randomizeNumberOfSites: false,
  });

  if (numberOfTags <= 0 || numberOfSitesPerTag <= 0) {
    return [];
  }

  const getRandomNumberOfSites = () =>
    Math.floor(Math.random() * numberOfSitesPerTag) + 1;

  return Array.from(
    { length: numberOfTags },
    (_, index): SiteTag => {
      const sitesCount = randomizeNumberOfSites
        ? getRandomNumberOfSites()
        : numberOfSitesPerTag + index;

      return {
        id: index,
        name: `tag-${index}`,
        sites: Array.from(
          { length: sitesCount },
          (_a, siteIndex): Site => {
            return {
              uuid: `${index}-${siteIndex}`,
              name: `site${index}${siteIndex}.com`,
              account: {
                wpe_id: generateRandomWpeId(),
              },
            };
          }
        ),
      };
    }
  );
};
