import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const BetterSearchReplace: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate
      name="BetterSearchReplace logo"
      viewBox="0 0 128 128"
      {...props}
    >
      <path
        d="M28.48 3.52055L3.52 28.4845C1.26 30.7448 0 33.7853 0 36.9658V121.999C0 125.32 2.68 128 6 128H122C125.32 128 128 125.32 128 121.999V6.00094C128 2.68042 125.32 0 122 0H36.98C33.8 0 30.74 1.2602 28.5 3.52055H28.48Z"
        fill="url(#paint0_radial_159_10272)"
      />
      <g filter="url(#filter0_d_159_10272)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.01 57.0443H73.5605C73.3221 54.3675 71.5156 52.6855 68.234 52.4326H66.4206C63.2061 52.6736 61.5974 54.2008 61.5791 56.2469C61.4994 58.6791 63.8518 59.9151 67.5598 60.7125L71.0685 61.5099C79.1225 63.2842 83.5283 67.2713 83.5682 73.7903C83.5447 78.3538 81.4502 81.8265 77.6585 83.912H86.3783V70.9353H91.0803L98.0037 83.912H108L100.133 69.4729C104.298 67.5847 106.704 63.8638 106.704 58.625C106.704 50.8316 101.391 46 92.8759 46H84.8172H77.215H75.9028C80.3485 48.1287 82.9579 52.0598 83.01 57.0443ZM52.2183 52.4326H52.2171C50.83 48.4918 46.6429 46 39.809 46H24V83.912H41.0123C46.2039 83.912 50.0262 82.195 52.1847 79.433C51.0031 77.4288 50.3521 74.9672 50.3154 72.036H59.7649C59.9842 75.9633 62.855 77.9967 67.2209 77.9967C71.1881 77.9967 73.5206 76.2623 73.5605 73.7704C73.5206 71.4778 71.5071 70.2617 67.0215 69.225L62.7553 68.2282C55.6981 66.6135 51.3521 63.1247 51.372 56.9247C51.3593 55.3048 51.6548 53.7991 52.2183 52.4326ZM33.1633 76.5258V67.4921H38.9945C42.4932 67.4921 44.6406 69.3433 44.6406 72.2496C44.6406 74.9338 42.7894 76.5258 38.8279 76.5258H33.1633ZM33.1633 61.5869V53.2751H38.3836C41.438 53.2751 43.3818 54.8486 43.3818 57.3662C43.3818 60.0319 41.2344 61.5869 38.2355 61.5869H33.1633ZM86.3783 53.3862V63.7157H90.8952C95.0048 63.7157 97.2077 62.1422 97.2077 58.625C97.2077 55.1078 95.0048 53.3862 90.8952 53.3862H86.3783Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_159_10272"
          x="16"
          y="38"
          width="100"
          height="53.9121"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_10272"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_159_10272"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_159_10272"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(181.019)"
        >
          <stop stopColor="#0ECAD4" />
          <stop offset="1" stopColor="#006BD6" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default BetterSearchReplace;
