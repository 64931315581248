import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '@wpengine/unicorn/icons/utils/SvgTemplate';
import { darkModeManualInvertClass } from '@wpengine/unicorn/components/DarkMode';

const Green = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    <SvgTemplate
      className={darkModeManualInvertClass}
      name="green"
      ref={ref}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <circle cx="10" cy="10" r="10" fill="#C2DBC1" />
        <circle cx="10" cy="10" r="8" fill="#35872F" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3279 6.81571C14.6525 7.1403 14.6525 7.66657 14.3279 7.99116L9.34087 12.9782C9.01628 13.3028 8.49001 13.3028 8.16542 12.9782L5.67191 10.4847C5.34732 10.1601 5.34732 9.63381 5.67191 9.30922C5.9965 8.98462 6.52277 8.98462 6.84736 9.30922L8.75314 11.215L13.1524 6.81571C13.477 6.49112 14.0033 6.49112 14.3279 6.81571Z"
          fill="white"
        />
      </svg>
    </SvgTemplate>
  );
});
export default Green;
