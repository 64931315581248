import { rest } from 'msw';

/* eslint-disable no-console */
const handlers = [
  // Create a single sign on
  rest.post('*/single-sign-ons', async (req, res, ctx) => {
    return res(
      ctx.status(201),
      ctx.json({
        uuid: 'ksuid_goes_here',
        email_domains: ['example.com'],
        acs: 'wpe_acs_uri',
        audience: 'wpe_entity_id',
        relay_state: 'wpe_destination',
        mode: 'configure',
        technology: 'saml',
        testers: [],
        suggested_dns_name: 'txt_record_name',
        suggested_dns_value: 'txt_record_value',
      })
    );
  }),

  // Options request for CORS
  rest.options('*/single-sign-ons', async (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set('Access-Control-Allow-Origin', '*'),
      ctx.set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, HEAD'),
      ctx.set(
        'Access-Control-Allow-Headers',
        'Authorization, Origin, X-Requested-With, Content-Type, Accept'
      )
    );
  }),

  // Put metadata
  rest.put('*/single-sign-ons/*/metadata', async (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        uuid: 'ksuid_goes_here',
        email_domains: ['example.com'],
        metadata: 'xml_metadata_contents_here',
        acs: 'wpe_acs_uri',
        audience: 'wpe_entity_id',
        issuer: 'customer_entity_id',
        sso: 'https://customer.com/acs',
        cert: 'cert',
        cert_description: 'cert_description',
        cert_expiry: '11/19/22',
        relay_state: 'wpe_destination',
        mode: 'configure',
        technology: 'saml',
        testers: [],
        suggested_dns_name: 'txt_record_name',
        suggested_dns_value: 'txt_record_value',
      })
    );
  }),

  // Enable Test Mode
  rest.post('*/single-sign-ons/*/mode/testing', async (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        uuid: 'ksuid_goes_here',
        email_domains: ['example.com'],
        metadata: 'xml_metadata_contents_here',
        acs: 'wpe_acs_uri',
        audience: 'wpe_entity_id',
        issuer: 'customer_entity_id',
        sso: 'https://customer.com/acs',
        cert: 'cert',
        cert_description: 'cert_description',
        cert_expiry: '11/19/22',
        relay_state: 'wpe_destination',
        mode: 'testing',
        technology: 'saml',
        testers: ['user@example.com'],
        possible_shared_emails: ['it@example.com'],
        affected_accounts: {
          your_accounts: [],
          others: 0,
        },
        suggested_dns_name: 'txt_record_name',
        suggested_dns_value: 'txt_record_value',
      })
    );
  }),

  // Enable Test Mode
  rest.post('*/single-sign-ons/*/mode/live', async (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        uuid: 'ksuid_goes_here',
        email_domains: ['example.com'],
        metadata: 'xml_metadata_contents_here',
        acs: 'wpe_acs_uri',
        audience: 'wpe_entity_id',
        issuer: 'customer_entity_id',
        sso: 'https://customer.com/acs',
        cert: 'cert',
        cert_description: 'cert_description',
        cert_expiry: '11/19/22',
        relay_state: 'wpe_destination',
        mode: 'live',
        technology: 'saml',
        testers: ['user@example.com'],
        possible_shared_emails: ['it@example.com'],
        affected_accounts: {
          your_accounts: [],
          others: 0,
        },
        suggested_dns_name: 'txt_record_name',
        suggested_dns_value: 'txt_record_value',
      })
    );
  }),

  /**
   * Okta Token
   */
  rest.post(`*/users/token`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: true,
        data: req.body,
      })
    );
  }),

  /**
   * UserProfile
   */
  rest.patch(`*/users`, (req, res, ctx) => {
    return res(
      ctx.json({
        success: true,
        data: req.body,
      })
    );
  }),
];
export default handlers;
