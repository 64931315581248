import * as React from 'react';
import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

export interface SvgTemplateProps {
  name: string;
}

const SvgTemplate = React.forwardRef<
  SVGSVGElement,
  SvgIconProps & SvgTemplateProps
>((props, ref) => {
  const { name, children, ...other } = props;
  return (
    <SvgIcon aria-label={name} data-testid={name} ref={ref} {...other}>
      {children}
    </SvgIcon>
  );
});
export default SvgTemplate;
