import { rest } from 'msw';

const AccountNicknameHandlers = [
  rest.patch(`/accounts/*/nickname`, (_req, res, ctx) => {
    const pageParams = new URLSearchParams(window.location.search);
    const isError = pageParams.get('error') === 'true';
    if (isError) {
      return res(ctx.status(400));
    }
    return res(ctx.status(200));
  }),
];

export default AccountNicknameHandlers;
