import { generateMockData } from 'componentsDir/TrafficInsights/utils';
import * as responses from 'componentsDir/shared/repositories/SlowQueriesAPIClient/data/responses';
import { StatusCodes } from 'http-status-codes';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';

const handlers = [
  rest.get(`*/v1/slow-queries`, (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({
        slowQueries: [
          {
            uuid: '1033ff2a-20be-4587-a880-92d0d4624774',
            createdAt: '2024-07-01T16:25:18.886987Z',
            queryTime: '6000305',
            lockTime: '0',
            requestUrl: 'sqmsite.wpenginepoweredstaging.com/?wp-cmd=cron',
            assetType: 'plugin',
            assetName: 'Jetpack - WP Security',
            sourceFile: 'wp-content/plugins/example-plugin/example-plugin.php',
            sourceLine: 26,
            rowsAffected: '0',
            rowsExamined: '1',
            rowsSent: '1',
            killed: false,
            bytesSent: '63',
            queryString: 'SELECT SLEEP ( ? ) WHERE ? = ? OR ? = ?;',
          },
          {
            uuid: '46906d5d-7096-4a65-951a-6925c1164a1f',
            createdAt: '2024-06-30T16:25:12.619362Z',
            queryTime: '5000320',
            lockTime: '0',
            requestUrl:
              'sqmsite.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
            assetType: 'theme',
            assetName: 'Twenty Twenty Four',
            sourceFile: 'wp-content/plugins/example-plugin/example-plugin.php',
            sourceLine: 26,
            rowsAffected: '0',
            rowsExamined: '1',
            rowsSent: '1',
            killed: false,
            bytesSent: '63',
            queryString: 'SELECT SLEEP ( ? ) WHERE ? = ? OR ? = ?;',
          },
          {
            uuid: '88886d5d-7096-4a65-951a-6925c1164a1f',
            createdAt: '2024-04-02T16:25:12.619362Z',
            queryTime: '4000320',
            lockTime: '0',
            requestUrl:
              'mysite3.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
            assetType: 'other',
            assetName: 'My Other Plugin',
            sourceFile: 'wp-content/plugins/other-plugin.php',
            sourceLine: 12,
            rowsAffected: '0',
            rowsExamined: '1',
            rowsSent: '1',
            killed: false,
            bytesSent: '63',
            queryString: 'SELECT EXAMPLEOTHER ( ? ) WHERE OTHER = ? OR ? = ?;',
          },
          {
            uuid: 'a1b2c3d4-e5f6-7g8h-9i0j-123456789abc',
            createdAt: '2024-04-03T10:15:30.123456Z',
            queryTime: '5000450',
            lockTime: '0',
            requestUrl:
              'example1.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
            assetType: 'plugin',
            assetName: 'Yoast SEO',
            sourceFile: 'wp-content/plugins/custom-plugin.php',
            sourceLine: 15,
            rowsAffected: '0',
            rowsExamined: '2',
            rowsSent: '2',
            killed: false,
            bytesSent: '120',
            queryString:
              'SELECT PLUGINEXAMPLE ( ? ) WHERE PLUGIN = ? OR ? = ?;',
          },
          {
            uuid: 'b2c3d4e5-f6g7-h8i9-j0k1-234567890def',
            createdAt: '2024-04-04T11:20:35.654321Z',
            queryTime: '6000560',
            lockTime: '0',
            requestUrl:
              'example2.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
            assetType: 'theme',
            assetName: 'Ecommerce Theme',
            sourceFile: 'wp-content/themes/custom-theme/functions.php',
            sourceLine: 22,
            rowsAffected: '1',
            rowsExamined: '3',
            rowsSent: '1',
            killed: false,
            bytesSent: '150',
            queryString: 'SELECT THEMEEXAMPLE ( ? ) WHERE THEME = ? AND ? = ?;',
          },
          {
            uuid: 'c3d4e5f6-g7h8-i9j0-k1l2-345678901ghi',
            createdAt: '2024-04-05T12:25:40.987654Z',
            queryTime: '7000670',
            lockTime: '0',
            requestUrl:
              'example3.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
            assetType: 'other',
            assetName: 'External Service',
            sourceFile: 'wp-content/plugins/external-service/integration.php',
            sourceLine: 29,
            rowsAffected: '2',
            rowsExamined: '4',
            rowsSent: '2',
            killed: false,
            bytesSent: '200',
            queryString:
              'SELECT SERVICEEXAMPLE ( ? ) WHERE SERVICE = ? OR ? = ?;',
          },
          {
            uuid: 'd4e5f6g7-h8i9-j0k1-l2m3-456789012jkl',
            createdAt: '2024-04-06T13:30:45.321098Z',
            queryTime: '8000780',
            lockTime: '0',
            requestUrl:
              'example4.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
            assetType: 'plugin',
            assetName: 'Contact Form 7',
            sourceFile: 'wp-content/plugins/seo-plugin/optimizer.php',
            sourceLine: 33,
            rowsAffected: '0',
            rowsExamined: '5',
            rowsSent: '0',
            killed: false,
            bytesSent: '250',
            queryString: 'SELECT SEOEXAMPLE ( ? ) WHERE SEO = ? AND ? = ?;',
          },
          {
            uuid: 'e5f6g7h8-i9j0-k1l2-m3n4-567890123klm',
            createdAt: '2024-04-07T14:35:50.654987Z',
            queryTime: '9000890',
            lockTime: '0',
            requestUrl:
              'example5.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
            assetType: 'theme',
            assetName: 'Responsive Theme',
            sourceFile: 'wp-content/themes/responsive-theme/style.php',
            sourceLine: 40,
            rowsAffected: '1',
            rowsExamined: '6',
            rowsSent: '1',
            killed: false,
            bytesSent: '300',
            queryString: 'SELECT THEMESTYLE ( ? ) WHERE STYLE = ? OR ? = ?;',
          },
          {
            uuid: 'f6g7h8i9-j0k1-l2m3-n4o5-678901234mno',
            createdAt: '2024-07-08T13:40:55.123789Z',
            queryTime: '10000100',
            lockTime: '0',
            requestUrl:
              'example6.wpenginepoweredstaging.com/wp-cron.php?doing_wp_cron',
            assetType: 'other',
            assetName: '3rd Party API',
            sourceFile: 'wp-content/plugins/third-party-api/connector.php',
            sourceLine: 47,
            rowsAffected: '2',
            rowsExamined: '7',
            rowsSent: '2',
            killed: false,
            bytesSent: '350',
            queryString: 'SELECT APIEXAMPLE ( ? ) WHERE API = ? AND ? = ?;',
          },
        ],
      })
    );
  }),
  rest.get('*/v1/slow-queries/*', (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templateSlowQueryResponse)
    );
  }),
  rest.get('*/v1/insights/*', (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(generateMockData(req))
    );
  }),
  // SQM Aggregation Handlers
  // URL for Slow Query Log
  rest.get('*/sqm_aggregation', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json([
        {
          results: [
            {
              source_name: 'Yoast SEO',
              source_type: 'plugin',
              average_run_time: 123456,
              occurences: 15,
              last_ran: '2024-06-01',
              uuid: '1033ff2a-20be-4587-a880-92d0d4624774',
            },
          ],
        },
      ])
    );
  }),
  // URL for Problematic Sources
  rest.get('*/problematic_sources', (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templateSQMAggProblematicGenericRecResponse)
    );
  }),

  rest.get('*/v1/slow_queries_by_source', (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templateSQMAggBySourceResponse)
    );
  }),

  rest.get('*/v1/slow_query_recommendations', (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templateSQMAggRecommendationsResponse)
    );
  }),
];

export default handlers;
