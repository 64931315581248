import { SiteExtensionsResponse } from '../../../../../sites/mocks/types';
import mockDataStore from '../store';

import paginateMockData from '../../../../../sites/mocks/data/paginateMockData';

const mockSiteExtensions = (
  pageToken: string,
  filter?: string,
  pageSize: number = 10,
  search?: string
): SiteExtensionsResponse => {
  // Return plugins only
  if (filter?.includes('plugin')) {
    const [extensions, next_page_token] = paginateMockData(
      mockDataStore.getPlugins(search),
      pageToken,
      pageSize
    );
    return { extensions, next_page_token };
  }

  // Return themes only
  if (filter?.includes('theme')) {
    const [extensions, next_page_token] = paginateMockData(
      mockDataStore.getThemes(search),
      pageToken,
      pageSize
    );
    return { extensions, next_page_token };
  }

  // By default, return both plugins and themes combined (just like the real API).
  const combinedData = [
    ...mockDataStore.getPlugins(search),
    ...mockDataStore.getThemes(search),
  ];
  const [extensions, next_page_token] = paginateMockData(
    combinedData,
    pageToken,
    pageSize
  );
  return { extensions, next_page_token };
};

export default mockSiteExtensions;
