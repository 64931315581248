import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const ManagedWordPress: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="ManagedWordPress logo" viewBox="0 0 128 128" {...props}>
      <circle cx="64" cy="64" r="64" fill="url(#paint0_radial_119_7053)" />
      <g filter="url(#filter0_d_119_7053)">
        <path
          d="M97.9319 83.3318L89.4116 74.8055C88.73 74.1178 88.3405 73.1927 88.3405 72.2251V55.7749C88.3405 54.8072 88.7239 53.8761 89.4116 53.1945L97.9319 44.6803C99.356 43.2562 99.356 40.9436 97.9319 39.5134L88.4927 30.0742C87.0686 28.6501 84.7559 28.6501 83.3318 30.0742L74.8116 38.5883C74.1239 39.27 73.1988 39.6595 72.2312 39.6595H55.781C54.8133 39.6595 53.8822 39.276 53.2006 38.5883L44.6803 30.0681C43.2562 28.644 40.9436 28.644 39.5134 30.0681L30.0681 39.5134C28.644 40.9375 28.644 43.2501 30.0681 44.6803L38.5883 53.1945C39.276 53.8822 39.6594 54.8072 39.6594 55.7749V72.2251C39.6594 73.1927 39.276 74.1239 38.5883 74.8055L30.0681 83.3258C28.644 84.7499 28.644 87.0625 30.0681 88.4927L39.5073 97.9319C40.9314 99.356 43.244 99.356 44.6742 97.9319L53.1945 89.4117C53.8822 88.724 54.8072 88.3405 55.7749 88.3405H72.2251C73.1927 88.3405 74.1239 88.724 74.8055 89.4117L83.3258 97.9319C84.7499 99.356 87.0625 99.356 88.4866 97.9319L97.9258 88.4927C99.3499 87.0686 99.3499 84.756 97.9258 83.3258L97.9319 83.3318ZM64.003 73.7405C58.6231 73.7405 54.2656 69.383 54.2656 64.003C54.2656 58.6231 58.6292 54.2656 64.003 54.2656C69.3769 54.2656 73.7405 58.6231 73.7405 64.003C73.7405 69.383 69.3769 73.7405 64.003 73.7405Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_119_7053"
          x="21"
          y="21"
          width="86"
          height="86"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_119_7053"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_119_7053"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_119_7053"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(181.019)"
        >
          <stop stopColor="#0ECAD4" />
          <stop offset="1" stopColor="#006BD6" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default ManagedWordPress;
