import { rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { billing_mediator_path, billing_mediator_plan_path } from 'railsRoutes';
import { mockBillingData, mockPlanUpgrade } from '../data/mockBillingData';

const billingDataHandlers = [
  rest.get(billing_mediator_path(), async (req, res, ctx) => {
    return customResponse(ctx.status(201), ctx.json(mockBillingData));
  }),
  rest.get(billing_mediator_plan_path(), async (req, res, ctx) => {
    return customResponse(ctx.status(201), ctx.json(mockPlanUpgrade));
  }),
];
export default billingDataHandlers;
