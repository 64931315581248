import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import {
  mockTopSearches,
  mockTopEnvironments,
} from 'componentsDir/Dashboard/mocks/data';

const handlers = [
  rest.get('/widgets/top-searches', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(mockTopSearches));
  }),

  rest.get('/widgets/top-environments', (req, res, ctx) => {
    if (hasPageUrlQueryParam('topEnvironmentsError')) {
      return res(ctx.status(500));
    }

    if (hasPageUrlQueryParam('topEnvironmentsEmpty')) {
      return res(
        ctx.status(200),
        ctx.json({
          ...mockTopEnvironments,
          data: {
            billable_visits: [],
            storage: [],
          },
        })
      );
    }

    return res(ctx.status(200), ctx.json(mockTopEnvironments));
  }),
];

export default handlers;
