import * as React from 'react';
import { orange700 } from 'design-tokens';
import { admin_dashboard_path } from 'railsRoutes';
import { OwnershipToggle } from 'componentsDir/Ownership';
import { NavItem } from '@wpengine/unicorn/components';
import Divider from './Divider';

const AdminNavItems = () => {
  return (
    <>
      <Divider />
      <NavItem
        href={admin_dashboard_path()}
        size="small"
        sx={{ '&&&&': { color: orange700 } }}
      >
        Admin
      </NavItem>
      <OwnershipToggle />
    </>
  );
};

export default AdminNavItems;
