import { publish } from 'commonsDir/helpers/events';
import useUpdateCart from '../hooks/useUpdateCart';
import { AddonAction } from '../types/types';
import useCart from '../hooks/useCart';

const useAddAddon = () => {
  const { cart } = useCart();
  const { mutate } = useUpdateCart();

  return (addonAction: AddonAction) => {
    publish('OPEN_CART');
    const newAddons = [...cart.selected_addons];
    const updatedAddonIndex = newAddons.findIndex(
      addon => addon.base_sku === addonAction.base_sku
    );

    if (updatedAddonIndex >= 0) {
      return;
    }

    newAddons.push(addonAction);
    mutate({ ...cart, selected_addons: newAddons });
  };
};

export default useAddAddon;
