import { Datacenter } from 'componentsDir/addons/types';

const mockDatacenters: Datacenter[] = [
  {
    name: 'us',
    location_name: 'United States of America',
  },
  {
    name: 'au',
    location_name: 'Australia',
  },
  {
    name: 'be',
    location_name: 'Belgium',
  },
  {
    name: 'ca',
    location_name: 'Canada',
  },
  {
    name: 'de',
    location_name: 'Germany',
  },
  {
    name: 'tw',
    location_name: 'Taiwan',
  },
  {
    name: 'uk',
    location_name: 'United Kingdom of Great Britain and Northern Ireland',
  },
];

export default mockDatacenters;
