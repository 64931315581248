import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const StepperTwoIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="stepper-two-icon" ref={ref} {...props}>
        <path d="M12 6C10.0883 6 8.25 7.32454 8.25 9.5C8.25 10.0523 8.69772 10.5 9.25 10.5C9.80229 10.5 10.25 10.0523 10.25 9.5C10.25 8.67546 10.9293 8 12 8C13.0707 8 13.75 8.67546 13.75 9.5C13.75 10.0542 13.5392 10.4725 13.1344 10.889C12.7124 11.3233 12.1426 11.6945 11.4376 12.1539L11.3287 12.2249C9.94448 13.1277 8 14.3973 8 17C8 17.5523 8.44772 18 9 18H15C15.5523 18 16 17.5523 16 17C16 16.4477 15.5523 16 15 16H10.1946C10.5408 15.1925 11.3313 14.611 12.4213 13.9001C12.4743 13.8655 12.5282 13.8305 12.5828 13.795C13.2313 13.3737 13.9835 12.8851 14.5687 12.2829C15.2421 11.59 15.75 10.6958 15.75 9.5C15.75 7.32454 13.9117 6 12 6Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
        />
      </SvgTemplate>
    );
  }
);
export default StepperTwoIcon;
