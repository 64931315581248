import * as React from 'react';
import { InputAdornment } from '@mui/material';
import { ClearTextIcon, SearchIcon } from '@wpengine/unicorn/icons';
import { SxProps } from '@mui/system';
import { gray900 } from 'design-tokens';
import IconButton from '../IconButton';
import TextField from '../TextField';
import GradientCircularProgress from '../GradientCircularProgress';

interface SearchProps {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  helperText?: string;
  name?: string;
  id?: string;
  label?: string;
  loading?: boolean;
  inputRef?: React.Ref<any>;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  onSearchIconClick?: (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => void;
  onKeyUp?: React.KeyboardEventHandler<HTMLDivElement>;
  placeholder?: string;
  style?: object;
  sx?: SxProps;
  value?: string;
}

function mergeRefs<T = any>(refs: React.Ref<T>[]) {
  return (node: T) => {
    refs.forEach((ref: React.Ref<T>) => {
      const refValue = ref;
      if (typeof refValue === 'function') {
        refValue(node);
      } else if (refValue !== null) {
        (refValue as React.MutableRefObject<T | null>).current = node;
      }
    });
  };
}

const Search: React.FC<SearchProps> = ({
  autoFocus,
  className,
  disabled,
  helperText,
  id,
  inputRef = null,
  label,
  loading,
  name,
  onChange,
  onSearchIconClick,
  onKeyUp,
  placeholder,
  style,
  value,
  ...other
}): JSX.Element => {
  const [icon, setIcon] = React.useState(<></>);
  const innerRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (value === undefined) {
      // do not edit icon
    } else if (value === '') {
      setIcon(<></>);
    } else if (loading) {
      setIcon(
        <GradientCircularProgress
          aria-label="Loading"
          size={24}
          thickness={5}
          sx={{ mr: '8px' }}
        />
      );
    } else {
      const clearEvent = { target: { value: '' } };
      setIcon(
        <IconButton
          data-testid="clear-search-term"
          aria-label="Clear search"
          onClick={(): void => {
            if (onChange) {
              onChange(clearEvent);
            }
            innerRef.current?.focus();
          }}
          sx={{ ml: '8px' }}
        >
          <ClearTextIcon />
        </IconButton>
      );
    }
  }, [value, loading]);

  return (
    <TextField
      autoFocus={autoFocus}
      className={className}
      disabled={disabled}
      fullWidth
      helperText={helperText}
      id={id}
      name={name}
      label={label}
      onChange={onChange}
      style={style}
      value={value}
      onKeyUp={onKeyUp}
      {...other}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon
              onClick={onSearchIconClick}
              cursor={onSearchIconClick ? 'pointer' : 'auto'}
              htmlColor={gray900}
              sx={{
                opacity: 0.6,
              }}
            />
          </InputAdornment>
        ),
        endAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
        placeholder,
        inputRef: mergeRefs([innerRef, inputRef]),
      }}
    />
  );
};

export default Search;
