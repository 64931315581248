import * as React from 'react';
import { gray900 } from 'design-tokens';
import { Box, IconButton } from '@wpengine/unicorn/components';
import { CloseSmallIcon, ChevronLeftIcon } from '@wpengine/unicorn/icons';

type GroupHeadingProps = {
  title: string;
  onBack?: () => void;
};

const GroupHeading: React.FC<GroupHeadingProps> = ({ title, onBack }) => {
  return (
    <Box
      color={gray900}
      fontSize="12px"
      fontWeight="600"
      padding={onBack ? '4px 0px 4px 0px' : '8px'}
      textTransform="uppercase"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" alignItems="center">
        {onBack && (
          <IconButton
            color="default"
            onClick={() => onBack()}
            sx={{ paddingLeft: 0 }}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
        <div>{title}</div>
      </Box>
      {onBack && (
        <div>
          <IconButton color="default" onClick={() => onBack()}>
            <CloseSmallIcon />
          </IconButton>
        </div>
      )}
    </Box>
  );
};

export default GroupHeading;
