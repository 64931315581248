export default {
  SITES_TRANSFER_AVAILABLE_SITES: 'sites_transfer/available_sites',
  SITES_TRANSFER: 'sites_transfer',
  SUBSCRIPTION_DATA: 'sites/add_site_and_install/subscription_data',
  ACCOUNT_SOURCE_ENVIRONMENTS:
    '/sites/add_site_and_install/account_source_environments',
  INSTALL_NAME_AVAILABLE: 'installs/install_name_available',
  SITES_ENV_NAME_SUGGESTIONS: 'sites/env_name_suggestions',
  SITES_ADD_SITE_AND_INSTALL: 'sites/add_site_and_install',
  SITES_ADD_SITE_AND_INSTALL_MOVE: 'sites/add_site_and_install/move',
};
