import { samples } from 'componentsDir/AllSites/mocks/data/listSitesByUser';

export const install0: IInstall = {
  // install0
  name: samples.installs[0].name,
  id: 1,
  uuid: samples.installs[0].uuid,
  primaryDomain: samples.installs[0].domainName,
  domains: [],
  clusterId: 0,
  env: 'production',
  region: '',
  customerType: {
    customerType: '',
    planType: '',
    hostingType: '',
  },
  accountWpeId: samples.accounts[0].wpeId,
};
export const install6: IInstall = {
  // install6
  name: samples.installs[6].name,
  id: 6,
  uuid: samples.installs[6].uuid,
  primaryDomain: samples.installs[6].domainName,
  domains: [],
  clusterId: 0,
  env: 'production',
  region: '',
  customerType: {
    customerType: '',
    planType: '',
    hostingType: '',
  },
  accountWpeId: samples.accounts[1].wpeId,
};
export const install7: IInstall = {
  // install7
  name: samples.installs[7].name,
  id: 7,
  uuid: samples.installs[7].uuid,
  primaryDomain: samples.installs[7].domainName,
  domains: [],
  clusterId: 0,
  env: 'production',
  region: '',
  customerType: {
    customerType: '',
    planType: '',
    hostingType: '',
  },
  accountWpeId: samples.accounts[2].wpeId,
};
export const googleInstall: IInstall = {
  name: 'google',
  id: 1001,
  uuid: '2e65f51a-54c5-4329-9bf9-c04c91fd083c',
  primaryDomain: 'www.google.com',
  domains: [],
  clusterId: 1,
  env: 'production',
  region: 'us-east',
  accountWpeId: 1,
  customerType: {
    customerType: 'customer',
    planType: 'startup',
    hostingType: 'shared',
  },
};

export const yahooInstall: IInstall = {
  name: 'yahoo',
  id: 1002,
  uuid: '5b84737b-7ccd-414a-921d-66aefc5f9b3c',
  primaryDomain: 'www.yahoo.com',
  domains: [],
  clusterId: 1,
  env: 'production',
  region: 'us-east',
  accountWpeId: 1,
  customerType: {
    customerType: 'customer',
    planType: 'startup',
    hostingType: 'shared',
  },
};
export const askJeevesInstall: IInstall = {
  name: 'askjeeves',
  id: 1003,
  uuid: 'b9d2c590-b49a-4a6e-a709-7945e61e7f14',
  primaryDomain: 'www.askjeeves.com',
  domains: [],
  clusterId: 1,
  env: 'production',
  region: 'us-east',
  accountWpeId: 1,
  customerType: {
    customerType: 'customer',
    planType: 'startup',
    hostingType: 'shared',
  },
};
export const infoseekInstall: IInstall = {
  name: 'infoseek',
  id: 1004,
  uuid: 'a8e15df8-3929-42bb-9904-b5e3cd11711d',
  primaryDomain: 'www.infoseek.com',
  domains: [],
  clusterId: 1,
  env: 'production',
  region: 'us-east',
  accountWpeId: 1,
  customerType: {
    customerType: 'customer',
    planType: 'startup',
    hostingType: 'shared',
  },
};
export const bingInstall: IInstall = {
  name: 'bing',
  id: 1005,
  uuid: 'cc6cb964-821d-4f55-a1f5-e8369d27afe6',
  primaryDomain: 'www.bing.com',
  domains: [],
  clusterId: 1,
  env: 'production',
  region: 'us-east',
  accountWpeId: 1,
  customerType: {
    customerType: 'customer',
    planType: 'startup',
    hostingType: 'shared',
  },
};
export const duckduckgoInstall: IInstall = {
  name: 'duckduckgo',
  id: 1006,
  uuid: '25002109-85a0-49e7-b6b4-5d33becbe627',
  primaryDomain: 'www.duckduckgo.com',
  domains: [],
  clusterId: 1,
  env: 'production',
  region: 'us-east',
  accountWpeId: 1,
  customerType: {
    customerType: 'customer',
    planType: 'startup',
    hostingType: 'shared',
  },
};
export const facebookInstall: IInstall = {
  name: 'facebook',
  id: 1007,
  uuid: '5b6ab0d3-2ff6-485b-9ec1-a9499e3f71f4',
  primaryDomain: 'www.facebook.com',
  domains: [],
  clusterId: 1,
  env: 'production',
  region: 'us-east',
  accountWpeId: 2,
  customerType: {
    customerType: 'customer',
    planType: 'startup',
    hostingType: 'shared',
  },
};
export const instagramInstall: IInstall = {
  name: 'instagram',
  id: 1008,
  uuid: '21674200-9eb5-4f3a-884b-eb57a592fea7',
  primaryDomain: 'www.instagram.com',
  domains: [],
  clusterId: 1,
  env: 'production',
  region: 'us-east',
  accountWpeId: 2,
  customerType: {
    customerType: 'customer',
    planType: 'startup',
    hostingType: 'shared',
  },
};
export const nintendoInstall: IInstall = {
  name: 'nintendo',
  id: 1009,
  uuid: 'bbf8819f-7cfc-4dbf-8a53-094b0e7106ba',
  primaryDomain: 'www.nintendo.com',
  domains: [],
  clusterId: 1,
  env: 'production',
  region: 'us-east',
  accountWpeId: 3,
  customerType: {
    customerType: 'customer',
    planType: 'startup',
    hostingType: 'shared',
  },
};
export const segaInstall: IInstall = {
  name: 'sega',
  id: 1010,
  uuid: '444e3f83-9ef7-4ebe-9cb4-1457d8a136fc',
  primaryDomain: 'www.sega.com',
  domains: [],
  clusterId: 1,
  env: 'production',
  region: 'us-east',
  accountWpeId: 3,
  customerType: {
    customerType: 'customer',
    planType: 'startup',
    hostingType: 'shared',
  },
};
