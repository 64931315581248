import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  FormControl,
  FormControlProps,
  InputAdornment,
  SvgIconProps,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { gray700 } from 'design-tokens';
import { ChevronDownIcon, CheckIcon } from '../../icons';
import { Box } from '../layout';
import Link from '../Link';
import ErrorText from '../internal/ErrorText';
import HelperText from '../internal/HelperText';
import Label from '../internal/Label';
import UnicornInput from './components/UnicornInput';
import UnicornSelect from './components/UnicornSelect';
import UnicornMenuItem from './components/UnicornMenuItem';
import UnicornListSubheader from './components/UnicornListSubheader';
import CircularProgress from '../GradientCircularProgress';

const PREFIX = 'Dropdown';

const classes = {
  buttonLink: `${PREFIX}-buttonLink`,
};

const StyledFormControl = styled(FormControl)(() => ({
  [`& .${classes.buttonLink}`]: {
    lineHeight: 1,
    marginLeft: 'auto',
  },
}));

export interface Option {
  label: string;
  value: string;
  ItemTemplate?: DropdownTemplate<any>;
  disabled?: boolean;
  disabledTooltip?: string;
}

export interface Group {
  label: string;
  options: Option[];
}

export type DropdownTemplate<T extends Option> = React.FC<T>;

interface IDropdown {
  clear?: () => void;
  disabled?: boolean;
  fullWidth?: boolean;
  helperText?: string | JSX.Element;
  id: string;
  invalid?: boolean;
  invalidText?: string;
  labelText?: string | JSX.Element;
  loading?: boolean;
  maxMenuHeight?: string | number;
  name: string;
  onChange: (arg0: any) => void;
  options?: Option[];
  placeholder?: string;
  groups?: Group[];
  value: string;
  width?: number;
  ItemTemplate?: DropdownTemplate<any>;
  IconComponent?: React.ElementType<any>;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export const Dropdown: React.FC<IDropdown &
  Omit<FormControlProps, 'onChange'>> = ({
  clear,
  disabled = false,
  fullWidth = true,
  helperText,
  id,
  invalid = false,
  invalidText,
  labelText,
  loading = false,
  maxMenuHeight = 300,
  name,
  onChange,
  options = [],
  placeholder,
  groups = [],
  value: selectedValue,
  width,
  ItemTemplate,
  IconComponent,
  open,
  onOpen,
  onClose,
  ...other
}) => {
  const getTemplate = (option: Option): DropdownTemplate<any> | null => {
    return option?.ItemTemplate || ItemTemplate || null;
  };

  const renderLabelText = (value: string): JSX.Element => {
    if (value?.length === 0) {
      return <Box color={gray700}>{placeholder}</Box>;
    }
    let selectedOption = options.find(
      (option: Option) => option.value === value
    );

    if (!selectedOption) {
      selectedOption = groups
        .flatMap(group => group.options)
        .find((option: Option) => option.value === value);
    }

    let Template;
    if (selectedOption) {
      Template = getTemplate(selectedOption);
    }

    return (
      <>{Template ? <Template {...selectedOption} /> : selectedOption?.label}</>
    );
  };

  const renderMenuItem = (optProps: Option): JSX.Element => {
    return optProps.disabled && optProps.disabledTooltip ? (
      <Tooltip
        title={optProps.disabledTooltip}
        key={`dropdown-menu-item-${optProps.label}-${optProps.value}`}
      >
        <span>{renderMenuItemInternal(optProps)}</span>
      </Tooltip>
    ) : (
      renderMenuItemInternal(optProps)
    );
  };

  const renderMenuItemInternal = (optProps: Option): JSX.Element => {
    return (
      <UnicornMenuItem
        key={`dropdown-menu-item-${optProps.label}-${optProps.value}`}
        value={optProps.value}
        disabled={optProps.disabled}
      >
        {getTemplate(optProps)
          ? React.createElement(getTemplate(optProps), optProps)
          : optProps.label}
        {selectedValue === optProps.value ? (
          <Box display="inline-flex" ml="auto" pl={1}>
            <CheckIcon />
          </Box>
        ) : null}
      </UnicornMenuItem>
    );
  };

  const noOptions = () => {
    return (
      options?.length === 0 &&
      groups?.flatMap(group => group.options).length === 0
    );
  };

  const disabledOrLoading = disabled || loading;
  const labelId = `${id}-label`;
  return (
    <StyledFormControl
      fullWidth={!width && fullWidth}
      error={invalid}
      {...other}
    >
      {labelText || clear ? (
        <Box display="flex">
          {labelText ? (
            <Label id={labelId} disabled={disabledOrLoading} htmlFor={id}>
              {labelText}
            </Label>
          ) : null}
          {clear ? (
            <Link
              className={classes.buttonLink}
              component="button"
              disabled={disabledOrLoading}
              onClick={(): void => clear()}
              type="button"
            >
              Clear
            </Link>
          ) : null}
        </Box>
      ) : null}
      {helperText ? (
        <HelperText disabled={disabledOrLoading}>{helperText}</HelperText>
      ) : null}
      <UnicornSelect
        aria-labelledby={labelId}
        IconComponent={
          IconComponent ||
          ((props: SvgIconProps): JSX.Element => <ChevronDownIcon {...props} />)
        }
        displayEmpty
        id={id}
        name={name}
        renderValue={renderLabelText}
        value={selectedValue}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            elevation: 0,
            square: false,
            style: {
              ...(width && { width }),
            },
          },
          transitionDuration: 0,
          style: {
            marginTop: '8px',
            marginRight: '8px',
            ...(maxMenuHeight && {
              maxHeight: maxMenuHeight,
            }),
          },
        }}
        onChange={(event: SelectChangeEvent): void => {
          onChange(event.target.value as string);
        }}
        style={{ ...(width && { width }) }}
        input={
          <UnicornInput
            disabled={disabledOrLoading}
            startAdornment={
              loading ? (
                <InputAdornment position="start">
                  <CircularProgress size={16} />
                </InputAdornment>
              ) : null
            }
          />
        }
      >
        {noOptions() ? (
          <UnicornMenuItem disabled value="">
            No options
          </UnicornMenuItem>
        ) : null}
        {options?.length
          ? options.map(optProps => {
              return renderMenuItem(optProps);
            })
          : null}
        {groups?.length
          ? groups.map(optProps => {
              return [
                <UnicornListSubheader>{optProps.label}</UnicornListSubheader>,
                optProps.options.map(props => {
                  return renderMenuItem(props);
                }),
              ];
            })
          : null}
      </UnicornSelect>
      {invalid && invalidText ? (
        <ErrorText id={`${id}-error`} text={invalidText} />
      ) : null}
    </StyledFormControl>
  );
};

export default Dropdown;
