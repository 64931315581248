import * as React from 'react';
import { ecommerce_path, ecommerce_solution_path } from 'railsRoutes';
import { EcommerceIcon } from '@wpengine/unicorn/icons';
import SideNavLink from '../components/SideNavLink/SideNavLink';

interface EcommerceProps {
  hasEcommerce: boolean;
}

const Ecommerce: React.FC<EcommerceProps> = ({ hasEcommerce }) => {
  const path = hasEcommerce ? ecommerce_path() : ecommerce_solution_path();
  return <SideNavLink icon={<EcommerceIcon />} label="eCommerce" href={path} />;
};

export default Ecommerce;
