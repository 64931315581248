type SplashScreenListener = (
  text1: string,
  text2: string,
  durationMs?: number
) => void;

let splashScreenListeners: Array<SplashScreenListener> = [];

export const addSplashScreenListener = (
  callback: SplashScreenListener
): void => {
  splashScreenListeners.push(callback);
};

export const removeSplashScreenListener = (
  callback: SplashScreenListener
): void => {
  splashScreenListeners = splashScreenListeners.filter(cb => cb !== callback);
};

export const notifySplashScreenListeners = (
  text1: string,
  text2: string,
  durationMs?: number
): void => {
  splashScreenListeners.forEach((listener: any) =>
    listener(text1, text2, durationMs)
  );
};

export default {
  addSplashScreenListener,
  notifySplashScreenListeners,
  removeSplashScreenListener,
};
