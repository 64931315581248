import {
  NetworkInfoHostnameStatus,
  V1alpha1Domain,
  V1alpha1NetworkType,
} from 'platform_one_api_client';

import { getMockDomains } from './data';

const seedrandom = require('seedrandom');

const rng = seedrandom('DomainsRepository mocks');

type UpdateFields = Pick<V1alpha1Domain, 'networkType' | 'redirectDomainName'>;

export class DomainsRepository {
  private domains: V1alpha1Domain[];

  primaryDomain: V1alpha1Domain;

  constructor(domainsCount: number) {
    if (process.env.NODE_ENV === 'test') {
      this.domains = getMockDomains(5);
    } else {
      this.domains = getMockDomains(domainsCount);
      this.primaryDomain = this.domains[
        Math.floor(rng() * this.domains.length)
      ];
      this.primaryDomain.primary = true;
    }
  }

  getDomains = (pageSize: number, pageToken?: string) => {
    const page = !pageToken ? 0 : +pageToken;

    const from = pageSize * page;
    const domainsPage = this.domains.slice(from, from + pageSize);

    const nextPage = this.domains.slice(from + pageSize, from + pageSize * 2);

    let nextPageToken;
    if (nextPage.length) {
      nextPageToken = (page + 1).toString();
    }

    return { domains: domainsPage, nextPageToken };
  };

  getDomain = (domainName: string) => {
    const domain = this.domains.find(d => d.domainName === domainName);
    if (!domain) return null;

    if (!domain?.networkDetails?.networkInfo) {
      domain.networkDetails.networkInfo = {
        status: NetworkInfoHostnameStatus.Pending,
        ssl: { status: 'active' } as any,
        ownershipVerification: {
          type: 'txt',
          name: '_cf-custom-hostname.fakedata.com',
          value: '81e1hg19-fake-data-7d2a-123456789248',
        },
        ownershipVerificationHttp: {
          httpBody: 'http-verification-name',
          httpUrl: 'http-verification-value',
        },
        reconciled: true,
      };
    }

    return domain;
  };

  createDomain = (domainName: string) => {
    const newDomain = getMockDomains(1)[0];
    newDomain.domainName = domainName;
    newDomain.networkType = V1alpha1NetworkType.An;

    delete newDomain.networkDetails.networkInfo;

    this.domains.push(newDomain);
    this.domains.sort((pd, nd) => (pd.domainName > nd.domainName ? 1 : -1));

    return newDomain;
  };

  updateDomain = (domainName: string, updateMask: UpdateFields) => {
    this.domains = this.domains.map(domain => {
      if (domain.domainName === domainName) {
        return { ...domain, ...updateMask };
      }
      return domain;
    });

    return this.domains.find(d => d.domainName === domainName);
  };

  deleteDomain = (domainName: string) => {
    this.domains = this.domains.filter(d => d.domainName !== domainName);
    return true;
  };
}

export default DomainsRepository;
