import { subHours, subDays } from 'date-fns';

export const INSTALLS_TABLE_COLUMNS = [
  { columnName: 'Site', width: '30%', loadingWidth: '23.125rem' },
  { columnName: 'GES CNAME', width: '30%', loadingWidth: 'auto' },
  {
    columnName: 'Domains with GES',
    width: '20%',
    textAlign: 'right',
    loadingWidth: '4.375rem',
  },
  {
    columnName: 'Total no. of domains',
    width: '20%',
    textAlign: 'right',
    loadingWidth: '4.375rem',
  },
];

export const EVENTS_TABLE_COLUMNS = [
  { columnName: 'Total no. of events', width: '33,33%', loadingWidth: '125px' },
  { columnName: 'Events blocked', width: '33,33%', loadingWidth: '125px' },
  { columnName: 'Events challenged', width: '33,33%', loadingWidth: '125px' },
];

export const EVENTS_TABLE_LOADING_ROW_COUNT = 1;

export const INSTALL_TABLE_LOADING_ROWS_COUNT = 10;

export const DEFAULT_END_DATE = subHours(new Date(), 2);
export const DEFAULT_START_DATE = subDays(DEFAULT_END_DATE, 1);
