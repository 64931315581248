import { useState } from 'react';
import Cookies from 'universal-cookie';
import OwnershipCookieName from '../OwnershipCookieName';

export const useOwnershipToggle = () => {
  const cookies = new Cookies();
  const [checked, setChecked] = useState(
    () => cookies.get(OwnershipCookieName) === 'true'
  );

  const handleToggle = () => {
    setChecked(!checked);
  };

  return { checked, handleToggle };
};

export default useOwnershipToggle;
