const messages: {
  messages: NotifyHubMessage[];
} = {
  messages: [
    {
      key: 'spm_update_failed',
      subject: 'spm update',
      description: 'This is example description',
      severity: 'warning',
      category: 'spm',
      isActive: true,
    },
    {
      key: 'atlas_update_succesful',
      subject: 'atlas update',
      description:
        'This is example description and is ver kajsnduiashdiuashjdio ujahsiudjaisjdiasjdiojasod jaioshdiuashjdoiasj diuahsuidhjasoijdiu ashdiuasjdioajsdoias',
      severity: 'success',
      category: 'atlas',
      isActive: false,
    },
    {
      key: 'spm_processing_error',
      subject: 'spm app',
      description: 'This is example description',
      severity: 'critical',
      category: 'spm',
      isActive: false,
    },
    {
      key: 'atlas_billing_info',
      subject: 'atlas billing',
      description: 'This is example description',
      severity: 'info',
      category: 'atlas',
      isActive: true,
    },
    {
      key: 'atlas_build_success',
      subject: 'atlas build success',
      description: 'This is example description',
      severity: 'info',
      category: 'atlas',
      isActive: true,
    },
    {
      key: 'wpe_payments_keys_saved_successfully',
      subject: 'Stripe Connect keys were saved successfully for example.com.',
      description:
        'Stripe Connect keys were saved successfully for example.com.',
      severity: 'success',
      category: 'ecommerce',
      isActive: true,
    },
    {
      key: 'wpe_payments_invite_sent',
      subject: 'Invitation for Stripe Connect sent for example.com.',
      description:
        'Invitation to set up Stripe Connect was sent successfully for example.com.',
      severity: 'info',
      category: 'ecommerce',
      isActive: true,
    },
  ],
};

export default messages;
