import { rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { ENDPOINTS } from '../../../constants';

const cacheErrorHandler = [
  rest.post(`*${ENDPOINTS.CACHE_ERROR}`, (_req, _res, ctx) => {
    return customResponse(ctx.status(200), ctx.json({}));
  }),
];

export default cacheErrorHandler;
