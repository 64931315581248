export function difference<T>(A: Set<T>, B: Set<T>): Set<T> {
  return new Set<T>(Array.from(A).filter(x => !B.has(x)));
}

export function intersection<T>(A: Set<T>, B: Set<T>): Set<T> {
  return new Set<T>(Array.from(A).filter(x => B.has(x)));
}

export function union<T>(A: Set<T>, B: Set<T>): Set<T> {
  return new Set<T>([...Array.from(A), ...Array.from(B)]);
}
