import { rest } from 'msw';
import { deploys_path } from 'railsRoutes';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';

const createCopyHandlers = [
  rest.post(`*${deploys_path()}*`, async (req, res, ctx) => {
    if (hasPageUrlQueryParam('success', 'false')) {
      return res(
        ctx.status(200),
        ctx.json({
          message:
            'A copy or restore process is already in progress. Please try again later.',
          success: false,
        })
      );
    }

    return customResponse(
      ctx.status(200),
      ctx.json({
        redirectUrl: '/installs/install1?cp_success=1',
        success: true,
      })
    );
  }),
];

export default createCopyHandlers;
