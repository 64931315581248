import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const DiamondIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="diamond-icon" ref={ref} {...props}>
        <path d="m12 22c-.2711 0-.5328-.057-.785-.171-.2523-.114-.473-.2882-.6621-.5227l-9.1178-11.2946c-.14503-.17586-.2538-.368-.32631-.57643-.07253-.20844-.10879-.4299-.10879-.66439 0-.14982.01735-.29801.05204-.44456.03467-.14654.08669-.29147.15606-.43478l2.31726-4.81682c.17025-.32566.40514-.5862.70467-.78161.2995-.19541.62896-.29311.98838-.29311h13.56319c.3594 0 .6889.0977.9884.29311s.5344.45595.7046.78161l2.3173 4.81682c.0694.14331.1214.28824.1561.43478.0347.14655.052.29474.052.44456 0 .23449-.0363.45595-.1088.66439-.0725.20843-.1813.40057-.3263.57643l-9.1178 11.2946c-.1891.2345-.4098.4087-.6621.5227-.2522.114-.5139.171-.785.171zm-3.01721-13.94235h6.03441l-1.9863-4.10359h-2.0618zm2.07141 10.83535v-8.8813h-7.15051zm1.8916 0 7.1505-8.8813h-7.1505zm4.1806-10.83535h3.5752l-1.9862-4.10359h-3.5753zm-13.82804 0h3.57524l1.98627-4.10359h-3.57526z" />
      </SvgTemplate>
    );
  }
);
export default DiamondIcon;
