import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '@wpengine/unicorn/icons/utils/SvgTemplate';

const DoubleChevronLeftIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate
        name="double-chevron-left-icon"
        ref={ref}
        viewBox="0 0 24 14"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 7C0 6.73478 0.105357 6.48043 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976315 7.31658 -0.0976315 7.70711 0.292893C8.09763 0.683417 8.09763 1.31658 7.70711 1.70711L2.41421 7L7.70711 12.2929C8.09763 12.6834 8.09763 13.3166 7.70711 13.7071C7.31658 14.0976 6.68342 14.0976 6.29289 13.7071L0.292893 7.70711C0.105357 7.51957 0 7.26522 0 7ZM19.7071 13.7071C20.0976 13.3166 20.0976 12.6834 19.7071 12.2929L14.4142 7L19.7071 1.70711C20.0976 1.31658 20.0976 0.683417 19.7071 0.292893C19.3166 -0.0976315 18.6834 -0.0976315 18.2929 0.292893L12.2929 6.29289C11.9024 6.68342 11.9024 7.31658 12.2929 7.70711L18.2929 13.7071C18.6834 14.0976 19.3166 14.0976 19.7071 13.7071Z"
        />
      </SvgTemplate>
    );
  }
);
export default DoubleChevronLeftIcon;
