import * as React from 'react';
import {
  manage_path,
  modify_addons_esm_path,
  modify_plan_path,
  my_account_path,
  stats_path,
} from 'railsRoutes';
import { BillingIcon } from '@wpengine/unicorn/icons';
import SideNavDropdown from '../components/SideNavDropdown/SideNavDropdown';

interface BillingProps {
  canPurchaseAddons: boolean;
  hasEsm: boolean;
  hasStats: boolean;
}

const Billing: React.FC<BillingProps> = ({
  canPurchaseAddons,
  hasEsm,
  hasStats,
}) => {
  const links = [
    {
      href: hasEsm ? manage_path() : my_account_path(),
      label: 'Invoice',
    },
  ];

  if (hasStats) {
    links.push({
      href: stats_path(),
      label: 'Usage',
    });
  }

  if (canPurchaseAddons) {
    if (hasEsm) {
      links.push({
        href: modify_addons_esm_path(),
        label: 'Purchase extensions',
      });
    } else {
      links.push({
        href: modify_plan_path(),
        label: 'Purchase extensions',
      });
    }
  }

  return (
    <SideNavDropdown links={links} icon={<BillingIcon />} label="Billing" />
  );
};

export default Billing;
