import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const EcommerceIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="ecommerce-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3384 1.33908C11.9258 0.999945 12.6203 0.91373 13.2676 1.08707L17.6446 2.25928C18.3058 2.43216 18.8576 2.86221 19.1928 3.44276L22.6611 9.45028C23.0003 10.0377 23.0863 10.7323 22.9128 11.3795L21.7394 15.7566C21.5663 16.4177 21.1362 16.9692 20.5557 17.3043L11.1031 22.7619C10.2553 23.2515 9.17579 22.9626 8.68609 22.1144L1.23802 9.21366C0.748329 8.36547 1.03794 7.28621 1.88579 6.79669L11.3384 1.33908ZM12.7502 3.01899C12.6077 2.98082 12.4598 3.00105 12.3384 3.07112L3.08543 8.41348L10.3028 20.9146L19.5557 15.5723C19.6828 15.4989 19.7699 15.3835 19.8048 15.2491L19.8068 15.2417L19.8068 15.2417L20.981 10.8617C21.0192 10.7191 20.999 10.5714 20.9291 10.4503L17.4607 4.44274C17.3875 4.31592 17.2722 4.2289 17.1378 4.19401L17.1304 4.19209L17.1304 4.19207L12.7502 3.01899Z"
        />
        <path d="M17.7318 8.00017C18.284 8.95659 17.9563 10.1796 16.9998 10.7318C16.0434 11.284 14.8204 10.9563 14.2682 9.99983C13.716 9.04341 14.0437 7.82043 15.0002 7.26824C15.9566 6.71605 17.1796 7.04374 17.7318 8.00017Z" />
      </SvgTemplate>
    );
  }
);
export default EcommerceIcon;
