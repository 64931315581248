// @ts-nocheck
const mockDashboardProps: DashboardProps = {
  user: {
    first_name: 'Test',
  },
  csrfToken: 'token',
};

const mockLayoutV1 = {
  show_banner: true,
  layout: [
    {
      widget: 'outdated-plugins',
      x: 1,
      y: 1,
      w: 2,
      h: 1,
      name: 'Plugins',
    },
    {
      widget: 'outdated-themes',
      x: 3,
      y: 1,
      w: 2,
      h: 1,
      name: 'Themes',
    },
    {
      widget: 'outdated-php',
      x: 5,
      y: 1,
      w: 2,
      h: 1,
      name: 'PHP',
    },
    {
      widget: 'spm',
      x: 7,
      y: 1,
      w: 2,
      h: 1,
      name: 'Smart Plugin Manager',
    },
    {
      widget: 'site-health',
      x: 5,
      y: 2,
      w: 4,
      h: 1,
      name: 'Site health',
    },
    {
      widget: 'marketing',
      x: 5,
      y: 3,
      w: 4,
      h: 1,
    },
    {
      widget: 'top-environments',
      x: 5,
      y: 5,
      w: 4,
      h: 1,
      name: 'Top environments',
    },
    {
      widget: 'plan-overview',
      x: 1,
      y: 2,
      w: 4,
      h: 2,
      name: 'Plan overview',
    },
    {
      widget: 'trends',
      x: 1,
      y: 4,
      w: 8,
      h: 1,
      name: 'Trends',
    },
    {
      widget: 'account-activity',
      x: 1,
      y: 5,
      w: 4,
      h: 1,
      name: 'Recent account activity',
    },
    {
      widget: 'wordpress-community',
      x: 1,
      y: 6,
      w: 8,
      h: 1,
      name: 'Insights to inspire',
    },
  ],
};

const mockLayoutV2 = {
  show_banner: true,
  layout: [
    {
      widget: 'updates',
      x: 1,
      y: 1,
      w: 8,
      h: 1,
      name: 'Updates',
    },
    {
      widget: 'plan-overview',
      x: 1,
      y: 2,
      w: 4,
      h: 1,
      name: 'Plan overview',
    },
    {
      widget: 'last-twenty-four-hours',
      x: 5,
      y: 2,
      w: 4,
      h: 1,
      name: 'Your last 24 hours',
    },
    {
      widget: 'thirty-day-snapshot',
      x: 1,
      y: 3,
      w: 8,
      h: 1,
      name: '30-day snapshot',
    },
    {
      widget: 'lighthouse-performance',
      x: 1,
      y: 4,
      w: 4,
      h: 1,
      name: 'Lighthouse performance',
    },
    {
      widget: 'top-searches',
      x: 5,
      y: 4,
      w: 4,
      h: 1,
      name: 'Top searches',
    },
    {
      widget: 'account-activity',
      x: 1,
      y: 5,
      w: 4,
      h: 1,
      name: 'Recent account activity',
    },
    {
      widget: 'marketing',
      x: 5,
      y: 5,
      w: 4,
      h: 1,
    },
    {
      widget: 'wordpress-community',
      x: 1,
      y: 6,
      w: 8,
      h: 1,
      name: 'Insights to inspire',
    },
  ],
};

const mockOutdatedInstalls = {
  widget: 'outdated-installs',
  account: 'account_name',
  data: {
    pluginsCount: 3,
    pluginsInstallsCount: 1,
    themesCount: 0,
    themesInstallsCount: 0,
    phpLatestVersion: '0',
    phpInstallsCount: 0,
    anyInstallsData: true,
  },
};

const mockPlanOverviewData = {
  widget: 'plan-overview',
  data: {
    billingCycles: ['01-May-2023', '01-June-2023'],
    sitesAvailable: 4,
    sitesUsed: 3,
    maxBandwidth: 850,
    maxBillableVisits: 700000,
    maxStorage: 450,
    bandwidthUsed: 913000000000,
    usedBillableVisits: 690000,
    usedStorage: 209681923241,
    billingAccess: true,
    availableAddons: { sites: true, localStorage: false },
    ubs: false,
  },
};

const mockSpm = {
  widget: 'spm',
  account: 'account_name',
  data: {
    entitlementsCount: 5,
    activationsCount: 1,
    availableCount: 4,
    totalInstallsCount: 5,
  },
};

const mockTopEnvironments = {
  widget: 'top-environments',
  account: 'account',
  data: {
    billable_visits: [
      {
        id: 1,
        name: 'Environment',
        value: 1,
        environment: 'production',
        domain: 'test.com',
        sandbox: false,
      },
      {
        id: 2,
        name: 'Environment 2',
        value: 1,
        environment: 'development',
        domain: 'test2.com',
        sandbox: true,
      },
      {
        id: 3,
        name: 'Environment 3',
        value: 1,
        environment: 'production',
        domain: 'test3.com',
        sandbox: false,
      },
      {
        id: 4,
        name: 'Environment 4',
        value: 1,
        environment: 'staging',
        domain: 'test4.com',
        sandbox: false,
      },
    ],
    storage: [
      {
        id: 1,
        name: 'Environment',
        value: 1,
        environment: 'production',
        domain: 'test.com',
        sandbox: false,
      },
      {
        id: 3,
        name: 'Environment 3',
        value: 1,
        environment: 'production',
        domain: 'test3.com',
        sandbox: false,
      },
      {
        id: 2,
        name: 'Environment 2',
        value: 1,
        environment: 'development',
        domain: 'test2.com',
        sandbox: true,
      },
      {
        id: 4,
        name: 'Environment 4',
        value: 1,
        environment: 'staging',
        domain: 'test4.com',
        sandbox: false,
      },
    ],
  },
};

const mockSiteHealth = {
  widget: 'site-health',
  account: 'account',
  data: {
    monitoringOutagesCount: 2,
    gesAlertsCount: -1,
  },
};

const mockTrends = {
  widget: 'trends',
  billing_cycle: {
    start_date: '2023-08-01',
    end_date: '2023-08-31',
  },
  data: {
    billable_visits: [
      {
        value: 13,
        name: 'August 16, 2023',
        production: 8,
        staging: 3,
        development: 2,
        transferable: 8,
        nonTransferable: 5,
      },
      {
        value: 7,
        name: 'August 17, 2023',
        production: 2,
        staging: 3,
        development: 2,
        transferable: 2,
        nonTransferable: 5,
      },
      {
        value: 19,
        name: 'August 18, 2023',
        production: 8,
        staging: 3,
        development: 8,
        transferable: 8,
        nonTransferable: 11,
      },
      {
        value: 19,
        name: 'August 19, 2023',
        production: 8,
        staging: 8,
        development: 3,
        transferable: 11,
        nonTransferable: 8,
      },
    ],
    bandwidth: [
      {
        value: 3000310000,
        name: 'August 16, 2023',
        production: 15707832320,
        staging: 350466048,
        development: 170717184,
        transferable: 1147645952,
        nonTransferable: 15081369600,
        originBandwidth: 2250232500,
        cdnBandwidth: 750077500,
      },
      {
        value: 1000333000,
        name: 'August 17, 2023',
        production: 0,
        staging: 186499072,
        development: 0,
        transferable: 0,
        nonTransferable: 186499072,
        originBandwidth: 500166500,
        cdnBandwidth: 500166500,
      },
      {
        value: 2004400000,
        name: 'August 18, 2023',
        production: 15707848704,
        staging: 350474240,
        development: 170717184,
        transferable: 1147645952,
        nonTransferable: 15081394176,
        originBandwidth: 1753850000,
        cdnBandwidth: 250550000,
      },
      {
        value: 1053000000,
        name: 'August 19, 2023',
        production: 15707848704,
        staging: 350474240,
        development: 170717184,
        transferable: 1147645952,
        nonTransferable: 15081394176,
        originBandwidth: 1053000000,
        cdnBandwidth: 0,
      },
    ],
    storage: [
      {
        name: 'August 16, 2023',
        value: 16229015552,
        production: 15707832320,
        staging: 350466048,
        development: 170717184,
        transferable: 1147645952,
        nonTransferable: 15081369600,
        fileStorage: 15867879424,
        databaseStorage: 361136128,
      },
      {
        name: 'August 17, 2023',
        value: 186499072,
        production: 0,
        staging: 186499072,
        development: 0,
        transferable: 0,
        nonTransferable: 186499072,
        fileStorage: 184451072,
        databaseStorage: 2048000,
      },
      {
        name: 'August 18, 2023',
        value: 16229040128,
        production: 15707848704,
        staging: 350474240,
        development: 170717184,
        transferable: 1147645952,
        nonTransferable: 15081394176,
        fileStorage: 15867904000,
        databaseStorage: 361136128,
      },
    ],
  },
};

const mockAccountActivity = {
  widget: 'account-activity',
  data: [
    {
      id: 1,
      email: 'test@test.com',
      action: 'Logged in',
      time: '2023-09-12 18:07:37 UTC',
    },
    {
      id: 2,
      email: 'test@test.com',
      action: 'Logged in',
      time: '2023-09-11 17:36:50 UTC',
    },
    {
      id: 3,
      email: 'test@test.com',
      action: 'Logged in',
      time: '2023-09-06 13:18:25 UTC',
    },
    {
      id: 4,
      email: 'test@test.com',
      action:
        'Created transferable install anothasiteystg as a duplicate of anothasiteyo',
      time: '2023-08-30 18:10:57 UTC',
    },
    {
      id: 5,
      email: 'test@test.com',
      action: 'Logged in',
      time: '2023-08-29 17:53:29 UTC',
    },
  ],
};

const mockWordPressCommunity = {
  data: [
    {
      image:
        'https://wpengine.com/wp-content/uploads/2023/09/ACF-logo-new_1200x627.png',
      title: 'Insights from the First-Ever ACF Annual Survey',
      description:
        'Level up your WordPress workflow with best practices and actionable tips from more than 2,000 Advanced Custom Fields users!',
      link:
        'https://wpengine.com/blog/insights-from-the-first-ever-acf-annual-survey/',
    },
    {
      image:
        'https://wpengine.com/wp-content/uploads/2023/06/SEO-AI_1200x627.png',
      title: 'Empowering the Digital Success of Tomorrow, Today: AI and SEO',
      description:
        'Learn about the growing role of artificial intelligence in SEO strategies, including ways you can leverage AI to enhance your SEO efforts!',
      link:
        'https://wpengine.com/blog/ai-and-seo-empowering-the-digital-success-of-tomorrow-today/',
    },
    {
      image:
        'https://wpengine.com/wp-content/uploads/2023/08/dtc-header-final.png',
      title:
        'WP Engine is Sponsoring the 14th Annual Dam That Cancer Fundraiser',
      description:
        'WP Engine is sponsoring Dam That Cancer—the event raises money for mental health care supporting cancer patients and their families.',
      link: 'https://wpengine.com/blog/dam-that-cancer-2023/',
    },
  ],
};

const mockLighthousePerformance = {
  widget: 'lighthouse-performance',
  account: 'account_name',
  data: {
    performanceScores: [
      {
        name: 'josephlee',
        desktop: {
          performance: 94,
          accessibility: 100,
          seo: 60,
          best_practices: 20,
        },
        mobile: {
          performance: 84,
          accessibility: 100,
          seo: 60,
          best_practices: 20,
        },
      },
      {
        name: 'checklistqa081',
        desktop: {
          performance: 90,
          accessibility: 100,
          seo: 60,
          best_practices: 20,
        },
        mobile: {
          performance: 95,
          accessibility: 100,
          seo: 60,
          best_practices: 20,
        },
      },
    ],
    hasPageSpeedBoost: true,
    installCount: 100,
  },
};

const mockLighthousePerformanceEmpty = {
  widget: 'lighthouse-performance',
  account: 'account_name',
  data: {
    performanceScores: [],
    hasPageSpeedBoost: false,
    installCount: 0,
  },
};

const mockTopSearches = {
  widget: 'top-searches',
  account: 'account_name',
  data: {
    hasSmartSearch: true,
    insights: [
      {
        env: 'izaschickens',
        term: 'rooster',
        count: 1009,
      },
      {
        env: 'aronsvalheim',
        term: 'resources',
        count: 507,
      },
      {
        env: 'zachsenv',
        term: 'bloodborne',
        count: 225,
      },
      {
        env: 'erinssite',
        term: 'leader',
        count: 197,
      },
      {
        env: 'hamiltonshu',
        term: 'camping',
        count: 71,
      },
      {
        env: 'nicksweb',
        term: 'speaker',
        count: 9,
      },
    ],
  },
};

const mockLastTwentyFourHours = {
  widget: 'last-twenty-four-hours',
  account: 'account',
  data: {
    monitoringOutagesCount: 2,
    hasGes: false,
    hasSpm: true,
  },
};

const mockThirtyDaySnapshot = {
  widget: 'thirty-day-snapshot',
  data: {
    dailyUsage: {
      billableVisits: [
        {
          bandwidthSource: 'TRAFFIC_METRICS_SOURCE_UNSPECIFIED',
          cdnBandwidth: 0,
          development: 0,
          name: 'Aug 30, 2023',
          nonTransferable: 1271808,
          originBandwidth: 1271808,
          production: 1271808,
          staging: 0,
          transferable: 0,
          value: 10000,
        },
        {
          bandwidthSource: 'TRAFFIC_METRICS_SOURCE_UNSPECIFIED',
          cdnBandwidth: 0,
          development: 0,
          name: 'Aug 31, 2023',
          nonTransferable: 1271808,
          originBandwidth: 1271808,
          production: 1271808,
          staging: 0,
          transferable: 0,
          value: 10000,
        },
      ],
      storage: [
        {
          bandwidthSource: 'TRAFFIC_METRICS_SOURCE_UNSPECIFIED',
          cdnBandwidth: 0,
          development: 0,
          name: 'Aug 30, 2023',
          nonTransferable: 1271808,
          originBandwidth: 1271808,
          production: 1271808,
          staging: 0,
          transferable: 0,
          value: 10000000,
        },
        {
          bandwidthSource: 'TRAFFIC_METRICS_SOURCE_UNSPECIFIED',
          cdnBandwidth: 0,
          development: 0,
          name: 'Aug 31, 2023',
          nonTransferable: 1271808,
          originBandwidth: 1271808,
          production: 1271808,
          staging: 0,
          transferable: 0,
          value: 10000001,
        },
      ],
      bandwidth: [
        {
          bandwidthSource: 'TRAFFIC_METRICS_SOURCE_UNSPECIFIED',
          cdnBandwidth: 0,
          development: 0,
          name: 'Aug 30, 2023',
          nonTransferable: 1271808,
          originBandwidth: 1271808,
          production: 1271808,
          staging: 0,
          transferable: 0,
          value: 10000000,
        },
        {
          bandwidthSource: 'TRAFFIC_METRICS_SOURCE_UNSPECIFIED',
          cdnBandwidth: 0,
          development: 0,
          name: 'Aug 31, 2023',
          nonTransferable: 1271808,
          originBandwidth: 1271808,
          production: 1271808,
          staging: 0,
          transferable: 0,
          value: 20000000,
        },
      ],
    },
    topInstalls: {
      billableVisits: [
        {
          id: 1,
          name: 'testenvironment',
          value: 4,
          environment: 'production',
        },
        {
          id: 2,
          name: 'testenvironment2',
          value: 3,
          environment: 'production',
        },
        {
          id: 3,
          name: 'testenvironment3',
          value: 2,
          environment: 'production',
        },
      ],
      storage: [
        {
          id: 1,
          name: 'testenvironment',
          value: 13287,
          environment: 'production',
        },
        {
          id: 2,
          name: 'testenvironment2',
          value: 12287,
          environment: 'production',
        },
        {
          id: 3,
          name: 'testenvironment3',
          value: 11287,
          environment: 'production',
        },
      ],
      bandwidth: [
        {
          id: 1,
          name: 'testenvironment',
          value: 2761,
          environment: 'production',
        },
        {
          id: 2,
          name: 'testenvironment2',
          value: 2661,
          environment: 'production',
        },
        {
          id: 3,
          name: 'testenvironment3',
          value: 2561,
          environment: 'production',
        },
      ],
    },
    currentValues: {
      billableVisits: 20000,
      storage: 10000001,
      bandwidth: 30000000,
    },
    previousValues: {
      billableVisits: 40000,
      storage: 10000001,
      bandwidth: 15000000,
      under31days: false,
    },
  },
};

const mockThirtyDaySnapshotEmpty = {
  widget: 'thirty-day-snapshot',
  data: {
    dailyUsage: {
      billableVisits: [],
      storage: [],
      bandwidth: [],
    },
    topInstalls: {
      billableVisits: [],
      storage: [],
      bandwidth: [],
    },
    currentValues: {
      billableVisits: 0,
      storage: 0,
      bandwidth: 0,
    },
    previousValues: {
      billableVisits: 0,
      storage: 0,
      bandwidth: 0,
      under31days: false,
    },
  },
};

const mockUpdates = {
  widget: 'updates',
  account: 'account_name',
  data: {
    coreVersion: '10.0.0',
    anyInstallsData: true,
    outdatedInstallData: [
      {
        id: 1320491,
        provider_metadata: {
          account_id: '376716',
          cluster_id: '140255',
          install_name: 'ubssitey',
          account_name: 'esm3ay4vtpbmzl',
          install_id: '381184',
          environment_type: 'production',
        },
        plugins: [
          {
            id: 100,
            slug: 'plugin-1',
            name: 'plugin',
            distribution: 'free',
            active: true,
            version: '1.0.0',
            update: {
              version: '1.0.1',
              released_at: '2020-02-02',
              excluded: false,
              update_id: 0,
            },
          },
          {
            id: 101,
            slug: 'akismet/akismet.php',
            name: 'Akismet',
            distribution: 'free',
            active: true,
            version: '1.0.1',
            update: {
              version: '1.0.2',
              released_at: '2019-09-09',
              excluded: false,
              update_id: 6438,
            },
            updates_enabled: true,
            vulnerabilities: [],
          },
        ],
        themes: [
          {
            id: 200,
            slug: 'twentyfourteen',
            name: 'Twenty Fourteen',
            distribution: 'free',
            active: true,
            version: '1.0.1',
            updates_enabled: true,
          },
        ],
        core: {
          version: '6.4.5',
          update: {
            version: '6.5.5',
          },
          vulnerabilities: [],
        },
        php: {
          version: '2.0.0',
          update: {
            version: '10.0.0',
          },
        },
      },
    ],
  },
};

const mockUpdatesUpToDate = [
  {
    id: 1320491,
    provider_metadata: {
      account_id: '376716',
      cluster_id: '140255',
      install_name: 'ubssitey',
      account_name: 'esm3ay4vtpbmzl',
      install_id: '381184',
      environment_type: 'production',
    },
    plugins: [],
    themes: [],
    core: null,
    php: null,
  },
];

export {
  mockDashboardProps,
  mockLayoutV1,
  mockLayoutV2,
  mockOutdatedInstalls,
  mockPlanOverviewData,
  mockSpm,
  mockTopEnvironments,
  mockSiteHealth,
  mockTrends,
  mockAccountActivity,
  mockWordPressCommunity,
  mockLighthousePerformance,
  mockLighthousePerformanceEmpty,
  mockTopSearches,
  mockLastTwentyFourHours,
  mockThirtyDaySnapshot,
  mockThirtyDaySnapshotEmpty,
  mockUpdates,
  mockUpdatesUpToDate,
};
