import * as React from 'react';
import { Divider, Stack, styled } from '@mui/material';
import { NavItem } from '@wpengine/unicorn/components';
import SystemStatus from 'componentsDir/shared/SystemStatus/SystemStatus';
import { useTopNavContext } from 'componentsDir/TopNav/contexts/TopNavProvider';
import GroupHeading from './GroupHeading';
import SupportChatPin from './SupportChatPin';
import {
  BuilderCommunity,
  HeadlessCommunity,
  WpEngineApi,
  AgencyDirectory,
  ResourceCenter,
  TorqueMagazine,
  Velocitize,
  Blog,
  EventsAndWebinars,
  Newsroom,
  SupportCenter,
  ProductFeedback,
  MySupportTickets,
} from './linkData';

const LinkGroup = styled(Stack)({
  margin: '12px 0',
});

const SupportDropdownContent: React.FC = () => {
  const { allAccountsView } = useTopNavContext();

  return (
    <Stack direction="row" gap="40px" margin="0 12px">
      <LinkGroup>
        <GroupHeading title="Tech resources" />
        {[BuilderCommunity, HeadlessCommunity, WpEngineApi].map(link => (
          <NavItem href={link.href} key={link.text} size="small">
            {link.text}
          </NavItem>
        ))}
      </LinkGroup>
      <LinkGroup>
        <GroupHeading title="Resources" />
        {[AgencyDirectory, ResourceCenter, TorqueMagazine, Velocitize].map(
          link => (
            <NavItem href={link.href} key={link.text} size="small">
              {link.text}
            </NavItem>
          )
        )}
      </LinkGroup>
      <LinkGroup>
        <GroupHeading title="News & events" />
        {[Blog, EventsAndWebinars, Newsroom].map(link => (
          <NavItem href={link.href} key={link.text} size="small">
            {link.text}
          </NavItem>
        ))}
      </LinkGroup>
      <Divider
        flexItem
        orientation="vertical"
        sx={{
          margin: '0 -24px',
        }}
      />
      <LinkGroup>
        {[SupportCenter, ProductFeedback, MySupportTickets].map(link => (
          <NavItem href={link.href} key={link.text} size="small">
            {link.text}
          </NavItem>
        ))}
        <SystemStatus />
        <SupportChatPin disabled={allAccountsView} />
      </LinkGroup>
    </Stack>
  );
};

export default SupportDropdownContent;
