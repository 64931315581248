import React, { useEffect, useState } from 'react';
import { gray600 } from 'design-tokens';
import WpeLabsClient from 'componentsDir/Labs/clients/WpeLabsClient';
import useSessionSettings from 'componentsDir/Labs/hooks/useSessionStorage';
import { Badge, NavItem } from '@wpengine/unicorn/components';
import Toggle from '@wpengine/unicorn/components/Toggle/Toggle';

interface LabsToggleProps {
  csrfToken: string;
  userUuid: string;
}

const LabsToggle = ({ csrfToken, userUuid }: LabsToggleProps): JSX.Element => {
  const [toggled, setToggled] = useState(false);
  const client = React.useMemo(() => new WpeLabsClient(csrfToken), []);
  const sessionSettings = useSessionSettings({ userUuid });

  useEffect(() => {
    const settings = sessionSettings.getSessionSettings();

    if (settings) {
      setToggled(settings.enabled);
    } else {
      client.getLabs().then(d => {
        setToggled(d);
        sessionSettings.setSessionSettings(d);
      });
    }
  }, []);

  const handleClick = () => {
    if (toggled) {
      client.disableLabs().then(res => {
        setToggled(res.data);
        sessionSettings.setSessionSettings(res.data);
      });
    } else {
      client.enableLabs().then(res => {
        setToggled(res.data);
        sessionSettings.setSessionSettings(res.data);
      });
    }
  };

  return (
    <NavItem
      onClick={handleClick}
      size="small"
      sx={theme => ({
        width: '100%',
        '.MuiSwitch-switchBase': {
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary[90],
          },
        },
        '&:hover': {
          '.MuiSwitch-track': {
            backgroundColor: gray600,
          },
          '.Mui-checked + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary[110],
          },
        },
        '&& .Mui-checked:hover + .MuiSwitch-track': {
          backgroundColor: theme.palette.primary[110],
        },
      })}
    >
      <Toggle
        checked={toggled}
        sx={{
          margin: 0,
        }}
      />
      WP Engine Labs
      <Badge label="Beta" color="info" size="small" />
    </NavItem>
  );
};

export default LabsToggle;
