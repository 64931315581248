import { customResponse } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import ENDPOINTS from 'componentsDir/sites/components/AddSite/constants/endpoints';

const handlers = [
  rest.get(`*${ENDPOINTS.SITES_ENV_NAME_SUGGESTIONS}*`, (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({
        names: {
          production: 'mctest1',
          staging: 'mcteststg',
          development: 'mctestdev',
        },
      })
    );
  }),
];

export default handlers;
