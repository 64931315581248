import { InstallContextProps } from 'componentsDir/install/EcommercePayments/contexts/InstallContextProvider';
import {
  GatewayStatusList,
  StoreGatewayStatusList,
  StoreInvitationStatusList,
  StoreStatusList,
  StoreUpdateStatusList,
} from 'componentsDir/install/EcommercePayments/services/enums';

export const templateInstallContext: InstallContextProps = {
  id: '595bbd59-c842-4550-9d64-1a3eb8ab1499',
  name: 'InstallName',
  siteId: 1234,
};

export const templateStore: PaymentsStoreTemplate = {
  createdAt: '0001-01-01T00:00:00Z',
  deletedAt: null,
  deleted: false,
  installUUID: templateInstallContext.id,
  name: templateInstallContext.name,
  updatedAt: '0001-01-01T00:00:00Z',
  uuid: '7ed27cf3-2c1c-492c-84e0-425dcf413391',
  invitationStatus: null,
  termsStatus: {
    stripe: true,
  },
  status: StoreStatusList.READY,
  updateStatus: StoreUpdateStatusList.UPDATED,
  storeGatewayStatus: StoreGatewayStatusList.READY,
  gatewayStatus: GatewayStatusList.READY,
};

export const templateStoreNotOnboarded: PaymentsStoreTemplate = {
  ...templateStore,
  status: StoreStatusList.READY,
  updateStatus: StoreUpdateStatusList.UPDATED,
  storeGatewayStatus: null,
  gatewayStatus: null,
};

export const templateTlsError: PaymentsStoreTemplate = {
  ...templateStore,
  status: StoreStatusList.SSL_TLS_ERROR,
  updateStatus: StoreUpdateStatusList.UPDATED,
  storeGatewayStatus: null,
  gatewayStatus: null,
};

export const templateStoreMerchantInvitation: PaymentsStoreTemplate = {
  ...templateStoreNotOnboarded,
  invitationStatus: StoreInvitationStatusList.SENT,
};

export const templateStoreNotAgreed: PaymentsStoreTemplate = {
  ...templateStore,
  termsStatus: {
    stripe: false,
  },
};

export const templateFindStore: PaymentsFindStoreTemplate = {
  results: [templateStore],
};

export const templateGetPaymentsTerms: PaymentsAgreement = {
  stripe: false,
};

export const templateGetPaymentsTermsAgreed: PaymentsAgreement = {
  stripe: true,
};

export const templateMerchantInvitation: PaymentsMerchantInvitationTemplate = {
  token: '861a3e72798029ac8fbb580c541854c3',
  expires_at: '2022-07-22T18:33:48.163204Z',
};

export const retrievePaymentMethodConfiguration: RetrievePaymentMethodConfigurationResponseTemplate = {
  configuration: {
    apple_pay: {
      hasPermission: true,
      enabled: true,
    },
    bancontact: {
      hasPermission: true,
      enabled: false,
    },
    card: {
      hasPermission: true,
      enabled: true,
    },
    eps: {
      hasPermission: true,
      enabled: true,
    },
    giropay: {
      hasPermission: true,
      enabled: false,
    },
    google_pay: {
      hasPermission: true,
      enabled: true,
    },
    klarna: {
      hasPermission: true,
      enabled: true,
    },
    zip: {
      hasPermission: true,
      enabled: true,
    },
    p24: {
      hasPermission: true,
      enabled: false,
    },
    sepa_debit: {
      hasPermission: true,
      enabled: true,
    },
    link: {
      hasPermission: true,
      enabled: false,
    },
    affirm: {
      hasPermission: true,
      enabled: true,
    },
    afterpay_clearpay: {
      hasPermission: true,
      enabled: true,
    },
  },
};

export const templateOnboardingUrl: PaymentOnboardingUrlTemplate = {
  url:
    'https://connect.stripe.com/oauth/authorize?client_id=ca_LiYenqdnLCHnGOO35JdsgxwP62xEk7cl&amp;response_type=code&amp;scope=read_write&amp;state=d10104786f048b6e1e85abf268482020',
};

export const templateOnboardingStatuses: PaymentsOnboardingStatusTemplate = {
  pluginInstalled: false,
  pluginEnabled: false,
  hasPaymentAccount: false,
  paymentsBeingSetup: false,
  paymentsReady: false,
  payoutsReady: false,
  invitationExpired: false,
  invitationSent: false,
  gatewayStatus: null,
  storeGatewayStatus: null,
  pluginUpdateRequired: false,
  status: StoreStatusList.PLUGIN_NOT_FOUND,
  pluginUpdateAvailable: null,
};

export const templateOnboardingStatusesPluginInstalled: PaymentsOnboardingStatusTemplate = {
  ...templateOnboardingStatuses,
  status: StoreStatusList.READY,
  pluginInstalled: true,
};

export const templateOnboardingStatusesPluginEnabled: PaymentsOnboardingStatusTemplate = {
  ...templateOnboardingStatusesPluginInstalled,
  pluginEnabled: true,
  status: StoreStatusList.READY,
};

export const templateOnboardingStatusesStoreUnreachable: PaymentsOnboardingStatusTemplate = {
  ...templateOnboardingStatuses,
  status: StoreStatusList.UNREACHABLE,
};

export const templateOnboardingStatusInvitation: PaymentsOnboardingStatusTemplate = {
  ...templateOnboardingStatusesPluginEnabled,
  invitationSent: true,
};

// happy path
export const templateOnboardingStatusesConfigured: PaymentsOnboardingStatusTemplate = {
  pluginEnabled: true,
  pluginInstalled: true,
  hasPaymentAccount: true,
  paymentsBeingSetup: false,
  gatewayStatus: GatewayStatusList.READY,
  storeGatewayStatus: StoreGatewayStatusList.READY,
  paymentsReady: true,
  payoutsReady: true,
  invitationExpired: true,
  invitationSent: true,
  pluginUpdateRequired: false,
  status: StoreStatusList.READY,
  pluginUpdateAvailable: false,
};

export const templatePaymentsMatchedConfigs: PaymentsMatchedConfigsTemplate = {
  store_gateway_uuid: 'f1f69f51-642d-4a3c-ba0b-d058a3d75b7a',
  stores: [
    {
      gateway_uuid: 'f1f69f51-642d-4a3c-ba0b-d058a3d75b7a',
      gateway_type: 'stripe',
      site_url: 'wpengine.com',
      store_status: 'string',
      store_name: 'store name 1',
      store_gateway_status: 'string',
      store_uuid: 'd3db7241-38db-420c-85f7-0553e0745adc',
      install_uuid: '1100e7c8-4417-4547-9acc-f6184c15338e',
    },
  ],
};

export const templatePaymentsMatchedConfigsMultiple: PaymentsMatchedConfigsTemplate = {
  store_gateway_uuid: 'f1f69f51-642d-4a3c-ba0b-d058a3d75b7a',
  stores: [
    {
      gateway_uuid: 'f1f69f51-642d-4a3c-ba0b-d058a3d75b7a',
      gateway_type: 'stripe',
      site_url: 'wpengine.com',
      store_status: 'string',
      store_name: 'shustertest',
      store_gateway_status: 'string',
      store_uuid: 'd3db7241-38db-420c-85f7-0553e0745adc',
      install_uuid: '1100e7c8-4417-4547-9acc-f6184c15338e',
    },
    {
      gateway_uuid: null,
      gateway_type: 'stripe',
      site_url: 'google.com',
      store_status: 'string',
      store_name: 'shustertest_staging',
      store_gateway_status: 'string',
      store_uuid: 'da0e9a0e-86ec-433b-9ad3-9cade77cb747',
      install_uuid: 'a3bf4ca8-1cf5-40c0-9a47-24cd8fdd8fd9',
    },
  ],
};

export const templatePaymentsOwnership: PaymentsOwnershipTemplate = {
  storeGatewayUUID: 'f1f69f51-642d-4a3c-ba0b-d058a3d75b7a',
  stores: [
    {
      siteUrl: 'wpengine.com',
      storeStatus: StoreStatusList.READY,
      storeName: 'store name 1',
      storeUUID: 'd3db7241-38db-420c-85f7-0553e0745adc',
      installUUID: '1100e7c8-4417-4547-9acc-f6184c15338e',
    },
  ],
};

export const templatePaymentsOwnershipMultiple: PaymentsOwnershipTemplate = {
  storeGatewayUUID: 'f1f69f51-642d-4a3c-ba0b-d058a3d75b7a',
  stores: [
    {
      siteUrl: 'wpengine.com',
      storeStatus: StoreStatusList.READY,
      storeName: 'store name 1',
      storeUUID: 'd3db7241-38db-420c-85f7-0553e0745adc',
      installUUID: '1100e7c8-4417-4547-9acc-f6184c15338e',
    },
    {
      siteUrl: 'wpenginedev.com',
      storeStatus: StoreStatusList.READY,
      storeName: 'store name 2',
      storeUUID: '7d7b97f6-d67a-41dd-9739-8fe76535df36',
      installUUID: 'e1b3c36b-e107-453f-9b92-1bc8d615ac58',
    },
  ],
};

const allPaymentMethods: string[] = [
  'sepa_debit',
  'card',
  'apple_pay',
  'google_pay',
  'eps',
  'bancontact',
  'link',
  'giropay',
  'boleto',
  'p24',
  'klarna',
  'afterpay_clearpay',
  'affirm',
  'zip',
];

export const paymentMethodRequirements: PaymentOptionRequirements = {
  giropay: { requires_currency: ['EUR'] },
  p24: { requires_currency: ['EUR', 'PLN'] },
  bancontact: { requires_currency: ['USD'] },
};

export const templatePaymentAllOptionsAvailable: PaymentsStripeOptionsTemplate = {
  testModeEnabled: true,
  expressCheckoutOptions: {
    express_checkout_enabled: false,
    stripe_link_available: true,
    stripe_link_enabled: false,
    express_checkout_button_locations: [],
  },
  updatedCheckoutExperienceEnabled: true,
  baseCurrency: 'USD',
  paymentMethodRequirements,
  acceptedPaymentMethods: ['card'],
  availablePaymentMethods: allPaymentMethods,
  allPaymentMethods,
  stripeCheckoutEnabled: true,
  storeStatus: StoreStatusList.READY,
  ownership: templatePaymentsOwnership,
};

export const templateStripeCheckout: PaymentsStripeCheckoutOptionsTemplate = {
  stripe_checkout_enabled: true,
  coupons_enabled: true,
  shipping_enabled: true,
  tax_support_enabled: true,
  phone_number_enabled: true,
  policies_enabled: true,
  policies_text: `This sentence has five words. Here are five more words.
Five-word sentences are fine. But several together become monotonous.
Listen to what is happening. The writing is getting boring.
The sound of it drones. It’s like a stuck record. The ear demands some variety. Now listen. I vary the sentence length, and I create music. Music. The writing sings. It has a pleasant rhythm, a lilt, a harmony. I use short sentences. And I use sentences of medium length. And sometimes, when I am certain the reader is rested, I will engage him with a sentence of considerable length, a sentence that burns with energy and builds with all the impetus of a crescendo, the roll of the drums, the crash of the cymbals–sounds that say listen to this, it is important.
<script> window.location = 'https://wpengine.com;' </script>`,
  no_of_shipping_methods: 0,
};

export const templatePaymentOptionUnavailable: PaymentsStripeOptionsTemplate = {
  testModeEnabled: true,
  expressCheckoutOptions: {
    express_checkout_enabled: true,
    express_checkout_button_locations: ['product', 'cart', 'checkout'],
    stripe_link_available: false,
    stripe_link_enabled: false,
  },
  baseCurrency: 'USD',
  paymentMethodRequirements,
  updatedCheckoutExperienceEnabled: true,
  acceptedPaymentMethods: ['card'],
  availablePaymentMethods: ['p24'],
  allPaymentMethods,
  stripeCheckoutEnabled: null,
  storeStatus: StoreStatusList.READY,
  ownership: templatePaymentsOwnership,
};

export const templatePaymentOptionUnsupported: PaymentsStripeOptionsTemplate = {
  ...templatePaymentAllOptionsAvailable,
  acceptedPaymentMethods: null,
  availablePaymentMethods: null,
  allPaymentMethods: null,
  stripeCheckoutEnabled: null,
  expressCheckoutOptions: {
    express_checkout_enabled: null,
    express_checkout_button_locations: null,
    stripe_link_available: null,
    stripe_link_enabled: null,
  },
};

export const templatePaymentTestModeDisabled: PaymentsStripeOptionsTemplate = {
  ...templatePaymentAllOptionsAvailable,
  testModeEnabled: false,
};

export const templatePaymentGateway: PaymentsGatewayTemplate = {
  store_gateway_uuid: '09c278a0-195a-4786-afc8-4cb694d3acfd',
  store_uuid: '7ed27cf3-2c1c-492c-84e0-425dcf413391',
  gateway_uuid: 'e644b2ed-b626-47d8-9662-7c7ac967b111',
  name: 'Stripe',
  status: StoreGatewayStatusList.READY,
  account_name: 'Acme Corporation',
  account_id: 'acct_1NL54dAhio8tQUz7',
};

export const templatePaymentGatewayPermissions: PaymentsGatewayPermissionsTemplate[] = allPaymentMethods.map(
  allPaymentMethod => ({
    id: `${allPaymentMethod}_payments`,
    requested: true,
    status: 'active',
  })
);

export const templatePaymentGatewayPermissionsResponse: PaymentsGatewayPermissionsResponseTemplate = {
  permissions: templatePaymentGatewayPermissions,
};

export const templatePaymentGatewayPermissionsInactive: PaymentsGatewayPermissionsTemplate[] = allPaymentMethods.map(
  allPaymentMethod => ({
    id: `${allPaymentMethod}_payments`,
    requested: true,
    status: 'inactive',
  })
);

export const templatePaymentGatewayPermissionsInactiveResponse: PaymentsGatewayPermissionsResponseTemplate = {
  permissions: templatePaymentGatewayPermissionsInactive,
};

export const templatePaymentStoreGateway: PaymentsStoreGatewayTemplate = {
  uuid: '09c278a0-195a-4786-afc8-4cb694d3acfd',
  store_uuid: '7ed27cf3-2c1c-492c-84e0-425dcf413391',
  status: StoreGatewayStatusList.READY,
  store_name: 'InstallName Store',
  site_url: 'ecommerce_payments.wpengine.com',
};

export const templatePaymentStoreGatewaySiteFailed: PaymentsStoreGatewayTemplate = {
  ...templatePaymentStoreGateway,
  status: StoreGatewayStatusList.PUSH_FAILED,
};
export const templatePaymentGatewayPermissionsInactiveFiltered: PaymentsGatewayPermissionsTemplate[] = allPaymentMethods
  .filter(pm => !['giropay', 'klarna'].includes(pm))
  .map(allPaymentMethod => ({
    id: `${allPaymentMethod}_payments`,
    requested: true,
    status: 'inactive',
  }));
export const templatePaymentGatewayStatusResp: PaymentsGatewayStatusResponseTemplate = {
  uuid: 'e644b2ed-b626-47d8-9662-7c7ac967b111',
  status: GatewayStatusList.READY,
  store_gateways: [templatePaymentStoreGateway],
};

export const templatePaymentGatewayStatus: PaymentsGatewayStatusTemplate = {
  ...templatePaymentGatewayStatusResp,
  thisStoreGateway: templatePaymentStoreGateway,
};

export const templatePaymentGatewayStatusSiteFailed: PaymentsGatewayStatusResponseTemplate = {
  ...templatePaymentGatewayStatusResp,
  status: GatewayStatusList.PUSH_FAILED,
  store_gateways: [
    templatePaymentStoreGatewaySiteFailed,
    {
      ...templatePaymentStoreGatewaySiteFailed,
      site_url: 'ecommerce_payments2.wpengine.com',
      store_uuid: '19efe6d6-9ba4-4133-8d57-8490a6154ef6',
      store_name: 'InstallName2 Store',
    },
    {
      ...templatePaymentStoreGatewaySiteFailed,
      site_url: 'ecommerce_payments3.wpengine.com',
      store_uuid: 'ac88d6f8-e125-485a-8cef-b0c8b3ed8e17',
      store_name: 'InstallName3 Store',
    },
    {
      ...templatePaymentStoreGatewaySiteFailed,
      site_url: '48425cff-099a-4be6-8820-3efc9b7481e2',
      store_uuid: 'e8c618bb-15e0-4b39-8d2d-269a49c65a9f',
      store_name: 'InstallName4 Store',
    },
    {
      ...templatePaymentStoreGatewaySiteFailed,
      site_url: '1ecbdb35-db8f-432b-acbf-b1b74bbbeff6',
      store_uuid: 'bdcc2a2d-2a13-49fe-90b6-e3e1b59d6db8',
      store_name: 'InstallName5 Store',
    },
  ],
};

export const templatePaymentGatewayStatusPushPending: PaymentsGatewayStatusResponseTemplate = {
  ...templatePaymentGatewayStatusResp,
  status: GatewayStatusList.PUSH_PENDING,
  store_gateways: [
    {
      ...templatePaymentStoreGateway,
      status: StoreGatewayStatusList.PUSH_PENDING,
    },
  ],
};

export const templatePaymentGetCustomerStoresNone: PaymentsGetCustomerStoresResponse = {
  results: [],
};
