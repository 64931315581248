import { customResponse } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import endpoints from '../constants/endpoints';
import validateJson from './data/sitesTransfer';

const handlers = [
  rest.post(`*${endpoints.SITES_TRANSFER}`, (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.delay(0),
      ctx.json(validateJson)
    );
  }),
];

export default handlers;
