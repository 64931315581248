import { rest } from 'msw';

const handlers = [
  rest.patch('*/ftu_survey', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: true,
        data: req.body,
      })
    );
  }),
];

export default handlers;
