import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const SwitchIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="switch-icon" ref={ref} {...props}>
        <path d="M16.2929 2.29289C15.9024 2.68342 15.9024 3.31658 16.2929 3.70711L18.5858 6H3C2.44772 6 2 6.44772 2 7V10C2 10.5523 2.44772 11 3 11C3.55228 11 4 10.5523 4 10V8H18.5858L16.2929 10.2929C15.9024 10.6834 15.9024 11.3166 16.2929 11.7071C16.6834 12.0976 17.3166 12.0976 17.7071 11.7071L21.7071 7.70711C22.0976 7.31658 22.0976 6.68342 21.7071 6.29289L17.7071 2.29289C17.3166 1.90237 16.6834 1.90237 16.2929 2.29289Z" />
        <path d="M7.70711 13.7071C8.09763 13.3166 8.09763 12.6834 7.70711 12.2929C7.31658 11.9024 6.68342 11.9024 6.29289 12.2929L2.29289 16.2929C1.90237 16.6834 1.90237 17.3166 2.29289 17.7071L6.29289 21.7071C6.68342 22.0976 7.31658 22.0976 7.70711 21.7071C8.09763 21.3166 8.09763 20.6834 7.70711 20.2929L5.41421 18H21C21.5523 18 22 17.5523 22 17V14C22 13.4477 21.5523 13 21 13C20.4477 13 20 13.4477 20 14V16H5.41421L7.70711 13.7071Z" />
      </SvgTemplate>
    );
  }
);
export default SwitchIcon;
