import { rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';
import mockDomainStatusesData from '../../../admin/PortalDevTools/mocks/data/mockDomainStatusesData';

const domainStatusesHandler = [
  rest.get('/admin/domain_networks.json', (req, res, ctx) => {
    const domainName = req.url.searchParams.get('search[name]');

    const domainStatuses = mockDomainStatusesData[domainName];

    if (!domainStatuses) {
      return customResponse(
        ctx.status(404),
        ctx.json({ message: 'Domain not found' })
      );
    }

    const response = {
      domain_statuses: domainStatuses,
    };

    return customResponse(ctx.status(200), ctx.json(response));
  }),
];

export default domainStatusesHandler;
