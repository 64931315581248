export const LOG_ACTIONS = {
  ONBOARDING_DISMISSED: 'sandbox_site.onboarding_dismissed',
};

// Ensure MSW_PARAMS keys match response keys in Onboarding::SandboxSitesController
export const MSW_PARAMS = {
  FIRST_SANDBOX_CREATED: 'firstSandboxCreated',
  ONBOARDING_DISMISSED: 'onboardingDismissed',
};

export const QUERY_KEYS = {
  ONBOARDING_FETCH: 'sandbox-sites-onboarding-fetch',
  ONBOARDING_POST_LOG: 'sandbox-sites-onboarding-post-log',
};
