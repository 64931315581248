import { rest } from 'msw';
import { portal_dev_tools_install_config_hash_path } from 'railsRoutes';
import { customResponse } from 'mocksDir/utils/browserUtils';
import mockInstallConfigHashByInstallName from '../data/mockInstallConfigData';

const installConfigHashHandler = [
  rest.get(portal_dev_tools_install_config_hash_path(), (req, res, ctx) => {
    const installName = req.url.searchParams.get('install_name');

    if (installName && installName in mockInstallConfigHashByInstallName) {
      const installConfig = mockInstallConfigHashByInstallName[installName];

      if (installConfig === null) {
        return customResponse(
          ctx.status(404),
          ctx.json({ message: 'Error fetching config hash' })
        );
      }

      const response = {
        installs_config_hash: installConfig,
      };

      return customResponse(ctx.status(200), ctx.json(response));
    }
    return customResponse(
      ctx.status(404),
      ctx.json({ message: 'Install not found' })
    );
  }),
];

export default installConfigHashHandler;
