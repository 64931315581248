import React, { lazy, Suspense } from 'react';
import { lookupAccounts } from 'commonsDir/helpers/lookupAccounts';
import ErrorBoundary from 'componentsDir/shared/Rollbar/ErrorBoundary';
import { Provider } from '@rollbar/react';
import { getCurrentAccountUUID } from 'commonsDir/helpers/cookies';
import 'react-hot-loader';
// eslint-disable-next-line my-wpe/no-direct-queryclient
import { QueryClient, QueryClientProvider } from 'react-query';

// Components to render on every page can be part of the main pack
import _SideNav from '../components/SideNav/SideNav';
import _TopNav from '../components/TopNav/TopNav';
import _Footer from '../components/footer';
import _Impersonation from '../components/Impersonation';

/* eslint-disable-next-line global-require */
window.ReactOnRails = window.ReactOnRails || require('react-on-rails').default;

const currentAccountUUID = getCurrentAccountUUID();
const rollbarConfig = {
  accessToken: process.env.ROLLBAR_CLIENT_TOKEN,
  environment: process.env.NODE_ENV,
  payload: {
    person: {
      id: currentAccountUUID,
    },
  },
  checkIgnore: (isUncaught, args) => {
    if (args?.[0]?.name === 'ChunkLoadError') {
      return true;
    }

    return isUncaught;
  },
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 20_000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const withProviders = Component => props => {
  if (!rollbarConfig.payload.person.username) {
    rollbarConfig.payload.person.username = lookupAccounts('uuid', [
      currentAccountUUID,
    ])?.[0]?.nickname;
  }

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={null}>
            <Component {...props} />
          </Suspense>
        </QueryClientProvider>
      </ErrorBoundary>
    </Provider>
  );
};

const SideNav = withProviders(_SideNav);
const TopNav = withProviders(_TopNav);
const Footer = withProviders(_Footer);
const Impersonation = withProviders(_Impersonation);

// Components on specific pages should be async loaded
/* GENERATOR START */

const ApplicationPerformanceProductDetailPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "application-performance-product-detail-page" */ '../components/marketing/ApplicationPerformanceProductDetailPage'
  )
);
const ApplicationPerformanceProductDetailPage = withProviders(
  ApplicationPerformanceProductDetailPageLazy
);

const SmartPluginManagerProductDetailPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "smart-plugin-manager-product-detail-page" */ '../components/marketing/SmartPluginManagerProductDetailPage'
  )
);
const SmartPluginManagerProductDetailPage = withProviders(
  SmartPluginManagerProductDetailPageLazy
);

const ManageSiteTagsBaseLazy = lazy(() =>
  import(
    /* webpackChunkName: "manage-site-tags" */ '../components/ManageSiteTagsPage/ManageSiteTagsBase'
  )
);
const ManageSiteTagsBase = withProviders(ManageSiteTagsBaseLazy);

const DynamicPluginLoadingLazy = lazy(() =>
  import(
    /* webpackChunkName: "dynamic-plugin-loading" */ '../components/DynamicPluginLoading/DynamicPluginLoading'
  )
);
const DynamicPluginLoading = withProviders(DynamicPluginLoadingLazy);

const ChatDisabledLazy = lazy(() =>
  import(
    /* webpackChunkName: "chat-disabled" */ '../components/ChatDisabled/ChatDisabled'
  )
);
const ChatDisabled = withProviders(ChatDisabledLazy);

const ManagedMigrationSuccessPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "managed-migration-success-page" */ '../components/ManagedMigrationPage/ManagedMigrationSuccessPage'
  )
);
const ManagedMigrationSuccessPage = withProviders(
  ManagedMigrationSuccessPageLazy
);
const AllAccountsSideNavLazy = lazy(() =>
  import(
    /* webpackChunkName: "all-accounts-side-nav" */ '../components/SideNav/AllAccountsSideNav/AllAccountsSideNav'
  )
);
const AllAccountsSideNav = withProviders(AllAccountsSideNavLazy);

const GenesisProOnlySideNavLazy = lazy(() =>
  import(
    /* webpackChunkName: "gen-pro-only-side-nav" */ '../components/SideNav/GenesisProOnlySideNav/GenesisProOnlySideNav'
  )
);
const GenesisProOnlySideNav = withProviders(GenesisProOnlySideNavLazy);

const TrafficInsightsLazy = lazy(() =>
  import(
    /* webpackChunkName: "traffic-insights" */ '../components/TrafficInsights/TrafficInsightsPage'
  )
);
const TrafficInsights = withProviders(TrafficInsightsLazy);

const Utilities2Lazy = lazy(() =>
  import(
    /* webpackChunkName: "utilities2" */ '../components/Utilities2/Utilities2'
  )
);
const Utilities2 = withProviders(Utilities2Lazy);

const ManagedMigrationPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "managed-migration-page" */ '../components/ManagedMigrationPage/ManagedMigrationPage'
  )
);
const ManagedMigrationPage = withProviders(ManagedMigrationPageLazy);

const PerformanceLazy = lazy(() =>
  import(
    /* webpackChunkName: "performance" */ 'componentsDir/install/Performance/PerformanceBase'
  )
);
const Performance = withProviders(PerformanceLazy);

const PortalDevToolsLazy = lazy(() =>
  import(
    /* webpackChunkName: "portal-dev-tools" */ '../components/admin/PortalDevTools/PortalDevTools'
  )
);
const PortalDevTools = withProviders(PortalDevToolsLazy);

const EcommerceSlowQueryLogsLazy = lazy(() =>
  import(
    /* webpackChunkName: "ecommerce-slow-query-logs" */ '../components/EcommerceSlowQueryLogs/EcommerceSlowQueryLogs'
  )
);
const EcommerceSlowQueryLogs = withProviders(EcommerceSlowQueryLogsLazy);

const SiteTransferLazy = lazy(() =>
  import(
    /* webpackChunkName: "site-transfer" */ '../components/SiteTransfer/SiteTransferBase'
  )
);
const SiteTransfer = withProviders(SiteTransferLazy);

const SmartSearchInsightsLazy = lazy(() =>
  import(
    /* webpackChunkName: "smart-search-insights" */ '../components/install/SmartSearchInsights/SmartSearchInsightsBase'
  )
);
const SmartSearchInsights = withProviders(SmartSearchInsightsLazy);

const SmartSearchLazy = lazy(() =>
  import(
    /* webpackChunkName: "smart-search" */ '../components/products/smart_search/SmartSearch'
  )
);
const SmartSearch = withProviders(SmartSearchLazy);

const AccountNicknameLazy = lazy(() =>
  import(
    /* webpackChunkName: "account-nickname" */ '../components/AccountNickname/AccountNickname'
  )
);
const AccountNickname = withProviders(AccountNicknameLazy);

const FlywheelUserDashLazy = lazy(() =>
  import(
    /* webpackChunkName: "flywheel-user-dash" */ '../components/FlywheelUserDash/FlywheelUserDash'
  )
);
const FlywheelUserDash = withProviders(FlywheelUserDashLazy);

const PageSpeedBoostLazy = lazy(() =>
  import(
    /* webpackChunkName: "page-speed-boost" */ '../components/PageSpeedBoost/PageSpeedBoostBase'
  )
);
const PageSpeedBoost = withProviders(PageSpeedBoostLazy);

const PageSpeedBoostProductDetailPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "page-speed-boost-product-detail-page" */ 'componentsDir/marketing/PageSpeedBoostProductDetailPage'
  )
);
const PageSpeedBoostProductDetailPage = withProviders(
  PageSpeedBoostProductDetailPageLazy
);

const UsersSummaryLazy = lazy(() =>
  import(
    /* webpackChunkName: "users-summary" */ '../components/UsersSummary/UsersSummaryPageBase'
  )
);
const UsersSummaryPage = withProviders(UsersSummaryLazy);

const PluginsAndThemesLazy = lazy(() =>
  import(
    /* webpackChunkName: "plugins-and-themes" */ '../components/install/PluginsAndThemes/PluginsAndThemesBase'
  )
);
const PluginsAndThemes = withProviders(PluginsAndThemesLazy);

const AllSitesBaseLazy = lazy(() =>
  import(
    /* webpackChunkName: "all-sites" */ 'componentsDir/AllSites/AllSitesBase'
  )
);
const AllSitesBase = withProviders(AllSitesBaseLazy);

const SupportPageBaseLazy = lazy(() =>
  import(
    /* webpackChunkName: "support-page" */ 'componentsDir/Support/SupportPageBase'
  )
);
const SupportPageBase = withProviders(SupportPageBaseLazy);

const UserPersonasLazy = lazy(() =>
  import(
    /* webpackChunkName: "user-personas" */ '../components/UserPersonas/UserPersonas'
  )
);
const UserPersonas = withProviders(UserPersonasLazy);

const EsmPurchaseAddonsBaseLazy = lazy(() =>
  import(
    /* webpackChunkName: "purchase-addons-page" */ '../components/EsmPurchaseAddonsPage/EsmPurchaseAddonsBase'
  )
);
const EsmPurchaseAddonsBase = withProviders(EsmPurchaseAddonsBaseLazy);

const DatacentersPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "datacenters-page" */ '../components/Datacenters/DatacentersPage'
  )
);
const DatacentersPage = withProviders(DatacentersPageLazy);

const DiscoveryHubLazy = lazy(() =>
  import(
    /* webpackChunkName: "terms-and-conditions" */ '../components/DiscoveryHub/DiscoveryHub'
  )
);
const DiscoveryHub = withProviders(DiscoveryHubLazy);

const TermsAndConditionsLazy = lazy(() =>
  import(
    /* webpackChunkName: "terms-and-conditions" */ '../components/TermsAndConditions/TermsAndConditions'
  )
);
const TermsAndConditions = withProviders(TermsAndConditionsLazy);

const SalesAssistedLandingLazy = lazy(() =>
  import(
    /* webpackChunkName: "sales-assisted-landing" */ '../components/SalesAssistedLanding/SalesAssistedLanding'
  )
);
const SalesAssistedLanding = withProviders(SalesAssistedLandingLazy);

const NotificationsLazy = lazy(() =>
  import(
    /* webpackChunkName: "notifications" */ '../components/Notifications/NotificationsCenter'
  )
);
const NotificationsCenter = withProviders(NotificationsLazy);

const DomainNetworksLazy = lazy(() =>
  import(
    /* webpackChunkName: "domain-networks" */ '../components/DomainNetworks/DomainNetworks'
  )
);
const DomainNetworks = withProviders(DomainNetworksLazy);

const AdvancedNetworkLandingPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "advanced-network-landing-page" */ '../components/AdvancedNetworkLandingPage/LandingPageWrapper'
  )
);
const AdvancedNetworkLandingPage = withProviders(
  AdvancedNetworkLandingPageLazy
);

const EcommercePerformancePageLazy = lazy(() =>
  import(
    /* webpackChunkName: "ecomm-performance-page" */ '../components/EcommercePerformance/EcommercePerformancePage'
  )
);
const EcommercePerformancePage = withProviders(EcommercePerformancePageLazy);

const DomainsRefreshLazy = lazy(() =>
  import(
    /* webpackChunkName: "domains-v2" */ '../components/DomainsRefresh/DomainsRefresh'
  )
);
const DomainsRefresh = withProviders(DomainsRefreshLazy);

const EcommerceUpgradeBannerLazy = lazy(() =>
  import(
    /* webpackChunkName: "ecommerce-upgrade-banner" */ '../components/shared/EcommerceUpgradeBanner/EcommerceUpgradeBanner'
  )
);
const EcommerceUpgradeBanner = withProviders(EcommerceUpgradeBannerLazy);

const EnvironmentOverviewPageContentLazy = lazy(() =>
  import(
    /* webpackChunkName: "environment-overview-page-content" */ '../components/install/Overview/components/EnvironmentOverviewPageContent'
  )
);
const EnvironmentOverviewPageContent = withProviders(
  EnvironmentOverviewPageContentLazy
);

const QueryClientEcommerceUpgradeBannerLazy = lazy(() =>
  import(
    /* webpackChunkName: "query-client-ecommerce-upgrade-banner" */ '../components/shared/EcommerceUpgradeBanner/QueryClientEcommerceUpgradeBanner'
  )
);
const QueryClientEcommerceUpgradeBanner = withProviders(
  QueryClientEcommerceUpgradeBannerLazy
);

const EverCacheEnableBannerLazy = lazy(() =>
  import(
    /* webpackChunkName: "evercache-upgrade-banner" */ '../components/shared/EverCacheEnableBanner/EverCacheEnableBanner'
  )
);
const EverCacheEnableBanner = withProviders(EverCacheEnableBannerLazy);

const EnvironmentDashboardSideNavLazy = lazy(() =>
  import(
    /* webpackChunkName: "environment-dashboard-sidenav" */ '../components/install/EnvironmentDashboardSideNav/EnvironmentDashboardSideNav'
  )
);
const EnvironmentDashboardSideNav = withProviders(
  EnvironmentDashboardSideNavLazy
);

const SshKeysLazy = lazy(() =>
  import(/* webpackChunkName: "sshkeys-page" */ '../components/ssh/SshKeysPage')
);
const SshKeysPage = withProviders(SshKeysLazy);

const CwvReportsLazy = lazy(() =>
  import(
    /* webpackChunkName: "cwv-reports" */ '../components/CwvReports/CwvReports'
  )
);
const CwvReports = withProviders(CwvReportsLazy);

const LogsOverviewLazy = lazy(() =>
  import(
    /* webpackChunkName: "logs-overview" */ '../components/LogsOverview/LogsOverview'
  )
);
const LogsOverview = withProviders(LogsOverviewLazy);

const SiteMonitoringAppLazy = lazy(() =>
  import(
    /* webpackChunkName: "site-monitoring-page" */ '../components/SiteMonitoring'
  )
);
const SiteMonitoringApp = withProviders(SiteMonitoringAppLazy);

const EcommerceLazy = lazy(() =>
  import(
    /* webpackChunkName: "ecommerce" */ '../components/Ecommerce/Ecommerce'
  )
);
const Ecommerce = withProviders(EcommerceLazy);

const EcommercePaymentsLazy = lazy(() =>
  import(
    /* webpackChunkName: "ecommerce-payments" */ '../components/install/EcommercePayments/EcommercePayments'
  )
);
const EcommercePayments = withProviders(EcommercePaymentsLazy);

const QueryClientStripeConnectUpgradeBannerLazy = lazy(() =>
  import(
    /* webpackChunkName: "query-client-stripe-connect-upgrade-banner" */ '../components/shared/EcommerceUpgradeBanner/QueryClientStripeConnectUpgradeBanner'
  )
);
const QueryClientStripeConnectUpgradeBanner = withProviders(
  QueryClientStripeConnectUpgradeBannerLazy
);

const LogPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "logs-feed" */ '../components/install/LogsFeed/LogPage'
  )
);
const LogPage = withProviders(LogPageLazy);

const SyncLazy = lazy(() =>
  import(/* webpackChunkName: "sync" */ '../components/admin/Sync/Sync')
);
const Sync = withProviders(SyncLazy);

const WrenLazy = lazy(() =>
  import(/* webpackChunkName: "wren" */ '../components/Wren/Wren')
);
const Wren = withProviders(WrenLazy);

const AddEnvironmentLazy = lazy(() =>
  import(
    /* webpackChunkName: "add-environment" */ '../components/sites/components/AddEnvironment/AddEnvironment'
  )
);
const AddEnvironment = withProviders(AddEnvironmentLazy);

const AddSiteLazy = lazy(() =>
  import(
    /* webpackChunkName: "add-new-site-selection-page" */ '../components/sites/components/AddSite'
  )
);
const AddSite = withProviders(AddSiteLazy);

const AdminApiKeysPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "admin-api-keys-page" */ '../components/admin/apiKeys/index'
  )
);
const AdminApiKeysPage = withProviders(AdminApiKeysPageLazy);

const AdminEntitlements = lazy(() =>
  import(
    /* webpackChunkName: "admin-entitlements" */ '../components/admin/AdminEntitlements/AdminEntitlements'
  )
);
const AdminEntitlementsPage = withProviders(AdminEntitlements);

const AdminCancelReasonsLazy = lazy(() =>
  import(
    /* webpackChunkName: "admin-cancel-reasons" */ '../components/admin/cxbiAdmin/components/cancellations/CancelReasons'
  )
);
const AdminCancelReasons = withProviders(AdminCancelReasonsLazy);

const AdminDowngradeReasonsLazy = lazy(() =>
  import(
    /* webpackChunkName: "admin-downgrade-reasons" */ '../components/admin/cxbiAdmin/components/downgrades/DowngradeReasons'
  )
);
const AdminDowngradeReasons = withProviders(AdminDowngradeReasonsLazy);

const AdminFeatureFlagsLazy = lazy(() =>
  import(
    /* webpackChunkName: "admin-feature-flags" */ '../components/admin/FeatureFlags'
  )
);
const AdminFeatureFlags = withProviders(AdminFeatureFlagsLazy);

const AdminManagedMigrationLazy = lazy(() =>
  import(
    /* webpackChunkName: "admin-managed-migration" */ '../components/admin/ManagedMigration/ManagedMigration'
  )
);
const AdminManagedMigration = withProviders(AdminManagedMigrationLazy);

const AdminAnnouncementHidersLazy = lazy(() =>
  import(
    /* webpackChunkName: "admin-announcement-hiders" */ '../components/admin/AnnouncementHiders'
  )
);

const AdminAnnouncementHiders = withProviders(AdminAnnouncementHidersLazy);

const AdminSingleSignOnsLazy = lazy(() =>
  import('../components/admin/AdminSingleSignOns/AdminSingleSignOns')
);
const AdminSingleSignOns = withProviders(AdminSingleSignOnsLazy);

const AdminSitesPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "admin-sites-page" */ '../components/admin/sites/index'
  )
);
const AdminSitesPage = withProviders(AdminSitesPageLazy);

const AdminThemesPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "admin-themes-page" */ '../components/admin/themes/index'
  )
);
const AdminThemesPage = withProviders(AdminThemesPageLazy);

const AdminConfigureChatLazy = lazy(() =>
  import(
    /* webpackChunkName: "admin-configure-chat" */ '../components/admin/ConfigureChat/ConfigureChat'
  )
);
const AdminConfigureChat = withProviders(AdminConfigureChatLazy);

const AdminEmailDeliveryLazy = lazy(() =>
  import(
    /* webpackChunkName: "admin-email-delivery" */ '../components/admin/EmailDelivery/EmailDelivery'
  )
);
const AdminEmailDelivery = withProviders(AdminEmailDeliveryLazy);

const AlertLazy = lazy(() =>
  import(
    /* webpackChunkName: "alert" */ '@wpengine/unicorn/components/Alert/Alert'
  )
);
const Alert = withProviders(AlertLazy);

const AuthConfirmationPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "auth-confirmation-page" */ '../components/AuthConfirmationPage/AuthConfirmationPage'
  )
);
const AuthConfirmationPage = withProviders(AuthConfirmationPageLazy);

const SmartSearchMarketingPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "smart-search" */ '../components/marketing/SmartSearchMarketingPage'
  )
);
const SmartSearchMarketingPage = withProviders(SmartSearchMarketingPageLazy);

const SmartSearchAiMarketingPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "smart-search-ai" */ '../components/marketing/SmartSearchAiMarketingPage'
  )
);
const SmartSearchAiMarketingPage = withProviders(
  SmartSearchAiMarketingPageLazy
);

const BannerAnnouncementLazy = lazy(() =>
  import(
    /* webpackChunkName: "banner-announcement" */
    '@wpengine/unicorn/components/Banner/BannerAnnouncement'
  )
);
const BannerAnnouncement = withProviders(BannerAnnouncementLazy);

const BbpPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "bbp-page" */
    '../components/CICD/BitbucketPipeline/BbpPage'
  )
);
const BbpPage = withProviders(BbpPageLazy);

const CancelAccountLazy = lazy(() =>
  import(
    /* webpackChunkName: "cancel-account" */ '../components/billing/cancel/CancelAccount'
  )
);
const CancelAccount = withProviders(CancelAccountLazy);

const CoreEnterprisePremiumPlansLazy = lazy(() =>
  import(
    /* webpackChunkName: "core-enterprise-premium-plans" */ '../components/marketing/components/CoreEnterprisePremiumPlans/CoreEnterprisePremiumPlans'
  )
);
const CoreEnterprisePremiumPlans = withProviders(
  CoreEnterprisePremiumPlansLazy
);

const NewCopyEnvironmentLazy = lazy(() =>
  import(
    /* webpackChunkName: "copy-environment" */ 'componentsDir/install/CopyEnvironment/NewCopyEnvironment'
  )
);
const NewCopyEnvironment = withProviders(NewCopyEnvironmentLazy);

const CopyBaseLazy = lazy(() =>
  import(
    /* webpackChunkName: "copy-base" */ 'componentsDir/install/CopyEnvironment/PushPullEnvironment/CopyBase'
  )
);
const CopyBase = withProviders(CopyBaseLazy);

const DismissableNotificationLazy = lazy(() =>
  import(
    /* webpackChunkName: "dismissable-notification" */ '../components/shared/DismissableNotification'
  )
);
const DismissableNotification = withProviders(DismissableNotificationLazy);

const DownloadZipLazy = lazy(() =>
  import(
    /* webpackChunkName: "download-zip" */ '../components/DownloadZip/DownloadZip'
  )
);
const DownloadZip = withProviders(DownloadZipLazy);

const EcommerceSolutionLazy = lazy(() =>
  import(
    /* webpackChunkName: "ecommerce-solution" */ '../components/install/EcommerceSolution/EcommerceSolution'
  )
);
const EcommerceSolution = withProviders(EcommerceSolutionLazy);

const AccountActivityLazy = lazy(() =>
  import(
    /* webpackChunkName: "account-activity" */ '../components/AccountActivity/AccountActivity'
  )
);
const AccountActivity = withProviders(AccountActivityLazy);

const AccountUsersLazy = lazy(() =>
  import(
    /* webpackChunkName: "account-users" */ '../components/AccountUsers/AccountUsers'
  )
);
const AccountUsers = withProviders(AccountUsersLazy);

const ElasticsearchLazy = lazy(() =>
  import(
    /* webpackChunkName: "elastic-search" */ '../components/products/elasticsearch/Elasticsearch'
  )
);
const Elasticsearch = withProviders(ElasticsearchLazy);

const FTUSurveyLazy = lazy(() =>
  import(
    /* webpackChunkName: "new-ftu-survey" */ '../components/FTUSurvey/FTUSurvey'
  )
);
const FTUSurvey = withProviders(FTUSurveyLazy);

const GenesisCustomBlocksProLazy = lazy(() =>
  import(
    /* webpackChunkName: "genesis-custom-blocks-pro" */ '../components/products/genesis_pro/GenesisCustomBlocksPro'
  )
);
const GenesisCustomBlocksPro = withProviders(GenesisCustomBlocksProLazy);

const GenesisBlocksProLazy = lazy(() =>
  import(
    /* webpackChunkName: "genesis-blocks-pro" */ '../components/products/genesis_pro/GenesisBlocksPro'
  )
);
const GenesisBlocksPro = withProviders(GenesisBlocksProLazy);

const GenesisProInvoiceLazy = lazy(() =>
  import(
    /* webpackChunkName: "genesis-pro-invoice" */ '../components/GenesisProInvoice/GenesisProInvoice'
  )
);
const GenesisProInvoice = withProviders(GenesisProInvoiceLazy);

const GenesisProMarketingPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "genesis-pro-marketing-page" */ '../components/marketing/GenesisProMarketingPage'
  )
);
const GenesisProMarketingPage = withProviders(GenesisProMarketingPageLazy);

const GesProductDetailPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "ges-product-detail-page" */ '../components/marketing/GesProductDetailPage'
  )
);
const GesProductDetailPage = withProviders(GesProductDetailPageLazy);

const GlobalIngressUpdateNetworkLazy = lazy(() =>
  import(
    /* webpackChunkName: "global-ingress-update-network" */ '../components/GlobalIngress/UpdateNetwork'
  )
);
const GlobalIngressUpdateNetwork = withProviders(
  GlobalIngressUpdateNetworkLazy
);

const HeadlessAppsLazy = lazy(() =>
  import(
    /* webpackChunkName: "headless-apps" */
    '../components/HeadlessApps/HeadlessApps'
  )
);
const HeadlessApps = withProviders(HeadlessAppsLazy);

const GhaPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "gha-page" */
    '../components/CICD/GHA/GhaPage'
  )
);
const GhaPage = withProviders(GhaPageLazy);

const GoLiveChecklistLazy = lazy(() =>
  import(
    /* webpackChunkName: "go-live-checklist" */
    '../components/install/GoLiveChecklist/GoLiveChecklist'
  )
);
const GoLiveChecklist = withProviders(GoLiveChecklistLazy);

const MyAccountInvoicesLazy = lazy(() =>
  import(
    /* webpackChunkName: "my-account-invoices" */ '../components/Invoices/Invoices'
  )
);
const MyAccountInvoices = withProviders(MyAccountInvoicesLazy);

const StandalonePaymentUpdateLazy = lazy(() =>
  import(
    /* webpackChunkName: "standalone-payment-update" */ '../components/Invoices/StandalonePaymentUpdate'
  )
);
const StandalonePaymentUpdate = withProviders(StandalonePaymentUpdateLazy);

const MFALazy = lazy(() =>
  import(/* webpackChunkName: "mfa" */ '../components/MFA/MFA')
);
const MFA = withProviders(MFALazy);

const ModifyHostingPlanActionListLazy = lazy(() =>
  import(
    /* webpackChunkName: "modify-hosting-plan-action-list" */ '../components/billing/ModifyHostingPlanActionList/ModifyHostingPlanActionList'
  )
);
const ModifyHostingPlanActionList = withProviders(
  ModifyHostingPlanActionListLazy
);

const NoAccountsLazy = lazy(() =>
  import(
    /* webpackChunkName: "no-accounts" */ '../components/no_accounts/NoAccounts'
  )
);
const NoAccounts = withProviders(NoAccountsLazy);

const OmniSearchPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "omni-search-page" */ '../components/OmniSearch/OmniSearchPage'
  )
);
const OmniSearchPage = withProviders(OmniSearchPageLazy);

const PasswordProtectLazy = lazy(() =>
  import(
    /* webpackChunkName: "password-protect" */ '../components/install/Utilities/PasswordProtect/PasswordProtect'
  )
);
const PasswordProtect = withProviders(PasswordProtectLazy);

const AltCronLazy = lazy(() =>
  import(
    /* webpackChunkName: "alt-cron" */ '../components/install/Utilities/AltCron/AltCron'
  )
);
const AltCron = withProviders(AltCronLazy);

const PendingInstallLazy = lazy(() =>
  import(
    /* webpackChunkName: "pending-install" */ '../components/install/Overview/components/PendingInstall'
  )
);
const PendingInstall = withProviders(PendingInstallLazy);

const PendingInstallTransferMigrationLazy = lazy(() =>
  import(
    /* webpackChunkName: "pending-install-transfer-migration" */ '../components/install/Overview/components/PendingInstallTransferMigration'
  )
);
const PendingInstallTransferMigration = withProviders(
  PendingInstallTransferMigrationLazy
);

const PlanModificationPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "plan-modification-page" */ '../components/PlanModification/PlanModificationPage'
  )
);
const PlanModificationPage = withProviders(PlanModificationPageLazy);

const PriceCreationLazy = lazy(() =>
  import(
    /* webpackChunkName: "price-creation" */ '../components/billing/prices/PriceCreation'
  )
);
const PriceCreation = withProviders(PriceCreationLazy);

const PublicApiKeysLazy = lazy(() =>
  import(
    /* webpackChunkName: "public-api-keys" */ '../components/capi/public_api_keys/PublicApiKeys'
  )
);
const PublicApiKeys = withProviders(PublicApiKeysLazy);

const PurchaseAddonsBaseLazy = lazy(() =>
  import(
    /* webpackChunkName: "purchase-addons-base" */ '../components/addons/PurchaseAddonsBase'
  )
);
const PurchaseAddonsBase = withProviders(PurchaseAddonsBaseLazy);

const EsmPremiumPurchaseSummaryBaseLazy = lazy(() =>
  import('../components/PurchaseSummary/EsmPremiumPurchaseSummaryBase')
);
const EsmPremiumPurchaseSummaryBase = withProviders(
  EsmPremiumPurchaseSummaryBaseLazy
);

const PurchaseSummaryPageLazy = lazy(() =>
  import('../components/PurchaseSummary/PremiumPurchaseSummary')
);
const PurchaseSummaryPage = withProviders(PurchaseSummaryPageLazy);

const RedirectBotsLazy = lazy(() =>
  import(
    /* webpackChunkName: "redirect-bots" */ 'componentsDir/install/Utilities/RedirectBots/RedirectBots'
  )
);
const RedirectBots = withProviders(RedirectBotsLazy);

const SameKeyConfirmationModalLazy = lazy(() =>
  import(
    /* webpackChunkName: "same-key-confirmation-modal" */
    '../components/GitPush/SameKeyConfirmationModal'
  )
);
const SameKeyConfirmationModal = withProviders(SameKeyConfirmationModalLazy);

const GitPushPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "git-push2" */ '../components/install/GitPush/GitPushPage'
  )
);
const GitPushPage = withProviders(GitPushPageLazy);

const SplashScreenWithProgressLazy = lazy(() =>
  import(
    /* webpackChunkName: "splash-screen-with-progress" */
    '@wpengine/unicorn/components/SplashScreenWithProgress/SplashScreenWithProgress'
  )
);
const SplashScreenWithProgress = withProviders(SplashScreenWithProgressLazy);

const SeamlessLoginAccessLazy = lazy(() =>
  import(
    /* webpackChunkName: "seamless-login-access" */ '../components/seamless_login_access/SeamlessLoginAccess'
  )
);
const SeamlessLoginAccess = withProviders(SeamlessLoginAccessLazy);

const SingleSignOnRouterLazy = lazy(() =>
  import(
    /* webpackChunkName: "single-sign-on-router" */ '../components/singleSignOn/SingleSignOnRouter'
  )
);
const SingleSignOnRouter = withProviders(SingleSignOnRouterLazy);

const SiteMigrationLazy = lazy(() =>
  import(
    /* webpackChunkName: "site-migration" */ '../components/install/SiteMigration/SiteMigration'
  )
);
const SiteMigration = withProviders(SiteMigrationLazy);

const EnvironmentDashboardHeaderLazy = lazy(() =>
  import(
    /* webpackChunkName: "environment-overview-header" */ '../components/install/EnvironmentDashboardHeader/EnvironmentDashboardHeader'
  )
);
const EnvironmentDashboardHeader = withProviders(
  EnvironmentDashboardHeaderLazy
);

const SmartPluginManagerLazy = lazy(() =>
  import(
    /* webpackChunkName: "smart-plugin-manager" */ '../components/products/smart_plugin_manager/SmartPluginManager'
  )
);
const SmartPluginManager = withProviders(SmartPluginManagerLazy);

const NonSelfServePageLazy = lazy(() =>
  import(
    /* webpackChunkName: "non-self-serve-page" */ '../components/Plans/NonSelfServePage/NonSelfServePage'
  )
);
const NonSelfServePage = withProviders(NonSelfServePageLazy);

const SslCertificatesLazy = lazy(() =>
  import(
    /* webpackChunkName: "ssl-certificates" */ 'componentsDir/SslCertificates/SslCertificates'
  )
);
const SslCertificates = withProviders(SslCertificatesLazy);

const AddSslCertificateLazy = lazy(() =>
  import(
    /* webpackChunkName: "add-ssl-certificates" */ 'componentsDir/SslCertificates/pages/AddSslCertificate'
  )
);
const AddSslCertificate = withProviders(AddSslCertificateLazy);

const AddCsrLazy = lazy(() =>
  import(
    /* webpackChunkName: "add-csr" */ 'componentsDir/SslCertificates/pages/AddCsr'
  )
);
const AddCsr = withProviders(AddCsrLazy);

const AddLetsEncryptLazy = lazy(() =>
  import(
    /* webpackChunkName: "add-lets-encrypt" */ 'componentsDir/SslCertificates/pages/AddLetsEncrypt'
  )
);
const AddLetsEncrypt = withProviders(AddLetsEncryptLazy);

const ThemesLazy = lazy(() =>
  import(/* webpackChunkName: "themes" */ '../components/themes/Themes')
);
const Themes = withProviders(ThemesLazy);

const ThemesDocumentationBreadcrumbsLazy = lazy(() =>
  import(
    /* webpackChunkName: "themes-documentation-breadcrumbs" */ '../components/themes/DocumentationBreadcrumbs'
  )
);
const ThemesDocumentationBreadcrumbs = withProviders(
  ThemesDocumentationBreadcrumbsLazy
);

const ThemesPlanUpgradeLazy = lazy(() =>
  import(
    /* webpackChunkName: "themes-plan-upgrade" */ '../components/themes/PlanUpgrade'
  )
);
const ThemesPlanUpgrade = withProviders(ThemesPlanUpgradeLazy);

const ToasterListenerLazy = lazy(() =>
  import(
    /* webpackChunkName: "toaster-listener" */ '@wpengine/unicorn/components/UnicornToasts/ToasterListener'
  )
);
const ToasterListener = withProviders(ToasterListenerLazy);

const ToastNotificationLazy = lazy(() =>
  import(
    /* webpackChunkName: "toast-notification" */ '@wpengine/unicorn/components/UnicornToasts/ToastNotification'
  )
);
const ToastNotification = withProviders(ToastNotificationLazy);

const TransferableEnvironmentPanelLazy = lazy(() =>
  import(
    /* webpackChunkName: "transferable-environment-panel" */
    '../components/install/Overview/components/TransferableEnvironment'
  )
);
const TransferableEnvironmentPanel = withProviders(
  TransferableEnvironmentPanelLazy
);

const UnicornSplashScreenLazy = lazy(() =>
  import(
    /* webpackChunkName: "unicorn-splash-screen" */ '../components/SideNav/components/UnicornSplashScreen'
  )
);
const UnicornSplashScreen = withProviders(UnicornSplashScreenLazy);

const UserProfileLazy = lazy(() =>
  import(
    /* webpackChunkName: "user-profile" */ '../components/UserProfile/UserProfile'
  )
);
const UserProfile = withProviders(UserProfileLazy);

const BulkUpdatesLazy = lazy(() =>
  import(
    /* webpackChunkName: "bulk-management" */ '../components/BulkUpdates/BulkUpdatesBase'
  )
);
const BulkUpdatesBase = withProviders(BulkUpdatesLazy);

const EnvironmentGraphsLazy = lazy(() =>
  import(
    /* webpackChunkName: "environment-graphs" */ '../components/sites/components/EnvironmentGraphs/EnvironmentGraphsBase'
  )
);
const EnvironmentGraphsBase = withProviders(EnvironmentGraphsLazy);

const GrowthSuiteOwnerOnboardingBannerLazy = lazy(() =>
  import(
    /* webpackChunkName: "growth-suite-owner-onboarding-banner" */ '../components/GrowthSuite/components/GrowthSuiteOwnerOnboardingBanner'
  )
);
const GrowthSuiteOwnerOnboardingBanner = withProviders(
  GrowthSuiteOwnerOnboardingBannerLazy
);

const GrowthSuiteMemberOnboardingBannerLazy = lazy(() =>
  import(
    /* webpackChunkName: "growth-suite-member-onboarding-banner" */ '../components/GrowthSuite/components/GrowthSuiteMemberOnboardingBanner'
  )
);
const GrowthSuiteMemberOnboardingBanner = withProviders(
  GrowthSuiteMemberOnboardingBannerLazy
);

const GrowthSuiteLazy = lazy(() =>
  import(
    /* webpackChunkName: "growth-suite" */ '../components/GrowthSuite/GrowthSuite'
  )
);
const GrowthSuite = withProviders(GrowthSuiteLazy);

const AnnualBillingInlineLazy = lazy(() =>
  import(
    /* webpackChunkName: "annual-billing-inline" */ '../components/shared/AnnualBillingInline/AnnualBillingInline'
  )
);
const AnnualBillingInline = withProviders(AnnualBillingInlineLazy);

const AccountCancellationBannerLazy = lazy(() =>
  import(
    /* webpackChunkName: "annual-billing-inline" */ '../components/shared/AccountCancellation/AccountCancellationBanner'
  )
);
const AccountCancellationBanner = withProviders(AccountCancellationBannerLazy);

const AccountMigratingInlineLazy = lazy(() =>
  import(
    /* webpackChunkName: "account-migrating-inline" */ '../components/shared/AccountMigratingInline/AccountMigratingInline'
  )
);
const AccountMigratingInline = withProviders(AccountMigratingInlineLazy);

const GlobalEdgeSecurityPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "global-edge-security-page" */ '../components/products/GlobalEdgeSecurityPage/GlobalEdgeSecurityPage'
  )
);
const GlobalEdgeSecurityPage = withProviders(GlobalEdgeSecurityPageLazy);

const SiteMonitoringProductDetailPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "site-monitoring-product-detail-page" */ 'componentsDir/marketing/SiteMonitoringProductDetailPage'
  )
);
const SiteMonitoringProductDetailPage = withProviders(
  SiteMonitoringProductDetailPageLazy
);

const BackupsBaseLazy = lazy(() =>
  import(
    /* webpackChunkName: "all-sites" */ 'componentsDir/install/Backups/BackupsBase'
  )
);
const BackupsBase = withProviders(BackupsBaseLazy);

const BackupsSupportHeaderLazy = lazy(() =>
  import('../components/install/Backups/SupportHeader')
);
const BackupsSupportHeader = withProviders(BackupsSupportHeaderLazy);

const RedirectRulesLazy = lazy(() =>
  import(
    /* webpackChunkName: "redirect-rules" */ '../components/install/RedirectRules/components/RedirectRules'
  )
);
const RedirectRules = withProviders(RedirectRulesLazy);

const DashboardLazy = lazy(() => import('../components/Dashboard/Dashboard'));
const Dashboard = withProviders(DashboardLazy);

const GroupNotesMigrationLazy = lazy(() =>
  import('../components/GroupNotesMigration')
);
const GroupNotesMigration = withProviders(GroupNotesMigrationLazy);

const TechnicalContactsLazy = lazy(() =>
  import(
    /* webpackChunkName: "technical-contacts-page" */ '../components/TechnicalContacts/TechnicalContacts'
  )
);
const TechnicalContacts = withProviders(TechnicalContactsLazy);

const UsageLazy = lazy(() => import('../components/Usage/Usage'));
const Usage = withProviders(UsageLazy);

const HeadlessProductDetailPageLazy = lazy(() =>
  import('../components/marketing/HeadlessProductDetailPage')
);
const HeadlessProductDetailPage = withProviders(HeadlessProductDetailPageLazy);

const EcommerceProductDetailPageLazy = lazy(() =>
  import('../components/marketing/EcommerceProductDetailPage')
);
const EcommerceProductDetailPage = withProviders(
  EcommerceProductDetailPageLazy
);

const CacheBaseLazy = lazy(() =>
  import(
    /* webpackChunkName: "CacheBase" */ 'componentsDir/install/Cache/CacheBase'
  )
);
const CacheBase = withProviders(CacheBaseLazy);

const UpdatedPremiumToPremiumV2BannerLazy = lazy(() =>
  import('../components/shared/UpdatedPremiumToPremiumV2Banner')
);
const UpdatedPremiumToPremiumV2Banner = withProviders(
  UpdatedPremiumToPremiumV2BannerLazy
);

const InfoTipLazy = lazy(() => import('componentsDir/InfoTip/InfoTip'));
const InfoTip = withProviders(InfoTipLazy);

const ManageLazy = lazy(() => import('../components/Manage/Manage'));
const Manage = withProviders(ManageLazy);

const AdvancedLazy = lazy(() =>
  import('../components/install/Advanced/Advanced')
);
const Advanced = withProviders(AdvancedLazy);

const CartLazy = lazy(() => import('../components/Cart/Cart'));
const Cart = withProviders(CartLazy);

window.ReactOnRails.register({
  ApplicationPerformanceProductDetailPage,
  ManageSiteTagsBase,
  DynamicPluginLoading,
  ChatDisabled,
  ManagedMigrationSuccessPage,
  AllAccountsSideNav,
  GenesisProOnlySideNav,
  ManagedMigrationPage,
  Performance,
  PortalDevTools,
  SiteTransfer,
  AccountActivity,
  AccountCancellationBanner,
  AccountMigratingInline,
  AccountNickname,
  AccountUsers,
  AddEnvironment,
  AddCsr,
  AddLetsEncrypt,
  AddSite,
  AdminApiKeysPage,
  AdminCancelReasons,
  AdminConfigureChat,
  AdminDowngradeReasons,
  AdminEntitlementsPage,
  AdminEmailDelivery,
  AdminFeatureFlags,
  AdminManagedMigration,
  AdminAnnouncementHiders,
  AdminSingleSignOns,
  AdminSitesPage,
  AdminThemesPage,
  Advanced,
  AdvancedNetworkLandingPage,
  Alert,
  AllSitesBase,
  AnnualBillingInline,
  HeadlessProductDetailPage,
  AuthConfirmationPage,
  SmartSearch,
  SmartSearchAiMarketingPage,
  SmartSearchInsights,
  SmartSearchMarketingPage,
  BackupsBase,
  BackupsSupportHeader,
  BannerAnnouncement,
  BbpPage,
  BulkUpdatesBase,
  Cart,
  CacheBase,
  CancelAccount,
  CoreEnterprisePremiumPlans,
  NewCopyEnvironment,
  CopyBase,
  CwvReports,
  Dashboard,
  DatacentersPage,
  DismissableNotification,
  DomainNetworks,
  DomainsRefresh,
  DownloadZip,
  Ecommerce,
  EcommercePayments,
  EcommercePerformancePage,
  EcommerceProductDetailPage,
  EcommerceSolution,
  EcommerceUpgradeBanner,
  Elasticsearch,
  EnvironmentGraphsBase,
  EnvironmentDashboardHeader,
  EnvironmentDashboardSideNav,
  EnvironmentOverviewPageContent,
  EsmPurchaseAddonsBase,
  EverCacheEnableBanner,
  FlywheelUserDash,
  Footer,
  FTUSurvey,
  GenesisBlocksPro,
  GenesisCustomBlocksPro,
  GenesisProInvoice,
  GenesisProMarketingPage,
  GesProductDetailPage,
  GhaPage,
  GitPushPage,
  GlobalEdgeSecurityPage,
  GlobalIngressUpdateNetwork,
  GoLiveChecklist,
  GroupNotesMigration,
  GrowthSuite,
  GrowthSuiteMemberOnboardingBanner,
  GrowthSuiteOwnerOnboardingBanner,
  HeadlessApps,
  Impersonation,
  InfoTip,
  LogPage,
  LogsOverview,
  Manage,
  MFA,
  ModifyHostingPlanActionList,
  MyAccountInvoices,
  StandalonePaymentUpdate,
  NoAccounts,
  NonSelfServePage,
  NotificationsCenter,
  OmniSearchPage,
  PageSpeedBoost,
  PageSpeedBoostProductDetailPage,
  PasswordProtect,
  TrafficInsights,
  AltCron,
  PendingInstall,
  PendingInstallTransferMigration,
  PlanModificationPage,
  PluginsAndThemes,
  PriceCreation,
  DiscoveryHub,
  PublicApiKeys,
  EsmPremiumPurchaseSummaryBase,
  PurchaseSummaryPage,
  PurchaseAddonsBase,
  QueryClientEcommerceUpgradeBanner,
  QueryClientStripeConnectUpgradeBanner,
  RedirectBots,
  RedirectRules,
  SalesAssistedLanding,
  SameKeyConfirmationModal,
  SeamlessLoginAccess,
  EcommerceSlowQueryLogs,
  UsersSummaryPage,
  SideNav,
  SingleSignOnRouter,
  SiteMigration,
  SiteMonitoringApp,
  SiteMonitoringProductDetailPage,
  SmartPluginManager,
  SmartPluginManagerProductDetailPage,
  SplashScreenWithProgress,
  SshKeysPage,
  AddSslCertificate,
  SslCertificates,
  SupportPageBase,
  Sync,
  TermsAndConditions,
  TechnicalContacts,
  Themes,
  ThemesDocumentationBreadcrumbs,
  ThemesPlanUpgrade,
  ToasterListener,
  ToastNotification,
  TopNav,
  TransferableEnvironmentPanel,
  UnicornSplashScreen,
  UpdatedPremiumToPremiumV2Banner,
  Usage,
  UserPersonas,
  UserProfile,
  Utilities2,
  Wren,
});
