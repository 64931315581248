import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import mockSites from './data/mockSites';

const handlers = [
  // GET list of SDS sites
  rest.get(`*/v1/site_installs`, (req, res, ctx) => {
    const installNames: string[] = req?.url?.searchParams
      ?.get('installNames')
      ?.split(',');

    if (hasPageUrlQueryParam('pendingInstall')) {
      return customResponse(
        ctx.status(200),
        ctx.json(mockSites(['testsite1700']))
      );
    }

    if (hasPageUrlQueryParam('dedicatedTransfer')) {
      return customResponse(
        ctx.status(200),
        ctx.json(mockSites(['prdInstall', 'stgInstall', 'devInstall']))
      );
    }

    return customResponse(ctx.status(200), ctx.json(mockSites(installNames)));
  }),
  rest.patch(`*/sites/:siteId`, (req, res, ctx) => {
    if (hasPageUrlQueryParam('editSiteError')) {
      return res(ctx.status(400), ctx.json({ error: 'Oops! 😢' }));
    }

    return res(ctx.status(200));
  }),
  rest.delete(`*/stores`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.get(`*/install_avatar`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        url:
          'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png',
      })
    );
  }),
];

export default handlers;
