import { customResponse } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import minify from '../wpApi/plugins/minify';
import cache from '../wpApi/plugins/cache';

const mocks: Record<string, any> = {
  minify,
  cache,
};

const handlers = [
  // handler for https://github.com/wpengine/my_wpe/blob/main/app/webpack/components/PageSpeedBoost/hooks/useIncompatiblePluginsQuery/fetchWpPlugins.ts
  rest.get('*/api.wordpress.org/plugins/info/1.2', (req, res, ctx) => {
    const tag = req.url.searchParams.get('request[tag]');
    const page = req.url.searchParams.get('request[page]') || '1';
    const data = mocks[tag][page];
    return customResponse(ctx.status(200), ctx.json(data));
  }),
];

export default handlers;
