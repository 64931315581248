import * as React from 'react';
import classNames from 'classnames';
import { Badge } from '@mui/material';
import { BellNotificationIcon } from '@wpengine/unicorn/icons';
import { sendPendoEvent } from 'componentsDir/Notifications/utils/functions';
import StyledBox from 'componentsDir/Notifications/NotificationsPanel/NotificationsPanelBell/styles';
import NavIconButton from 'componentsDir/TopNav/components/NavIconButton';

interface props {
  open: boolean;
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  hasNewNotifications: boolean;
}

const NotificationsPanelBell: React.FC<props> = ({
  open,
  handleClick,
  hasNewNotifications,
}) => {
  return (
    <StyledBox
      className={classNames('notifications-bell', {
        'notifications-bell__no-new-messages': !hasNewNotifications,
        'notifications-bell__new-messages': hasNewNotifications,
      })}
    >
      <NavIconButton
        active={open}
        className={classNames('notifications-panel-bell__bell-box', {
          'notifications-panel-bell__bell-box--open': open,
        })}
        onClick={(e): void => {
          if (hasNewNotifications) {
            // This actually means that there was new notifications since the user last clicked on the bell.
            sendPendoEvent(
              '[Notifications] Portal user click on the bell when there is at least 1 unread notification'
            );
          }
          handleClick(e);
        }}
        title={
          hasNewNotifications
            ? `You have new notifications`
            : `No new notifications`
        }
      >
        <Badge
          data-testid="bell-notifications-badge"
          className={classNames('notifications-panel-bell__badge', {
            'notifications-panel-bell__badge-dot': hasNewNotifications,
          })}
          badgeContent=""
          invisible={!hasNewNotifications}
          color="error"
          sx={theme => ({
            '.MuiBadge-badge': {
              fontWeight: 700,
              outline: `1px solid ${theme.palette.common.white}`,
              minWidth: 0,
              width: '15px',
              height: '15px',
              padding: 0,
              right: '5px',
            },
          })}
        >
          <BellNotificationIcon
            className="notifications-panel-bell__icon"
            data-testid="bell-notifications-icon"
            sx={{
              color: open ? 'primary.main' : 'inherit',
            }}
          />
        </Badge>
      </NavIconButton>
    </StyledBox>
  );
};

export default NotificationsPanelBell;
