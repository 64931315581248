import { compose, context, response, ResponseTransformer } from 'msw';
import { isTestMode } from '@wpengine/headless/utils/mode';
import { DelayMode } from 'msw/lib/types/context/delay';

// We use delay() in MSW to simulate a realistic server response time.
// However, we only need a delay when running MSW in the browser, not in tests.
// Running a delay in tests makes them longer, so we disable the delay in that case.
export function addDelay(
  duration?: number | DelayMode | undefined
): ResponseTransformer {
  if (isTestMode) {
    // Ensure there is no response delay.
    return compose();
  }

  // If no duration is passed, MSW will used a randomized realistic server response time.
  return compose(context.delay(duration));
}

// A custom response that gives you some defaults out of the box.
export function customResponse(...transformers: ResponseTransformer[]) {
  // Applies an infinite delay to the response. i.e it will never resolve.
  // This is useful when developing loading states.
  // Usage: In browser, navigate to http://localhost:3000/<your-page-path>?loading=true
  if (hasPageUrlQueryParam('loading')) {
    return response(addDelay('infinite'), context.json({}));
  }

  // Returns a generic error response for all requests made within the called page.
  // This is useful when developing UIs to handle error scenarios.
  // Usage: In browser, navigate to http://localhost:3000/<your-page-path>?error=true
  if (hasPageUrlQueryParam('error')) {
    return response(context.status(400), context.json({ error: 'Oops 😢' }));
  }

  // Return the exact same response transforms which were passed in, but also add a realistic server delay.
  return response(...transformers, addDelay());
}

// Checks to see if a query param is present in the browser's current page url.
// By default we check for 'true' as the query param value, but it can accept any string value.
// Examples:
// http://localhost:3000/<your-page-path>?slackSaveError=true
// http://localhost:3000/<your-page-path>?name=jimmy
// http://localhost:3000/<your-page-path>?sitesEmpty=true
export function hasPageUrlQueryParam(key: string, value: string = 'true') {
  return getQueryParam(key) === value;
}

// Gets the value of the page url query param by key.
export function getQueryParam(key: string) {
  const pageUrlParams = new URLSearchParams(window.location.search);
  return pageUrlParams.get(key);
}
