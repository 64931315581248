import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import * as colors from 'design-tokens';
import classNames from 'classnames';
import { darkModeManualInvertClass } from '../components/DarkMode';

type GlyphType =
  | 'TealBlue'
  | 'Navy'
  | 'TealWhite'
  | 'White'
  | 'product'
  | 'info'
  | 'internal'
  | 'warning'
  | 'success'
  | 'error'
  | 'disabled';
export interface GlyphTemplateProps extends SvgIconProps {
  type?: GlyphType;
  size?: '64' | '48' | 'default' | 'large';
  backgroundCircle?: keyof typeof colors;
  disabled?: Boolean;
}

export const accentColors: Record<GlyphType, string> = {
  TealBlue: colors.teal700,
  Navy: colors.blue1100,
  TealWhite: colors.teal700,
  White: colors.colorWhite,
  product: colors.teal700,
  info: colors.teal700,
  internal: colors.teal700,
  warning: colors.teal700,
  success: colors.teal700,
  error: colors.teal700,
  disabled: colors.teal700,
};

export const baseColors: Record<GlyphType, string> = {
  TealBlue: colors.blue700,
  Navy: colors.blue1100,
  TealWhite: colors.colorWhite,
  White: colors.colorWhite,
  product: colors.blue700,
  info: colors.blue700,
  internal: colors.blue700,
  warning: colors.blue700,
  success: colors.blue700,
  error: colors.blue700,
  disabled: colors.blue700,
};

const glyphSizes = {
  large: {
    circle: 6, // 96px
    default: 4, // 64px
  },
  '64': {
    circle: 6, // 96px
    default: 4, // 64px
  },
  default: {
    circle: 6, // 96px
    default: 4, // 64px
  },
  '48': {
    circle: 4, // 64px
    default: 3, // 48px
  },
};

interface GlyphStyles {
  [key: string]: {
    outlineColor?: string;
    fillColor?: string;
    filter?: string;
  };
}

export const glyphStyles: GlyphStyles = {
  product: {
    outlineColor: colors.purple700,
    fillColor: colors.purple100,
  },
  info: {
    outlineColor: colors.blue700,
    fillColor: colors.blue100,
  },
  internal: {
    outlineColor: colors.purple900,
    fillColor: colors.teal500,
  },
  warning: {
    outlineColor: colors.gray1200,
    fillColor: colors.yellow200,
  },
  success: {
    outlineColor: colors.green800,
    fillColor: colors.gray100,
  },
  error: {
    outlineColor: colors.red700,
    fillColor: colors.red100,
  },
  disabled: {
    outlineColor: colors.purple700,
    fillColor: colors.purple100,
  },
};

const getGlyphSize = (size: string, backgroundCircle?: string): number => {
  const sizeValues =
    glyphSizes[size as keyof typeof glyphSizes] || glyphSizes.default;
  return backgroundCircle ? sizeValues.circle : sizeValues.default;
};

const GlyphTemplate = React.forwardRef<SVGSVGElement, GlyphTemplateProps>(
  (props, ref) => {
    const {
      name,
      children,
      className = '',
      size = '64',
      backgroundCircle,
      sx,
      disabled = false,
      ...other
    } = props;

    const glyphSize = getGlyphSize(size, backgroundCircle);
    const scale = glyphSizes[size].default / glyphSizes[size].circle;

    return (
      <SvgIcon
        aria-label={name}
        data-testid={name}
        ref={ref}
        className={classNames(darkModeManualInvertClass, className)}
        sx={[
          {
            fontSize: `${glyphSize}rem`,
            ...(disabled && {
              filter: 'opacity(.4) grayscale(1)',
            }),
          },
          // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...other}
      >
        {backgroundCircle && (
          <circle cx="50%" cy="50%" r="50%" fill={colors[backgroundCircle]} />
        )}
        <g
          style={{
            transform: backgroundCircle ? `scale(${scale})` : null,
            transformOrigin: backgroundCircle ? 'center' : null,
          }}
        >
          {children}
        </g>
      </SvgIcon>
    );
  }
);
export default GlyphTemplate;
