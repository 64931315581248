import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '@wpengine/unicorn/icons/utils/SvgTemplate';
import { darkModeManualInvertClass } from '@wpengine/unicorn/components/DarkMode';

const Red = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    <SvgTemplate
      className={darkModeManualInvertClass}
      name="red"
      ref={ref}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <circle cx="10" cy="10" r="10" fill="#F2BBC8" />
        <circle cx="10" cy="10" r="8" fill="#D21B46" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 10C5 9.44772 5.44772 9 6 9H14C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H6C5.44772 11 5 10.5523 5 10Z"
          fill="white"
        />
      </svg>
    </SvgTemplate>
  );
});
export default Red;
