import axios, { AxiosRequestConfig } from 'axios';
import getCsrfToken from 'componentsDir/users/utils/userUtils';

const csrfToken = getCsrfToken();

const getConfig = () => {
  return {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': csrfToken || getCsrfToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
};

const FetchAPI = {
  get: async (url: string, options: AxiosRequestConfig = getConfig()) => {
    const response = await axios.get(url, options);
    return response?.data;
  },
  delete: async (url: string) => {
    try {
      const response = await axios.delete(url, getConfig());
      return response?.data;
    } catch (error) {
      return Promise.reject(error?.response?.data);
    }
  },
  post: async <T>(url: string, payload?: T) => {
    try {
      const response = await axios.post(url, payload, getConfig());
      return response?.data;
    } catch (error) {
      return Promise.reject(error?.response?.data);
    }
  },
  patch: async <T>(url: string, payload?: T) => {
    try {
      const response = await axios.patch(url, payload, getConfig());
      return response?.data;
    } catch (error) {
      return Promise.reject(error?.response?.data);
    }
  },
};
export default FetchAPI;
