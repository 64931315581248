import { getSettingsMap } from 'commonsDir/helpers/Settings';
import { StatusCodes } from 'http-status-codes';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import * as responses from '../services/PaymentsApiClient/data/responses';

const baseUrl = getSettingsMap().WPE_PAYMENTS_URL;

const handlers = [
  rest.get(`*/gateways/ownership`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templatePaymentsMatchedConfigs)
    );
  }),
  rest.put(`*/gateways/ownership`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.NO_CONTENT));
  }),
  rest.get(`*/v1/installs/stripe-options`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templatePaymentAllOptionsAvailable)
    );
  }),
  rest.get(`*/v2/installs/stripe-options`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templatePaymentAllOptionsAvailable)
    );
  }),
  rest.get(`*/installs/stripe-checkout-options`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templateStripeCheckout)
    );
  }),
  rest.put(`*/installs/stripe-checkout`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.NO_CONTENT));
  }),
  rest.put(`*/installs/stripe-checkout-options`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.NO_CONTENT));
  }),
  rest.put(`*/installs/test-mode`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.NO_CONTENT));
  }),
  rest.put(`*/installs/google-apple-pay`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.NO_CONTENT));
  }),
  rest.put(`*/installs/payment-methods`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.NO_CONTENT));
  }),
  rest.get(`${baseUrl}/v1/stores`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templateStore)
    );
  }),
  rest.get(`${baseUrl}/v2/stores`, (req, res, ctx) =>
    customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templateStore)
    )
  ),
  rest.post(`${baseUrl}/v1/stores`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templateFindStore)
    );
  }),
  rest.get(`${baseUrl}/v1/stores/*/terms-status`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templateGetPaymentsTermsAgreed)
    );
  }),
  rest.get(
    `${baseUrl}/v1/gateways/*/payment-method-config`,
    (req, res, ctx) => {
      return customResponse(
        ctx.status(StatusCodes.OK),
        ctx.json(responses.retrievePaymentMethodConfiguration)
      );
    }
  ),
  rest.post(`${baseUrl}/v1/stores/*/terms-status`, (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templateGetPaymentsTerms)
    );
  }),
  rest.delete(`${baseUrl}/v1/stores`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.OK), ctx.json(true));
  }),
  rest.post(`${baseUrl}/v1/stores/domain`, (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.OK));
  }),
  rest.put('*/stores/*/removeGateway', (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.NO_CONTENT));
  }),
  rest.get('*/gateways/*/status', (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templatePaymentGatewayStatusResp)
    );
  }),
  rest.post('*/stores/*/regenerate', (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.OK));
  }),
  rest.post('*/gateways/*/retry', (req, res, ctx) => {
    return customResponse(ctx.status(StatusCodes.OK));
  }),
  rest.get('*/gateways/*/permissions', (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templatePaymentGatewayPermissionsResponse)
    );
  }),
  rest.get('*/gateways/stripe', (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templatePaymentGateway)
    );
  }),
  rest.post('*/merchant-invitations', (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templateMerchantInvitation)
    );
  }),
  rest.post('*/sessions', (req, res, ctx) => {
    return customResponse(
      ctx.status(StatusCodes.OK),
      ctx.json(responses.templateOnboardingUrl)
    );
  }),
];

export default handlers;
