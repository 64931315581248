import { Entitlement } from 'commonsDir/types/Entitlement';
import { GetEntitlementsResponse } from 'componentsDir/shared/repositories/EntitlementsPrivateAPIClient/types';

const mockEntitlements: GetEntitlementsResponse = {
  entitlements: [
    {
      uuid: '1996d0b5-69ab-47d8-84ac-da4fab30b86f',
      product: 'local-storage',
      quantity: 350,
      created_on: '2019-10-10T19:01:44.854595Z',
      updated_on: '2019-10-10T19:01:44.854631Z',
      ended_on: null,
      has_ended: false,
      is_active: true,
      vendored_identifiers: [{ vendor: 'portal', id: 0, identifier: '182735' }],
    },
    {
      uuid: '4f108093-d764-4bf3-8e04-42ea48cbf07e',
      product: 'sites',
      quantity: 4,
      created_on: '2019-10-10T19:01:44.854595Z',
      updated_on: '2019-10-10T19:01:44.854631Z',
      ended_on: null,
      has_ended: false,
      is_active: true,
      vendored_identifiers: [{ vendor: 'portal', id: 0, identifier: '182735' }],
    },
    {
      uuid: '50c7e06c-c081-4d62-940d-ec3b99de2fd6',
      product: 'smart-plugin-manager',
      quantity: 15,
      created_on: '2019-10-10T19:01:44.854595Z',
      updated_on: '2019-10-10T19:01:44.854631Z',
      ended_on: null,
      has_ended: false,
      is_active: true,
      vendored_identifiers: [{ vendor: 'portal', id: 0, identifier: '182735' }],
    },
    {
      uuid: '937163-f9301-g94a-84jg-0ldalpqoe',
      product: 'monitoring-site',
      quantity: 8,
      created_on: '2019-10-10T19:01:44.854595Z',
      updated_on: '2019-10-10T19:01:44.854631Z',
      ended_on: null,
      has_ended: false,
      is_active: true,
      vendored_identifiers: [{ vendor: 'portal', id: 0, identifier: '182735' }],
    },
    {
      uuid: '6edbaae9-5baf-4d8a-b450-4537419e01e4',
      product: 'global-edge-security',
      quantity: 0,
      created_on: '2019-10-10T19:01:44.854595Z',
      updated_on: '2019-10-10T19:01:44.854631Z',
      ended_on: null,
      has_ended: false,
      is_active: true,
      vendored_identifiers: [{ vendor: 'portal', id: 0, identifier: '182735' }],
    },
    {
      uuid: '083ebacb-95c4-481f-835e-3a7a2b24265e',
      product: 'page-speed-boost',
      quantity: 15,
      created_on: '2023-11-21T19:01:44.854595Z',
      updated_on: '2023-11-21T19:01:44.854631Z',
      ended_on: null,
      has_ended: false,
      is_active: true,
      vendored_identifiers: [{ vendor: 'portal', id: 0, identifier: '182735' }],
    },
  ],
};

export default mockEntitlements;

export const mockEntitlement = (
  entitlement: Partial<Entitlement>
): Entitlement => {
  return {
    uuid: '083ebacb-95c4-481f-835e-3a7a2b24265e',
    product: 'page-speed-boost',
    quantity: 15,
    created_on: '2023-11-21T19:01:44.854595Z',
    updated_on: '2023-11-21T19:01:44.854631Z',
    ended_on: null,
    has_ended: false,
    is_active: true,
    vendored_identifiers: [{ vendor: 'portal', id: 0, identifier: '182735' }],
    ...entitlement,
  };
};
