import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const WPMigrate: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="WPMigrate logo" viewBox="0 0 128 128" {...props}>
      <path
        d="M28.48 3.52055L3.52 28.4845C1.26 30.7448 0 33.7853 0 36.9658V121.999C0 125.32 2.68 128 6 128H122C125.32 128 128 125.32 128 121.999V6.00094C128 2.68042 125.32 0 122 0H36.98C33.8 0 30.74 1.2602 28.5 3.52055H28.48Z"
        fill="url(#paint0_radial_148_9764)"
      />
      <g filter="url(#filter0_d_148_9764)">
        <path
          d="M65.5349 91.6965C84.0816 86.7269 97.9397 78.2851 96.5198 72.8486L108.483 69.6431C107.042 64.2657 100.511 61.3409 93.9102 63.1095C87.2926 64.8827 83.1156 70.6769 84.5393 76.0588L75.0167 78.6104L62.6372 32.3406C51.9973 35.1916 44.476 43.6306 42.8027 53.2683L40.2185 50.9808C31.3458 60.4956 31.6799 74.8058 40.9607 82.9715C42.5762 84.3943 44.4037 85.4392 46.2942 86.3066L29.2773 90.8662C30.7316 96.2935 46.9711 96.6706 65.5349 91.6965Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_148_9764"
          x="21.2773"
          y="24.3408"
          width="95.2058"
          height="78.854"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_148_9764"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_148_9764"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_148_9764"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(181.019)"
        >
          <stop stopColor="#0ECAD4" />
          <stop offset="1" stopColor="#006BD6" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default WPMigrate;
