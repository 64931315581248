import * as React from 'react';
import { darken, styled, SxProps, Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  CloseSmallIcon,
  ErrorIcon,
  InfoIcon,
  CheckCircleIcon,
  WarningIcon,
  WpEngineIcon,
  AnnouncementIcon,
  ExclamationCircleIcon,
} from '@wpengine/unicorn/icons';
import {
  borderRadiusLarge,
  yellow200,
  orange300,
  orange700,
  orange900,
  red100,
  red300,
  red800,
  green100,
  green300,
  green900,
  blue700,
  blue100,
  blue300,
  blue800,
  teal700,
  teal100,
  teal300,
  teal900,
  purple100,
  purple300,
  purple700,
  purple800,
  elevation1BoxShadow,
  elevation3BoxShadow,
} from 'design-tokens';
import { IconButton } from '@wpengine/unicorn/components';
import Link, { LinkProps } from '../../Link/Link';
import { Box } from '../../layout';
import Message from './Message';

interface ForwardProps {
  messageStyle: {
    backgroundColor: string;
    borderColor: string;
    color: string;
    iconColor: string;
  };
}

interface MessageBoxContainerProps extends ForwardProps {
  smallScreen: boolean;
  toast: boolean;
}

const MessageBoxContainer = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'messageStyle' && prop !== 'toast' && prop !== 'smallScreen',
})<MessageBoxContainerProps>(({ messageStyle, smallScreen, toast }) => ({
  alignItems: 'stretch',
  color: messageStyle.color,
  backgroundColor: messageStyle.backgroundColor,
  display: 'flex',
  flexDirection: smallScreen ? 'column' : 'row',
  fontSize: '0.875rem',
  border: toast ? 'none' : `1px solid ${messageStyle.borderColor}`,
  borderRadius: borderRadiusLarge,
  boxShadow: toast ? elevation3BoxShadow : elevation1BoxShadow,
  padding: '16px',
  '.MuiCard-root &': {
    border: 'none',
    boxShadow: 'none',
  },
}));

const MessageContainer = styled('div')<ForwardProps>(({ messageStyle }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  'a, .MuiLink-root': {
    alignSelf: 'flex-start',
    color: messageStyle.color,
    textDecorationColor: messageStyle.color,
    '&:visited:not(.list-link)': {
      color: messageStyle.color,
    },
  },
  '.MuiButtonBase-root, button': {
    borderColor: messageStyle.color,
    color: messageStyle.color,
  },
  'a, button': {
    '&:hover': {
      color: darken(messageStyle.color, 0.25),
      background: 'none',
      borderColor: darken(messageStyle.color, 0.25),
      '.MuiSvgIcon-root': {
        color: darken(messageStyle.color, 0.25),
      },
    },
  },
}));

const messageTypeStyles = {
  error: {
    backgroundColor: red100,
    borderColor: red300,
    color: red800,
    iconColor: red800,
  },
  attention: {
    backgroundColor: red100,
    borderColor: red300,
    color: red800,
    iconColor: red800,
  },
  info: {
    backgroundColor: blue100,
    borderColor: blue300,
    color: blue800,
    iconColor: blue700,
  },
  success: {
    backgroundColor: green100,
    borderColor: green300,
    color: green900,
    iconColor: green900,
  },
  warning: {
    backgroundColor: yellow200,
    borderColor: orange300,
    color: orange900,
    iconColor: orange700,
  },
  internal: {
    backgroundColor: teal100,
    borderColor: teal300,
    color: teal900,
    iconColor: teal700,
  },
  announcement: {
    backgroundColor: purple100,
    borderColor: purple300,
    color: purple800,
    iconColor: purple700,
  },
};

export type MessagingType =
  | 'success'
  | 'error'
  | 'attention'
  | 'warning'
  | 'info'
  | 'internal'
  | 'announcement';

export type MessageBoxProps = {
  actionButtons?: React.ReactElement;
  body?: React.ReactNode;
  dismissible?: boolean;
  dismissPath?: string;
  linkProps?: LinkProps;
  message?: string;
  onClose?: () => void;
  sx?: SxProps<Theme>;
  title?: string;
  toast?: boolean;
  type: MessagingType;
};

export const MessageBox: React.FC<MessageBoxProps> = ({
  actionButtons,
  body,
  dismissPath,
  dismissible = false,
  linkProps,
  message,
  onClose,
  sx,
  title,
  toast = false,
  type,
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const style = messageTypeStyles[type];

  const getIconForType = (): React.ReactNode => {
    let icon = null;

    switch (type) {
      case 'success':
        icon = <CheckCircleIcon htmlColor={style.iconColor} />;
        break;
      case 'error':
        icon = <ErrorIcon htmlColor={style.iconColor} />;
        break;
      case 'attention':
        icon = <ExclamationCircleIcon htmlColor={style.iconColor} />;
        break;
      case 'warning':
        icon = <WarningIcon htmlColor={style.iconColor} />;
        break;
      case 'info':
        icon = <InfoIcon htmlColor={style.iconColor} />;
        break;
      case 'internal':
        icon = <WpEngineIcon htmlColor={style.iconColor} />;
        break;
      case 'announcement':
        icon = <AnnouncementIcon htmlColor={style.iconColor} />;
        break;
      default:
        icon = <InfoIcon htmlColor={style.iconColor} />;
        break;
    }

    return <Box mr={2}>{icon}</Box>;
  };

  const getClose = (): React.ReactElement | null => {
    const getCloseButton = () => {
      return (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            marginTop: theme.spacing(-1),
            marginRight: theme.spacing(-1),
            padding: 1,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <CloseSmallIcon htmlColor={style.iconColor} />
        </IconButton>
      );
    };

    if (dismissible || dismissPath) {
      return dismissPath ? (
        <Link
          href={dismissPath}
          sx={{
            padding: 0,
            lineHeight: 'unset',
            '&.MuiLink-root:hover': {
              backgroundColor: 'transparent',
            },
          }}
          data-method="post"
          data-remote="true"
          rel="nofollow"
        >
          {getCloseButton()}
        </Link>
      ) : (
        getCloseButton()
      );
    }
    return null;
  };

  return (
    <MessageBoxContainer
      messageStyle={style}
      role="alert"
      smallScreen={smallScreen}
      sx={sx}
      toast={toast}
    >
      {smallScreen ? (
        <Box display="flex">
          {getIconForType()}
          <Box ml="auto">{getClose()}</Box>
        </Box>
      ) : (
        getIconForType()
      )}
      <MessageContainer messageStyle={style}>
        <Message
          title={title}
          body={body}
          message={message}
          linkProps={linkProps}
          actionButtons={actionButtons}
        />
      </MessageContainer>
      {!smallScreen && <Box ml="auto">{getClose()}</Box>}
    </MessageBoxContainer>
  );
};

export default MessageBox;
