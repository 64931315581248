import * as React from 'react';
import { support_path } from 'railsRoutes';
import Button from '@wpengine/unicorn/components/Button';
import { capitalize, groupBy, values } from 'lodash';
import {
  accountLink,
  installLink,
  newInstallLink,
} from 'componentsDir/OmniSearch/LinkBuilder';
import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { navigationClicked } from 'analytics/segment';
import EmphasisLink from '../EmphasisLink';

const SearchResult = styled('div')({
  paddingBottom: '1em',
});

const SearchResultContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const SearchResultType = styled('div')({
  fontSize: '18px',
  fontWeight: 'bold',
  paddingBottom: '0.5em',
});

interface TypeAheadResultsProps {
  currentAccountName: string;
  searchResultBody: SearchResult[] | string[];
  query: string;
}

const TypeAheadResults: React.FC<TypeAheadResultsProps> = ({
  currentAccountName,
  searchResultBody,
  query,
}) => {
  const [renderable, setRenderable] = React.useState(null);
  const searchResults = groupBy(searchResultBody, 'table_source');
  const total = searchResultBody.length;
  const limit = 3;

  const buildLink = (result: SearchResult): string => {
    let url: string;

    switch (result.table_source) {
      case 'sites':
        if (result.install_name === null) {
          url = newInstallLink(
            result.account_name,
            currentAccountName,
            result.id
          );
        } else {
          url = installLink(
            result.account_name,
            currentAccountName,
            result.install_name
          );
        }
        break;
      case 'accounts':
        url = accountLink(result.account_name);
        break;
      case 'environments':
        url = installLink(
          result.account_name,
          currentAccountName,
          result.install_name
        );
        break;
      case 'domains':
        url = installLink(
          result.account_name,
          currentAccountName,
          result.install_name
        );
        break;
      default:
        url = installLink(
          result.account_name,
          currentAccountName,
          result.install_name
        );
        break;
    }
    return url;
  };

  const getFragments = (type: string): Array<React.ReactFragment> => {
    return searchResults[type].map(
      (result: SearchResult): React.ReactFragment => {
        if (type === 'accounts') {
          const alias = result.account_alias?.toString();

          if (
            alias &&
            alias.toUpperCase().indexOf(query.toUpperCase()) !== -1
          ) {
            return (
              <EmphasisLink
                key={result.account_alias}
                url={buildLink(result)}
                emphasis={query}
                text={result.account_alias}
              />
            );
          }
        }

        return (
          <EmphasisLink
            key={result.name}
            url={buildLink(result)}
            emphasis={query}
            text={result.name}
          />
        );
      }
    );
  };

  const buildResult = (type: string): React.ReactFragment => {
    if (searchResults[type] !== undefined && searchResults[type].length > 0) {
      const { length } = searchResults[type];
      return (
        <SearchResult>
          <SearchResultType>
            {capitalize(type)} ({length})
          </SearchResultType>
          <SearchResultContent>
            {getFragments(type).slice(0, limit)}
          </SearchResultContent>
        </SearchResult>
      );
    }
    return null;
  };

  const calculateOverage = (): boolean => {
    return values(searchResults).reduce(
      (res: boolean, val: SearchResult[]): boolean => res || val.length > limit,
      false
    );
  };

  const createFragment = (): React.ReactFragment => {
    const more: Function = (): React.ReactFragment => {
      let frag: React.ReactFragment;
      if (calculateOverage()) {
        frag = (
          <Button
            fullWidth
            href={`/omni_search?q=${query}`}
            variant="outlined"
            size="small"
            onClick={(): void => {
              navigationClicked({
                link_type: 'Navigation',
                link_url: `/omni_search?q=${query}`,
                navigation_action: 'View all search results',
                navigation_location: 'Omnisearch results',
                navigation_text: `See all (${total})`,
                navigation_type: 'Button',
              });
            }}
          >
            See all ({total})
          </Button>
        );
      }
      return frag;
    };
    return (
      <div>
        {buildResult('domains')}
        {buildResult('environments')}
        {buildResult('sites')}
        {buildResult('accounts')}
        {more()}
      </div>
    );
  };

  const processResults: Function = (results: (SearchResult | any)[]): void => {
    let fragment: React.ReactFragment;
    if (results.length === 0) {
      fragment = (
        <Stack spacing={2} width="100%">
          <div>No matching terms found!</div>
          <Button
            href={support_path({ query })}
            onClick={() =>
              navigationClicked({
                navigation_location: 'omnisearch',
                navigation_text: 'Search our help documentation',
                navigation_type: 'link',
                navigation_url: support_path({ query }),
              })
            }
            size="small"
            variant="outlined"
          >
            Search our help documentation
          </Button>
        </Stack>
      );
    } else if (results.length === 1 && results[0] === 'error') {
      fragment = (
        <div>Search is unavailable at the moment, please try again later.</div>
      );
    } else {
      fragment = createFragment();
    }
    setRenderable(fragment);
  };

  React.useEffect(() => {
    processResults(searchResultBody);
  }, [searchResultBody]);

  return (
    <Box
      id="omni-search-results"
      sx={{
        a: {
          wordBreak: 'break-word',
        },
      }}
    >
      {renderable}
    </Box>
  );
};

export default TypeAheadResults;
