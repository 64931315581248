import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const AbacusIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="abacus-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 12C23 12.5523 22.5523 13 22 13H12C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11H22C22.5523 11 23 11.4477 23 12Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 4C13 4.55228 12.5523 5 12 5H2C1.44771 5 1 4.55228 1 4C1 3.44772 1.44771 3 2 3H12C12.5523 3 13 3.44772 13 4Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 12C1 11.4477 1.44772 11 2 11H7C7.55228 11 8 11.4477 8 12C8 12.5523 7.55228 13 7 13H2C1.44772 13 1 12.5523 1 12Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 20C1 19.4477 1.44772 19 2 19H8C8.55228 19 9 19.4477 9 20C9 20.5523 8.55228 21 8 21H2C1.44772 21 1 20.5523 1 20Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 4C23 4.55228 22.5523 5 22 5H16V3H22C22.5523 3 23 3.44772 23 4Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 1C17.5523 1 18 1.44772 18 2V6C18 6.55228 17.5523 7 17 7C16.4477 7 16 6.55228 16 6V2C16 1.44772 16.4477 1 17 1Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 9C7.55228 9 8 9.44771 8 10V14C8 14.5523 7.55228 15 7 15C6.44772 15 6 14.5523 6 14V10C6 9.44771 6.44772 9 7 9Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 20C23 20.5523 22.5523 21 22 21H13V19H22C22.5523 19 23 19.4477 23 20Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 17C12.5523 17 13 17.4477 13 18V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V18C11 17.4477 11.4477 17 12 17Z"
        />
      </SvgTemplate>
    );
  }
);
export default AbacusIcon;
