const mockCapiDomains = [
  {
    id: '84bb1e10-b72b-449e-b576-fb5e8827b92d',
    name: 'google.biz',
    duplicate: false,
    primary: false,
  },
];

export default mockCapiDomains;
