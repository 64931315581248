/* eslint-disable no-param-reassign */
import { ServerSiteNote } from './types';
import { SiteNoteResponse } from '../../../types';
import mockSiteNotes from './mockSiteNotes';

class SiteNotesMockDataStore {
  siteNotes: ServerSiteNote[] = [];

  constructor() {
    this.reset();
  }

  reset() {
    this.siteNotes = mockSiteNotes;
  }

  getSiteNotes(siteIds: number[]): SiteNoteResponse[] {
    const filteredSiteNotes = this.siteNotes.filter(s =>
      siteIds.includes(s.siteId)
    );
    return filteredSiteNotes.map(s => this.serverSiteNoteToRenderedSiteNote(s));
  }

  upsertSiteNotes(
    siteId: number,
    content: string,
    lastUpdatedByUserId: number = 9999,
    accountWpeId: number = 101
  ): SiteNoteResponse {
    const existingSiteNote = this.siteNotes.find(s => s.siteId === siteId);
    if (existingSiteNote) {
      const siteNotesWithoutExisting = this.siteNotes.filter(
        s => s.siteId !== siteId
      );
      const updatedSiteNote: ServerSiteNote = {
        ...existingSiteNote,
        content,
        lastUpdatedByUserId,
      };
      this.siteNotes = [...siteNotesWithoutExisting, updatedSiteNote];
      return this.serverSiteNoteToRenderedSiteNote(updatedSiteNote);
    }
    const newSiteNote: ServerSiteNote = {
      id: Math.max(...this.siteNotes.map(n => n.id)) + 1,
      siteId,
      content,
      lastUpdatedByUserId,
      userUpdatedAt: new Date(),
      accountWpeId,
    };
    this.siteNotes = [...this.siteNotes, newSiteNote];
    return this.serverSiteNoteToRenderedSiteNote(newSiteNote);
  }

  serverSiteNoteToRenderedSiteNote = (
    serverSiteNote: ServerSiteNote
  ): SiteNoteResponse => {
    return {
      id: serverSiteNote.id,
      content: serverSiteNote.content,
      userUpdatedAt: serverSiteNote.userUpdatedAt.toISOString(),
      lastUpdatedByUser: `[name for ${serverSiteNote.id}]`,
      siteId: serverSiteNote.siteId,
    };
  };
}

const newDataStore = new SiteNotesMockDataStore();

export default newDataStore;
