import { rest } from 'msw';
import { ENDPOINTS, QUERY_PARAMS } from 'componentsDir/AllSites/constants';
import siteNotesStore from './data/siteNotes/store';
import { SiteNoteResponse } from '../types';

const handlers = [
  rest.get(`*${ENDPOINTS.SITE_NOTES}*`, (req, res, ctx) => {
    let sites: SiteNoteResponse[] = [];
    const siteIdsParam = req.url.searchParams.get(QUERY_PARAMS.SITE_IDS);
    if (siteIdsParam) {
      const siteIds = siteIdsParam.split(',').map(id => Number(id));
      sites = siteNotesStore.getSiteNotes(siteIds);
    }
    return res(ctx.status(200), ctx.json({ siteNotes: sites }));
  }),
];

export default handlers;
