import { customResponse } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import endpoints from '../constants/endpoints';
import environmentDropdownOptions from './data/sitesTransferAvailableSites';

const handlers = [
  rest.get(`*${endpoints.SITES_TRANSFER_AVAILABLE_SITES}`, (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({ environmentDropdownOptions })
    );
  }),
];

export default handlers;
