import * as React from 'react';
import { Container as MuiContainer, ContainerProps } from '@mui/material';

const Container: React.FC<ContainerProps> = props => (
  <MuiContainer
    style={{ marginLeft: 'auto', marginRight: 'auto' }}
    {...props}
  />
);

export default Container;
