import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const WooCommercePerformance: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate
      name="WooCommercePerformance logo"
      viewBox="0 0 64 64"
      {...props}
    >
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_821_891)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 37C40.4477 37 40 36.5523 40 36C40 35.4477 40.4477 35 41 35H47C47.5523 35 48 35.4477 48 36V43C48 43.5523 47.5523 44 47 44C46.4477 44 46 43.5523 46 43V38.4142L40.5578 43.8564C39.1441 45.2701 36.8764 45.3456 35.3718 44.0291C34.6438 43.3921 33.5422 43.4455 32.8792 44.1499L26.7282 50.6853C26.3497 51.0875 25.7168 51.1067 25.3146 50.7282C24.9125 50.3497 24.8933 49.7168 25.2718 49.3146L31.4228 42.7792C32.8235 41.291 35.1508 41.1782 36.6888 42.524C37.401 43.1471 38.4744 43.1114 39.1436 42.4422L44.5858 37H41Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.975 31.0004C52.0026 31.552 51.5523 32 51 32H38C38 35.3137 35.3137 38 32 38C30.3431 38 28.8431 37.3284 27.7574 36.2426L18.565 45.435C18.1744 45.8256 17.5392 45.8272 17.1687 45.4176C15.1916 43.2323 13.719 40.6336 12.8612 37.8057C11.9064 34.6583 11.7426 31.324 12.3843 28.0982C13.026 24.8724 14.4533 21.8546 16.5398 19.3121C18.6263 16.7697 21.3077 14.7811 24.3463 13.5224C27.385 12.2638 30.6872 11.7739 33.9603 12.0963C37.2335 12.4187 40.3767 13.5433 43.1114 15.3706C45.8461 17.1979 48.088 19.6714 49.6384 22.5721C51.0315 25.1783 51.8277 28.0571 51.975 31.0004ZM32 21C31.4477 21 31 20.5523 31 20V17C31 16.4477 31.4477 16 32 16C32.5523 16 33 16.4477 33 17V20C33 20.5523 32.5523 21 32 21ZM23.2929 24.7071C23.6834 25.0976 24.3166 25.0976 24.7071 24.7071C25.0976 24.3166 25.0976 23.6834 24.7071 23.2929L22.5858 21.1716C22.1953 20.7811 21.5621 20.7811 21.1716 21.1716C20.781 21.5621 20.781 22.1953 21.1716 22.5858L23.2929 24.7071ZM17 32C17 31.4477 17.4477 31 18 31H21C21.5523 31 22 31.4477 22 32C22 32.5523 21.5523 33 21 33H18C17.4477 33 17 32.5523 17 32ZM42.7071 21.2929C43.0976 21.6834 43.0976 22.3166 42.7071 22.7071L34.7076 30.7066C34.8951 31.0982 35 31.5368 35 32C35 33.6568 33.6569 35 32 35C30.3431 35 29 33.6568 29 32C29 30.3431 30.3431 29 32 29C32.4632 29 32.9018 29.1049 33.2934 29.2924L41.2929 21.2929C41.6834 20.9024 42.3166 20.9024 42.7071 21.2929Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_821_891"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default WooCommercePerformance;
