import * as React from 'react';
import classnames from 'classnames';
import { styled } from '@mui/material/styles';
import {
  FormControl,
  Input,
  InputAdornment,
  FormHelperText,
  InputLabelProps as MuiInputLabelProps,
  FormLabel,
} from '@mui/material';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { CopyIcon, InfoIcon } from '@wpengine/unicorn/icons';
import {
  borderRadiusMedium,
  colorWhite,
  gray1100,
  gray100,
  gray600,
  gray900,
  elevation1BoxShadow,
} from 'design-tokens';
import Typography from '../Typography';
import { Box } from '../layout';
import IconButton from '../IconButton';
import InfoTip from '../InfoTip';
import { InfoTipProps as InfoTipInterface } from '../InfoTip/InfoTip';
import Toaster from '../Toaster';
import ErrorText from '../internal/ErrorText';
import HelperText from '../internal/HelperText';

Toaster.configure();

export interface TextFieldProps {
  canCopy?: boolean;
  /**
   * Handler event to be triggered in addition to copying text field contents
   *
   * @returns void
   */
  onCopy?: () => void;
  errorText?: string;
  immutableText?: string;
  infoTip?: string;
  InfoTipProps?: Partial<InfoTipInterface>;
  margin?: 'none' | 'dense' | 'normal';
  maxCharacters?: number;
  reservedCharacters?: number;
  readOnly?: boolean;
  InputLabelProps?: MuiInputLabelProps;
  value?: string;
  endAdornment?: React.ReactNode;
  helperComponent?: React.ReactNode;
}

const StyledFormControl = styled(FormControl)({
  alignItems: 'baseline',
  [`& label+.MuiInput-root, & p+.MuiInput-root`]: {
    marginTop: 8,
  },
});

const StyledInput = styled(Input)(({ theme }) => ({
  backgroundColor: colorWhite,
  border: `1px solid ${theme.palette.common.border}`,
  borderRadius: borderRadiusMedium,
  boxShadow: elevation1BoxShadow,
  color: gray1100,
  height: '56px',
  lineHeight: '22px',
  padding: '8px 0px 8px 16px',
  '.MuiInputAdornment-root, .MuiIconButton-root': {
    color: gray1100,
  },
  '& .MuiInputBase-input.MuiInput-input:not(.MuiInputBase-inputMultiline):only-child': {
    marginRight: '16px',
  },
  '& .MuiInput-input': {
    height: '24px',
  },
  [`&.MuiInputBase-multiline`]: {
    height: 'auto',
    padding: '16px 0 0 16px',
    '& .MuiInputBase-inputMultiline': {
      paddingRight: '16px',
      paddingBottom: '16px',
      resize: 'both',
    },
  },
  '&.Mui-focused': {
    borderColor: theme.palette.primary.main,
    boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
  },
  '&.Mui-error': {
    borderColor: theme.palette.error.main,
    boxShadow: `inset 0 0 0 1px ${theme.palette.error.main}`,
  },
  '&.Mui-error.Mui-focused': {
    borderColor: theme.palette.error.main,
    boxShadow: `inset 0 0 0 2px ${theme.palette.error.main}`,
  },
  '&.Mui-disabled': {
    backgroundColor: gray100,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.common.border}`,
    '.MuiInputAdornment-root, .MuiIconButton-root': {
      color: gray600,
    },
  },
  '&.readOnly': {
    backgroundColor: gray100,
    border: `1px solid ${theme.palette.common.border}`,
    '&, .MuiInputBase-input': {
      cursor: 'not-allowed',
    },
    '&.Mui-focused': {
      boxShadow: 'none',
    },
    '.MuiIconButton-root': {
      color: gray900,
    },
  },
  [`&.MuiInput-fullWidth`]: {
    '&.Mui-focused': {
      '& .MuiInputAdornment-root': {
        marginRight: '11px',
      },
    },
    '&.MuiInputBase-inputMultiline': {
      resize: 'vertical',
    },
  },
}));

const TextField = React.forwardRef<
  HTMLInputElement,
  TextFieldProps & MuiTextFieldProps
>(
  (
    {
      autoFocus,
      canCopy,
      onCopy,
      defaultValue,
      disabled,
      error,
      errorText,
      fullWidth,
      helperText,
      id,
      immutableText,
      infoTip,
      InfoTipProps = { placement: 'top' },
      InputLabelProps,
      inputProps,
      InputProps,
      inputRef,
      label,
      margin = 'none',
      maxCharacters,
      reservedCharacters = 0,
      multiline = false,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder = '',
      readOnly = false,
      rows = 3,
      // rowsMax,
      type,
      value,
      endAdornment,
      helperComponent,
      ...other
    },
    ref
  ) => {
    const errorTextId = errorText && id ? `${id}-error-text` : undefined;
    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
    const inputLabelId = label && id ? `${id}-label` : undefined;

    const handleCopy = (): void => {
      if (value) {
        navigator.clipboard.writeText(value);
        Toaster.success('Copied to clipboard');
        if (onCopy) {
          onCopy();
        }
      }
    };
    const copyEndAdornment = canCopy && (
      <InputAdornment position="end">
        <IconButton
          aria-label="Copy"
          disabled={disabled}
          onClick={handleCopy}
          sx={{ mr: '8px' }}
        >
          <CopyIcon />
        </IconButton>
      </InputAdornment>
    );

    const CharacterCount = (
      max: number,
      current: number | undefined
    ): React.ReactNode => {
      return (
        <Typography marginLeft="auto">
          {current || 0}/{max}
        </Typography>
      );
    };

    return (
      <StyledFormControl
        {...{ disabled, error, fullWidth, margin, ref, ...other }}
      >
        {label && (
          <FormLabel htmlFor={id} id={inputLabelId} {...InputLabelProps}>
            {infoTip ? (
              <Box alignItems="center" display="flex" gap=".5rem">
                {label}
                <InfoTip
                  icon={<InfoIcon />}
                  title={infoTip}
                  sx={{
                    padding: 0,
                  }}
                  {...InfoTipProps}
                />
              </Box>
            ) : (
              label
            )}
          </FormLabel>
        )}
        {helperText && <HelperText id={helperTextId}>{helperText}</HelperText>}
        {helperComponent}
        {immutableText ? (
          <Typography variant="helper">{immutableText}</Typography>
        ) : (
          <StyledInput
            aria-describedby={helperTextId}
            aria-label={name}
            className={classnames({ label, readOnly })}
            disableUnderline
            error={error}
            endAdornment={endAdornment || copyEndAdornment}
            readOnly={readOnly}
            {...{
              autoFocus,
              defaultValue,
              disabled,
              fullWidth,
              id,
              inputProps,
              inputRef,
              multiline,
              name,
              onBlur,
              onChange,
              onFocus,
              placeholder,
              rows,
              // rowsMax,
              type,
              value,
              ...InputProps,
            }}
          />
        )}
        {((error && errorText) || maxCharacters) && (
          <FormHelperText
            component="div"
            disabled={disabled}
            error={error}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '4px',
            }}
          >
            {error && errorText && (
              <ErrorText id={errorTextId} text={errorText} sx={{ margin: 0 }} />
            )}
            {maxCharacters &&
              CharacterCount(
                maxCharacters,
                value ? value.length + reservedCharacters : reservedCharacters
              )}
          </FormHelperText>
        )}
      </StyledFormControl>
    );
  }
);

export default TextField;
