import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import {
  ENDPOINTS,
  MSW_PARAMS,
} from 'componentsDir/ManageSiteTagsPage/constants';
import {
  generateSitesTagsDataAlphabetically,
  generateSiteTagsData,
} from './data';

const sitesBulkTagsHandler = [
  rest.get(`*/v1/${ENDPOINTS.BULK_LIST_TAGGED_SITES}`, (_req, _res, ctx) => {
    if (hasPageUrlQueryParam(MSW_PARAMS.NO_SITE_TAG)) {
      return customResponse(
        ctx.status(200),
        ctx.json(
          generateSiteTagsData({
            numberOfTags: 0,
            numberOfSitesPerTag: 0,
            randomizeNumberOfSites: false,
          })
        )
      );
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.SINGLE_SITE_TAG, 'true')) {
      return customResponse(
        ctx.status(200),
        ctx.json(
          generateSiteTagsData({
            numberOfTags: 1,
            numberOfSitesPerTag: 3,
            randomizeNumberOfSites: false,
          })
        )
      );
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.SINGLE_SITE_TAG_SINGLE_SITE)) {
      return customResponse(
        ctx.status(200),
        ctx.json(
          generateSiteTagsData({
            numberOfTags: 1,
            numberOfSitesPerTag: 1,
            randomizeNumberOfSites: false,
          })
        )
      );
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.RANDOMIZE_SITE_TAG_SITES_COUNT)) {
      return customResponse(
        ctx.status(200),
        ctx.json(
          generateSiteTagsData({
            numberOfTags: 50,
            numberOfSitesPerTag: 10,
            randomizeNumberOfSites: true,
          })
        )
      );
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.ALPHABET_TAGS_AND_SITES)) {
      return customResponse(
        ctx.status(200),
        ctx.json(generateSitesTagsDataAlphabetically())
      );
    }

    return customResponse(
      ctx.status(200),
      ctx.json(
        generateSiteTagsData({
          numberOfTags: 10,
          numberOfSitesPerTag: 3,
          randomizeNumberOfSites: false,
        })
      )
    );
  }),
];

export default sitesBulkTagsHandler;
