import { AvailableLicenses } from 'componentsDir/shared/types';

const sample: AvailableLicenses = {
  licenses: {
    spm: {
      available: 15,
      total: 20,
    },
    monitoring: {
      available: 15,
      total: 20,
    },
  },
};

export default { sample };
