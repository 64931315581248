export const purgeUnderscores = (target: string): string => {
  return target.replace(/_/g, ' ');
};

export const trimWhitespace = (name: string): string => {
  return name.replace(/\s+/g, ' ').trim();
};

export const removeZeroWidthCharacters = (input: string): string => {
  return input.replace(
    /[\u200A-\u200F\u202A-\u202E\u2060-\u2064\u2066-\u206F\uFEFF\uFFF9-\uFFFB]/g,
    ''
  );
};

export const trimInput = (input: string): string => {
  return trimWhitespace(removeZeroWidthCharacters(input));
};

export const extractDomain = (searchQuery: string): string => {
  return searchQuery.replace(/^https?:\/\//i, '').split(/[/?#]/)[0];
};
