import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const StepperTwoFilledIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="stepper-two-filled-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 6C10.0883 6 8.25 7.32454 8.25 9.5C8.25 10.0523 8.69772 10.5 9.25 10.5C9.80229 10.5 10.25 10.0523 10.25 9.5C10.25 8.67546 10.9293 8 12 8C13.0707 8 13.75 8.67546 13.75 9.5C13.75 10.0542 13.5392 10.4725 13.1344 10.889C12.7124 11.3233 12.1426 11.6945 11.4376 12.1539L11.3287 12.2249C9.94448 13.1277 8 14.3973 8 17C8 17.5523 8.44772 18 9 18H15C15.5523 18 16 17.5523 16 17C16 16.4477 15.5523 16 15 16H10.1946C10.5408 15.1925 11.3313 14.611 12.4213 13.9001L12.5828 13.795C13.2313 13.3737 13.9835 12.8851 14.5687 12.2829C15.2421 11.59 15.75 10.6958 15.75 9.5C15.75 7.32454 13.9117 6 12 6Z"
        />
      </SvgTemplate>
    );
  }
);
export default StepperTwoFilledIcon;
