import { Subscription } from 'componentsDir/EsmPurchaseAddonsPage/models/Subscription';
import { SubscriptionPlansV2 } from 'componentsDir/EsmPurchaseAddonsPage/models/SubscriptionPlansV2';
import { ManageContext } from '../types/types';

export const mockCustomer: CustomerInfo = {
  nickname: '',
  customerId: 'C72129 Jeff Jeffson',
  contacts: [
    {
      customerId: 'C72129 Jeff Jeffson',
      firstName: 'Jeff',
      lastName: 'Jeffson',
      email: 'paul.ledeboer+0918-02@gmail.com',
      role: 'primary',
    },
    {
      customerId: 'C72129 Jeff Jeffson',
      firstName: 'Jeff',
      lastName: 'Jeffson',
      email: 'paul.ledeboer+0918-02@gmail.com',
      role: 'billing',
    },
  ],
  capabilities: {
    invoiceExperience: 'summary',
    modifyPlan: '',
    subscriptionView: '',
    canUpgradePlan: true,
  },
};

export const mockSubscriptions: CustomerSubscription[] = [
  {
    planName: 'Growth',
    planItems: [
      'Shared Support',
      'Global Ingress Beta',
      'Visitors',
      'CDN Bandwidth',
      'Sites',
      'Hosting Shared',
      'Local Storage',
      'WPE Hosting Account',
      'WP Engine SSL',
      'SSH Gateway',
      'Phone Support',
      'Third Party SSL',
      'Page Performance',
      'CDN Subscription',
      'Themes/Framework',
      'Support',
    ],
    renewalFrequency: {
      value: 12,
      unit: 'MONTHS',
    },
    nextPayment: {
      amount: 1150,
      date: '2024-09-18T07:00:00',
      currency: 'USD',
    },
    canUpgradePlan: true,
    canAddAddons: true,
  },
];

export const mockPaymentMethod: CustomerCardDetails & {
  contact: CustomerCardContact;
} = {
  last4: '4242',
  expiration: '1/2026',
  brand: 'VISA',
  contact: {
    email: 'jeff.jefferson@test.com',
    firstName: 'Jeff',
    lastName: 'Jefferson',
    addressLine1: '123 Main St',
    addressLine2: '',
    addressCity: 'Austin',
    addressState: 'TX',
    addressZip: '78701',
    addressCountry: 'US',
  },
};

export const mockTransactions: CustomerTransactionInfo[] = [
  {
    transactionNumber: 'INV47804151',
    transactionType: 'Invoice',
    date: '2024-05-16T00:00:00',
    amount: 5190.81,
    amountRemaining: 5190.81,
    currency: 'USD',
    open: false,
  },
  {
    transactionNumber: 'INV47804152',
    transactionType: 'Credit Memo',
    date: '2024-05-15T00:00:00',
    amount: -419.81,
    amountRemaining: 0,
    currency: 'USD',
    open: false,
  },
  {
    transactionNumber: 'INV47804153',
    transactionType: 'Invoice',
    date: '2024-05-14T00:00:00',
    amount: 2537.43,
    amountRemaining: 0,
    currency: 'USD',
    open: true,
  },
  {
    transactionNumber: 'INV47804154',
    transactionType: 'Invoice',
    date: '2024-05-13T00:00:00',
    amount: 2537.43,
    amountRemaining: 0,
    currency: 'USD',
    open: false,
  },
  {
    transactionNumber: 'CM5550497',
    transactionType: 'Credit Memo',
    date: '2024-05-12T00:00:00',
    amount: -4226.69,
    amountRemaining: -1269.45,
    currency: 'USD',
    open: true,
  },
  {
    transactionNumber: 'CM5550498',
    transactionType: 'Credit Memo',
    date: '2024-05-11T00:00:00',
    amount: -4226.69,
    amountRemaining: -1269.45,
    currency: 'USD',
    open: true,
  },
];

export const mockInvoiceResult = {
  invoice: 'test_value',
};

export const mockBmsSubscription: Subscription = {
  subscription_id: 'S124792',
  family_id: 'startup',
  plan_name: 'Startup',
  plan_group_id: '1',
  plan_group_order: 2,
  base_price: '30',
  recurrence: 'MONTHLY',
  currency: 'USD',
  region: 'BASE',
  plan_lines: [
    {
      product: 'visitors',
      description: 'Visitors',
      quantity: 25000,
      base_sku: 'visitors',
    },
    {
      product: 'local-storage',
      description: 'Local Storage',
      quantity: 10,
      base_sku: 'local-storage',
    },
    {
      product: 'cdnbw',
      description: 'CDN Bandwidth',
      quantity: 50,
      base_sku: 'cdnbw',
    },
  ],
  addons: [
    {
      product: 'monitoring-site-addon',
      name: 'Site Monitoring',
      description: 'Site Monitoring - 1 Site Environment',
      quantity: 5,
      base_sku: 'monitoring-site',
      total_price: '55.0',
    },
    {
      product: 'smart-plugin-manager-addon',
      name: 'Smart Plugin Manager',
      description: 'Smart Plugin Manager',
      quantity: 1,
      base_sku: 'smart-plugin-manager',
      total_price: '25.0',
    },
  ],
  can_add_addons: true,
  can_upgrade_plan: true,
};

export const mockBmsSubscriptionPlan: SubscriptionPlansV2 = {
  subscription_plan_id: 'startup-20240201',
  subscription_family_id: 'startup',
  plan_name: 'Startup',
  plan_group_order: 2,
  recurrence: 'MONTHLY',
  currency: 'USD',
  region: 'BASE',
  marketing_json: '{}',
  price: '30',
  plan_lines: [
    {
      product: 'cdnbw',
      description: 'CDN Bandwidth',
      quantity: 50,
      base_sku: 'cdnbw',
    },
    {
      product: 'local-storage',
      description: 'Local Storage',
      quantity: 10,
      base_sku: 'local-storage',
    },
    {
      product: 'visitors',
      description: 'Visitors',
      quantity: 25000,
      base_sku: 'visitors',
    },
  ],
  addons: [
    {
      product: 'monitoring-site-addon',
      name: 'Site Monitoring',
      description: 'Site Monitoring - 1 Site Environment',
      base_sku: 'monitoring-site',
      prices: [
        { price: '20', quantity: 15 },
        { price: '15', quantity: 5 },
        { price: '5', quantity: 1 },
      ],
    },
    {
      product: 'smart-plugin-manager-addon',
      name: 'Smart Plugin Manager',
      description: 'Smart Plugin Manager',
      base_sku: 'smart-plugin-manager',
      prices: [
        { price: '300', quantity: 150 },
        { price: '30', quantity: 5 },
        { price: '10', quantity: 1 },
      ],
    },
  ],
};

export const mockManageContext: ManageContext = {
  currentAccountInfo: {
    accountName: 'Mock Account Name',
    accountUuid: '12345',
    annualSavings: null,
    displayName: 'Display Me',
    hostingId: '12345',
  },
  currentUserInfo: {
    email: 'email@wpengine.com',
    oktaUid: '23432',
    uuid: '123334',
  },
  billingContact: mockCustomer.contacts[0],
  bmsSubscription: mockBmsSubscription,
  bmsSubscriptionLoading: false,
  bmsSubscriptionError: false,
  bmsSubscriptionPlan: mockBmsSubscriptionPlan,
  bmsSubscriptionPlanLoading: false,
  bmsSubscriptionPlanError: false,
  setBillingContact: () => {},
  customer: mockCustomer,
  customerError: false,
  customerLoading: false,
  customReasons: { reason: 'Some reason' },
  downgradeReasons: {
    'Primary reason 1': {
      id: 1,
      secondaries: ['Belongs to primary reason 1', 'Other'],
    },
    'Primary reason 2': { id: 2, secondaries: ['Belongs to primary reason 2'] },
  },
  genesisProPrimaryProduct: false,
  impersonating: false,
  isPremium: false,
  paymentMethod: mockPaymentMethod,
  paymentMethodError: false,
  paymentMethodLoading: false,
  redirectToPlans: true,
  salesPhone: '911',
  showDowngradeModal: false,
  setShowDowngradeModal: () => {},
  showUpgradeModal: false,
  setShowUpgradeModal: () => {},
  subscriptions: mockSubscriptions,
  subscriptionsError: false,
  subscriptionsLoading: false,
  transactions: mockTransactions,
  transactionsError: false,
  transactionsLoading: false,
};
