import * as React from 'react';
import { FocusTrap } from '@mui/base/FocusTrap';
import { Divider, Stack } from '@mui/material';
import { ChevronRightIcon } from '@wpengine/unicorn/icons';
import { NavItem } from '@wpengine/unicorn/components';
import SystemStatus from 'componentsDir/shared/SystemStatus/SystemStatus';
import { useTopNavContext } from 'componentsDir/TopNav/contexts/TopNavProvider';
import SubMenu from './SubMenu';
import {
  BuilderCommunity,
  HeadlessCommunity,
  WpEngineApi,
  AgencyDirectory,
  ResourceCenter,
  TorqueMagazine,
  Velocitize,
  Blog,
  EventsAndWebinars,
  Newsroom,
  SupportCenter,
  ProductFeedback,
  MySupportTickets,
} from './linkData';
import SupportChatPin from './SupportChatPin';

const SupportDropdownContent: React.FC = () => {
  const [showTechResources, setShowTechResources] = React.useState(false);
  const [showResources, setShowResources] = React.useState(false);
  const [showNewsAndEvents, setShowNewsAndEvents] = React.useState(false);

  const { allAccountsView } = useTopNavContext();
  return (
    <FocusTrap open>
      <>
        <Stack>
          {[SupportCenter, ProductFeedback, MySupportTickets].map(link => (
            <NavItem href={link.href} key={link.text}>
              {link.text}
            </NavItem>
          ))}
          <SystemStatus />
          <SupportChatPin disabled={allAccountsView} inNav={false} />
          <Divider sx={{ margin: '16px -24px' }} />
          <NavItem
            endIcon={<ChevronRightIcon />}
            onClick={() => setShowTechResources(true)}
            size="small"
          >
            Tech resources
          </NavItem>
          <NavItem
            endIcon={<ChevronRightIcon />}
            onClick={() => setShowResources(true)}
            size="small"
          >
            Resources
          </NavItem>
          <NavItem
            endIcon={<ChevronRightIcon />}
            onClick={() => setShowNewsAndEvents(true)}
            size="small"
          >
            News & events
          </NavItem>
        </Stack>
        <SubMenu
          heading="Tech resources"
          onBack={() => setShowTechResources(false)}
          open={showTechResources}
        >
          {[BuilderCommunity, HeadlessCommunity, WpEngineApi].map(link => (
            <NavItem href={link.href} key={link.text}>
              {link.text}
            </NavItem>
          ))}
        </SubMenu>
        <SubMenu
          heading="Resources"
          onBack={() => setShowResources(false)}
          open={showResources}
        >
          {[AgencyDirectory, ResourceCenter, TorqueMagazine, Velocitize].map(
            link => (
              <NavItem href={link.href} key={link.text}>
                {link.text}
              </NavItem>
            )
          )}
        </SubMenu>
        <SubMenu
          heading="News & events"
          onBack={() => setShowNewsAndEvents(false)}
          open={showNewsAndEvents}
        >
          {[Blog, EventsAndWebinars, Newsroom].map(link => (
            <NavItem href={link.href} key={link.href}>
              {link.text}
            </NavItem>
          ))}
        </SubMenu>
      </>
    </FocusTrap>
  );
};

export default SupportDropdownContent;
