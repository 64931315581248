import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import {
  mockCustomer,
  mockPaymentMethod,
  mockSubscriptions,
  mockInvoiceResult,
  mockTransactions,
} from './mocks';

const handlers = [
  rest.get('/capi/private/customers/*/transactions', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(mockTransactions));
  }),
  rest.get('/capi/private/customers/*/subscriptions', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(mockSubscriptions));
  }),
  rest.get('/capi/private/customers/*/card_details', (req, res, ctx) => {
    if (hasPageUrlQueryParam('empty')) {
      return customResponse(ctx.status(404), ctx.json(null));
    }
    return customResponse(ctx.status(200), ctx.json(mockPaymentMethod));
  }),
  rest.get('/capi/private/customers/*/invoices/*', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(mockInvoiceResult));
  }),
  rest.get('/capi/private/customers/*', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(mockCustomer));
  }),
];

export default handlers;
