import { rest } from 'msw';
import { ENDPOINTS } from 'componentsDir/AllSites/constants';

let idIndex = 0;

const handlers = [
  rest.post(`*${ENDPOINTS.SITE_TAGS}*`, (req, res, ctx) => {
    const { name } = req.body as { name: string };
    idIndex += 1;
    return res(
      ctx.status(200),
      ctx.json({ tag: { id: idIndex, name, quantity: null } })
    );
  }),

  rest.post(`*${ENDPOINTS.USER_TAGS}*`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ tags: [{ id: 1, name: 'jack-pappertons' }] })
    );
  }),
];

export default handlers;
