export const MSW_PARAMS = {
  CUSTOM_DOMAIN: 'custom-domain',
  EFPC_ENABLED: 'efpc-enabled',
  FAILED_DOMAINS_FETCH: 'failed-domains-fetch',
  FAILED_DOMAIN_UPDATE: 'failed-domain-update',
  FAILED_INSTALL_FETCH: 'failed-install-fetch',
  FAILED_NETWORK_CACHING_PURGE: 'failed-cdn-caching-purge',
  FAILED_OBJECT_CACHING_ENABLEMENT: 'failed-object-caching-enablement',
  FAILED_OBJECT_CACHING_PURGE: 'failed-object-caching-purge',
  FAILED_PAGE_CACHING_PURGE: 'failed-page-caching-purge',
  PURGE_CACHE_ERROR: 'purge-cache-error',
  SHOW_ECOMM_UPGRADE_BANNER: 'show-ecomm-upgrade-banner',
  SHOW_WHOO_COMM_FEATURE_USAGE_BANNER: 'show-whoo-comm-feature-usage-banner',
  WPENGINE_DOMAIN: 'wpengine-domain',
};

export const ENDPOINTS = {
  CACHE_ERROR: '/cache_error',
  CAPI_ENTITLEMENTS: 'capi/private/entitlements',
  DOMAINS_P1A: '/domains/v1alpha1/environments',
  PSB_INTEGRATION: '/v1alpha1/integrations',
  PURGE_CACHE: '/purge_cache',
  SITES_P1A: '/sites/v1alpha1/environments',
  WHOO_COMM_FEATURE_USAGE_V2: '/whoocomm/v2/feature-usage',
  WHOO_COMM_FEATURE_USAGE_V3: '/whoocomm/v3/feature-usage',
};

export const QUERY_KEYS = {
  GET_DOMAINS: 'get_domains',
  GET_ENVIRONMENT: 'get_environment',
};

export const MUTATION_KEYS = {
  CLEAR_ALL_CACHES: 'clear-all-caches',
  PURGE_ALL_CACHES: 'purge-all-caches',
  PURGE_PAGE_CACHE: 'purge-page-cache',
  PURGE_NETWORK_CACHE: 'purge-network-cache',
  PURGE_OBJECT_CACHE: 'purge-object-cache',
  UPDATE_EXCLUSIONS: 'update_exclusions',
};

export const RATE_LIMITS_COUNTDOWN = { DEFAULT_SECONDS: 5 * 60 };

export const CACHE_PANEL_ACTIONS = {
  DISABLE_BUTTONS_AND_HIDE_ALL_INDIVIDUAL_CACHES_TIMER:
    'DISABLE_BUTTONS_AND_HIDE_ALL_INDIVIDUAL_CACHES_TIMER',
  DISABLE_CLEAR_ALL_CACHES_BUTTON: 'DISABLE_CLEAR_ALL_CACHES_BUTTON',
  DISABLE_CLEAR_NETWORK_CACHE_BUTTON: 'DISABLE_CLEAR_NETWORK_CACHE_BUTTON',
  DISABLE_CLEAR_OBJECT_CACHE_BUTTON: 'DISABLE_CLEAR_OBJECT_CACHE_BUTTON',
  ENABLE_CLEAR_ALL_CACHES_BUTTON: 'ENABLE_CLEAR_ALL_CACHES_BUTTON',
  ENABLE_CLEAR_NETWORK_CACHE_BUTTON: 'ENABLE_CLEAR_NETWORK_CACHE_BUTTON',
  ENABLE_CLEAR_OBJECT_CACHE_BUTTON: 'ENABLE_CLEAR_OBJECT_CACHE_BUTTON',
  ENABLE_CLEAR_PAGE_CACHE_BUTTON: 'ENABLE_CLEAR_PAGE_CACHE_BUTTON',
  ENABLE_EACH_CLEAR_CACHE_BUTTON: 'ENABLE_EACH_CLEAR_CACHE_BUTTON',
  HANDLE_CLEAR_ALL_CACHES_BUTTON: 'HANDLE_CLEAR_ALL_CACHES_BUTTON',
  HIDE_PAGE_CACHE_TIMER: 'HIDE_PAGE_CACHE_TIMER',
  HIDE_CLEAR_ALL_CACHES_TIMER: 'HIDE_CLEAR_ALL_CACHES_TIMER',
  HIDE_NETWORK_TIMER: 'HIDE_NETWORK_TIMER',
  HIDE_OBJECT_TIMER: 'HIDE_OBJECT_TIMER',
  SET_ALL_CACHES_LAST_CLEARED_AT_TO_NOW:
    'SET_ALL_CACHES_LAST_CLEARED_AT_TO_NOW',
  SET_ALL_CACHES_LAST_ERRORED_AT_TO_NOW:
    'SET_ALL_CACHES_LAST_ERRORED_AT_TO_NOW',
  SET_NETWORK_CACHE_LAST_CLEARED_AT_TO_NOW:
    'SET_NETWORK_CACHE_LAST_CLEARED_AT_TO_NOW',
  SET_NETWORK_CACHE_LAST_ERRORED_AT_TO_NOW:
    'SET_NETWORK_CACHE_LAST_ERRORED_AT_TO_NOW',
  SET_OBJECT_CACHE_LAST_CLEARED_AT_TO_NOW:
    'SET_OBJECT_CACHE_LAST_CLEARED_AT_TO_NOW',
  SET_OBJECT_CACHE_LAST_ERRORED_AT_TO_NOW:
    'SET_OBJECT_CACHE_LAST_ERRORED_AT_TO_NOW',
  SET_PAGE_CACHE_LAST_CLEARED_AT_TO_NOW:
    'SET_PAGE_CACHE_LAST_CLEARED_AT_TO_NOW',
  SET_PAGE_CACHE_LAST_ERRORED_AT_TO_NOW:
    'SET_PAGE_CACHE_LAST_ERRORED_AT_TO_NOW',
} as const;

export const SEGMENT = {
  CACHE_UI: 'Cache UI',
  SECTIONS: {
    HEADER: 'Header section',
    BANNER: 'Banner section',
    CLEAR_ALL_CACHE: 'Clear All Cache section',
    PAGE_CACHE: 'Page Cache section',
    NETWORK_CACHE: 'Network Cache section',
    OBJECT_CACHE: 'Object Cache section',
    CACHE_EXCLUSIONS: 'Cache exclusions section',
    CACHE_EXCLUSIONS_MODAL: 'Cache exclusions modal',
  },
  ELEMENTS: {
    BUTTON: 'button',
    LINK: 'link',
    TOGGLE: 'toggle',
    INPUT: 'input',
  },
  ACTIONS: {
    OPEN: 'Open',
    CLOSE: 'Close',
    ON: 'On',
    OFF: 'Off',
    CLICK: 'Click',
  },
};
