import { useEffect, useState } from 'react';

export const usePrefersColorScheme = () => {
  const [prefersDark, setPrefersDark] = useState(
    window && window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  const togglePrefersDarkColorScheme = (e: MediaQueryListEvent): void => {
    setPrefersDark(e.matches);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', togglePrefersDarkColorScheme);

    return () =>
      mediaQuery.removeEventListener('change', togglePrefersDarkColorScheme);
  }, []);

  return {
    prefersDark,
  };
};

export default usePrefersColorScheme;
