import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { use_object_cache_install_cache_dashboard_index_path } from 'railsRoutes';
import { MSW_PARAMS } from 'componentsDir/install/Cache/constants';

const objectCacheEnablementHandler = [
  rest.post(
    `*${use_object_cache_install_cache_dashboard_index_path({
      install_id: ':install_id',
    })}*`,
    (_req, _res, ctx) => {
      if (hasPageUrlQueryParam(MSW_PARAMS.FAILED_OBJECT_CACHING_ENABLEMENT)) {
        return customResponse(ctx.status(500), ctx.json({}));
      }

      return customResponse(ctx.status(200), ctx.json({}));
    }
  ),
];

export default objectCacheEnablementHandler;
