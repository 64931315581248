import { customResponse } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import ENDPOINTS from 'componentsDir/sites/components/AddSite/constants/endpoints';

const handlers = [
  rest.get(`*${ENDPOINTS.INSTALL_NAME_AVAILABLE}*`, (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json({ nameAvailable: true }));
  }),
];

export default handlers;
