export const installLink = (
  accountName: string,
  currentAccountName: string,
  installName: string
): string => {
  return buildLink(
    `/installs/${installName}`,
    '?',
    accountName,
    currentAccountName
  );
};

export const newInstallLink = (
  accountName: string,
  currentAccountName: string,
  siteId: number
): string => {
  return buildLink(
    `/installs/new?site_env=production&site_id=${siteId}`,
    '&',
    accountName,
    currentAccountName
  );
};

export const accountLink = (accountName: string): string => {
  if (!accountName) {
    return '/';
  }

  return `/accounts/${accountName}`;
};

const buildLink = (
  url: string,
  queryStringCharacter: '&' | '?',
  accountName: string,
  currentAccountName: string
): string => {
  if (currentAccountName !== accountName) {
    return `${url}${queryStringCharacter}account_name=${accountName}`;
  }
  return url;
};
