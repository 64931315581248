import { Option } from '@wpengine/unicorn/components/Dropdown/Dropdown';
import { CartAddon } from 'componentsDir/shared/providers/Cart/types/types';
import pluralize from 'pluralize';
import formatCurrency from 'commonsDir/helpers/formatCurrency';
import { SubscriptionPlansV2 } from 'commonsDir/types/BillingMediator';
import { localeArray } from 'componentsDir/shared/providers/Cart/helpers/summary';

const renderPrice = (price: string, currency: string): string => {
  const locale = localeArray[currency] ?? 'en-US';
  return formatCurrency({
    amount: parseInt(price, 10),
    locale,
    currency,
  });
};

const buildAddonOptions = (
  addon: CartAddon,
  subscriptionPlan: SubscriptionPlansV2
): Option[] => {
  const { prices } = addon;

  return prices
    .sort((a, b) => parseInt(a.price, 10) - parseInt(b.price, 10))
    .map(price => {
      const label = pluralize('license', price.quantity, false);
      const disabled =
        addon.amount_owned > 0 ? price.quantity <= addon.amount_owned : false;
      const { currency } = subscriptionPlan;

      return {
        disabled,
        label: `${price.quantity} ${label}`,
        value: `${price.quantity}`,
        price: renderPrice(price.price, currency),
        priceLabel: '/mo',
      };
    });
};

export { renderPrice, buildAddonOptions };
