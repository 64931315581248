import { SslCertificate, SslCsr } from '../types';

// Helper function to generate deterministic SSL certificates
export const generateSslCertificates = (count: number): SslCertificate[] => {
  const certificates: SslCertificate[] = [];
  const statuses: SslCertificate['status'][] = [
    'New',
    'Pending',
    'Upload Certificate',
    'Processing',
    'Secure specific URLs',
    'Enabled',
    'Disabled',
    'Contact Support',
  ];
  const types: SslCertificate['type'][] = [
    '3rd-party',
    "Let's Encrypt™",
    'RapidSSL',
    'WP Engine',
  ];

  for (let i = 0; i < count; i += 1) {
    const id = i + 1;
    const certificate: SslCertificate = {
      id,
      installId: Math.floor(id / 10) + 1,
      expiry: new Date(Date.now() + id * 86400000).toISOString(), // Each cert expires 1 day later than the previous
      name: `certificate${id}.wpengine.com`,
      status: statuses[id % statuses.length],
      type: types[id % types.length],
      sslDomains: [],
      canDelete: id % 3 === 0,
      canManageUrls: true,
    };

    if (id % 5 === 0) {
      certificate.sslCsr = generateSslCsr(id);
      certificate.sslDomains = generateSslDomainName(certificate.name, id);
    }

    if (id % 2 === 0) {
      certificate.sslDomains = [`www.${certificate.name}`];
    }

    certificates.push(certificate);
  }

  return certificates;
};

export const generateSslDomainName = (name: string, id: number) => {
  const domains: string[] = [];
  for (let i = 1; i <= id; i += 1) {
    domains.push(`${i}.${name}`);
  }
  return domains;
};

// Helper function to generate SSL CSR
export const generateSslCsr = (id: number): SslCsr => {
  const certificateTypes: SslCsr['certificateType'][] = [
    'Standard',
    'Wildcard',
    'Multidomain',
  ];
  return {
    id,
    sslCertificateId: id,
    certificateType: certificateTypes[id % certificateTypes.length],
    csr: generateRandomCSR(),
    email: `admin${id}@example.com`,
    city: 'Example City',
    state: 'Example State',
    country: 'US',
    company: 'Example Company',
    commonName: `example${id}.com`,
    department: 'IT',
    createdAt: new Date(Date.now() - id * 86400000).toISOString(),
    updatedAt: new Date().toISOString(),
    otherDomains: [`sub1.example${id}.com`, `sub2.example${id}.com`],
  };
};

const generateRandomCSR = (): string => {
  const generateRandomBase64 = (length: number): string => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    return Array.from(
      { length },
      () => characters[Math.floor(Math.random() * characters.length)]
    ).join('');
  };

  const wrapLines = (text: string, lineLength: number): string => {
    const lines = text.match(new RegExp(`.{1,${lineLength}}`, 'g')) || [];
    return lines.join('\n');
  };

  const csrContent = generateRandomBase64(1024); // Adjust the length as needed
  const wrappedContent = wrapLines(csrContent, 64);

  return `-----BEGIN CERTIFICATE REQUEST-----\n${wrappedContent}\n-----END CERTIFICATE REQUEST-----`;
};

// Generate 250 SSL certificates
export const allSslCertificates = generateSslCertificates(250);
