import * as React from 'react';
import { Stack, Divider, Box, Popover } from '@mui/material';
import { SupportIcon, CloseSmallIcon } from '@wpengine/unicorn/icons';
import { IconButton, Typography } from '@wpengine/unicorn/components';
import useEscapeKeyHandler from 'commonsDir/hooks/useEscapeKeyHandler';
import { useTopNavContext } from '../../contexts/TopNavProvider';
import NavIconButton from '../NavIconButton';
import LargeViewContent from './LargeViewContent';
import SmallViewContent from './SmallViewContent';
import TopNavDrawer from '../TopNavDrawer';

const SupportDropdown = () => {
  const { smallScreen } = useTopNavContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'support-menu' : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useEscapeKeyHandler(() => setAnchorEl(null));

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box flexShrink="0">
      <NavIconButton active={open} onClick={handleClick} title="Support Menu">
        <SupportIcon />
      </NavIconButton>
      {smallScreen ? (
        <TopNavDrawer open={open} onClose={handleClose}>
          <Stack
            alignItems="center"
            direction="row"
            flexWrap="wrap"
            gap={2}
            justifyContent="space-between"
            padding={theme => theme.spacing(0.5, 1.5, 0.5, 2.5)}
            width="100%"
          >
            <Typography variant="h4">Support</Typography>
            <IconButton color="default" onClick={handleClose}>
              <CloseSmallIcon />
            </IconButton>
          </Stack>
          <Divider />
          <Box sx={theme => ({ padding: theme.spacing(1.5) })}>
            <SmallViewContent />
          </Box>
        </TopNavDrawer>
      ) : (
        <Popover
          id={id}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          elevation={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={theme => ({
            marginTop: theme.spacing(2),
          })}
        >
          <LargeViewContent />
        </Popover>
      )}
    </Box>
  );
};

export default SupportDropdown;
