enum ActivationStatus {
  NEW = 'NEW',
  ACTIVATING = 'ACTIVATING',
  ACTIVE = 'ACTIVE',
  DEACTIVATING = 'DEACTIVATING',
  INACTIVE = 'INACTIVE',
  FAILED_TO_ACTIVATE = 'FAILED_TO_ACTIVATE',
  FAILED_TO_DEACTIVATE = 'FAILED_TO_DEACTIVATE',
  FAILED_TO_SUSPEND = 'FAILED_TO_SUSPEND',
  SUSPENDING = 'SUSPENDING',
  SUSPENDED = 'SUSPENDED',
  UNSUSPENDING = 'UNSUSPENDING',
}

export default ActivationStatus;
