import * as React from 'react';
import { gray900, gray100, gray1200 } from 'design-tokens';
import { styled } from '@mui/material/styles';
import { IconButton } from '@wpengine/unicorn/components';
import {
  DoubleChevronLeftIcon,
  DoubleChevronRightIcon,
} from '@wpengine/unicorn/icons';

const StyledIconButton = styled(IconButton)({
  color: gray900,
  height: '32px',
  margin: '10px 12px',
  minHeight: '32px',
  width: '32px',
  '&:focus, &:active': {
    color: gray900,
  },
  '&:hover': {
    backgroundColor: gray100,
    color: gray1200,
  },
});

export interface SideNavToggleProps {
  collapse: boolean;
  onClick: () => void;
}

const SideNavToggle: React.FC<SideNavToggleProps> = ({ collapse, onClick }) => {
  return (
    <StyledIconButton
      className="focusable"
      title={collapse ? 'Expand menu' : 'Collapse menu'}
      onClick={onClick}
      sx={{ alignSelf: collapse ? 'center' : 'flex-end' }}
    >
      {collapse ? <DoubleChevronRightIcon /> : <DoubleChevronLeftIcon />}
    </StyledIconButton>
  );
};

export default SideNavToggle;
