import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const ClearTextIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="clear-text-icon" ref={ref} {...props}>
        <path d="M9.29289 8.29289C9.68342 7.90237 10.3166 7.90237 10.7071 8.29289L13 10.5858L15.2929 8.29289C15.6834 7.90237 16.3166 7.90237 16.7071 8.29289C17.0976 8.68342 17.0976 9.31658 16.7071 9.70711L14.4142 12L16.7071 14.2929C17.0976 14.6834 17.0976 15.3166 16.7071 15.7071C16.3166 16.0976 15.6834 16.0976 15.2929 15.7071L13 13.4142L10.7071 15.7071C10.3166 16.0976 9.68342 16.0976 9.29289 15.7071C8.90237 15.3166 8.90237 14.6834 9.29289 14.2929L11.5858 12L9.29289 9.70711C8.90237 9.31658 8.90237 8.68342 9.29289 8.29289Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.25685 12.9805C0.914384 12.3717 0.914384 11.6283 1.25685 11.0195L5.47012 3.52922C6.00147 2.58459 7.00103 2 8.08485 2H20C21.6569 2 23 3.34315 23 5V19C23 20.6569 21.6569 22 20 22H8.08485C7.00103 22 6.00148 21.4154 5.47012 20.4708L1.25685 12.9805ZM3 12L7.21327 19.4903C7.39039 19.8051 7.72357 20 8.08485 20H20C20.5523 20 21 19.5523 21 19V5C21 4.44772 20.5523 4 20 4H8.08485C7.72357 4 7.39039 4.19486 7.21327 4.50974L3 12Z"
        />
      </SvgTemplate>
    );
  }
);
export default ClearTextIcon;
