export const generateSampleFeatureV3Meta = (
  data?: Partial<FeatureV3Meta>
): FeatureV3Meta => {
  return {
    enabled: false,
    last_checked: '2021-09-09T00:00:00Z',
    ...data,
  };
};

export const generateSampleFeatureUsageV3Data = (
  data?: Partial<FeatureV3List & { installName: string; page_token: string }>
): FeatureUsageV3Response => {
  const featureV3MetaSample = generateSampleFeatureV3Meta();

  return {
    results: [
      {
        [data?.installName || 'install0']: {
          woocommerce: featureV3MetaSample,
          elasticsearch: featureV3MetaSample,
          spm: featureV3MetaSample,
          quickstoresetup: featureV3MetaSample,
          genesispro: featureV3MetaSample,
          evercache: featureV3MetaSample,
          livecart: featureV3MetaSample,
          wpe_payments: featureV3MetaSample,
          ...data,
        },
      },
    ],
    page_token: data?.page_token || '1',
  };
};
