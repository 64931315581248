import * as React from 'react';
import { ButtonProps } from '@mui/material';
import { gray50, gray100, blue700, blue800 } from 'design-tokens';
import SideNavContext from '../SideNavContext';
import SideNavButton from '../SideNavButton/SideNavButton';

interface CollapseButtonProps extends ButtonProps {
  active?: boolean;
  label: string;
}

const CollapseButton: React.FC<CollapseButtonProps> = ({
  active,
  sx,
  label,
  title,
  ...props
}) => {
  const { collapsed } = React.useContext(SideNavContext);
  const show = !collapsed;
  return (
    <SideNavButton
      sx={{
        margin: collapsed ? '2px 12px' : '0 12px',
        padding: collapsed ? '8px' : '10px 8px',
        width: collapsed ? '36px' : 'auto',
        ...(active && {
          backgroundColor: gray100,
          color: blue700,
          '.MuiTypography-root': {
            fontWeight: '700',
          },
          '&:focus, &:focus-visible, &:hover': {
            backgroundColor: gray50,
            color: blue800,
          },
        }),
        ...sx,
      }}
      label={show ? label : ''}
      title={title || label}
      {...props}
    />
  );
};

export default CollapseButton;
