import { rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';

const handlers = [
  rest.get('*/themes', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({
        themes: [
          {
            id: 622980,
            thumbnail_url:
              'https://my.studiopress.com/wp-content/uploads/navigation-pro-190x190.jpg',
            title: 'Navigation Pro Theme Package',
            description: null,
            version: '1.2.1',
            last_updated: '1/10/23',
            demo_url: 'https://demo.studiopress.com/navigation/',
            slug: 'navigation',
            setup_id: 623607,
            setup_path: 'navigation-pro-theme/',
            download_url:
              'https://my.studiopress.com/?download_id=277215ce38d4b1041d02d63ce0ec7295',
          },
          {
            id: 580713,
            thumbnail_url:
              'https://my.studiopress.com/wp-content/uploads/revolution-pro-lifestyle-190x190.png',
            title: 'Revolution Pro Theme Package',
            description: null,
            version: '1.5.1',
            last_updated: '1/10/23',
            demo_url: 'https://demo.studiopress.com/revolution/',
            slug: 'revolution',
            setup_id: 581469,
            setup_path: 'revolution-pro-theme/',
            download_url:
              'https://my.studiopress.com/?download_id=c1a0587a2e13eefb451b8d2ac06d18c4',
          },
        ],
        genesis_sample_theme: {
          id: 13414,
          thumbnail_url:
            'https://my.studiopress.com/wp-content/uploads/genesis-sample-190x190.jpg',
          title: 'Genesis Sample Theme',
          description: 'Build a custom theme using this sample.',
          version: '3.4.2',
          last_updated: '12/19/22',
          demo_url: 'https://demo.studiopress.com/genesis-sample/',
          slug: 'genesis-sample',
          setup_id: 329969,
          setup_path: 'genesis-sample-theme/',
          download_url:
            'https://my.studiopress.com/?download_id=22caf4a827e98c890d7a964175aa9872',
        },
        genesis_framework: {
          latest_version: '3.4.0',
          filename: 'genesis.3.4.0.zip',
        },
      })
    );
  }),
  rest.get('*/theme_favorites', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({
        favorite_themes: [],
      })
    );
  }),
  rest.get('*/sites', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json({}));
  }),
];

export default handlers;
