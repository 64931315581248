export default {
  originAccountName: 'Account 2',
  originEnvironmentName: 'originEnvName',
  destinationAccountName: 'destinationAccount',
  environmentDropdownOptions: [
    {
      label: 'site1',
      className: 'selector-site-name',
      options: [
        {
          label: 'envDev',
          value: {
            site: '529d6f45-0b1c-47c8-b214-15f9363b288f',
            site_env: 'development',
          },
          disabled: true,
        },
        {
          label: 'envProd',
          value: {
            site: '529d6f45-0b1c-47c8-b214-15f9363b288f',
            site_env: 'production',
          },
          disabled: true,
        },
        {
          label: 'envStaging',
          value: {
            site: '529d6f45-0b1c-47c8-b214-15f9363b288f',
            site_env: 'staging',
          },
          disabled: true,
        },
      ],
    },
    {
      label: 'site2',
      className: 'selector-site-name',
      options: [
        {
          label: 'site2EnvProd',
          value: {
            site: '5319379f-da5f-438d-9950-b333e1e9764c',
            site_env: 'production',
          },
          disabled: true,
        },
        {
          label: 'Available - STAGE',
          value: {
            site: '5319379f-da5f-438d-9950-b333e1e9764c',
            site_env: 'staging',
          },
          disabled: false,
        },
        {
          label: 'Available - DEV',
          value: {
            site: '5319379f-da5f-438d-9950-b333e1e9764c',
            site_env: 'development',
          },
          disabled: false,
        },
      ],
    },
  ],
  customDomainRestricted: false,
  users: [
    {
      uuid: '42d3c5ae-9bad-423b-b441-37528cc865aa',
      name: 'Albert Duchnicz',
      email: 'user1@gmail.com',
    },
    {
      uuid: '7537eed1-2220-4af1-97a8-2ebf462a2c16',
      name: 'Alvaro Cavalcanti',
      email: 'user2@wpengine.com',
    },
  ],
};
