import { Cart } from '../types/types';

const initialCartState: Cart = {
  id: '',
  account: {
    portal_account_uuid: '',
    name: '',
    plan: '',
    subscription: '',
  },
  new_plan: null,
  selected_addons: [],
  user: {
    email: '',
  },
};

export default initialCartState;

// export const addonData = (
//   subscription: Subscription,
//   plan: SubscriptionPlansV2,
//   base_sku: string
// ) => {
//   const subscriptionPlanAddon = findSubscriptionPlanAddon(
//     subscription,
//     base_sku
//   );
//   const planAddon = findPlanAddon(plan, base_sku);
//   const subscriptionAddon = findSubscriptionAddon(subscription, base_sku);

//   const subscriptionAddonQuantity = subscriptionAddon?.quantity ?? 0;
//   const subscriptionOriginalAddonQuantity =
//     subscriptionAddon?.original_quantity;
//   const subscriptionPlanAddonQuantity = subscriptionPlanAddon?.quantity ?? 0;
//   const planAddonQuantity = planAddon?.quantity ?? 0;

//   return {
//     subscriptionAddonQuantity,
//     subscriptionOriginalAddonQuantity,
//     subscriptionPlanAddonQuantity,
//     planAddonQuantity,
//   };
// };

// const calculateNewPlanSiteQuantity = (
//   subscription: Subscription,
//   plan: SubscriptionPlansV2
// ) => {
//   const {
//     subscriptionAddonQuantity,
//     subscriptionOriginalAddonQuantity,
//     subscriptionPlanAddonQuantity,
//     planAddonQuantity,
//   } = addonData(subscription, plan, 'sites');

//   const sitesAddonQuantity =
//     (subscriptionOriginalAddonQuantity ?? subscriptionAddonQuantity) +
//     subscriptionPlanAddonQuantity -
//     planAddonQuantity;
//   return Math.max(sitesAddonQuantity, 0);
// };

// export const addonsList = (
//   addons: AddonInfo[],
//   subscription: Subscription,
//   newPlan: SubscriptionPlansV2
// ) => {
//   const selectedAddons = Object.values(addons).filter(
//     addon => addon.quantity > 0
//   );
//   const replaceable = (addon: Addon) => {
//     const selected = selectedAddons.find(
//       selectedAddon => selectedAddon.base_sku === addon.base_sku
//     );
//     return selected ? selected.replaceable : false;
//   };

//   /* eslint no-param-reassign: "error" */
//   const filteredSubscriptionAddons = subscription.addons.filter(addon => {
//     if (newPlan && addon.base_sku === 'sites') {
//       addon.original_quantity = addon.original_quantity ?? addon.quantity;
//       addon.quantity = calculateNewPlanSiteQuantity(subscription, newPlan);
//     }

//     return addon.quantity > 0 && !replaceable(addon);
//   });

//   return { selectedAddons, filteredSubscriptionAddons };
// };

// export const calcSelectedAddonPrice = (
//   addon: AddonInfo,
//   planAddons: Array<PlanAddon>
// ) => {
//   let price = 0;

//   const currentAddon = planAddons.find(
//     planAddon =>
//       addon.base_sku === planAddon.base_sku &&
//       !planAddon.product.includes('-bundled')
//   );

//   if (currentAddon.prices.length === 1) {
//     price = addon.quantity * Number(currentAddon.prices[0].price);
//   } else {
//     const priceObject = currentAddon.prices.find(
//       prices => prices.quantity === addon.quantity
//     );
//     if (priceObject) {
//       price = Number(priceObject.price);
//     }
//   }

//   return price;
// };

// export const calcSubscriptionAddonPrice = (
//   addon: Addon,
//   planAddons: Array<PlanAddon>,
//   subscriptionAddons: Array<Addon>
// ) => {
//   // Subscription data doesn't have any pricing in it so we need to get the pricing from the plan
//   const currentSubscriptionAddon = subscriptionAddons.find(
//     planAddon => addon.base_sku === planAddon.base_sku
//   );

//   if (currentSubscriptionAddon) {
//     const planAddonPrices = planAddons
//       .find(
//         planAddon =>
//           addon.base_sku === planAddon.base_sku &&
//           !planAddon.product.includes('-bundled')
//       )
//       ?.prices.sort((a, b) => {
//         return a.quantity - b.quantity;
//       });

//     const currentPrice = planAddonPrices
//       ?.filter(price => price.quantity <= currentSubscriptionAddon.quantity)
//       .slice(-1)[0];

//     if (currentPrice) {
//       const addonQuantity =
//         addon.base_sku === 'local-storage'
//           ? currentSubscriptionAddon.quantity / 250
//           : currentSubscriptionAddon.quantity;
//       const price = Number(currentPrice.price) / currentPrice.quantity;
//       return Number(price * addonQuantity);
//     }
//   }

//   return 0;
// };

// export const calcTotalAddonsPrice = (
//   addons: AddonInfo[],
//   plan: SubscriptionPlansV2,
//   subscription: Subscription,
//   newPlan: boolean
// ): number => {
//   let totalPrice = 0;
//   const { selectedAddons, filteredSubscriptionAddons } = addonsList(
//     addons,
//     subscription,
//     newPlan ? plan : null
//   );

//   // We need to add both existing addons and newly selected addons
//   selectedAddons.forEach(addon => {
//     totalPrice += calcSelectedAddonPrice(addon, plan?.addons);
//   });

//   filteredSubscriptionAddons.forEach(addon => {
//     totalPrice += calcSubscriptionAddonPrice(
//       addon,
//       plan?.addons,
//       subscription?.addons
//     );
//   });

//   return totalPrice;
// };
