import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const AISmartSearch: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="AISmartSearch logo" viewBox="0 0 64 64" {...props}>
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_793_820)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8928 41.8927C37.2833 41.5022 37.9165 41.5022 38.307 41.8927L44.707 48.2927C45.0975 48.6832 45.0975 49.3164 44.707 49.7069C44.3165 50.0974 43.6833 50.0974 43.2928 49.7069L36.8928 43.3069C36.5023 42.9164 36.5023 42.2832 36.8928 41.8927Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1714 43.3429C32.8935 43.3429 38.3429 37.8935 38.3429 31.1714C38.3429 24.4493 32.8935 19 26.1714 19C19.4493 19 14 24.4493 14 31.1714C14 37.8935 19.4493 43.3429 26.1714 43.3429ZM26.1714 45.3429C33.9981 45.3429 40.3429 38.9981 40.3429 31.1714C40.3429 23.3448 33.9981 17 26.1714 17C18.3448 17 12 23.3448 12 31.1714C12 38.9981 18.3448 45.3429 26.1714 45.3429Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7813 23.3342C28.2944 22.6494 26.6435 22.4016 25.0211 22.6199C23.3988 22.8381 21.8719 23.5134 20.6189 24.5668C20.1962 24.9222 19.5654 24.8676 19.21 24.4448C18.8546 24.0221 18.9092 23.3913 19.3319 23.0359C20.8754 21.7383 22.7561 20.9066 24.7545 20.6377C26.7529 20.3689 28.7865 20.6741 30.618 21.5176C32.4494 22.3612 34.0032 23.7084 35.0977 25.4018C36.1923 27.0953 36.7826 29.0651 36.7997 31.0815C36.8044 31.6337 36.3604 32.0852 35.8082 32.0899C35.2559 32.0946 34.8044 31.6507 34.7997 31.0984C34.7859 29.4615 34.3067 27.8623 33.4181 26.4875C32.5295 25.1127 31.2681 24.0191 29.7813 23.3342Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6409 25.0886C19.1041 25.3894 19.2357 26.0088 18.9349 26.472C18.6888 26.8511 18.4727 27.2489 18.2889 27.6619C18.0643 28.1664 17.4731 28.3933 16.9686 28.1687C16.4641 27.944 16.2372 27.3529 16.4618 26.8484C16.6883 26.3397 16.9544 25.8496 17.2576 25.3827C17.5584 24.9195 18.1777 24.7879 18.6409 25.0886Z"
        fill="#006BD6"
      />
      <path
        d="M48.0194 22.0971C48.0065 22.0325 48.0016 21.9666 47.9952 21.9011C47.9455 21.3952 47.5189 21 47 21C46.4811 21 46.0545 21.3952 46.0048 21.9011C45.9984 21.9666 45.9935 22.0325 45.9806 22.0971L45.7974 23.0129C45.7182 23.4088 45.4088 23.7182 45.0129 23.7974L44.0971 23.9806C44.0325 23.9935 43.9666 23.9984 43.9011 24.0048C43.3952 24.0545 43 24.4811 43 25C43 25.5189 43.3952 25.9455 43.9011 25.9952C43.9666 26.0016 44.0325 26.0065 44.0971 26.0194L45.0129 26.2026C45.4088 26.2818 45.7182 26.5912 45.7974 26.9871L45.9806 27.9029C45.9935 27.9675 45.9984 28.0334 46.0048 28.0989C46.0545 28.6048 46.4811 29 47 29C47.5189 29 47.9455 28.6048 47.9952 28.0989C48.0016 28.0334 48.0065 27.9675 48.0194 27.9029L48.2026 26.9871C48.2818 26.5912 48.5912 26.2818 48.9871 26.2026L49.9029 26.0194C49.9675 26.0065 50.0334 26.0016 50.0989 25.9952C50.6048 25.9455 51 25.5189 51 25C51 24.4811 50.6048 24.0545 50.0989 24.0048C50.0334 23.9984 49.9675 23.9935 49.9029 23.9806L48.9871 23.7974C48.5912 23.7182 48.2818 23.4088 48.2026 23.0129L48.0194 22.0971Z"
        fill="#006BD6"
      />
      <path
        d="M42.2694 13.3471C42.2565 13.2825 42.2513 13.2166 42.2461 13.151C42.1957 12.5069 41.657 12 41 12C40.343 12 39.8043 12.5069 39.7539 13.151C39.7487 13.2166 39.7435 13.2825 39.7306 13.3471L39.4641 14.6796C39.3849 15.0755 39.0755 15.3849 38.6796 15.4641L37.3471 15.7306C37.2825 15.7435 37.2166 15.7487 37.151 15.7539C36.5069 15.8043 36 16.343 36 17C36 17.657 36.5069 18.1957 37.151 18.2461C37.2166 18.2513 37.2825 18.2565 37.3471 18.2694L38.6796 18.5359C39.0755 18.6151 39.3849 18.9245 39.4641 19.3204L39.7306 20.6529C39.7435 20.7175 39.7487 20.7834 39.7539 20.849C39.8043 21.4931 40.343 22 41 22C41.657 22 42.1957 21.4931 42.2461 20.849C42.2513 20.7834 42.2565 20.7175 42.2694 20.6529L42.5359 19.3204C42.6151 18.9245 42.9245 18.6151 43.3204 18.5359L44.6529 18.2694C44.7175 18.2565 44.7834 18.2513 44.849 18.2461C45.4931 18.1957 46 17.657 46 17C46 16.343 45.4931 15.8043 44.849 15.7539C44.7834 15.7487 44.7175 15.7435 44.6529 15.7306L43.3204 15.4641C42.9245 15.3849 42.6151 15.0755 42.5359 14.6796L42.2694 13.3471Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_793_820"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default AISmartSearch;
