import React from 'react';
import { Box, Typography } from '@wpengine/unicorn/components';
import { gray300, gray900 } from 'design-tokens';
import { useCartSummaryContext } from 'componentsDir/shared/providers/Cart/contexts/CartSummaryContext';

const Header: React.FC = () => {
  const { itemCount } = useCartSummaryContext();

  return (
    <Box display="flex" alignItems="baseline" bgcolor={gray300} p={2}>
      <Typography variant="h4">My cart</Typography>
      <Typography variant="h4" color={gray900} ml={1}>
        ({itemCount})
      </Typography>
    </Box>
  );
};

export default Header;
