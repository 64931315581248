import * as React from 'react';
import { borderRadiusMedium, gray50, gray100 } from 'design-tokens';
import { ButtonProps, useTheme } from '@mui/material';
import Button from '../Button';

const NavItem = React.forwardRef(
  (
    {
      active = false,
      autoFocus = false,
      children,
      size = 'normal',
      sx,
      target,
      ...props
    }: Omit<ButtonProps, 'size'> & {
      active?: boolean;
      autoFocus?: boolean;
      size?: 'small' | 'normal';
      target?: string;
    },
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const theme = useTheme();

    React.useEffect(() => {
      if (autoFocus) {
        (ref as React.MutableRefObject<HTMLButtonElement>)?.current?.focus();
      }
    }, [autoFocus]);

    let styles;
    if (typeof sx === 'function') {
      styles = sx(theme);
    } else {
      styles = sx;
    }

    return (
      <Button
        ref={ref}
        sx={{
          '&&&': {
            alignItems: 'center',
            backgroundColor: 'inherit',
            border: 0,
            borderRadius: borderRadiusMedium,
            color: theme.palette.secondary.light,
            display: 'flex',
            gap: '8px',
            justifyContent: 'flex-start',
            padding: size === 'normal' ? '12px' : '8px',
            '&:focus-visible': {
              outlineColor: theme.palette.primary[90],
              outlineWidth: '2px',
              outlineStyle: 'solid',
              outlineOffset: '2px',
            },
            '&:focus-visible, &:hover': {
              color: theme.palette.secondary.main,
              backgroundColor: gray50,
            },
            '.MuiButton-startIcon, .MuiButton-endIcon': {
              margin: 0,
              '.MuiSvgIcon-root': {
                fontSize: size === 'normal' ? '24px' : '20px',
              },
            },
            '.MuiButton-endIcon': {
              marginLeft: 'auto',
            },
            ...(active && {
              backgroundColor: gray100,
              color: theme.palette.primary.main,
              '.MuiTypography-root': {
                fontWeight: '700',
              },
              '&:focus, &:focus-visible, &:hover': {
                backgroundColor: gray50,
                color: theme.palette.primary.dark,
              },
            }),
          },
          ...styles,
        }}
        target={target}
        variant="text"
        {...props}
      >
        {children}
      </Button>
    );
  }
);

export default NavItem;
