import { rest } from 'msw';
import { singleErrorLog } from './data/error_logs';

const handlers = [
  // GET /installs/*/error_logs_feed_data
  rest.get(`*/installs/:installName/error_logs_feed_data`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        logs: singleErrorLog,
      })
    );
  }),
];

export default handlers;
