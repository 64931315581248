import { rest } from 'msw';
import {
  data_install_checkpoints_path,
  download_install_checkpoints_path,
  install_checkpoints_path,
  restore_install_checkpoints_path,
} from 'railsRoutes';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { Backup } from 'componentsDir/install/Backups/types';

const checkpointsResponse: Backup[] = [
  {
    archives: [
      {
        destinationUrl: 'https://fulldownload.example.com',
        filters: ['Entire database'],
        initiatedOn: '2020-08-20T18:57:16.123456Z',
      },
      {
        destinationUrl: 'https://partialdownload.example.com',
        filters: [
          'Entire database',
          'Plugins',
          'Media uploads',
          'Everything else',
        ],
        initiatedOn: '2020-08-20T18:57:16.123456Z',
      },
    ],
    commit: {
      info: 'Nov 28 9:20AM UTC (14 days ago) - daily checkpoint',
    },
    spec: {
      description: 'my fancy backup checkpoint',
    },
    status: {
      completedOn: '2023-11-11T23:47:10Z',
      restoreId: '123456789',
    },
    uuid: 'f8a9d74f-724b-4616-94e0-b14cc5f0182a',
  },
  {
    archives: [
      {
        destinationUrl: 'https://fullsingledownload.example.com',
        filters: ['Entire database'],
        initiatedOn: '2020-08-20T18:57:16.123456Z',
      },
    ],
    commit: {
      info: 'Nov 28 9:20AM UTC (14 days ago) - daily checkpoint',
    },
    spec: {
      description: 'my other backup checkpoint',
    },
    status: {
      completedOn: '2023-11-11T23:48:18Z',
      restoreId: '111111111',
    },
    uuid: '6542a7b8-f186-4d4a-8795-88251e23b6f4',
  },
];

const handlers = [
  rest.get(
    `*${data_install_checkpoints_path(':installName')}*`,
    (req, res, ctx) => {
      if (hasPageUrlQueryParam('empty')) {
        return customResponse(
          ctx.status(200),
          ctx.json({
            backups: [],
            recipients: [
              'lady-blossom@wpengine.com',
              'jack-pappertons@wpengine.com',
            ],
          })
        );
      }
      return customResponse(
        ctx.status(200),
        ctx.json({
          backups: checkpointsResponse,
          backupOptions: [],
          recipients: [
            'lady-blossom@wpengine.com',
            'jack-pappertons@wpengine.com',
          ],
          restoreToOptions: [
            {
              value: 'bogus',
              label: 'bogus',
              environment: 'produciton',
              back_ups_url: '',
              site: 'My fancy bogus site',
              disabled: false,
              transferable: false,
              move_only_to_production: false,
            },
          ],
        })
      );
    }
  ),

  rest.post(`*${install_checkpoints_path(':installName')}`, (req, res, ctx) => {
    if (hasPageUrlQueryParam('errorBackupInProgress')) {
      return customResponse(
        ctx.status(429),
        ctx.json({
          msg:
            'A backup is already in progress. Please try again after it completes.',
        })
      );
    }
    return customResponse(
      ctx.status(200),
      ctx.json({
        msg:
          'Backup started. You will be notified via email when it has completed.',
      })
    );
  }),

  rest.post(
    `*${download_install_checkpoints_path(':installName')}`,
    (req, res, ctx) => {
      if (hasPageUrlQueryParam('errorDownloadInProgress')) {
        return customResponse(
          ctx.status(429),
          ctx.json({
            msg:
              'A download is already in progress. Please try again after it completes.',
          })
        );
      }
      return customResponse(
        ctx.status(200),
        ctx.json({
          msg: `Your download request has started. We'll email you when it's ready.`,
        })
      );
    }
  ),

  rest.post(
    `*${restore_install_checkpoints_path(':installName')}`,
    (req, res, ctx) => {
      if (hasPageUrlQueryParam('errorRestoreInProgress')) {
        return customResponse(
          ctx.status(429),
          ctx.json({
            msg:
              'A restore is already in progress. Please try again after it completes.',
          })
        );
      }
      return customResponse(
        ctx.status(200),
        ctx.json({
          msg: `Your restore has started. We'll email you when it's done.`,
        })
      );
    }
  ),
];

export default handlers;
