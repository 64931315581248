import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const WpEngineIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate
        viewBox="0 0 208 208"
        name="wp-engine-icon"
        ref={ref}
        {...props}
      >
        <path d="M133 208C134.66 208 136 206.66 136 205V162.49C136 160.9 135.37 159.37 134.24 158.25L121.75 145.76C120.62 144.63 119.1 144 117.51 144H90.48C88.89 144 87.36 144.63 86.24 145.76L73.75 158.25C72.62 159.38 71.99 160.9 71.99 162.49V205C71.99 206.66 73.33 208 74.99 208H133Z" />
        <path d="M158.24 73.76L145.75 86.25C144.62 87.38 143.99 88.9 143.99 90.49V117.52C143.99 119.11 144.62 120.64 145.75 121.76L158.24 134.25C159.37 135.38 160.89 136.01 162.48 136.01H204.99C206.65 136.01 207.99 134.67 207.99 133.01V75.01C207.99 73.35 206.65 72.01 204.99 72.01H162.48C160.89 72.01 159.36 72.64 158.24 73.77V73.76Z" />
        <path d="M75 0C73.34 0 72 1.34 72 3V45.51C72 47.1 72.63 48.63 73.76 49.75L86.25 62.24C87.38 63.37 88.9 64 90.49 64H117.52C119.11 64 120.64 63.37 121.76 62.24L134.25 49.75C135.38 48.62 136.01 47.1 136.01 45.51V3C136.01 1.34 134.67 0 133.01 0H75Z" />
        <path d="M205 144H162.49C160.9 144 159.37 144.63 158.25 145.76L145.76 158.25C144.63 159.38 144 160.9 144 162.49V205C144 206.66 145.34 208 147 208H205C206.66 208 208 206.66 208 205V147C208 145.34 206.66 144 205 144Z" />
        <path d="M61 0H18.49C16.89 0 15.37 0.63 14.24 1.76L1.76 14.24C0.63 15.37 0 16.89 0 18.49V61C0 62.66 1.34 64 3 64H45.51C47.1 64 48.63 63.37 49.75 62.24L62.24 49.75C63.37 48.62 64 47.1 64 45.51V3C64 1.34 62.66 0 61 0Z" />
        <path d="M144 3V45.51C144 47.1 144.63 48.63 145.76 49.75L158.25 62.24C159.38 63.37 160.9 64 162.49 64H205C206.66 64 208 62.66 208 61V3C208 1.34 206.66 0 205 0H147C145.34 0 144 1.34 144 3Z" />
        <path d="M104 120C95.16 120 88 112.84 88 104C88 95.16 95.17 88 104 88C112.83 88 120 95.16 120 104C120 112.84 112.83 120 104 120Z" />
        <path d="M0 147V205C0 206.66 1.34 208 3 208H61C62.66 208 64 206.66 64 205V162.49C64 160.9 63.37 159.37 62.24 158.25L49.75 145.76C48.62 144.63 47.1 144 45.51 144H3C1.34 144 0 145.34 0 147Z" />
        <path d="M62.24 86.24L49.75 73.75C48.62 72.62 47.1 71.99 45.51 71.99H3C1.34 72 0 73.34 0 75V133C0 134.66 1.34 136 3 136H46.2C47.79 136 49.32 135.37 50.44 134.24L62.24 122.45C63.37 121.32 64 119.8 64 118.21V90.49C64 88.9 63.37 87.37 62.24 86.25V86.24Z" />
      </SvgTemplate>
    );
  }
);
export default WpEngineIcon;
