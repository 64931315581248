import { rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';

const handlers = [
  rest.get('*/your/api/endpoint', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({ message: 'Hello User Portal 👋' })
    );
  }),
];

export default handlers;
