import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const BillingIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="billing-icon" ref={ref} {...props}>
        <path d="M7.00006 8.00006C7.00006 7.44778 7.44778 7.00006 8.00006 7.00006H16.0001C16.5523 7.00006 17.0001 7.44778 17.0001 8.00006C17.0001 8.55235 16.5523 9.00006 16.0001 9.00006H8.00006C7.44778 9.00006 7.00006 8.55235 7.00006 8.00006Z" />
        <path d="M8.00006 11.0001C7.44778 11.0001 7.00006 11.4478 7.00006 12.0001C7.00006 12.5523 7.44778 13.0001 8.00006 13.0001H16.0001C16.5523 13.0001 17.0001 12.5523 17.0001 12.0001C17.0001 11.4478 16.5523 11.0001 16.0001 11.0001H8.00006Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.05407 1.67503C3.09386 1.5592 3.15432 1.45298 3.23105 1.36078C3.32245 1.25066 3.43312 1.16485 3.55442 1.1046C3.67344 1.04525 3.80596 1.00894 3.94614 1.00149C4.0722 0.994617 4.19995 1.01147 4.32219 1.05308C4.42626 1.08847 4.52263 1.14054 4.60811 1.20609L6.00006 2.25006L7.40006 1.20006C7.75561 0.933392 8.2445 0.933392 8.60006 1.20006L10.0001 2.25006L11.4001 1.20006C11.7556 0.933392 12.2445 0.933392 12.6001 1.20006L14.0001 2.25006L15.4001 1.20006C15.7556 0.933392 16.2445 0.933392 16.6001 1.20006L18.0001 2.25006L19.3922 1.20593C19.4769 1.14106 19.5721 1.08941 19.675 1.05407C19.7981 1.01175 19.9269 0.99457 20.0539 1.00149C20.3226 1.01612 20.5837 1.13856 20.7675 1.35886C20.8482 1.45534 20.911 1.56727 20.9509 1.68957C20.985 1.79402 21.0012 1.9023 21.0001 2.00997V17.0001H22.0001C22.5523 17.0001 23.0001 17.4478 23.0001 18.0001C23.0001 19.1766 22.6646 20.3811 22.0157 21.319C21.3583 22.2692 20.3293 23.0001 19.0001 23.0001H7.00006C5.67085 23.0001 4.64182 22.2692 3.98443 21.319C3.33548 20.3811 3.00006 19.1766 3.00006 18.0001L3.00006 2.01036C2.99882 1.89756 3.01658 1.78407 3.05407 1.67503ZM5.00006 4.00006V18.0007C5.00018 18.824 5.24038 19.6192 5.62914 20.1811C6.00957 20.7309 6.48054 21.0001 7.00006 21.0001C7.51959 21.0001 7.99055 20.7309 8.37098 20.1811C8.75984 19.619 9.00006 18.8236 9.00006 18.0001C9.00006 17.4478 9.44778 17.0001 10.0001 17.0001H19.0001V4.00006L18.6001 4.30006C18.2445 4.56673 17.7556 4.56673 17.4001 4.30006L16.0001 3.25006L14.6001 4.30006C14.2445 4.56673 13.7556 4.56673 13.4001 4.30006L12.0001 3.25006L10.6001 4.30006C10.2445 4.56673 9.75561 4.56673 9.40006 4.30006L8.00006 3.25006L6.60006 4.30006C6.2445 4.56673 5.75561 4.56673 5.40006 4.30006L5.00006 4.00006ZM19.0001 21.0001H10.2185C10.5702 20.3976 10.8056 19.709 10.9198 19.0001H20.8816C20.7741 19.446 20.5978 19.8533 20.371 20.1811C19.9906 20.7309 19.5196 21.0001 19.0001 21.0001Z"
        />
      </SvgTemplate>
    );
  }
);
export default BillingIcon;
