import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const StepperOneFilledIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="stepper-one-filled-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM13 7C13 6.59554 12.7564 6.2309 12.3827 6.07612C12.009 5.92134 11.5789 6.0069 11.2929 6.29289L9.29289 8.29289C8.90237 8.68342 8.90237 9.31658 9.29289 9.70711C9.68342 10.0976 10.3166 10.0976 10.7071 9.70711L11 9.41421V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V7Z"
        />
      </SvgTemplate>
    );
  }
);
export default StepperOneFilledIcon;
