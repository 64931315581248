import * as React from 'react';
import MuiTypography, {
  TypographyTypeMap as MuiTypographyTypeMap,
} from '@mui/material/Typography';
import {
  OverridableComponent,
  OverrideProps,
} from '@mui/material/OverridableComponent';

export type UnicornTypographyVariant =
  | 'body1'
  | 'bodyBold'
  | 'bodyItalic'
  | 'button'
  | 'codeSnippet'
  | 'helper'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'label'
  | 'tableHeader';

interface TypographyTypeMap<P = {}, D extends React.ElementType = 'span'> {
  props: Omit<MuiTypographyTypeMap<P, D>['props'], 'variant'> & {
    variant?: UnicornTypographyVariant;
  };
  defaultComponent: D;
}

export type TypographyProps<
  D extends React.ElementType = TypographyTypeMap['defaultComponent'],
  P = {}
> = OverrideProps<TypographyTypeMap<P, D>, D>;

const Typography: OverridableComponent<TypographyTypeMap> = React.forwardRef(
  ({ children, ...rest }, ref) => {
    return (
      <MuiTypography {...rest} ref={ref}>
        {children}
      </MuiTypography>
    );
  }
);

export default Typography;
