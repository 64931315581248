import * as React from 'react';
import { colorWhite, borderRadiusMedium, blue700 } from 'design-tokens';
import { Box, Popover } from '@mui/material';
import { Search } from '@wpengine/unicorn/components';
import { SwitchIcon } from '@wpengine/unicorn/icons';
import { all_sites_path, switch_account_path } from 'railsRoutes';
import { getCurrentUserAccountsFromDOM } from 'commonsDir/helpers/lookupAccounts';
import { AccountDetails } from 'commonsDir/types/Accounts';
import Avatar from '../Avatar/Avatar';
import SideNavButton from '../SideNavButton/SideNavButton';
import SideNavContext from '../SideNavContext';
import { notifySplashScreenListeners } from '../SplashScreenListeners';
import AllAccountsIcon from './AllAccountsIcon';
import CopyAccountIconButton from './CopyAccountIconButton';
import CollapseButton from '../CollapseButton/CollapseButton';

export interface SideNavAccountSwitcherProps {
  activeAccount: AccountDetails;
  allAccountsView: boolean;
  multipleAccounts: boolean;
}

const SideNavAccountSwitcher: React.FC<SideNavAccountSwitcherProps> = ({
  activeAccount,
  allAccountsView,
  multipleAccounts,
}) => {
  const { collapsed } = React.useContext(SideNavContext);
  const [search, setSearch] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => setSearch(''), 200); // Clear search term after menu close
  };

  const accounts: AccountDetails[] = getCurrentUserAccountsFromDOM();

  const handleAccountClicked = (account: AccountDetails): void => {
    handleClose();
    notifySplashScreenListeners(`Switching to ${account.nickname}`, null, 3000);
    window.location.href = switch_account_path(account.id);
  };

  const handleAllAccountsClicked = (): void => {
    handleClose();
    notifySplashScreenListeners(`Switching to all accounts`, null, 3000);
    window.location.href = all_sites_path({
      all_accounts_view: true,
    });
  };

  const accountSort = (a: AccountDetails, b: AccountDetails): number => {
    return a.nickname.localeCompare(b.nickname, undefined, {
      sensitivity: 'base',
    });
  };

  const accountFilter = (account: AccountDetails): boolean => {
    return account.nickname.toLowerCase().includes(search.toLowerCase());
  };

  const filteredAccounts = accounts.filter(accountFilter).sort(accountSort);

  return (
    <>
      <CollapseButton
        id="account-switcher-button"
        aria-controls={open ? 'account-switcher-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        active={open}
        label={activeAccount.nickname}
        title="Switch account"
        startIcon={
          allAccountsView ? (
            <AllAccountsIcon />
          ) : (
            <Avatar
              color={activeAccount.color}
              nickname={activeAccount.nickname}
            />
          )
        }
        endIcon={
          multipleAccounts && !collapsed ? (
            <SwitchIcon aria-label="switch account" />
          ) : null
        }
        onClick={handleClick} // not clickable if there is only one account
        sx={{
          gap: '6px',
          margin: '6px',
          padding: '12px',
          width: collapsed ? '48px' : 'auto',
          '& .MuiButton-startIcon': {
            ' .MuiSvgIcon-root': {
              fontSize: allAccountsView ? '1.5rem' : '1.25rem',
            },
          },
        }}
      />
      <Popover
        id="account-switcher-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={3}
        marginThreshold={8}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        role="menu"
        slotProps={{
          paper: {
            'aria-labelledby': 'account-switcher-button',
          },
        }}
        sx={{
          marginTop: '4px',
          '& .MuiPaper-root': {
            borderRadius: borderRadiusMedium,
            overflow: 'hidden',
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          maxHeight="275px"
          overflow="auto"
          padding="0 4px 4px"
          minWidth="224px"
          maxWidth="224px"
        >
          <Search
            autoFocus
            placeholder="Search accounts"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setSearch(e.target.value);
            }}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>): void => {
              if (e.key === 'Enter' && filteredAccounts.length > 0) {
                handleAccountClicked(filteredAccounts[0]);
              }
            }}
            sx={{
              backgroundColor: colorWhite,
              marginBottom: '4px',
              padding: '8px 0 0',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              '& .MuiInputBase-root': {
                height: '40px',
                padding: '8px 0 8px 8px',
              },
              '& .MuiSvgIcon-root': {
                fontSize: '1.25rem',
              },
              '& .MuiInputBase-root.Mui-focused': {
                boxShadow: `inset 0 0 0 1px ${blue700}`,
              },
            }}
            value={search}
          />
          {multipleAccounts && search === '' && (
            <SideNavButton
              onClick={handleAllAccountsClicked}
              startIcon={<AllAccountsIcon />}
              label="All accounts"
              sx={{ margin: 0 }}
            />
          )}
          {filteredAccounts.map(account => (
            <Box
              key={account.id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap="8px"
              sx={{
                '&:hover, &:focus-within': {
                  ' .copy-account-button': {
                    visibility: 'visible',
                  },
                },
              }}
            >
              <SideNavButton
                onClick={() => handleAccountClicked(account)}
                startIcon={
                  <Avatar
                    color={account.color}
                    nickname={account.nickname}
                    size="small"
                  />
                }
                label={account.nickname}
                sx={{
                  flex: 1,
                  margin: 0,
                  overflow: 'hidden',
                }}
              />
              {activeAccount.isAdmin && (
                <CopyAccountIconButton copyName={account.copyName} />
              )}
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default SideNavAccountSwitcher;
