import AccountNicknameHandlers from 'componentsDir/AccountNickname/mocks/handlers';
import purchaseAddonsHandlers from 'componentsDir/addons/mocks/handlers';
import allSitesHandlers from 'componentsDir/AllSites/mocks/handlers';
import domainsRefreshHandlers from 'componentsDir/DomainsRefresh/mocks/handlers';
import slowQueriesHandlers from 'componentsDir/EcommerceSlowQueryLogs/mocks/slowQueriesHandlers';
import ecommerceStorePerformanceHandler from 'componentsDir/EcommerceStorePerformance/mocks/handlers';
import ftuSurveyHandlers from 'componentsDir/FTUSurvey/mocks/handlers';
import backupsHandlers from 'componentsDir/install/Backups/mocks/handlers';
import wpePaymentsHandlers from 'componentsDir/install/EcommercePayments/mocks/handlers';
import ecommercePanelHanders from 'componentsDir/install/EcommerceSolution/EcommercePanel/mocks/handlers/ecommercePanelHandler';
import gitPushHandlers from 'componentsDir/install/GitPush/mocks/handlers';
import logsFeedHandlers from 'componentsDir/install/LogsFeed/mocks/handlers';
import environmentOverviewHandlers from 'componentsDir/install/Overview/mocks/handlers';
import sideNavChatPinHandlers from 'componentsDir/SideNav/components/SideNavChatPin/mocks/handlers';
import pageSpeedBoostHandlers from 'componentsDir/PageSpeedBoost/mocks/handlers';
import gesPageHandlers from 'componentsDir/products/GlobalEdgeSecurityPage/mocks/handlers';
import sandboxSitesOnboardingHandlers from 'componentsDir/SandboxSites/mocks/onboardingHandlers';
import quickBuyHandlers from 'componentsDir/shared/QuickBuyModal/mocks/handlers';
import notifyHubHandlers from 'componentsDir/shared/repositories/NotifyHubClient/mocks/handlers';
import splitIOHandlers from 'componentsDir/shared/SplitIO/mocks/handlers/splitIOHandlers';
import ssoHandlers from 'componentsDir/singleSignOn/mocks/handlers';
import siteMonitoringHandlers from 'componentsDir/SiteMonitoring/mocks/handlers';
import accountSourceEnvironmentsHandler from 'componentsDir/sites/components/AddSite/mocks/accountSourceEnvironmentsHandler';
import addSiteAndInstall from 'componentsDir/sites/components/AddSite/mocks/addSiteAndInstall';
import envNameSuggestions from 'componentsDir/sites/components/AddSite/mocks/envNameSuggestions';
import installNameAvailable from 'componentsDir/sites/components/AddSite/mocks/installNameAvailable';
import siteTransferHandler from 'componentsDir/sites/components/AddSite/mocks/sitesTransfer';
import acceptAllSitesTransferHandlers from 'componentsDir/sites/components/AddSite/mocks/sitesTransferAvailableSites';
import subscriptionDataHandler from 'componentsDir/sites/components/AddSite/mocks/subscriptionDataHandler';
import siteExtensionsHandlers from 'componentsDir/sites/mocks/handlers';
import spmHandlers from 'componentsDir/SPM/mocks/handlers/index';
import sshHandlers from 'componentsDir/ssh/mocks/handlers';
import technicalContactsHandlers from 'componentsDir/TechnicalContacts/mocks/handlers';
import themesHandler from 'componentsDir/themes/mocks/handlers';
import usageHandlers from 'componentsDir/Usage/mocks/handlers';
import usersSummaryHandlers from 'componentsDir/UsersSummary/mocks/handlers';
import manageHandlers from 'componentsDir/Manage/mocks/handlers';
import accountReadyHandlers from 'mocksDir/shared/handlers/accountReadyHandlers';
import activationsHandlers from 'mocksDir/shared/handlers/activationsHandlers';
import datacentersHandlers from 'mocksDir/shared/handlers/datacentersHandlers';
import entitlementsHandlers from 'mocksDir/shared/handlers/entitlementsHandlers';
import pluginIntegrationHandlers from 'mocksDir/shared/handlers/pluginIntegrationHandlers';
import userEntitlementsHandler from 'mocksDir/shared/handlers/userEntitlementsHandlers';
import redirectRulesHandlers from 'componentsDir/install/RedirectRules/mocks/handlers';
import copyEnvironmentHandlers from 'componentsDir/install/CopyEnvironment/PushPullEnvironment/helper/mocks/handlers/copyEnvironmentHandlers';
import installAdvancedHandler from 'componentsDir/install/Advanced/mocks/handlers';
import discoveryHubHandlers from 'componentsDir/DiscoveryHub/mocks/handlers';
import cacheDashboardHandlers from 'componentsDir/install/Cache/mocks/msw/cacheDashboardHandlers';
import dashboardHandlers from 'componentsDir/Dashboard/mocks/handlers';
import longRunningOperationHandlers from 'componentsDir/install/StatusVisibilityBanner/mocks/handlers';
import advancedHandlers from 'mocksDir/shared/handlers/advancedHandlers';
import dplHandlers from 'componentsDir/DynamicPluginLoading/mocks/dplHandlers';
import sslCertificatesHandlers from 'componentsDir/SslCertificates/mocks/handlers';
import manageSiteTagsHandler from 'componentsDir/ManageSiteTagsPage/mocks/msw/manageSiteTagsHandler';
import portalDevToolsHandlers from 'componentsDir/admin/PortalDevTools/views/mainMenu/Installs/handler';
import cartHandlers from 'componentsDir/shared/providers/Cart/mocks/handlers/cartHandlers';
import billingHandlers from 'componentsDir/shared/providers/BillingMediator/mocks/handlers/billingDataHandlers';
import platformOneHandlers from 'mocksDir/shared/handlers/platformOneHandlers';
import domainStatusesHandler from 'componentsDir/DomainNetworks/mocks/handlers/domainStatusesHandler';
import announcementHiderHandlers from './shared/handlers/announcementHiderHandlers';
import transferSiteHandlers from './shared/handlers/transferSiteHandlers';
import availableLicensesHandler from './shared/handlers/availableLicensesHandler';
import capiUserEmailHandlers from './shared/handlers/capiUserEmail';
import installOperationStatusHandlers from './shared/handlers/installOperationStatusHandlers';

const rootHandlers = [
  ...advancedHandlers,
  ...announcementHiderHandlers,
  ...allSitesHandlers,
  ...backupsHandlers,
  ...activationsHandlers,
  ...cacheDashboardHandlers,
  ...capiUserEmailHandlers,
  ...dashboardHandlers,
  ...sshHandlers,
  ...ssoHandlers,
  ...ftuSurveyHandlers,
  ...siteExtensionsHandlers,
  ...siteMonitoringHandlers,
  ...logsFeedHandlers,
  ...gitPushHandlers,
  ...purchaseAddonsHandlers,
  ...wpePaymentsHandlers,
  ...notifyHubHandlers,
  ...sideNavChatPinHandlers,
  ...domainsRefreshHandlers,
  ...gesPageHandlers,
  ...sandboxSitesOnboardingHandlers,
  ...quickBuyHandlers,
  ...environmentOverviewHandlers,
  ...usageHandlers,
  ...usersSummaryHandlers,
  ...userEntitlementsHandler,
  ...pluginIntegrationHandlers,
  ...pageSpeedBoostHandlers,
  ...acceptAllSitesTransferHandlers,
  ...siteTransferHandler,
  ...accountSourceEnvironmentsHandler,
  ...AccountNicknameHandlers,
  ...spmHandlers,
  ...availableLicensesHandler,
  ...subscriptionDataHandler,
  ...entitlementsHandlers,
  ...installNameAvailable,
  ...addSiteAndInstall,
  ...envNameSuggestions,
  ...themesHandler,
  ...ecommerceStorePerformanceHandler,
  ...ecommercePanelHanders,
  ...technicalContactsHandlers,
  ...slowQueriesHandlers,
  ...splitIOHandlers,
  ...transferSiteHandlers,
  ...accountReadyHandlers,
  ...discoveryHubHandlers,
  ...datacentersHandlers,
  ...redirectRulesHandlers,
  ...copyEnvironmentHandlers,
  ...installAdvancedHandler,
  ...manageHandlers,
  ...discoveryHubHandlers,
  ...dplHandlers,
  ...longRunningOperationHandlers,
  ...sslCertificatesHandlers,
  ...installOperationStatusHandlers,
  ...manageSiteTagsHandler,
  ...portalDevToolsHandlers,
  ...domainStatusesHandler,
  ...platformOneHandlers,
  ...cartHandlers,
  ...billingHandlers,
];

export default rootHandlers;
