import React from 'react';
import { Box, Button, Typography } from '@wpengine/unicorn/components';
import { Update } from '@wpengine/unicorn/glyphs';
import { Paper } from '@mui/material';
import { gray400, gray50 } from 'design-tokens';
import { useCartSummaryContext } from 'componentsDir/shared/providers/Cart/contexts/CartSummaryContext';
import { useRemovePlan } from 'componentsDir/shared/providers/Cart/mutations';
import { renderPrice } from '../../helpers/cart';

const Plan: React.FC = () => {
  const removePlan = useRemovePlan();
  const { newPlan, newAddons, recurrenceShort } = useCartSummaryContext();

  if (!newPlan) {
    return null;
  }

  const handleRemovePlan = () => {
    removePlan();
  };

  return (
    <Box
      sx={{
        p: 2,
        borderBottom: newAddons.length > 0 && `1px solid ${gray400}`,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Update />
          <Typography variant="h5">{newPlan.plan_name} Plan</Typography>
        </Box>
        <Button tabIndex={0} variant="text" onClick={handleRemovePlan}>
          Remove
        </Button>
      </Box>
      <Paper
        sx={{
          backgroundColor: gray50,
          borderRadius: 1,
          padding: 2,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="bodyBold">Cost</Typography>
          <Typography variant="bodyBold">
            {renderPrice(newPlan.price, newPlan.currency)}{' '}
            <Typography display="inline" variant="body1">
              {recurrenceShort}
            </Typography>
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default Plan;
