interface formatCurrencyProps {
  amount: number;
  locale: string;
  currency: string;
  wholeNumber?: boolean;
}

const formatCurrency = ({
  amount,
  locale,
  currency,
  wholeNumber,
}: formatCurrencyProps) => {
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: wholeNumber ? 0 : 2,
  }).format(amount);
};

export default formatCurrency;
