import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const ECommerce: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="ECommerce logo" viewBox="0 0 128 128" {...props}>
      <circle cx="64" cy="64" r="64" fill="url(#paint0_radial_216_14333)" />
      <g filter="url(#filter0_d_216_14333)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.7894 104.118L25.7851 53.8815C24.9539 52.4419 25.4423 50.6203 26.8823 49.7889L63.6927 28.5364C65.0727 27.7397 66.7131 27.5324 68.2509 27.9443L85.3084 32.5123C86.8612 32.9155 88.1631 33.9234 88.9597 35.303L102.466 58.697C103.263 60.0766 103.47 61.7167 103.057 63.2544L98.4847 80.3107C98.0811 81.8633 97.0727 83.1653 95.6927 83.9621L58.8823 105.215C57.4423 106.046 55.6206 105.558 54.7894 104.118ZM87.0527 52C88.7095 54.8698 87.7263 58.5393 84.8565 60.1962C81.9868 61.853 78.3172 60.8698 76.6604 58C75.0035 55.1302 75.9868 51.4607 78.8565 49.8038C81.7263 48.147 85.3958 49.1302 87.0527 52Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_216_14333"
          x="17.3801"
          y="19.7377"
          width="93.8843"
          height="93.8822"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_216_14333"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_216_14333"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_216_14333"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(181.019)"
        >
          <stop stopColor="#0ECAD4" />
          <stop offset="1" stopColor="#006BD6" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default ECommerce;
