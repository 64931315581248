import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const StepperEightIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="stepper-eight-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 6C10.0915 6 8.3 7.37072 8.3 9.35294C8.3 10.2811 8.69277 11.0752 9.30642 11.6572C8.51669 12.3072 8 13.2492 8 14.3529C8 16.4756 9.91099 18 12 18C14.089 18 16 16.4756 16 14.3529C16 13.2492 15.4833 12.3072 14.6936 11.6572C15.3072 11.0752 15.7 10.2811 15.7 9.35294C15.7 7.37072 13.9085 6 12 6ZM13.7 9.35294C13.7 9.97874 13.0738 10.7059 12 10.7059C10.9262 10.7059 10.3 9.97874 10.3 9.35294C10.3 8.72715 10.9262 8 12 8C13.0738 8 13.7 8.72715 13.7 9.35294ZM10 14.3529C10 13.5607 10.7753 12.7059 12 12.7059C13.2247 12.7059 14 13.5607 14 14.3529C14 15.1452 13.2247 16 12 16C10.7753 16 10 15.1452 10 14.3529Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
        />
      </SvgTemplate>
    );
  }
);
export default StepperEightIcon;
