import * as React from 'react';
import { NavItem } from '@wpengine/unicorn/components';
import LabsToggle from 'componentsDir/Labs/components/LabsToggle';
import { useTopNavContext } from 'componentsDir/TopNav/contexts/TopNavProvider';
import {
  default_profile_path,
  destroy_user_session_path,
  mfa_authentication_path,
  ssh_keys_path,
  github_action_path,
  bitbucket_pipeline_path,
  account_nickname_path,
} from 'railsRoutes';
import Divider from './Divider';
import AdminNavItems from './AdminNavItems';

const NavItems: React.FC = () => {
  const {
    csrfToken,
    currentUserUuid,
    hasAccounts,
    hasWpeLabsAccess,
    isAccountOwner,
    isAdmin,
    showSshKeys,
  } = useTopNavContext();

  // If the current URL contains the query param `flywheel=true` - or the corresponding sessionStorage value is already
  // set - we assume the user was redirected from the Flywheel app and is thus a "Flywheel user".
  const isFlywheelUser =
    new URLSearchParams(window.location.search).get('flywheel') === 'true' ||
    sessionStorage.flywheel_user === 'true';

  // Only display applicable menu item(s) if the user is _not_ a Flywheel user AND the user has accounts.
  const displayNavItem = !isFlywheelUser && hasAccounts;

  // If the user is a Flywheel user (i.e. they were originally redirected from the Flywheel app to the profile_path with
  // query param `?flywheel=true` appeneded) we need to reappend the query param here to ensure RegistrationsController
  // sets the Rails session variable `flywheel_user` to `true`.
  const profileUrl = isFlywheelUser
    ? `${default_profile_path()}?flywheel=true`
    : default_profile_path();

  return (
    <>
      <NavItem autoFocus href={profileUrl} size="small">
        Profile & Settings
      </NavItem>
      {isAccountOwner && (
        <NavItem href={account_nickname_path()} size="small">
          Change account name
        </NavItem>
      )}
      <NavItem href={mfa_authentication_path()} size="small">
        Multi-factor authentication
      </NavItem>
      {displayNavItem && showSshKeys && (
        <NavItem href={ssh_keys_path()} size="small">
          SSH keys
        </NavItem>
      )}
      {displayNavItem && (
        <>
          <NavItem href={github_action_path()} size="small">
            GitHub Action
          </NavItem>
          <NavItem href={bitbucket_pipeline_path()} size="small">
            Bitbucket Pipeline
          </NavItem>
        </>
      )}
      {hasWpeLabsAccess && csrfToken && (
        <>
          <Divider />
          <LabsToggle csrfToken={csrfToken} userUuid={currentUserUuid} />
          <Divider />
        </>
      )}
      <NavItem
        href={destroy_user_session_path()}
        size="small"
        onClick={() => window.drift.reset()}
      >
        Sign out
      </NavItem>
      {isAdmin && <AdminNavItems />}
    </>
  );
};

export default NavItems;
