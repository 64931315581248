import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '@wpengine/unicorn/icons/utils/SvgTemplate';
import { darkModeManualInvertClass } from '@wpengine/unicorn/components/DarkMode';

const Yellow = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    <SvgTemplate
      className={darkModeManualInvertClass}
      name="yellow"
      ref={ref}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <circle cx="10" cy="10" r="10" fill="#FFEFCB" />
        <circle cx="10" cy="10" r="8" fill="#EFB736" />
        <circle cx="10" cy="10" r="3" fill="white" />
      </svg>
    </SvgTemplate>
  );
});
export default Yellow;
