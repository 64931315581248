import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const ACF: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="ACF logo" viewBox="0 0 128 128" {...props}>
      <path
        d="M28.48 3.52055L3.52 28.4845C1.26 30.7448 0 33.7853 0 36.9658V121.999C0 125.32 2.68 128 6 128H122C125.32 128 128 125.32 128 121.999V6.00094C128 2.68042 125.32 0 122 0H36.98C33.8 0 30.74 1.2602 28.5 3.52055H28.48Z"
        fill="url(#paint0_radial_143_8650)"
      />
      <g filter="url(#filter0_d_143_8650)">
        <path
          d="M90.5646 84.3314H81.2979V47.0397H106V55.7236H90.5646V62.3781H105.112V70.846H90.5646V84.3329V84.3314Z"
          fill="white"
        />
        <path
          opacity="0.05"
          d="M78.8536 68.6808H88.0385C86.6796 78.1726 78.7004 84.4498 69.0333 84.4498C58.447 84.4498 49.8279 76.5143 49.8279 65.8283C49.8058 63.3432 50.2875 60.8795 51.2438 58.5869C52.2001 56.2944 53.6111 54.2207 55.391 52.492C59.0442 48.9431 63.9481 46.987 69.0333 47.0501C78.611 47.0501 86.813 53.3759 87.9794 62.7156H78.8036C76.0448 52.136 58.8535 53.1705 58.8535 65.8283C58.8535 78.4876 76.2602 79.4201 78.8536 68.6839V68.6808Z"
          fill="#002447"
        />
        <path
          d="M76.9739 68.6809C75.3981 74.0209 69.7895 77.196 64.2689 75.8663C58.7452 74.5321 55.2842 69.1769 56.4308 63.7334C57.5759 58.2885 62.9176 54.7011 68.5292 55.6094C70.8281 55.9217 72.9479 57.0239 74.5275 58.7281C75.6193 59.8555 76.4386 61.2192 76.9223 62.7141H85.8297C84.6634 53.3532 76.4385 47.0501 66.8836 47.0501C61.7978 46.9853 56.8927 48.9404 53.2382 52.489C51.4571 54.2182 50.0453 56.2927 49.0887 58.5864C48.1321 60.8802 47.6506 63.3451 47.6736 65.8314C47.6736 76.5175 56.2427 84.4499 66.8866 84.4499C76.5462 84.4499 84.5026 78.1727 85.8858 68.6809H76.9724H76.9739Z"
          fill="white"
        />
        <path
          opacity="0.05"
          d="M49.2941 78.9061H36.3979L34.2898 84.3252H24.4209L39.5238 47H46.106L61.8095 84.3373H51.3901L49.291 78.9061H49.2941ZM40.0637 69.6668L39.7406 70.5096H46.0014L45.7845 69.8768L42.8695 61.8729L40.0637 69.6668Z"
          fill="#002447"
        />
        <path
          d="M46.8519 78.9061H33.977L31.8704 84.3252H22L37.1044 47H43.6867L59.3901 84.3373H48.9752L46.8519 78.9061ZM37.6428 69.6668L37.3213 70.5096H43.582L43.3636 69.8768L40.4501 61.8729L37.6443 69.6668H37.6428Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_143_8650"
          x="14"
          y="39"
          width="100"
          height="53.4499"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_143_8650"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_143_8650"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_143_8650"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(181.019)"
        >
          <stop stopColor="#0ECAD4" />
          <stop offset="1" stopColor="#006BD6" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default ACF;
