import * as React from 'react';
import { Button, Menu, MenuItem } from '@wpengine/unicorn/components';
import { ChevronDownIcon, ChevronUpIcon } from '@wpengine/unicorn/icons';

export type AccountUser = {
  name: string;
  pin: string;
  href: string;
};

type AccountUsersDropdownProps = {
  accountUsers: Array<AccountUser>;
};

const AccountUsersDropdown: React.FC<AccountUsersDropdownProps> = ({
  accountUsers = [],
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls={open ? 'impersonate-account-users-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-label="Open menu"
        endIcon={open ? <ChevronUpIcon /> : <ChevronDownIcon />}
        onClick={handleClick}
        size="small"
        title="Other users on this account"
        variant="text"
      >
        Switch account user
      </Button>
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        {accountUsers?.map(au => (
          <MenuItem component="a" href={au.href}>
            {au.pin}: {au.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AccountUsersDropdown;
