import * as React from 'react';
import {
  CircularProgress,
  CircularProgressProps,
  styled,
  useTheme,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import * as colors from 'design-tokens';

const GradientCircularProgress = (
  props: CircularProgressProps
): JSX.Element => {
  const theme = useTheme();
  const uuid = uuidv4();

  const GradientCircle = styled(CircularProgress)({
    circle: {
      stroke: `url(#linearColors-${uuid})`,
    },
  });

  return (
    <>
      <svg
        height="0"
        width="0"
        style={{ display: 'block', position: 'absolute' }}
      >
        <linearGradient id={`linearColors-${uuid}`} x1="0" y1="0" x2="1" y2="1">
          <stop offset="20%" stopColor={colors.teal700} />
          <stop offset="90%" stopColor={theme.palette.primary.main} />
        </linearGradient>
      </svg>
      <GradientCircle
        aria-label={props['aria-label'] || 'loading'}
        {...props}
      />
    </>
  );
};

export default GradientCircularProgress;
