let isMSWStarted = false;
const isMSWEnabledInLocalStorage = localStorage.getItem('enableMSW') === 'true';
// Allows MSW to be enabled while running locally, in the live development environment, or in review apps.
const isDevelopment =
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'my_wpe_development';

const startMSW = () => {
  if (!isMSWStarted && isMSWEnabledInLocalStorage && isDevelopment) {
    isMSWStarted = true;
    // eslint-disable-next-line global-require
    const { worker } = require('mocksDir/browser');
    worker.start();
  }
};

export default startMSW;
