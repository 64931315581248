import bulkUpdatesHandlers from 'componentsDir/BulkUpdates/pages/main/mocks/handlers';
import {
  DefaultRequestBody,
  PathParams,
  ResponseComposition,
  RestContext,
  RestRequest,
  rest,
} from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import bulkUpdatesMockDataStore from 'componentsDir/BulkUpdates/pages/main/mocks/store';
import { EnvironmentsResponse } from 'componentsDir/SPM/mocks/data/rawSpmEnvironments';
import { UpdatesHistoryInterface } from 'componentsDir/products/smart_plugin_manager/utils/interfaces';
import paginateMockData from 'componentsDir/sites/mocks/data/paginateMockData';
import spmUpdatesMockDataStore from '../store';

class SitesUpdatesStore {
  currentStore:
    | typeof spmUpdatesMockDataStore
    | typeof bulkUpdatesMockDataStore;

  setStore(page?: 'BulkManagement') {
    if (page === 'BulkManagement') {
      this.currentStore = bulkUpdatesMockDataStore;
      return;
    }
    this.currentStore = spmUpdatesMockDataStore;
  }

  getCurrentStore() {
    if (this.currentStore) {
      return this.currentStore;
    }
    return window.location.href.includes('bulk_updates')
      ? bulkUpdatesMockDataStore
      : spmUpdatesMockDataStore;
  }
}
const sitesUpdatesStore = new SitesUpdatesStore();
export { sitesUpdatesStore };

export const getDateNDaysAgo = (days: number, hours = 0) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  date.setHours(hours, 0, 0, 0);
  return date.toISOString();
};

const getSitesHandler = (
  req: RestRequest<never, PathParams>,
  res: ResponseComposition<DefaultRequestBody>,
  ctx: RestContext
) => {
  const filter: string = req?.url.searchParams.get('filter') ?? '';
  const pageSize: number = parseInt(req?.url.searchParams.get('page_size'), 10);
  const pageToken: string = req?.url.searchParams.get('page_token');

  // 200 OK - Respond with mock environments
  return customResponse(
    ctx.status(200),
    ctx.json(
      spmUpdatesMockDataStore.getEnvironments(pageToken, filter, pageSize)
    )
  );
};
const handlers = [
  ...bulkUpdatesHandlers,
  // GET list of sites that need updates
  rest.get('*/v3/sites', getSitesHandler),
  rest.get('*/v3/sites:batchGet', getSitesHandler),

  // POST bulk changes to site
  rest.post('*/v3/sites:batchUpdate', (req, res, ctx) => {
    const { sites: sitesChanges } = req.body as {
      sites: Partial<EnvironmentsResponse>[];
    };

    const changedSites = spmUpdatesMockDataStore.updateSites(sitesChanges);

    return customResponse(ctx.status(200), ctx.json({ sites: changedSites }));
  }),

  // GET PHP update data from platform one API
  rest.get('*/sites/v1alpha1/environments/:installName', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({
        phpVersion: {
          active: '7.4',
          available: ['8.0'],
        },
      })
    );
  }),

  // GET deferral state for specified environments
  rest.get('*/installs/wp_core/batch_deferral_state', (req, res, ctx) => {
    const installNames: string[] = req?.url?.searchParams?.getAll('installs[]');

    return customResponse(
      ctx.status(200),
      ctx.json(spmUpdatesMockDataStore.getDeferralStates(installNames))
    );
  }),

  // POST change deferral state
  rest.post('*/installs/:installName/wp_core/deferral', (req, res, ctx) => {
    const { installName } = req.params;
    const {
      install: { do_not_upgrade_major_core_versions },
    } = req.body as {
      install: {
        do_not_upgrade_major_core_versions: boolean;
      };
    };
    spmUpdatesMockDataStore.changeDeferralState(
      installName as string,
      do_not_upgrade_major_core_versions
    );
    return customResponse(ctx.status(200));
  }),

  // PUT order php update
  rest.put('*/v3/sites/:siteId/updates/php', (req, res, ctx) => {
    const { siteId } = req.params;
    spmUpdatesMockDataStore.updatePHP(parseInt(siteId as string, 10));
    return customResponse(ctx.status(200));
  }),

  // PUT order WP update
  rest.put('*/v3/sites/:siteId/updates/core', (req, res, ctx) => {
    const { siteId } = req.params;
    spmUpdatesMockDataStore.updateCore(parseInt(siteId as string, 10));
    return customResponse(ctx.status(200));
  }),

  // PUT order to activate SPM
  rest.put('*/v3/sites', (req, res, ctx) => {
    const {
      provider_metadata: { install_name },
    } = req.body as {
      provider_metadata: {
        install_name: string;
      };
    };
    const updatedSite = spmUpdatesMockDataStore.activateSite(install_name);
    // 200 OK - Respond with mock activated environment
    return customResponse(ctx.status(200), ctx.json({ site: updatedSite }));
  }),

  // PUT add to autoupdater
  rest.put('*/sites/:siteId/autoupdater', (req, res, ctx) => {
    const { siteId } = req.params;
    return customResponse(
      ctx.status(200),
      ctx.json(
        spmUpdatesMockDataStore.getAutoupdaterData(
          parseInt(siteId as string, 10)
        )
      )
    );
  }),

  rest.get('*/v3/sites/:siteId/syncs', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({
        syncs: [
          {
            id: 1237840,
            state: 'completed',
            started_at: '1707-04-15T07:35:19.000Z',
            finished_at: '1783-09-18T07:36:12.000Z',
            finish_reason: 'success',
            finish_message: 'Plugins were successfully synchronized.',
            connector_mode: 'plugin',
          },
        ],
        next_page_token: 'fake_token',
      })
    );
  }),

  rest.put('*/v3/sites/:siteId/syncs', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({
        syncs: [
          {
            id: 1237840,
            state: 'completed',
            started_at: '1707-04-15T07:35:19.000Z',
            finished_at: '1783-09-18T07:36:12.000Z',
            finish_reason: 'success',
            finish_message: 'Plugins were successfully synchronized.',
            connector_mode: 'plugin',
          },
        ],
        next_page_token: 'fake_token',
      })
    );
  }),

  // GET list of running updates
  rest.get(`*/sites/updates/running`, (req, res, ctx) => {
    const siteIds: string[] = req?.url?.searchParams?.get('ids')?.split(',');

    return customResponse(
      ctx.status(200),
      ctx.json({
        updates: sitesUpdatesStore.getCurrentStore().getUpdates(siteIds),
      })
    );
  }),

  // GET list of sites update history
  rest.get(`*/v3/sites/:siteId/updates`, (req, res, ctx) => {
    const update: UpdatesHistoryInterface = {
      id: 0,
      state: 'completed',
      finishReason: 'update_passed' as const,
      finishMessage: 'Update(s) were successfully applied.',
      startedAt: '2020-03-19T12:14:07Z',
      completedAt: '2020-03-19T12:32:05Z',
    };
    const updates: UpdatesHistoryInterface[] = Array.from(
      { length: 30 },
      (_, i) => ({
        ...update,
        id: i + 1,
        startedAt: `2020-03-19T12:${10 + i + 1}:07Z`,
      })
    );
    const pageSize: number =
      parseInt(req?.url.searchParams.get('page_size'), 10) || 25;
    const pageToken: string = req?.url.searchParams.get('page_token') || '0';
    const [paginatedUpdates, nextPageToken] = paginateMockData(
      updates,
      pageToken,
      pageSize
    );

    return customResponse(
      ctx.status(200),
      ctx.json({ updates: paginatedUpdates, next_page_token: nextPageToken })
    );
  }),

  // PUT update extensions for specified site
  rest.put(`*/v3/sites/:siteId/updates`, (req, res, ctx) => {
    if (hasPageUrlQueryParam('updateError')) {
      // 400 Bad Request
      // Usage:
      // 1. In browser, navigate to http://localhost:3000/bulk_updates?updateError=true
      // 2. Click "Update" button on topbar to open modal.
      // 3. Click "Update" button on modal.
      return res(ctx.status(400), ctx.json({ error: 'Oops! 😢' }));
    }

    const reqBody = req.body as {
      extensions: { plugins: { slug: string }[]; themes: { slug: string }[] };
    };

    const pluginSlugs = reqBody.extensions.plugins.map(e => e.slug);
    const themeSlugs = reqBody.extensions.themes.map(e => e.slug);
    const { siteId } = req.params;
    sitesUpdatesStore
      .getCurrentStore()
      .updateExtension(parseInt(String(siteId), 10), pluginSlugs, themeSlugs);

    return res(ctx.status(200), ctx.json({ update: { state: 'running' } }));
  }),

  rest.get('*/v3/sites/:siteId/extensions/changes', (req, res, ctx) => {
    return customResponse(
      ctx.status(200),
      ctx.json({
        site_extension_changes: [
          {
            change_type: 'updated',
            change_time: getDateNDaysAgo(1),
            extension_type: 'core',
            version: '6.6.1',
            id: 1,
          },
          {
            change_type: 'updated',
            change_time: getDateNDaysAgo(10),
            display_name: `ZooEffect Plugin for Video player, Photo Gallery Slideshow jQuery and audio / music / podcast - HTML5`,
            extension_type: 'plugin',
            version: `3.1416`,
            id: 9,
          },
          ...Array.from({ length: 15 }, (_, i) => ({
            change_type: 'updated',
            change_time: getDateNDaysAgo(10, i),
            display_name: `Plugin number ${i + 1}`,
            extension_type: 'plugin',
            version: `1.2.${i * 2}`,
            id: i + 10,
          })),
          {
            change_type: 'created',
            change_time: getDateNDaysAgo(20),
            display_name: `Super Cool Plugin`,
            extension_type: 'plugin',
            id: 20,
          },
        ],
        next_page_token: 'fake_token',
      })
    );
  }),
];

export default handlers;
