import { V1alpha2ListEnvironmentsResponse } from 'platform_one_api_client';

const environmentsResponseData: V1alpha2ListEnvironmentsResponse = {
  environments: [
    {
      name: 'deletefordemo',
      account: {
        number: 376935,
        name: 'markaboganda1s',
      },
      cluster: {
        number: 140255,
      },
      createTime: '2024-10-08T12:54:11Z',
      state: 'ACTIVE',
      phpVersion: {},
      technicalContacts: [
        {
          displayName: 'Mark Gwapo',
          email: 'mark.aboganda1+single@gmail.com',
          firstName: 'Mark',
          lastName: 'Gwapo',
        },
      ],
      primaryDomain: 'deletefordemo.wpenginepoweredstaging.com',
      htaccessEnabled: false,
      type: 'PRODUCTION',
      wpeId: 388836,
      sandbox: false,
    },
    {
      name: 'findme1',
      account: {
        number: 376935,
        name: 'markaboganda1s',
      },
      cluster: {
        number: 140255,
      },
      createTime: '2024-02-13T09:07:48Z',
      state: 'ACTIVE',
      phpVersion: {},
      technicalContacts: [
        {
          displayName: 'Mark Gwapo',
          email: 'mark.aboganda1+single@gmail.com',
          firstName: 'Mark',
          lastName: 'Gwapo',
        },
      ],
      primaryDomain: 'newlyaddedwithefpc.com',
      htaccessEnabled: false,
      type: 'PRODUCTION',
      wpeId: 381455,
      sandbox: false,
    },
    {
      name: 'findmedev',
      account: {
        number: 376935,
        name: 'markaboganda1s',
      },
      cluster: {
        number: 140255,
      },
      createTime: '2024-05-16T10:31:26Z',
      state: 'ACTIVE',
      phpVersion: {},
      technicalContacts: [
        {
          displayName: 'Mark Gwapo',
          email: 'mark.aboganda1+single@gmail.com',
          firstName: 'Mark',
          lastName: 'Gwapo',
        },
      ],
      primaryDomain: 'findmedev.wpenginepoweredstaging.com',
      htaccessEnabled: false,
      type: 'DEVELOPMENT',
      wpeId: 384602,
      sandbox: false,
    },
    {
      name: 'forfpc',
      account: {
        number: 376935,
        name: 'markaboganda1s',
      },
      cluster: {
        number: 140255,
      },
      createTime: '2024-06-18T12:45:04Z',
      state: 'ACTIVE',
      phpVersion: {},
      technicalContacts: [
        {
          displayName: 'Mark Gwapo',
          email: 'mark.aboganda1+single@gmail.com',
          firstName: 'Mark',
          lastName: 'Gwapo',
        },
      ],
      primaryDomain: 'efpcbetaon.net',
      htaccessEnabled: false,
      type: 'PRODUCTION',
      wpeId: 385939,
      sandbox: false,
    },
    {
      name: 'legacycustom',
      account: {
        number: 376935,
        name: 'markaboganda1s',
      },
      cluster: {
        number: 140255,
      },
      createTime: '2024-09-12T09:10:24Z',
      state: 'ACTIVE',
      phpVersion: {},
      technicalContacts: [
        {
          displayName: 'Mark Gwapo',
          email: 'mark.aboganda1+single@gmail.com',
          firstName: 'Mark',
          lastName: 'Gwapo',
        },
      ],
      primaryDomain: 'imacustomdomainonadvancenetwork.com',
      htaccessEnabled: false,
      type: 'PRODUCTION',
      wpeId: 388187,
      sandbox: false,
    },
    {
      name: 'overviewallsit',
      account: {
        number: 376935,
        name: 'markaboganda1s',
      },
      cluster: {
        number: 140255,
      },
      createTime: '2024-10-01T13:44:02Z',
      state: 'ACTIVE',
      phpVersion: {},
      technicalContacts: [
        {
          displayName: 'Mark Gwapo',
          email: 'mark.aboganda1+single@gmail.com',
          firstName: 'Mark',
          lastName: 'Gwapo',
        },
      ],
      primaryDomain: 'overviewallsit.wpenginepoweredstaging.com',
      htaccessEnabled: false,
      type: 'PRODUCTION',
      wpeId: 388594,
      sandbox: false,
    },
    {
      name: 'sanboxtestdev',
      account: {
        number: 376935,
        name: 'markaboganda1s',
      },
      cluster: {
        number: 140255,
      },
      createTime: '2024-08-22T10:16:45Z',
      state: 'ACTIVE',
      phpVersion: {},
      technicalContacts: [
        {
          displayName: 'Mark Gwapo',
          email: 'mark.aboganda1+single@gmail.com',
          firstName: 'Mark',
          lastName: 'Gwapo',
        },
      ],
      primaryDomain: 'sanboxtestdev.wpenginedev.com',
      htaccessEnabled: false,
      type: 'DEVELOPMENT',
      wpeId: 387949,
      sandbox: true,
    },
    {
      name: 'installWithNoConfigHash',
      account: {
        number: 376935,
        name: 'markaboganda1s',
      },
      cluster: {
        number: 140255,
      },
      createTime: '2024-08-22T10:16:45Z',
      state: 'ACTIVE',
      phpVersion: {},
      technicalContacts: [
        {
          displayName: 'Mark Gwapo',
          email: 'mark.aboganda1+single@gmail.com',
          firstName: 'Mark',
          lastName: 'Gwapo',
        },
      ],
      primaryDomain: '',
      htaccessEnabled: false,
      type: 'DEVELOPMENT',
      wpeId: 387949,
      sandbox: true,
    },
  ],
  totalSize: 8,
  allEnvironmentsIncluded: true,
};

export default environmentsResponseData;
