// status of the payment gateway regardless of store
// gateway = payment account
export enum StoreUpdateStatusList {
  UPDATED = 'Updated',
  UPDATE_AVAILABLE = 'Update Available',
  UPDATE_REQUIRED = 'Update Required',
}

export enum StoreInvitationStatusList {
  SENT = 'Sent',
  EXPIRED = 'Expired',
}

export enum GatewayStatusList {
  READY = 'Ready',
  PUSH_PENDING = 'Push Pending',
  PUSH_FAILED = 'Push Failed',
  REGENERATE_KEYS_FAILED = 'Regenerate Keys Failed',
}

// individual installs with gateway
export enum StoreGatewayStatusList {
  READY = 'Ready',
  PUSH_PENDING = 'Push Pending',
  PUSH_FAILED = 'Push Failed',
  REGENERATE_KEYS_FAILED = 'Regenerate Keys Failed',
}

// install specific status
export enum StoreStatusList {
  READY = 'Ready',
  UNREACHABLE = 'Unreachable',
  ERROR = 'Error',
  PLUGIN_NOT_FOUND = 'Plugin Not Found',
  SSL_TLS_ERROR = 'SslTlsError',
}
