import { rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';
import environmentsResponseData from '../data/mockEnvironments';

const platformOneHandlers = [
  rest.get(
    '*/platform-one/sites/v1alpha2/environments',
    async (_req, _res, ctx) => {
      return customResponse(
        ctx.status(200),
        ctx.json(environmentsResponseData)
      );
    }
  ),
];

export default platformOneHandlers;
