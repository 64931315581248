/* eslint-disable no-console */
import { AccountLookupType } from '../types/Accounts';
import { fetchEnvironment } from './Settings';

/**
 * Get current user enabled accounts from DOM
 * @returns Array of AccountLookupType Objects
 */
export const getCurrentUserAccountsFromDOM = () => {
  const accountListElement = document.getElementById('current-user-accounts');

  let accountListString = '[]';
  if (accountListElement) {
    accountListString = accountListElement.getAttribute(
      'data-current-user-accounts'
    );
  } else {
    console.error(
      'lookupAccounts: the current user accounts element was not found in the DOM'
    );
  }

  const accountList = JSON.parse(accountListString) as AccountLookupType[];
  return accountList || [];
};

type AccountIdentifiers = keyof AccountLookupType;

const badIdentifiers = ['nickname'];
const internalDevEnvs = ['development', 'my_wpe_development', 'test'];

/**
 * Return identifying account information for the given Array
 * @param lookupKey The identifying key to look up values (e.g., uuid, wpe_id)
 * @param lookupValues An Array of the values to return account information for
 * @returns An Array of AccountLookupType Objects
 */
export const lookupAccounts = (
  lookupKey: AccountIdentifiers,
  lookupValues: Array<string | number>
) => {
  const currentUserAccounts = getCurrentUserAccountsFromDOM();
  let { environment } = global;
  if (!environment) {
    environment = fetchEnvironment();
  }
  // Only show internal dev errors if in development or test mode
  if (
    internalDevEnvs.includes(environment) &&
    badIdentifiers.includes(lookupKey)
  ) {
    console.error(
      `lookupAccounts: "${lookupKey}" is not a unique identifier and will not return accurate results. Use a unique identifier instead.`
    );
    return null;
  }

  return currentUserAccounts.filter((acct: AccountLookupType) => {
    const accountValue = acct[lookupKey];
    return lookupValues.includes(accountValue);
  });
};

export default { lookupAccounts, getCurrentUserAccountsFromDOM };
