import { Select as SelectBase, styled } from '@mui/material';
import { colorWhite, gray100, gray600 } from 'design-tokens';

const UnicornSelect = styled(SelectBase)(({ theme }) => ({
  '& .MuiSelect-select': {
    lineHeight: '22px',
    padding: '16px 40px 16px 16px',
    backgroundColor: colorWhite,
    '&:focus': {
      backgroundColor: 'inherit',
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      backgroundColor: gray100,
      color: gray600,
    },
  },
  '& .MuiSelect-icon': {
    right: 16,
    top: 'auto',
  },
}));

export default UnicornSelect;
