import * as React from 'react';
import { isNavActive } from 'componentsDir/SideNav/utils';
import CollapseButton from '../CollapseButton/CollapseButton';

export interface SideNavLinkProps {
  href: string;
  icon: React.ReactNode;
  label: string;
}

const SideNavLink: React.FC<SideNavLinkProps> = ({ href, icon, label }) => {
  const active = isNavActive(href);
  return (
    <CollapseButton
      href={href}
      startIcon={icon}
      label={label}
      active={active}
    />
  );
};

export default SideNavLink;
