import { MenuItem, styled } from '@mui/material';
import { colorWhite, gray1200, gray400, gray600 } from 'design-tokens';

const UnicornMenuItem = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: gray400,
  },
  '&:active': {
    backgroundColor: `${gray600}`,
    borderColor: `transparent`,
    boxShadow: `none`,
  },
  '&:focus': {
    backgroundColor: gray400,
    borderColor: `${theme.palette.primary.main}`,
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
  },
  '&:active:focus': {
    borderColor: `${theme.palette.primary.main}`,
    backgroundColor: `${gray600}`,
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
  },
  '&.Mui-selected': {
    backgroundColor: `${gray1200}`,
    color: `${colorWhite}`,
    '&:focus, &:active, &:hover': {
      backgroundColor: `${gray1200}`,
      color: `${colorWhite}`,
    },
  },
  '&.Mui-disabled': {
    backgroundColor: gray400,
    color: gray600,
    opacity: 1,
    '&.Mui-selected': {
      borderLeft: '1px solid transparent',
    },
  },
}));

export default UnicornMenuItem;
