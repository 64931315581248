import React from 'react';
import { render } from '@testing-library/react';
import { AccountLookupType } from 'commonsDir/types/Accounts';

interface DOMAccountProperties {
  id?: number;
  nickname?: string;
  wpe_id?: number;
  uuid?: string;
}

export const mockAccounts: AccountLookupType[] = [
  {
    id: 1,
    nickname: 'Account 1',
    wpe_id: 1231,
    uuid: '1234-1234-1',
  },
  {
    id: 2,
    nickname: 'Account 2',
    wpe_id: 1232,
    uuid: '1234-1234-2',
  },
  {
    id: 3,
    nickname: 'Account 3',
    wpe_id: 1233,
    uuid: '1234-1234-3',
  },
  {
    id: 4,
    nickname: 'Account 4',
    wpe_id: 1234,
    uuid: '1234-1234-4',
  },
];

export const setDOMAccounts = (accounts: AccountLookupType[]) => {
  const id = 'current-user-accounts';
  if (document.getElementById(id)) {
    document.getElementById(id).dataset.currentUserAccounts = JSON.stringify(
      accounts
    );
  }
};

export const renderDomAccounts = (accounts?: any[]) => {
  return render(
    <div
      id="current-user-accounts"
      data-current-user-accounts={JSON.stringify(accounts || mockAccounts)}
    />
  );
};

export const setSingleDOMAccount = (properties?: DOMAccountProperties) => {
  setDOMAccounts([
    {
      id: 1,
      nickname: `MSW setSingleDOMAccount()`,
      wpe_id: 1,
      uuid: '00000000-0000-0000-0000-000000000001',
      ...properties,
    },
  ]);
};

export const setCurrentUserInDOM = () => {
  const currentUserDiv = document.createElement('div');
  currentUserDiv.id = 'current-user';
  currentUserDiv.setAttribute(
    'data-current-user',
    JSON.stringify({ email: 'testUser@email.com', uuid: '1234' })
  );

  document.body.appendChild(currentUserDiv);
};
