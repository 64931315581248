import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import {
  mockQuickBuyData,
  nonBundleMockQuickBuyData,
} from 'componentsDir/shared/QuickBuyModal/mocks/data/mockQuickBuyData';
import { QuickBuyAddon } from 'componentsDir/shared/QuickBuyModal/types';
import {
  mockQuickBuyAddons,
  mockQBBundledSPMAddons,
} from 'componentsDir/shared/QuickBuyModal/mocks/data/mockQuickBuyAddons';

const handlers = [
  // GET /quick_buy_addons
  rest.get('*/quick_buy_addons', async (req, res, ctx) => {
    if (hasPageUrlQueryParam('comped')) {
      const quickBuyData = { ...mockQuickBuyData, comped: true };
      return customResponse(ctx.status(200), ctx.json(quickBuyData));
    }

    if (
      hasPageUrlQueryParam('addonPurchased') ||
      hasPageUrlQueryParam('addonFresh')
    ) {
      let addonsPacks = mockQBBundledSPMAddons;
      let quickBuyData = mockQuickBuyData;
      if (hasPageUrlQueryParam('addonEsm')) {
        addonsPacks = addonsPacks.map((addon: QuickBuyAddon) => {
          return { ...addon, esm: true };
        });
      }
      if (hasPageUrlQueryParam('addonPurchased')) {
        addonsPacks[0].purchased = true;
      }
      if (hasPageUrlQueryParam('addonEu')) {
        quickBuyData = { ...quickBuyData, currency: 'EUR' };
      }
      const payloadData = {
        ...quickBuyData,
        addonData: addonsPacks,
      };

      return customResponse(ctx.status(200), ctx.json(payloadData));
    }

    const addonId = req.url.searchParams.get('addon');
    const bundleAddon =
      addonId.includes('smart-plugin-manager') ||
      addonId.includes('monitoring-site') ||
      addonId.includes('page-speed-boost');
    const quickBuyDataMocked = bundleAddon
      ? mockQuickBuyData
      : nonBundleMockQuickBuyData;
    const addonData: QuickBuyAddon[] = [];

    mockQuickBuyAddons.forEach(addon => {
      if (addon.id.includes(addonId)) {
        addonData.push(addon);
      }
    });

    const quickBuyData = { ...quickBuyDataMocked, addonData };

    return customResponse(ctx.status(200), ctx.json(quickBuyData));
  }),
];

export default handlers;
