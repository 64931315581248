import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { QuestionCircleIcon } from '@wpengine/unicorn/icons';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import * as React from 'react';

export interface CustomProps {
  size?: number;
  iconButtonProps?: IconButtonProps;
  icon?: React.ReactNode;
}
export type InfoTipProps = Omit<TooltipProps, 'children'> & CustomProps;
export const InfoTip: React.FC<InfoTipProps> = ({
  size,
  iconButtonProps,
  icon,
  ...props
}) => {
  return (
    <Tooltip {...props}>
      <IconButton {...iconButtonProps}>{icon}</IconButton>
    </Tooltip>
  );
};

InfoTip.defaultProps = {
  icon: <QuestionCircleIcon />,
  placement: 'bottom',
  size: 24,
};

export default InfoTip;
