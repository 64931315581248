import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const GlobalEdgeSecurity: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="GlobalEdgeSecurity logo" viewBox="0 0 64 64" {...props}>
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_793_795)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 30C17.3431 30 16 31.3431 16 33V46C16 47.6569 17.3431 49 19 49H45C46.6569 49 48 47.6569 48 46V33C48 31.3431 46.6569 30 45 30H19ZM36 38C36 39.8638 34.7252 41.4299 33 41.874V44C33 44.5523 32.5523 45 32 45C31.4477 45 31 44.5523 31 44V41.874C29.2748 41.4299 28 39.8638 28 38C28 35.7909 29.7909 34 32 34C34.2091 34 36 35.7909 36 38Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 22C21 15.9249 25.9249 11 32 11C38.0751 11 43 15.9249 43 22V28C43 28.5523 42.5523 29 42 29C41.4477 29 41 28.5523 41 28V22C41 17.0294 36.9706 13 32 13C27.0294 13 23 17.0294 23 22V28C23 28.5523 22.5523 29 22 29C21.4477 29 21 28.5523 21 28V22Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 16C31 15.4477 31.4477 15 32 15C32.9193 15 33.8295 15.1811 34.6788 15.5328C35.5281 15.8846 36.2997 16.4002 36.9497 17.0503C37.5998 17.7003 38.1154 18.4719 38.4672 19.3212C38.8189 20.1705 39 21.0807 39 22C39 22.5523 38.5523 23 38 23C37.4477 23 37 22.5523 37 22C37 21.3434 36.8707 20.6932 36.6194 20.0866C36.3681 19.48 35.9998 18.9288 35.5355 18.4645C35.0712 18.0002 34.52 17.6319 33.9134 17.3806C33.3068 17.1293 32.6566 17 32 17C31.4477 17 31 16.5523 31 16Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 20C31 19.4477 31.4477 19 32 19C32.394 19 32.7841 19.0776 33.1481 19.2284C33.512 19.3791 33.8427 19.6001 34.1213 19.8787C34.3999 20.1573 34.6209 20.488 34.7716 20.8519C34.9224 21.2159 35 21.606 35 22C35 22.5523 34.5523 23 34 23C33.4477 23 33 22.5523 33 22C33 21.8687 32.9741 21.7386 32.9239 21.6173C32.8736 21.496 32.8 21.3858 32.7071 21.2929C32.6142 21.2 32.504 21.1264 32.3827 21.0761C32.2614 21.0259 32.1313 21 32 21C31.4477 21 31 20.5523 31 20Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_793_795"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default GlobalEdgeSecurity;
