import * as React from 'react';
import { IconButtonProps } from '@mui/material';
import { IconButton } from '@wpengine/unicorn/components';
import { gray25, gray100 } from 'design-tokens';

type NavIconButtonProps = IconButtonProps & {
  active?: boolean;
};

const NavIconButton: React.FC<NavIconButtonProps> = React.forwardRef(
  ({ active, ...props }, ref) => {
    return (
      <IconButton
        color="default"
        ref={ref}
        sx={theme => ({
          ':hover, :focus-visible': {
            backgroundColor: gray25,
          },
          '.MuiSvgIcon-root': {
            fontSize: '20px',
          },
          ...(active && {
            color: theme.palette.primary.main,
            backgroundColor: gray100,
          }),
        })}
        {...props}
      />
    );
  }
);

export default NavIconButton;
