import React, { createContext, useContext } from 'react';
import useBillingMediator from 'componentsDir/shared/hooks/billingMediator/useBillingMediator';
import { CartSummaryContext } from '../types/types';
import useCartSummaryContextValue from '../hooks/useCartSummaryContextValue';
import useCart from '../hooks/useCart';

const initialCartSummaryState: CartSummaryContext = {
  cartTotal: 0,
  currentPlan: null,
  currentAddons: [],
  currentAddonsTotal: 0,
  isNewPlanPremium: false,
  itemCount: 0,
  formattedCartTotal: '',
  formattedNewTotal: '',
  previousRecurrence: '',
  previousTotal: 0,
  newAddons: [],
  newAddonsTotal: 0,
  newPlan: null,
  newRecurrence: '',
  newTotal: 0,
  recurrenceShort: '',
  recurrenceLong: '',
  sitesAndStorageUsed: null,
  subscription: null,
  subscriptionRenewalTotal: null,
};

const CreateCartSummaryContext = createContext(initialCartSummaryState);

const CartSummaryContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { billingInfo } = useBillingMediator();
  const { subscription, subscriptionPlan } = billingInfo;
  const { cart } = useCart();
  const { new_plan } = cart;
  const cartSummaryContextValue = useCartSummaryContextValue(
    subscription,
    subscriptionPlan,
    new_plan,
    cart
  );

  return (
    <CreateCartSummaryContext.Provider value={cartSummaryContextValue}>
      {children}
    </CreateCartSummaryContext.Provider>
  );
};

const useCartSummaryContext = () => {
  const context = useContext(CreateCartSummaryContext);
  if (context === undefined) {
    throw new Error(
      'useCartSummaryContext must be used within a CartSummaryContextProvider'
    );
  }
  return context;
};

export {
  CartSummaryContextProvider,
  useCartSummaryContext,
  CreateCartSummaryContext,
};
