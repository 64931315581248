import { rest } from 'msw';

const handlers = [
  rest.get(`*/feature-usage/*`, (req, res, ctx) => {
    return res(
      ctx.json({
        results: {
          evercache: false,
          woocommerce: true,
          live_cart: false,
          instant_store_search: false,
          spm: false,
          storefront: false,
          ecommerce_store_optimizer: false,
          genesis_page_builder: false,
          wpe_payments: false,
          qss: false,
        },
      })
    );
  }),
  rest.get(
    `*/site-templates-orchestrator/v1/template-status/*`,
    (req, res, ctx) => {
      return res(
        ctx.json({
          environment_name: 'TestInstall',
          environment_platform: 'wpengine',
          template_params:
            '"ecommerce_store_optimizer": [], "genesis_page_builder": [], "instant_store_search": ["instant_store_search"], "spm": [], "storefront": [], "woocommerce": [], "wpe_payments": []',
          template_uuid: 'da61941e-346d-4768-933b-1979069cc26d',
          uuid: 'd1ef9ea9-6433-463f-94t9-2fd33ab9o285',
        })
      );
    }
  ),
  rest.post(`*/site-templates-orchestrator/v1/templates/*`, (req, res, ctx) => {
    return res(
      ctx.json({
        steps: [
          {
            error_reason: '',
            name: 'Instant Store Search',
            status: 'FINISHED',
          },
        ],
        uuid: '595bbd59-c842-4550-9d64-1a3eb8ab7680',
      })
    );
  }),
];

export default handlers;
