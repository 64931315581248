import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
  addDelay,
} from 'mocksDir/utils/browserUtils';
import mockSiteExtensions from 'componentsDir/BulkUpdates/pages/main/mocks/data/mockSiteExtensions';
import { sitesUpdatesStore } from 'componentsDir/SPM/mocks/handlers/sites';

const handlers = [
  // GET /sites/extensions
  rest.get(`*/v3/sites/extensions`, (req, res, ctx) => {
    // Get query params included in the API request.
    const filter: string = req?.url.searchParams.get('filter');
    const search: string = req?.url.searchParams.get('search');
    const pageSize: number = parseInt(
      req?.url.searchParams.get('page_size'),
      10
    );
    const pageToken: string = req?.url.searchParams.get('page_token');

    if (hasPageUrlQueryParam('siteExtensionsError')) {
      // 400 Bad Request
      // Usage: In browser, navigate to http://localhost:3000/bulk_updates?siteExtensionsError=true
      return res(ctx.status(400), ctx.json({ error: 'Oops! 😢' }));
    }

    if (hasPageUrlQueryParam('empty')) {
      // 200 OK - response with empty extensions.
      // Usage: In browser, navigate to http://localhost:3000/bulk_updates?empty=true
      return res(ctx.status(200), ctx.json({ extensions: [] }), addDelay(2000));
    }

    // Check for basic filter formatting.
    if (!filter?.includes('type = "')) {
      // 400 Bad Request - Filter not in correct format.
      return res(
        ctx.status(400),
        ctx.json({
          error: 'invalid_filter_format',
          message: 'filter query parameter has invalid format.',
        })
      );
    }

    // 200 OK - Respond with mock extensions
    // Just like the real API, this could return plugins only or themes only or both combined.
    return customResponse(
      ctx.status(200),
      ctx.json(mockSiteExtensions(pageToken, filter, pageSize, search))
    );
  }),

  // PATCH /sites/:siteId/extensions/:extensionId
  // activate/deactivate plugins
  rest.patch(`*/v3/sites/:siteId/extensions/:extensionId`, (req, res, ctx) => {
    const { siteId, extensionId } = req.params;
    const reqBody = req.body as { active: boolean };
    sitesUpdatesStore
      .getCurrentStore()
      .changePluginActiveState(
        parseInt(siteId as string, 10),
        parseInt(extensionId as string, 10),
        reqBody.active
      );
    return customResponse(ctx.status(200));
  }),

  // DELETE /sites/:siteId/extensions/:extensionId
  // uninstall plugin/themes
  rest.delete(`*/v3/sites/:siteId/extensions/:extensionId`, (req, res, ctx) => {
    const { siteId, extensionId } = req.params;
    sitesUpdatesStore
      .getCurrentStore()
      .deleteExtension(
        parseInt(siteId as string, 10),
        parseInt(extensionId as string, 10)
      );
    return customResponse(ctx.status(200));
  }),
];

export default handlers;
