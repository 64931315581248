import { rest } from 'msw';
import { data_deploys_path } from 'railsRoutes';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { sampleBackup } from '../data/backup';

const mockBackups = sampleBackup();
const mockEmptyBackup = sampleBackup({ count: 0, backupsPresent: false });

const backupHandlers = [
  rest.get(`*${data_deploys_path()}*`, async (req, _res, ctx) => {
    if (hasPageUrlQueryParam('empty')) {
      return customResponse(ctx.status(200), ctx.json(mockEmptyBackup));
    }

    return customResponse(ctx.status(200), ctx.json(mockBackups));
  }),
];

export default backupHandlers;
