export const mockedIntegration: NotifyHubSlackIntegration = {
  id: 'mocked_integration_id',
  name: 'accounts/mocked_account_id/slackIntegrations/mocked_integration_id',
  accountId: 'mocked_account_id',
  code: 'mocked_code',
  redirectUri: 'mocked_redirect_uri',
  team: 'mocked_team',
  teamIconUri: '',
  warnings: ['mocked_warning'],
  commonSlackIntegrations: [],
};

export const mockedIntegrationList = {
  nextPageToken: '',
  slackIntegrations: [...Array(10)].map((_, index) => ({
    ...mockedIntegration,
    id: `mocked_integration_id_${index}`,
    name: `accounts/mocked_account_id/slackIntegrations/mocked_integration_id_${index}`,
  })),
};
