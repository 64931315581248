import { rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { mockAddonEnablements } from 'componentsDir/Usage/mocks/data';

const handlers = [
  rest.get('/usage/addon_enablement', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(mockAddonEnablements));
  }),
];

export default handlers;
