import { InstallOperationStatusPayload } from 'componentsDir/shared/types';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';

const handlers = [
  // POST */install_operation_status/add_status
  rest.post<InstallOperationStatusPayload>(
    '*/install_operation_status/add_status',
    (req, res, ctx) => {
      return customResponse(
        ctx.status(201),
        ctx.json({ message: 'Test message' })
      );
    }
  ),
];

export default handlers;
