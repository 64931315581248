import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const KeyIcon = React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    <SvgTemplate name="key-icon" ref={ref} {...props}>
      <path d="M18 7C18 7.55228 17.5523 8 17 8C16.4477 8 16 7.55228 16 7C16 6.44772 16.4477 6 17 6C17.5523 6 18 6.44772 18 7Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3C13.2386 3 11 5.23858 11 8C11 8.50683 11.0751 8.99431 11.2141 9.45288C11.3211 9.80592 11.225 10.1892 10.9642 10.45L3 18.4142L3.00001 21H5L5 20C5 19.7348 5.10536 19.4804 5.29289 19.2929C5.48043 19.1054 5.73478 19 6 19H7V18C7 17.4477 7.44772 17 8 17H9V16C9 15.4477 9.44772 15 10 15H11.5858L13.55 13.0358C13.8108 12.775 14.1941 12.6789 14.5471 12.7859C15.0057 12.9249 15.4932 13 16 13C18.7614 13 21 10.7614 21 8C21 5.23858 18.7614 3 16 3ZM7 21L7.00001 22C7.00001 22.2652 6.89465 22.5196 6.70712 22.7071C6.51958 22.8946 6.26523 23 6.00001 23H2.00001C1.44773 23 1.00001 22.5523 1.00001 22L1 18C0.999999 17.7348 1.10536 17.4804 1.29289 17.2929L9.14801 9.43779C9.05092 8.97308 9 8.49204 9 8C9 4.13401 12.134 1 16 1C19.866 1 23 4.13401 23 8C23 11.866 19.866 15 16 15C15.508 15 15.0269 14.9491 14.5622 14.852L12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17H11V18C11 18.5523 10.5523 19 10 19H9V20C9 20.5523 8.55228 21 8 21H7Z"
      />
    </SvgTemplate>
  );
});
export default KeyIcon;
