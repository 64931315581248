import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';

import SvgTemplate from '../utils/SvgTemplate';

const HeadlessIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="headless-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.50341 2.89172C8.50341 1.85077 9.34379 1 10.3943 1H13.6257C14.6662 1 15.5066 1.84076 15.5066 2.88171V5.33394C15.5066 5.60419 15.6166 5.85441 15.7967 6.04459L17.9577 8.20655C18.1478 8.39672 18.3979 8.49682 18.668 8.49682H21.1191C22.1596 8.49682 23 9.33758 23 10.3785V13.6115C23 14.6524 22.1596 15.4932 21.1191 15.4932H18.668C18.3979 15.4932 18.1478 15.6033 17.9577 15.7834L15.7967 17.9454C15.6066 18.1356 15.5066 18.3858 15.5066 18.656V21.1183C15.5066 22.1592 14.6662 23 13.6257 23H10.3943C9.3538 23 8.51342 22.1592 8.51342 21.1183V18.6661C8.51342 18.3958 8.40337 18.1456 8.22328 17.9554L6.0623 15.7934C5.87221 15.6033 5.6221 15.5032 5.35198 15.5032H2.88085C1.84038 15.5032 1 14.6624 1 13.6215V10.3885C1 9.34759 1.84038 8.50682 2.88085 8.50682H5.33197C5.60209 8.50682 5.85221 8.39672 6.04229 8.21656L8.20327 6.0546C8.39336 5.86442 8.49341 5.61419 8.49341 5.34395V2.89172H8.50341ZM10.5043 3.50227V5.80437C10.5043 6.30482 10.3042 6.78526 9.95407 7.13558L7.14279 9.94813C6.79263 10.2985 6.31241 10.4986 5.81219 10.4986H3.51114C3.23101 10.4986 3.01091 10.7188 3.01091 10.9991V13.0009C3.01091 13.2812 3.23101 13.5014 3.51114 13.5014H5.81219C6.31241 13.5014 6.79263 13.7015 7.14279 14.0519L9.95407 16.8644C10.3042 17.2147 10.5043 17.6952 10.5043 18.1956V20.4977C10.5043 20.778 10.7244 20.9982 11.0045 20.9982H13.0055C13.2856 20.9982 13.5057 20.778 13.5057 20.4977V18.1956C13.5057 17.6952 13.7058 17.2147 14.0559 16.8644L16.8772 14.0419C17.2274 13.6915 17.7076 13.4914 18.2078 13.4914H20.5089C20.789 13.4914 21.0091 13.2712 21.0091 12.9909V10.9891C21.0091 10.7088 20.789 10.4886 20.5089 10.4886H18.2078C17.7076 10.4886 17.2274 10.2884 16.8772 9.93813L14.0559 7.11556C13.7058 6.76524 13.5057 6.2848 13.5057 5.78435V3.48226C13.5057 3.202 13.2856 2.9818 13.0055 2.9818H11.0045C10.7244 2.9818 10.5043 3.202 10.5043 3.48226V3.50227Z"
        />
      </SvgTemplate>
    );
  }
);
export default HeadlessIcon;
