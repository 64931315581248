import moment from 'moment';
import { PathParams, RestRequest } from 'msw';

const defaultDate = () =>
  moment
    .utc()
    .subtract(1, 'day')
    .startOf('day')
    .toDate();

const generateHours = (date: Date): PerformanceInsight[] => {
  const data: PerformanceInsight[] = [];

  for (let i = 0; i < 24; i += 1) {
    const timestamp = moment(date)
      .add(i, 'h')
      .toISOString();

    data.push({
      timestamp,
      visits: 0,
      type: 'total',
      uncachedVisits: 0,
      countUnder5s: 0,
      countUnder2s: 0,
      countUnder1s: 0,
      countUnder500ms: 0,
      countUnder200ms: 0,
    });
  }
  return data;
};

const valueGen = (
  prev: number,
  lower: number,
  upper: number
): [number, number] => {
  let prevValue = prev;
  if (!prevValue) {
    prevValue = Math.floor(Math.random() * (upper - lower + 1)) + lower;
  }

  let rand =
    Math.floor(Math.random() * (prevValue + 100 - (prevValue - 100) + 1)) +
    (prevValue - 100);

  rand = Math.max(lower, rand);
  rand = Math.min(upper, rand);

  return [prevValue, rand];
};

const generateMockData = (req: RestRequest<never, PathParams>) => {
  const date = moment.utc(req.url.searchParams.get('date'), 'DD-MM-YYYY');

  const randVisitsLower = 500;
  const randVisitsUpper = 10000;

  const randUncachedVisitsLower = 100;

  const randLatencyLower = 500;

  const mockData = [];
  let prevRandVisits;

  let prevRandUncachedVisits;

  let prevCountUnder5s;
  let prevCountUnder2s;
  let prevCountUnder1s;
  let prevCountUnder500ms;
  let prevCountUnder200ms;

  for (let i = 0; i < 24; i += 1) {
    let randVisits;
    [prevRandVisits, randVisits] = valueGen(
      prevRandVisits,
      randVisitsLower,
      randVisitsUpper
    );

    let randUncachedVisits;
    [prevRandUncachedVisits, randUncachedVisits] = valueGen(
      prevRandUncachedVisits,
      randUncachedVisitsLower,
      randVisits
    );

    let countUnder5s;
    let countUnder2s;
    let countUnder1s;
    let countUnder500ms;
    let countUnder200ms;
    [prevCountUnder5s, countUnder5s] = valueGen(
      prevCountUnder5s,
      randLatencyLower,
      randVisits
    );
    [prevCountUnder2s, countUnder2s] = valueGen(
      prevCountUnder2s,
      randLatencyLower,
      randVisits
    );
    [prevCountUnder1s, countUnder1s] = valueGen(
      prevCountUnder1s,
      randLatencyLower,
      randVisits
    );
    [prevCountUnder500ms, countUnder500ms] = valueGen(
      prevCountUnder500ms,
      randLatencyLower,
      randVisits
    );
    [prevCountUnder200ms, countUnder200ms] = valueGen(
      prevCountUnder200ms,
      randLatencyLower,
      randVisits
    );

    mockData.push({
      timestamp: moment(date)
        .add(i, 'h')
        .toISOString(),
      visits: randVisits,
      uncachedVisits: randUncachedVisits,
      countUnder5s,
      countUnder2s,
      countUnder1s,
      countUnder500ms,
      countUnder200ms,
    });

    prevRandVisits = randVisits;
    prevRandUncachedVisits = randUncachedVisits;
  }

  return {
    start: date.toISOString(),
    end: moment(date)
      .add(23, 'h')
      .toISOString(),
    data: {
      total: mockData,
    },
  };
};
export { defaultDate, generateHours, generateMockData };
