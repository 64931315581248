// eslint-disable-next-line import/prefer-default-export
export const slackChannel = {
  id: 'mocked_channel_id',
  channel: 'mocked_channel',
  name: 'mocked_channel',
};

export const slackChannelList = {
  nextPageToken: '',
  channels: [...Array(10)].map((_, index) => ({
    ...slackChannel,
    id: `mocked_channel_id_${index}`,
    channel: `mocked_channel_${index}`,
    name: `mocked_channel_${index}`,
  })),
};
