import FetchAPI from 'commonsDir/services/FetchAPI';
import { useQuery } from 'react-query';
import {
  Subscription,
  SubscriptionPlansV2,
} from 'commonsDir/types/BillingMediator';
import { billing_mediator_path } from 'railsRoutes';
import { CancellationRequest } from 'componentsDir/shared/AccountCancellation/types';

type BillingData = {
  subscription: Subscription;
  subscriptionPlan: SubscriptionPlansV2;
  cancellationRequest?: CancellationRequest;
};

const useBillingMediator = () => {
  const { data, isLoading, isError, isSuccess } = useQuery('billingData', () =>
    FetchAPI.get(billing_mediator_path())
  );

  const billingInfo: BillingData = data || {};
  return { billingInfo, isError, isLoading, isSuccess };
};

export default useBillingMediator;
