import * as React from 'react';
import { Chip, ChipProps, Tooltip } from '@mui/material';
import Typography from '../../Typography';

interface BadgeProps extends ChipProps {
  /**
   * To display with or without label
   * If used without label, must provide tooltipText
   */
  number?: number;
  component?: React.ElementType;
  /**
   * If no label provided, must provide tooltipText
   * Also add an aria-label for screen readers
   */
  tooltipText?: string;
}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ label, number, icon, sx, tooltipText, ...rest }, ref) => {
    const getLabel = () => {
      if (number && label) {
        return `${label} (${number})`;
      }
      if (number && icon) {
        return `(${number})`;
      }
      if (number) {
        return number;
      }
      return label;
    };

    const labelText = () => {
      const labelDisplay = getLabel();
      const labelTypography = (
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '30ch',
          }}
          component="span"
        >
          {labelDisplay}
        </Typography>
      );
      if (
        !label ||
        (labelDisplay && labelDisplay.toString().length > 30) ||
        tooltipText
      ) {
        return (
          <Tooltip title={!label || tooltipText ? tooltipText : labelDisplay}>
            {labelTypography}
          </Tooltip>
        );
      }
      return labelTypography;
    };

    return (
      <Chip
        variant="filled"
        label={labelText()}
        icon={icon}
        ref={ref}
        sx={{
          ...sx,
          borderRadius: '32px',
          width: 'fit-content',
          '& .MuiChip-icon': {
            marginLeft: '12px',
          },
        }}
        {...rest}
      />
    );
  }
);

export default Badge;
