import { rest } from 'msw';

import { customResponse } from 'mocksDir/utils/browserUtils';
import { firewallEventsResponseMock } from './data';

export const getFirewallEventsHandler = () => {
  return rest.get(
    `*/products/global_edge_security/firewall_events`,
    (req, res, ctx) => {
      return customResponse(
        ctx.status(200),
        ctx.delay(700),
        ctx.json(firewallEventsResponseMock)
      );
    }
  );
};

export const getFirewallEventsPageHandler = () => {
  return rest.get(
    `*/products/global_edge_security/firewall_events_page`,
    (req, res, ctx) => {
      return customResponse(
        ctx.status(200),
        ctx.delay(700),
        ctx.json(firewallEventsResponseMock)
      );
    }
  );
};

export default [getFirewallEventsHandler()];
