import { MockAccessLogsRequest } from '../types';

const now = Number(new Date());

const mockGooglePhpLogs: MockAccessLogsRequest = {
  logs: [
    {
      installName: 'google',
      environment: 'PROD',
      domain: null,
      ipAddress: '44.210.68.181',
      type: 'php',
      verb: 'GET',
      resource: '/',
      protocol: 'HTTP/1.0',
      status: 200,
      referer: null,
      userAgent:
        'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.60 Safari/537.36',
      date: new Date(now + 213679009.4).toISOString().replace('Z', '+00:00'),
    },
    {
      installName: 'google',
      environment: 'PROD',
      domain: null,
      ipAddress: '44.210.68.181',
      type: 'php',
      verb: 'GET',
      resource: '/',
      protocol: 'HTTP/1.0',
      status: 200,
      referer: null,
      userAgent:
        'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.60 Safari/537.36',
      date: new Date(now - 45520990.6).toISOString().replace('Z', '+00:00'),
    },
  ],
};

const mockYahooPhpLogs: MockAccessLogsRequest = {
  logs: [
    {
      installName: 'yahoo',
      environment: 'PROD',
      domain: null,
      ipAddress: '44.210.68.181',
      type: 'php',
      verb: 'GET',
      resource: '/',
      protocol: 'HTTP/1.0',
      status: 200,
      referer: null,
      userAgent:
        'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.60 Safari/537.36',
      date: new Date(now + 170479009.4).toISOString().replace('Z', '+00:00'),
    },
  ],
};

export { mockGooglePhpLogs, mockYahooPhpLogs };
