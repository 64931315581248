import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const CoreWebVital: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="CoreWebVital logo" viewBox="0 0 64 64" {...props}>
      <path
        d="M64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32Z"
        fill="url(#paint0_radial_793_835)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 48H49C50.6569 48 52 46.6569 52 45L52 19C52 17.3431 50.6568 16 49 16H15C13.3431 16 12 17.3431 12 19V45C12 46.6569 13.3431 48 15 48ZM14 38H50V19C50 18.4477 49.5523 18 49 18H15C14.4477 18 14 18.4477 14 19V38ZM17 43C17 42.4477 17.4477 42 18 42H24C24.5523 42 25 42.4477 25 43C25 43.5523 24.5523 44 24 44H18C17.4477 44 17 43.5523 17 43ZM29 42C28.4477 42 28 42.4477 28 43C28 43.5523 28.4477 44 29 44H35C35.5523 44 36 43.5523 36 43C36 42.4477 35.5523 42 35 42H29ZM39 43C39 42.4477 39.4477 42 40 42H46C46.5523 42 47 42.4477 47 43C47 43.5523 46.5523 44 46 44H40C39.4477 44 39 43.5523 39 43Z"
        fill="#006BD6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.1671 23.7898L42.7412 28.4169C43.0253 28.7847 43.4637 29 43.9283 29H46C46.5523 29 47 28.5523 47 28C47 27.4477 46.5523 27 46 27H44.1739L40.2948 21.9779C39.6432 21.1343 38.3441 21.2205 37.8098 22.143L32.012 32.151L27.3714 25.6014C26.7713 24.7545 25.5131 24.7584 24.9183 25.609L21.148 31H18C17.4477 31 17 31.4477 17 32C17 32.5523 17.4477 33 18 33H21.4085C21.8982 33 22.3571 32.761 22.6377 32.3597L26.1502 27.3372L30.8304 33.9427C31.4621 34.8342 32.8045 34.7729 33.3522 33.8274L39.1671 23.7898Z"
        fill="#006BD6"
      />
      <defs>
        <radialGradient
          id="paint0_radial_793_835"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(90.5097)"
        >
          <stop stopColor="#E7FAFB" />
          <stop offset="1" stopColor="#D5E6F8" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default CoreWebVital;
