import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '@wpengine/unicorn/icons/utils/SvgTemplate';
import { colorWhite, teal700 } from 'design-tokens';

const AllAccountsIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="all-accounts-icon" ref={ref} {...props}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_60_7789)">
            <path
              d="M15.3788 20.34C20.1402 20.34 24 16.5702 24 11.92C24 7.26976 20.1402 3.5 15.3788 3.5C10.6175 3.5 6.75768 7.26976 6.75768 11.92C6.75768 16.5702 10.6175 20.34 15.3788 20.34Z"
              fill={teal700}
            />
            <path
              d="M5.71331 11.92C5.71331 8.3 7.82253 5.15 10.9044 3.58C6.66553 4.11 3.37884 7.64 3.37884 11.92C3.37884 16.2 6.66553 19.74 10.9044 20.27C7.82253 18.69 5.72355 15.55 5.72355 11.92H5.71331Z"
              fill={teal700}
            />
            <path
              d="M2.35495 11.92C2.35495 8.3 4.46416 5.15 7.54607 3.57C3.29693 4.09 0 7.63 0 11.92C0 16.21 3.29693 19.75 7.54607 20.27C4.46416 18.7 2.35495 15.55 2.35495 11.92Z"
              fill={teal700}
            />
            <path
              d="M17.7541 16.08H19.8735L16.6483 6.94H14.0988L10.8735 16.08H12.993L13.6892 14H17.0681L17.7643 16.08H17.7541ZM14.1909 12.49L15.3377 9.03H15.4094L16.5561 12.49H14.1807H14.1909Z"
              fill={colorWhite}
            />
          </g>
          <defs>
            <clipPath id="clip0_60_7789">
              <rect
                width="24"
                height="16.84"
                fill={colorWhite}
                transform="translate(0 3.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </SvgTemplate>
    );
  }
);
export default AllAccountsIcon;
