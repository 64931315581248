// https://github.com/wpengine/atlas-content-engine-control-plane/blob/3699afc4bc0a2926731a99baa8edfcbf6f6669d3/api/wpengine/ce/provision/v1/provision.proto#L25-L38

enum ProvisionStatesEnum {
  Unspecified = 'STATE_UNSPECIFIED',
  Pending = 'STATE_PENDING',
  InProgress = 'STATE_IN_PROGRESS',
  Successful = 'STATE_SUCCESSFUL',
  Unsuccessful = 'STATE_UNSUCCESSFUL',
  Deleting = 'STATE_DELETING',
  Deleted = 'STATE_DELETED',
  DeletionFailed = 'STATE_DELETION_FAILED',
}

export default ProvisionStatesEnum;
