import * as React from 'react';
import { Box } from '@mui/material';
import { ButtonGroup } from '@wpengine/unicorn/components';
import DOMPurify from 'dompurify';
import Link, { LinkProps } from '../../Link/Link';

interface MessageProps {
  actionButtons?: React.ReactElement;
  body?: React.ReactNode;
  linkProps?: LinkProps;
  message?: string;
  title?: string;
}

const Message: React.FC<MessageProps> = ({
  actionButtons,
  body,
  linkProps,
  message,
  title,
}) => {
  return (
    <Box
      className="message"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        lineHeight: '1.5rem',
        paddingRight: '0.5rem',
        flexGrow: 1,
        maxWidth: '676px',
      }}
    >
      {title && (
        <Box
          sx={{
            fontSize: '0.875rem',
            fontWeight: 620,
            lineHeight: '24px',
          }}
        >
          {title}
        </Box>
      )}
      {body && <span>{body}</span>}
      {message && (
        <>
          <span
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
          />{' '}
        </>
      )}
      {linkProps && <Link {...linkProps} />}
      {actionButtons && (
        <ButtonGroup
          justifyContent="flex-start"
          sx={{ pt: 1, a: { alignSelf: 'center' } }}
        >
          {actionButtons}
        </ButtonGroup>
      )}
    </Box>
  );
};

export default Message;
