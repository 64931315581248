import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const RefreshIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="refresh-icon" ref={ref} {...props}>
        <path d="M12 3C7.02944 3 3 7.02944 3 12C3 12.5523 2.55228 13 2 13C1.44772 13 1 12.5523 1 12C1 5.92487 5.92487 1 12 1C15.1527 1 17.9955 2.32704 20 4.4502V3C20 2.44772 20.4477 2 21 2C21.5523 2 22 2.44772 22 3V7C22 7.55228 21.5523 8 21 8H17C16.4477 8 16 7.55228 16 7C16 6.44772 16.4477 6 17 6H18.7084C17.0593 4.15739 14.6649 3 12 3Z" />
        <path d="M22 11C22.5523 11 23 11.4477 23 12C23 18.0751 18.0751 23 12 23C8.84734 23 6.00455 21.673 4 19.5498V21C4 21.5523 3.55228 22 3 22C2.44772 22 2 21.5523 2 21V17C2 16.4477 2.44772 16 3 16H7C7.55228 16 8 16.4477 8 17C8 17.5523 7.55228 18 7 18H5.29162C6.9407 19.8426 9.33515 21 12 21C16.9706 21 21 16.9706 21 12C21 11.4477 21.4477 11 22 11Z" />
      </SvgTemplate>
    );
  }
);
export default RefreshIcon;
