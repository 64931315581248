import * as React from 'react';
import LogoTemplate, { LogoTemplateProps } from '../LogoTemplate';

const PatternManager: React.FC<LogoTemplateProps> = ({ ...props }) => {
  return (
    <LogoTemplate name="PatternManager logo" viewBox="0 0 128 128" {...props}>
      <path
        d="M28.48 3.52055L3.52 28.4845C1.26 30.7448 0 33.7853 0 36.9658V121.999C0 125.32 2.68 128 6 128H122C125.32 128 128 125.32 128 121.999V6.00094C128 2.68042 125.32 0 122 0H36.98C33.8 0 30.74 1.2602 28.5 3.52055H28.48Z"
        fill="url(#paint0_radial_159_10510)"
      />
      <g filter="url(#filter0_d_159_10510)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.0706 30C30.8225 30 29 31.7909 29 34V98C29 100.209 30.8225 102 33.0706 102H44.9294C47.1775 102 49 100.209 49 98V34C49 31.7909 47.1775 30 44.9294 30H33.0706Z"
          fill="white"
        />
        <path
          d="M83.9791 55C81.7815 55 80 56.7823 80 58.9808V98.0192C80 100.218 81.7815 102 83.9791 102H95.0209C97.2185 102 99 100.218 99 98.0192V58.9808C99 56.7823 97.2185 55 95.0209 55H83.9791Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.9428 30C55.7653 30 54 31.813 54 34.0495V45.9505C54 48.187 55.7653 50 57.9428 50H95.0572C97.2347 50 99 48.187 99 45.9505V34.0495C99 31.813 97.2347 30 95.0572 30H57.9428Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.9388 55C55.7634 55 54 56.7907 54 58.9996V71.0004C54 73.2093 55.7634 75 57.9388 75H71.0612C73.2366 75 75 73.2093 75 71.0004V58.9996C75 56.7907 73.2366 55 71.0612 55H57.9388Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54 82C54 80.8954 54.8954 80 56 80H73C74.1046 80 75 80.8954 75 82C75 83.1046 74.1046 84 73 84H56C54.8954 84 54 83.1046 54 82Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54 91C54 89.8954 54.8954 89 56 89H73C74.1046 89 75 89.8954 75 91C75 92.1046 74.1046 93 73 93H56C54.8954 93 54 92.1046 54 91Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54 100C54 98.8954 54.8954 98 56 98H73C74.1046 98 75 98.8954 75 100C75 101.105 74.1046 102 73 102H56C54.8954 102 54 101.105 54 100Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_159_10510"
          x="21"
          y="22"
          width="86"
          height="88"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.141176 0 0 0 0 0.278431 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_159_10510"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_159_10510"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_159_10510"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(45) scale(181.019)"
        >
          <stop stopColor="#0EBCD4" />
          <stop offset="1" stopColor="#00366B" />
        </radialGradient>
      </defs>
    </LogoTemplate>
  );
};

export default PatternManager;
