import { WPVersionsData } from 'componentsDir/AllSites/types';

interface Responses {
  [key: string]: WPVersionsData;
}

const responses: Responses = {
  empty: {
    versions: [
      {
        installName: 'install 1',
        wpVersion: '-1',
      },
      {
        installName: 'install 2',
        wpVersion: '-1',
      },
    ],
  },
};

export default responses;
