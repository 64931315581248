import * as React from 'react';
import { blue700, blue800, gray50, gray100 } from 'design-tokens';
import { isNavActive } from 'componentsDir/SideNav/utils';
import SideNavButton from '../SideNavButton/SideNavButton';

export interface SideNavOptionProps {
  key?: string;
  href: string;
  label: string;
}

const SideNavOption: React.FC<SideNavOptionProps> = ({ href, label }) => {
  const active = isNavActive(href);

  return (
    <SideNavButton
      href={href}
      sx={{
        marginLeft: '36px',
        padding: '8px 12px',
        ...(active && {
          backgroundColor: gray100,
          color: blue700,
          '.MuiTypography-root': {
            fontWeight: '700',
          },
          '&:focus, &:focus-visible, &:hover': {
            backgroundColor: gray50,
            color: blue800,
          },
        }),
      }}
      label={label}
    />
  );
};

export default SideNavOption;
