import * as React from 'react';
import { Box } from '@wpengine/unicorn/components';
import { IconButton } from '@mui/material';
import { WpEngineLogo } from '@wpengine/unicorn/illustrations';
import { WpEngineIcon } from '@wpengine/unicorn/icons';
import { default_profile_path, dashboard_path } from 'railsRoutes';
import { teal700, borderRadiusSmall } from 'design-tokens';
import { useTopNavContext } from 'componentsDir/TopNav/contexts/TopNavProvider';
import { darkModeIgnoreInlineStylesClass } from '@wpengine/unicorn/components/DarkMode';

const Dashboard: React.FC = () => {
  const { largeScreen } = useTopNavContext();

  // If the current URL contains the query param `flywheel=true` - or the corresponding sessionStorage value is already
  // set - we assume the user was redirected from the Flywheel app and is thus a "Flywheel user".
  const isFlywheelUser =
    new URLSearchParams(window.location.search).get('flywheel') === 'true' ||
    sessionStorage.flywheel_user === 'true';

  // Append the `flywheel=true` query param to ensure Rails sets the session variable `flywheel_user` to `true`.
  const urlDestination = isFlywheelUser
    ? `${default_profile_path()}?flywheel=true`
    : dashboard_path();
  const ariaLabel = isFlywheelUser ? 'Profile' : 'Dashboard';

  // If the user is identified as a Flwyheel user by way of the `flywheel=true` query param,
  // store a window sessionStorage value to indicate that they are a Flywheel user.
  if (isFlywheelUser) {
    sessionStorage.setItem('flywheel_user', 'true');
  }

  return (
    <Box display="flex">
      <IconButton
        aria-label={ariaLabel}
        href={urlDestination}
        sx={theme => ({
          padding: '8px',
          '&:hover': {
            backgroundColor: 'inherit',
          },
          borderRadius: borderRadiusSmall,
          '&:focus': {
            outline: 'none', // undo normalize.scss for links
          },
          '&:focus-visible': {
            outline: `5px auto ${theme.palette.primary.main}`,
          },
        })}
      >
        {largeScreen ? (
          <WpEngineLogo className={darkModeIgnoreInlineStylesClass} />
        ) : (
          <WpEngineIcon
            htmlColor={teal700}
            sx={{ height: '32px', width: '32px' }}
          />
        )}
      </IconButton>
    </Box>
  );
};
export default Dashboard;
