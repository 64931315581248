import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import SvgTemplate from '../utils/SvgTemplate';

const StepperSixIcon = React.forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => {
    return (
      <SvgTemplate name="stepper-six-icon" ref={ref} {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 10.5C10 9.42263 10.3561 8.82977 10.7218 8.49812C11.114 8.14243 11.6142 8 12.0001 8C12.4661 8 13.0969 8.21611 13.5128 8.77405C13.8428 9.21688 14.4693 9.30831 14.9121 8.97828C15.355 8.64825 15.4464 8.02172 15.1164 7.57889C14.2646 6.43605 12.993 6 12.0001 6C11.1859 6 10.1861 6.28404 9.37827 7.01658C8.54397 7.77316 8 8.9303 8 10.5L8.00006 14C8.00007 16.1696 9.78387 18 12 18C14.2162 18 16 16.1696 16 14C16 11.8304 14.2162 10 12 10C11.2704 10 10.5877 10.1984 10 10.542L10 10.5ZM10.0001 14C10.0001 12.9208 10.9025 12 12 12C13.0976 12 14 12.9209 14 14C14 15.0791 13.0976 16 12 16C10.9025 16 10.0001 15.0791 10.0001 14Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
        />
      </SvgTemplate>
    );
  }
);
export default StepperSixIcon;
