import React from 'react';
import { CartAddon } from 'componentsDir/shared/providers/Cart/types/types';
import {
  Box,
  Button,
  Dropdown,
  Typography,
} from '@wpengine/unicorn/components';
import { Paper } from '@mui/material';
import { borderRadiusMedium, gray400, gray50 } from 'design-tokens';
import addonIcons from 'componentsDir/shared/providers/Cart/helpers/icons';
import { useCartSummaryContext } from 'componentsDir/shared/providers/Cart/contexts/CartSummaryContext';
import {
  useRemoveAddon,
  useUpdateAddon,
} from 'componentsDir/shared/providers/Cart/mutations';
import { buildAddonOptions, renderPrice } from '../../helpers/cart';
import AddonDropdownItem from './AddonDropdownItem';

interface AddonProps {
  addon: CartAddon;
  border: boolean;
}

const Addon: React.FC<AddonProps> = ({ addon, border }) => {
  const { currentPlan, recurrenceShort } = useCartSummaryContext();
  const { base_sku, name, quantity, price, prices } = addon;
  const removeAddon = useRemoveAddon();
  const updateAddon = useUpdateAddon();

  const Icon = addonIcons[base_sku];
  const options = buildAddonOptions(addon, currentPlan);

  const renderPriceLine = () => {
    if (prices.length === 1) {
      return (
        <Paper
          sx={{
            backgroundColor: gray50,
            borderRadius: borderRadiusMedium,
            padding: 2,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="bodyBold">Cost</Typography>
            <Typography variant="bodyBold">
              {renderPrice(price, currentPlan.currency)}{' '}
              <Typography display="inline" variant="body1">
                {recurrenceShort}
              </Typography>
            </Typography>
          </Box>
        </Paper>
      );
    }

    const handleDropdownChange = (e: string) => {
      updateAddon({ base_sku, quantity: parseInt(e, 10) });
    };

    return (
      <Dropdown
        id={base_sku}
        ItemTemplate={AddonDropdownItem}
        name={base_sku}
        options={options}
        onChange={handleDropdownChange}
        value={`${quantity}`}
      />
    );
  };

  const handleRemove = () => {
    removeAddon(base_sku);
  };

  return (
    <Box sx={{ p: 2, borderBottom: border && `1px solid ${gray400}` }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Box display="flex" alignItems="center">
          <Icon sx={{ mr: 1 }} />
          <Typography variant="h5">{name}</Typography>
        </Box>
        <Button tabIndex={0} variant="text" onClick={handleRemove}>
          Remove
        </Button>
      </Box>
      {renderPriceLine()}
    </Box>
  );
};

export default Addon;
