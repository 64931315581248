import { rest } from 'msw';
import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { emptyMockEnvData, mockEnvData } from 'componentsDir/Usage/mocks/data';

const handlers = [
  rest.get('/usage/env_usage', (req, res, ctx) => {
    if (hasPageUrlQueryParam('empty')) {
      return customResponse(ctx.status(200), ctx.json(emptyMockEnvData));
    }
    return customResponse(ctx.status(200), ctx.json(mockEnvData));
  }),
];

export default handlers;
