import { useMode } from './useMode';

export const useDarkMode = () => {
  const { isMode: isPortalInDarkMode } = useMode('dark');

  return {
    isPortalInDarkMode,
  };
};

export default useDarkMode;
