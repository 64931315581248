import * as React from 'react';
import { destroy_user_session_path } from 'railsRoutes';

const md5 = require('md5');

const useVerifyHash = (string: string, backendHash: string) => {
  React.useEffect(() => {
    const frontendHash = md5(string);
    if (backendHash !== frontendHash)
      window.location.href = destroy_user_session_path();
  }, []);
};

export default useVerifyHash;
