import { rest } from 'msw';
import { getSettingsMap } from 'commonsDir/helpers/Settings';

const handlers = [
  rest.get(`${getSettingsMap().CAPI_URL}/ssh_keys`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: true,
        results: [
          {
            comment: 'MyFirstKey',
            created_at: '2021-09-16T21:39:19.188Z',
            fingerprint: 'fingerprint',
            uuid: 'uuid',
          },
          {
            comment: 'MySecondKey',
            created_at: '2021-09-12T21:39:19.188Z',
            fingerprint: 'fingerprint2',
            uuid: 'uuid2',
          },
        ],
      })
    );
  }),

  rest.post(`${getSettingsMap().CAPI_URL}/ssh_keys`, (req, res, ctx) => {
    return res(
      ctx.json({
        success: true,
        data: req.body,
      })
    );
  }),

  rest.delete(`${getSettingsMap().CAPI_URL}/ssh_keys/uuid`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        success: true,
        data: req.body,
      })
    );
  }),
];

export default handlers;
