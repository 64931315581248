import { IconButton, Toaster } from '@wpengine/unicorn/components';
import { CopyIcon } from '@wpengine/unicorn/icons';
import * as React from 'react';

interface CopyAccountIconButtonProps {
  copyName: string;
}

const CopyAccountIconButton: React.FC<CopyAccountIconButtonProps> = ({
  copyName,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigator.clipboard.writeText(copyName).then(() => {
      Toaster.success(`${copyName} copied to your clipboard!`);
    });
  };
  return (
    <IconButton
      className="copy-account-button"
      onClick={handleClick}
      size="small"
      sx={{ visibility: 'hidden' }}
    >
      <CopyIcon fontSize="inherit" />
    </IconButton>
  );
};

export default CopyAccountIconButton;
