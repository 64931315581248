import * as React from 'react';
import {
  dashboard_path,
  growth_suite_path,
  logs_overview_path,
  plans_path,
  sites_path,
} from 'railsRoutes';
import { AccountLookupType } from 'commonsDir/types/Accounts';
import SideNavWrapper from 'componentsDir/SideNav/SideNavWrapper';
import {
  AbacusIcon,
  DashboardIcon,
  FlywheelIcon,
  LogsOverviewIcon,
  SitesIcon,
} from '@wpengine/unicorn/icons';
import AddonsDevTools from './NavCategories/AddonsDevTools';
import Ecommerce from './NavCategories/Ecommerce';
import Headless, { HeadlessAccount } from './NavCategories/Headless';
import Users from './NavCategories/Users';
import Billing from './NavCategories/Billing';
import SideNavLink from './components/SideNavLink/SideNavLink';

interface SideNavProps {
  canModifyPlan: boolean;
  canPurchaseAddons: boolean;
  chatPin: string;
  currentAccount: AccountLookupType;
  hasDashboard: boolean;
  hasEcommerce: boolean;
  hasEsm: boolean;
  hasGrowthSuite: boolean;
  hasUsers: boolean;
  headlessAccount: HeadlessAccount;
  hasLogs: boolean;
  hasStats: boolean;
  hasBilling: boolean;
}

const SideNav: React.FC<SideNavProps> = ({
  canModifyPlan,
  canPurchaseAddons,
  chatPin,
  currentAccount,
  hasDashboard,
  hasEcommerce,
  hasEsm,
  hasGrowthSuite,
  hasUsers,
  headlessAccount,
  hasLogs,
  hasStats,
  hasBilling,
}) => {
  if (
    currentAccount?.nickname === null ||
    currentAccount?.nickname === undefined
  ) {
    return <nav className="unicorn sidenav" />;
  }

  const dashboardPath = dashboard_path();
  const sitesPath = sites_path();
  const growthSuitePath = growth_suite_path();
  const logsOverviewPath = logs_overview_path();
  const plansPath = plans_path();

  return (
    <SideNavWrapper
      chatPin={chatPin}
      currentAccount={currentAccount}
      allAccountsView={false}
    >
      {hasDashboard && (
        <SideNavLink
          icon={<DashboardIcon />}
          label="Dashboard"
          href={dashboardPath}
        />
      )}
      <SideNavLink icon={<SitesIcon />} label="Sites" href={sitesPath} />
      <Headless account={headlessAccount} />
      <Ecommerce hasEcommerce={hasEcommerce} />
      {hasGrowthSuite && (
        <SideNavLink
          icon={<FlywheelIcon />}
          label="Flywheel Growth Suite"
          href={growthSuitePath}
        />
      )}
      <AddonsDevTools />
      {hasUsers && <Users currentAccountName={currentAccount.name} />}
      {hasBilling && (
        <Billing
          canPurchaseAddons={canPurchaseAddons}
          hasEsm={hasEsm}
          hasStats={hasStats}
        />
      )}
      {hasLogs && (
        <SideNavLink
          icon={<LogsOverviewIcon />}
          label="Logs Overview"
          href={logsOverviewPath}
        />
      )}
      {canModifyPlan && (
        <SideNavLink
          icon={<AbacusIcon />}
          label="Modify plan"
          href={plansPath}
        />
      )}
    </SideNavWrapper>
  );
};

export default SideNav;
