import { rest } from 'msw';
import { customResponse } from 'mocksDir/utils/browserUtils';
import { mockAllData } from './mockData';

const handlers = [
  rest.get('capi/private/*/products', (req, res, ctx) => {
    return customResponse(ctx.status(200), ctx.json(mockAllData));
  }),
];

export default handlers;
