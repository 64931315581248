import { V1alpha1NetworkType } from 'platform_one_api_client';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../constants';

export const mockedInstallsWithDomainResponse = [
  {
    installName: 'My Site#1',
    domains: [
      { networkType: V1alpha1NetworkType.Ges },
      { networkType: V1alpha1NetworkType.An },
      { networkType: V1alpha1NetworkType.Ges },
    ],
  },
  {
    installName: 'My Site#2',
    domains: [{ networkType: V1alpha1NetworkType.Ges }],
  },
];

export const firewallEventsResponseMock = {
  firewall_data_available: true,
  total_events: 10,
  attacks_blocked: 4,
  events_challenged: 3,
  start_time: DEFAULT_START_DATE.toISOString(),
  end_time: DEFAULT_END_DATE.toISOString(),
};
