interface Team {
  name: string;
  // eslint-disable-next-line prettier/prettier
  channel: `#${string}`;
  channelId: string;
}

type TeamName =
  | 'ARES'
  | 'ATH'
  | 'AU'
  | 'AU-SPM'
  | 'AU-PSB'
  | 'BC'
  | 'BX'
  | 'CA'
  | 'CAFE'
  | 'CICD'
  | 'CJEM'
  | 'ELEC'
  | 'FOOL'
  | 'GEN'
  | 'IPA'
  | 'LOCAL'
  | 'NIMB'
  | 'GAIA'
  | 'Orion'
  | 'Payup'
  | 'Plush'
  | 'TITAN'
  | 'TMNT'
  | 'TPOZ'
  | 'WREN'
  | 'MSMRC'
  ;

export const PORTAL_TEAMS: Record<TeamName, Team> = {
  ARES: {
    name: 'Ares',
    channel: '#team-pe-ares-public',
    channelId: 'C03B42GJKGF',
  },
  ATH: {
    name: 'Athena',
    channel: '#pe-athena-public',
    channelId: 'CHLR0LXHV',
  },
  AU: {
    name: 'Golden',
    channel: '#team-golden-public',
    channelId: 'CLF8CCA3W',
  },
  'AU-SPM': {
    name: 'Golden',
    channel: '#golden-escalations',
    channelId: 'C01AN8XSAG4',
  },
  'AU-PSB': {
    name: 'Golden',
    channel: '#page-speed-boost-escalations',
    channelId: 'C05Q28U1ZRU',
  },
  BC: {
    name: 'Bouncer',
    channel: '#team-identity-public',
    channelId: 'C01UG4HFEMT',
  },
  BX: {
    name: 'Builder Experience Temp',
    channel: '#temp-bx-team',
    channelId: 'C05830ZP935',
  },
  CA: {
    name: 'Webstack',
    channel: '#pe-webstack',
    channelId: 'CELDCUZQF',
  },
  CAFE: {
    name: 'CAFE',
    channel: '#team-pe-cafe-public',
    channelId: 'C05GCC6R7T6',
  },
  CICD: {
    name: 'Domino',
    channel: '#pe-bx-domino-public',
    channelId: 'C03FNALBJKU',
  },
  CJEM: {
    name: 'Customer Journey',
    channel: '#customer-journey-public',
    channelId: 'C05LRJS8FAR',
  },
  ELEC: {
    name: 'Electrum',
    channel: '#electrum-public',
    channelId: 'C01AXGNJ2UX',
  },
  FOOL: {
    name: 'Föolery',
    channel: '#team-föolery-public',
    channelId: 'C018XD56NQJ',
  },
  GEN: {
    name: 'Genesis',
    channel: '#pe-team-gen-public',
    channelId: 'CFDJDSE4Q',
  },
  IPA: {
    name: 'IPA',
    channel: '#team-pe-ipa',
    channelId: 'C05FVCGNCEB',
  },
  LOCAL: {
    name: 'Local',
    channel: '#local-support-wpe',
    channelId: 'C018W27D2AH',
  },
  NIMB: {
    name: 'Nimbus',
    channel: '#team-pe-nimbus-public',
    channelId: 'C02217VJKND',
  },
  GAIA: {
    name: 'Gaia',
    channel: '#atlas-gaia-public',
    channelId: 'C029EKY04UA',
  },
  Orion: {
    name: 'Orion',
    channel: '#orion-public',
    channelId: 'C01RTRCDAQ6',
  },
  Payup: {
    name: 'Payup',
    channel: '#es-signup-public',
    channelId: 'C054Z3Q70LV',
  },
  Plush: {
    name: 'Plush',
    channel: '#pe-plush',
    channelId: 'C041EV08E4R',
  },
  TITAN: {
    name: 'Titan',
    channel: '#team-pe-titan-public',
    channelId: 'C04SJP4UBB7',
  },
  TMNT: {
    name: 'Momentum',
    channel: '#team-pe-momentum',
    channelId: 'C018XK7AK9R',
  },
  TPOZ: {
    name: 'TPOZ',
    channel: '#team-tpoz-public',
    channelId: 'CEJTQ5LUC',
  },
  WREN: {
    name: 'WREN',
    channel: '#web-rules-support',
    channelId: 'C027VQ3SX54',
  },
  MSMRC: {
    name: 'MSMRC',
    channel: '#team-mesmeric-public',
    channelId: 'C01MULFTGQL',
  },
};

export default PORTAL_TEAMS;
