import { MockErrorLogsRequest } from '../types/index';

const now = Number(new Date());

const mockGoogleErrorLogs: MockErrorLogsRequest = {
  logs: [
    {
      installName: 'google',
      environment: 'PROD',
      type: 'error',
      severity: 'notice',
      client: '35.245.159.253:0',
      message:
        'auditor:event=upgrader_process_complete_plugin {"plugin":"autoupdater_success_30/autoupdater_update.php","type":"plugin","action":"update","blog_id":1,"event":"upgrader_process_complete_plugin","current_user_id":1,"remote_addr":"35.245.159.253"}',
      date: new Date(now - 66012590.6).toISOString().replace('Z', '+00:00'),
    },
  ],
};

const mockYahooErrorLogs: MockErrorLogsRequest = {
  logs: [
    {
      installName: 'yahoo',
      environment: 'PROD',
      type: 'error',
      severity: 'notice',
      client: '35.245.159.253:0',
      message:
        'auditor:event=upgrader_process_complete_plugin {"plugin":"autoupdater_success_30/autoupdater_update.php","type":"plugin","action":"update","blog_id":1,"event":"upgrader_process_complete_plugin","current_user_id":1,"remote_addr":"35.245.159.253"}',
      date: new Date(now - 100572590.60000001)
        .toISOString()
        .replace('Z', '+00:00'),
    },
  ],
};

export { mockGoogleErrorLogs, mockYahooErrorLogs };
