import {
  customResponse,
  hasPageUrlQueryParam,
} from 'mocksDir/utils/browserUtils';
import { rest } from 'msw';
import {
  ENDPOINTS,
  MSW_PARAMS,
} from 'componentsDir/ManageSiteTagsPage/constants';
import { generateTaggableSites } from './data';

const taggableSitesHandler = [
  rest.get(`*/v1/${ENDPOINTS.GET_TAGGABLE_SITES}`, (_req, _res, ctx) => {
    if (hasPageUrlQueryParam(MSW_PARAMS.NOT_TAGGABLE)) {
      return customResponse(
        ctx.status(200),
        ctx.json({
          taggableSites: generateTaggableSites({
            numberOfAccounts: 5,
            numberOfSitesPerAccount: 10,
            randomizeNumberOfSitesPerAccount: true,
            taggable: false,
          }),
        })
      );
    }

    if (hasPageUrlQueryParam(MSW_PARAMS.MORE_THAN_FIFTY_SITES)) {
      return customResponse(
        ctx.status(200),
        ctx.json({
          taggableSites: generateTaggableSites({
            numberOfAccounts: 5,
            numberOfSitesPerAccount: 51,
            randomizeNumberOfSitesPerAccount: true,
            taggable: true,
          }),
        })
      );
    }

    return customResponse(
      ctx.status(200),
      ctx.json({ taggableSites: generateTaggableSites() })
    );
  }),
];

export default taggableSitesHandler;
