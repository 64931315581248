import { AccountDetails } from 'commonsDir/types/Accounts';

// eslint-disable-next-line import/prefer-default-export
export const ALL_ACCOUNTS_LOOKUP: AccountDetails = {
  id: 0,
  isAdmin: false,
  nickname: 'All accounts',
  name: 'all_accounts',
  wpe_id: 0,
  uuid: 'All accounts all sites',
  accountUserId: 'All accounts all sites',
};
