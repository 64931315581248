import paginateMockData from 'componentsDir/sites/mocks/data/paginateMockData';
import { pick } from 'lodash';
import { hasPageUrlQueryParam } from 'mocksDir/utils/browserUtils';
import rawEnvironments, {
  EnvironmentsResponse,
} from 'componentsDir/SPM/mocks/data/rawSpmEnvironments';
import { MockUpdate } from './data/types';
import filterEnvironments from './data/filterEnvironments';

class SpmUpdatesMockDataStore {
  environments: EnvironmentsResponse[] = rawEnvironments;

  updatesBySiteId = {} as Record<string, MockUpdate>;

  completeUpdateTimeout: ReturnType<typeof setTimeout>;

  updateDelay = 10_000;

  finishUpdatesAfterDelay = true;

  deferredInstallNames = [
    'testsite801',
    'testsite500',
    'testsite300',
    'testsite1000',
  ];

  reset({
    finishUpdatesAfterDelay = true,
  }: {
    finishUpdatesAfterDelay: boolean;
  }) {
    if (this.completeUpdateTimeout) {
      clearTimeout(this.completeUpdateTimeout);
    }
    this.updateDelay = 10_000;
    this.finishUpdatesAfterDelay = finishUpdatesAfterDelay;
    this.environments = rawEnvironments;
    this.updatesBySiteId = {};
    this.deferredInstallNames = [
      'testsite801',
      'testsite500',
      'testsite300',
      'testsite1000',
    ];
  }

  getEnvironments(pageToken: string, filter?: string, pageSize: number = 10) {
    const filteredEnvironments = filterEnvironments(this.environments, filter);
    const [sites, next_page_token] = paginateMockData(
      filteredEnvironments,
      pageToken,
      pageSize
    );
    return { sites, next_page_token };
  }

  completeProcessingUpdate(siteId: number) {
    if (hasPageUrlQueryParam('updateCompleteError')) {
      delete this.updatesBySiteId[siteId];
      return;
    }
    if (this.updatesBySiteId[siteId]) {
      const { extensions, php, core } = this.updatesBySiteId[siteId];

      const updatedExtensionsIds = extensions?.map(e => e.id);
      this.environments = this.environments.map(environment => {
        if (environment.id === siteId) {
          if (extensions) {
            return {
              ...environment,
              plugins: environment.plugins.filter(
                plugin => !updatedExtensionsIds.includes(plugin.id)
              ),
              themes: environment.themes.filter(
                theme => !updatedExtensionsIds.includes(theme.id)
              ),
            };
          }
          if (php) {
            return {
              ...environment,
              php: undefined,
            };
          }
          if (core) {
            return {
              ...environment,
              core: undefined,
            };
          }
        }
        return environment;
      });
      delete this.updatesBySiteId[siteId];
    }
  }

  getUpdates(siteIds: string[]) {
    return Object.values(pick(this.updatesBySiteId, siteIds));
  }

  slugToExtensionId(slugs: string[], siteId: number) {
    const thisEnvironment = this.environments.find(
      environment => environment.id === siteId
    );
    const environmentExtensions = [
      ...thisEnvironment.themes,
      ...thisEnvironment.plugins,
    ];
    return slugs.map(slug => ({
      id: environmentExtensions.find(extension => extension.slug === slug).id,
    }));
  }

  updateCore(siteId: number) {
    this.updatesBySiteId[siteId] = {
      id: Math.round(Math.random() * 1000),
      site_id: siteId,
      started_at: String(new Date()),
      core: true,
    };
    if (this.finishUpdatesAfterDelay) {
      this.completeUpdateTimeout = setTimeout(
        () => this.completeProcessingUpdate(siteId),
        this.updateDelay
      );
    }
  }

  updatePHP(siteId: number) {
    this.updatesBySiteId[siteId] = {
      id: Math.round(Math.random() * 1000),
      site_id: siteId,
      started_at: String(new Date()),
      php: true,
    };
    if (this.finishUpdatesAfterDelay) {
      this.completeUpdateTimeout = setTimeout(
        () => this.completeProcessingUpdate(siteId),
        this.updateDelay
      );
    }
  }

  updateExtension(
    siteId: number,
    pluginSlugs?: string[],
    themeSlugs?: string[]
  ) {
    const extensionsUpdates = this.slugToExtensionId(
      [...pluginSlugs, ...themeSlugs],
      siteId
    );

    this.updatesBySiteId[siteId] = {
      id: Math.round(Math.random() * 1000),
      site_id: siteId,
      started_at: String(new Date()),
      extensions: extensionsUpdates,
    };
    if (this.finishUpdatesAfterDelay) {
      this.completeUpdateTimeout = setTimeout(
        () => this.completeProcessingUpdate(siteId),
        this.updateDelay
      );
    }
  }

  changeDeferralState(installName: string, newStatus: boolean) {
    if (newStatus) {
      this.deferredInstallNames.push(installName);
    } else {
      this.deferredInstallNames = this.deferredInstallNames.filter(
        name => name !== installName
      );
    }
  }

  getDeferralStates(installNames: string[]) {
    return {
      deferral: installNames.map(installName => {
        const deferralState = {
          installName,
          deferred: false,
          url: `/installs/${installName}/wp_core/deferral`,
        };
        if (this.deferredInstallNames.includes(installName)) {
          deferralState.deferred = true;
        }

        return deferralState;
      }),
    };
  }

  activateSite(installNames: string) {
    let activatedSite;
    this.environments = this.environments.map(site => {
      if (site.provider_metadata.install_name === installNames) {
        activatedSite = {
          ...site,
          autoupdater_active: true,
        };
        return activatedSite;
      }
      return site;
    });
    return activatedSite;
  }

  getActivatedSites(installNames: string) {
    const activatedSite = this.environments.find(
      site => site.provider_metadata.install_name === installNames
    );
    return { site: activatedSite };
  }

  getAutoupdaterData(siteId: number) {
    const autoUpdaterSite = this.environments.find(site => site.id === siteId);
    return {
      autoupdater: {
        autoupdater_activated_at: autoUpdaterSite?.autoupdater_activated_at,
        autoupdater_deactivated_at: null as string,
        site_id: siteId,
      },
    };
  }

  updateSites(updateSites: Partial<EnvironmentsResponse>[]) {
    const changedSitesIds = updateSites.map(site => site.id);
    this.environments = this.environments.map(environment => {
      if (changedSitesIds.includes(environment.id)) {
        return {
          ...environment,
          ...updateSites.find(site => site.id === environment.id),
        };
      }

      return environment;
    });

    return this.environments.filter(environment =>
      changedSitesIds.includes(environment.id)
    );
  }

  changePluginActiveState(
    siteId: number,
    extensionId: number,
    active: boolean
  ) {
    this.environments.forEach(environment => {
      if (environment.id === siteId) {
        [...environment.plugins, ...environment.themes].forEach(extension => {
          if (extension.id === extensionId) {
            // eslint-disable-next-line no-param-reassign
            extension.active = active;
          }
        });
      }
    });
  }

  deleteExtension(siteId: number, extensionId: number) {
    this.environments.forEach(environment => {
      if (environment.id === siteId) {
        // eslint-disable-next-line no-param-reassign
        environment.plugins = environment.plugins.filter(
          extension => extension.id !== extensionId
        );
        // eslint-disable-next-line no-param-reassign
        environment.themes = environment.themes.filter(
          extension => extension.id !== extensionId
        );
      }
    });
  }
}

const mockDataStore = new SpmUpdatesMockDataStore();

export default mockDataStore;
